export enum KEY_LS {
    REMEMBER_ME = "remember_me",
    JWT_TOKEN = "auth_token",
    JWT_TOKEN_TIME = "auth_token_time",
    USER_ID = "user_id",
    DEVICE_ID = "device_id",
    DEVICE_INFO = "device_info",
    APPLE_DEVICE_TOKEN = "apple_device_token",
    GUEST_USER = "guest_user",
}
