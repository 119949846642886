import React, {useCallback, useState} from "react";
import {AppBar, IconButton, Toolbar} from "@material-ui/core";
import SelectAllIcon from "@material-ui/icons/SelectAll";
import DeviceHubIcon from "@material-ui/icons/DeviceHub";
import PanToolIcon from "@material-ui/icons/PanTool";
import ObjectsTreeDialog from "../../../Dialogs/ObjectsTreeDialog/ObjectsTreeDialog";
import {ObjectItem} from "../../../../Services/ObjectsService";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import {useEditElementsStyles} from "../SiteMapStyles";
import {PropsEditElements} from "../TypesSiteMap";
// import {equalsIgnoreOrder} from "../../../../Services/Utils";

const filterObjectsIds: string[] = [];

export default function EditElements(props: PropsEditElements) {
    const {
        siteMapController,
        userInfo,
        closeDrawers,
        setCreateMode,
        setZoneType,
        setPropertiesObjectOpened,
        setElementObjectsOpened,
        elementObjectsOpened,
    } = props;

    const classes = useEditElementsStyles();

    const [filterObjectsType, setFilterObjectsType] = useState<string>("all_available");
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

    // TODO [IM] Keep it here until removing filterObjectsIds prop from ObjectsTreeDialog
    // const childrenIds = useRef<string[]>([]);
    //
    // const mapChildren = siteMapController?.getChildrenObject()
    //     .map((_object) => _object.object_id) || [];
    // if (!equalsIgnoreOrder(mapChildren, childrenIds.current)) {
    //     childrenIds.current = mapChildren;
    // }

    const closeMenu = () => {
        setMenuAnchorEl(null);
    };

    const handleBindChild = useCallback((object: ObjectItem | null) => {
        if (siteMapController && object) {
            const mapChildren = siteMapController?.getChildrenObject()
                .map((_object) => _object.object_id) || [];

            siteMapController.setSelectObject(object);

            if (!mapChildren.includes(object.object_id)) {
                setCreateMode(true);
            }
        }

        closeDrawers();
    }, [siteMapController]);

    const zoneButtonClicked = (event: any) => {
        closeDrawers();
        setCreateMode(false);
        siteMapController?.setSelectObject(null);
        setMenuAnchorEl(event.currentTarget);
    };

    const newZoneMenuItemClicked = (objectType: string) => {
        closeMenu();
        setCreateMode(true);
        setZoneType(objectType);
    };

    const moveButtonClicked = () => {
        setCreateMode(false);
        setZoneType("");
        siteMapController?.setSelectObject(null);
    };

    const openListObjects = () => {
        setPropertiesObjectOpened(false);
        setFilterObjectsType("all_available");
        setElementObjectsOpened(true);
        setCreateMode(false);
        siteMapController?.setSelectObject(null);
    };

    const placeExistingZoneMenuItemClicked = () => {
        closeMenu();
        setPropertiesObjectOpened(false);
        setElementObjectsOpened(true);
        setFilterObjectsType("mapZone");
    };

    return (
        <>
            <AppBar
                position="fixed"
                style={{
                    top: "auto",
                    bottom: 0,
                }}
            >
                <Toolbar>
                    <div className={classes.iconButton}>
                        <IconButton classes={{root: classes.iconRoot}} color="inherit" onClick={moveButtonClicked}>
                            <PanToolIcon/>
                        </IconButton>
                        <div>Move</div>
                    </div>
                    <div className={classes.iconButton}>
                        <IconButton classes={{root: classes.iconRoot}} className="" color="inherit"
                                    onClick={zoneButtonClicked}>
                            <SelectAllIcon/>
                        </IconButton>
                        <div>Zone</div>
                        <Menu
                            id="map-zone-menu"
                            anchorEl={menuAnchorEl}
                            keepMounted
                            open={Boolean(menuAnchorEl)}
                            onClose={closeMenu}
                        >
                            <MenuItem onClick={newZoneMenuItemClicked.bind(null, "Zone")}>New Zone</MenuItem>
                            <MenuItem onClick={newZoneMenuItemClicked.bind(null, "CameraMapZone")}>
                                New Camera Zone
                            </MenuItem>
                            <MenuItem onClick={placeExistingZoneMenuItemClicked}>
                                Place Existing Zone…
                            </MenuItem>
                        </Menu>
                    </div>
                    <div className={classes.iconButton}>
                        <IconButton classes={{root: classes.iconRoot}} color="inherit" onClick={openListObjects}>
                            <DeviceHubIcon/>
                        </IconButton>
                        <div>Objects</div>
                    </div>
                </Toolbar>
            </AppBar>
            <ObjectsTreeDialog
                show={elementObjectsOpened}
                filterObjectsIds={filterObjectsIds}
                filterType={[filterObjectsType]}
                userInfo={userInfo}
                handleBindChild={handleBindChild}
            />
        </>
    );
}
