import {Point} from "paper";
import { postMessageToParentApp } from "../../Utils";
import { hitOptionsAllTouch } from "../../Constants";
import {
    ObjectItem,
    subPropertiesValueByKey,
    unsubPropertiesValueByKey
} from "../../../../Services/ObjectsService";
import { PropertyItem } from "../../../../Services/ObjectsService";
import {v4} from "uuid";
import {OriginType} from "../../../../Services/ObjectsService/Constants";

/*
// Legacy Property Type. Now the PropertyItem type from Object should be used instead.
export type ElementProperty = {
    name: string
    value: number
    type: 'number' | 'string'
    read_only?: boolean
    units: string
    min?: number
    max?: number
}
*/

export type ElementInfo = {
    object_name: string;
    description: string;
};

export type ElementData = {
    id: string;
    type: ElementType;
    object_type?: string;
    // properties: Array<PropertyItem>;
    properties: { [index: string]: any };
    info: ElementInfo;
    // TODO: do we actually need the Values and Formulas, if the element rendering is
    // encapsulated into the element class?

    // Visualisation formulas
    // Defining how the real-world properties are converted into the element look on the plan
    formulas: any;
    // Visualisation values
    // Values defining the element look. Calculated by the formulas above
    currentValues: any;
    // Element-Specific alignment information
    alignment: any;
};

export enum ElementType {
    SOLID_WALL,

    SINGLE_DOOR,
    DOUBLE_DOOR,
    SLIDING_DOOR,

    SINGLE_WINDOW,
    DOUBLE_WINDOW,
    SLIDING_WINDOW,

    LIGHT,
    SWITCH,

    TABLE,
    CHAIR,
    OBJECT_ELEMENT,
    ZONE_ELEMENT,
}

export abstract class AbstractElement {
    /**
     * PaperJS Item, representing the element in PaperJS Layer
     */

    protected paperJSItem: any;
    protected removed: boolean = false;
    protected parentId: string | null = null;
    propSubId: string | null = null;
    public isDisabled: boolean = false;

    constructor(paperJSItem?: any, parentId?: string | null) {
        if (parentId) {
            this.parentId = parentId;
        }
        if (paperJSItem) {
            this.paperJSItem = paperJSItem;
            this.setPropertiesAccordingToCurrentGeometry();
        } else {
            this.generatePaperJSItem();
            this.setDefaultData();
            // Object type is the same as the type of the element.
            // This can be overridden when the object will be explicitly assigned
            // on the element
            this.paperJSItem.data.object_type = this.paperJSItem.data.type;
        }
    }

    // Accessor Methods
    //=======================================================================

    protected unsubElementProperty() {
        if (this.propSubId) {
            unsubPropertiesValueByKey(this.paperJSItem.data.id, this.propSubId);
        }
    }

    public subUpdateProperty(cb:any): void {
        if (this.paperJSItem.data.id && (this.paperJSItem.data?.properties?.["plan_point"]?.key || this.paperJSItem.data?.properties?.["plan_polygon"]?.key)) {
            this.unsubElementProperty(); // TODO [IM] Can be removed?

            this.propSubId = v4();

            subPropertiesValueByKey(this.paperJSItem.data.id, this.paperJSItem.data?.properties?.["plan_point"]?.key || this.paperJSItem.data?.properties?.["plan_polygon"]?.key, this.propSubId, OriginType.LOCAL, (keys, data) => {
                if (keys.objectId !== this.paperJSItem.data.id) {
                    console.error("Incorrect Object ID");
                    return;
                }

                if (!keys.propertyKey) {
                    return;
                }

                this.setProperty(keys.propertyKey as string, (data as PropertyItem)?.value);
                this.updateGeometry();
                cb();
            });
        }
    }

    public getId(): any {
        return this.paperJSItem.data.id;
    }

    public getElementType(): ElementType {
        return this.paperJSItem.data.type;
    }

    public getProperty(key: string): any {
        if (key === "plan_point" || key === "plan_polygon") {
            let parent_id = this.getParentId();
            return this.paperJSItem.data.properties[key].value[parent_id];
        } else {
            return this.paperJSItem.data.properties[key].value;
        }
    }

    public setProperties(new_properties: any){
        this.paperJSItem.data.properties = new_properties
    }

    public setProperty(key: string, value: any) {
        if (key === "plan_point" || key === "plan_polygon") {
            let parent_id = this.getParentId();
            if (!value[parent_id]) {
                if(typeof this.paperJSItem.data.properties[key].value === 'undefined') {
                    this.paperJSItem.data.properties[key].value = {}
                }

                return this.paperJSItem.data.properties[key].value[parent_id] = value;
            }
        }

        return this.paperJSItem.data.properties[key].value = value;
        //this.updateGeometry();
    }

    public setPropertyItem(property: PropertyItem) {
        this.paperJSItem.data.properties[property.key] = property;
    }

    public isSelected(): Boolean {
        return false;
    }

    public setSelect(select: boolean) {
        this.paperJSItem.selected = select;
    }

    public getElementData(): ElementData {
        return this.paperJSItem.data;
    }

    public setObjectData(object: ObjectItem) {
        Object.assign(this.paperJSItem.data.properties, object.properties);
        this.paperJSItem.data.icon = object.icon;
        this.paperJSItem.data.info.object_name = object.object_name;
        this.paperJSItem.data.info.description = object.description;
        this.paperJSItem.data.id = object.object_id;
        this.paperJSItem.data.object_type = object.object_type;
    }

    public getParentId(): string {
        return this.parentId as string;
    }

    public setObjectType(objectType: ElementType | string) {
        this.paperJSItem.data.object_type = objectType;
    }

    public getObjectInstance(): ObjectItem {
        return {
            object_id: this.paperJSItem.data.id,
            object_type: this.paperJSItem.data.object_type,
            object_name: this.paperJSItem.data.info.object_name,
            description: this.paperJSItem.data.info.description,
            // Temporary code. Location is to be removed from the mandatory fields
            properties: this.paperJSItem.data.properties,
        };
    }

    // getTitle?

    // getDescription?

    public setObjectName(value: string) {
        this.paperJSItem.data.info.object_name = value;
    }

    public setDescription(value: string) {
        this.paperJSItem.data.info.description = value;
    }

    // Common method to return the PaperJS representation of the object.
    // It should likely not be used, as it exposes the internal Element structure
    public getPaperJSItem(): any {
        return this.paperJSItem;
    }

    protected setPropertiesAccordingToCurrentGeometry() {}
    // Mouse Events Handlers. To be invoked by the tools
    // =======================================================================
    public abstract onMouseDown(event: any): void;

    public abstract onMouseDrag(event: any): void;

    public abstract onMouseUp(event: any): void;

    public removeElement() {
        this.unsubElementProperty();
        this.paperJSItem.remove();
        this.removed = true;
    }

    public isRemoved() {
        return this.removed;
    }

    // DEPRECATED
    // TODO: Should it be moved to separate singleton class. E.g. ExpressionEngine?
    protected evaluateExpression(expressionString: any) {
        let expressionStringWithValuesReplaced = expressionString;
        Object.keys(this.paperJSItem.data.properties).forEach((key: any) => {
            expressionStringWithValuesReplaced = expressionStringWithValuesReplaced.replace(
                key,
                this.paperJSItem.data.properties[key].value
            );
        });
        return eval(expressionStringWithValuesReplaced);
    }

    // Alignment Methods
    // =======================================================================

    /**  
        @name updateAlignment - 
        Updates the alignment. Should be called during the element editing to 
        adjust the relations of the element.
        @param elements - an array of the elements the current element can 
        possibly stick to  
    */

    public abstract updateAlignment(elements: AbstractElement[]): void;

    /**
        Alignment method. Updates properties using the alignment information.
        I.e. sets properties according to the position of the elements
        the current element has to stick to.
    */

    public abstract alignElement(elements: AbstractElement[]): void;

    /**
        Geometry update according to the properties set
    */

    public abstract updateGeometry(): void;

    // Geometry
    // =======================================================================
    // Hit test. To be used by tools.
    public testElementHit(event: any): Boolean {
        let hitResult = this.paperJSItem.hitTest(event.point, hitOptionsAllTouch);
        if (hitResult) {
            return true;
        }
        return false;
    }
    
    
    // To adjust the current touch position to match the actual point of the element which is being dragged
    public getDragStartDifference(event: any): any {
        // No difference by default
        return new Point(0,0)
    }

    // Abstract Init Methods
    // =======================================================================

    // Method to return the PaperJS representation of the element.
    // To be implemented in the concrete class
    protected abstract generatePaperJSItem(): void;
    protected abstract setDefaultData(): void;

    // Deprecated. This should be done by either Tool or Editor
    // Accessor method to get element data should be implemented instead
    // =======================================================================
    // Present the element data in the UI.
    // Should it be moved to Global Manager or to be a static method?
    showElementData(): void {
        // console.log('Sending Message with Element Properties');
        let message = {
            type: "showElementData",
            data: this.paperJSItem.data,
        };

        postMessageToParentApp(message);
    }
}
