import React, {ReactNode, useCallback, useEffect, useState} from "react";
import {Link} from "@material-ui/core";
import {ObjectItem, postObjectProperties, PropertyItem, PropertyItemValue} from "../../../Services/ObjectsService";
import PropertyControls from "./PropertyControls/PropertyControls";
import {propertyDefaultValues} from "./PropertyUtils";
import {usePropertyStyles} from "./PropertyStyles";
import {useSubPropertyValue} from "../../../Hooks/Subscriptions/useSubPropertyValue";
import {AccessLevel, OriginType} from "../../../Services/ObjectsService/Constants";
import {SPAM_LOGS} from "../../../Services/Constants";
import Icon from "@material-ui/core/Icon";
import {useGlobalNotificationContext} from "../../Notification/NotificationProvider";
import type {SnackBarType} from "../../../Services/NotificationService";
import {isUserHasAccess} from "../../../Services/Utils";
import {UserInfoType} from "../../../Hooks/useUserProfile";

export type PropertyProps = {
    onChange?(key: string, value: PropertyItemValue, loader?: boolean): void;
    property: PropertyItem;
    isDisabled?: boolean;
    object?: ObjectItem;
    variant?: "title" | "propertyCard" | "drawer" | "string" | "marker" | "popover";
    fontSize?: number;
    settings?: ReactNode;
    userInfo: UserInfoType;
    parentId?: string;
};

export default function Property(props: PropertyProps) {

    const {onChange, property, userInfo, isDisabled, object, variant, fontSize, settings, parentId} = props;

    const notify: SnackBarType = useGlobalNotificationContext();
    const classes = usePropertyStyles();

    const [objectId, setObjectId] = useState<string | null>(object?.object_id || null);

    const [value, setValue] = useState<PropertyItemValue>(property.value);
    const [reportedValue, setReportedValue] = useState<PropertyItemValue | undefined>(property.reported_value);
    const [valueMissing, setValueMissing] = useState<boolean>(!property.hasOwnProperty("value"));

    const [allowEditMissingValue, setAllowEditMissingValue] = useState<boolean>(false);
    const mounted = React.useRef(false);

    const {subscriptionId} = useSubPropertyValue(objectId, property.key, (data) => {
        if (data.hasOwnProperty("value")) {
            setValue(data.value);
            setValueMissing(false);
        }

        if (data.hasOwnProperty("reported_value")) {
            setReportedValue(data.reported_value);
        }
    });

    useEffect(() => {
        mounted.current = true; // Will set it to true on mount ...
        return () => {
            mounted.current = false;
        }; // ... and to false on unmount
    }, []);

    const updateProperty = useCallback(async (key: string, propertyValue: PropertyItemValue, push?: boolean) => {
        if (!mounted.current) {
            return;
        }

        !push && setValue(propertyValue);

        if (!property.hasOwnProperty('value')) {
            property.value = propertyValue
        }

        if (!objectId || (!property.writable && !push)) return;

        const clientId = userInfo.clientId;

        try {
            const body = {
                [key]: {
                    value: propertyValue,
                    writable: property.writable,
                    property_id: property.property_id,
                    type: property.type,
                    name: property.name,
                    key: key
                }
            };

            await postObjectProperties(objectId, body, {
                origin: OriginType.USER,
                clientId: clientId,
                sid: subscriptionId,
                // event: SubscriptionType.PROPERTIES
            });
            if (!mounted.current) {
                return;
            }
            if (SPAM_LOGS) {
                console.log(`Property ${key} in object ${objectId} updated successfully`);
            }
            push && setValue(propertyValue);
        } catch (err: any) {
            if (!object?.deleted) {
                notify.errorNotify(err?.message || JSON.stringify(err));
            }
        }
    }, [notify, objectId, property, userInfo.clientId])

    useEffect(() => {
        setObjectId(object?.object_id || null);
    }, [object?.object_id]);

    useEffect(() => {
        if (!onChange || value === undefined) {
            return;
        }
        onChange(property.key, value, property.writable && reportedValue !== value && (!Array.isArray(value) || (!!reportedValue && reportedValue.toString() !== value.toString())));
    }, [value, reportedValue]);

    useEffect(() => {
        if (JSON.stringify(property.value) === JSON.stringify(value)) return;
        setValue(property.value);
    }, [property.value]);

    if (property.protection && valueMissing && !allowEditMissingValue) {
        return (
            <>
                {`${property.name}: Value encrypted `}
                {property.writable && property.name !== "Custom" && !isUserHasAccess(object, AccessLevel.WRITE) && variant !== "title" && (
                    <Link
                        className={classes.linkEdit}
                        onClick={() => {
                            setAllowEditMissingValue(true)
                        }}>
                        <Icon className={"fa fa-pencil-alt"} fontSize="small"/>
                    </Link>
                )}
            </>
        );
    } else if (!property.protection && valueMissing) {
        return (
            <>
                {`${property.name}: Value encrypted`}
            </>
        );
    }

    const propertyControlsValue = property.hasOwnProperty('value') ? value
        : Object.keys(propertyDefaultValues).includes(property.type) && allowEditMissingValue ?
            propertyDefaultValues[property.type] : 'Value encrypted'

    return value !== undefined || allowEditMissingValue ? (
        <PropertyControls
            onChange={updateProperty}
            property={property}
            userInfo={userInfo}
            isDisabled={isDisabled}
            object={object}
            settings={settings}
            parentId={parentId}
            variant={variant}
            fontSize={fontSize}
            value={propertyControlsValue}
        />
    ) : null;
}
