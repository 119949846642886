import React, {useEffect, useState} from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import {IconButton} from "@material-ui/core";
import {useUserProfileStyles} from "./UserProfileStyles";


type CollapseButtonProps = {
    state: boolean;
    onClick(): void;
};

const CollapseButton = (props: CollapseButtonProps) => {
    const classes = useUserProfileStyles();

    const {state, onClick} = props;

    const [show, setShow] = useState<boolean>(state);

    const handleOnClick = () => {
        onClick();
    };

    useEffect(() => {
        setShow(state);
    }, [state]);

    return (
        <IconButton size="small" edge="end" onClick={handleOnClick}>
            {show && <ExpandMoreIcon className={classes.iconSecondaryColor}/>}
            {!show && <ArrowForwardIosIcon fontSize="small" className={classes.iconSecondaryColor}/>}
        </IconButton>
    );
};

export default CollapseButton;