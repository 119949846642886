import React, {useCallback, useState} from "react";
import {CardContentPropertyProps} from "../../TypesCard";
import CardContentItem from "./CardContentItem";
import {PropertyItemValue} from "../../../../../Services/ObjectsService";
import Property from "../../../Property/Property";


const CardContentProperty = (props: CardContentPropertyProps) => {

    const {property, object, isDisabled, userInfo} = props;
    const [loader, setLoader] = useState<boolean>(
        property.writable && property.value !== property.reported_value &&
        (!Array.isArray(property.value) || (!!property.reported_value && property.reported_value.toString() !== property.value.toString()))
    );

    const handlePropertyOnChange = useCallback(
        (key: string, value: PropertyItemValue, inProgress?: boolean) => setLoader(!!inProgress),
        [setLoader]);

    return (
        <>
            <CardContentItem
                icon={property.icon || "fas fa-info-circle"}
                label={
                    <Property
                        property={property}
                        object={object}
                        variant={property.writable ? "popover" : "string"}
                        onChange={handlePropertyOnChange}
                        userInfo={userInfo}
                    />
                }
                clickable={property.writable}
                loader={loader}
                isDisabled={isDisabled}
                isMarker={props.isMarker}
            />
        </>
    )

};


export default CardContentProperty;
