import React from "react";
import {SlideType} from "../WelcomeDialogContent";
import {Grid, Typography} from "@material-ui/core";
import Link from "@material-ui/core/Link";
import WelcomeDialogContentBodyImg from "../WelcomeDialogContentBodyImg";

export const Slide10 = (props: SlideType) => {
    return (
        <>
            <Grid
                container
                spacing={3}
                direction="row"
                justifyContent="center"
                alignItems="flex-start">
                <Grid item xs={12} sm={6}>
                    <WelcomeDialogContentBodyImg
                        src={"/img/welcome_dialog/img_slide_10_dark.png"}
                        themePaletteType="dark"
                    />
                    <WelcomeDialogContentBodyImg
                        src={"/img/welcome_dialog/img_slide_10_light.png"}
                        themePaletteType="light"
                    />
                </Grid>
                <Grid item xs={12} sm>
                    <Typography
                        variant={props.xsSize ? "body1" : "h6"}
                        align="left"
                        gutterBottom={true}>
                        Location is always important, the discovered objects will have it assigned and will be displayed
                        on your site map or floor plan next to your IoT devices.
                    </Typography>
                    <Typography
                        variant={props.xsSize ? "body1" : "h6"}
                        align="left"
                        gutterBottom={true}>
                        For iOS front or back camera the location will automatically be assigned to all of the
                        discovered
                        objects, using the iOS device location and orientation. No preliminary configuration is
                        needed.
                    </Typography>
                    <Typography
                        variant={props.xsSize ? "body1" : "h6"}
                        align="left"
                        gutterBottom={true}>
                        For IP cameras the camera floor or map zone needs to be created for the location to be assigned
                        to the discovered objects
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography
                        variant={props.xsSize ? "body1" : "h6"}
                        align="left"
                        gutterBottom={true}>
                        <Link href="https://kb.localmachines.io/users-guides/detect-objects-map/" rel="noreferrer" target="_blank">
                            LocalMachines. How to detect objects with camera and see the detected objects on map or floor plan</Link>
                    </Typography>
                </Grid>
            </Grid>
        </>
    )
}
