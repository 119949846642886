
const controlSwitchTypes = [
    "PowerState",
    "LiveStreaming",
    "ObjectDetection",
    "HoldPosition",
    // "RotationDirection",
    // "TargetDoorState",
    // "TargetLockMechanismState",
    "OutputState",
    // "TemperatureUnits",
    "HoldPosition",
    "Boolean",
    "AirplayStreaming"
];
const controlNumberTypes = ["Elevation", "number", "Number"];
const controlTextTypes = ["Address", "Name", "Url", "text", "String", "Page"];
const controlHueTypes = ["Hue"];
const controlSliderTypes = [
    "Saturation",
    "Brightness",
    "TargetPosition",
    "RotationSpeed",
    // "TargetSecuritySystemState",
    // "TargetHeatingCooling",
    "TargetTemperature",
    "TargetRelativeHumidity",
    "CoolingThreshold",
    "HeatingThreshold",
    "TargetHorizontalTilt",
    "TargetVerticalTilt",
];
const controlMapPointType = ["MapPoint"];
const controlMapPolygonType = ["MapPolygon"];
const controlImagePointType = ["ImagePoint"];
const controlImagePolygonType = ["ImagePolygon"];
const controlPlanPolygonType = ["PlanPolygon"];
const controlPlanPointType = ["PlanPoint"];
const controlImageType = ["Image"];
const controlChatType = ["ChatMessage"];
const controlFileType = ["File"];

export const HMCharacteristicValues = {
    "LockMechanismLastKnownAction": [                                                                                   //26
        {
            value: 0,
            label: 'Secured Physically, Interior',
        },
        {
            value: 1,
            label: 'Unsecured Physically, Interior',

        },
        {
            value: 2,
            label: 'Secured Physically, Exterior',

        },
        {
            value: 3,
            label: 'Unsecured Physically, Exterior',
        },
        {
            value: 4,
            label: 'Secured by Keypad',
        },
        {
            value: 5,
            label: 'Unsecured by Keypad',
        },
        {
            value: 6,
            label: 'Secured Remotely',

        },
        {
            value: 7,
            label: 'Unsecured Remotely',

        },
        {
            value: 8,
            label: 'Secured by Auto Secure Timeout',
        },
        {
            value: 9,
            label: 'Secured Physically',
        },
        {
            value: 10,
            label: 'Unsecured Physically',
        },
    ],
    "AirParticulateSize": [                                                                                             //59
        {
            value: 0,
            label: '2.5 μm',
        },
        {
            value: 1,
            label: '10  μm',

        },
    ],
    "AirQuality": [                                                                                                     //78
        {
            value: 0,
            label: 'unknown',
        },
        {
            value: 1,
            label: 'Excellent',

        },
        {
            value: 2,
            label: 'Good',

        },
        {
            value: 3,
            label: 'Fair',
        },
        {
            value: 4,
            label: 'Inferior',
        },
        {
            value: 5,
            label: 'Poor',
        },
    ],
    "PositionState": [                                                                                                  //102
        {
            value: 0,
            label: 'Decreasing',
        },
        {
            value: 1,
            label: 'Increasing',

        },
        {
            value: 2,
            label: 'Stopped',

        },
    ],
    "CurrentSecuritySystemState": [                                                                                     //122
        {
            value: 0,
            label: 'Stay Arm',
        },
        {
            value: 1,
            label: 'Away Arm',

        },
        {
            value: 2,
            label: 'Night Arm',

        },
        {
            value: 3,
            label: 'Disarmed',
        },
        {
            value: 4,
            label: 'Alarm Triggered',
        },
    ],
    "TargetSecuritySystemState": [                                                                                      //145
        {
            value: 0,
            label: 'Stay Arm',
        },
        {
            value: 1,
            label: 'Away Arm',

        },
        {
            value: 2,
            label: 'Night Arm',

        },
        {
            value: 3,
            label: 'Disarm',
        },
    ],
    "StatusJammed": [                                                                                                   //164
        {
            value: 0,
            label: 'Not Jammed',
        },
        {
            value: 1,
            label: 'Jammed',
        },
    ],
    "StatusTampered": [                                                                                                 //179
        {
            value: 0,
            label: 'Not Tampered',
        },
        {
            value: 1,
            label: 'Tampered',
        },
    ],
    "LeakDetected": [                                                                                                   //194
        {
            value: 0,
            label: 'Leak Not Detected',
        },
        {
            value: 1,
            label: 'Leak Detected',
        },
    ],
    "ContactState": [                                                                                                   //209
        {
            value: 0,
            label: 'Contact Detected',
        },
        {
            value: 1,
            label: 'Contact Not Detected',
        },
    ],
    "StatusFault": [                                                                                                    //224
        {
            value: 0,
            label: 'No Fault',
        },
        {
            value: 1,
            label: 'General Fault',
        },
    ],
    "CarbonMonoxideDetected": [                                                                                         //239
        {
            value: 0,
            label: 'CO Levels Normal',
        },
        {
            value: 1,
            label: 'CO Levels Abnormal',
        },
    ],
    "CarbonDioxideDetected": [                                                                                          //254
        {
            value: 0,
            label: 'CO2 Levels Normal',
        },
        {
            value: 1,
            label: 'CO2 Levels Abnormal',
        },
    ],
    "OccupancyDetected": [                                                                                              //269
        {
            value: 0,
            label: 'Occupancy Not Detected',
        },
        {
            value: 1,
            label: 'Occupancy Detected',
        },
    ],
    "SecuritySystemAlarmType": [                                                                                        //284
        {
            value: 0,
            label: 'No Alarm',
        },
        {
            value: 1,
            label: 'Unknown',
        },
    ],
    "CurrentAirPurifierState": [                                                                                        //300
        {
            value: 0,
            label: 'Inactive',
        },
        {
            value: 1,
            label: 'Idle',
        },
        {
            value: 2,
            label: 'Purifying Air',
        },
    ],
    "TargetAirPurifierState": [                                                                                         //317
        {
            value: 0,
            label: 'Manual',
        },
        {
            value: 1,
            label: 'Auto',
        },
    ],
    "CurrentSlatState": [                                                                                               //333
        {
            value: 0,
            label: 'Fixed',
        },
        {
            value: 1,
            label: 'Jammed',
        },
        {
            value: 2,
            label: 'Swinging',
        },
    ],
    "SlatType": [                                                                                                       //350
        {
            value: 0,
            label: 'Horizontal',
        },
        {
            value: 1,
            label: 'Vertical',
        },
    ],
    "FilterChangeIndication": [                                                                                         //365
        {
            value: 0,
            label: 'Filter OK',
        },
        {
            value: 1,
            label: 'Change Filter',
        },
    ],
    "LabelNamespace": [                                                                                                 //380
        {
            value: 0,
            label: 'Dots',
        },
        {
            value: 1,
            label: 'Arabic Numerals',
        },
    ],
    "ProgramMode": [                                                                                                    //396
        {
            value: 0,
            label: 'No Program Scheduled',
        },
        {
            value: 1,
            label: 'Program Scheduled',
        },
        {
            value: 2,
            label: 'Program Scheduled (Manual Mode)',
        },
    ],
    "InUse": [                                                                                                          //413
        {
            value: 0,
            label: 'Not In Use',
        },
        {
            value: 1,
            label: 'In Use',
        },
    ],
    "ValveType": [                                                                                                      //430
        {
            value: 0,
            label: 'Generic Valve',
        },
        {
            value: 1,
            label: 'Irrigation',
        },
        {
            value: 2,
            label: 'Shower Head',
        },
        {
            value: 3,
            label: 'Water Faucet',
        },
    ],
    "CurrentDoorState": [                                                                                               //452
        {
            value: 0,
            label: 'Open',
        },
        {
            value: 1,
            label: 'Closed',
        },
        {
            value: 2,
            label: 'Opening',
        },
        {
            value: 3,
            label: 'Closing',
        },
        {
            value: 4,
            label: 'Stopped',
        },
    ],
    "CurrentHeatingCooling": [                                                                                          //474
        {
            value: 0,
            label: 'Off',
        },
        {
            value: 1,
            label: 'Heat',
        },
        {
            value: 2,
            label: 'Cool',
        },
    ],
    "CurrentLockMechanismState": [                                                                                      //493
        {
            value: 0,
            label: 'Unsecured',
        },
        {
            value: 1,
            label: 'Secured',
        },
        {
            value: 2,
            label: 'Jammed',
        },
        {
            value: 3,
            label: 'Unknown',
        },
    ],
    "TargetLockMechanismState": [                                                                                       //512
        {
            value: 0,
            label: 'Unsecured',
        },
        {
            value: 1,
            label: 'Secured',
        },
    ],
    "RotationDirection": [                                                                                              //527
        {
            value: 0,
            label: 'Clockwise',
        },
        {
            value: 1,
            label: 'Counter-clockwise',
        },
    ],
    "TargetDoorState": [                                                                                                //542
        {
            value: 0,
            label: 'Open',
        },
        {
            value: 1,
            label: 'Closed',
        },
    ],
    "TargetHeatingCooling": [                                                                                           //559
        {
            value: 0,
            label: 'Off',
        },
        {
            value: 1,
            label: 'Heat',
        },
        {
            value: 2,
            label: 'Cool',
        },
        {
            value: 3,
            label: 'Auto',
        },
    ],
    "TemperatureUnits": [                                                                                               //578
        {
            value: 0,
            label: 'Celsius',
        },
        {
            value: 1,
            label: 'Fahrenheit',
        },
    ],
    "InputEvent": [                                                                                                     //594
        {
            value: 0,
            label: 'Single Press',
        },
        {
            value: 1,
            label: 'Double Press',
        },
        {
            value: 2,
            label: 'Long Press',
        },
    ],
    "SmokeDetected": [                                                                                                  //611
        {
            value: 0,
            label: 'Smoke Not Detected',
        },
        {
            value: 1,
            label: 'Smoke Detected',
        },
    ],
    "StatusLowBattery": [                                                                                               //626
        {
            value: 0,
            label: 'Battery Level Normal',
        },
        {
            value: 1,
            label: 'Battery Level Low',
        },
    ],
    "ChargingState": [                                                                                                  //642
        {
            value: 0,
            label: 'Not Charging',
        },
        {
            value: 1,
            label: 'Charging',
        },
        {
            value: 2,
            label: 'Not Chargeable',
        },
    ],
    "LockPhysicalControls": [                                                                                           //659
        {
            value: 0,
            label: 'Control Lock Disabled',
        },
        {
            value: 1,
            label: 'Control Lock Enabled',
        },
    ],
    "CurrentFanState": [                                                                                                //675
        {
            value: 0,
            label: 'Inactive',
        },
        {
            value: 1,
            label: 'Idle',
        },
        {
            value: 2,
            label: 'Blowing Air',
        },
    ],
    "Active": [                                                                                                         //692
        {
            value: 0,
            label: 'Inactive',
        },
        {
            value: 1,
            label: 'Active',
        },
    ],
    "CurrentHeaterCoolerState": [                                                                                       //709
        {
            value: 0,
            label: 'Inactive',
        },
        {
            value: 1,
            label: 'Idle',
        },
        {
            value: 2,
            label: 'Heating',
        },
        {
            value: 3,
            label: 'Cooling',
        },
    ],
    "TargetHeaterCoolerState": [                                                                                        //729
        {
            value: 0,
            label: 'Auto',
        },
        {
            value: 1,
            label: 'Heat',
        },
        {
            value: 2,
            label: 'Cool',
        },
    ],
    "CurrentHumidifierDehumidifierState": [                                                                             //748
        {
            value: 0,
            label: 'Inactive',
        },
        {
            value: 1,
            label: 'Idle',
        },
        {
            value: 2,
            label: 'Humidifying',
        },
        {
            value: 3,
            label: 'Dehumidifying',
        },
    ],
    "TargetHumidifierDehumidifierState": [                                                                              //768
        {
            value: 0,
            label: 'Humidifier or Dehumidifier',
        },
        {
            value: 1,
            label: 'Humidifier',
        },
        {
            value: 2,
            label: 'Dehumidifier',
        },
    ],
    "SwingMode": [                                                                                                      //785
        {
            value: 0,
            label: 'Swing Disabled',
        },
        {
            value: 1,
            label: 'Swing Enabled',
        },
    ],
    "TargetFanState": [                                                                                                 //800
        {
            value: 0,
            label: 'Manual',
        },
        {
            value: 1,
            label: 'Auto',
        },
    ],
    "IsConfigured": [                                                                                                   //815
        {
            value: 0,
            label: 'Not Configured',
        },
        {
            value: 1,
            label: 'Configured',
        },
    ],
}

export {
    controlSwitchTypes,
    controlNumberTypes,
    controlTextTypes,
    controlHueTypes,
    controlSliderTypes,
    controlMapPointType,
    controlMapPolygonType,
    controlImagePointType,
    controlImagePolygonType,
    controlPlanPolygonType,
    controlPlanPointType,
    controlImageType,
    controlChatType,
    controlFileType,
}
