import React, {useEffect, useState} from "react";
import {DialogContent, Grid, TextField} from "@material-ui/core";
import {asyncParallelLimit} from "../../../../Services/Utils";
import AccessLevelSelect from "../AccessLevelSelect/AccessLevelSelect";
import {AccessLevel, AccessType, OriginType} from "../../../../Services/ObjectsService/Constants";
import Actions from "../Actions/Actions";
import {getClientId, getUsers, getUserId} from "../../../../Services/UserService";
import {
    postObjectPermissionsInvite,
    saveObjectEncryptKeyForExistingUser
} from "../../../../Services/ObjectsService/CRUD";
import {useGlobalNotificationContext} from "../../../Notification/NotificationProvider";
import type {SnackBarType} from "../../../../Services/NotificationService";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import SendIcon from "@material-ui/icons/Send";
import AddIcon from "@material-ui/icons/Add";
import {
    Checkbox,
    FormControlLabel,
} from "@material-ui/core";
import {AddUsersProps} from "../ShareDialogTypes";
import ProgressComponent from "../../../Common/ProgressComponent/ProgressComponent";
import {Permissions} from "../../../../Services/ObjectsService/Types";
import {v4} from "uuid";
import AddPeopleDropdown from "../../../Common/AddPeopleDropdown/AddPeopleDropdown";

const AddUsers = (props: AddUsersProps) => {

    const {userData, userAccessLevel, objectItem, loader, accessType, addPeopleMode, listObjectId, setLoader, onClose, onClickAddPeople} = props;

    const notify: SnackBarType = useGlobalNotificationContext();

    const [sideAdding, setSideAdding] = useState("");
    const [addedEmails, setAddedEmails] = useState<string[]>([]);
    const [emailsInput, setEmailsInput] = useState("");
    const [accessLevel, setAccessLevel] = useState<AccessLevel>(AccessLevel.READ);
    const [notifyPeople, setNotifyPeople] = useState<boolean>(true);
    const [comment, setComment] = useState<string>("");
    const [saveDataMode, setSaveDataMode] = useState<boolean>(false);
    const [gettingUserDataState, setGettingUserDataState] = useState<"init" | "in_progress" | "completed">("init");
    const [savePermissionsAndSendInviteState, setSavePermissionsAndSendInviteState] = useState<"init" | "in_progress" | "completed">("init");
    const [encryptedCount, setEncryptedCount] = useState<number>(0);

    const onChangeAccessLevel = (value: AccessLevel) => {
        setAccessLevel(value);
    };

    const onCloseClicked = () => {
        onClose();
    };

    const onAgreeClicked = async () => {
        if (emailsInput) {
            setSideAdding(emailsInput);
            return;
        }

        if (!addedEmails.length) {
            return;
        }

        try {
            setSaveDataMode(true);

            setGettingUserDataState("in_progress");
            const users = await getUsers({email_list: addedEmails});

            const tempPermissions: Permissions = {
                perm_id: v4(),
                updated: Date.now(),
                private_acl: {}
            };

            for (const user of users || []) {
                if (!user?.id || !tempPermissions?.private_acl) {
                    continue;
                }
                tempPermissions.private_acl[user.id] = {
                    updated: Date.now(),
                    access_level: accessLevel
                }
            }

            setGettingUserDataState("completed");

            await asyncParallelLimit(listObjectId || [], 5, async (id) => {
                try {
                    await saveObjectEncryptKeyForExistingUser(id, tempPermissions, {origin: OriginType.USER});
                } catch (e) {
                    console.error(e);
                }
                setEncryptedCount((prev) => {
                    return prev + 1;
                });
                return;
            });

            setSavePermissionsAndSendInviteState("in_progress");
            const clientId = getClientId();

            await postObjectPermissionsInvite(
                objectItem.object_id,
                accessLevel,
                addedEmails,
                notifyPeople ? comment : "",
                listObjectId,
                {
                    origin: OriginType.USER,
                    clientId: clientId
                }
            );

            setSavePermissionsAndSendInviteState("completed");
        } catch (e: any) {
            notify?.errorNotify(e.message || e);
            setLoader(false);
        }

        onCloseClicked();
        return;

    };

    const onChangeAccessType = (value: AccessType) => {
        props.onChangeAccessType(value);
    }

    useEffect(()=>{
        setAccessLevel(userAccessLevel === AccessLevel.OWNER ? AccessLevel.WRITE : userAccessLevel);
    }, [userAccessLevel])

    if (saveDataMode) {
        return (<>
            {gettingUserDataState === "in_progress" && <ProgressComponent variant="query" label={"Getting users data..."} endAdornmentVariant="shareDialog"/>}
            {gettingUserDataState !== "in_progress" && <ProgressComponent variant="determinate" value={gettingUserDataState === "init" ? 0 : 100} max={100} label={"Getting users data..."} endAdornmentVariant="shareDialog"/>}
            <ProgressComponent variant="determinate" value={encryptedCount} max={listObjectId?.length} label={"Objects key encryption..."} endAdornmentVariant="shareDialog"/>
            {savePermissionsAndSendInviteState === "in_progress" && <ProgressComponent variant="query" label={"Save permissions data and send invite(s)..."} endAdornmentVariant="shareDialog"/>}
            {savePermissionsAndSendInviteState !== "in_progress" && <ProgressComponent variant="determinate" value={savePermissionsAndSendInviteState === "init" ? 0 : 100} max={100} label={"Save permissions data and send invite(s)..."} endAdornmentVariant="shareDialog"/>}
        </>);
    }

    if (accessType !== AccessType.RESTRICTED || loader || !addPeopleMode) {
        return null;
    }

    return (
        <>
            <DialogContent>
                <Grid container>
                    <Grid item sm={addedEmails.length ? 8 : 12} xs={12}>
                        <AddPeopleDropdown
                            sideAdding={sideAdding}
                            userData={userData}
                            addedEmails={addedEmails}
                            setAddedEmails={setAddedEmails}
                            emailsInput={emailsInput}
                            setEmailsInput={setEmailsInput}
                        />
                    </Grid>
                    <Grid item sm={addedEmails.length ? 4 : 12} xs={12}>
                        <AccessLevelSelect
                            accessLevel={accessLevel}
                            hide={!addedEmails.length}
                            userAccessLevel={userAccessLevel}
                            onChange={onChangeAccessLevel}
                        />
                    </Grid>
                </Grid>
                {!!addedEmails.length &&
                <Grid container>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox checked={notifyPeople} color="primary"
                                          onChange={() => setNotifyPeople(!notifyPeople)}/>
                            }
                            label="Notify people"
                        />
                    </Grid>
                    {notifyPeople ? (
                        <Grid item xs={12}>
                            <TextField
                                id="email_message"
                                label="Message"
                                multiline
                                rowsMax={6}
                                rows={4}
                                value={comment}
                                fullWidth={true}
                                onChange={(e) => setComment(e.target.value)}
                            />
                        </Grid>
                    ) : null}
                </Grid>}
            </DialogContent>
            <Actions
                disabled={loader}
                isOwner={objectItem.owner_id === getUserId()}
                onClose={onClickAddPeople}
                accessType={accessType}
                disagreeText="Back"
                agreeText={addedEmails.length ? "Send": "Add"}
                disagreeIcon={<ArrowBackIosIcon/>}
                agreeIcon={addedEmails.length ? <SendIcon/> : <AddIcon/>}
                onAgree={onAgreeClicked}
                onChangeAccessType={onChangeAccessType}
            />

        </>
    );
};

export default AddUsers;