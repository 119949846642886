import React, {useEffect, useRef, useState, useMemo} from "react";
import {useTheme} from "@material-ui/core";
import {DomEvent, LatLng, LatLngTuple} from "leaflet";
import {Polygon, Polyline} from "react-leaflet";
import {PointMarker, PointMarkerProps} from "./PointMarker";
import { Color } from "paper/dist/paper-core";
import Marker from "../../MarkerLabel/Marker";
import {mapMarkerFontSizes, mapMarkerIconSizes} from "../../MarkerLabel/MarkerUtils";
import {ObjectItem} from "../../../Services/ObjectsService/Types";
import {UserInfoType} from "../../../Hooks/useUserProfile";
import {getMarkerPointForPolygon} from "../../Objects/SiteMap/MapUtils";

type PolygonComponentProps = {
    bounds: LatLngTuple[];
    hideMarkers?: boolean;
    createMode?: boolean;
    isDraggablePolygon?: boolean;
    imageExists: boolean;
    color?: number;
    saveBounds(value: LatLngTuple[]): void;
    changeSelectedPoint?(index: number): void;
    selectObject?(): void;
    addPoint?(e: any, index: number): void;
    dragPolygon?(e: any): void;
    showMarker?: boolean;
    object?: ObjectItem;
    zoomed: number;
    userInfo: UserInfoType;
    handleClickOnMarker?(object_id: string): void;
    selected?: boolean;
    editMode?: boolean;
    map: any;
}

export const PolygonComponent = (props: PolygonComponentProps) => {
    const {
        bounds,
        hideMarkers,
        saveBounds,
        changeSelectedPoint,
        selectObject,
        addPoint,
        createMode,
        dragPolygon,
        isDraggablePolygon,
        imageExists,
        showMarker,
        object,
        zoomed,
        userInfo,
        handleClickOnMarker,
        selected,
        editMode,
        map,
        color
    } = props;

    const polygonRef = useRef<any>(null);
    const markersRef = useRef<any[]>([]);
    const theme = useTheme();
    const [positions, setPositions] = useState<LatLngTuple[]>(bounds || []);
    const zoneColor = useMemo(()=>{
        return  color? `${new Color(`hsl(${color}, 50%, 50%)`).toCSS(true)}` : `${theme.palette.primary[theme.palette.type]}`;
    }, [color, theme.palette.primary, theme.palette.type])
    // const [showPointLabels, setShowPointLabels] = useState<boolean>(false);
    // const [localHideMarkers, setLocalHideMarkers] = useState<boolean>(!!hideMarkers);

    const showLabelForPoints = () => {
        // setShowPointLabels((prev) => {
        //     return !prev;
        // });
        if (selectObject) {
            selectObject();
        }
    };

    const setSelectedPositionValue = (index: number, latlng: LatLng) => {
        const polygonLatLngs = positions;
        if (!Array.isArray(polygonLatLngs) || !polygonLatLngs[index]) return;

        setPositions((prev)=>{
            polygonLatLngs[index] = [latlng.lat, latlng.lng];
            polygonRef.current.leafletElement.setLatLngs(polygonLatLngs);
            return prev;
        })
    };

    const changeSelectedPosition = (index: number) => {
        if (changeSelectedPoint) {
            changeSelectedPoint(index);
        }
    };

    const saveSelectedValue = (index: number) => {
        const polygonLatLngs = getPolygonLatLngs();

        if (Array.isArray(polygonLatLngs) && Array.isArray(polygonLatLngs[0])) {
            const newBounds = polygonLatLngs[0].map((latLng: LatLng) => {
                return [latLng.lat, latLng.lng] as LatLngTuple;
            });

            saveBounds(newBounds);
            if (changeSelectedPoint) {
                changeSelectedPoint(index);
            }
        }
    };

    const getPolygonLatLngs = () => {
        if (!polygonRef || !polygonRef.current) {
            return null;
        }

        return polygonRef.current.leafletElement.getLatLngs();
    };

    const onDrag = (e:any) => {
        let points = e.target.getLatLngs();
        setPositions(points[0].map((point: LatLng) => [point.lat, point.lng]))
    }

    useEffect(() => setPositions(bounds), [bounds]);

    // useEffect(() => {
    //     setLocalHideMarkers(!!hideMarkers);
    // }, [hideMarkers]);

    if (isDraggablePolygon) {
        polygonRef?.current?.leafletElement.dragging.enable();
    } else {
        polygonRef?.current?.leafletElement.dragging.disable();
    }

    const markerPoint = getMarkerPointForPolygon(positions);
    const markerLayerPoint = markerPoint && map ? map.latLngToContainerPoint(markerPoint) : null;

    const _showMarker = showMarker && markerLayerPoint && object && (object.object_type !== "CameraMapZone" || selected || editMode || !imageExists);

    return (
        <React.Fragment>
            {_showMarker && object && (
                <Marker
                    object={object}
                    object_id={object.object_id}
                    object_name={object.object_name}
                    object_type={object.object_type}
                    object_icon={object.icon}
                    centerPointX={Math.round(markerLayerPoint.x)}
                    centerPointY={Math.round(markerLayerPoint.y)}
                    fontSize={mapMarkerFontSizes[zoomed]}
                    iconSize={mapMarkerIconSizes[zoomed]}
                    selected={!!selected}
                    showData={zoomed >= 17}
                    userInfo={userInfo}
                    visibilityType="map"
                    handleClickOnMarker={handleClickOnMarker}
                />
            )}
            <Polygon
                positions={positions}
                color={zoneColor}
                weight={!hideMarkers ? 1 : 0}
                bubblingMouseEvents={false}
                onClick={showLabelForPoints}
                fillColor={zoneColor}
                fillOpacity={!imageExists ? 0.5 : 0.01}
                ref={polygonRef}
                onDragEnd={dragPolygon}
                onDrag={onDrag}
            />
            {addPoint && !hideMarkers
                ? positions.map((point, index) => {
                    let edgePolygon = positions.slice(index, index + 2);
                    if (edgePolygon.length === 1) {
                        edgePolygon.push(positions[0]);
                    }
                    return (
                        <Polyline
                            positions={edgePolygon}
                            weight={1}
                            color={zoneColor}
                            onMouseDown={isDraggablePolygon ? (e: any) => {
                                DomEvent.preventDefault(e);
                                addPoint(e, index);
                                changeSelectedPosition(index + 1);
                                const _onMouseMove = (event:any) => {
                                    DomEvent.preventDefault(event);
                                    setSelectedPositionValue(index + 1, event.latlng);
                                    markersRef.current[index + 1].leafletElement.setLatLng(event.latlng);
                                }
                                e.sourceTarget.options.leaflet.map.dragging.disable();
                                e.sourceTarget.options.leaflet.map.on("mousemove", _onMouseMove);
                                e.sourceTarget.options.leaflet.map.once("mouseup", (event:any) => {
                                    DomEvent.preventDefault(event);
                                    e.sourceTarget.options.leaflet.map.off("mousemove", _onMouseMove);
                                    e.sourceTarget.options.leaflet.map.dragging.enable();
                                    saveSelectedValue(index + 1);
                                });
                            } : null}
                            bubblingMouseEvents={false}
                            key={point[0] + "_point_" + index}
                        />
                    );
                })
                : null}
            {!hideMarkers &&
            positions.map((item: LatLngTuple, index: number) => {
                const getRef = (element:PointMarkerProps) => (markersRef.current.splice(index, 1, element))
                return (
                    <PointMarker
                        key={`marker_${index}`}
                        position={item}
                        index={index}
                        onClick={changeSelectedPosition}
                        onDrag={setSelectedPositionValue}
                        onDragEnd={saveSelectedValue.bind(null, index)}
                        isDraggable={createMode || (!createMode && !!isDraggablePolygon)}
                        ref={getRef}
                    />
                );
            })}
        </React.Fragment>
    );
};
