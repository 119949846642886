import React from "react";
import { PropertyItem } from "../../../Services/ObjectsService";
import { filterObjectProperties } from "../../../Services/Utils";
import { ListPropertiesOnCardProps } from "./TypesCard";
import CardContentProperty from "./ObjectCard/CardContentItem/CardContentProperty";
import Skeleton from "@material-ui/lab/Skeleton";

export default function ListPropertiesOnCard(props: ListPropertiesOnCardProps) {
    const {properties, object, isDisabled, userInfo, filterVisibility} = props;

    if (!object?.object_id) {
        return (
            <>
                <Skeleton animation="wave" height={15} width="100%"/>
                <Skeleton animation="wave" height={15} width="65%"/>
            </>
        )
    }

    if (!object?.encryption_key) {
        return null;
    }

    return (
        <>
            {Object.values(properties || {})
                .sort(filterObjectProperties)
                .map((property: PropertyItem, index: number) => {
                    return property.visibility?.includes(filterVisibility) ? (
                        <CardContentProperty
                            key={`${property.property_id}_${index}`}
                            property={property}
                            object={object}
                            isDisabled={isDisabled}
                            userInfo={userInfo}
                            isMarker={props.isMarker}
                        />
                    ) : null;
                })}
        </>
    );
}
