import React, {ErrorInfo} from "react";
import ErrorBoundaryComponent from "./ErrorBoundaryComponent/ErrorBoundaryComponent";
import type { SystemErrorType } from "../../../Services/SupportService";

class ErrorBoundary extends React.Component<any, SystemErrorType> {
    constructor(props: any) {
        super(props);
        this.state = {
            error: null,
            errorInfo: null,
            errorDate: null,
        };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo,
            errorDate: Date.now()
        })
        // You can also log error messages to an error reporting service here
    }

    render() {
        if (this.state.errorInfo) {
            // Error path
            return (
                <ErrorBoundaryComponent
                    error={this.state.error}
                    errorInfo={this.state.errorInfo}
                    errorDate={this.state.errorDate}
                />
            );
        }
        // Normally, just render children
        return this.props.children;
    }
}

export default ErrorBoundary;
