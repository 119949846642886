import {useEffect, useState} from "react";
import {v4} from "uuid";
import {
    isSignedIn,
    signInAsGuest,
    signOut as deleteCookie
} from "../Services/AuthenticationService";
import {socketReconnect} from "../Services/MqttService";
import {
    deleteUserDeviceToken,
    getClientInfo,
    getProfile,
    getUserId,
    getUserInfo,
    isGuest,
    postUserClientById,
    postUserStatus,
    removeUserId,
    setUserId,
    setUserInfo,
    setGuestUser,
    PROFILE_FIELDS_TO_STORE
} from "../Services/UserService";
import type {ClientItem, UserItem} from "../Services/UserService";
import {currentUserChanged, isDevice, requestDeviceToken, signOutUser} from "../Services/DeviceService";
import {useSubIAPUserStatus} from "./Subscriptions/useSubIAPUserStatus";
import {useGlobalLoaderActionsContext} from "../GlobalContext/GlobalContext";
import {useGlobalNotificationContext} from "../Components/Notification/NotificationProvider";
import type {SnackBarType} from "../Services/NotificationService";
import {useHistory, useLocation} from "react-router-dom";
import {deregisterObjects, processKeepAlive, registerObjects} from "../Services/ObjectsService";
import useRecursiveTimeout from "./useRecursiveTimeout";
import { cleanSystemStates } from "../Services/SystemStatesService";

const DEFAULT_PAGE_LINK: string = process.env.REACT_APP_DEFAULT_PAGE_LINK || "";

export type UserInfoType = {
    user: UserItem | null;
    clientId: null | string;
    checkAuthToken(): boolean;
    setUser(value: any, flag?: boolean): void;
    updateClients(value: ClientItem[]): void;
    signOut(): void;
    firstShowWelcomeDialog: boolean;
};

export const useUserProfile = () => {

    const setLoader = useGlobalLoaderActionsContext();
    const notify: SnackBarType = useGlobalNotificationContext();
    const history = useHistory();
    const location = useLocation();

    const setUser = async (user: any, isLogin?: boolean) => {
        try {
            if (!user) {
                return;
            }

            if (!user.gender) {
                user.gender = "other";
            }

            const userInfo = getUserInfo(user.id);

            if (!userInfo.client_id) {
                userInfo.client_id = v4();
            }

            user.clients = user.clients || [];

            userInfo.clients = user.clients;

            let clientIndex = userInfo.clients.findIndex((_client: ClientItem) => _client.client_id === userInfo.client_id);
            const clientInfo = getClientInfo(userInfo.client_id);
            let _new_client = false;
            if (clientIndex === -1) {
                clientIndex = userInfo.clients.push(clientInfo) - 1;
                _new_client = true;
            } else {
                userInfo.clients[clientIndex] = {...userInfo.clients[clientIndex], ...clientInfo};
            }

            delete user.encryption_keys;

            PROFILE_FIELDS_TO_STORE.forEach((user_field) => {
                if (user.hasOwnProperty(user_field)) {
                    userInfo[user_field] = user[user_field];
                }
            });

            if (_new_client) {
                await postUserClientById(userInfo.clients[clientIndex], isLogin);
            } else {
                postUserClientById(userInfo.clients[clientIndex], isLogin).then(() => {}).catch((e) => { console.error(e); });
            }

            setUserInfo(user.id, userInfo);
            setUserId(user.id);

            setUserProfile((curState: any) => {
                return {
                    ...curState,
                    user: user,
                    clientId: userInfo.client_id,
                };
            });
        } catch (e) {
            console.error(e);
        }
    };

    const updateClients = (clients: any) => {
        setUserProfile((curState: any) => {
            let user = curState.user;
            user.clients = clients;

            const userInfo = getUserInfo(user.id);
            userInfo.clients = clients;
            setUserInfo(user.id, userInfo);

            return {
                ...curState,
                user: user,
            };
        });
    };

    const signOut = async () => {
        try {
            if (isDevice()) {
                await deleteUserDeviceToken();
            }

            setUserProfile((curState: any) => {
                return {
                    ...curState,
                    user: null,
                    clientId: null,
                };
            });

            signOutUser();
            removeUserId();
            deleteCookie();
            cleanSystemStates();

            await socketReconnect();

            await configureGuestUser();
        } catch (e) {
            console.error(e);
        }
    };

    const [userProfile, setUserProfile] = useState<UserInfoType>({
        user: null,
        clientId: null,
        checkAuthToken: isSignedIn,
        setUser: setUser,
        updateClients: updateClients,
        signOut: signOut,
        firstShowWelcomeDialog: true
    });

    const configureUser = async () => {
        if (isSignedIn() && getUserId()) {
            try {
                setLoader(true);
                const user = await getProfile();
                await setUser(user);
                currentUserChanged();

            } catch (e: any) {
                console.log(e);
                notify.errorNotify(e?.message || JSON.stringify(e));
            } finally {
                setLoader(false);
            }
        }

        //  configure guest user
        await configureGuestUser();
    };

    const configureGuestUser = async () => {
        if ((getUserId() && isSignedIn()) || (isGuest() && getUserId() && isSignedIn())) {
            return;
        }

        setLoader(true);

        try {
            const res = await signInAsGuest();

            setGuestUser(res.user);

            await setUser(res.user);

            notify.successNotify("You are logged in as a Guest User");
        } catch (e) {
            console.error(e);
        } finally {
            setLoader(false);
        }

        if (!location.pathname.includes("create-password")) {
            history.replace(DEFAULT_PAGE_LINK);
        }
    };

    const postStatus = async () => {
        if (!userProfile?.checkAuthToken() || !userProfile?.clientId) {
            return;
        }

        try {
            await postUserStatus(userProfile?.clientId);
            await processKeepAlive();
        } catch (error) {
            console.error(error);
            // notify.errorNotify(error?.message || JSON.stringify(error));
        }
    };

    useSubIAPUserStatus((userProfile?.user as any)?.id);

    useEffect(() => {
        void configureUser();

        window.addEventListener('signOut', async () => {
            await userProfile.signOut();
        }, {passive: false});
    }, []);

    useEffect(() => {
        deregisterObjects();

        if (!userProfile.user?.id) {
            return;
        }

        requestDeviceToken();
        (async () => {
            await registerObjects();
            await socketReconnect(true);
            await postStatus();
        })();
    }, [userProfile.user?.id]);

    useRecursiveTimeout(postStatus, 20000);

    return userProfile;
};
