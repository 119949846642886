import { getSystemState, setSystemState, STATE_TYPE } from "Services/SystemStatesService";
import { ObjectItem } from "../Services/ObjectsService/Types";
import { getObjects } from "../Services/ObjectsService/CRUD";
import { OBJECT_TYPE } from "../Services/ObjectsService/Constants";
import { convertArrayToObject } from "../Services/Utils";

const getOrganization = () => {
    return getSystemState(STATE_TYPE.ORGANIZATION);
}

const setOrganization = (org: ObjectItem | null) => {
    setSystemState(STATE_TYPE.ORGANIZATION, org);
}

const getOrgHierarchy = async () => {
    const objects_arr: ObjectItem[] = await getObjects({}); // TODO check Unknown?
    const objects_dict : { [key: string]: ObjectItem } = convertArrayToObject(objects_arr, 'object_id');

    let arr: string[] = [];
    let rev_arr: string[] = [];
    let org_ids: string[] = [];

    const org_id = getOrganization()?.object_id;

    if (org_id && !objects_dict[org_id]) {
        setOrganization(null);
        return [];
    }

    if (org_id) {
        org_ids.push(org_id);
    } else {
        objects_arr.forEach((obj_item) => {
            if (obj_item.object_type === OBJECT_TYPE.ORGANIZATION) {
                org_ids.push(obj_item.object_id);
            }
        });
    }

    const extractMachinesFromNode = async (obj: ObjectItem) => {
        for (const child of obj.children || []) {
            if (!arr.includes(child) && objects_dict[child]) {
                arr.push(child);
                await extractMachinesFromNode(objects_dict[child]);
            }
        }
    };

    for (const org_id of org_ids) {
        await extractMachinesFromNode(objects_dict[org_id]);
    }

    if (!org_id) {
        rev_arr = objects_arr
            .map((obj_item) => { return obj_item.object_id; })
            .filter((obj_id) => { return !arr.includes(obj_id); });

        return rev_arr;
    }

    return arr;
};

export {
    getOrganization,
    setOrganization,
    getOrgHierarchy
}
