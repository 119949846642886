import { RESOLUTIONS } from "../Constants";

export const FEATURES_BY_PLAN: { [key: string]: string[] } = {
    free: [
        "HomeKit Accessories Support",
        "IP Cameras Support (1 streaming camera per the client device)",
        "Floor Plan Editor (up to 50 elements)",
        "Objects/Zones placement on the map",
        "Objects/Zones placement on the floor plan",
        "Objects history (last 24 hours)",
        "Objects sharing (with up to 5 users)",
        "Cameras resolution programmatically reduced to 480p",
        "Camera images upload to the cloud (4 frames/minute)",
        "Sync with backend (150,000 updates per month)"
    ],
    pro: [
        "Objects History. Unlimited",
        "Objects Sharing. Unlimited",
        "Floor Plan Editor. Unlimited elements",
        "IP Cameras Support. Number is limited by the performance of the client device",
        "Selectable camera resolution (480p, 720p, 1080p)",
        "Camera images upload to the cloud (1 frame/second)",
        "Sync with backend (2,500,000 updates per month)"
    ]
};

export const CONSTRAINTS_BY_PLAN: { [key: string]: { [key: string]: string | number } } = {
    // free: {
    //     maxResolution: RESOLUTIONS.SD, // [IM 15/06/2022] temporary increased to Pro level
    //     maxNumberOfActiveIPCameras: 1
    // },
    free: {
        maxResolution: RESOLUTIONS.FULL_HD,
        maxNumberOfActiveIPCameras: 0
    },
    pro: {
        maxResolution: RESOLUTIONS.FULL_HD,
        maxNumberOfActiveIPCameras: 0
    }
}

export const PRODUCTS_BY_PLAN: { [key: string]: string[] } = {
    free: ["free"],
    pro: ["io.localmachines.app.sub.1monthpro"]
}

export const API_LIMIT = {
    // free: 300000, // [IM 15/06/2022] temporary increased to Pro level
    free: 5000000,
    pro: 5000000
};

export const PLAN_LIMIT = {
    // free: 50     // [IM 15/06/2022] temporary increased to Pro level
    free: 500
};

export const SHARING_LIMIT = {
    // free: 5      // [IM 15/06/2022] temporary increased to Pro level
    free: 100
};

export const CAMERA_STREAM_TIMEOUT = {
    // free: 15000, // [IM 15/06/2022] temporary equal to Pro level
    free: 1000,
    pro: 1000
};
