import React, {Fragment, useCallback, useEffect, useState, useMemo} from "react";
import {ObjectItem} from "../../../../Services/ObjectsService/Types";
import {PLAN_RELATED_TYPES} from "../../../../Services/ObjectsService/Constants";
import {PlanViewController} from "../../PlanViewController";
import {PlanEditController} from "../../PlanEditController";
import {UserInfoType} from "../../../../Hooks/useUserProfile";
import {CanvasOffsetProps} from "../../TypesPlanView";
import {ViewPoint} from "../../../MarkerLabel/MarkerTypes";
import useForceUpdate from "../../../../Hooks/ForceUpdate/useForseUpdate";
import {makeStyles} from "@material-ui/core/styles";
import Draggable from "react-draggable";
import Marker from "../../../MarkerLabel/Marker";
import {planMarkerFontSizes, planMarkerIconSizes} from "../../../MarkerLabel/MarkerUtils";
import useUpdateCameraImage from "../../../../Hooks/UpdateCameraImage/useUpdateCameraImage";

type PlanPointChildProps = {
    object_id: string
    object_type: string
    object_name: string
    object_icon: string
    object_item?: ObjectItem
    centerPointX: number
    centerPointY: number
    planItem: PlanViewController | PlanEditController | null
    canvasOffset: CanvasOffsetProps
    planZoom: number
    optimizedZoom: number
    userInfo: UserInfoType
    editMode: boolean
    handleUpdateSelectedElementId(element_id: string | null): void
    openDetails?(): void;
}

const useStyles = makeStyles({
    markerSelected: {
        zIndex: 501,
        position: "absolute"
    }
});

const visibilityType = "plan";

const PlanPointChild = (props: PlanPointChildProps) => {

    const {
        object_id,
        object_type,
        object_name,
        object_icon,
        object_item,
        centerPointX,
        centerPointY,
        planItem,
        canvasOffset,
        planZoom,
        optimizedZoom,
        userInfo,
        editMode,
        handleUpdateSelectedElementId,
        openDetails
    } = props

    const selected = object_id === planItem?.getSelectedElement()?.getId()
    const draggable = editMode && !PLAN_RELATED_TYPES.includes(object_type) && selected;

    const forceUpdate = useForceUpdate();

    const [draggableCenterPointX, setDraggableCenterPointX] = useState<number>(centerPointX);
    const [draggableCenterPointY, setDraggableCenterPointY] = useState<number>(centerPointY);

    // styles
    const classes = useStyles()

    const handleClickOnMarker = useCallback((object_id: string) => {
        if (planItem) {
            if (selected && openDetails) {
                openDetails();
            }

            planItem?.setElementSelected(object_id)
            handleUpdateSelectedElementId(object_id)
            forceUpdate()
        }
    }, [planItem, selected])

    // Move Marker
    let startX = 0, startY = 0;

    const updatePositionOfSelectedMarker = (point: ViewPoint) => {
        const element = planItem?.getSelectedElement()
        if (element) {
            const last_plan_point = element.getProperty("plan_point")
            element.setProperty("plan_point", [last_plan_point[0] - point.x / planZoom, last_plan_point[1] - point.y / planZoom])
            setDraggableCenterPointX(last_plan_point[0] - point.x / planZoom);
            setDraggableCenterPointY(last_plan_point[1] - point.y / planZoom);
        }
    }

    const handleMarkerDragOnStart = (e: any) => {
        if (e.type === "touchstart") {
            startX = e.changedTouches[0].clientX
            startY = e.changedTouches[0].clientY
        } else {
            startX = e.clientX
            startY = e.clientY
        }

    }

    const handleMarkerDragOnStop = (e: any) => {
        let {clientX, clientY} = e
        if (e.type === "touchend") {
            clientX = e.changedTouches[0].clientX
            clientY = e.changedTouches[0].clientY
        }

        const endX = startX - clientX;
        const endY = startY - clientY;

        if (endX !== 0 || endY !== 0) {
            updatePositionOfSelectedMarker({x: endX, y: endY});
        }

        planItem?.setElementSelected(null)

        forceUpdate()
    }
    // End Move Marker

    useEffect(() => {
        setDraggableCenterPointX(centerPointX);
        setDraggableCenterPointY(centerPointY);
        forceUpdate();
    }, [centerPointX, centerPointY]);

    useUpdateCameraImage(object_id, object_type);

    const correctedCenterPointX = useMemo(() => { return Math.round((draggableCenterPointX - canvasOffset.x) * planZoom); }, [draggableCenterPointX, canvasOffset.x, planZoom]);
    const correctedCenterPointY = useMemo(() => { return Math.round((draggableCenterPointY - canvasOffset.y) * planZoom); }, [draggableCenterPointY, canvasOffset.y, planZoom]);

    return (
        <Fragment
            key={object_id}
        >
            {draggable ? (
                <Draggable
                    onStart={(e: any) => handleMarkerDragOnStart(e)}
                    onStop={(e: any) => handleMarkerDragOnStop(e)}
                >
                    <div
                        className={classes.markerSelected}
                    >
                        <Marker
                            object={object_item}
                            object_id={object_id}
                            object_name={object_name}
                            object_type={object_type}
                            object_icon={object_icon}
                            centerPointX={correctedCenterPointX}
                            centerPointY={correctedCenterPointY}
                            fontSize={planMarkerFontSizes[optimizedZoom]}
                            iconSize={planMarkerIconSizes[optimizedZoom]}
                            selected={selected}
                            showData={planZoom >= 1}
                            userInfo={userInfo}
                            visibilityType={visibilityType}
                            handleClickOnMarker={handleClickOnMarker}
                        />
                    </div>
                </Draggable>
            ) : (
                <Marker
                    object={object_item}
                    object_id={object_id}
                    object_name={object_name}
                    object_type={object_type}
                    object_icon={object_icon}
                    centerPointX={correctedCenterPointX}
                    centerPointY={correctedCenterPointY}
                    fontSize={planMarkerFontSizes[optimizedZoom]}
                    iconSize={planMarkerIconSizes[optimizedZoom]}
                    selected={selected}
                    showData={planZoom >= 1}
                    userInfo={userInfo}
                    visibilityType={visibilityType}
                    handleClickOnMarker={handleClickOnMarker}
                />
            )}
        </Fragment>
    )
};

export default PlanPointChild;
