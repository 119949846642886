import {useSubObjectStatus} from "../../../../Hooks/Subscriptions/useSubObjectStatus";
import {TreeItem, TreeItemProps} from "@material-ui/lab";
import Status from "../../../Common/Status/Status";
import React, {useEffect, useMemo, useState} from "react";
import {PropertyItem} from "../../../../Services/ObjectsService/Types";
import {getPrimeProperty} from "../../../Common/PropertiesPriority";
import Property from "../../../Objects/Property/Property";
import {UserInfoType} from "../../../../Hooks/useUserProfile";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {HMCharacteristicValues} from "../../../Objects/Property/PropertyControls/PropertyControlsTypes";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";

const useTreeItemStyles = makeStyles((theme: Theme) =>
    createStyles({
        labelRoot: {
            padding: theme.spacing(0.5, 0),
        },
        reachable: {
            paddingLeft: theme.spacing(1),
        },
        labelWrapper: {
            flexWrap: 'nowrap!important' as 'nowrap'
        },
        labelText: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        mediaWrapper: {
            width: '50%'
        },
        mediaContent: {
            height: '100%'
        }
    }),
);
type StyledTreeItemProps = TreeItemProps & {
    object_id: string
    labelText: string
    labelIcon: string
    properties: { [string: string]: any },
    displayOptions?: string[];
    userInfo: UserInfoType;
    lastActivityEpoch?: number | null
    objectDeviceName: string
    selectObjectHandler(object_id: string): void
};

const ObjectTreeItem = (props: StyledTreeItemProps) => {

    const {
        object_id,
        labelText,
        labelIcon,
        properties,
        displayOptions,
        userInfo,
        lastActivityEpoch,
        objectDeviceName,
        selectObjectHandler,

        nodeId,
        children
    } = props;

    const classes = useTreeItemStyles();

    const {lastActive, isOffline} = useSubObjectStatus(object_id, lastActivityEpoch, objectDeviceName);
    const [primeProperty, setPrimeProperty] = useState<PropertyItem | undefined>();

    const iconLabel = useMemo(() => {
        if (primeProperty) {
            return <Property
                property={primeProperty as PropertyItem}
                userInfo={userInfo}
                variant={HMCharacteristicValues.hasOwnProperty(primeProperty.type) ? "string" : "marker"} // TODO: [IM] workaround for building tree in Dialog
            />
        } else {
            return null
        }
    }, [primeProperty, userInfo]);

    const selectObject = (event: any) => {
        event.preventDefault();
        selectObjectHandler(object_id);
    }
    const iconAwesomeClass = labelIcon || "fas fa-info-circle"

    useEffect(() => {
        if (Object.values(properties).length > 0) {
            const property = getPrimeProperty(Object.values(properties), "map")
            setPrimeProperty(
                property ? Object.values(properties).find(
                    (el: PropertyItem) => el.property_id === property.property_id) : undefined
            )
        }
    }, [properties])

    return (
        <TreeItem
            label={
                <Grid
                    container
                    direction="column"
                    className={classes.labelRoot}
                    onClick={selectObject}
                >
                    <Grid
                        item
                        container
                        direction="row"
                        className={classes.labelWrapper}
                    >
                        <Grid item className={classes.labelText}>
                            <Typography className={classes.labelText} title={labelText}>
                                <i className={iconAwesomeClass}/> {iconLabel} {labelText}
                            </Typography>
                        </Grid>
                        {lastActive && (
                            <Grid item>
                                <Typography className={classes.reachable}>
                                    <Status lastActivityEpoch={lastActive} isOffline={isOffline}/>
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                    {displayOptions?.includes("image") && properties?.image?.value && (
                        <Grid item className={classes.mediaWrapper}>
                            <CardMedia component="img" className={classes.mediaContent} image={properties?.image?.value}/>
                        </Grid>
                    )}
                </Grid>
            }
            nodeId={nodeId}
            children={children}
        />
    )
};

export default ObjectTreeItem
