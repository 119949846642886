import React from "react";
import {Link, useLocation} from "react-router-dom";
import {Checkbox, FormControlLabel, Icon, IconButton, Menu, MenuItem, Typography} from "@material-ui/core";
import {makeStyles, createStyles} from "@material-ui/core/styles";
import {pubPublicObjectsFilterEvent, pubOwnObjectsFilterEvent} from "../../../Services/SystemEventsService";

const useStyles = makeStyles(() =>
    createStyles({
        title: {
            flexGrow: 1,
        },
    })
);

export default function IAPNavigationBar() {
    const location = useLocation();
    const classes = useStyles();

    const [filterAnchorEl, setFilterAnchorEl] = React.useState<null | HTMLElement>(null);
    const [filterSettings, setFilterSettings] = React.useState({isPublic: true, isOwn: true});

    const filterButtonClicked = (event: any) => {
        setFilterAnchorEl(event.currentTarget);
    };

    const closeMenu = () => {
        setFilterAnchorEl(null);
    };

    const stickToPublicCheckboxClicked = (event: any) => {
        pubPublicObjectsFilterEvent(!!event.target.checked);
        setFilterSettings((prev)=> ({...prev, isPublic: !!event.target.checked}));
        closeMenu();
    };

    const stickToOwnCheckboxClicked = (event: any) => {
        pubOwnObjectsFilterEvent(!!event.target.checked);
        setFilterSettings((prev)=> ({...prev, isOwn: !!event.target.checked}));
        closeMenu();
    };

    return (
        <React.Fragment>
            <IconButton
                color="inherit"
                onClick={filterButtonClicked}
                title={"Filter objects"}
            >
                <Icon className="fa fa-filter" fontSize="small"/>
            </IconButton>
            <Menu
                id="floor-plan-settings-menu"
                anchorEl={filterAnchorEl}
                keepMounted
                open={Boolean(filterAnchorEl)}
                onClose={closeMenu}
            >
                <MenuItem>
                    <FormControlLabel
                        control={<Checkbox checked={filterSettings.isPublic} onChange={stickToPublicCheckboxClicked} color="primary"/>}
                        label="Public Objects"
                    />
                </MenuItem>
                <MenuItem>
                    <FormControlLabel
                        control={<Checkbox checked={filterSettings.isOwn} onChange={stickToOwnCheckboxClicked} color="primary"/>}
                        label="Own Objects"
                    />
                </MenuItem>
            </Menu>
            <Typography variant="h6" noWrap className={classes.title}>
                Public
            </Typography>
            <IconButton color="inherit" component={Link} to={`${location.pathname}/edit`}>
                <Icon className={"fa fa-pencil-alt"} fontSize="small"/>
            </IconButton>
        </React.Fragment>
    );
}
