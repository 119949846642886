import React from "react";
import {Marker, /*Tooltip*/} from "react-leaflet";
import {divIcon, LatLng, LatLngTuple} from "leaflet";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

export type PointMarkerProps = {
    position: LatLngTuple;
    index: number;
    isDraggable: boolean;
    onClick(index: number): void;
    onDrag(index: number, value: LatLng): void;
    onDragEnd(index: number): void;
};


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        divIcon: ({isDraggable}: {isDraggable:boolean}) => ({
            "& > * > svg": {
                fontSize: 35,
                top: -16,
                left: -17,
                position: "absolute",
                color: "blue",
                stroke: "black",
                strokeWidth: 15,
                "&:hover": {
                    color: !isDraggable ? "blue" : "white",
                }
            },
            "& > * > span": {
                position: "absolute",
                top: !isDraggable ? -35 : -43,
                left: -10,
                fontSize: 13,
                color: "black",
                // fontWeight: "bold",
                backgroundColor: "white",
                opacity: 0.5,
                border: "solid 1px black",
                borderRadius: 6,
                padding: 3,
            },
            "&:hover > * > span": {
                opacity: !isDraggable ? 0.5 : 1,
            },
        }),
    })
);


export const PointMarker = React.forwardRef<any, PointMarkerProps>((props: PointMarkerProps, ref) => {
    const classes = useStyles({isDraggable: props.isDraggable});

    // const markerRef = useRef<any>(null);
    // const [index, setIndex] = useState<number>(props.index);
    const {index, position, onDrag, onClick, onDragEnd, isDraggable} = props;

    // const dragEventHandler = (e: any) => {
    //     onDrag(e.target.getLatLng());
    // };

    // useEffect(() => {
    //     if (markerRef.current) {
    //         if (showLabel) {
    //             console.log(markerRef.current.leafletElement);
    //             markerRef.current.leafletElement.openTooltip(position);
    //         } else {
    //             markerRef.current.leafletElement.closeTooltip();
    //         }
    //     }
    // }, [showLabel]);

    // useEffect(() => {
    //     setIndex(props.index);
    // }, [props.index]);

    return (
        <Marker
            position={position}
            draggable={isDraggable}
            onDrag={(e:any)=>onDrag(index, e.target.getLatLng())}
            onDragEnd={onDragEnd.bind(null, index)}
            onClick={onClick.bind(null, index)}
            icon={divIcon({
                html: `<div><span>p${index}</span>'${isDraggable ? '<i class="fas fa-crosshairs"/>' : '<i class="far fa-square" style="font-size: 16px;top: -9px; left: -6px">'}</div>`,
                iconSize: [1, 1],
                className: classes.divIcon
            })}
            ref={ref}
        >
            {/*<Tooltip>{`p${index}`}</Tooltip>*/}
        </Marker>
    );
});