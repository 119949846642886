import React, {useCallback, useMemo} from "react";
import {Theme, withStyles} from "@material-ui/core/styles";
import MuiDialogContent from "@material-ui/core/DialogContent";
import {useDialogStyles} from "../DialogStyles";
import {TabContext, TabPanel} from "@material-ui/lab";
import {Slide1} from "./Slides/Slide1";
import {Slide2} from "./Slides/Slide2";
import {Slide3} from "./Slides/Slide3";
// import {Slide4} from "./Slides/Slide4";
// import {Slide5} from "./Slides/Slide5";
import {Slide6} from "./Slides/Slide6";
import {Slide7} from "./Slides/Slide7";
import {Slide8} from "./Slides/Slide8";
import {Slide9} from "./Slides/Slide9";
import {Slide10} from "./Slides/Slide10";
import {Slide11} from "./Slides/Slide11";
import {Slide12} from "./Slides/Slide12";


const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(4),
        paddingTop: theme.spacing(6),
    },
}))(MuiDialogContent);

export type SlideType = {
    xsSize: boolean;
    handlePageChange(e: any): void;
}

type WelcomeDialogContentProps = {
    slidePage: number;
    xsBreakpoint: boolean;
    handlePageChange(event: React.ChangeEvent<unknown>, value: number): void;
}

const WelcomeDialogContent = (props: WelcomeDialogContentProps) => {
    const {slidePage, xsBreakpoint, handlePageChange} = props;

    const classes = useDialogStyles();

    const handlePageChangeOnSlide = useCallback((event: any) => {
        handlePageChange(event, slidePage + 1)
    }, [slidePage])

    const slides = useMemo(() => {
        return [
            <Slide1 xsSize={xsBreakpoint} handlePageChange={handlePageChangeOnSlide}/>,
            <Slide2 xsSize={xsBreakpoint} handlePageChange={handlePageChangeOnSlide}/>,
            <Slide3 xsSize={xsBreakpoint} handlePageChange={handlePageChangeOnSlide}/>,
            <Slide3 xsSize={xsBreakpoint} handlePageChange={handlePageChangeOnSlide}/>,     // Hide slide with Sharing info. Temp solution, indexes for Slide4 and Slide5 will be skipped in Actions Component [AS]
            <Slide3 xsSize={xsBreakpoint} handlePageChange={handlePageChangeOnSlide}/>,     // Hide slide with Sharing info. Temp solution, indexes for Slide4 and Slide5 will be skipped in Actions Component [AS]
            // <Slide4 xsSize={xsBreakpoint} handlePageChange={handlePageChangeOnSlide}/>,  // Hide slide with Sharing info. Temp solution, indexes for Slide4 and Slide5 will be skipped in Actions Component [AS]
            // <Slide5 xsSize={xsBreakpoint} handlePageChange={handlePageChangeOnSlide}/>,  // Hide slide with Sharing info. Temp solution, indexes for Slide4 and Slide5 will be skipped in Actions Component [AS]
            <Slide6 xsSize={xsBreakpoint} handlePageChange={handlePageChangeOnSlide}/>,
            <Slide7 xsSize={xsBreakpoint} handlePageChange={handlePageChangeOnSlide}/>,
            <Slide8 xsSize={xsBreakpoint} handlePageChange={handlePageChangeOnSlide}/>,
            <Slide9 xsSize={xsBreakpoint} handlePageChange={handlePageChangeOnSlide}/>,
            <Slide10 xsSize={xsBreakpoint} handlePageChange={handlePageChangeOnSlide}/>,
            <Slide11 xsSize={xsBreakpoint} handlePageChange={handlePageChangeOnSlide}/>,
            <Slide12 xsSize={xsBreakpoint} handlePageChange={handlePageChangeOnSlide}/>,
        ]
    }, [xsBreakpoint, handlePageChangeOnSlide])

    return (
        <DialogContent dividers>
            <TabContext value={slidePage.toString()}>
                {slides.map((slide, index) => (
                        <TabPanel
                            key={index}
                            value={(index + 1).toString()}
                            classes={{root: classes.tabPanel}}
                        >
                            {slide}
                        </TabPanel>
                    )
                )}
            </TabContext>
        </DialogContent>
    )
}

export default WelcomeDialogContent
