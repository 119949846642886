import React from "react";
import { Link, useHistory } from "react-router-dom";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { ExitToApp as ExitToAppIcon } from "@material-ui/icons";

import { useGlobalLoaderActionsContext } from "GlobalContext/GlobalContext";
import type { UserInfoType } from "Hooks/useUserProfile";
import { isGuest } from "Services/UserService";

type AuthActionsProps = {
    userInfo: UserInfoType;
    menuOpenToggled(): void;
};

const AuthActions = (props: AuthActionsProps) => {
    const { userInfo, menuOpenToggled } = props;

    const setLoader = useGlobalLoaderActionsContext();
    const history = useHistory();

    const logoutClicked = async () => {
        menuOpenToggled();

        setLoader(true);
        await userInfo.signOut();
        setLoader(false);

        history.replace("/map");
    };

    if (userInfo.checkAuthToken() && !isGuest()) {
        return (
            <List disablePadding>
                <ListItem button key="Logout" onClick={logoutClicked}>
                    <ListItemIcon>
                        <ExitToAppIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Logout"/>
                </ListItem>
            </List>
        );
    }

    return (
        <List disablePadding>
            <ListItem button key="Login" component={Link} to="/login" onClick={menuOpenToggled}>
                <ListItemIcon>
                    <ExitToAppIcon/>
                </ListItemIcon>
                <ListItemText primary="Login"/>
            </ListItem>
        </List>
    );
};

export default AuthActions;
