import React, {useCallback} from "react";
import {LatLng, LatLngTuple} from "leaflet";
import MapPolygonImageOverlay from "../../../../Controls/common/MapPolygonImageOverlay";
import {MapPolygonChildProps} from "../../TypesSiteMap";
import useUpdateCameraImage from "../../../../../Hooks/UpdateCameraImage/useUpdateCameraImage";

function MapPolygonChild(props: MapPolygonChildProps) {
    const {
        editMode,
        child,
        siteMapController,
        zoomed,
        selectObject,
        map,
        createMode,
        showMarkers,
        openProperties,
        selectionHistory
    } = props;

    const handleClickOnMarker = useCallback(() => {
        if (siteMapController) {
            if (openProperties && selectionHistory && selectionHistory.current === child.object_id) {
                openProperties();
            }
            siteMapController.setSelectObject(child)
        }
    }, [siteMapController, child])

    const selectedObjectId = siteMapController?.getSelectObject()?.object_id || "";

    const addPointInPolygon = useCallback((e: any, index: number) => {
        let _selectObject = siteMapController?.getSelectObject() || null;
        let point: LatLngTuple = [e.latlng.lat, e.latlng.lng];
        if (_selectObject) {
            let property = _selectObject.properties["map_polygon"];
            if (property) {
                (property.value as LatLngTuple[]).splice(index + 1, 0, point);
                siteMapController?.updateValueOfProperty(property.key, property.value);
            }
        }
    }, [siteMapController])

    const dragPolygon = useCallback((key: string, e: any)=>{
        let points = e.target.getLatLngs();
        siteMapController?.updateValueOfProperty(
            key,
            points[0].map((point: LatLng) => [point.lat, point.lng])
        );
    }, [siteMapController]);


    let property = child.properties["map_polygon"] || null;

    const handleSaveBounds = useCallback((value: LatLngTuple[]) => {
        siteMapController?.updateValueOfProperty(property?.key || "", value);
    },[siteMapController, property])

    useUpdateCameraImage(child?.object_id, child?.object_type);

    if (property && Array.isArray(property.value) && property.value.length > 0) {
        return (
            <>
                <MapPolygonImageOverlay
                    bounds={property.value as LatLngTuple[]}
                    object={child}
                    saveBounds={handleSaveBounds}
                    addPoint={addPointInPolygon}
                    hideMarkers={selectedObjectId !== child.object_id}
                    selectObject={selectObject.bind(null, child)}
                    dragPolygon={dragPolygon.bind(null, property.key)}
                    isDraggablePolygon={editMode && selectedObjectId === child.object_id}
                    createMode={createMode}
                    editMode={editMode}
                    selected={selectedObjectId === child.object_id}
                    userInfo={props.userInfo}
                    showMarker={showMarkers}
                    zoomed={zoomed}
                    handleClickOnMarker={handleClickOnMarker}
                    map={map}
                />
            </>
        )
    }

    return null;

}

export default MapPolygonChild
