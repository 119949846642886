import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Divider} from "@material-ui/core";


type AlertDialogProps = {
    open: boolean,
    dialogTitle: string,
    dialogContent?: string,
    agreeText?: string;
    disagreeText?: string;
    handleAgree (): void,
    handleDisagree (): void
}

const AlertDialog = (props: AlertDialogProps) => {

    const { open, dialogTitle, dialogContent, agreeText, disagreeText, handleAgree,  handleDisagree } = props;

    return (
        <Dialog
            open={open}
            onClose={handleDisagree}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {dialogContent}
                </DialogContentText>
            </DialogContent>
            <Divider/>
            <DialogActions>
                <Button onClick={handleDisagree} variant="contained" color="secondary">
                    {disagreeText || "Disagree"}
                </Button>
                <Button onClick={handleAgree} variant="contained" color="primary">
                    {agreeText || "Agree"}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AlertDialog;