import React, { useRef } from "react";
import {makeStyles, useTheme} from "@material-ui/core/styles";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import BorderAllIcon from "@material-ui/icons/BorderAll";
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import DeviceHubIcon from "@material-ui/icons/DeviceHub";
import PanToolIcon from "@material-ui/icons/PanTool";
import SelectAllIcon from "@material-ui/icons/SelectAll";
import {PlanEditController} from "../FloorPlanEditor/PlanEditController";
import SettingsIcon from '@material-ui/icons/Settings';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {ObjectItem, Params} from "../../Services/ObjectsService/Types";
import {getObjects} from "../../Services/ObjectsService/CRUD";
import {getPlanInfo, PLAN_LIMIT} from "../../Services/PurchasesService";
import {
    useUpdateGlobalDialogActionsContext,
    useUpdateGlobalDialogContentActionsContext
} from "../../GlobalContext/GlobalContext";
import {isObjectAvailableToDisplay} from "../../Services/ObjectsService";
import {useMediaQuery} from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import {OBJECT_TYPE} from "../../Services/ObjectsService/Constants";
import { getOrgHierarchy } from "../../Helpers/OrganizationsHelper";

const useStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
    },
    text: {
        padding: theme.spacing(2, 2, 0),
    },
    paper: {
        paddingBottom: 50,
    },
    list: {
        marginBottom: theme.spacing(2),
    },
    subheader: {
        backgroundColor: theme.palette.background.paper,
    },
    appBar: {
        top: "auto",
        bottom: 0,
        height: 76
    },
    grow: {
        flexGrow: 1,
    },
    fabButton: {
        position: "absolute",
        zIndex: 1,
        top: -30,
        left: 0,
        right: 0,
        margin: "0 auto",
    },
    iconButton: {
        flexDirection: "column",
        padding: theme.spacing(1),
    },
    iconRoot: {
        padding: 0,
    },
}));

type FloorPlanEditorToolbarProps = {
    planEditItem: PlanEditController | null;
    openDrawerObjects(filterType: string): void;
    closeDrawerObjects(): void;
};

export default function FloorPlanEditorToolbar(props: FloorPlanEditorToolbarProps) {
    const classes = useStyles();
    const theme = useTheme();
    const down450 = useMediaQuery(theme.breakpoints.down(450));

    const orgObjects = useRef<string[]>([]);

    const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
    const [elementsAnchorEl, setElementsAnchorEl] = React.useState<null | HTMLElement>(null);
    const [settingsAnchorEl, setSettingsAnchorEl] = React.useState<null | HTMLElement>(null);

    const product_id = getPlanInfo().product_id
    const setGlobalDialog = useUpdateGlobalDialogActionsContext()
    const setGlobalDialogContent = useUpdateGlobalDialogContentActionsContext()

    const loadObjects = async () => {
        orgObjects.current = await getOrgHierarchy();

        let params: Params = {
            insideHierarchy: orgObjects.current
        };

        try {
            return (await getObjects(params) || []).filter((object: ObjectItem) => {
                return isObjectAvailableToDisplay(object);
            });
        } catch (e) {
            console.log("Error: ", e);
        }
        return false
    }

    const planCheck = async () => {
        if (product_id === "free") {
            const floorPlanObjects = await loadObjects()
            if (floorPlanObjects) {
                const countFloorPlanObjects = floorPlanObjects.filter(
                    (_object: ObjectItem) => Object.keys(_object.properties).includes("plan_point")
                        || Object.keys(_object.properties).includes("plan_polygon")
                ).length;
                return countFloorPlanObjects <= PLAN_LIMIT.free;
            }
        }
        return true
    }

    const moveButtonClicked = (event: any) => {
        console.log("selectAndDragToolSelected");
        closeMenu();
        if (props.planEditItem) {
            props.planEditItem.selectEditElementTool(event);
        }
    };

    const wallButtonClicked = (event: any) => {
        console.log("createWallToolSelected");
        closeMenu();
        if (props.planEditItem) {
            props.planEditItem.selectCreateWallTool(event);
        }
    };

    const doorButtonClicked = (event: any) => {
        console.log("createDoorToolSelected");
        closeMenu();
        if (props.planEditItem) {
            props.planEditItem.selectCreateDoorTool(event);
        }
    };

    const windowButtonClicked = (event: any) => {
        console.log("createWindowToolSelected");
        closeMenu();
        if (props.planEditItem) {
            props.planEditItem.selectCreateWindowTool(event);
        }
    };


    const objectsButtonClicked = async () => {
        // TODO. Object should be selected by user from Children Elements list.
        // Children elements list should be displayed in the right menu
        // and object should be selected from there. Then this logic should be applied.

        if (await planCheck()) {
            closeMenu();
            props.openDrawerObjects("all_available");
        } else {
            setGlobalDialog("UpgradeToProDialog")
            setGlobalDialogContent("floor_plan_new_element_pro")
        }
    };

    const elementsButtonClicked = async (event: any) => {
        if (await planCheck()) {
            props.closeDrawerObjects();
            setElementsAnchorEl(event.target);
        } else {
            setGlobalDialog("UpgradeToProDialog")
            setGlobalDialogContent("floor_plan_new_element_pro")
        }
    };

    const zoneButtonClicked =async (event: any) => {
        if (await planCheck()) {
            props.closeDrawerObjects();
            setMenuAnchorEl(event.target);
        } else {
            setGlobalDialog("UpgradeToProDialog")
            setGlobalDialogContent("floor_plan_new_element_pro")
        }
    };

    const newZoneMenuItemClicked = (objectType: string, event: any) => {
        closeMenu();
        if (props.planEditItem) {
            props.planEditItem.selectCreateZoneTool(event, objectType);
        }
    };

    const placeExistingZoneMenuItemClicked = () => {
        closeMenu();

        props.openDrawerObjects("zone");
    };
    
    const stickToGridCheckboxClicked = (event: any) => {
      console.log("stick to grid - " + event.target.checked);
      if (props.planEditItem) {
        if(event.target.checked)props.planEditItem.setGridBindingEnabled();
        else props.planEditItem.setGridBindingDisabled();
      }
    };

    const stickToGuidesCheckboxClicked = (event: any) => {
      console.log("stick to guids - " + event.target.checked);
      if (props.planEditItem) {
        if(event.target.checked)props.planEditItem.setGuidesBindingEnabled();
        else props.planEditItem.setGuidesBindingDisabled();
      }
    };

    const settingsButtonClicked = (event: any) => {
      props.closeDrawerObjects();
      setSettingsAnchorEl(event.currentTarget);
    };

    const closeMenu = () => {
        setMenuAnchorEl(null);
        setElementsAnchorEl(null);
        setSettingsAnchorEl(null);
    };

    return (
        <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
                <div className={classes.iconButton}>
                    <IconButton classes={{root:classes.iconRoot}} color="inherit" onClick={moveButtonClicked}>
                        <PanToolIcon/>
                    </IconButton>
                    <div>Move</div>
                </div>
                {
                    down450 &&
                    <div className={classes.iconButton}>
                        <IconButton classes={{root:classes.iconRoot}} color="inherit" onClick={elementsButtonClicked}>
                            <BorderAllIcon/>
                        </IconButton>
                        <div>Elements</div>
                        <Menu
                            id="floor-plan-elements-menu"
                            anchorEl={elementsAnchorEl}
                            keepMounted
                            open={Boolean(elementsAnchorEl)}
                            onClose={closeMenu}
                        >
                            <MenuItem onClick={wallButtonClicked}>Wall</MenuItem>
                            <MenuItem onClick={doorButtonClicked}>Door</MenuItem>
                            <MenuItem onClick={windowButtonClicked}>Window</MenuItem>
                        </Menu>
                    </div>
                }
                {
                    !down450 &&
                    <>
                        <div className={classes.iconButton}>
                            <IconButton classes={{root:classes.iconRoot}} color="inherit" onClick={wallButtonClicked}>
                                <Icon className="fas fa-grip-lines"/>
                            </IconButton>
                            <div>Wall</div>
                        </div>
                        <div className={classes.iconButton}>
                            <IconButton classes={{root:classes.iconRoot}} color="inherit" onClick={doorButtonClicked}>
                                <MeetingRoomIcon/>
                            </IconButton>
                            <div>Door</div>
                        </div>
                        <div className={classes.iconButton}>
                            <IconButton classes={{root:classes.iconRoot}} color="inherit" onClick={windowButtonClicked}>
                                <BorderAllIcon/>
                            </IconButton>
                            <div>Window</div>
                        </div>
                    </>
                }
                <div className={classes.iconButton}>
                    <IconButton classes={{root:classes.iconRoot}} color="inherit" onClick={zoneButtonClicked}>
                        <SelectAllIcon/>
                    </IconButton>
                    <div>Zone</div>
                    <Menu
                        id="floor-plan-zone-menu"
                        anchorEl={menuAnchorEl}
                        keepMounted
                        open={Boolean(menuAnchorEl)}
                        onClose={closeMenu}
                    >
                        <MenuItem onClick={newZoneMenuItemClicked.bind(null, OBJECT_TYPE.ZONE)}>New Zone</MenuItem>
                        <MenuItem onClick={newZoneMenuItemClicked.bind(null, OBJECT_TYPE.ROOM)}>New Room</MenuItem>
                        <MenuItem onClick={newZoneMenuItemClicked.bind(null, OBJECT_TYPE.CAMERA_PLAN_ZONE)}>
                            New Camera Zone
                        </MenuItem>
                        <MenuItem onClick={placeExistingZoneMenuItemClicked} disabled={!props.openDrawerObjects}>
                            Place Existing Zone…
                        </MenuItem>
                    </Menu>
                </div>
                { !!props.openDrawerObjects ? (
                    <div className={classes.iconButton}>
                        <IconButton classes={{root:classes.iconRoot}} color="inherit" onClick={objectsButtonClicked}>
                            <DeviceHubIcon/>
                        </IconButton>
                        <div>Objects</div>
                    </div>
                ) : null }
                <div className={classes.iconButton}>
            <IconButton classes={{root:classes.iconRoot}} color="inherit" onClick={settingsButtonClicked}>
              <SettingsIcon />
            </IconButton>
            <div>Settings</div>
            <Menu
              id="floor-plan-settings-menu"
              anchorEl={settingsAnchorEl}
              keepMounted
              open={Boolean(settingsAnchorEl)}
              onClose={closeMenu}
            >
              <MenuItem>
                <FormControlLabel
                  control={
                    <Checkbox 
                      defaultChecked
                      onChange={stickToGridCheckboxClicked}
                      color="primary"
                    />
                  }
                  label="Snap to grid"
                />
              </MenuItem>

              <MenuItem>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked
                      onChange={stickToGuidesCheckboxClicked}
                      color="primary"
                    />
                  }
                  label="Snap to guides"
                />
              </MenuItem>
            </Menu>
            </div>
            </Toolbar>
        </AppBar>
    );
}
