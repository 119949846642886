import React, {useEffect, useRef, useState} from "react";

import {Color, PaperScope, Path, Point, Tool} from "paper";
import {ObjectItem, PointTuple} from "../../../Services/ObjectsService";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import {PointFields} from "../common/PointFields";
import {CardMedia, Collapse} from "@material-ui/core";
import {ImagePointComponentProps} from "./ImagePointTypes";
import {makeStyles} from "@material-ui/core/styles";
import ObjectsFromTreeView from "../../ListObjects/ObjectsFromTreeView/ObjectsFromTreeView";

const useCommonStyles = makeStyles((theme) => ({
    cursorPointer: {
        cursor: "pointer",
    },
    insideWrapper: {
        position: "relative",
        // height: "inherit",
        height: "inherit",
        width: "inherit",
        maxHeight: "fit-content",
        maxWidth: "fit-content",
        margin: "0 auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    coveredImage: {
        width: "auto",
        maxHeight: "100%",
        maxWidth: "100%",
        margin: "0 auto",
        position: "relative",
    },
    coveringCanvas: {
        position: "absolute",
        top: 0,
        left: 0,
        // backgroundColor: 'rgba(255,255,255,.1)',
        border: 0,
        padding: 0,
    },
    leafletContainer: {
        width: "100%",
        height: "100%",
    },
    border: {
        borderRadius: 7,
        borderColor: theme.palette.divider,
        padding: "20px",
    },
    boxBottomSpacing: {
        paddingBottom: "8px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    popoverContent: {
        minWidth: "175px"
    },
    fullWidth: {
        width: "100%"
    }
}));

const ImagePointComponent = (props: ImagePointComponentProps) => {
    const classes = useCommonStyles();

    const [floatPointX, setFloatPointX] = useState<number>(props.value?.point[0] || 0);
    const [floatPointY, setFloatPointY] = useState<number>(props.value?.point[1] || 0);
    const [currentImage, setCurrentImage] = useState("");
    const [objectId, setObjectId] = useState<string | undefined>(props.value?.object_id);
    const [loader, setLoader] = useState<boolean>(true);

    const refScope = useRef<any>(null);
    const refTool = useRef<any>(null);
    const refPath = useRef<any>(null);
    const refImgWidth = useRef<any>(null);
    const refImgHeight = useRef<any>(null);

    const handleOnLoadImage = (e: React.ChangeEvent<HTMLImageElement>) => {
        console.log("OnLoad");

        const imgWidth = e.target.width;
        const imgHeight = e.target.height;
        refImgWidth.current = imgWidth;
        refImgHeight.current = imgHeight;

        const canvas = document.getElementById("paper") as HTMLCanvasElement;
        canvas.width = imgWidth;
        canvas.height = imgHeight;

        const handlePositionChange = changePointPosition.bind(null, imgWidth, imgHeight);

        const scope = new PaperScope();

        scope.setup(canvas);

        const pointX = floatPointX * imgWidth + (canvas.width - imgWidth) / 2;
        const pointY = floatPointY * imgHeight + (canvas.height - imgHeight) / 2;

        const tool = new Tool();

        const path = new Path.Circle(new Point(pointX, pointY), 3);
        path.strokeColor = new Color("black");
        path.fillColor = new Color("red");

        // Define a mousedown and mousedrag handler
        tool.onMouseDown = (event: any) => {
            console.log("MouseDown");
            path.position = handlePositionChange(event.point.x, event.point.y).point;
        };

        tool.onMouseDrag = (event: any) => {
            console.log("MouseDrag");
            path.position = handlePositionChange(event.point.x, event.point.y, true).point;
        };

        tool.onMouseUp = (event: any) => {
            console.log("MouseUp");
            const result = handlePositionChange(event.point.x, event.point.y);
            path.position = result.point;
            props.saveValue({object_id: objectId, point: [result.floatX, result.floatY]});
        };

        refScope.current = scope;
        refTool.current = tool;
        refPath.current = path;
    };

    const handleChangeSelectedObject = (object: ObjectItem | null, reason?: string) => {
        if (reason === "clear") {
            setCurrentImage("");
            //TODO will be change to initial ImagePoint value
            setFloatPointX(0);
            setFloatPointY(0);
            return;
        }

        if (object) {
            setCurrentImage(object.properties?.image?.value);
            setObjectId(object.object_id);

            //TODO will be change to Object property, contain ImagePoint
            setFloatPointX(0);
            setFloatPointY(0);
        }
    };

    const handleChangeFieldsCoordinates = (coordinates: PointTuple) => {
        const newFloatX: number = coordinates[0] || 0;
        const newFloatY: number = coordinates[1] || 0;
        const newX: number = newFloatX * refImgWidth.current;
        const newY: number = newFloatY * refImgHeight.current;

        refPath.current.position = changePointPosition(refImgWidth.current, refImgHeight.current, newX, newY).point;
    };

    const changePointPosition = (width: number, height: number, x: number, y: number, notUpdate?: boolean) => {
        const newX: number = x > width ? width : x < 0 ? 0 : x;
        const newY: number = y > height ? height : y < 0 ? 0 : y;
        const newFloatX: number = newX / width;
        const newFloatY: number = newY / height;

        if (!notUpdate) {
            setFloatPointX(newFloatX);
            setFloatPointY(newFloatY);
        }

        return {
            point: new Point(newX, newY),
            floatX: newFloatX,
            floatY: newFloatY,
        };
    };

    useEffect(() => {
        let active = true;

        setTimeout(() => {
            if (active) {
                //TODO Handled issue for popover, when canvas size (height) incorrect with initial image via props [AS]
                setLoader(false);
            }
        }, 500);

        return () => {
            active = false;
        };
    }, []);

    return (
        <Box height="inherit" width={"100%"} maxWidth={props.maxWidth}>
            <Collapse in={!props.isPropertyCard}>
                <Box className={classes.boxBottomSpacing}>
                    <ObjectsFromTreeView
                        displayLoader={setLoader}
                        handleObject={handleChangeSelectedObject}
                        filterType={['contains_image']}
                        currentObjectId={objectId}
                        userInfo={props.userInfo}
                    />
                </Box>
            </Collapse>
            <Box height="inherit" className={classes.boxBottomSpacing}>
                {loader && <CircularProgress/>}
                {!loader && currentImage && (
                    <Box className={classes.insideWrapper}>
                        <CardMedia
                            className={classes.coveredImage}
                            image={currentImage}
                            component="img"
                            alt=""
                            onLoad={handleOnLoadImage}
                            onError={() => {
                                setCurrentImage("");
                            }}
                        />
                        <canvas id="paper" className={classes.coveringCanvas} data-paper-resize/>
                    </Box>
                )}
                {!loader && !currentImage && <Typography align="left">Image does not exist</Typography>}
            </Box>
            <Box className={classes.boxBottomSpacing}>
                {!props.isPropertyCard && (
                    <PointFields
                        point={[floatPointX, floatPointY]}
                        isDisabled={loader || props.isDisabled}
                        controlInputProps={{inputProps: {step: "0.0001"}}}
                        handleUpdate={handleChangeFieldsCoordinates}
                    />
                )}
            </Box>
        </Box>
    );
};

export {ImagePointComponent};
