import * as LocalmachinesConstants from "./Environments/LocalmachinesConstants";
import * as ExpiriumConstants from "./Environments/ExpiriumConstants";
import * as DokuConstants from "./Environments/DokuConstants";
import {APPLICATION_ID} from "./Constants";

const REACT_APP_ID: string = process.env.REACT_APP_ID || "";

let AppConstants = LocalmachinesConstants;
if (REACT_APP_ID === APPLICATION_ID.EXPIRIUM) {
    AppConstants = ExpiriumConstants;
}
if (REACT_APP_ID === APPLICATION_ID.DOKU) {
    AppConstants = DokuConstants;
}

export const MENU_ITEM_KEYS = AppConstants.MENU_ITEM_KEYS;
export const HELP_MENU_ITEM_CONTENT = AppConstants.HELP_MENU_ITEM_CONTENT;
export const DEFAULT_PROPERTIES_BY_MACHINES = AppConstants.DEFAULT_PROPERTIES_BY_MACHINES;

export * from "./Constants";
