import React, {Fragment, useEffect, useRef, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {IconButton, useTheme} from "@material-ui/core";
import {PlanViewController} from "../../FloorPlanEditor/PlanViewController";
import {ObjectItem} from "../../../Services/ObjectsService";
import type {SystemOfMeasuresType} from "../../../Helpers/SystemOfMeasures";
import PlanControlMarker from "../../FloorPlanEditor/PlanControlMarker/PlanControlMarker";
import useForceUpdate from "../../../Hooks/ForceUpdate/useForseUpdate";
import Icon from "@material-ui/core/Icon";

const useStyles = makeStyles((theme) => ({
    insideWrapper: {
        position: "relative",
        height: "95%",
    },
    planBgColor: {
        backgroundColor: theme.palette.type === "dark" ? theme.palette.background.paper : theme.palette.background.default,
    },
    zoomPanel: {
        margin: theme.spacing(0.5),
        display: "flex",
        justifyContent: "center",
    },
    iconButton: {
        flexDirection: "column",
        padding: theme.spacing(1),
    }
}));

type PlanControlTypes = {
    object?: ObjectItem;
    parentId?: string;
    variant?: string;
    systemOfMeasures: SystemOfMeasuresType;
};

const PlanControl = (props: PlanControlTypes) => {

    const {variant, object, parentId, systemOfMeasures} = props;

    const theme = useTheme();
    const classes = useStyles();

    const forceUpdate = useForceUpdate();

    const canvasRef = useRef<any>(null)

    const [planViewItem, setPlanViewItem] = useState<PlanViewController | null>(null);
    const [canvasSize, setCanvasSize] = useState<any>({width: 0, height: 0});
    const [planZoom, setPlanZoom] = useState<number>(1)
    const canvasBlock = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        setPlanViewItem(new PlanViewController(canvasRef.current, theme.palette.type, systemOfMeasures, forceUpdate, parentId));

        return () => {
            setPlanViewItem(null);
        }
    }, []);

    useEffect(() => {
        if (planViewItem && object) {
            planViewItem.loadPlanObjects([object]);
            planViewItem.moveToCenter();
            forceUpdate()
        }
    }, [planViewItem, object]);


    useEffect(() => {
        if (canvasBlock.current) {
            const {width, height} = canvasBlock.current.getBoundingClientRect()
            setCanvasSize({width, height})
        }
    }, [canvasBlock])

    const zoomInButtonClicked = () => {
        if (planViewItem) {
            planViewItem.zoomIn();
            setPlanZoom(planViewItem.getCurrentZoom())
        }
    };

    const zoomOutButtonClicked = () => {
        if (planViewItem) {
            planViewItem.zoomOut();
            setPlanZoom(planViewItem.getCurrentZoom())
        }
    };

    const getMarker = () => {
        if (planViewItem?.getAllMarkedObjects().length) {
            const item = planViewItem?.getAllMarkedObjects()[0].getPaperJSItem()
            return (
                <PlanControlMarker
                    centerPoint={{
                        x: canvasSize.width / 2,
                        y: canvasSize.height / 2,
                    }}
                    object_type={item.data.info.object_type}
                    icon={item.data.icon}
                    object_name={item.data.info.object_name}
                    zoom={planZoom}
                />
            )
        } else {
            return null
        }
    }

    return (
        <Fragment>
            <div
                className={classes.insideWrapper}
                ref={canvasBlock}
            >
                {getMarker()}
                <canvas
                    ref={canvasRef}
                    data-paper-resize
                    className={classes.planBgColor}
                />
            </div>
            {variant !== "title" && (
                <div className={classes.zoomPanel}>
                    <IconButton color="inherit" onClick={zoomInButtonClicked} className={classes.iconButton}>
                        <Icon className="fa fa-search-plus" fontSize="small"/>
                    </IconButton>
                    <IconButton color="inherit" onClick={zoomOutButtonClicked} className={classes.iconButton}>
                        <Icon className="fa fa-search-minus" fontSize="small"/>
                    </IconButton>
                </div>
            )}
        </Fragment>
    );
};

export {PlanControl};
