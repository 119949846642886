import React, {useState} from "react";
import { Typography, Button } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { pubSubmitEvent } from "../../../Services/SystemEventsService";
import {
    usePressButtonsOnKeyboard,
    useSubKeyDownCmdS
} from "../../../Hooks/PressButtonsOnKeyboard/usePressButtonsOnKeyboard";
import {getSystemState, STATE_TYPE} from "../../../Services/SystemStatesService";
import {useSubUnsavedChangesToObject} from "../../../Hooks/SystemEvent/Toolbar/useToolbar";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            flexGrow: 1,
        },
        iconButton: {
            flexDirection: "column",
            padding: theme.spacing(1),
        },
    })
);

type DocumentEditorNavbarToolsProps = {
    pageName: string;
};

export const DocumentEditorNavbarTools = (props: DocumentEditorNavbarToolsProps) => {
    const classes = useStyles();

    const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);

    const saveDocument = () => {
        if (!hasUnsavedChanges) {
            console.log("Save disabled")
            return;
        }

        pubSubmitEvent();
    };

    useSubUnsavedChangesToObject(() => {
        setHasUnsavedChanges(getSystemState(STATE_TYPE.UNSAVED_CHANGES_TO_OBJECT));
    });

    usePressButtonsOnKeyboard();

    useSubKeyDownCmdS(() => {
        saveDocument();
    });

    return (
        <React.Fragment>
            <Typography variant="h6" noWrap className={classes.title}>
                {props.pageName}
            </Typography>
            <Button color="inherit" onClick={saveDocument} disabled={!hasUnsavedChanges}>
                Save
            </Button>
        </React.Fragment>
    );
};
