import {useEffect, useState} from "react";
import {v4} from "uuid";
import {
    // BodyObjectItem,
    ObjectActivityStatus, PrimaryClient,
    // subLastActiveObjectChanges,
    subObjectStatusChanges,
    // subReachabilityOfObjectChanges,
    // unsubLastActiveObjectChanges,
    unsubObjectStatusChanges,
    // unsubReachabilityOfObjectChanges
} from "../../Services/ObjectsService";
import {OriginType} from "../../Services/ObjectsService/Constants";

type useSubObjectLastActiveType = (objectId: string | undefined, lastActivity: number | null | undefined, objectDeviceName: string | undefined) => {lastActive: number | null, isOffline: boolean | undefined, deviceName: string | undefined};

export const useSubObjectStatus: useSubObjectLastActiveType = (objectId, epoch, objectDeviceName) => {
    const [lastActive, setLastActive] = useState<number | null>(epoch || null);
    const [isOffline, setIsOffline] = useState<boolean | undefined>();
    const [deviceName, setDeviceName] = useState<string | undefined>(objectDeviceName);

    useEffect(() => {

        if (!objectId) {
            return;
        }

        let active = true;

        const subscriptionId = v4();

        subObjectStatusChanges(objectId, subscriptionId, OriginType.USER,  (keys, data) => {
            if (keys.objectId !== objectId || !active || !data) {
                return;
            }

            if (data.hasOwnProperty("last_active")) {
                setLastActive((data as ObjectActivityStatus).last_active as number);
            }

            if (data.hasOwnProperty("reachable")) {
                setIsOffline((data as ObjectActivityStatus).reachable === false ? true : undefined);
            }

            if (data.hasOwnProperty("primary_client")) {
                setDeviceName(((data as ObjectActivityStatus).primary_client as PrimaryClient).name as string);
            }

        });

        // subLastActiveObjectChanges(objectId, subscriptionId, OriginType.USER, (keys, data) => {
        //     if (keys.objectId !== objectId || !active || !data) {
        //         return;
        //     }
        //
        //     setLastActive(data as number);
        // });
        //
        // subReachabilityOfObjectChanges(objectId, subscriptionId, OriginType.USER, (keys, data) =>{
        //     if (keys.objectId !== objectId || !active) {
        //         return;
        //     }
        //
        //     setIsOffline(data === false ? true : undefined);
        // });

        return () => {
            active = false;
            // unsubLastActiveObjectChanges(objectId, subscriptionId);
            // unsubReachabilityOfObjectChanges(objectId, subscriptionId);
            unsubObjectStatusChanges(objectId, subscriptionId);
        }

    }, [objectId]);

    useEffect(() => {
        let active = true;

        if (active) {
            setLastActive(epoch || null);
        }

        return () => {
            active = false;
        };
    }, [epoch]);

    useEffect(() => {
        let active = true;

        if (active) {
            setDeviceName(objectDeviceName);
        }

        return () => {
            active = false;
        };
    }, [objectDeviceName]);

    return { lastActive, isOffline, deviceName };
}