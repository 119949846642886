import React, {Fragment, useEffect, useState} from "react";
import ObjectsTreeDialog from "../../Dialogs/ObjectsTreeDialog/ObjectsTreeDialog";
import {getObjectById, ObjectItem} from "../../../Services/ObjectsService";
import {IconButton, InputAdornment, InputLabel, OutlinedInput} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from '@material-ui/icons/Add';
import FormControl from "@material-ui/core/FormControl";
import {UserInfoType} from "../../../Hooks/useUserProfile";


type ObjectsFromTreeViewProps = {
    displayLoader?(state: boolean): void;
    handleObject(object: ObjectItem | null, reason?: string): void;
    currentObjectId?: string;
    filterType?: string[];
    userInfo: UserInfoType;
    childrenObjectsIds?: string[];
}

const filterObjectsIds: string[] = [];

const ObjectsFromTreeView = (props: ObjectsFromTreeViewProps) => {

    const {
        displayLoader,
        handleObject,
        currentObjectId,
        filterType,
        userInfo,
        childrenObjectsIds
    } = props;


    const [showDialog, setShowDialog] = React.useState<boolean>(false);
    const [selectedObject, setSelectedObject] = useState<ObjectItem | null>(null);

    const handleClearSearch = (e: any) => {
        e.stopPropagation();
        setSelectedObject(null)
        handleObject(null, "clear")
    }

    const handleBindProperty = (object: ObjectItem | null) => {
        if (object) {
            setSelectedObject(object);
            handleObject(object);
        }

        setShowDialog(false);
    };

    const getSelectedObjectById = async (object_id: string) => {

        let response = null;
        displayLoader && displayLoader(true);

        try {
            response = await getObjectById(object_id);
        } catch (error) {
            console.error(error);
        } finally {
            displayLoader && displayLoader(false);
        }
        return response;
    };

    useEffect(() => {
        (async () => {
            if (currentObjectId) {
                const currentObject = await getSelectedObjectById(currentObjectId);
                setSelectedObject(currentObject);
                handleObject(currentObject);
            }
        })();
        displayLoader && displayLoader(false);
    }, [])

    return (
        <Fragment>

            <FormControl
                fullWidth
                variant="outlined">
                <InputLabel>Select Object</InputLabel>
                <OutlinedInput
                    type={'text'}
                    value={selectedObject ? selectedObject.object_name : ''}
                    disabled={true}
                    onClick={()=>setShowDialog(true)}
                    endAdornment={
                        <InputAdornment position="end">
                            {selectedObject ?
                                (<IconButton
                                        onClick={handleClearSearch}
                                        edge="end"
                                    >
                                        <CloseIcon/>
                                    </IconButton>
                                ) : (
                                    <IconButton
                                        onClick={()=>setShowDialog(true)}
                                        edge="end"
                                    >
                                        <AddIcon/>
                                    </IconButton>)
                            }
                        </InputAdornment>
                    }
                    label={'Select Object'}
                />
            </FormControl>

            <ObjectsTreeDialog
                show={showDialog}
                filterObjectsIds={filterObjectsIds}
                childrenObjectsIds={childrenObjectsIds}
                filterType={filterType}
                userInfo={userInfo}
                handleBindChild={handleBindProperty}
            />
        </Fragment>
    )

};

export default ObjectsFromTreeView;
