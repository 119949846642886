import {useEffect, useState} from "react";
import {v4} from "uuid";
import {NotificationStateType, ObjectItem, PropertyItem} from "../../Services/ObjectsService/Types";
import {subObjectNotificationState, unsubObjectNotificationState,} from "../../Services/ObjectsService/Subscription";
import {OriginType, PROPERTIES_WITH_NOTIFICATION} from "../../Services/ObjectsService/Constants";

export const useSubObjectNotificationStatus = (object_item: ObjectItem) => {

    const [showNotification, setShowNotification] = useState<boolean>(false)
    const [notificationValue, setNotificationValue] = useState<boolean>(
        object_item.notifications ? object_item.notifications.state : true);

    const objectWithNotification = () => {
        const objectPropertiesTypesList = Object.values(object_item.properties).map((prop_item: PropertyItem) => prop_item.type)
        return !!objectPropertiesTypesList.filter((type: string) => PROPERTIES_WITH_NOTIFICATION.includes(type)).length
    }

    useEffect(() => {
        setShowNotification(objectWithNotification())
    }, [object_item.properties])

    useEffect(() => {
        showNotification && setNotificationValue(
            object_item.notifications ? object_item.notifications.state : true)
    }, [showNotification])

    useEffect(()=>{
        if (!object_item?.object_id || !showNotification) {
            return;
        }
        let active = true;

        const subscriptionId = v4();

        subObjectNotificationState(object_item?.object_id, subscriptionId, OriginType.USER, (keys, data)=>{
            if (keys.objectId !== object_item?.object_id || !active || !data) {
                return;
            }
            setNotificationValue((data as NotificationStateType).state)
        })

        return () => {
            active = false;
            unsubObjectNotificationState(object_item?.object_id, subscriptionId);
        }
    })


    return { showNotification, notificationValue, setNotificationValue };
}
