import { authRequest, nonAuthRequest, REQUEST_TYPE, METHOD } from "../RequestService";
import { getClientId, getClientInfo, getDeviceId, getDeviceInfo } from "../UserService";
import type { UserCrypto } from "../CryptoService";
import { getVersion } from "../Utils";

const getRemoteIoTLink = async () => {
    return authRequest("auth/iot-link", {
        method: METHOD.GET,
        requestType: REQUEST_TYPE.GET_IOT_LINK,
    });
};

const getRemoteLogin = async (username: string) => {
    const params = {
        email: username
    };

    return nonAuthRequest("auth/login", {
        method: METHOD.GET,
        query: params,
        requestType: REQUEST_TYPE.GET_LOGIN,
    });
};

const getRemoteGuestLogin = async () => {
    const clientId = getClientId();

    const body = {
        device_id: getDeviceId(),
        device_info: getDeviceInfo(),
        client_info: getClientInfo(clientId),
        version: getVersion()
    };

    return nonAuthRequest("auth/guest-login", {
        method: METHOD.POST,
        body: JSON.stringify(body),
        requestType: REQUEST_TYPE.POST_GUEST_LOGIN,
    })
};

const postRemoteRefreshToken = async () => {
    return authRequest("auth/refresh", {
        method: METHOD.POST,
        requestType: REQUEST_TYPE.POST_REFRESH_TOKEN,
    });
};

const postRemoteLogin = async (username: string, sessionId: string, signedVerificationCode: string) => {
    const body = {
        email: username,
        session_id: sessionId,
        signed_verification_code: signedVerificationCode
    };

    return nonAuthRequest("auth/login", {
        method: METHOD.POST,
        body: JSON.stringify(body),
        requestType: REQUEST_TYPE.POST_LOGIN,
    });
};

const postRemoteVerifiedEmail = async (token: string) => {
    const clientId = getClientId();

    const body = {
        device_info: getDeviceInfo(),
        client_info: getClientInfo(clientId),
        version: getVersion()
    }

    return authRequest("auth/verified-email", {
        method: METHOD.POST,
        token: token,
        body: JSON.stringify(body),
        requestType: REQUEST_TYPE.POST_VERIFIED_EMAIL,
    });
};

const postRemoteRegister = async (user: { username: string, firstname: string, lastname: string }, crypto: UserCrypto) => {
    const { encryption, signing } = crypto;
    const clientId = getClientId();

    const body = {
        email: user.username,
        firstname: user.firstname,
        lastname: user.lastname,
        encryption: {
            private_key: encryption.privateKey,
            public_key: encryption.publicKey,
            iv: encryption.iv,
            salt: encryption.salt,
        },
        signing: {
            private_key: signing.privateKey,
            public_key: signing.publicKey,
            iv: signing.iv,
            salt: signing.salt,
        },
        device_info: getDeviceInfo(),
        client_info: getClientInfo(clientId),
        version: getVersion()
    };

    return nonAuthRequest("auth/register", {
        method: METHOD.POST,
        body: JSON.stringify(body),
        requestType: REQUEST_TYPE.POST_REGISTER,
    });
};

const postRemoteForgotPassword = async (username: string) => {
    const body = {
        email: username
    };

    return nonAuthRequest("auth/forgot-password", {
        method: METHOD.POST,
        body: JSON.stringify(body),
        requestType: REQUEST_TYPE.POST_FORGOT_PASSWORD,
    });
};

const postRemoteCreatePassword = async (token: string, sessionId: string, verificationCode: string, crypto: UserCrypto) => {
    const { encryption, signing } = crypto;

    const body = {
        session_id: sessionId,
        verification_code: verificationCode,
        encryption: {
            private_key: encryption.privateKey,
            public_key: encryption.publicKey,
            iv: encryption.iv,
            salt: encryption.salt,
        },
        signing: {
            private_key: signing.privateKey,
            public_key: signing.publicKey,
            iv: signing.iv,
            salt: signing.salt,
        }
    };

    return authRequest("auth/create-password", {
        method: METHOD.POST,
        body: JSON.stringify(body),
        token: token,
        requestType: REQUEST_TYPE.POST_CREATE_PASSWORD,
    });
};

const postRemoteUpdatePassword = async (username: string, sessionId: string, signedVerificationCode: string, crypto: UserCrypto) => {
    const { encryption, signing } = crypto;

    const body = {
        email: username,
        session_id: sessionId,
        signed_verification_code: signedVerificationCode,
        encryption: {
            private_key: encryption.privateKey,
            public_key: encryption.publicKey,
            iv: encryption.iv,
            salt: encryption.salt,
        },
        signing: {
            private_key: signing.privateKey,
            public_key: signing.publicKey,
            iv: signing.iv,
            salt: signing.salt,
        }
    };

    return authRequest("auth/update-password", {
        method: METHOD.POST,
        body: JSON.stringify(body),
        requestType: REQUEST_TYPE.POST_UPDATE_PASSWORD,
    });
};

export {
    getRemoteIoTLink,
    getRemoteLogin,
    getRemoteGuestLogin,
    postRemoteRefreshToken,
    postRemoteLogin,
    postRemoteVerifiedEmail,
    postRemoteRegister,
    postRemoteForgotPassword,
    postRemoteCreatePassword,
    postRemoteUpdatePassword,
}
