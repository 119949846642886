import React, {useCallback, useEffect, useState} from "react";
import {Grid, IconButton} from "@material-ui/core";
import Property from "../../Property/Property";
import {Settings as SettingsIcon} from "@material-ui/icons";
import {ObjectItem, PropertiesType, PropertyItem, PropertyItemValue} from "../../../../Services/ObjectsService/Types";
import {UserInfoType} from "../../../../Hooks/useUserProfile";
import ModalAddProperty from "../../ObjectDetails/ModalAddProperty";

type ObjectFormPropertiesProps = {
    object: ObjectItem;
    userInfo: UserInfoType;
    parentId?: string;
    updateObjectByKey(key: string, value: string | number | boolean | PropertiesType): void;
};

const ObjectFormProperties = (props: ObjectFormPropertiesProps) => {
    const {object, userInfo, parentId, updateObjectByKey} = props;

    const [properties, setProperties] = useState<PropertiesType | undefined>(object.properties);
    const [openPropertyDialog, setOpenPropertyDialog] = useState<boolean>(false);
    const [editProperty, setEditProperty] = useState<PropertyItem | undefined>();

    const handleDialogClose = useCallback(() => {
        setEditProperty(undefined);
        setOpenPropertyDialog(false);
    }, [setEditProperty, setOpenPropertyDialog]);

    const savePropertyChanges = (key: string, value: PropertyItemValue) => {
        const updateProperties = object.properties;
        updateProperties[key].value = value;
        updateObjectByKey("properties", updateProperties);
    };

    const handleEditPropertyDialogOpen = (property: PropertyItem) => {
        setEditProperty(JSON.parse(JSON.stringify(property)));
        setOpenPropertyDialog(true);
    };

    useEffect(() => {
        setProperties(object.properties);
    }, [object.properties]);

    if (!object.properties) {
        return null;
    }

    return (
        <>
            {Object.values(properties as PropertiesType).map((property) => {
                if (property.removed || !property.key) {
                    return null;
                }

                return (
                    <Grid item xs={12} sm={10} md={8} key={property.key}>
                        <Property
                            property={property}
                            userInfo={userInfo}
                            object={object}
                            onChange={savePropertyChanges}
                            parentId={parentId}
                            settings={
                                <IconButton
                                    color="primary"
                                    children={<SettingsIcon/>}
                                    onClick={handleEditPropertyDialogOpen.bind(null, property)}
                                />
                            }
                        />
                    </Grid>
                )
            })}
            <ModalAddProperty
                open={openPropertyDialog}
                onClose={handleDialogClose}
                object={object}
                saveObjectByKey={updateObjectByKey}
                propertyItem={editProperty}
                userInfo={props.userInfo}
                parentId={props.parentId}
            />
        </>
    )
};

export default ObjectFormProperties;
