import { Point, Path, Color } from "paper";
import { PlanEditController } from '../PlanEditController';
import { ElementType } from '../tools/Elements/AbstractElement';
import { AbstractBinding } from './AbstractBinding';

export class GuidesBinding extends AbstractBinding {

    protected planEditController: PlanEditController;
    protected guidesLayer: any;
    protected stickToGuidesTolerance: number = 20;

    constructor(planEditController: PlanEditController, guidesLayer: any) {
        super();
        this.planEditController = planEditController;
        this.guidesLayer = guidesLayer;
    }

    doBinding(point: any): any {
        this.guidesLayer.removeChildren();

        let elements = this.planEditController.getElements();

        elements.forEach(element => {
            if (element.getElementType() !== ElementType.SOLID_WALL)
                return;
            element.getPaperJSItem().segments.forEach((segment: any) => {
                // preventing snapping of an editable point
                if (segment.point.selected) return;

                let distanceX = Math.abs(segment.point.x - point.x);
                let distanceY = Math.abs(segment.point.y - point.y);
                
                if (distanceX < this.stickToGuidesTolerance) {
                    point = new Point(segment.point.x, point.y);
                    this.drawGuides(segment.point, point);
                }
                if (distanceY < this.stickToGuidesTolerance) {
                    point = new Point(point.x, segment.point.y);
                    this.drawGuides(segment.point, point);
                }
            });
        });
        return point;
    }

    protected drawGuides(sourcePoint: any, destinationPoint: any): void {
        let guideLine = new Path.Line(sourcePoint, destinationPoint);
        guideLine.strokeColor = new Color(0.5, 0, 0.5);
        guideLine.dashArray = [5, 6];
        this.guidesLayer.addChild(guideLine);
    }

    clear(): void {
        this.guidesLayer.removeChildren();
    }

}