import React, {Fragment, ReactNode, useCallback, useMemo} from "react";
import {
    controlHueTypes,
    controlImagePointType,
    controlImagePolygonType,
    controlImageType,
    controlChatType,
    controlMapPointType,
    controlMapPolygonType,
    controlNumberTypes,
    controlPlanPointType,
    controlPlanPolygonType,
    controlSliderTypes,
    controlSwitchTypes,
    controlTextTypes,
    HMCharacteristicValues,
    controlFileType,
} from "./PropertyControlsTypes";
import {PROPERTY_KEYS_FOR_TIME_TRANSFORM} from "../../../../Services/ObjectsService/Constants";
import {
    ChatMessageType,
    ImagePointItem,
    ImagePolygonItem,
    FileType,
    ObjectItem,
    PropertyItem,
    PropertyItemValue,
    ServiceLifeType
} from "../../../../Services/ObjectsService";
import {SwitchControl} from "../../../Controls/SwitchControl/SwitchControl";
import {NumberControl} from "../../../Controls/NumberControl";
import {TextControl} from "../../../Controls/TextControl";
import {ChatMessageControl} from "../../../Controls/ChatMessageControl";
import {Hue} from "../../../Controls/Hue/Hue";
import {SliderControl} from "../../../Controls/SliderControl/SliderControl";
import {PointsFieldsControl} from "../../../Controls/PointsFieldsControl";
import {PlanPoint} from "../../../Controls/PlanPoint";
import {ViewImage} from "../../../Controls/ViewImage";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import {getValueWithUnits} from "../../../../Helpers/SystemOfMeasures";
import Typography from "@material-ui/core/Typography/Typography";
import {ImagePolygon} from "../../../Controls/ImagePolygon/ImagePolygon";
import {ImagePoint} from "../../../Controls/ImagePoint/ImagePoint";
import {MapPoint} from "../../../Controls/MapPoint/MapPoint";
import {MapPolygon} from "../../../Controls/MapPolygon/MapPolygon";
import {TextControlWithSetting} from "../../../Controls/TextControlWithSettings";
import SelectControl from "../../../Controls/SelectControl/SelectControl";
import MultipleSelectControl from "../../../Controls/MultipleSelectControl/MultipleSelectControl";
import {differenceInDays, format, parse} from "date-fns";
import {UserInfoType} from "../../../../Hooks/useUserProfile";
import ServiceLifeControl from "../../../Controls/ServiceLifeControl";
import {FileControl} from "../../../Controls/FileControl";

export type PropertyControlsProps = {
    onChange(key: string, value: PropertyItemValue, push?: boolean): void;
    property: PropertyItem;
    isDisabled?: boolean;
    objectId?: string;
    object?: ObjectItem;
    variant?: string;
    fontSize?: number;
    settings?: ReactNode;
    userInfo: UserInfoType;
    parentId?: string;
    value: PropertyItemValue;
};

const PropertyControls = (props: PropertyControlsProps) => {
    const {onChange, property, isDisabled, object, variant, fontSize, settings, userInfo, parentId, value} = props;

    const systemOfMeasures = userInfo.user?.system_of_measures || "imperial";
    const imagePolygonMaxWidth = useMemo(() => variant === "popover" ? 400 : undefined, [variant]);

    const popoverInputProps = {
        margin: "dense",
        variant: "outlined",
    };

    const otherInputProps = {
        fullWidth: true,
        variant: "outlined",
        disabled: isDisabled,
        InputProps: settings
            ? {startAdornment: <InputAdornment position="start">{settings}</InputAdornment>}
            : undefined,
    };

    const handleSaveValue = useCallback(
        (key: string, value: PropertyItemValue) => {
            onChange(key, value, variant === "propertyCard" || variant === "title");
        }
        , [onChange, variant])

    const getStringValue = () => {
        if (controlImagePolygonType.includes(property.type)) {
            return `${property.name}: ${(value as ImagePolygonItem).points || []}`
        } else if (controlPlanPointType.includes(property.type) || controlPlanPolygonType.includes(property.type)) {
            return `${property.name}: ${(parentId ? (value as any)[parentId] : Object.values(value)[0]) || []}`
        } else if (HMCharacteristicValues.hasOwnProperty(property.type)) {
            return `${property.name}: ${HMCharacteristicValues[property.type as keyof typeof HMCharacteristicValues]?.[value as number]?.label || value}`
        } else if (property.units) {
            return `${property.name}: ${getValueWithUnits(systemOfMeasures, Number(value), property.units)}`
        } else if (Array.isArray(value)) {
            return `${property.name}: ${value.join(", ")}`
        } else if (controlChatType.includes(property.type)) {
            return `${property.name}: ${(value as ChatMessageType).text || ""}`;
        } else if (property.type === "ServiceLife") {
            const serviceLifeValue = value as ServiceLifeType
            const remainingDaysOfLife: number = serviceLifeValue.life_time - differenceInDays(new Date(), parse(serviceLifeValue.start_time, 'MM/dd/yyyy', new Date()))
            return `${property.name}: ${(remainingDaysOfLife > 0 ? serviceLifeValue.show_percentage ? Math.round(remainingDaysOfLife / serviceLifeValue.life_time * 100) : remainingDaysOfLife : `0`) + (serviceLifeValue.show_percentage ? `%`: remainingDaysOfLife === 1 ? ` day` : ` days`)}`
        } else if (property.type === "File") {
            const fileValue = value as FileType;
            return `${property.name}: ${fileValue.name}`
        }
        return `${property.name}: ${value}`
    }

    if (PROPERTY_KEYS_FOR_TIME_TRANSFORM.includes(property.key)) {
        const time = new Date(isNaN(parseInt(value.toString())) ? value.toString() : value as number);
        return (
            <>
                {property.name}: {time.toString() !== "Invalid Date" ? format(new Date(time).getTime(), 'MM/dd/yyyy HH:mm:ss') : ''}
            </>
        );
    }

    if (variant === "marker" ) {
        if (property.type === "ServiceLife") {
            return (
                <Fragment>
                    {getStringValue()}
                </Fragment>
            )
        }

        if (!property.writable) {
            return (
                <Fragment>
                    {getValueWithUnits(systemOfMeasures, value, property.units)}
                </Fragment>
            )
        }
    }

    if (variant === "string") {
        return (
            <>
                {getStringValue()}
            </>)
    }

    if (property.type === "numericValue") {
        return null;
    }

    if (property.type === "enum" && Array.isArray(property.value_options_list) && property.value_options_list.length) {
        return (
            <SelectControl
                propertyKey={property.key}
                label={property.name}
                value={value as string}
                valueOptionsList={property.value_options_list}
                saveValue={handleSaveValue}
                variant={variant}
                controlInputProps={variant === "popover" ? popoverInputProps : otherInputProps}
                isWritable={property.writable}
            />
        )
    }

    if (HMCharacteristicValues.hasOwnProperty(property.type)) {
        const totalValueOptionsList = HMCharacteristicValues[property.type as keyof typeof HMCharacteristicValues]
        let valueOptionsList: { value: number; label: string; }[] = []

        if (property.valid_values?.length) {
            property.valid_values.forEach((value) => {
                if (totalValueOptionsList[value]?.label) {
                    valueOptionsList.push(totalValueOptionsList[value])
                }
            })
        } else if (property.min && property.max) {
            for (let i = (property.min as number); i <= (property.max as number); i++) {
                if (totalValueOptionsList[i]?.label) {
                    valueOptionsList.push(totalValueOptionsList[i])
                }
            }
        } else {
            valueOptionsList = totalValueOptionsList
        }

        return (
            <SelectControl
                propertyKey={property.key}
                label={property.name}
                value={value as number}
                valueOptionsList={valueOptionsList}
                saveValue={handleSaveValue}
                variant={variant}
                controlInputProps={variant === "popover" ? popoverInputProps : otherInputProps}
                isHMCharacteristicValue={true}
                isWritable={property.writable}
        />
        )
    }

    if (property.type === "MultipleSelect" && Array.isArray(property.value_options_list) && property.value_options_list.length) {
        return (
            <MultipleSelectControl
                propertyKey={property.key}
                label={property.name}
                value={value as string[]}
                valueOptionsList={property.value_options_list}
                saveValue={handleSaveValue}
                variant={variant}
                settings={settings}
            />
        )
    }

    if (property.type === "ServiceLife") {
        return (
            <ServiceLifeControl
                id={property.property_id || property.key}
                propertyKey={property.key}
                label={property.name}
                value={value as ServiceLifeType}
                saveValue={handleSaveValue}
                variant={variant}
                settings={settings}
            />
        )
    }

    if (controlSwitchTypes.includes(property.type)) {
        let switchFontSize = undefined
        if (variant === "popover") {
            switchFontSize = "small"
        } else if (variant === "marker") {
            switchFontSize = fontSize
        }
        return (
            <SwitchControl
                propertyKey={property.key}
                label={property.name}
                value={value as boolean}
                fontSize={switchFontSize}
                variant={variant}
                saveValue={handleSaveValue}
                isDisabled={isDisabled}
                settings={settings}
            />
        );
    }

    if (controlNumberTypes.includes(property.type)) {
        return (
            <NumberControl
                propertyKey={property.key}
                label={property.name}
                onChangeHandler={property.type === "number"}
                value={value as number}
                saveValue={handleSaveValue}
                variant={variant}
                controlInputProps={variant === "popover" ? popoverInputProps : otherInputProps}
                units={property.units}
                systemOfMeasures={systemOfMeasures}
            />
        );
    }

    if (controlTextTypes.includes(property.type)) {
        return (
            <TextControl
                propertyKey={property.key}
                label={property.name}
                value={value as string}
                saveValue={handleSaveValue}
                variant={variant}
                controlInputProps={variant === "popover" ? popoverInputProps : otherInputProps}
            />
        );
    }

    if (controlHueTypes.includes(property.type)) {
        return (
            <Hue
                propertyKey={property.key}
                value={value as number}
                label={property.name as string}
                variant={variant}
                width={"100%"}
                fontSize={fontSize}
                saveValue={handleSaveValue}
                isDisabled={isDisabled}
                settings={settings}
            />
        );
    }

    if (controlSliderTypes.includes(property.type)) {
        return (
            <SliderControl
                propertyKey={property.key}
                value={value as number}
                label={property.name as string}
                min={property.min as number}
                max={property.max as number}
                saveValue={handleSaveValue}
                width={200}
                variant={variant}
                units={property.units}
                system_of_measures={systemOfMeasures}
                isDisabled={isDisabled}
                settings={settings}
            />
        );
    }

    if (controlMapPointType.includes(property.type)) {
        return (
            <MapPoint
                propertyKey={property.key}
                value={value as L.LatLngTuple}
                label={property.name as string}
                saveValue={handleSaveValue}
                variant={variant}
                isDisabled={isDisabled}
                settings={settings}
            />
        );
    }

    if (controlMapPolygonType.includes(property.type)) {
        return (
            <MapPolygon
                propertyKey={property.key}
                label={property.name as string}
                value={value as L.LatLngTuple[]}
                saveValue={handleSaveValue}
                variant={variant}
                object={object}
                isDisabled={isDisabled || variant === 'propertyCard'}
                settings={settings}
                userInfo={userInfo}
            />
        );
    }

    if (controlImagePointType.includes(property.type)) {
        return (
            <ImagePoint
                propertyKey={property.key}
                label={property.name as string}
                value={value as ImagePointItem}
                saveValue={handleSaveValue}
                isDisabled={isDisabled}
                variant={variant}
                maxWidth={variant === "popover" ? 400 : undefined}
                settings={settings}
                userInfo={userInfo}
            />
        );
    }

    if (controlImagePolygonType.includes(property.type)) {
        return (
        <ImagePolygon
                propertyKey={property.key}
                property_id={property.property_id}
                label={property.name as string}
                value={value as ImagePolygonItem}
                saveValue={handleSaveValue}
                isDisabled={isDisabled}
                variant={variant}
                maxWidth={imagePolygonMaxWidth}
                settings={settings}
                userInfo={userInfo}
        />
        );
    }

    if (controlPlanPolygonType.includes(property.type)) {
        return (
            <PointsFieldsControl
                propertyKey={property.key}
                label={property.name as string}
                value={value as any}
                enableDelete={true}
                saveValue={handleSaveValue}
                isDisabled={isDisabled}
                settings={settings}
                parentId={parentId}
                variant={variant}
                object={object}
                userInfo={userInfo}
                systemOfMeasures={systemOfMeasures}
            />
        );
    }

    if (controlPlanPointType.includes(property.type)) {
        return (
            <PlanPoint
                propertyKey={property.key}
                label={property.name as string}
                value={value as any}
                saveValue={handleSaveValue}
                isDisabled={isDisabled}
                settings={settings}
                parentId={parentId}
                variant={variant}
                object={object}
                userInfo={userInfo}
                systemOfMeasures={systemOfMeasures}
            />
        );
    }

    if (controlImageType.includes(property.type)) {
        return (
            <ViewImage
                id={property.property_id || property.key}
                label={property.name as string}
                value={value as string}
                saveValue={handleSaveValue}
                isDisabled={isDisabled}
                settings={settings}
                variant={variant}
                icon={object?.icon}
            />
        );
    }

    if (controlChatType.includes(property.type)) {
        return (
            <ChatMessageControl
                propertyKey={property.key}
                label={property.name}
                value={value as ChatMessageType}
                saveValue={handleSaveValue}
                variant={variant}
                controlInputProps={variant === "popover" ? popoverInputProps : otherInputProps}
            />
        );
    }

    if (controlFileType.includes(property.type)) {
        return (
            <FileControl
                id={property.property_id || property.key}
                label={property.name as string}
                value={value as FileType}
                saveValue={handleSaveValue}
                isDisabled={isDisabled}
                settings={settings}
                variant={variant}
            />
        );
    }

    if (variant === "propertyCard") {
        return (
            <Typography noWrap gutterBottom variant="h3">
                {getValueWithUnits(systemOfMeasures, value, property.units)}
            </Typography>
        );
    }


    return (
        <TextControlWithSetting
            label={property.name}
            value={value as string}
            units={property.units}
            settings={settings}
            systemOfMeasures={systemOfMeasures}
        />
    )
}

export default PropertyControls
