import React, {useEffect, useRef, useState} from "react";
import { useHistory } from "react-router-dom";
import {
    CssBaseline,
    TextField,
    Link,
    Box,
    Typography,
    Container,
    Grid,
    Button,
} from "@material-ui/core";
import { useGlobalNotificationContext } from "../../Notification/NotificationProvider";
import type { SnackBarType } from "../../../Services/NotificationService";
import { createPassword } from "../../../Services/AuthenticationService";
import { getUserCryptoInfo, setUserCryptoInfo } from "../../../Services/UserService";
import useAuthorizationStyles from "../AuthorizationStyles";
import Copyright from "../Copyright/Copyright";
import { ExtendedUserCrypto, generateUserCryptoKeys, buildCryptoData } from "../../../Services/CryptoService";
import { useGlobalLoaderActionsContext, useGlobalLoaderContext } from "../../../GlobalContext/GlobalContext";
import AlertDialog from "../../Dialogs/AlertDialog/AlertDialog";
import {useQuery} from "../../../Hooks/useQuery";

type CreatePasswordPropsType = {
    isResetPassword?: boolean;
}
type QueryParamsType = {
    token: string;
    sessionId: string;
    verificationCode: string;
    userId: string;
};

const CreatePassword = (props: CreatePasswordPropsType) => {
    const { isResetPassword = false } = props;

    const classes = useAuthorizationStyles();
    const history = useHistory();
    const query = useQuery();
    const notify: SnackBarType = useGlobalNotificationContext();

    const loader = useGlobalLoaderContext();
    const setLoader = useGlobalLoaderActionsContext();

    const passwordRef = useRef<HTMLInputElement>(null);
    const passwordSecondRef = useRef<HTMLInputElement>(null);

    const [queryParams] = useState<QueryParamsType>({
        token: query.get("token") || "",
        sessionId: query.get("session_id") || "",
        verificationCode: query.get("verification_code") || "",
        userId: query.get("user_id") || "",
    });
    const [openPasswordDialog, setOpenPasswordDialog] = useState<boolean>(false);

    const checkSearchParams = () => {
        if (!queryParams.token) {
            throw new Error("Token Not Found");
        }

        if (!queryParams.userId) {
            throw new Error("User ID Not Found");
        }

        if (!queryParams.sessionId) {
            throw new Error("Session ID not found");
        }

        if (!queryParams.verificationCode) {
            throw new Error("Verification code not found");
        }

        if (!passwordRef.current?.value) {
            throw new Error("Password Required");
        }

        if (passwordRef.current?.value !== passwordSecondRef.current?.value) {
            throw new Error("Password Mismatch");
        }
    }

    const saveUpdatedCryptoData = async (crypto: ExtendedUserCrypto) => {
        try {
            await createPassword({
                token: queryParams.token,
                password: passwordRef.current?.value || "",
                sessionId: queryParams.sessionId,
                verificationCode: queryParams.verificationCode,
                crypto
            });

            setUserCryptoInfo(queryParams.userId, crypto);
            notify.successNotify("Password updated successfully");
        } catch(err: any) {
            throw new Error(err);
        }
    }

    const createPasswordHandler = async () => {
        try {
            await checkSearchParams();
            setLoader(true);
            let cryptoData = getUserCryptoInfo(queryParams.userId);

            if (!cryptoData) {
                const generatedCryptoData = await generateUserCryptoKeys(passwordRef.current?.value || "");
                cryptoData = buildCryptoData(generatedCryptoData);
            }

            await saveUpdatedCryptoData(cryptoData);
            history.replace("/login");
        } catch (err: any) {
            notify.errorNotify(err?.message || JSON.stringify(err));
        } finally {
            setLoader(false);
        }
    };

    const resetPasswordHandler = async () => {
        try {
            await checkSearchParams();
            setLoader(true);
            let cryptoData = getUserCryptoInfo(queryParams.userId);

            if (!cryptoData) {
                setLoader(false);
                setOpenPasswordDialog(true);
                return notify.warningNotify("This is an unknown client. Please, use the known clients to reset your " +
                    "password for saving your access to encrypted data.");
            }

            await saveUpdatedCryptoData(cryptoData);
            history.replace("/login");
        } catch (err: any) {
            notify.errorNotify(err?.message || JSON.stringify(err));
        } finally {
            setLoader(false);
        }
    };

    const onClickRememberPassword = () => {
        if (loader) {
            return;
        }

        history.push("/login");
    };

    const continueHandler = async() => {
        setOpenPasswordDialog(false);

        try {
            await checkSearchParams();

            setLoader(true);

            const generatedCryptoData = await generateUserCryptoKeys(passwordRef.current?.value || "");

            const cryptoData = buildCryptoData(generatedCryptoData);

            await saveUpdatedCryptoData(cryptoData);

            history.replace("/login");
        } catch (e: any) {
            notify.errorNotify(e?.message || JSON.stringify(e));
        } finally {
            setLoader(false);
        }
    }

    const cancelHandler = () => {
        setOpenPasswordDialog(false);
    }

    useEffect(() => {
        const { token, sessionId, verificationCode, userId } = queryParams;
        if (!token || !sessionId || !verificationCode || !userId) {
            notify.errorNotify("Incorrect URL");
            history.replace("/");
            return;
        }
    }, [queryParams]);

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box className={classes.paper}>
                <Typography component="h1" variant="h5" gutterBottom>
                    Create New Password
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            id="password"
                            label="New Password"
                            name="password"
                            autoComplete="current-password"
                            type="password"
                            disabled={loader}
                            inputRef={passwordRef}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            id="passwordSecond"
                            label="Repeat Password"
                            name="password"
                            autoComplete="current-password"
                            type="password"
                            disabled={loader}
                            inputRef={passwordSecondRef}
                        />
                    </Grid>
                </Grid>
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={loader}
                    onClick={isResetPassword ? resetPasswordHandler : createPasswordHandler}
                >
                    { isResetPassword ? "Update Password" : "Create password" }
                </Button>
                <Grid container justifyContent="flex-end">
                    <Grid item>
                        <Link onClick={onClickRememberPassword} variant="body2">
                            Remember password? Sign in
                        </Link>
                    </Grid>
                </Grid>
            </Box>
            <AlertDialog
                open={openPasswordDialog}
                dialogTitle="This client is unknown"
                dialogContent={"This client is unknown. Please, use the known clients to reset your password" +
                    " for saving your access to encrypted data. " +
                    "If you want continue to reset password using this client, Click CONTINUE button. " +
                    "In this case will be generate new encryption keys and everything existing object" +
                    " data and object history will be not available, without the possibility of recovery." +
                    " If you do not agree click CANCEL button."}
                agreeText={"Continue"}
                disagreeText={"Cancel"}
                handleAgree={continueHandler}
                handleDisagree={cancelHandler}
            />
            <Box mt={5}>
                <Copyright />
            </Box>
        </Container>
    );
};

export default  CreatePassword;
