import React, {useEffect, useState} from "react";
import Typography from "@material-ui/core/Typography";
import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';
import DatePickerControl from "../../../../Controls/DatePickerControl";
import RadioButtonsControl from "../../../../Controls/RadioButtonsControl";
import {UserPropertyItemProps} from "../../UserProfileTypes";
import {useUserProfileStyles} from "../../UserProfileStyles";
import {Skeleton} from "@material-ui/lab";
import UserPropertyItemAction from "./UserPropertyItemAction/UserPropertyItemAction";
import UserPropertyItemContent from "./UserPropertyItemContent/UserPropertyItemContent";
import {useGlobalLoaderContext} from "../../../../../GlobalContext/GlobalContext";
import {useMediaQuery, useTheme} from "@material-ui/core";


const genderData = [
    {
        label: "Female",
        value: "female",
    },
    {
        label: "Male",
        value: "male",
    },
    {
        label: "Other",
        value: "other"
    }
];

const SYSTEM_OF_MEASURES = [
    {
        label: "imperial",
        value: "imperial",
    },
    {
        label: "metric",
        value: "metric",
    }
]

const UserPropertyItem = (props: UserPropertyItemProps) => {
    const classes = useUserProfileStyles();
    const theme = useTheme();
    const downXs = useMediaQuery(theme.breakpoints.down("xs"));

    const {name, value, divider, imageSrc, isAvatar, itemKey, updateItem, disabled} = props;

    const loader = useGlobalLoaderContext();

    const [editMode, setEditMode] = useState<boolean>(false);
    const [editableValue, setEditableValue] = useState<string>(value);

    let button = {button: true as true};
    if (editMode) {
        delete (button as {button?: true|undefined}).button;
    }

    const handleTextFieldChange = (event: React.FocusEvent<HTMLInputElement>) => {
        setEditableValue(event.target.value);
    };

    const handleEditModeClick = () => {
        if (editMode || isAvatar || loader) {
            return;
        }
        setEditMode(true);
    };

    const handleDatePickerChange = (date: string) => {
        setEditableValue(date);
        updateItem(itemKey, date);
        setEditMode(false);
    };

    const handleRadioButtonsChange = (date: string) => {
        setEditableValue(date);
        updateItem(itemKey, date);
        setEditMode(false);
    };

    const handleAgreeClick = () => {
        if (!editMode || loader) {
            return;
        }

        setEditableValue((prev) => {
            updateItem(itemKey, prev);
            setEditMode(false);
            return prev;
        });
    };

    const handleDisagreeClick = () => {
        if (!editMode || loader) {
            return;
        }

        setEditableValue(value);
        setEditMode(false);
    };

    const getGenderLabel = (gender: string) => {
        const found = genderData.find((item) => {
            return item.value === gender;
        });

        return found ? found.label : gender;
    };

    const renderSwitch = () => {
        switch (itemKey) {
            case "gender":
                return value || !loader ? getGenderLabel(value as string) : (
                    <Skeleton animation="wave" height={30} width={150}/>);
            case "email":
                return editableValue || !loader ? editableValue : (
                    <Skeleton animation="wave" height={30} width={150}/>);
            default:
                return value || !loader ? value : (<Skeleton animation="wave" height={30} width={150}/>);
        }
    }

    useEffect(() => {
        setEditableValue(value);
    }, [value]);

    let userContent;
    if (isAvatar || !editMode) {
        userContent = <Typography
            variant={isAvatar ? (downXs ? "caption" : "body2") : (downXs ? "caption" : "subtitle1")}
            color={isAvatar ? "textSecondary" : (itemKey === "email" && !editableValue ? "error" : "initial")}
        >
            {renderSwitch()}
        </Typography>
    } else if (itemKey === "age") {
        userContent = <DatePickerControl
            date={editableValue}
            onChange={handleDatePickerChange}
        />
    } else if (itemKey === "gender") {
        userContent = <RadioButtonsControl
            data={genderData}
            value={editableValue as string || "other"}
            onChange={handleRadioButtonsChange}
        />
    } else if (itemKey === "system_of_measures") {
        userContent = <RadioButtonsControl
            data={SYSTEM_OF_MEASURES}
            value={editableValue as string || "metric"}
            onChange={handleRadioButtonsChange}
        />
    } else {
        userContent = <TextField fullWidth value={editableValue} onChange={handleTextFieldChange}/>;
    }

    const userActions = isAvatar ?
        <Avatar src={imageSrc} className={classes.largeAvatar}/>
        :
        <UserPropertyItemAction
            editMode={editMode}
            handleAgree={handleAgreeClick}
            handleChangeMode={handleEditModeClick}
            handleDisagree={handleDisagreeClick}
            disabled={disabled}
        />

    return (
        <UserPropertyItemContent
            actions={userActions}
            content={userContent}
            title={name}
            editMode={editMode}
            divider={divider}
            handleChangeMode={handleEditModeClick}
            disabled={disabled}
        />
    )
};

export default UserPropertyItem;
