import {AbstractElement, ElementData, ElementType} from "../AbstractElement";
import {Color, CompoundPath, Group, Path, Point, PointText, Raster} from "paper";
import {v4} from "uuid";
import {hitOptionsAll, MM_PER_PIXEL} from "../../../Constants";
import {
    getObjectById,
    ImagePolygonItem,
    imagePropertyKey,
    PointTuple,
    PropertyItem,
    subPropertiesValueByKey,
    unsubPropertiesValueByKey
} from "../../../../../Services/ObjectsService";
import {
    _transformImage,
    changeImageSize,
    getMaxPoint,
    getMinPoint,
} from "../../../../../Services/ImageTransformService";
import {OriginType} from "../../../../../Services/ObjectsService/Constants";

export class ZoneElement extends AbstractElement {
    protected selectedPath?: any;
    protected selectedSegment?: paper.Segment;

    protected image?: any;
    protected imageObjectId?: any;
    protected subId?: string;
    public isDisabled: any = false;
    protected mathCount = () => {
        return {
            label: this.isDisabled ? 20 : 30,
            marker: this.isDisabled ? 9 : 13
        }
    }
    protected delta: any = {x: 0, y: 0};
    protected callDisplayImage = false;

    protected setPropertiesPlanBounds(segments: paper.Segment[]) {
        if (segments && Array.isArray(segments)) {
            this.setProperty(
                "plan_polygon",
                segments.map((segment: paper.Segment) => {
                    return [segment.point.x * MM_PER_PIXEL, segment.point.y * MM_PER_PIXEL];
                })
            );
        }
    }

    protected createNewLabel = (point: any, index: number) => {
        let marker: any;
        if (this.isDisabled) {
            marker = new CompoundPath(`M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-6 400H54c-3.3 0-6-2.7-6-6V86c0-3.3 2.7-6 6-6h340c3.3 0 6 2.7 6 6v340c0 3.3-2.7 6-6 6z`);
            marker.scale(1 / 30)
        } else {
            marker = new CompoundPath(`M500 224L469.636 224C 455.724 130.325 381.675 56.276 288 42.364L288 42.364L288 12C 288 5.373 282.627 0 276 0L276 0L236 0C 229.373 0 224 5.373 224 12L224 12L224 42.364C 130.325 56.276 56.276 130.325 42.364 224L42.364 224L12 224C 5.373 224 0 229.373 0 236L0 236L0 276C 0 282.627 5.373 288 12 288L12 288L42.364 288C 56.276 381.675 130.325 455.724 224 469.636L224 469.636L224 500C 224 506.627 229.373 512 236 512L236 512L276 512C 282.627 512 288 506.627 288 500L288 500L288 469.636C 381.675 455.724 455.724 381.675 469.636 288L469.636 288L500 288C 506.627 288 512 282.627 512 276L512 276L512 236C 512 229.373 506.627 224 500 224zM288 404.634L288 364C 288 357.373 282.627 352 276 352L276 352L236 352C 229.373 352 224 357.373 224 364L224 364L224 404.634C 165.826 392.232 119.783 346.243 107.366 288L107.366 288L148 288C 154.627 288 160 282.627 160 276L160 276L160 236C 160 229.373 154.627 224 148 224L148 224L107.366 224C 119.768 165.826 165.757 119.783 224 107.366L224 107.366L224 148C 224 154.627 229.373 160 236 160L236 160L276 160C 282.627 160 288 154.627 288 148L288 148L288 107.366C 346.174 119.768 392.217 165.757 404.634 224L404.634 224L364 224C 357.373 224 352 229.373 352 236L352 236L352 276C 352 282.627 357.373 288 364 288L364 288L404.634 288C 392.232 346.174 346.243 392.217 288 404.634zM288 256C 288 273.673 273.673 288 256 288C 238.327 288 224 273.673 224 256C 224 238.327 238.327 224 256 224C 273.673 224 288 238.327 288 256z`);
            marker.scale(1 / 15)
        }
        marker.set({
            position: point,
            fillColor: "blue",
            strokeColor: 'black',
            locked: true,
            selected: false,
        });

        const newPoint = new Point(point.x, point.y - this.mathCount().label);
        const label = new PointText({
            fontSize: 12,
            justification: "center",
            point: newPoint,
            fillColor: "black",
            content: `p${index}`,
            locked: true,
            selected: false,
            opacity: 0.5,
            style: {
                backgroundColor: "white",
                border: "1px solid black",
                borderRadius: 6,
                padding: 3
            },
        });

        const backLabel = new Path.Rectangle({
            point: [label.bounds.x - 5, label.bounds.y - 7.5],
            size: [20 + 5 * index.toString().length, 30],
            strokeColor: "black",
            radius: 6,
            fillColor: "white",
            opacity: 0.5,
            locked: true,
            selected: false,
        });

        const backMarker = new Path.Circle({
            center: point,
            strokeColor: "black",
            radius: 20,
            fillColor: "white",
            opacity: 0,
            locked: false,
            selected: false,
        });

        return new Group({
            children: [backMarker, marker, backLabel, label],
            locked: this.isDisabled,
            selected: false,
            position: [point.x, point.y - this.mathCount().marker],
            onMouseEnter: () => {
                if (this.isDisabled) return;
                marker.set({fillColor: "white"});
                label.opacity = 1;
                backLabel.opacity = 1;
            },
            onMouseLeave: () => {
                if (this.isDisabled) return;
                marker.set({fillColor: "blue"});
                label.opacity = 0.5;
                backLabel.opacity = 0.5;
            },
        });
    }

    protected displayLabelForPoints() {
        if (!this.paperJSItem) {
            return;
        }

        if (Array.isArray(this.paperJSItem.children) && this.paperJSItem.children[3]) {
            this.paperJSItem.children[3].removeChildren(0);
        }

        if (!this.isSelected()) {
            return;
        }

        const planBounds = this.getProperty("plan_polygon");

        planBounds.forEach((point: PointTuple, index: number) => {
            const x = point[0] / MM_PER_PIXEL;
            const y = point[1] / MM_PER_PIXEL;

            this.paperJSItem.children[3].removeChildren(index, index + 1);
            this.paperJSItem.children[3].insertChild(index, this.createNewLabel(new Point(x, y), index));
        });
    }

    protected displayZoneName() {
        if (!this.paperJSItem) {
            return;
        }

        if (!this.paperJSItem.children[2].children || !this.paperJSItem.children[2].children[0]) {
            this.paperJSItem.children[2].addChild(
                new PointText({
                    fontSize: 12,
                    justification: "center",
                })
            );
        }

        const content = this.paperJSItem.children[0].segments.length < 3 ? "" : this.getElementData().info.object_name;
        this.paperJSItem.children[2].children[0].point = this.paperJSItem.bounds.center.clone();
        this.paperJSItem.children[2].children[0].content = content;
    }

    protected displayImage() {
        if (!this.paperJSItem || !this.paperJSItem.children[2] || this.callDisplayImage) {
            return;
        }

        let imagePolygon: ImagePolygonItem | null = null;

        try {
            imagePolygon = this.getProperty("image_polygon");
        } catch (e) {
            console.log("ImagePolygon property does not exist for this Zone type");
        }

        if (!imagePolygon?.object_id) {
            return;
        }

        const objectId = imagePolygon.object_id as string;

        if (objectId !== this.imageObjectId) {
            if (this.imageObjectId) {
                console.log("unsubscribeProperties");
                if (this.subId) {
                    unsubPropertiesValueByKey(objectId, this.subId);
                }
            }

            if (objectId) {
                console.log("subscribeProperties");
                this.subId = v4();

                subPropertiesValueByKey(objectId, imagePropertyKey, this.subId, OriginType.USER, (keys, data) => {
                    if (keys.objectId !== objectId) {
                        console.error("Incorrect Object ID");
                        return;
                    }

                    this.image = (data as PropertyItem)?.value;
                    this.updateTransformedImage();
                });
            }

            this.imageObjectId = objectId;
        }

        if (!this.image) {
            (async () => {
                try {
                    const response = await getObjectById(objectId);

                    if (!this.image && response?.properties?.image?.value) {
                        this.image = response.properties.image.value;
                        this.updateTransformedImage();
                    }
                } catch (e) {
                    console.error(e);
                }
            })();
        } else {
            this.updateTransformedImage();
        }
    }

    protected updateTransformedImage() {
        if (!this.paperJSItem || !this.paperJSItem.children[2] || !this.image) {
            return this.paperJSItem.children[2].clear();
        }

        const planBounds = this.getProperty("plan_polygon");
        let imagePolygon: ImagePolygonItem | null = null;

        try {
            imagePolygon = this.getProperty("image_polygon");
        } catch (e) {
            console.log("ImagePolygon property does not exist for this Zone type");
        }

        if (!imagePolygon) {
            return this.paperJSItem.children[2].clear();
        }

        const points = imagePolygon.points;
        // const objectId = imagePolygon.object_id as string;

        const changed = planBounds.map((point: PointTuple) => {
            return [point[0] / MM_PER_PIXEL, point[1] / MM_PER_PIXEL];
        });

        const max = getMaxPoint(changed);
        const min = getMinPoint(changed);

        _transformImage(this.image, points, changed, false, (result: HTMLCanvasElement | null) => {
            if (!result) {
                return this.paperJSItem.children[2].clear();
            }
            changeImageSize(result.toDataURL(), max[0] - min[0], max[1] - min[1], (res: HTMLCanvasElement | null) => {
                if (res) {
                    this.paperJSItem.children[2].canvas = res;
                    this.paperJSItem.children[2].position = this.paperJSItem.children[0].bounds.center.clone();
                } else {
                    this.paperJSItem.children[2].clear();
                }
            });
        });
    }

    protected setPropertiesAccordingToCurrentGeometry() {
        if (!this.paperJSItem) {
            return;
        }
        this.paperJSItem.children[0].onFrame = () => {
            this.displayLabelForPoints();
        }
        this.setPropertiesPlanBounds(this.paperJSItem.children[0].segments);
    }

    public isSelected(): Boolean {
        return this.paperJSItem.children[0].selected;
    }

    public onMouseDown(event: any): void {
        console.log("ZoneOnMouseDown");

        this.selectedSegment = undefined;
        this.selectedPath = undefined;
        this.paperJSItem.children[0].selected = true;
        if (this.isDisabled) return;
        const hitResult: paper.HitResult = this.paperJSItem.children[0].hitTest(event.point, {
            ...hitOptionsAll,
            tolerance: 22
        });
        if (!hitResult) {
            this.selectedSegment = this.paperJSItem.children[0].add(new Point(event.point));
            this.setPropertiesPlanBounds(this.paperJSItem.children[0].segments);
        }

        if (hitResult) {
            this.selectedPath = hitResult.item;
            if (hitResult.type === "fill") {
                this.selectedSegment = hitResult.segment;
                this.delta = new Point(hitResult.item.position.x - event.downPoint.x, hitResult.item.position.y - event.downPoint.y);
            } else if (hitResult.type === "segment") {
                this.selectedSegment = hitResult.segment;
                this.delta = new Point(hitResult.segment.point.x - event.point.x, hitResult.segment.point.y - event.point.y);
            } else if (hitResult.type === "stroke") {
                const location = hitResult.location;
                this.selectedSegment = this.selectedPath.insert(location.index + 1, event.point);
                this.setPropertiesPlanBounds(this.selectedPath.segments);
            }
        }
    }

    public onMouseDrag(event: any): void {
        console.log("ZoneOnMouseDrag");
        if (this.selectedSegment) {
            this.callDisplayImage = true;
            const _point = new Point(event.point.x + this.delta.x, event.point.y + this.delta.y);
            this.selectedSegment.point = _point;
            const planBounds = this.getProperty("plan_polygon");
            const newPoint = [_point.x * MM_PER_PIXEL, _point.y * MM_PER_PIXEL];
            planBounds[this.selectedSegment.index] = newPoint;
            this.setProperty("plan_polygon", planBounds);
        } else if (this.selectedPath) {
            this.callDisplayImage = true;
            this.selectedPath.position = new Point(event.point.x + this.delta.x, event.point.y + this.delta.y);
            this.setPropertiesPlanBounds(this.selectedPath.segments);
        }
    }

    public onMouseUp(event: any): void {
        console.log("ZoneOnMouseUp");
        this.delta = {x: 0, y: 0}
        this.callDisplayImage = false;
        if (!this.paperJSItem) {
            return;
        }
        this.setPropertiesPlanBounds(this.paperJSItem.children[0].segments);
    }

    public updateAlignment(elements: AbstractElement[]): void {
    }

    /**
     Alignment method. Updates properties using the alignment information.
     I.e. sets properties according to the position of the elements
     the current element has to stick to.
     */
    public alignElement(elements: AbstractElement[]): void {
    }

    public setSelect(select: boolean) {
        this.paperJSItem.children[0].selected = select;
    }

    /**
     Geometry update according to the properties set
     */
    public updateGeometry(): void {
        const planBounds = this.getProperty("plan_polygon");
        if (!Array.isArray(planBounds)) {
            return;
        }

        this.paperJSItem.children[0].segments = planBounds.map((point: PointTuple) => {
            return new Point(point[0] / MM_PER_PIXEL, point[1] / MM_PER_PIXEL);
        });

        // this.displayLabelForPoints();
        // This is now displayed as a marker
        // this.displayZoneName();

        this.displayImage();

        const hue = this.getProperty("color");
        this.paperJSItem.children[0].fillColor = new Color(`hsla(${hue}, 50%, 50%, 0.5)`);
        this.paperJSItem.children[0].strokeColor = new Color(`hsl(${hue}, 50%, 50%)`);
    }

    protected generatePaperJSItem(): void {
        let polygon: paper.Path = new Path({
            segments: [],
            closed: true,
            onFrame: () => {
                this.displayLabelForPoints();
            }
        });

        let labels: paper.Group = new Group({
            children: [],
        });

        let polygonName: paper.Group = new Group({
            children: [],
        });

        let polygonImage: paper.Raster = new Raster({
            position: polygon.bounds.center.clone(),
        });

        let path: paper.Group = new Group({
            children: [polygon, polygonName, polygonImage, labels],
        });

        this.paperJSItem = path;
    }

    protected setDefaultData(): void {
        let data: ElementData = {
            id: v4(),
            type: ElementType.ZONE_ELEMENT,
            info: {
                object_name: "Zone",
                description: "Zone",
            },
            properties: {
                plan_polygon: {
                    key: "plan_polygon",
                    property_id: v4(),
                    name: "PlanBounds",
                    value: {},
                    type: "PlanPolygon",
                    readable: true,
                    writable: false,
                    visibility: ["details"],
                },
                color: {
                    key: "color",
                    property_id: v4(),
                    name: "Color",
                    value: 230,
                    type: "Hue",
                    readable: true,
                    writable: false,
                    visibility: ["details"],
                },
            },
            // Visualisation formulas
            formulas: {},
            // Visualisation values
            currentValues: {},
            alignment: {},
        };

        this.paperJSItem.data = data;
    }
}
