import React, {FormEvent, useRef} from "react";
import { useHistory } from "react-router-dom";
import {Button, TextField, FormControlLabel, Checkbox, Link, Grid} from "@material-ui/core";
import {useGlobalNotificationContext} from "../../../Notification/NotificationProvider";
import type {SnackBarType} from "../../../../Services/NotificationService";
import { saveRememberMe, signIn } from "../../../../Services/AuthenticationService";
import {UserInfoType} from "../../../../Hooks/useUserProfile";
import useAuthorizationStyles from "../../AuthorizationStyles";
import {useGlobalLoaderActionsContext, useGlobalLoaderContext} from "../../../../GlobalContext/GlobalContext";
import {isEmail} from "../../../../Services/Utils";
import {signOutUser, currentUserChanged} from "../../../../Services/DeviceService";
import {clearIdentificationCache} from "../../../../Services/ObjectsService/ObjectsNativeService/ObjectsIdentificationService/ObjectsIdentificationService";
import {clearObjectCache} from "../../../../Services/ObjectsService";

type SignInProps = {
    userInfo: UserInfoType;
    toggleLogin():void;
};

const SignIn = (props: SignInProps) => {
    const classes = useAuthorizationStyles();
    const history = useHistory();

    const notify: SnackBarType = useGlobalNotificationContext();
    const loader = useGlobalLoaderContext();
    const setLoader = useGlobalLoaderActionsContext();

    const emailRef = useRef<HTMLInputElement>(null);
    const passwordRef = useRef<HTMLInputElement>(null);
    const rememberMeRef = useRef<HTMLInputElement>(null);

    const loginButtonClicked = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!emailRef.current?.value) {
            return notify.errorNotify("Email is required");
        }

        if (!passwordRef.current?.value) {
            return notify.errorNotify("Password is required");
        }

        if (!isEmail(emailRef.current.value)) {
            return notify.errorNotify("Email is invalid");
        }

        setLoader(true);

        try {
            const result  = await signIn(emailRef.current.value, passwordRef.current.value);
            // Send a message to Native that Guest user changed to signed-in [AS]
            signOutUser();
            // And clear caches specific for every user [AS]
            clearIdentificationCache();
            clearObjectCache();

            await props.userInfo.setUser(result, true);
            currentUserChanged();
        } catch (err: any) {
            notify.errorNotify(err?.message || JSON.stringify(err));
        } finally {
            setLoader(false);
        }
    };

    const rememberMeHandler = () => {
        if (!rememberMeRef.current) return;
        saveRememberMe(rememberMeRef.current.checked)
    }

    return (
        <form className={classes.form} noValidate onSubmit={loginButtonClicked}>
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                type="email"
                disabled={loader}
                autoFocus
                inputRef={emailRef}
            />
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                disabled={loader}
                inputRef={passwordRef}
            />
            <FormControlLabel disabled={loader} control={<Checkbox inputRef={rememberMeRef} onChange={rememberMeHandler} color="primary" />} label="Remember me" />
            <Button fullWidth variant="contained" color="primary" className={classes.submit} type="submit" disabled={loader}>
                Sign In
            </Button>
            <Grid container>
                <Grid item xs>
                    <Link onClick={()=> {
                        if (loader) return;
                        history.push("/forgot-password");
                    }} variant="body2">
                        Forgot password?
                    </Link>
                </Grid>
                <Grid item>
                    <Link variant="body2" onClick={props.toggleLogin}>
                        {"Don't have an account? Sign Up"}
                    </Link>
                </Grid>
            </Grid>
        </form>
    );
}

export default SignIn;
