import React, {useCallback, useState} from "react";
import { Grid } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import {ObjectItem} from "../../../../../Services/ObjectsService/Types";
import {useGlobalLoaderActionsContext} from "../../../../../GlobalContext/GlobalContext";
import {postObjectAccessRequest} from "../../../../../Services/ObjectsService";
import ButtonWithLoader from "../../../../Common/ButtonWithLoader/ButtonWithLoader";
import LockOpenIcon from "@material-ui/icons/LockOpen";


type RequestAccessOnCardProps = {
    object: ObjectItem;
};

const RequestAccessOnCard = (props: RequestAccessOnCardProps) => {

    const {object} = props;

    const setGlobalLoader = useGlobalLoaderActionsContext();

    const [loader, setLoader] = useState<boolean>(false);

    const onClick = useCallback(async () => {
        try {
            setGlobalLoader(true);
            setLoader(true);

            await postObjectAccessRequest(object.object_id);
        } catch (e) {
            console.error(e);
        } finally {
            setLoader(false);
            setGlobalLoader(false);
        }
    }, [object.object_id]);

    if (!object?.object_id) {
        return (
            <Grid container justifyContent="center">
                <Grid container item justifyContent="center">
                    <Skeleton animation="wave" height={65} width="75%"/>
                </Grid>
            </Grid>
        )
    }

    if (object?.encryption_key) {
        return null;
    }

    return (<>
        <ButtonWithLoader onClick={onClick} loading={loader} buttonProps={{startIcon: <LockOpenIcon/>}} value={"Request Access"}/>
    </>);
};

export default RequestAccessOnCard;