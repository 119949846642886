import React, {Fragment, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import {Map, TileLayer} from "react-leaflet";
import {Fab} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import {
    convertChildrenToStringArray,
    getObjectById,
    getObjects,
    getPublicObjects,
    isObjectAvailableToDisplay,
    MapObject,
    ObjectItem,
    Params,
    subscribeAllPublicObjects,
    unsubscribeAllPublicObjects
} from "../../../Services/ObjectsService";
import L from "leaflet";
import {v4} from "uuid";
import ObjectMapProperties from "./ObjectMapProperties";
import LocateControl from "./LocationControl";
import {useLocation, useRouteMatch} from "react-router-dom";
import { MAP_BOUNDS, MAP_CENTER, MAP_ZONE_ELEMENT_OBJECT_TYPE } from "./Constants";
import ChildrenOnMap from "./ChildrenOnMap/ChildrenOnMap";
import {useSiteMapStyles} from "./SiteMapStyles";
import {ObjectSiteMapProps} from "./TypesSiteMap";
import EditElements from "./EditElements/EditElements";
import {inPolygon} from "./MapUtils";
import {useSubMap} from "../../../Hooks/Subscriptions/useSubMap";
import {
    getEpochTime,
    isObjectPublic,
    isUserHasAccess,
    latitudeToYTile,
    longitudeToXTile,
    wait,
} from "../../../Services/Utils";
import {AccessLevel, OriginType} from "../../../Services/ObjectsService/Constants";
import useMapController from "../../../Hooks/MapController/useMapController";
import {getClientId} from "../../../Services/UserService";
import {useGlobalLoaderActionsContext} from "../../../GlobalContext/GlobalContext";
import {useSubUndo, useSubRedo, useSubRemove, useSubSubmit} from "../../../Hooks/SystemEvent/Toolbar/useToolbar";
import {useSubPublicObjects, useSubOwnObjects} from "../../../Hooks/SystemEvent/Filters/useFilters";
import { getOrgHierarchy } from "Helpers/OrganizationsHelper";
import { useSubOrganization } from "../../../Hooks/SystemEvent/useContext";

const zoneColorsByType: { [key: string]: number } = {
    Zone: 115,
    CameraMapZone: 230,
};

export function SiteMap(props: ObjectSiteMapProps) {
    const {
        parentId,
        userInfo,
        notify,
        setObject,
        displayModalBind,
        openModalBind,
        saveObjectByKey,
        handleBackButton
    } = props;

    const setLoader = useGlobalLoaderActionsContext();

    const siteMapController = useMapController();

    const [elementObjectsOpened, setElementObjectsOpened] = useState(false);
    const [propertiesObjectOpened, setPropertiesObjectOpened] = useState(false);
    const [position, setPosition] = useState<L.LatLngTuple>(MAP_CENTER);
    const [createMode, setCreateMode] = useState<boolean>(false);
    const [zoom, setZoom] = useState<number>(2);
    const [zoomed, setZoomed] = useState<number>(2);
    const [layer, setLayer] = useState<string>("map");
    const [currentPath, setCurrentPath] = useState<string>("");
    const [zoneType, setZoneType] = useState<string>("");

    const [publicRequest, setPublicRequest] = useState<boolean>(true);
    const [ownObjects, setOwnObjects] = useState<boolean>(true);

    const location = useLocation();

    const selectionHistory = useRef<string | null>(null);
    const objectParent = useRef<ObjectItem | null>(null);
    const lastPromiseRef = useRef<Promise<ObjectItem[]>>(); //fix for getListObjectsToDisplayOnMap request trace
    const mapRef = useRef<any>(null);
    const isLoad = useRef<boolean>(false);
    const orgObjects = useRef<string[]>([]);

    const editMode = !!useRouteMatch("(.*)/map/edit");
    const isSite = !!useRouteMatch("/sites/(.*)/map");

    const classes = useSiteMapStyles({public_map: !editMode ? publicRequest : false, open: true});

    const [tileMinMaxValue, setTileMinMaxValue] = useState<{minX: number; minY: number; maxX: number; maxY: number} | null>(null);

    const [mapDraggable, setMapDraggable] = useState<boolean>(true);
    const [showMarkers, setShowMarkers] = useState<boolean>(true);

    const [children, setChildren] = useState<string[] | null>(null);

    const [keepCurrentZoomLevel, setKeepCurrentZoomLevel] = useState<boolean>(false);

    useSubPublicObjects((data) => {
        if (data?.boolState !== undefined) {
            setPublicRequest(data.boolState);
        }
    });

    useSubOwnObjects((data) => {
        if (data?.boolState !== undefined) {
            setOwnObjects(data.boolState);
        }
    });

    const getListObjectsToDisplayOnMap = async () => {
        let countPublic = 0;

        const checkObjectToInclude = (contains: boolean, isPublic: boolean,) => {
            if (contains && isPublic) {
                if (countPublic < 100) {
                    countPublic++;
                    return contains;
                }
                return false;
            }

            return contains;
        };

        let params: Params = {
            publicRequest: !editMode ? publicRequest : false,
            ownObjects: ownObjects
        };

        if (!isSite) {
            orgObjects.current = await getOrgHierarchy();
            params.insideHierarchy = orgObjects.current;
        }

        if (children) {
            params.children = children;
        }

        // const geoBounds = getScreenMapBounds();
        //
        // if (geoBounds) {
        //     params.geo_bounds_limit = geoBounds;
        // }

        let _objects = await getObjects(params);

        // _objects = _objects.map((object: ObjectItem) => {
        //     let properties = [];
        //
        //     for (let key in object.properties) {
        //         properties.push(object.properties[key]);
        //     }
        //     return {...object, properties: properties};
        // });

        if (!mapRef.current) {
            return [];
        }

        const map = mapRef.current.leafletElement;
        const bounds = map.getBounds();

        return _objects.filter((_object: ObjectItem) => {
            if (!_object || (!isUserHasAccess(_object, AccessLevel.CONTROL) && createMode) || !_object.properties) {
                return false;
            }
            if (!_object.properties.map_point?.value && !_object.properties.map_polygon?.value?.length) {
                return false;
            }
            // Hide Unknown objects [AS]
            if (!isObjectAvailableToDisplay(_object)) {
                return false;
            }

            if (!children || children?.includes(_object.object_id)) {
                if (MAP_ZONE_ELEMENT_OBJECT_TYPE.includes(_object.object_type)) {
                    const zone = _object.properties.map_polygon?.value;
                    const contains = bounds.contains(zone) || bounds.intersects(zone);
                    return checkObjectToInclude(contains, isObjectPublic(_object.permissions));
                }

                if (_object?.properties["map_point"]) {
                    const point = _object?.properties["map_point"].value;
                    const contains = bounds.contains(point);
                    return checkObjectToInclude(contains, isObjectPublic(_object.permissions));
                }
            }
            return false;
        });
    };

    useSubOrganization(() => {
        if (!isSite) {
            updateListObjectsOnMap().then(() => {});
        }
    });

    useEffect(() => {
        if (((Array.isArray(children) && children.length === 0) || !currentPath) && !isSite) {
            siteMapController.setObjects([]);
            return unmount();
        }

        (!editMode || !isSite) && updateListObjectsOnMap();

        return () => {
            unmount();
        };
    }, [currentPath, publicRequest, ownObjects]);

    useEffect(() => {
        if ((children && children.length > 0) || !Array.isArray(children) || siteMapController) {
            setCurrentPath(location.pathname);
        }
    }, [children, location]);

    const scale = useMemo(() => {
        if (zoomed < 12) {
            return null;
        }

        return zoomed < 15 ? zoomed - 1 : 14;
    }, [zoomed]);

    useEffect(() => {
        if (!tileMinMaxValue || !scale) {
            return;
        }

        const minX = tileMinMaxValue?.minX;
        const minY = tileMinMaxValue?.minY;

        const maxX = tileMinMaxValue?.maxX;
        const maxY = tileMinMaxValue?.maxY;

        const tileNames: string[] = [];
        for (let x = minX; x <= maxX; x++) {
            for (let y = minY; y <= maxY; y++) {
                tileNames.push(`${scale}/${x}/${y}`);
            }
        }

        const subscriptionId = v4();
        subscribeAllPublicObjects(subscriptionId, tileNames);

        return () => {
            void unsubscribeAllPublicObjects(subscriptionId, tileNames);
        };
    }, [tileMinMaxValue?.maxX, tileMinMaxValue?.maxY, tileMinMaxValue?.minX, tileMinMaxValue?.minY]);

    useSubSubmit(async () => {
        try {
            setLoader(true);
            await siteMapController?.saveMap(parentId, {origin: OriginType.USER, clientId: getClientId(), parentOwnerId: objectParent.current?.owner_id});

            //TODO Possibly we should use this logic to update the top-level object state. This code was used earlier on MainContent [AS]
            //    let _children = object.children?.concat([]) || [];
            //                 saveObjects.forEach((_object) => {
            //                     if (!_children.includes(_object.object_id)) {
            //                         _children.push(_object.object_id);
            //                     } else {
            //                         _children = _children.filter((childId) => childId !== _object.object_id);
            //                     }
            //                 });
            //                 saveObjectByKey("children", _children);
        } catch (e: any) {
            notify.errorNotify(e?.message || JSON.stringify(e));
        } finally {
            handleBackButton();
            setLoader(false);
        }
    });

    useSubUndo(() => {
        siteMapController?.makeUndo();
    });

    useSubRedo(() => {
        siteMapController?.makeRedo();
    });

    useSubRemove(() => {
        siteMapController?.deleteSelectObjectOnMap();
    });

    useLayoutEffect(() => {
        if (!parentId || !isSite) {
            return;
        }

        if (isLoad.current) {
            return setLoader(false);
        }

        let active = true;

        (async () => {
            try {
                setLoader(true);
                const parentObject = await getObjectById(parentId);
                objectParent.current = parentObject;

                if (!active) {
                    return setLoader(false);
                }

                const listChildren = parentObject.children || [];
                if (!listChildren.includes(parentId)) {
                    listChildren.push(parentId);
                }

                const siteObjects = (await getObjects({
                    children: listChildren
                })).filter((object: ObjectItem) => {
                    if (!isObjectAvailableToDisplay(object)) {
                        return false;
                    }

                    return (object.properties?.map_polygon?.value && MAP_ZONE_ELEMENT_OBJECT_TYPE.includes(object.object_type))
                        || object.properties?.map_point?.value
                });

                if (!active) {
                    return setLoader(false);
                }

                siteMapController.setObjects(siteObjects);

                setChildren([...listChildren]);

                if (!siteObjects.length) {
                    isLoad.current = true;
                    return;
                }

                if (siteObjects.length === 1) {
                    setZoom(17);
                    setPosition(siteObjects[0].properties.map_point.value);
                    isLoad.current = true;
                    return;
                }

                const bounds = siteObjects.map((el: ObjectItem) => el.properties?.map_polygon?.value || el.properties?.map_point?.value);

                mapRef.current.leafletElement.fitBounds(bounds, {
                    paddingTopLeft: [25, 25],
                    paddingBottomRight: [25, 25]
                });

                isLoad.current = true;
            } catch (e) {
                console.error(e);
            }
        })();

        return () => {
            unmount();
            active = false;
        };
    }, [parentId]);

    useSubMap(mapRef.current?.leafletElement?.getBounds() || null, siteMapController?.getChildrenObject().map((object) => object.object_id ), async (data: MapObject) => {
        // [IM] While adding new CameraZone this hook called twice,
        // because responseHandlerOLS handles SubscriptionType.PROPERTIES twice

        await wait(500);
        orgObjects.current = await getOrgHierarchy();

        const deleteObject = async (objectId: string) => {
            siteMapController?.deleteChildrenObjects([objectId]);
        };

        if (data.isDeleted) {
            await deleteObject(data.objectId);
            return;
        }

        if (!orgObjects.current.includes(data.objectId)) {
            return;
        }

        let updatedObject = data.object || await getObjectById(data.objectId, {skipChildrenTransform: true}); //[AM] Temp  getObjectById

        updatedObject.children = convertChildrenToStringArray(updatedObject.children);

        if (updatedObject.deleted && (updatedObject.updated + 60000 < Date.now())) {
            return;
        }
        const objectExistingOnMap = siteMapController?.getChildrenObject().find((item) => {
            return item.object_id === updatedObject.object_id;
        });

        if (objectExistingOnMap) {
            const oldUpdated = getEpochTime(objectExistingOnMap.updated);
            const newUpdated = getEpochTime(updatedObject.updated);

            if (oldUpdated < newUpdated) {
                siteMapController?.updateChildObjects({[updatedObject.object_id]: updatedObject});
            }
        } else {
            siteMapController?.addChildObjects({[updatedObject.object_id]: updatedObject});
            if (updatedObject.deleted) {
                setTimeout(deleteObject, 3000, data.objectId);
            }
        }
    });

    const unmount = () => {
        closeDrawers();
        setCreateMode(false);
        setZoneType("");
        setLoader(false);
    };

    const closeDrawers = useCallback(() => {
        setElementObjectsOpened(false);
        setPropertiesObjectOpened(false);
    }, []);

    const openProperties = useCallback(() => {
        setPropertiesObjectOpened(true);
        selectionHistory.current = null;

        let selectObject = siteMapController?.getSelectObject();

        if (selectObject && selectObject.is_created) {
            setElementObjectsOpened(false);
        }
    }, [siteMapController]);

    const setSelectedObject = (_object: ObjectItem) => {
        if (!createMode) {
            siteMapController?.setSelectObject(_object);
        }
    };

    const createNewZone = (type: string) => {
        // noinspection RegExpSimplifiable
        let newZone: ObjectItem & {image: string} = {
            object_id: v4(),
            object_type: type,
            object_name: type.replace(/([a-z0-9])([A-Z])/g, "$1 $2"),
            children: [],
            description: `This ${type} was created on a map.`,
            plan: null,
            properties: {
                map_polygon: {
                    key: "map_polygon",
                    property_id: v4(),
                    name: "MapBounds",
                    readable: true,
                    type: "MapPolygon",
                    value: [],
                    writable: false,
                    visibility: ["card", "details", "parent"],
                },
                image_polygon: {
                    key: "image_polygon",
                    property_id: v4(),
                    name: "ImageBounds",
                    readable: true,
                    type: "ImagePolygon",
                    value: "",
                    writable: false,
                    visibility: ["card", "details", "parent"],
                },
                color: {
                    key: "color",
                    property_id: v4(),
                    name: "Color",
                    readable: true,
                    type: "Hue",
                    value: zoneColorsByType.hasOwnProperty(type) ? zoneColorsByType[type] : 230,
                    writable: false,
                    visibility: ["card", "details"],
                },
            },
            image: "",
            is_created: true,
        };
        if (type !== "CameraMapZone") {
            delete newZone.properties.image_polygon;
        }
        siteMapController?.setSelectObject(newZone);
        return newZone;
    };

    const handleClickOnMap = (e: any) => {
        let _selectObject = siteMapController?.getSelectObject() || null;

        if (!createMode) {
            selectionHistory.current = _selectObject?.object_id || null;

            const map = mapRef.current.leafletElement;
            const layerPoint = map.latLngToContainerPoint(e.latlng);

            // We still need to look for CameraMapZone objects...
            let zones: { [id: string]: [number, number][] } = {};
            const mapChildren = siteMapController?.getChildrenObject() || [];
            for (const child of mapChildren) {
                if (child.object_type === "CameraMapZone" || child.object_type === "Zone") {
                    let property = child.properties["map_polygon"] || null;
                    if (property && Array.isArray(property.value) && property.value.length > 2) {
                        zones[child.object_id] = property.value;
                    }
                }
            }

            // then polygons
            let polygon = null;
            for (const key of Object.keys(zones)) {
                const polygonSelected = inPolygon(
                    layerPoint,
                    zones[key].map((latLng) => map.latLngToContainerPoint(latLng))
                );
                if (polygonSelected) {
                    polygon = mapChildren.find((child) => child.object_id === key);
                    if (polygon) {
                        setSelectedObject(polygon);
                        return;
                    }
                }
            }
            siteMapController?.setSelectObject(null);
            return;
        }

        if (!_selectObject && zoneType) {
            _selectObject = createNewZone(zoneType);
        }

        if (!_selectObject) {
            return;
        }

        let point: L.LatLngTuple = [e.latlng.lat, e.latlng.lng];
        if (MAP_ZONE_ELEMENT_OBJECT_TYPE.includes(_selectObject.object_type)) {
            let property = _selectObject.properties["map_polygon"];
            if (!property?.value?.length) {
                if (!property) {
                    _selectObject.properties["map_polygon"] = {
                        key: "map_polygon",
                        property_id: v4(),
                        name: "MapBounds",
                        readable: true,
                        type: "MapPolygon",
                        value: [],
                        writable: false,
                        visibility: ["card", "details", "parent"],
                    };
                    property = _selectObject.properties["map_polygon"];
                }
                property.value = [point];
                siteMapController?.addChildObject(_selectObject);
            } else if (property) {
                (property.value as L.LatLngTuple[]).push(point);
                siteMapController?.updateValueOfProperty(property.key, property.value);
            }
        } else {
            if (!siteMapController?.getChildrenObject()
                    .find((child) => child.object_id === _selectObject?.object_id)
            ) {
                let newChild = Object.assign({}, _selectObject);
                newChild.properties["map_point"] = {
                    key: "map_point",
                    property_id: v4(),
                    name: "Location",
                    readable: true,
                    type: "MapPoint",
                    value: point,
                    writable: false,
                    visibility: ["card", "map", "details"],
                };
                siteMapController?.addChildObject(newChild);
                setCreateMode(false);
            }
        }
    };

    const handlerViewportChange = async (e: any) => {
        // Refresh markers position when dragging map
        siteMapController.setViewportCenter(e.center);

        // if (zoomed < 14) {
        //     return;
        // }
        const geoBounds = getScreenMapBounds();

        if (geoBounds) {
            await getPublicObjects({geo_bounds_limit: geoBounds});
        }
        // forceUpdate();
    };

    const switchLayers = () => {
        setLayer((prev) => (prev === "map" ? "satellite" : "map"));
    };

    const getScreenMapBounds = () => {
        if (!mapRef.current) {
            return;
        }

        const map = mapRef.current.leafletElement;

        // const mapZoom = map.getZoom();

        // if (mapZoom < 13) {
        //     const center = map.getCenter();
        //     return [center.lat - 0.2, center.lat + 0.2, center.lng - 0.5, center.lng + 0.5];
        // }

        const bounds = map.getBounds();
        const northEast = bounds.getNorthEast();
        const southWest = bounds.getSouthWest();

        let lat_min;
        let lat_max;
        let lng_min;
        let lng_max;

        if (northEast.lat > southWest.lat) {
            lat_min = southWest.lat;
            lat_max = northEast.lat;
        } else {
            lat_max = southWest.lat;
            lat_min = northEast.lat;
        }

        if (northEast.lng > southWest.lng) {
            lng_min = southWest.lng;
            lng_max = northEast.lng;
        } else {
            lng_max = southWest.lng;
            lng_min = northEast.lng;
        }

        return [lat_min, lat_max, lng_min, lng_max];
    };

    const updateListObjectsOnMap = async () => {
        const currentPromise = getListObjectsToDisplayOnMap();
        lastPromiseRef.current = currentPromise;

        const filterObjects = await currentPromise;
        if (currentPromise !== lastPromiseRef.current) return;

        const listFilterObjectsIds: string[] = [];

        const childrenToAdd: { [key: string]: ObjectItem } = {};
        const childrenToUpdate: { [key: string]: ObjectItem } = {};
        const childrenToRemove: string[] = [];

        filterObjects.forEach((_object: ObjectItem) => {
            const exist = siteMapController?.getChildrenObject().find((item) => {
                return item.object_id === _object.object_id;
            });

            if (!exist) {
                childrenToAdd[_object.object_id] = _object;
            } else {
                const oldUpdated = getEpochTime(exist.updated);
                const newUpdated = getEpochTime(_object.updated);

                if (oldUpdated < newUpdated || exist.changed) {
                    childrenToUpdate[_object.object_id] = _object;
                }
            }

            listFilterObjectsIds.push(_object.object_id);
        });

        if (Object.keys(childrenToAdd).length !== 0) {
            siteMapController?.addChildObjects(childrenToAdd);
        }

        if (Object.keys(childrenToUpdate).length !== 0) {
            siteMapController?.updateChildObjects(childrenToUpdate);
        }

        siteMapController?.getChildrenObject().forEach((item) => {
            if (!listFilterObjectsIds.includes(item.object_id)) {
                childrenToRemove.push(item.object_id);
            }
        });

        if (childrenToRemove.length !== 0) {
            siteMapController?.deleteChildrenObjects(childrenToRemove);
        }
    };

    const updateTileMinMaxValue = () => {
        if (editMode || !scale || !mapRef.current) {
            setTileMinMaxValue(null);
            return;
        }

        const map = mapRef.current.leafletElement;
        const bounds = map.getBounds();

        const min = bounds.getNorthWest();
        const max = bounds.getSouthEast();

        const minX = longitudeToXTile(min.lng, scale);
        const minY = latitudeToYTile(min.lat, scale);

        const maxX = longitudeToXTile(max.lng, scale);
        const maxY = latitudeToYTile(max.lat, scale);

        setTileMinMaxValue({
            minX: minX,
            minY: minY,
            maxY: maxY,
            maxX: maxX
        });
    };

    return (
        <Fragment>
            <Map
                center={position}
                zoom={zoom}
                className={editMode ? classes.leafletContainerEdit : classes.leafletContainer}
                onClick={handleClickOnMap}
                onViewportChange={handlerViewportChange}
                onzoomstart={() => {
                    setShowMarkers(false);
                }}
                onzoomend={(e: any) => {
                    // !editMode && updateListObjectsOnMap();
                    setShowMarkers(true);
                    setZoomed(e.target._zoom);
                }}
                onmoveend={() => {
                    !editMode && updateListObjectsOnMap();
                    updateTileMinMaxValue();
                }}
                onlocationfound={() => {
                    setKeepCurrentZoomLevel(true);
                }}
                dragging={mapDraggable}
                ref={mapRef}
                maxBounds={MAP_BOUNDS}
                tap={false}
            >
                {layer === "map" ? (
                    <TileLayer
                        noWrap
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                    />
                ) : (
                    [
                        <TileLayer
                            noWrap
                            url="https://server.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                            attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                        />,
                        <TileLayer
                            noWrap
                            url="https://server.arcgisonline.com/arcgis/rest/services/Reference/World_Boundaries_and_Places/MapServer/tile/{z}/{y}/{x}"
                            attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                        />,
                    ]
                )}
                <ChildrenOnMap
                    siteMapController={siteMapController}
                    mapRef={mapRef}
                    selectObject={setSelectedObject}
                    editMode={editMode}
                    createMode={createMode}
                    userInfo={userInfo}
                    setMapDraggable={setMapDraggable}
                    zoomed={zoomed}
                    showMarkers={showMarkers}
                    openProperties={openProperties}
                    selectionHistory={selectionHistory}
                />
                <LocateControl
                    options={{
                        onLocationError: () => {
                            console.error("Geolocation error: User denied Geolocation.");
                        },
                        keepCurrentZoomLevel: keepCurrentZoomLevel,
                        initialZoomLevel: 14,
                    }}
                    startDirectly={!isSite}
                />
            </Map>
            <Fab
                aria-label="switch layers"
                key={layer}
                component="div"
                className={classes.switch_layers}
                onClick={switchLayers}
                children={<a className={`fas fa-${layer === "map" ? "satellite" : "map"}`}/>}
            />
            <ObjectMapProperties
                open={propertiesObjectOpened}
                closeDrawer={closeDrawers}
                object={siteMapController?.getSelectObject()}
                siteMapController={siteMapController}
                isDisabled={!editMode}
                userInfo={userInfo}
                saveObjectByKey={saveObjectByKey}
                handleBackButton={handleBackButton}
                parentId={parentId}
                setObject={setObject}
                displayModalBind={displayModalBind}
                openModalBind={openModalBind}
            />
            {!propertiesObjectOpened && !elementObjectsOpened ? (
                <Fab
                    size="small"
                    color="primary"
                    aria-label="open properties"
                    component="span"
                    className={classes.properties_open}
                    onClick={openProperties}
                    children={<InfoIcon/>}
                />
            ) : null}
            {editMode && (
                <EditElements
                    siteMapController={siteMapController}
                    userInfo={userInfo}
                    closeDrawers={closeDrawers}
                    setCreateMode={setCreateMode}
                    setZoneType={setZoneType}
                    setPropertiesObjectOpened={setPropertiesObjectOpened}
                    setElementObjectsOpened={setElementObjectsOpened}
                    elementObjectsOpened={elementObjectsOpened}
                />
            )}
        </Fragment>
    );
}
