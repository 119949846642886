import React, {useEffect, useMemo, useState} from "react";
import { makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import BorderLinearProgress from "./BorderLinearProgress/BorderLinearProgress";
import {LinearProgressProps} from "@material-ui/core/LinearProgress/LinearProgress";
import {useMediaQuery, useTheme} from "@material-ui/core";
import BorderCircularProgress from "./BorderCircularProgress/BorderCircularProgress";
import {Variant} from "@material-ui/core/styles/createTypography";

const useStyles = makeStyles((theme) => ({
    root: (props: {ml: number}) => ({
        marginTop: theme.spacing(1),                        //TODO [AM] for [AS] move margin data to parent component
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(props.ml),
        marginRight: theme.spacing(2)
    })
}));

type ShareDialogProgressProps = {
    value?: number;
    min?: number;
    max?: number;
    label?: string;
    endAdornmentVariant?: "default" | "shareDialog";
    progressVariant?: "linear" | "circular";
    size?: number;
    thickness?: number;
    displayValue?: string;
    valueTypographyProps?: {                                // TODO [AM] for [AS] extended to TypographyProps
        variant: Variant;
    };
} & LinearProgressProps;

const normalise = (options: {value: number, min?: number, max?: number}) => {
    const {min, max, value} = options;
    const MIN = min || 0;
    const MAX = max || 100;
    return (value - MIN) * 100 / (MAX - MIN);
};

const ProgressComponent = (props: ShareDialogProgressProps) => {
    const {value, min, max, label, variant, endAdornmentVariant = "default", progressVariant = "linear", size, thickness, displayValue, valueTypographyProps} = props;

    const classes = useStyles({ml: !endAdornmentVariant || endAdornmentVariant === "default" ? 0 : 3 });

    const theme = useTheme();
    const downXs = useMediaQuery(theme.breakpoints.down("xs"));

    const [progressValue, setProgressValue] = useState<number | undefined>(Number.isInteger(value) ? normalise({
        value: value as number,
        max: max,
        min: min
    }) : undefined);

    useEffect(() => {
        setProgressValue(Number.isInteger(value) ? normalise({value: value as number, max: max, min: min}) : undefined);
    }, [value, min, max]);

    const valueToDisplay = useMemo(() => {
        return displayValue || `${Math.round((progressValue || 0) * 100) / 100}%`;
    }, [progressValue, displayValue]);


    const DefaultEndAdornment = () => (
        <Box minWidth={35}>
            <Typography variant="subtitle1" color="textSecondary">
                {valueToDisplay}
            </Typography>
        </Box>
    );

    const ShareDialogEndAdornment = () => (
        <Box minWidth={35}>
            {
                progressValue === undefined || value === max ?
                    (progressValue === undefined ? <HourglassEmptyIcon fontSize="small"/> :
                        <CheckCircleIcon fontSize="small"/>) :
                    <Typography variant={downXs ? "caption" : "subtitle1"} color="textSecondary">
                        {valueToDisplay}
                    </Typography>
            }
        </Box>
    );

    const EndAdornment =  !endAdornmentVariant || endAdornmentVariant === "default" ? DefaultEndAdornment : ShareDialogEndAdornment;

    const Linear = () => {
        return (
            <Box display="flex" alignItems="center">
                <Box width="100%" mr={1}>
                    <BorderLinearProgress variant={variant} value={progressValue}/>
                </Box>
                <EndAdornment/>
            </Box>
        )
    };

    const Circular = () => {
        return (
            <Box position="relative" display="inline-flex" pt={1}>
                <BorderCircularProgress value={progressValue} size={size} thickness={thickness}/>
                <Box
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Typography
                        variant="caption"
                        component="div"
                        color="textSecondary"
                        {...valueTypographyProps}
                    >
                        {valueToDisplay}
                    </Typography>
                </Box>
            </Box>
        )
    };

    const Progress = !progressVariant || progressVariant === "linear" ? Linear : Circular;

    return (
        <Box className={classes.root}>
            <Box display="flex" alignItems="left">
                <Typography variant={downXs ? "caption" : "body2"} color="textSecondary">
                    {label}
                </Typography>
            </Box>
            <Progress/>
        </Box>
    );
};

export default ProgressComponent;