import { ALLOWED_GUEST_API, API_WITH_LIMIT, AUTH_HEADERS, METHOD, REQUEST_TYPE } from "./Constants";
import type { RequestParams, RequestOptions } from "./Types";
import {
    checkAuth,
    getToken,
    isSignedIn,
    isTokenExpired,
    refreshToken
} from "../AuthenticationService";
import { API_BASE_URL, APP_ID } from "../Constants";
import { NOTIFICATION_TYPE, pubServiceNotification as showNotification } from "../NotificationService";
import { isGuest, getUserIAPData } from "../UserService";

let $lastLimitedAPICallTime = 0;
let $lastNotificationShown = 0;

const authRequest = async (path: string, options: RequestOptions) => {
    const { method, body, requestType, query, token } = options;

    if (!isSignedIn() && !token) {
        return checkAuth({ auth: false, message: "Failed to authenticate token." });
    }

    if (!isGuestAPIRequestAllowed(requestType)) {
        return Promise.reject({ message: "The request was blocked. The Guest user cannot be used the remote API. Please sign-up" });
    }

    if (!isAPICallAllowed(requestType)) {
        return Promise.reject({ message: "The request was blocked. API call limits exceeded" })
    }

    const requestOptions: RequestInit = {
        method: method,
        headers: generateHeaders({ token: token })
    };

    if (body) {
        requestOptions.body = body;
    }

    const url = new URL(buildAPIURL(path));

    if (query && Object.keys(query || {}).length) {
        generateParams(query, url);
    }

    try {
        if (isTokenExpired() && requestType !== REQUEST_TYPE.POST_REFRESH_TOKEN && !token) {
            await refreshToken();
            if ((requestOptions?.headers as Headers)?.has("x-access-token")) {
                (requestOptions.headers as Headers).set("x-access-token", getToken());
            }
        }

        const result = await handleResponse(await fetch(url.toString(), requestOptions), requestType);

        return checkAuth(result);
    } catch (err: any) { // [IM] Add custom error type?
        return err?.auth === false ? checkAuth(err) : Promise.reject(err);
    }
};

const nonAuthRequest = async (path: string, options: RequestOptions) => {
    const { method, body, requestType, query } = options;

    if (!isAPICallAllowed(requestType)) {
        return Promise.reject({ message: "API call limits exceeded" });
    }

    const requestOptions: RequestInit = {
        method: method,
        headers: generateHeaders({ disable_auth: true })
    };

    if (body) {
        requestOptions.body = body;
    }

    const url = new URL(buildAPIURL(path));

    if (query && Object.keys(query || {}).length) {
        generateParams(query, url);
    }

    return handleResponse(await fetch(url.toString(), requestOptions), requestType);
};

const uploadToS3 = async (signedURL: string, blobData: Blob) => {
    const requestOptions: RequestInit = {
        method: METHOD.PUT,
        body: blobData,
        headers: generateHeaders({ disable_auth: true }),
    };

    const result: Response = await fetch(signedURL, requestOptions);

    checkAPICallLimitExceeded(result.status, REQUEST_TYPE.UPLOAD_TO_S3);

    if (result.status === 200) {
        return;
    }

    return Promise.reject({
        status: result.status || 500,
        message: "Error uploadToS3 " + result.statusText,
        auth: false,
    });
};

const downloadFromS3 = async (signedURL: string) => {
    const requestOptions: RequestInit = {
        method: METHOD.GET,
        // responseType: 'arraybuffer' // [IM] responseType absent in RequestInit type
    };

    return handleResponse(await fetch(signedURL, requestOptions), REQUEST_TYPE.DOWNLOAD_FROM_S3);
};

const buildAPIURL = (path: string) => {
    return `${API_BASE_URL}${path}`;
};

const generateHeaders = (options?: { disable_auth?: boolean, token?: string }) => {
    const headers: { [key: string]: string } = {
        "Content-Type": "application/json",
        "x-access-token": options?.token || getToken(),
        "Application-Identifier": APP_ID,
    };

    const iapData = getUserIAPData();

    if (iapData?.product_id) {
        headers[AUTH_HEADERS.IAP_PRODUCT_ID] = iapData.product_id;
    }
    if (iapData?.end_date) {
        headers[AUTH_HEADERS.IAP_END_DATE] = iapData.end_date;
    }

    if (options?.disable_auth) {
        delete headers["x-access-token"];
    }

    return new Headers(headers);
};

const isAPICallAllowed = (requestType: REQUEST_TYPE) => {
    if (!isAPIWithLimit(requestType) || !$lastLimitedAPICallTime) {
        return true;
    }

    const epoch = Date.now();
    const minuteHasPassed = epoch - $lastLimitedAPICallTime > 60 * 1000;

    if (minuteHasPassed) {
        $lastLimitedAPICallTime = epoch;
        return true;
    }

    return false;
};

const isGuestAPIRequestAllowed = (requestType: REQUEST_TYPE) => {
    if (!isGuest()) {
        return true;
    }

    // noinspection RedundantIfStatementJS
    if (ALLOWED_GUEST_API.includes(requestType)) {
        return true;
    }

    return false;
};

const handleResponse = async (res: Response, requestType: REQUEST_TYPE) => {
    checkAPICallLimitExceeded(res.status, requestType);

    const resJSON = await res.json();

    if (res.status === 200) {
        return resJSON;
    }

    return Promise.reject({
        status: res.status || 500,
        message: resJSON?.message || "Something went wrong",
        auth: resJSON?.auth,
    });
};

const generateParams = (params: RequestParams, url: URL) => {
    Object.keys(params).forEach((key) => {
        const param = params[key];
        if (param && Array.isArray(param)) {
            url.searchParams.append(key, JSON.stringify(param));
            return;
        }

        url.searchParams.append(key, params[key] as string);
    });
};

const isAPIWithLimit = (requestType: REQUEST_TYPE) => {
    return API_WITH_LIMIT.includes(requestType);
};

const checkAPICallLimitExceeded = (status: number, requestType: REQUEST_TYPE) => {
    if (!isAPIWithLimit(requestType)) {
        return;
    }

    const epoch = Date.now();
    const hourHasPassed = epoch - $lastNotificationShown > 60 * 60 * 1000;

    if (status === 429) {
        if (!$lastLimitedAPICallTime || hourHasPassed) {
            showNotification({
                message: "API call limits exceeded. Objects data does not publish from your device. Upgrade your plan for extending available limits",
                type: NOTIFICATION_TYPE.WARNING
            });
            $lastNotificationShown = epoch;
        }
        $lastLimitedAPICallTime = epoch;
    }

    if (status === 200) {
        $lastLimitedAPICallTime = 0;
    }
};

export {
    authRequest,
    nonAuthRequest,
    uploadToS3,
    downloadFromS3
};
