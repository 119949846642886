import React, {useMemo} from "react";
import {CardMedia, Dialog, Fab, makeStyles} from "@material-ui/core";
import {
    useGlobalDialogContentContext,
    useGlobalDialogContext,
    useUpdateGlobalDialogActionsContext,
    useUpdateGlobalDialogContentActionsContext
} from "../../../GlobalContext/GlobalContext";
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';


const useStyles = makeStyles((theme) => ({
    fullscreenExitIcon: {
        // position: "absolute",
        // bottom: 0,
        // right: 0,
        margin: 10,
        position: "fixed",
        top: theme.spacing(1),
        right: theme.spacing(1),
        zIndex: theme.zIndex.drawer + 1,
    },
    img: {
        width: "auto",
        height: "100%",
        objectFit: "contain",
        position: "relative"
    },
    imgBlur: {
        width: "inherit",
        height: "inherit",
        opacity: "0.5",
        filter: "blur(10px)",
        position: "absolute",
        top: "0",
        left: "0",
    }
}));

const FullscreenImageDialog = () => {

    const classes = useStyles();

    const globalDialog = useGlobalDialogContext()
    const setGlobalDialog = useUpdateGlobalDialogActionsContext()

    const globalDialogContent = useGlobalDialogContentContext()
    const setGlobalDialogContent = useUpdateGlobalDialogContentActionsContext()

    const handleFullscreenCloseClick = () => {
        setGlobalDialog(undefined);
        setGlobalDialogContent(undefined);
    }
    const open = useMemo(() => globalDialog ? globalDialog.split("_")[0] === "FullscreenImageDialog" : false
    , [globalDialog])

    return (
        <Dialog fullScreen open={open}>
            <Fab
                size="small"
                color="primary"
                aria-label="close fullscreen"
                component="span"
                className={classes.fullscreenExitIcon}
                onClick={handleFullscreenCloseClick}
                children={<FullscreenExitIcon/>}
            />
            <CardMedia className={classes.imgBlur} image={globalDialogContent} component="img" alt=""/>
            <CardMedia className={classes.img} image={globalDialogContent} component="img"/>
        </Dialog>
    )
}

export default FullscreenImageDialog;
