import {useState} from "react";
import useSystemEvent from "../useSystemEvent";
import {EVENT_TYPE} from "../../../Services/SystemEventsService";

const useDisableUI = () => {

    const [disabled, setDisabled] = useState<boolean>(false);

    useSystemEvent(EVENT_TYPE.DISABLE_UI, (data) => {
        setDisabled(!!data?.boolState);
    });

    return {
        disabled
    };

};

export default useDisableUI;
