import {useEffect, useState} from "react";
import {v4} from "uuid";
import {subscribeClientLastActive, unsubscribeClientLastActive} from "../../Services/UserService";

type useSubClientLastActiveType = (clientId: string, lastActivity: number | undefined, lastLogin: number| undefined) => { lastActivityEpoch: number | null, loginEpoch: number | null };

export const useSubClientLastActive: useSubClientLastActiveType = (clientId, lastActivity, lastLogin) => {
    const [lastActivityEpoch, setLastActivityEpoch] = useState<number | null>(lastActivity || null);
    const [loginEpoch, setLoginEpoch] = useState<number | null>(lastLogin || null);

    useEffect(() => {
        if (!clientId) {
            return;
        }

        let active = true;
        const subscriptionId = v4();

        subscribeClientLastActive(clientId, subscriptionId, (keys, data) => {
            if (keys.client_id !== clientId || !active || !data) {
                return;
            }

            setLastActivityEpoch(data.lastActiveEpoch);
            setLoginEpoch(data.loginEpoch);
        });

        return () => {
            active = false;
            unsubscribeClientLastActive(clientId, subscriptionId);
        };
    }, [clientId]);

    useEffect(() => {
        setLastActivityEpoch(lastActivity || null);
    }, [lastActivity]);

    useEffect(() => {
        setLoginEpoch(lastLogin || null);
    }, [lastLogin]);

    return { lastActivityEpoch, loginEpoch };
};
