import React, {useCallback, useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {
    Button,
    capitalize,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle as MuiDialogTitle,
    FormControlLabel,
    Grid,
    IconButton,
    TextField,
    Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {v4} from "uuid";
import {Autocomplete} from "@material-ui/lab";
import {ObjectItem, PropertyItem} from "../../../Services/ObjectsService";
import {useGlobalNotificationContext} from "../../Notification/NotificationProvider";
import type {SnackBarType} from "../../../Services/NotificationService";
import Property from "../Property/Property";
import {UserInfoType} from "../../../Hooks/useUserProfile";
import {TypeList, TypeListType} from "../../Common/Constants";
import type {MeasurementUnitsListType} from "../../../Helpers/SystemOfMeasures";
import {MEASUREMENT_UNITS_LIST} from "../../../Helpers/SystemOfMeasures";
import ImperialMeasurementsControls from "../../Common/ImperialMeasurementsControls";
import {controlNumberTypes, controlSwitchTypes} from "../Property/PropertyControls/PropertyControlsTypes";

const useStyles = makeStyles((theme) => ({
    root_MuiDialog: {
        margin: 0,
        padding: theme.spacing(2),
    },
    root_MuiDialogContent: {
        // padding: theme.spacing(2),
        [theme.breakpoints.up("sm")]: {
            minWidth: 400,
        },
    },
    root_MuiDialogActions: {
        margin: 0,
        padding: theme.spacing(1),
    },
    root_MuiDialogTitle: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

type DialogTitleProps = {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
};

const visibility = ["card", "details", "map", "plan", "parent", "title"];

const DialogTitle = (props: DialogTitleProps) => {
    const {children, onClose, ...other} = props;
    const classes = useStyles();
    return (
        <MuiDialogTitle disableTypography className={classes.root_MuiDialogTitle} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
};

type ModalAddPropertyProps = {
    propertyItem?: PropertyItem;
    object: ObjectItem | any;
    parentId?: string;
    open: boolean;
    userInfo: UserInfoType;
    onClose(): void;
    saveObjectByKey(key: string, value: any): void;
};

export default function ModalAddProperty(props: ModalAddPropertyProps) {

    const classes = useStyles();

    const notify: SnackBarType = useGlobalNotificationContext();

    const {propertyItem, object, open, userInfo, onClose, saveObjectByKey, parentId} = props;

    const [dropDownTypeValue, setDropDownTypeValue] = useState<TypeListType>({
        type: "",
        name: "",
        key: "",
        def_value: "",
    });

    const [dropDownUnitsValue, setDropDownUnitsValue] = useState<MeasurementUnitsListType | null>({
        name: "",
        method: "",
        symbol: "",
        coefficient: "",
    });

    const [lisOfTypes, setListOfTypes] = useState<TypeListType[]>([]);
    const [listOfUnitsTypes, setListOfUnitsTypes] = useState<MeasurementUnitsListType[]>([]);


    const [property, setProperty] = useState<{ [string: string]: any }>(
        propertyItem || {
            key: "",
            property_id: v4(),
            name: "",
            readable: true,
            type: "",
            value: "",
            writable: false,
            visibility: visibility.filter((vis) => vis !== "parent"),
        }
    );

    const [isImperialControls, setIsImperialControls] = useState<boolean>(false)

    const systemOfMeasures = userInfo.user?.system_of_measures || "imperial";

    const filterTypeList = useCallback(() => {
        return !propertyItem
            ? TypeList.filter((prop) => {

                return (
                    !object.properties[prop.key] &&
                    (prop.key !== "map_point" || !object.properties["map_polygon"]) &&
                    (prop.key !== "map_polygon" || !object.properties["map_point"]) &&
                    (prop.key !== "plan_point" || !object.properties["plan_polygon"]) &&
                    (prop.key !== "plan_polygon" || !object.properties["plan_point"])
                );
            })
            : [
                {
                    type: propertyItem.type,
                    name: propertyItem.name,
                    key: propertyItem.key,
                    def_value: propertyItem.value,
                } as TypeListType,
            ];
    }, [propertyItem, object.properties])

    const filterMeasurementUnitsList = useCallback(() => {
        return Object.values(MEASUREMENT_UNITS_LIST);
    }, [propertyItem, MEASUREMENT_UNITS_LIST])

    useEffect(() => {
        setListOfTypes(filterTypeList())
    }, [filterTypeList])

    useEffect(() => {
        setListOfUnitsTypes(filterMeasurementUnitsList())
    }, [filterMeasurementUnitsList])

    useEffect(() => {
        if (propertyItem?.units === "mm" && systemOfMeasures === "imperial") {
            setIsImperialControls(true)
        }
    }, [propertyItem, systemOfMeasures])

    useEffect(() => {
        if (open) {
            setListOfTypes(filterTypeList);
        } else {
            setProperty({
                key: "",
                property_id: v4(),
                name: "",
                readable: true,
                type: "",
                value: "",
                writable: false,
                visibility: visibility.filter((vis) => vis !== "parent"),
            });
        }
    }, [open, filterTypeList]);

    useEffect(() => {
        if (propertyItem) {
            setProperty(propertyItem);
        }
    }, [propertyItem]);

    useEffect(() => {
        setDropDownTypeValue(
            lisOfTypes.find((type) => type.type === property?.type) || {type: "", name: "", key: "", def_value: ""}
        );
    }, [property.type, lisOfTypes, property]);

    function addProperty() {
        if (!property.type) {
            notify.errorNotify("Property Type Required");
            return;
        }

        if (!property.name) {
            notify.errorNotify("Property Name Required");
            return;
        }

        if (controlNumberTypes.includes(property.type) && property.value === "") {
            notify.errorNotify("Property Value Required");
            return;
        }

        if (property.max !== undefined && property.min !== undefined && property.max < property.min) {
            notify.errorNotify("Min Value should be less then Max Value");
            return;
        }

        if (controlSwitchTypes.includes(property.type) && property.value === "") {
            property.value = false;
        }
        let _prop: { [index: string]: any } = {};
        _prop[property.key] = property;
        Object.assign(object.properties, _prop);
        saveObjectByKey("properties", object.properties);
        onClose();
    }

    function changeVisibility(name: string) {
        handleOnChange(
            "visibility",
            !property.visibility.includes(name)
                ? [...property.visibility, name]
                : property.visibility.filter((vis: string) => vis !== name)
        );
    }

    function handleOnChange(key: string, value: string) {
        if (key === "max" || key === "min") {
            setProperty((prev) => ({...prev, [key]: parseInt(value || "0")}));
            return;
        }
        setProperty((prev) => ({...prev, [key]: value}));
    }

    function handleInputChange(event: object, value: TypeListType | null) {
        const type = value?.type || "";
        const key = ["Number", "Boolean", "String", "ServiceLife"].includes(type) ? property.property_id : value?.key || ""
        setProperty((prev) => ({
            ...prev,
            ...{type, value: value?.def_value || "", key},
        }));
    }

    const handleUnitsValueChange = (event: object, value: MeasurementUnitsListType | null) => {
        setDropDownUnitsValue(value);
        setProperty((prev) => ({
            ...prev,
            units: value?.method
        }));
    }

    function handleDeleteProperty() {
        object.properties[property.key].removed = true;
        saveObjectByKey("properties", object.properties);
        onClose();
    }

    return (
        <Dialog open={open} onClose={onClose} classes={{root: classes.root_MuiDialog}}>
            <DialogTitle id="simple-modal-title" onClose={onClose}>
                {propertyItem ? "Edit Property" : "Create New Property"}
            </DialogTitle>
            <DialogContent dividers classes={{root: classes.root_MuiDialogContent}}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            value={property.name}
                            name={"Name"}
                            type="text"
                            label="Name"
                            fullWidth={true}
                            onChange={(e: any) => handleOnChange("name", e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            id="type"
                            options={lisOfTypes}
                            getOptionLabel={(option: TypeListType) => option.type}
                            onChange={handleInputChange}
                            value={dropDownTypeValue}
                            disabled={!!propertyItem}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth={true}
                                    variant={"outlined"}
                                    label="Type"
                                    placeholder="Type"
                                />
                            )}
                        />
                    </Grid>
                    {property.type === "Number" ? (
                        <Grid item xs={12}>
                            <Autocomplete
                                id="units"
                                options={listOfUnitsTypes}
                                value={dropDownUnitsValue}
                                onChange={handleUnitsValueChange}
                                getOptionLabel={(option: MeasurementUnitsListType) => {
                                    if (option?.name) {
                                        return option?.name + " (" + option?.symbol + ")";
                                    }
                                    if (propertyItem?.units && MEASUREMENT_UNITS_LIST.hasOwnProperty(propertyItem?.units)) {
                                        return MEASUREMENT_UNITS_LIST?.[propertyItem?.units]?.name + " (" + MEASUREMENT_UNITS_LIST?.[propertyItem?.units]?.symbol + ")";
                                    }
                                    return "";
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth={true}
                                        variant={"outlined"}
                                        label="Measurement units"
                                        placeholder="Measurement units"
                                    />
                                )}
                            />
                        </Grid>
                    ) : null}
                    <Grid item xs={12}>
                        {property.type ? (
                            <Property
                                property={property as PropertyItem}
                                object={object}
                                onChange={(key: string, value: any) => handleOnChange("value", value)}
                                userInfo={props.userInfo}
                                parentId={parentId}
                            />
                        ) : (
                            isImperialControls
                                ? (
                                    <ImperialMeasurementsControls
                                        name={"Value"}
                                        value={property.value}
                                        onChange={(val: number) => handleOnChange("value", String(val))}
                                        disabled={!property.writable}/>)
                                : (<Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        value={property.value}
                                        name={"Value"}
                                        type="text"
                                        label="Value"
                                        fullWidth={true}
                                        onChange={(e: any) => handleOnChange("value", e.target.value)}
                                        disabled={!property.writable}
                                    />
                                </Grid>)
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>Visibility</Typography>
                        {visibility.map((vis) => (
                            <FormControlLabel
                                key={vis}
                                control={
                                    <Checkbox
                                        name={vis}
                                        checked={property.visibility.includes(vis)}
                                        color="primary"
                                        onChange={() => changeVisibility(vis)}
                                    />
                                }
                                label={capitalize(vis)}
                            />
                        ))}
                    </Grid>
                    {property.min !== undefined ? (
                        isImperialControls
                            ? (
                                <ImperialMeasurementsControls
                                    name={"Min Value"}
                                    value={property.min}
                                    onChange={(val: number) => handleOnChange("min", String(val))}
                                    disabled={!property.writable}/>)
                            : (<Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    value={property.min.toString()}
                                    name={"Min Value"}
                                    type="number"
                                    label="Min Value"
                                    fullWidth={true}
                                    onChange={(e: any) => handleOnChange("min", e.target.value)}
                                    disabled={!property.writable}
                                />
                            </Grid>)
                    ) : null}
                    {property.max !== undefined ? (
                            isImperialControls
                                ? (
                                    <ImperialMeasurementsControls
                                        name={"Max Value"}
                                        value={property.max}
                                        onChange={(val: number) => handleOnChange("max", String(val))}
                                        disabled={!property.writable}/>)
                                : (<Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        value={property.max.toString()}
                                        name={"Max Value"}
                                        type="number"
                                        label="Max Value"
                                        fullWidth={true}
                                        onChange={(e: any) => handleOnChange("max", e.target.value)}
                                        disabled={!property.writable}
                                    />
                                </Grid>)
                        )
                        : null}
                </Grid>
            </DialogContent>
            <DialogActions classes={{root: classes.root_MuiDialogActions}}>
                <Grid container justifyContent={"space-between"}>
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={addProperty}>
                            {!propertyItem ? "Add" : "Update"}
                        </Button>
                    </Grid>
                    <Grid item>
                        {propertyItem && TypeList.find((type) => type.type === propertyItem?.type) ? (
                            <Button variant="contained" color="secondary" onClick={handleDeleteProperty}>
                                Delete
                            </Button>
                        ) : null}
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}
