import React, {useEffect} from "react";
import {Box} from "@material-ui/core";
import { UserBasicInfo } from "./UserBasicInfo/UserBasicInfo";
import UserClientsList from "./UserClientsList/UserClientsList"
import {UserProfileProps} from "./UserProfileTypes";
import {getProfile, postUserStatus} from "../../../Services/UserService";
import UserPurchases from "./UserPurchases/UserPurchases";

const UserProfile = (props: UserProfileProps) => {

    const {userInfo, setLoader, loader} = props;

    useEffect(() => {
        if (!userInfo.user) {
            return;
        }

        let active = true;

        (async () => {
            if (!userInfo.checkAuthToken()) {
                return;
            }
            setLoader(true);

            try {
                await postUserStatus(userInfo.clientId);
                if (!active) {
                    return;
                }
                const user = await getProfile();
                if (!active) {
                    return;
                }
                await userInfo.setUser(user);
            } catch (e) {
                console.error(e);
            }
            setLoader(false);
        })();

        return () => {
            active = false;
        };
    }, []);

    return (
        <React.Fragment>
            <UserBasicInfo
                userInfo={userInfo}
                notify={props.notify}
            />

            <UserPurchases
                notify={props.notify}
                userInfo={userInfo}
                setLoader={setLoader}
                loader={loader}
            />

            <UserClientsList
                notify={props.notify}
                userInfo={userInfo}
                setLoader={setLoader}
                loader={loader}
            />

            <Box p={0.5}/>
        </React.Fragment>
    );
};

export default UserProfile;
