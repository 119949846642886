import type { SystemEventType, SystemEventCallbackData, SystemEventCallbackType } from "./Types"
import { EVENT_TYPE } from "./Constants"

const events: { [key in EVENT_TYPE]?: SystemEventType[] } = {};

const subSystemEvent = (SID: string, eventType: EVENT_TYPE, callback: SystemEventCallbackType) => {
    if (!events[eventType]) {
        events[eventType] = [];
    }

    events[eventType]?.push({
        SID: SID,
        eventType: eventType,
        callback: callback
    });
};

const unsubSystemEvent = (SID: string, eventType: EVENT_TYPE) => {
    if (!events.hasOwnProperty(eventType)) {
        return;
    }

    events[eventType] = (events[eventType] || []).filter((item) => {
        return item.SID !== SID;
    });
};

const pubSystemEvent = (eventType: EVENT_TYPE, data?: SystemEventCallbackData) => {
    if (!events.hasOwnProperty(eventType)) {
        return;
    }

    for (const event of events[eventType] || []) {
        event.callback(data);
    }
};

export {
    subSystemEvent,
    unsubSystemEvent,
    pubSystemEvent
};
