import React from "react";
import { Icon, IconButton, Typography, Button } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { pubUndoEvent, pubRedoEvent, pubRemoveEvent, pubSubmitEvent } from "../../../Services/SystemEventsService";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            flexGrow: 1,
        },
        iconButton: {
            flexDirection: "column",
            padding: theme.spacing(1),
        },
    })
);

export default function MapEditorNavbarTools() {
    const classes = useStyles();

    return (
        <React.Fragment>
            <IconButton color="inherit" onClick={pubUndoEvent} className={classes.iconButton}>
                <Icon className="fa fa-undo" fontSize="small"/>
            </IconButton>
            <IconButton color="inherit" onClick={pubRedoEvent} className={classes.iconButton}>
                <Icon className="fa fa-redo" fontSize="small"/>
            </IconButton>
            <IconButton color="inherit" onClick={pubRemoveEvent} className={classes.iconButton}>
                <Icon className="fa fa-trash-alt" fontSize="small"/>
            </IconButton>
            <Typography variant="h6" noWrap className={classes.title}>
                Edit Map
            </Typography>
            <Button color="inherit" onClick={pubSubmitEvent}>
                Save
            </Button>
        </React.Fragment>
    );
}
