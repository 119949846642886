import { useEffect } from "react";
import { subObjectDelete, unsubObjectDelete } from "../../Services/ObjectsService";
import { v4 } from "uuid";
import { OriginType } from "../../Services/ObjectsService/Constants";

type CallbackType = () => void;
type UseSubObjectDeleteType = (objectId: string, callback: CallbackType) => void;

export const useSubObjectDelete: UseSubObjectDeleteType = (objectId, callback) => {

    useEffect(() => {
        if (!objectId) {
            return;
        }

        const subscriptionId = v4();
        let active = true;

        subObjectDelete(objectId, subscriptionId, OriginType.USER, (keys) => {
            if (keys.objectId !== objectId || !active) {
                console.error("Incorrect Object Id");
                return;
            }

            callback();
        });

        return () => {
            active = false;
            unsubObjectDelete(objectId, subscriptionId);
        }

    }, [objectId, callback]);

}
