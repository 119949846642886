import { SUBSCRIPTION_TYPE } from "./Constants";
import type {
    NotificationPayload,
    SubscriptionNotificationType,
    CallbackDataServiceNotificationType,
    ServiceNotificationsCallbackType,
    SubscriptionNotificationCallbackType
} from "./Types";

const subscriptions: { [key: string]: SubscriptionNotificationType[] } = {};

const subNotification = (subscriptionId: string, callback_handler: SubscriptionNotificationCallbackType | ServiceNotificationsCallbackType, type: string) => {
    if (!subscriptions[type]) {
        subscriptions[type] = [];
    }

    subscriptions[type].push({ subscriptionId, callback_handler });
};

const unSubNotification = (subscriptionId: string, type: string) => {
    if (!subscriptions.hasOwnProperty(type)) {
        return;
    }

    subscriptions[type] = subscriptions[type].filter((item: SubscriptionNotificationType) => item.subscriptionId !== subscriptionId);
};

const pubApnNotification = async (notification: NotificationPayload, type: string = SUBSCRIPTION_TYPE.APN_NOTIFICATION) => {
    if (!subscriptions[type]) {
        return;
    }

    for (const subscription of subscriptions[type]) {
        (subscription.callback_handler as SubscriptionNotificationCallbackType)(notification);
    }
};

const pubServiceNotification = (data: CallbackDataServiceNotificationType, type: string = SUBSCRIPTION_TYPE.SERVICE_NOTIFICATION) => {
    if (!subscriptions[type]) {
        return;
    }

    for (const subscription of subscriptions[type]) {
        (subscription.callback_handler as ServiceNotificationsCallbackType)(data);
    }
};

export {
    subNotification,
    unSubNotification,
    pubApnNotification,
    pubServiceNotification
}
