import React, { useEffect, useState } from "react";

import Grid, { GridSize } from "@material-ui/core/Grid";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import PlaceIcon from "@material-ui/icons/Place";
import WhereToVoteIcon from "@material-ui/icons/WhereToVote";
import IconButton from "@material-ui/core/IconButton";
import { PointTuple } from "../../../Services/ObjectsService";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    disableNumberInputRows: {
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(0),
        "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
            display: "none",
        },
    },
}));

type PointFieldsProps = {
    point: L.LatLngTuple | PointTuple;
    index?: number;
    labelX?: string;
    labelY?: string;
    label?: string;
    enableSelect?: boolean;
    enableDelete?: boolean;
    isSelected?: boolean;
    isDisabled?: boolean;
    controlInputProps?: object;
    handleRemove?(): void;
    handleSelect?(): void;
    handleUpdate(value: L.LatLngTuple): void;
};

const PointFields = (props: PointFieldsProps) => {
    const {
        point,
        index,
        isSelected,
        labelX,
        labelY,
        label,
        enableSelect,
        enableDelete,
        isDisabled,
        controlInputProps,
        handleRemove,
        handleSelect,
        handleUpdate,
    } = props;

    const classes = useStyles();

    const [valueX, setValueX] = useState<number>(point[0]);
    const [valueY, setValueY] = useState<number>(point[1]);

    useEffect(() => {
        setValueX(point[0]);
        setValueY(point[1]);
    }, [point]);

    const handleOnChangeFirstCoordinate = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValueX: number = parseFloat(e.target.value) || 0;
        setValueX(newValueX);
        handleUpdate([newValueX, valueY]);
    };

    const handleOnChangeSecondCoordinate = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValueY: number = parseFloat(e.target.value) || 0;
        setValueY(newValueY);
        handleUpdate([valueX, newValueY]);
    };

    const removeField = () => {
        if (handleRemove) {
            handleRemove();
        }
    };

    const selectField = () => {
        if (handleSelect) {
            handleSelect();
        }
    };

    const getFieldsSize = (size: string) => {
        let gridItemSize;

        switch (size) {
            case "xs":
                gridItemSize = 2;
                break;
            case "sm":
                gridItemSize = 1;
                break;
            default:
                gridItemSize = 1;
                break;
        }

        if (enableSelect && enableDelete) {
            return (12 - gridItemSize * 2) as GridSize;
        }

        if ((enableSelect && !enableDelete) || (!enableSelect && enableDelete)) {
            return (12 - gridItemSize) as GridSize;
        }

        return 12;
    };

    return (
        <React.Fragment>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={label ? 1 : undefined}>
                    <Typography variant="caption">{label}</Typography>
                </Grid>
                <Grid container item xs={label ? 11 : 12} justifyContent="center" alignItems="center" spacing={1}>
                    <Grid container item xs={getFieldsSize("xs")} sm={getFieldsSize("sm")} spacing={1}>
                        <Grid item xs={6}>
                            <TextField
                                {...controlInputProps}
                                className={classes.disableNumberInputRows}
                                variant="outlined"
                                value={valueX.toString()}
                                name={`valueX_${index || 0}`}
                                type="number"
                                label={labelX || "x"}
                                margin="dense"
                                fullWidth={true}
                                disabled={isDisabled}
                                onChange={handleOnChangeFirstCoordinate}
                                onFocus={selectField}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                {...controlInputProps}
                                className={classes.disableNumberInputRows}
                                variant="outlined"
                                value={valueY.toString()}
                                name={`valueY_${index || 0}`}
                                type="number"
                                label={labelY || "y"}
                                margin="dense"
                                fullWidth={true}
                                disabled={isDisabled}
                                onChange={handleOnChangeSecondCoordinate}
                                onFocus={selectField}
                            />
                        </Grid>
                    </Grid>
                    {enableSelect && !isDisabled && (
                        <Grid item xs={2} sm={1}>
                            <IconButton onClick={selectField} size="small"  edge="start">
                                {isSelected ? <WhereToVoteIcon color="primary" /> : <PlaceIcon color="disabled" />}
                            </IconButton>
                        </Grid>
                    )}
                    {enableDelete && !isDisabled && (
                        <Grid item xs={2} sm={1}>
                            <IconButton onClick={removeField} size="small" edge="start">
                                <DeleteForeverIcon color="secondary" />
                            </IconButton>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export { PointFields };
