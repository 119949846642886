import React, {useMemo} from "react";
import Snackbar from "@material-ui/core/Snackbar";
import {SnackbarCloseReason} from "@material-ui/core/Snackbar/Snackbar";
import MuiAlert, {AlertProps} from "@material-ui/lab/Alert";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import {pubApnNotification} from "../../Services/NotificationService"
import type {ToastType} from "../../Services/NotificationService";

const Alert = (props: AlertProps) => {
    return <MuiAlert elevation={6} variant="filled" {...props}/>;
};

const useStyles = makeStyles(() =>
    createStyles({
        notify: {
            width: '100%',
            position: 'absolute',
            top: '0'
        },
        root: {
            width: '100%',
            top: '64px',
            zIndex: 1150
        },
        message: {
            width: '95%'
        },

    })
);

type SnackBarMessageProp = {
    toast: ToastType | null;
    dismiss: Function;
}

const SnackBarMessage = (props: SnackBarMessageProp) => {

    const classes = useStyles();
    const {toast, dismiss} = props;

    const handlerOnClose = (id: number, event: React.SyntheticEvent<any>, reason?: SnackbarCloseReason) => {
        event?.preventDefault();
        event?.stopPropagation();

        if (reason === "clickaway" || !id) {
            return;
        }

        dismiss();
    };

    const handleMessageClick = (toast_object: ToastType) => {
        if (toast_object && toast_object.notification_payload) {
            const notification = toast_object.notification_payload;
            pubApnNotification(notification).then(() => {});
            dismiss();
        }
    };

    const autoHideDuration = useMemo(() => {
        return toast?.options?.autoDismiss && toast.options.autoDismissTime ? toast.options.autoDismissTime : undefined;
    }, [toast?.options?.autoDismiss, toast?.options?.autoDismissTime]);

    if (!toast) {
        return null;
    }

    return (
        <div className={classes?.notify}>
            <Snackbar
                classes={{root: classes.root}}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                key={toast?.id}
                open={true}
                onClose={handlerOnClose.bind(null, toast?.id)}
                autoHideDuration={autoHideDuration}
            >
                <Alert
                    severity={toast?.options?.type}
                    onClose={handlerOnClose.bind(null, toast?.id)}
                    onClick={handleMessageClick.bind(null, toast)}
                    classes={{root: classes.root, message: classes.message}}
                >
                    {toast?.message}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default SnackBarMessage;
