import React from "react";
import {SlideType} from "../WelcomeDialogContent";
import {Grid, Icon, Typography} from "@material-ui/core";

export const Slide7 = (props: SlideType) => {
    return (
        <>
            <Typography
                variant={props.xsSize ? "h6" : "h3"}
                align="center"
                gutterBottom={true}>
                Got a camera?
            </Typography>

            <Grid
                container
                direction="row"
                spacing={4}
                justifyContent="center"
                alignItems="center">
                <Grid item xs={6} sm={2}>
                    <Typography
                        variant={props.xsSize ? "body1" : "h6"}
                        align="center"
                        gutterBottom={true}>
                        <Icon className={"fas fa-video"}/><br/>
                        RTSP
                    </Typography>
                </Grid>
                <Grid item xs={6} sm={2}>
                    <Typography
                        variant={props.xsSize ? "body1" : "h6"}
                        align="center"
                        gutterBottom={true}>
                        <Icon className={"fas fa-camera"}/><br/>
                        iOS Camera
                    </Typography>
                </Grid>
            </Grid>

            <Typography
                variant={props.xsSize ? "body1" : "h5"}
                align="center"
                gutterBottom={true}>
                Stay aware of the situation around you by associating the camera image with the certain geographical
                area and enabling the object detection algorithms of LocalMachines
            </Typography>
        </>
    )
}
