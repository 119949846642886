import React, {Fragment, useCallback, useEffect, useState} from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import ObjectsRouting from "./Objects/ObjectsRouting";
import {
    EMPTY_OBJECT,
    getObjectById,
    ObjectItem,
    rootControllers,
} from "../Services/ObjectsService";
import { authControllers, OBJECT_TYPE } from "../Services/ObjectsService/Constants";

import {Redirect, Route, Switch, useHistory, useLocation} from "react-router-dom";
import Loader from "./Loader";
import {useGlobalNotificationContext} from "./Notification/NotificationProvider";

import {SiteMap} from "./Objects/SiteMap/SiteMap";

import UserProfile from "./User/UserProfile/UserProfile";
import useDarkMode, {DarkMode} from "@fisch0920/use-dark-mode";
import {getCurrentDeviceId} from "../Services/ObjectsService/ObjectsNativeService/ObjectsNativeService";
import {checkPathIncludes} from "../Services/Utils";
import {UserInfoType, useUserProfile} from "../Hooks/useUserProfile";
import MenuComponent from "./Menu/MenuComponent";
import Purchases from "./Purchases/Purchases";
import {
    useGlobalLoaderActionsContext,
    useGlobalLoaderContext,
    useUpdateGlobalDialogActionsContext,
    useUpdateGlobalDialogContentActionsContext
} from "../GlobalContext/GlobalContext";
import AppGlobalDialogs from "./Dialogs/AppGlobalDialogs";
import {changeDesignMode, configureDeviceInfo, Notification, setCurrentClientConfig} from "../Services/DeviceService";
import {getPlanInfo} from "../Services/PurchasesService";
import Authorization from "./Authorization/Authorization";
import type {SnackBarType} from "../Services/NotificationService";
import PrivacyPolicy from "./Public/PrivacyPolicy/PrivacyPolicy";
import CheckAuthComponent from "./Authorization/CheckAuthComponent/CheckAuthComponent";
import AddPeopleDialog from "./Dialogs/AddPeopleDialog/AddPeopleDialog";
import { useSubOrganization } from "../Hooks/SystemEvent/useContext";
import { getOrganization, setOrganization } from "Helpers/OrganizationsHelper";

const DEFAULT_PAGE_LINK: string = process.env.REACT_APP_DEFAULT_PAGE_LINK || "";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // necessary for content to be below app bar
        toolbar: theme.mixins.toolbar,
        content: {
            width: "100%",
            flexGrow: 1,
            padding: theme.spacing(0),
            height: "100%",
        },
        mobileContentForEdit: {
            overflow: "hidden",
            touchAction: "none",
            position: "relative",
        },
    })
);

const WINDOW = (window as any);

export function MainContent() {
    const darkMode: DarkMode = useDarkMode();

    const classes = useStyles();

    const history = useHistory();
    const location = useLocation();
    const userInfo: UserInfoType = useUserProfile();
    const notify: SnackBarType = useGlobalNotificationContext();

    const loader = useGlobalLoaderContext();
    const setLoader = useGlobalLoaderActionsContext();

    const product_id = getPlanInfo().product_id;
    const setGlobalDialog = useUpdateGlobalDialogActionsContext();
    const setGlobalDialogContent = useUpdateGlobalDialogContentActionsContext();

    const [object, setObject] = useState<ObjectItem>(EMPTY_OBJECT);
    const [parentId, setParentId] = useState<string>("");

    const [openModalBind, setOpenModalBind] = useState<boolean>(false);
    const [openModalDirect, setOpenModalDirect] = useState<boolean>(false);
    const [openModalShare, setOpenModalShare] = useState<boolean>(false);

    const redirect = React.useRef<string>("/map");

    const saveObjectByKey = useCallback((key: string, value: any) => {
        setObject((prevState) => ({...prevState, [key]: value}));
    }, [setObject]);

    const contentStyle = () => {
        const re = new RegExp("(.*)/plan");
        if (re.test(location.pathname)) {
            return [classes.content, classes.mobileContentForEdit].join(" ");
        }
        return classes.content;
    };

    const handleBackButton = useCallback(() => {
        if (!/\/(map|plan)(\/edit|)/.test(location.pathname)) setObject(EMPTY_OBJECT);
        if (/^((?:(?!\/map\/|\/plan\/).)*(\/map|\/page|\/plan|\/chat|\/history|\/edit|\/create-object|\/profile))$/.test(location.pathname)) {
            return history.goBack();
        }
        let pathName = location.pathname.split("/");

        if (pathName.length > 1) {
            if (pathName[pathName.length-2] === "objects") {
                history.replace(DEFAULT_PAGE_LINK);
                return;
            }

            (pathName[pathName.length - 2] === object.object_id ||
                rootControllers.includes(pathName[pathName.length - 2])) &&
            setObject(EMPTY_OBJECT);
            //pathName.pop() === "edit" && pathName[pathName.length - 1] === object.object_id && pathName.pop();
            pathName.pop();
        }

        history.replace(pathName.join("/"));
    }, [history, location.pathname, object.object_id]);

    useSubOrganization(() => {
        let orgRouting = "";
        const selectedOrg = getOrganization();

        if (selectedOrg?.object_id) {
            orgRouting = "/org/" + selectedOrg.object_id;
        }

        if (orgRouting && location.pathname.startsWith(orgRouting)) {
            return;
        }

        const _locationPathname = location.pathname.replace(/(org\/[0-9a-z-]+\/)/, "");
        const pathName = _locationPathname.split("/");

        if (rootControllers.includes(pathName[1]) && location.pathname !== orgRouting + "/" + pathName[1]) {
            history.replace(orgRouting + "/" + pathName[1]);
        } else if (location.pathname !== orgRouting + "/" + pathName[1] && location.pathname !== DEFAULT_PAGE_LINK) {
            history.replace(DEFAULT_PAGE_LINK);
        }
    });

    // [IM] Should be moved to DeviceService
    // After rewriting opening modals using subscriptions
    const handleMaxNumberOfActiveIPCameras = () => {
        if (product_id === "free") {
            setGlobalDialog("UpgradeToProDialog");
            setGlobalDialogContent("number_of_cameras_pro")
        }
    };
    const handleNotification = (notification: Notification) => {
        switch (notification.code) {
            case "Camera.maxNumberOfActiveIPCameras.limitExceeded":
                handleMaxNumberOfActiveIPCameras()

        }
    };
    WINDOW.showNotification = (inputMessage: string) => {
        let notification = JSON.parse(inputMessage) as Notification;
        console.log("Notification ", notification);
        console.error(notification.code);
        handleNotification(notification)
    };
    // [IM] Should be moved to DeviceService
    // After rewriting opening modals using subscriptions

    useEffect(() => {
        if (!userInfo.clientId) {
            return;
        }
        setCurrentClientConfig();
    }, [userInfo.clientId]);

    useEffect(() => {
        changeDesignMode(darkMode.value);
    }, [darkMode.value]);

    useEffect(() => {
        let active = true;

        const stored_org = getOrganization();

        if ((!stored_org && location.pathname.includes('/org/')) || (stored_org?.object_name === 'Loading...' && location.pathname === DEFAULT_PAGE_LINK)) {
            let org_id = stored_org?.object_id || location.pathname.split('/')[2];

            if (org_id) {
                let org_object: ObjectItem | null = null;

                if (!stored_org) {
                    org_object = {
                        object_id: org_id,
                        object_name: 'Loading...',
                        object_type: OBJECT_TYPE.ORGANIZATION,
                        description: "",
                        properties: {}
                    };

                    setOrganization(org_object);
                }

                (async () => {
                    try {
                        org_object = await getObjectById(org_id, {skipChildrenTransform: true});
                    } catch (e) {
                        console.log(e);
                    }

                    if (!active) {
                        return;
                    }

                    setOrganization(org_object);
                })();
            }
        }

        return () => {
            active = false;
        }
    }, [location.pathname]);

    useEffect(() => {
        let active = true;
        const redirectPaths = ["/profile", "/objects"];
        const publicPaths = ["/verified-email", "/login", "/create-password", "/forgot-password"];

        if (!userInfo.checkAuthToken() && checkPathIncludes(location.pathname, redirectPaths)) {
            redirect.current = location.pathname;
        } else if (!checkPathIncludes(location.pathname, publicPaths)) {
            redirect.current = DEFAULT_PAGE_LINK;
        }

        (async () => {
            if (!location.pathname.includes("create-object")) {
                setParentId("");
            } else if (location.pathname.includes("/cameras/")) {
                let objectId = "";

                try {
                    objectId = await getCurrentDeviceId();
                } catch (e) {
                    console.log(e);
                }

                if (!active) {
                    return;
                }

                setParentId(objectId);
            } else {
                setParentId(object.object_id || getOrganization()?.object_id || '');
            }
        })();

        return () => {
            active = false;
        }
    }, [location.pathname]);

    const handleAddPeopleDialogClose = useCallback(() => {
        setOpenModalDirect(false);
    }, []);

    useEffect(() => {
        configureDeviceInfo();
    }, []);

    return (
        <Fragment>
            <MenuComponent
                setOpenModalBind={setOpenModalBind}
                setOpenModalDirect={setOpenModalDirect}
                setOpenModalShare={setOpenModalShare}
                object={object}
                setObject={setObject}
                handleBackButton={handleBackButton}
                userInfo={userInfo}
            />
            <main className={contentStyle()}>
                <Loader state={loader}/>

                <div className={classes.toolbar}/>
                <Switch>
                    <Route
                        exact
                        strict
                        path="(.*//+.*)"
                        render={({location}) => <Redirect to={location.pathname.replace(/\/\/+/g, "/")}/>}
                    />
                    <Route key="privacy-policy" path="/privacy-policy" exact>
                        <PrivacyPolicy/>
                    </Route>
                    <Route key="map" path={["/map", "/map/edit"]} exact>
                        <CheckAuthComponent userInfo={userInfo}>
                            <SiteMap
                                userInfo={userInfo}
                                notify={notify}
                                handleBackButton={handleBackButton}
                                saveObjectByKey={saveObjectByKey}
                                setObject={setObject}
                                displayModalBind={setOpenModalBind}
                                openModalBind={openModalBind}
                            />
                        </CheckAuthComponent>
                    </Route>
                    <Route key="objects" path={`/(org/[0-9a-z-]+/)?(${rootControllers.join("|")})`}>
                        <CheckAuthComponent userInfo={userInfo}>
                            <ObjectsRouting
                                saveObjectByKey={saveObjectByKey}
                                setObject={setObject}
                                displayLoader={setLoader}
                                displayModalBind={setOpenModalBind}
                                displayModalDirect={setOpenModalDirect}
                                displayModalShare={setOpenModalShare}
                                openModalBind={openModalBind}
                                openModalDirect={openModalDirect}
                                openModalShare={openModalShare}
                                object={object}
                                notify={notify}
                                loader={loader}
                                userInfo={userInfo}
                                handleBackButton={handleBackButton}
                                parentId={parentId}
                            />
                        </CheckAuthComponent>
                    </Route>
                    <Route key="profile" path="/profile">
                        <CheckAuthComponent userInfo={userInfo}>
                            <UserProfile
                                userInfo={userInfo}
                                notify={notify}
                                setLoader={setLoader}
                                loader={loader}
                            />
                        </CheckAuthComponent>
                    </Route>
                    <Route key="Purchases" path="/purchases">
                        <CheckAuthComponent userInfo={userInfo}>
                            <Purchases displayLoader={setLoader} userInfo={userInfo} loader={loader}/>
                        </CheckAuthComponent>
                    </Route>
                    <Route key="auth" path={`/(${authControllers.join("|")})`}>
                        <Authorization userInfo={userInfo} redirectPath={redirect.current}/>
                    </Route>
                    <Redirect from="*" to={DEFAULT_PAGE_LINK}/>
                </Switch>
                <AppGlobalDialogs
                    userInfo={userInfo}/>
                {openModalDirect && <AddPeopleDialog
                    open={openModalDirect}
                    userInfo={userInfo}
                    handleCloseDialog={handleAddPeopleDialogClose}
                />}
            </main>
        </Fragment>
    );
}
