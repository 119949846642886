import {useEffect, useState, useMemo} from "react";
import {matchPath, Route, Switch, useLocation, useRouteMatch} from "react-router-dom";
import ObjectsGrid from "./ObjectsGrid";
import ObjectDetails from "./ObjectDetails/ObjectDetails";
import ObjectForm from "./ObjectForm/ObjectForm";
import {forceSyncObjectById, getObjectById, ObjectItem, rootControllers} from "../../Services/ObjectsService";
import type {SnackBarType} from "../../Services/NotificationService";
import { ShowBottomNavigation } from "../Menu/NavigationBar/BottomNavigationBar";
import {SiteMap} from "./SiteMap/SiteMap";
import {UserInfoType} from "../../Hooks/useUserProfile";
import ObjectFloorPlan from "./ObjectFloorPlan";
import {ObjectDocument} from "./ObjectDocument";
import {PageEditController} from "../Controls/Page/PageEditController";
import ObjectConversation from "./Conversation/ObjectConversation";
import ObjectHistory from "./History/ObjectHistory";
import { useSubObjectDelete } from "Hooks/Subscriptions/useSubObjectDelete";

type ObjectsRoutingProps = {
    setObject(object: ObjectItem): void;
    saveObjectByKey(key: string, value: any): void;
    displayLoader(value: boolean): void;
    displayModalBind(state: boolean): void;
    displayModalDirect(state: boolean): void;
    displayModalShare(state: boolean): void;
    openModalBind: boolean;
    openModalDirect: boolean;
    openModalShare: boolean;
    object: ObjectItem;
    notify: SnackBarType;
    loader: boolean;
    userInfo: UserInfoType;
    handleBackButton(): void;
    parentId?: string;
};

export default function ObjectsRouting(props: ObjectsRoutingProps) {
    let location = useLocation();
    let {path} = useRouteMatch();
    const [currentUrl, setCurrentUrl] = useState<string>("");

    const _locationPathname = location.pathname.replace(/(org\/[0-9a-z-]+\/)/, "");

    let {params}: any = matchPath(`/${_locationPathname.replace(/(\/(plan\/edit|map\/edit|page\/edit|edit|page|plan|map|chat|history)$)/, "")}`, [
        "(.*)/:parentId/:id",
        "(.*)/:id",
    ]) || {params: {}};

    const controllerMatch: any = matchPath(_locationPathname, {
        path: "/:controller/(.*)*",
    });

    const requested_type = useMemo(() => {
        if (controllerMatch) {
            switch (controllerMatch.params.controller) {
                case "machines":
                    return "Machine";
                case "consumables":
                    return "Machine";
                case "sites":
                    return "Site";
                case "buildings":
                    return "Building";
                case "organizations":
                    return "Organization";
                case "cameras":
                    return "Camera";
                case "documents":
                    return "Document";
                case "files":
                    return "File";
                case "conversations":
                    return "Conversation";
                case "favorites":
                    return "Favorites";
                case "shared-with-me":
                    return "SharedWithMe";
            }
        }
        return "";
    }, [controllerMatch]);

    const parentId = useMemo(()=>{
        return params.parentId !== controllerMatch.params.controller ? params.parentId : null;
    }, [controllerMatch.params.controller, params.parentId]);

    const delay = (wait: number) => {
        return new Promise(res => {
            setTimeout(res, wait);
        })
    }

    const syncObject = async (object_id: string, wait: number) => {
        let attempts = 0
        while (attempts <= 5) {
            try {
                await forceSyncObjectById(object_id)
                return;
            } catch (e) {
                console.log("SyncDetectedObject : ", e);
                await delay(wait);
            }
            attempts++;
        }

    }

    useEffect(() => {
        setCurrentUrl(location.pathname);
        if (/\/(map)(\/edit|)/.test(location.pathname) && currentUrl && controllerMatch.params.controller !== 'sites') return;
        if ([controllerMatch.params.controller, "create-object"].includes(params.id)) return;

        if (currentUrl !== location.pathname) {
            (async () => {
                props.displayLoader(true);
                try {
                    await syncObject(params.id, 5000);

                    const objectResult = await getObjectById(params.id, {
                        includeDeleted: true
                    });
                    props.setObject(objectResult);
                } catch (e: any) {
                    props.notify.errorNotify(e?.message || JSON.stringify(e));
                    props.handleBackButton();
                    return;
                }
                props.displayLoader(false);
            })();
        }

        return () => {
            setCurrentUrl("");
        };
    }, [location.pathname]);

    useSubObjectDelete(parentId && params.id, () => {
        // [IM] Emulate back button if currently opened object removed
        props.handleBackButton();
    });

    return (
        <>
            <Switch>
                <Route path={`(.*)/(page/edit)`}>
                    <PageEditController
                        object={props.object}
                        saveObjectByKey={props.saveObjectByKey}
                        userInfo={props.userInfo}
                    />
                </Route>
                <Route path={`(.*)/(page)`}>
                    <ObjectDocument
                        object={props.object}
                        userInfo={props.userInfo}
                        displayModalBind={props.displayModalBind}
                        openModalBind={props.openModalBind}
                    />
                </Route>
                <Route path={`(.*)/(plan|plan/edit)`}>
                    <ObjectFloorPlan
                        displayLoader={props.displayLoader}
                        setObject={props.setObject}
                        displayModalBind={props.displayModalBind}
                        handleBackButton={props.handleBackButton}
                        saveObjectByKey={props.saveObjectByKey}
                        openModalBind={props.openModalBind}
                        object={props.object}
                        notify={props.notify}
                        userInfo={props.userInfo}
                    />
                </Route>
                <Route path={`(.*)/(chat)`}>
                    <ObjectConversation
                        object={props.object}
                        openModalShare={props.openModalShare}
                        displayModalShare={props.displayModalShare}
                    />
                </Route>
                <Route strict exact path={`(.*)/(map|map/edit)`}>
                    <SiteMap
                        userInfo={props.userInfo}
                        notify={props.notify}
                        parentId={params.id}
                        handleBackButton={props.handleBackButton}
                        saveObjectByKey={props.saveObjectByKey}
                        setObject={props.setObject}
                        displayModalBind={props.displayModalBind}
                        openModalBind={props.openModalBind}
                    />
                </Route>
                <Route exact path={`${path}/create-object`}>
                    <ObjectForm
                        setObject={props.setObject}
                        userInfo={props.userInfo}
                        parentId={props.parentId}
                        creating
                        handleBackButton={props.handleBackButton}
                    />
                </Route>
                <Route exact path={`(.*)/:id/edit`}>
                    <ObjectForm
                        setObject={props.setObject}
                        userInfo={props.userInfo}
                        parentId={props.parentId}
                        handleBackButton={props.handleBackButton}
                    />
                </Route>
                <Route strict exact path={`(.*)/(${rootControllers.join("|")})`} key={requested_type}>
                    <ObjectsGrid
                        displayLoader={props.displayLoader}
                        requested_type={requested_type}
                        notify={props.notify}
                        userInfo={props.userInfo}
                        setObject={props.setObject}
                        openModalDirect={props.openModalDirect}
                        displayModalDirect={props.displayModalDirect}
                    />
                </Route>
                <Route strict exact path={`(.*)/:id/history`}>
                    <ObjectHistory
                        object={props.object}
                        notify={props.notify}
                        userInfo={props.userInfo}
                        parentId={parentId}
                    />
                </Route>
                <Route strict exact path={`(.*)/:id`}>
                    {props.object?.object_id && <ObjectDetails
                        parentId={parentId}
                        setObject={props.setObject}
                        displayModalBind={props.displayModalBind}
                        handleBackButton={props.handleBackButton}
                        openModalBind={props.openModalBind}
                        object={props.object}
                        userInfo={props.userInfo}
                    />}
                </Route>
            </Switch>
            <ShowBottomNavigation
                objectType={props.object.object_type}
            />
        </>
    );
}
