import React from "react";
import { Avatar, ListItem, ListItemAvatar, ListItemText, Typography } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { blue } from "@material-ui/core/colors";

// Components
import type { MessageItemType } from "Components/Objects/Conversation/ObjectConversation";

// Services
import { getTextAvatar } from "Services/Utils";

const useStyles = makeStyles((theme: Theme) => ({
    chatMessage: {
        paddingTop: '0',
        paddingBottom: '0',
        "&:hover": {
            backgroundColor: theme.palette.action.hover
        },
        "&:hover > * > span": {
            display: 'block'
        }
    },
    chatMessageText: {
        minHeight: '20px'
    },
    ownAvatar: {
        backgroundColor: blue[100],
        color: blue[600]
    },
    clockAvatar: {
        display: 'none',
        textAlign: 'center',
        verticalAlign: 'bottom'
    },
    username: {
        fontSize: '0.9rem',
        fontWeight: 'bold',
        display: 'inline',
        marginRight: theme.spacing(1)
    }
}));

const formatChatMessageTimestamp = (timestamp: number) => {
    return new Date(timestamp).getHours() + ':' + ("0" + new Date(timestamp).getMinutes()).slice(-2);
};

type ChatMessageProps = {
    chatMessage: MessageItemType;
};

const ChatMessage = (props: ChatMessageProps) => {

    const { chatMessage } = props;

    const classes = useStyles();

    return (
        <ListItem
            alignItems="flex-start"
            classes={{ root: classes.chatMessage }}
        >
            <ListItemAvatar>
                {!chatMessage.hideTitle ? (
                    <Avatar className={(chatMessage.own ? classes.ownAvatar : "")}>
                        {getTextAvatar(chatMessage.title)}
                    </Avatar>
                ) : (
                    <Typography variant="caption" color="textSecondary" className={classes.clockAvatar}>
                        {formatChatMessageTimestamp(chatMessage.date)}
                    </Typography>
                )}
            </ListItemAvatar>
            <ListItemText
                classes={{ secondary: classes.chatMessageText }}
                primary={!chatMessage.hideTitle && (
                    <>
                        <Typography className={classes.username}>
                            {chatMessage.title}
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                            {formatChatMessageTimestamp(chatMessage.date)}
                        </Typography>
                    </>
                )}
                secondary={
                    <>
                        <Typography variant="body2" color="textPrimary" component="span">
                            {chatMessage.text}
                        </Typography>
                    </>
                }
            />
        </ListItem>
    );

}

export default ChatMessage;
