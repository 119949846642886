export enum SubscriptionType {
    CHILDREN,
    NEW,
    DELETE,
    STATUS,
    PROPERTIES,
    NOTIFICATION_STATUS,
    NOTIFICATION_DETECT_OBJECT,
    NOTIFICATION_LOST_OBJECT,
    NOTIFICATION_PROPERTIES,
    MAP,
    NOTIFICATION_STATE,
    FAVORITES,
    OBJECTS_TYPE,
    PERMISSIONS,
    NOTIFICATION_CHAT_MESSAGE
}

export enum OriginType {
    REMOTE,
    NATIVE,
    USER,
    LOCAL
}

export const EMPTY_OBJECT = {
    object_id: "",
    object_type: "",
    object_name: "",
    description: "",
    children: [],
    plan: null,
    properties: {},
};

export const OBJECT_TYPE = {
    BACK_CAMERA: "BackCamera",
    BUILDING: "Building",
    ORGANIZATION: "Organization",
    CAMERA_MAP_ZONE: "CameraMapZone",
    CAMERA_PLAN_ZONE: "CameraPlanZone",
    DOCUMENT: "Document",
    FILE: "File",
    CONVERSATION: "Conversation",
    FLOOR: "Floor",
    FRONT_CAMERA: "FrontCamera",
    IP_CAMERA: "IPCamera",
    MACHINE: "Machine",
    ROOM: "Room",
    SERVICE: "Service",
    SITE: "Site",
    ZONE: "Zone",

    UNKNOWN: "Unknown"
}

export const CAMERA_TYPES = [
    OBJECT_TYPE.BACK_CAMERA,
    OBJECT_TYPE.FRONT_CAMERA,
    OBJECT_TYPE.IP_CAMERA
];

export const CAMERA_ZONE_TYPES = [
    OBJECT_TYPE.CAMERA_MAP_ZONE,
    OBJECT_TYPE.CAMERA_PLAN_ZONE
];

export const imagePropertyKey = "image";

export const CREATION_TYPES = [
    OBJECT_TYPE.FLOOR,
    OBJECT_TYPE.ZONE,
    OBJECT_TYPE.ROOM,
    OBJECT_TYPE.CAMERA_MAP_ZONE,
    OBJECT_TYPE.CAMERA_PLAN_ZONE,
    OBJECT_TYPE.IP_CAMERA,
    OBJECT_TYPE.SERVICE,
    OBJECT_TYPE.DOCUMENT,
    OBJECT_TYPE.FILE
];

export const ROOT_TYPES = [
    OBJECT_TYPE.ORGANIZATION,
    OBJECT_TYPE.BUILDING,
    OBJECT_TYPE.SITE,
    OBJECT_TYPE.MACHINE,
    OBJECT_TYPE.DOCUMENT,
    OBJECT_TYPE.CONVERSATION
];

export const PLAN_RELATED_TYPES = [
    OBJECT_TYPE.BUILDING,
    OBJECT_TYPE.ROOM,
    OBJECT_TYPE.FLOOR,
    OBJECT_TYPE.ZONE,
    OBJECT_TYPE.CAMERA_PLAN_ZONE,
    OBJECT_TYPE.CAMERA_MAP_ZONE
];

export const rootControllers = ["machines", "consumables", "documents", "files", "sites", "buildings", "organizations", "cameras", "objects", "favorites", "conversations", "shared-with-me"];

export const authControllers = ["login", "verified-email", "forgot-password", "create-password", "reset-password"];

export const pagesException = ["favorites", "shared-with-me"];

export const pagesWithSharing = ["conversations"];

export const PROPERTY_KEYS_FOR_TIME_TRANSFORM = ["expiration_time", "detection_time"];

export enum DeviceHandler {
    IOS_NOTIFICATION = "handlerForIOSNotification",
    GET_DEVICE_INFO = "handlerForGetDeviceInfo",
    UPDATE_OBJECT_PROPERTIES = "handlerForUpdateObjectProperties",
    SET_CAMERAS = "handlerForSetCameras",
    RUN_UPDATING_DATABASES = "handlerForRunUpdatingDatabases",
    INIT_OBJECT_BY_ID = "handlerForInitializingObjectByID",
    SET_CLIENT_ID = "handlerForSetCurrentClientID",
    SET_CLIENT_CONFIG = "handlerForSetCurrentClientConfig",
    GET_QR_DATA = "handlerForStartGetDataQRCode",
    ADD_NEW_CAMERA = "handlerForAddingNewCamera",
    GET_SUBSCRIPTIONS = "handlerForGetiOSSubscriptions",
    GET_DEVICE_TOKEN = "handlerForGetRemoteNotificationToken",
    UPGRADE_SUBSCRIPTION = "handlerForPostiOSSubscriptionUpgrade",
    RESTORE_PURCHASES = "handlerForRestoreiOSPurchases",
    SIGN_OUT = "handlerForSignOutUser",
    IOS_NOTIFICATION_DEBUG_MANAGER = "handlerForWebNotificationInDebugManager"
}

export enum AppBundleID {
    LOCALMACHINES = "io.localmachines.app",
}

// [IM] Should we, possible, completely remove COMMENT and CONTROL access levels?
export enum AccessLevel {
    OWNER = "owner",
    REVOKED = "revoked",
    READ = "read",
    COMMENT = "comment",
    CONTROL = "control",
    CONTRIBUTE = "contribute",
    WRITE = "write",
}

export enum AccessType {
    PUBLIC = "public",
    RESTRICTED = "restricted"
}

export const OBJECT_TYPE_WITH_DISABLED_SHARING = ["Machine", "Service"];

export const VISIBILITY_OF_DETECTED_OBJECT = ["As a parent", "Public", "Private"];

export const OBJECT_TYPES_ALLOWED_FOR_DETECTION = [
    "Airplane", "Apple", "Backpack", "Banana", "Baseball Bat", "Baseball Glove", "Bear", "Bed",
    "Bench", "Bicycle", "Bird", "Boat", "Book", "Bottle", "Bowl", "Broccoli", "Bus", "Cake", "Car",
    "Carrot", "Cat", "Cell Phone", "Chair", "Clock", "Couch", "Cow", "Cup", "Dining Table", "Dog",
    "Donut", "Elephant", "Fire Hydrant", "Fork", "Frisbee", "Giraffe", "Hair Drier", "Handbag",
    "Horse", "Hot Dog", "Keyboard", "Kite", "Knife", "Laptop", "Microwave", "Motorcycle", "Mouse",
    "Orange", "Oven", "Parking Meter", "Person", "Pizza", "Potted Plant", "Refrigerator", "Remote",
    "Sandwich", "Scissors", "Sheep", "Sink", "Skateboard", "Skis", "Snowboard", "Spoon", "Sports Ball",
    "Stop Sign", "Suitcase", "Surfboard", "Teddy Bear", "Tennis Racket", "Tie", "Toaster", "Toilet",
    "Toothbrush", "Traffic Light", "Train", "Truck", "Tv", "Umbrella", "Vase", "Wine Glass", "Zebra"
];

// export const PROPERTIES_EDITABLE_ONLY_IN_PRO_VERSION = ["preferred_resolution"]; // [IM 15/06/2022] temporary increased to Pro level

export const PROPERTIES_EDITABLE_ONLY_IN_PRO_VERSION: string[] = [];

export const AVAILABLE_PROPERTY_KEYS = [
    "key",
    "property_id",
    "name",
    "readable",
    "type",
    "value",
    "reported_value",
    "value_options_list",
    "icon",
    "units",
    "min",
    "max",
    "valid_values",
    "writable",
    "visibility",
    "protection",
    "value_epoch",
    "reported_value_epoch",
];

export const AVAILABLE_NATIVE_PROPERTY_KEYS = [
    "reported_value",
    "value",
    "property_id",
    "type"
];

export const AVAILABLE_OBJECT_KEYS = [
    // "local_id",
    "object_id",
    "object_type",
    "object_name",
    "description",
    "children",
    "children_info",
    "source",
    "properties",
    "icon",
    "permissions",
    // "owner_id",
    "deleted",
    "created",
    "updated",
    "last_active",
    "reachable",
    "primary_client",
    "native_id",
    "native_identification_data",
    "notifications",
    "favorites",
    "favorites_state",
    "shared_as_root",
    "encryption_key",
    "orientation",
    "observer_id",
    "expiration_time",
    "observer_name",
    "service_life_notifications",
];


export const AVAILABLE_LOCAL_OBJECT_KEYS = [
    "local_id",
    "owner_id"
];

export const AVAILABLE_PRIVATE_ACL_KEYS = [
    "access_level",
    "root",
    "updated",
];

// TODO check types
export const PROPERTIES_WITH_NOTIFICATION = [
    "TargetSecuritySystemState",
    "TargetLockMechanismState",
    "TargetDoorState",
    "CarbonDioxideDetected",
    "StatusFault",
    "StatusLowBattery",
    "StatusTampered",
    "CarbonMonoxideDetected",
    "CurrentDoorState",
    "CurrentLockMechanismState",
    "LeakDetected",
    "MotionDetected",
    "OccupancyDetected",
    "CurrentSecuritySystemState",
    "SmokeDetected",
    "LiveStreaming" // TODO delete
];

export const CHAT_NOTIFICATION = [
    "ChatMessage"
];

export const RESPONSE_CODE = {
    SUCCESSFUL: 0,
    IDENTIFICATION: 1,               // [IDENTIFICATION]: Based on identification data was found another object with another object ID.
                                     // [IDENTIFICATION]: Send to client-side data from found db_object to re-resend data
                                     // [IDENTIFICATION]: with correct object ID, children_info, native_id, and encryption_data [AS]

    MISSING_CRYPTO_KEY: 2,           // [MISSING_CRYPTO_KEY]: DB_object has no crypto_keys and event data also has no crypto_keys.
                                     // [MISSING_CRYPTO_KEY]: Send data to the client-side for re-resend crypto_keys
                                     // [MISSING_CRYPTO_KEY]: for this object if the key exists on the client-side [AS].

    INCORRECT_CRYPTO_KEY: 3,         // [INCORRECT_CRYPTO_KEY]: DB_objects crypto_keys exists, but event data not contain identical object_key_id.
                                     // [INCORRECT_CRYPTO_KEY]: Send to client this error code and delete incorrect local key from client
                                     // [INCORRECT_CRYPTO_KEY]: Try to use correct object key from DB
};
