// HIT TESTS OPTIONS
import {ElementType} from "./tools/Elements/AbstractElement";

import {SingleDoor} from "./tools/Elements/doors/SingleDoor";
import {SingleWindow} from "./tools/Elements/windows/SingleWindow";
import {SolidWall} from "./tools/Elements/walls/SolidWall";
import {ObjectElement} from "./tools/Elements/objects/ObjectElement";
import {ZoneElement} from "./tools/Elements/objects/ZoneElement";


export const hitOptionsAllPrecise = {
    segments: true,
    stroke: true,
    fill: true,
    tolerance: 3,
};

export const hitOptionsAll = {
    segments: true,
    stroke: true,
    fill: true,
    tolerance: 5,
};

export const hitOptionsAllTouch = {
    segments: true,
    stroke: true,
    fill: true,
    tolerance: 5,
};

export const hitOptionsAllGuides = {
    segments: true,
    stroke: true,
    fill: true,
    tolerance: 30,
};

export const hitOptionsSegments = {
    segments: true,
    stroke: false,
    fill: false,
    tolerance: 5,
};

export const hitOptionsSegmentsTouch = {
    segments: true,
    stroke: false,
    fill: false,
    tolerance: 30,
};

export const hitOptionsStroke = {
    segments: false,
    stroke: true,
    fill: false,
    tolerance: 5,
};

export const hitOptionsStrokeTouch = {
    segments: false,
    stroke: true,
    fill: false,
    tolerance: 30,
};

export const hitOptionsFillTouch = {
    segments: false,
    stroke: false,
    fill: true,
    tolerance: 30,
};

export const zoneElementObjectTypes = ["Room", "Zone", "CameraPlanZone"];

// GRID
export const gridSize = 5;
export const GRID_LOWER_BORDER = -2000;
export const GRID_HIGHER_BORDER = 3000;
export const GRID_STEP_SMALL = 5;
export const GRID_STEP_LARGE = 50;
export const GRID_STEP_LARGEWWW = 50;

// MEASUREMENENTS

export const DIMENSIONS_FONT_SIZE = 15;

// WALL ELEMENTS

export const WALL_ELEMENT_WIDTH = 1000;
export const WALL_ELEMENT_HEIGHT = 100;

export const DOOR_OPEN_ANGLE = 30;
export const DOOR_CLOSE_ANGLE = 0;

type IElement = typeof SolidWall | typeof SingleDoor | typeof ObjectElement | typeof ZoneElement | typeof SingleWindow;

const getSupportedElementTypes = (): Map<ElementType, IElement> => {
    let types: Map<ElementType, IElement> = new Map();

    types.set(ElementType.SOLID_WALL, SolidWall);
    types.set(ElementType.SINGLE_DOOR, SingleDoor);
    types.set(ElementType.OBJECT_ELEMENT, ObjectElement);
    types.set(ElementType.ZONE_ELEMENT, ZoneElement);
    types.set(ElementType.SINGLE_WINDOW, SingleWindow);

    /*
    types.set(ElementType.SINGLE_DOOR, SingleDoor);
    

    types.set(ElementType.LIGHT, Light);
    types.set(ElementType.SWITCH, Switch);

    types.set(ElementType.TABLE, Table);
    types.set(ElementType.CHAIR, Chair);*/

    return types;
};

export const SUPPORTED_ELEMENT_TYPES: Map<ElementType, IElement> = getSupportedElementTypes();

export const WALL_ELEMENT_TYPES = [ElementType.SINGLE_DOOR, ElementType.SINGLE_WINDOW];

// SCALE FACTORS

export const MM_PER_PIXEL = 20; // For 100% Scale

// Theme colors

export const LINE_WITH_MAX_STEP_COLOR = {
    "light": "#9999ff",
    "dark": "#757575"
}

export const LINE_WITH_MIN_STEP_COLOR = {
    "light": "#cccccc",
    "dark": "#616161"
}

export const MEASUREMENTS_COLOR = {
    "light": "#212121",
    "dark": "#fafafa"
}

