import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
    createStyles({
        title: {
            flexGrow: 1,
        }
    })
);

export default function IAPNavigationBar() {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Typography variant="h6" noWrap className={classes.title}>
                In‑App Purchase
            </Typography>
        </React.Fragment>
    );
}
