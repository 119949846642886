import { Point, Path, Color } from "paper";
import { AbstractElement, ElementData, ElementType } from "../AbstractElement";
import { hitOptionsStroke, hitOptionsSegments, hitOptionsSegmentsTouch } from "../../../Constants";
import { v4 } from "uuid";

import { MM_PER_PIXEL } from "../../../Constants";

export class SolidWall extends AbstractElement {
    // Accessors

    public isSelected(): Boolean {
        if (this.paperJSItem.selected) {
            return true;
        }
        if (this.paperJSItem.segments > 0) {
            return this.paperJSItem.segments[0].selected || this.paperJSItem.segments[1].selected;
        }
        return false;
    }

    // Mouse Events Handlers. To be invoked by the tools
    // =======================================================================

    /**
        @type paper.Point
    */
    protected dragStartPoint: any;
    protected dragStartP0: any;
    protected dragStartP1: any;

    protected getNewSegmentPosition(oldPoint: any, dragStartPoint: any, event: any) {
        let x = oldPoint.x + event.point.x - dragStartPoint.x;
        let y = oldPoint.y + event.point.y - dragStartPoint.y;
        let point = new Point(x, y);
        return point;
    }

    protected setPropertiesAccordingToCurrentGeometry() {
        if (this.paperJSItem) {
            if (this.paperJSItem.segments.length === 2) {
                this.setProperty("plan_point", [
                    this.paperJSItem.segments[0].point.x * MM_PER_PIXEL,
                    this.paperJSItem.segments[0].point.y * MM_PER_PIXEL,
                ]);

                let delta = new Point(this.paperJSItem.segments[1].point.subtract(this.paperJSItem.segments[0].point));
                this.setProperty("length", delta.length * MM_PER_PIXEL);
                this.setProperty("angle", delta.angle);
            }
        }
    }

    public onMouseDown(event: any) {
        // Element or Element Part selection to be done here

        if (this.paperJSItem.segments.length === 0) {
            // The new wall is being created.
            // On mouse down the first point should be created
            // The second point should be selected for the further movement
            this.paperJSItem.add(new Point(event.point), new Point(event.point));
            this.paperJSItem.segments[1].selected = true;
        } else {
            // Select the closest segment or the entire path if no segments are hit
            let hitResult = this.paperJSItem.hitTest(event.point, hitOptionsSegmentsTouch);
            console.log("Solid Wall. On moust down Hit Results", hitResult);
            if (!hitResult) {
                this.paperJSItem.selected = true;
                this.dragStartPoint = new Point(event.point);
                this.dragStartP0 = new Point(this.paperJSItem.segments[0].point);
                this.dragStartP1 = new Point(this.paperJSItem.segments[1].point);
                console.log("Drag Start", this.dragStartP0, this.dragStartP1);
            } else {
                hitResult.segment.selected = true;
            }
        }
        this.setPropertiesAccordingToCurrentGeometry();
    }

    public onMouseDrag(event: any) {
        // TODO: Only create element case is handled.
        // For Select&Drag, should take into the consideration if the entire element or the particular segement is selected.
        if (this.paperJSItem.segments[0].selected) {
            this.paperJSItem.segments[0].point = new Point(event.point);
        } else if (this.paperJSItem.segments[1].selected) {
            this.paperJSItem.segments[1].point = new Point(event.point);
        } else {
            this.paperJSItem.segments[0].point = this.getNewSegmentPosition(
                this.dragStartP0,
                this.dragStartPoint,
                event
            );
            this.paperJSItem.segments[1].point = this.getNewSegmentPosition(
                this.dragStartP1,
                this.dragStartPoint,
                event
            );
        }
        this.setPropertiesAccordingToCurrentGeometry();
    }

    public onMouseUp(event: any) {
        // TODO: Only create element case is handled.
        // For Select&Drag, should take into the consideration if the entire element or the particular segement is selected.
        if (this.paperJSItem.segments[0].selected) {
            this.paperJSItem.segments[0].point = new Point(event.point);
        } else if (this.paperJSItem.segments[1].selected) {
            this.paperJSItem.segments[1].point = new Point(event.point);
        } else {
            this.paperJSItem.segments[0].point = this.getNewSegmentPosition(
                this.dragStartP0,
                this.dragStartPoint,
                event
            );
            this.paperJSItem.segments[1].point = this.getNewSegmentPosition(
                this.dragStartP1,
                this.dragStartPoint,
                event
            );
        }
        this.setPropertiesAccordingToCurrentGeometry();
    }

    /**
     @method getAlignmentForPoint
     @param point - paper.Point the location of the point for which the alignment 
        information of the current wall should be returned
     @returns \{wallId : uuid, wallTime: double\}, if the point lays on the wall
     @returns null, if the point does not lay on the wall
     */

    public getAlignmentForPoint(point: any): any {
        // Beginnig or end of the wall
        let hitResult = this.paperJSItem.hitTest(point, hitOptionsSegments);
        if (hitResult) {
            if (hitResult.segment === this.paperJSItem.segments[0]) {
                return {
                    wallId: this.paperJSItem.data.id,
                    wallTime: 0,
                };
            } else if (hitResult.segment === this.paperJSItem.segments[1]) {
                return {
                    wallId: this.paperJSItem.data.id,
                    wallTime: 1,
                };
            } else {
                return null;
            }
        } else {
            // Middle of the wall
            let hitResult = this.paperJSItem.hitTest(point, hitOptionsStroke);
            if (hitResult) {
                return {
                    wallId: this.paperJSItem.data.id,
                    wallTime: hitResult.location.offset / this.paperJSItem.length,
                };
            } else {
                return null;
            }
        }
    }

    public getPointForAlignment(alignment: any): any {
        if (alignment.wallId === this.paperJSItem.data.id) {
            return this.paperJSItem.getPointAt(alignment.wallTime * this.paperJSItem.length);
        }
        return null;
    }

    public getTangentForAlignmnet(alignment: any): any {
        if (alignment.wallId === this.paperJSItem.data.id) {
            return this.paperJSItem.getTangentAt(alignment.wallTime * this.paperJSItem.length);
        }
        return null;
    }

    public updateAlignment(elements: AbstractElement[]): void {
        // Get the wall elements, except of the current one
        let wallElements: SolidWall[] = (elements.filter((element: AbstractElement) => {
            return element.getElementType() === ElementType.SOLID_WALL && this.getId() !== element.getId();
        }) as unknown) as SolidWall[];
        // Check if one of the points of the current wall lays on another wall

        let p0Alignments = wallElements
            .map((wall: SolidWall) => {
                let alignment = wall.getAlignmentForPoint(this.paperJSItem.segments[0].point);
                /*if(alignment) {
                    if(alignment.wallTime==0 || alignment.wallTime==1) {
                        wall.updateAlignment([this]);
                    }
                }*/
                return alignment;
            })
            .filter((alignment: any) => {
                return alignment != null;
            });

        let p1Alignments = wallElements
            .map((wall: SolidWall) => {
                let alignment = wall.getAlignmentForPoint(this.paperJSItem.segments[1].point);
                /*if(alignment) {
                    if(alignment.wallTime==0 || alignment.wallTime==1) {
                        wall.updateAlignment([this]);
                    }
                }*/
                return alignment;
            })
            .filter((alignment: any) => {
                return alignment !== null;
            });

        this.paperJSItem.data.alignment = {
            p0: p0Alignments,
            p1: p1Alignments,
        };

        // console.log("New Alignment", this.paperJSItem.data.alignment);
    }

    // Wall-specific alignment function
    public alignElement(elements: AbstractElement[]): void {
        if (this.isSelected()) {
            return;
        }
        /*
        let wallElements: SolidWall[] = (elements.filter((element: AbstractElement) => {
            return element.getElementType() === ElementType.SOLID_WALL && this.getId() !== element.getId();
        }) as unknown) as SolidWall[];

        this.paperJSItem.data.alignment.p0.forEach((alignment: any) => {
            wallElements.forEach((element: SolidWall) => {
                let point = element.getPointForAlignment(alignment);
                if (point) {
                    this.paperJSItem.segments[0].point = new Point(point);
                }
            });
        });

        this.paperJSItem.data.alignment.p1.forEach((alignment: any) => {
            wallElements.forEach((element: SolidWall) => {
                let point = element.getPointForAlignment(alignment);
                if (point) {
                    this.paperJSItem.segments[1].point = new Point(point);
                }
            });
        });
        this.setPropertiesAccordingToCurrentGeometry();
        */
    }

    // Wall-specific geometry update function
    public updateGeometry(): void {
        // Applying Wall-Specific transformation form properties values to geometry
        this.paperJSItem.data.currentValues.thickness = this.getProperty("thickness") / MM_PER_PIXEL;

        let plan_point = this.getProperty("plan_point") || [0, 0];
        var point = new Point(0, 0);
        if (!Array.isArray(this.paperJSItem.segments) || this.paperJSItem.segments.length === 0) {
            this.paperJSItem.add(point, point);
        }

        this.paperJSItem.segments[0].point = new Point(plan_point[0] / MM_PER_PIXEL, plan_point[1] / MM_PER_PIXEL);

        point.length = this.getProperty("length") / MM_PER_PIXEL;
        point.angle = this.getProperty("angle");

        this.paperJSItem.segments[1].point = new Point(plan_point[0] / MM_PER_PIXEL, plan_point[1] / MM_PER_PIXEL).add(
            point
        );
    }

    // Generates the Vector Graphics Object to represent the element in PaperJS
    protected generatePaperJSItem(): void {
        let path: paper.Path = new Path();
        path.strokeColor = new Color("black");
        this.paperJSItem = path;
    }

    /*
        export type PropertyItem = {
        key: string,
        name: string,
        readable: boolean,
        type: string,
        value: string | number | boolean,
        icon?: string,
        units?: string,
        min?: number,
        max?: number,
        writable: boolean,
        visibility: string[]
    }
    */

    // Sets default data applicable for the wall
    protected setDefaultData(): void {
        let data: ElementData = {
            id: v4(),
            type: ElementType.SOLID_WALL,
            info: { object_name: "Wall", description: "Solid wall" },
            properties: {
                plan_point: {
                    key: "plan_point",
                    property_id: v4(),
                    name: "Plan Point",
                    value: {},
                    type: "PlanPoint",
                    readable: true,
                    writable: false,
                    visibility: ["details"],
                },
                length: {
                    key: "length",
                    property_id: v4(),
                    name: "length",
                    value: 0,
                    type: "number",
                    units: "mm",
                    min: 0,
                    max: 1000000,
                    readable: true,
                    writable: false,
                    visibility: ["details"],
                },
                thickness: {
                    key: "thickness",
                    property_id: v4(),
                    name: "thickness",
                    value: 0,
                    type: "number",
                    units: "mm",
                    min: 1,
                    max: 3000,
                    readable: true,
                    writable: false,
                    visibility: ["details"],
                },
                angle: {
                    key: "angle",
                    property_id: v4(),
                    name: "angle",
                    value: 0,
                    type: "number",
                    units: String.fromCharCode(176),
                    min: 0,
                    max: 360,
                    readable: true,
                    writable: false,
                    visibility: ["details"],
                },
            },
            // Visualisation formulas
            formulas: {
                thickness: "thickness/20",
            },
            // Visualisation values
            currentValues: {
                thickness: 0,
            },
            alignment: {
                p0: null,
                p1: null,
            },
        };

        this.paperJSItem.data = data;
        // console.log(data);
        // console.log(this.paperJSItem.data);
    }
}
