import React, { useCallback, useEffect } from "react";
import {
    deleteObject,
    deleteObjectChildren,
    ObjectItem,
    forceSyncObjectFromRemoteDatabaseById
} from "../../../Services/ObjectsService";
import ObjectDetailsHeader from "./ObjectDetailsView/ObjectDetailsContent/ObjectDetailsHeader";
import ShowListSubObjects from "./ObjectDetailsView/ObjectDetailsContent/ShowListSubObjects";
import {CssBaseline} from "@material-ui/core";
import {OriginType} from "../../../Services/ObjectsService/Constants";
import ModalBindWithTree from "./ModalBindWithTree";
import {useGlobalNotificationContext} from "../../Notification/NotificationProvider";
import type {SnackBarType} from "../../../Services/NotificationService";
import {UserInfoType} from "../../../Hooks/useUserProfile";
import {useGlobalLoaderActionsContext} from "../../../GlobalContext/GlobalContext";

type ObjectDetailsProps = {
    setObject(object: ObjectItem): void;
    displayModalBind(state: boolean): void;
    handleBackButton(): void;
    callbackDeleteObject?(id: string): void;
    openModalBind: boolean;
    object: ObjectItem;
    userInfo: UserInfoType;
    parentId?: string;
    viewInDrawer?: boolean;
};

export default function ObjectDetails(props: ObjectDetailsProps) {

    const notify: SnackBarType = useGlobalNotificationContext();
    const setLoader = useGlobalLoaderActionsContext();

    const handleUnlinkObject = async (child_id: string, cardView: boolean) => {
        setLoader(true);
        const _parent_id = !cardView && props.parentId ? props.parentId : props.object.object_id;
        const _child_id = !cardView && props.parentId ? props.object.object_id : child_id;
        try {
            await deleteObjectChildren(_parent_id, _child_id, {origin: OriginType.USER});

            console.log("Object unlinked successfully ", props.object.object_id);

            if (cardView) {
                // await setSubObjectsAfterUpdateChildren(
                //     props.object.children ? props.object.children.filter((child) => child !== child_id) : []
                // );
            } else if (!props.viewInDrawer) {
                props.handleBackButton();
            } else {
                props.callbackDeleteObject && props.callbackDeleteObject(child_id);
            }

            setLoader(false);
        } catch (err: any) {
            notify.errorNotify(err?.message || JSON.stringify(err));
            setLoader(false);
        }
    };

    const handleDeleteObject = async (id: string) => {
        console.log("Delete Button clicked");
        try {
            const res = await deleteObject(id, {origin: OriginType.USER});
            setLoader(false);
            if (res) {
                if (!props.viewInDrawer) props.handleBackButton();
                props.callbackDeleteObject && props.callbackDeleteObject(id);
            }
            console.log("Object deleted successfully ", id);
            // notify.successNotify("Object deleted successfully");
        } catch (err: any) {
            setLoader(false);
            notify.errorNotify(err?.message || JSON.stringify(err));
        }
    };

    const handleDeleteUnlinkObject = useCallback((id: string, action: string, cardView: boolean) => {
        switch (action) {
            case "delete":
                return handleDeleteObject(id);
            case "unlink":
                return handleUnlinkObject(id, cardView);
        }
    }, []);

    useEffect(() => {
        if (!props.object.object_id) {
            return;
        }

        (async() => {
            try {
                await forceSyncObjectFromRemoteDatabaseById(props.object.object_id);
            } catch (e) {}
        })();
    }, [props.object.object_id]);

    return (
        <>
            <ObjectDetailsHeader
                userInfo={props.userInfo}
                notify={notify}
                object={props.object}
                parentId={props.parentId}
                setObject={props.setObject}
                displayModalBind={props.displayModalBind}
                viewInDrawer={props.viewInDrawer}
                handleDeleteObject={handleDeleteUnlinkObject}
            />
            <CssBaseline/>
            <ShowListSubObjects
                handleDeleteObject={handleDeleteUnlinkObject}
                loadMoreObjects={() => {
                }}
                object={props.object}
                notify={notify}
                userInfo={props.userInfo}
                parentId={props.parentId}
                viewInDrawer={props.viewInDrawer}
                setObject={props.setObject}
            />
            <ModalBindWithTree
                show={props.openModalBind}
                handleShowDialog={props.displayModalBind}
                parentObject={props.object}
                userInfo={props.userInfo}
                notify={notify}
            />
        </>
    );
}
