import React, {Fragment, useEffect, useState} from "react";

import {ObjectItem, Permissions} from "../../../Services/ObjectsService/Types";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import {postObjectProperties} from "../../../Services/ObjectsService/CRUD";
import {OriginType} from "../../../Services/ObjectsService/Constants";
import {getClientId} from "../../../Services/UserService";
import {useSubSubmit} from "../../../Hooks/SystemEvent/Toolbar/useToolbar";
import {PageEditor} from "../../PageEditor/PageEditor";
import "../../PageEditor/styles.css";
import {useGlobalNotificationContext} from "../../Notification/NotificationProvider";
import {SnackBarType} from "../../../Services/NotificationService";
import {setSystemState, STATE_TYPE} from "../../../Services/SystemStatesService";
import {UserInfoType} from "../../../Hooks/useUserProfile";

const useStyles = makeStyles(() =>
    createStyles({
        pageEditorBox: {
            height: 'calc(100% - 58px)',
            "@media (min-width: 600px)": {
                height: 'calc(100% - 74px)',
            },
        },
    })
);

type PageEditControllerProps = {
    object: ObjectItem;
    saveObjectByKey(key: string, value: any): void;
    userInfo: UserInfoType;
};


export const PageEditController = (props: PageEditControllerProps) => {

    const {
        object,
        saveObjectByKey,
        userInfo,
    } = props;

    const notify: SnackBarType = useGlobalNotificationContext();

    const classes = useStyles();

    const initNullValueEditorState = `{"root":{"children":[{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}`

    const [editorState, setEditorState] = useState<string>(object?.properties?.page?.value ||initNullValueEditorState);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);

    let oldEditorState = object?.properties?.page?.value || initNullValueEditorState;

    useEffect(() => {
        setEditorState(object?.properties?.page?.value || initNullValueEditorState);
    }, [object?.properties?.page?.value])

    useSubSubmit(async () => {

        if (!hasUnsavedChanges) {
            console.log("No Unsaved Changes");
            return;
        }

        const newValue = editorState;

        oldEditorState = newValue;
        setSystemState(STATE_TYPE.UNSAVED_CHANGES_TO_OBJECT, false);
        setHasUnsavedChanges(false);

        const body = {
            page: {
                value: newValue,
                writable: object?.properties?.page?.writable,
                property_id: object?.properties?.page?.property_id,
                type: object?.properties?.page.type,
                name: object?.properties?.page.name,
                key: object?.properties?.page?.key
            }
        };

        await postObjectProperties(object.object_id, body, {
            origin: OriginType.USER,
            clientId: getClientId(),
        });

        object.properties.page.value = newValue;
        saveObjectByKey("properties", object.properties);

        notify.successNotify(`The document was saved successfully`);
    });

    const handleEditorStateChange = (editorState: string) => {
        const noChanges = editorState === oldEditorState

        if (hasUnsavedChanges === noChanges) {
            setSystemState(STATE_TYPE.UNSAVED_CHANGES_TO_OBJECT, !noChanges);
            setHasUnsavedChanges(!noChanges);
        }

        setEditorState(editorState);
    };

    return (
        <Fragment>
            <div className={classes.pageEditorBox}>
                <PageEditor
                    onEditorStateChange={handleEditorStateChange}
                    initEditorState={editorState}
                    userInfo={userInfo}
                    objectId={object.object_id}
                    objectOwnerId={object.owner_id}
                    objectPermissions={object.permissions}
                />
            </div>
        </Fragment>
    );
};
