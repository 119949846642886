import React, {useCallback, useMemo, useState} from "react";
import {ObjectItem} from "../../../Services/ObjectsService";
import {LatLngTuple} from "leaflet";
import {UserInfoType} from "../../../Hooks/useUserProfile";
import MapImageOverlay from "./MapImageOverlay";
import {PolygonComponent} from "./PolygonComponent";

type MapPolygonImageOverlayProps = {
    bounds: LatLngTuple[];
    object?: ObjectItem;
    hideMarkers?: boolean;
    createMode?: boolean;
    editMode?: boolean;
    selected?: boolean;
    isDraggablePolygon?: boolean;
    userInfo: UserInfoType;
    saveBounds(value: LatLngTuple[]): void;
    changeSelectedPoint?(index: number): void;
    selectObject?(): void;
    addPoint?(e: any, index: number): void;
    dragPolygon?(e: any): void;
    showMarker?: boolean;
    zoomed?: number;
    handleClickOnMarker?(object_id: string): void;
    map?: any;
};


const MapPolygonImageOverlay = (props: MapPolygonImageOverlayProps) => {

    const [imageExists, setImageExists] = useState<boolean>(false)

    const handleImageExists = useCallback((state: boolean) => {
        setImageExists(state)
    }, [])

    const getPixelPoint = useMemo((): LatLngTuple[] => {
        return props.bounds.map((point: LatLngTuple) => [props.map?.latLngToContainerPoint(point).x, props.map?.latLngToContainerPoint(point).y]);
    }, [props.bounds, props.map, props.zoomed]); // [IM] zoomed needed for proper re-calculation of transformed image

    if (!Array.isArray(props.bounds) || props.bounds.length === 0) {
        return null;
    }

    const showPolygon = !(props.object?.object_type === "CameraMapZone" &&
        props.object?.properties["image_polygon"] &&
        props.object?.properties["map_polygon"] && imageExists) || props.editMode || props.selected || props.isDraggablePolygon

    const polygonColor = props.object?.properties["color"]?.value || (props.object?.object_type === "Zone" ? 115 : 230);

    return (
        <React.Fragment>
            {props.object?.properties["image_polygon"] && props.object?.properties["map_polygon"] && (
                <MapImageOverlay
                    pixelPoints={getPixelPoint}
                    bounds={props.bounds}
                    object={props.object}
                    userInfo={props.userInfo}
                    handleImageExists={handleImageExists}
                />
            )}
            {showPolygon && (
                <PolygonComponent
                    bounds={props.bounds}
                    hideMarkers={props.hideMarkers}
                    saveBounds={props.saveBounds}
                    changeSelectedPoint={props.changeSelectedPoint}
                    selectObject={props.selectObject}
                    addPoint={props.addPoint}
                    createMode={props.createMode}
                    dragPolygon={props.dragPolygon}
                    isDraggablePolygon={props.isDraggablePolygon}
                    imageExists={imageExists}
                    color={polygonColor}
                    showMarker={props.showMarker}
                    object={props.object}
                    zoomed={props.zoomed || 2}
                    userInfo={props.userInfo}
                    handleClickOnMarker={props.handleClickOnMarker}
                    selected={props.selected}
                    editMode={props.editMode}
                    map={props.map}
                />
            )}
        </React.Fragment>
    );
};

export default React.memo(MapPolygonImageOverlay)
