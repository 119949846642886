import React, {useCallback, useState} from "react";
import {CardContent, CardHeader, Divider, Icon} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import {makeStyles} from "@material-ui/core/styles";
import {ObjectItem, PropertyItem, PropertyItemValue} from "../../../../Services/ObjectsService";
import Property from "../../Property/Property";
import type {SnackBarType} from "../../../../Services/NotificationService";
import {UserInfoType} from "../../../../Hooks/useUserProfile";
import Chart from "./Chart/Chart";

const useStyles = makeStyles((theme) => ({
    card: {
        minHeight: "425px",
        display: "flex",
        flexDirection: "column",
        margin: "0 auto",
        [theme.breakpoints.up("sm")]: {
            maxWidth: "320px",
        },
    },
    cardContent: {
        height: "280px",
    },
}));

export type PropertyCardProps = {
    property: PropertyItem;
    notify: SnackBarType;
    currentObject: ObjectItem;
    parentId?: string;
    userInfo: UserInfoType;
    viewInDrawer?: boolean;
};

export default function PropertyCard(props: PropertyCardProps) {

    const classes = useStyles();
    const [property, setProperty] = useState<PropertyItem>(props.property);

    const systemOfMeasures = props.userInfo.user?.system_of_measures || "imperial";

    const getIconOnCard = () => {
        return property?.icon || "fas fa-info-circle";
    };

    const getTitleCard = () => {
        return property?.name || property?.type;
    };

    const handleOnChange = useCallback((key: string, value: PropertyItemValue) => {
        setProperty((prevState) => (prevState ? {...prevState, value: value} : prevState))
    }, [setProperty])

    return (
        <>
            <Card className={classes.card}>
                <CardHeader avatar={<Icon fontSize="small" className={getIconOnCard()}/>} title={getTitleCard()}/>
                <CardContent className={classes.cardContent}>
                    <Property
                        property={props.property as PropertyItem}
                        object={props.currentObject}
                        variant={"propertyCard"}
                        userInfo={props.userInfo}
                        parentId={props.parentId}
                        onChange={handleOnChange}
                    />
                    {typeof property.value === "number" && (
                        <>
                            <Divider/>
                            <Chart
                                objectId={props.currentObject.object_id as string}
                                property={property as PropertyItem}
                                system_of_measures={systemOfMeasures}
                            />
                        </>
                    )}
                </CardContent>
            </Card>
        </>
    );
}
