import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Theme } from "@material-ui/core/styles";

type CustomTooltipProps = {
    children?: React.ReactNode;
    tooltip?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
    tooltipWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

        "&:hover [class*=tooltip]": {
            width: "fit-content",
            height: "fit-content",
            top: -60,
            opacity: 1,
            zIndex: 0,
        },
    },
    tooltip: {
        borderRadius: 12,
        position: "absolute",
        background: "white",
        color: "#333",
        paddingBottom: theme.spacing(1.75),
        paddingTop: theme.spacing(1.75),
        paddingLeft: theme.spacing(2.5),
        paddingRight: theme.spacing(2.5),
        lineHeight: 1.4,
        boxShadow: "0 3px 14px rgba(0 0 0 / 40%)",
        opacity: 0,
        top: -45,
        width: 0,
        height: 0,
        zIndex: -1,
        display: "flex",
        fontSize: 12,
        justifyContent: "space-around",
        alignItems: "center",

        "&::before": {
            content: '""',
            width: 10,
            height: 10,
            background: "#fff",
            borderRadius: 3,
            position: "absolute",
            left: "50%",
            marginLeft: theme.spacing(-0.625),//-5px
            bottom: -4,
            transform: "rotate(45deg)",
        }
    }
}));

const CustomTooltip = (props: CustomTooltipProps) => {
    const { children, tooltip } = props;

    const classes = useStyles();

    return (
        <div className={classes.tooltipWrapper} >
            { children }
            { tooltip &&
                <div className={classes.tooltip}>
                    { tooltip }
                </div>
            }
        </div>
    )
}

export default CustomTooltip;
