import React, { useRef, useEffect, useCallback, useMemo } from "react";
import { PropertyHistoryItem } from "Services/ObjectsService/Types";
import { Map, Marker, Popup, TileLayer } from "react-leaflet";
import { LatLngTuple } from "leaflet";
import { useCommonStyles } from "Components/Controls/common/Common";
import { MAP_BOUNDS, MAP_CENTER } from "../../SiteMap/Constants";
import { format } from "date-fns";

type HistoryMapProps = {
    historyData: PropertyHistoryItem[];
    layer?: "satellite" | "map";
}

const HistoryMap = (props: HistoryMapProps): JSX.Element | null => {
    const { historyData, layer = "map" } = props;

    const commonClasses = useCommonStyles();

    const mapRef = useRef<Map>(null);

    const filteredHistoryData: PropertyHistoryItem[] = useMemo(()=>{
        return historyData
            // filter empty data
            .filter((data: PropertyHistoryItem | null) => {
                if (!(data?.value as LatLngTuple)?.length) return null;
                if (!data?.value_epoch) return null;
                return data;
            })
            // filter the same points
            .reduce((acc: PropertyHistoryItem[], next: PropertyHistoryItem) => {
                let isEqual = false;
                const nextPoint = next.value as LatLngTuple;

                for (const item of acc) {
                    const point = item.value as LatLngTuple;
                    if (point[0] === nextPoint[0] && point[1] === nextPoint[1]) {
                        isEqual = true;
                    }
                }

                if (!isEqual) {
                    acc.push(next);
                }

                return acc;
            }, []);
    },[historyData]);

    const getMarkers: () => (JSX.Element | null)[] = useCallback(() => {
        return filteredHistoryData.map((data) => {
            if (!data?.value || !data?.value_epoch) return null;

            const value = data.value as LatLngTuple;

            return (
                <Marker position={value} key={data.value_epoch}>
                    <Popup>{format(new Date(data.value_epoch), "P HH:mm")}</Popup>
                </Marker>
            )
        })
    }, [filteredHistoryData]);

    useEffect(()=>{
        if (!mapRef.current) return;

        let points: LatLngTuple[] = filteredHistoryData
            .map((history: PropertyHistoryItem) => history.value as LatLngTuple)
            .filter((point: LatLngTuple) => point?.length);

        if (points.length > 1) {
            // mapRef.current.leafletElement.fitBounds crashes when bounds length is less than 2 or points have the same LatLng
            mapRef.current.leafletElement.fitBounds(points, {
                paddingTopLeft: [25, 100],
                paddingBottomRight: [25, 25]
            });
        } else if (points.length) {
            mapRef.current.leafletElement.setView(points[0], 12);
        } else {
            mapRef.current.leafletElement.setView(MAP_CENTER, 1);
        }
    },[filteredHistoryData]);

    return (
        <>
            <Map
                center={MAP_CENTER}
                zoom={1}
                scrollWheelZoom={false}
                className={commonClasses.leafletContainer}
                worldCopyJump={true}
                maxBounds={MAP_BOUNDS}
                ref={mapRef}
            >
                {layer === "map" ? (
                    <TileLayer
                        noWrap
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                    />
                ) : (
                    [
                        <TileLayer
                            noWrap
                            url="https://server.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                            attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                        />,
                        <TileLayer
                            noWrap
                            url="https://server.arcgisonline.com/arcgis/rest/services/Reference/World_Boundaries_and_Places/MapServer/tile/{z}/{y}/{x}"
                            attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                        />,
                    ]
                )}
                { getMarkers() }
            </Map>
        </>
    );
}

export default HistoryMap;
