import React from "react";
import {Grid, IconButton} from "@material-ui/core";
import {GridProps} from "@material-ui/core/Grid/Grid";
import {IconButtonProps} from "@material-ui/core/IconButton/IconButton";


type ActionButtonProps = {
    containerProps?: GridProps;
    buttonProps: IconButtonProps;
    icon: React.ReactElement;
};

const ActionButton = (props: ActionButtonProps) => {
    const {containerProps, buttonProps, icon} = props;

    return (
        <Grid item {...containerProps} >
            <IconButton color="inherit" {...buttonProps}>
                {icon}
            </IconButton>
        </Grid>
    );
};

export default ActionButton;