import React, { useEffect, useRef, useState } from "react";
import { FormControl, IconButton, MenuItem, Select, TextField, useMediaQuery, useTheme, Tooltip } from "@material-ui/core";
import { useShareDialogStyles } from "../ShareDialogStyles";
import { AccessLevel } from "../../../../Services/ObjectsService/Constants";
import { accessLevelPriority, isAllowedOperation } from "../../../../Services/Utils";
import { AccessLevelSelectProps } from "../ShareDialogTypes";
import { HelpOutline as HelpOutlineIcon } from "@material-ui/icons";

type AccessLevelOptionType = { value: AccessLevel, name: string, info: string, disabled?: boolean };

const accessLevelOptions: AccessLevelOptionType[] = [
    {value: AccessLevel.READ,       name: "Reader" ,     info: "Just see data"},
    {value: AccessLevel.COMMENT,    name: "Commenter",   info: "Deprecated", disabled: true},
    {value: AccessLevel.CONTROL,    name: "Controller",  info: "Deprecated", disabled: true},
    {value: AccessLevel.CONTRIBUTE, name: "Contributor", info: "Edit hierarchy, but not an exact object"},
    {value: AccessLevel.WRITE,      name: "Writer",      info: "Edit object and hierarchy"},
    {value: AccessLevel.REVOKED,    name: "Revoke",      info: "Revoke access to this object"}
]

const AccessLevelSelect = (props: AccessLevelSelectProps) => {

    const {isOwner, isWriter, hide, userAccessLevel, onChange} = props;

    const classes = useShareDialogStyles();
    const theme = useTheme();
    const downXs = useMediaQuery(theme.breakpoints.down("xs"));

    const [accessLevel, setAccessLevel] = useState(props.accessLevel);
    const [availableAccessLevelOptions, setAvailableAccessLevelOptions] = useState<AccessLevelOptionType[]>(
        accessLevelOptions.filter((level) => {
            return accessLevelPriority(level.value) <= accessLevelPriority(props.accessLevel)
        })
    );

    const userHasAccess = useRef<boolean>(false);

    const onChangeUserAccessLevel = (e: any) => {
        onChange(e.target.value)
    };

    useEffect(() => {
        setAccessLevel(props.accessLevel);
    }, [props.accessLevel]);

    useEffect(() => {
        userHasAccess.current = isAllowedOperation(userAccessLevel, props.accessLevel);
        setAvailableAccessLevelOptions(accessLevelOptions.filter((item) => {
            return isAllowedOperation(userAccessLevel, item.value) || !userHasAccess.current;
        }));
    }, [userAccessLevel]);

    if (hide) {
        return null;
    }

    return (
        <FormControl
            variant="outlined"
            fullWidth={true}
            disabled={!userHasAccess.current}
            className={classes.accessLevelForm}
        >
            {isOwner ? (
                <TextField
                    id="owner"
                    value="Owner"
                    fullWidth={true}
                    variant="outlined"
                    margin={downXs ? "dense" : "none"}
                    disabled={true}
                />
            ) : (
                <Select
                    value={accessLevel}
                    onChange={onChangeUserAccessLevel}
                    margin={downXs ? "dense" : "none"}
                    className={classes.select}
                    disabled={isWriter !== undefined && !isWriter}
                >
                    {availableAccessLevelOptions.map((item) => (
                        <MenuItem key={item.value} value={item.value} disabled={item.disabled} style={{ justifyContent: 'space-between' }}>
                            {item.name + (item.disabled ? " (deprecated)" : "")}
                            {!item.disabled && (
                                <Tooltip title={item.info} placement="left">
                                    <IconButton size={'small'}><HelpOutlineIcon/></IconButton>
                                </Tooltip>
                            )}
                        </MenuItem>
                    ))}
                </Select>
            )}
        </FormControl>
    );
};

export default AccessLevelSelect;
