import {
    BodyObjectItem,
    EncryptionKeyAES,
    FavoritesInfoType,
    ObjectItem,
    OriginPropsType,
    Params,
    Permissions,
    PropertiesType,
    PropertyItem
} from "./Types";
import {isSignedIn} from "../AuthenticationService";
import {
    bindLocalObjectChildren,
    deleteLocalObject,
    getLocalObjectById,
    getLocalObjects,
    postLocalObject,
    postLocalObjectPermissions,
    unBindLocalObjectChildren,
    updateLocalObjectProperties,
} from "../ObjectsLocalService";
import {
    deleteRemoteObject,
    deleteRemoteObjectChildren,
    getRemoteObjectById,
    getRemoteObjectPermissions,
    getRemoteObjectPropertyHistory as getObjectPropertyHistory,
    getRemoteObjects,
    getRemoteKeepAlive,
    postRemoteKeepAlive,
    postRemoteObject,
    postRemoteObjectAccessRequest,
    postRemoteObjectChildren,
    postRemoteObjectNotification,
    postRemoteObjectNotificationState,
    postRemoteObjectPermissions,
    postRemoteObjectPermissionsInvite,
    postRemoteObjectProperties,
    saveRemoteObjectPropertiesToS3,
    getRemoteCachedData
} from "../ObjectsRemoteService";
import {OriginType, RESPONSE_CODE, SubscriptionType, CHAT_NOTIFICATION, PROPERTIES_WITH_NOTIFICATION} from "./Constants";
import * as ObjectsServiceSubscription from "./Subscription";
import {v4} from "uuid";
import {
    addEpochToProperties,
    buildSenderTitle,
    checkIsOnline,
    cleanPermissionObjectFromRootKey,
    copy,
    isObjectPublic,
    mergeObjectChildren,
    mergeObjectNativeIdData,
    trimChatMessage
} from "../Utils";
import {updateCache} from "./ObjectsNativeService/ObjectsIdentificationService/ObjectsIdentificationService";
import {getListAllowedForSharingObjectIds, isAllowedObjectSharing, responseHandlerOLS} from "../ObjectsService";
import {decryptObjectCryptoKeys, getObjectEncryptionKey} from "../CryptoService";
import {
    getPlatform,
    getClientId,
    getDeviceId,
    getUserDeviceToken,
    getUserId,
    postUsers
} from "../UserService";
import {LIST_OF_PROPERTIES_STORED_ON_S3} from "../Constants";
import {APN_NOTIFICATION_TYPE} from "../NotificationService";
import { keepAliveCache } from "../KeepAliveCache";

const keepAliveCacheInstance = new keepAliveCache();

const isAllowedUpdateRemoteDatabase = (object: BodyObjectItem, origin: OriginType) => origin !== OriginType.NATIVE || object.primary_client?.id === getDeviceId() || !object.last_active || !checkIsOnline(object.last_active);

const getObjects = async (params: Params) => {
    try {
        if (isSignedIn() && !params.only_public) {
            return getLocalObjects(params);
        }

        return getRemoteObjects(params);
    } catch (err) {
        console.error(err);
        return Promise.reject(err);
    }
};

const getObjectById = async (object_id: string, params?: Params) => {
    try {
        if (!isSignedIn()) {
            return getRemoteObjectById(object_id, params);
        }

        let _object = await getLocalObjectById(object_id, params);

        if ((!params || !params.skipChildrenTransform) && (!isObjectPublic(_object.permissions) || _object.owner_id === getUserId())) {
            let childrenList = copy(_object.children);
            const listExistingChildren = (await getLocalObjects({
                children: childrenList,
                includeDeleted: true,
                publicRequest: true,
            })) as ObjectItem[];

            const listDeleted: string[] = [];

            for (let children of listExistingChildren) {
                if (children.deleted) {
                    listDeleted.push(children.object_id);
                }
            }

            if (listDeleted.length) {
                childrenList = childrenList.filter((childId: string) => !listDeleted.includes(childId));

                const unbindChildren = async () => {
                    try {
                        const res = await unBindLocalObjectChildren(_object.object_id, listDeleted, new Date().getTime());
                        ObjectsServiceSubscription.pubChildrenChanges(res.object, {origin: OriginType.LOCAL});
                    } catch (e) {
                        console.error(e)
                    }
                };
                unbindChildren();
            }
            _object.children = childrenList;
        }
        return _object;

    } catch (err) {
        console.error(err);
        return Promise.reject(err);
    }
};

const postObjectToRemote = async (object: BodyObjectItem, options: { clientId?: string, parentOwnerId?: string, encryptionKey: string | null, saveKeyRequired: boolean, isPublic?: boolean, isOwner: boolean, permissions: Permissions | null }, originProps: OriginPropsType) => {
    try {
        const localObject = copy({...object, /*children_info: {}*/});
        const localObjectId = localObject.object_id as string;
        delete localObject.encryption_key;

        // let res;
        // try {
            const res = await postRemoteObject(localObject, options);
        // } catch (e) {
        // }

        if (!res || res.response_code === RESPONSE_CODE.SUCCESSFUL) {
            return;
        }

        let keyToRemote: EncryptionKeyAES = options.encryptionKey;
        let saveKeyRequired = options.saveKeyRequired;

        if (res.response_code === RESPONSE_CODE.IDENTIFICATION) {
            // update local object id to remote object id
            const remoteObjectId = res?.object?.object_id;

            if (!remoteObjectId || remoteObjectId === localObjectId || !res?.object) {
                return;
            }
            // Key should not be saved to remote in this case, because we already received  this key from remote [AS]
            saveKeyRequired = false;

            console.log(`Local object ID "${localObjectId}" was changed to "${remoteObjectId}"`);
            localObject.object_id = remoteObjectId;

            mergeObjectChildren(res.object, localObject);
            localObject.native_id = mergeObjectNativeIdData(res?.object?.native_id, localObject.native_id);

            // localObject.encryption_key = await decryptObjectEncryptionKey(res?.object?.encryption_keys);
            localObject.encryption_key = await decryptObjectCryptoKeys(res?.object?.crypto_keys);

            updateCache(localObjectId, remoteObjectId);

            responseHandlerOLS(await postLocalObject(localObject, {includeDeleted: true, saveEncryptionKey: true},  OriginType.REMOTE), {
                ...originProps,
                origin: OriginType.REMOTE
            });
            responseHandlerOLS(await deleteLocalObject(localObjectId, new Date().getTime(), {forceDelete: true}, OriginType.REMOTE), {
                ...originProps,
                origin: OriginType.REMOTE
            });
        }

        if (res.response_code === RESPONSE_CODE.MISSING_CRYPTO_KEY) {
            // Key should be saved to remote in this case, because remote has no encryption key [AS]
            saveKeyRequired = true;

            if (!keyToRemote) {
                try {
                    keyToRemote = await getObjectEncryptionKey(localObjectId);
                } catch (e) {
                    // [IM] Removing object without encryption_key from local database
                    responseHandlerOLS(await deleteLocalObject(localObjectId, new Date().getTime(), {forceDelete: true}, OriginType.REMOTE), {
                        ...originProps,
                        origin: OriginType.REMOTE
                    });

                    throw e;
                }
            }
        }

        if (res.response_code === RESPONSE_CODE.INCORRECT_CRYPTO_KEY) {
            const remoteObjectId = res?.object?.object_id;
            const encryption_key = await decryptObjectCryptoKeys(res?.object?.crypto_keys);

            keyToRemote = encryption_key;

            responseHandlerOLS(await postLocalObject({
                object_id: remoteObjectId,
                encryption_key: encryption_key
            }, {includeDeleted: true, saveEncryptionKey: true},  OriginType.REMOTE), {
                ...originProps,
                origin: OriginType.REMOTE
            });
        }

        postRemoteObject(localObject, {
            encryptionKey: keyToRemote,
            saveKeyRequired: saveKeyRequired,
            clientId: options.clientId,
            isPublic: options.isPublic,
            isOwner: options.isOwner,
            parentOwnerId: options.parentOwnerId,
            permissions: options.permissions
        });
    } catch (e) {
        console.error("Something went wrong to save object to remote: ", e);
    }
};

const postObjectPropertiesToRemote = async (objectId: string, properties: PropertiesType, options: { clientId: string | null, epoch: number, source?: string, encryptionKey: EncryptionKeyAES, isPublic?: boolean, objectName: string }, originProps: OriginPropsType) => {
    try {

        const localProperties = copy(properties);
        let keyToRemote: EncryptionKeyAES = options.encryptionKey;
        const res = await postRemoteObjectProperties(objectId, localProperties, options);

        if (!res || res.response_code === RESPONSE_CODE.SUCCESSFUL) {
            return;
        }

        if (res.response_code === RESPONSE_CODE.INCORRECT_CRYPTO_KEY) {
            const remoteObjectId = res?.object?.object_id;
            const encryption_key = await decryptObjectCryptoKeys(res?.object?.crypto_keys);

            keyToRemote = encryption_key;

            responseHandlerOLS(await postLocalObject({
                object_id: remoteObjectId,
                encryption_key: encryption_key
            }, {includeDeleted: true, saveEncryptionKey: true},  OriginType.REMOTE), {
                ...originProps,
                origin: OriginType.REMOTE
            });
        }

        postRemoteObjectProperties(objectId, localProperties, {
            ...options,
            encryptionKey: keyToRemote
        });

    } catch (e) {
        console.error("Something went wrong to save object properties to remote: ", e);
    }
};

const postObject = async (object: BodyObjectItem, originProps: OriginPropsType & {saveKeyRequired?: boolean}) => {
    if (!object.object_id) {
        object.object_id = v4();
    }

    let events = [];

    // get added object and send notification
    if (object.first_detection && object.observer_id) {
        events.push(SubscriptionType.NOTIFICATION_DETECT_OBJECT)
    }

    const epoch = new Date().getTime();

    Object.assign(object, {updated: epoch});

    if (object.properties) {
        // originProps.event = SubscriptionType.PROPERTIES;
        // ObjectsServiceSubscription.pubPropertiesChanges(object.object_id, object.properties, originProps);
        object.properties = addEpochToProperties(object.properties, epoch);
    }

    const clientId = originProps.clientId || undefined;

    const inputObject = copy(object);

    try {
        let params: Params = {};
        if (originProps.parentOwnerId) {
            params.parentOwnerId = originProps.parentOwnerId;
        }

        const response = await postLocalObject(object, params, originProps.origin);

        if (isAllowedUpdateRemoteDatabase(response.object, originProps.origin)) {
            postObjectToRemote({
                ...inputObject,
                source: response.object.source,
                object_type: response.object.object_type,
                native_identification_data: response.object?.native_identification_data
            }, {
                clientId: clientId,
                encryptionKey: response.encryption?.encryptionKey || null,
                saveKeyRequired: !!response.encryption?.saveKeyRequired || !!originProps.saveKeyRequired,
                isPublic: response.encryption?.isPublic,
                isOwner: response.object.owner_id === getUserId(),
                parentOwnerId: originProps.parentOwnerId,
                permissions: response.object?.permissions || null
            }, originProps);

            // get added object and send notification
            if (inputObject.first_detection && inputObject.observer_id && inputObject.observer_name) {
                const notification = {
                    alert: `A ${(inputObject as ObjectItem)?.object_name} has been detected by ${inputObject.observer_name}`,
                    payload: {
                        "type": APN_NOTIFICATION_TYPE.OBJECT,
                        "parent_id": inputObject.observer_id,
                        "child_id": inputObject.object_id
                    }
                };
                postRemoteObjectNotification(
                    inputObject.observer_id,
                    notification,
                    getUserDeviceToken() ?? null,
                    getPlatform() ?? null
                ).then(() => {}).catch(() => {});
            }
        }

        return responseHandlerOLS({...response, events: [...response.events, ...events]}, originProps);
    } catch (err) {
        return Promise.reject(err);
    }
};

const getKeepAliveCache = async () => {
    try {
        return keepAliveCacheInstance.pop();
    } catch (err) {
        return Promise.reject(err);
    }
};

const postObjectKeepAlive = async (object: BodyObjectItem, originProps: OriginPropsType) => {
    try {
        const response = await postLocalObject(object, {}, originProps.origin);

        if (isAllowedUpdateRemoteDatabase(response.object, originProps.origin)) {
            keepAliveCacheInstance.push({
                object_id: object.object_id,
                last_active: object.last_active
            });
        }

        return responseHandlerOLS({...response, events: response.events}, originProps);
    } catch (err) {
        return Promise.reject(err);
    }
};

const processKeepAlive = async () => {
    try {
        const _objects_to_remote: BodyObjectItem[] = await getKeepAliveCache();
        const _objects_from_local: ObjectItem[] = await getLocalObjects({object_type: ["IPCamera", "BackCamera", "FrontCamera", "Machine", "Service"]});

        let _objects_from_remote: BodyObjectItem[] = [];
        let _objects_to_local: BodyObjectItem[] = [];

        if (_objects_from_local.length) {
            for (let object of _objects_from_local) {
                if (object.object_id && (!object.primary_client || object.primary_client.id !== getDeviceId() || !checkIsOnline(object.last_active))) {
                    _objects_from_remote.push({object_id: object.object_id});
                }
            }
        }

        if (_objects_to_remote.length) {
            const _polling_needed: BodyObjectItem[] = await postRemoteKeepAlive(_objects_to_remote);

            if (_polling_needed.length) {
                for (let object of _polling_needed) {
                    if (object.object_id) {
                        _objects_from_remote.push({object_id: object.object_id});
                    }
                }
            }
        }

        if (_objects_from_remote.length) {
            _objects_to_local = await getRemoteKeepAlive(_objects_from_remote.map((item: BodyObjectItem) => { return item.object_id as string; }));

            const _originProps = {
                origin: OriginType.REMOTE
            };

            for (let object of _objects_to_local) {
                const response = await postLocalObject(object, {}, _originProps.origin);
                responseHandlerOLS({...response, events: response.events}, _originProps);
            }
        }

        return null;
    } catch (err) {
        return Promise.reject(err);
    }
};

const deleteObject = async (objectId: string, originProps: OriginPropsType) => {
    let epoch = originProps.epoch || new Date().getTime();

    deleteRemoteObject(objectId, epoch);
    try {
        const response = await deleteLocalObject(objectId, epoch, {}, originProps.origin);
        return responseHandlerOLS(response, originProps);
    } catch (err) {
        return Promise.reject(err);
    }
};

const deleteObjects = async (objectIds: string[], originProps: OriginPropsType) => {
    let epoch = originProps.epoch || new Date().getTime();

    for (const objectId of objectIds) {//[AM] Temporarily. batch delete functionality is required
        deleteRemoteObject(objectId, epoch);
    }

    for (const objectId of objectIds) {
        try {
            const response = await deleteLocalObject(objectId, epoch, {}, originProps.origin);
            if (response.object.observer_id && response.object?.observer_name) {
                const notification = {
                    alert: `A ${response.object?.object_name} has been lost by ${response.object?.observer_name}`,
                    payload: {
                        "type": APN_NOTIFICATION_TYPE.OBJECT,
                        "parent_id": response.object.observer_id,
                        "child_id": response.object.object_id
                    }
                };
                postRemoteObjectNotification(
                    response.object.observer_id,
                    notification,
                    getUserDeviceToken() ?? null,
                    getPlatform() ?? null
                ).then(() => {}).catch(() => {});
            }
            responseHandlerOLS(response, originProps);
        } catch (e) {
            console.error(e);
        }
    }
    return
};

const postObjectPermissions = async (objectId: string, permissions: Permissions, list_object_id: string[] | null, originProps: OriginPropsType) => {
    try {
        const allowedObjectIds = await getListAllowedForSharingObjectIds(list_object_id || []);

        const clientId = originProps.clientId || null;

        await postRemoteObjectPermissions(objectId, permissions, allowedObjectIds, { clientId: clientId });

        const cleanedPermissions: any = cleanPermissionObjectFromRootKey(permissions);

        for (const id of allowedObjectIds || []) {
            try {
                const localResponse = await postLocalObjectPermissions(id, cleanedPermissions);
                responseHandlerOLS(localResponse, originProps);
            } catch (e) {
                console.error(e);
            }
        }
        return;
    } catch (err) {
        return Promise.reject(err);
    }
};

const postObjectPermissionsInvite = async (objectId: string, accessLevel: string, emails: string[], comment: string | undefined, list_object_id: string[] | null, originProps: OriginPropsType) => {
    try {
        const allowedObjectIds = await getListAllowedForSharingObjectIds(list_object_id || []);

        const clientId = originProps.clientId || null;

        const response = await postRemoteObjectPermissionsInvite(objectId, accessLevel, emails, comment, allowedObjectIds, { clientId: clientId });

        await postUsers(response.users);

        const cleanedPermissions: any = cleanPermissionObjectFromRootKey(response.permissions);

        for (const id of allowedObjectIds || []) {
            try {
                if (id !== objectId) {
                    const localResponse = await postLocalObjectPermissions(id, cleanedPermissions);
                    responseHandlerOLS(localResponse, originProps);
                    continue;
                }

                const rootPermissions = copy(cleanedPermissions || {});
                for (const _user of response.users || []) {
                    if (!rootPermissions.private_acl.hasOwnProperty(_user.id)) {
                        continue;
                    }

                    rootPermissions.private_acl[_user.id] = {...rootPermissions.private_acl[_user.id], root: true};
                }

                const localResponse = await postLocalObjectPermissions(id, rootPermissions);
                responseHandlerOLS(localResponse, originProps);
            } catch (e) {
                console.error(e);
            }
        }

        return;
    } catch (err) {
        return Promise.reject(err);
    }
};

const saveObjectEncryptKeyForExistingUser = async (objectId: string, permissions: Permissions, originProps: OriginPropsType) => {
    try {
        const localObject = await getLocalObjectById(objectId, {skipChildrenTransform : true});

        if (!localObject.encryption_key) {
            throw new Error(`Object with ID: ${objectId} has no encryption key`);
        }

        if (!isAllowedObjectSharing(localObject)) {
            throw new Error(`Object with ID: ${objectId} can not be shared`)
        }

        return await postObjectToRemote({
            object_id: objectId,
            source: localObject.source,
            object_type: localObject.object_type,
            native_identification_data: localObject.native_identification_data
        }, {
            clientId: getClientId(),
            encryptionKey: localObject.encryption_key || null,
            saveKeyRequired: true,
            isPublic: false,
            isOwner: localObject.owner_id === getUserId(),
            permissions: permissions || null
        }, originProps);
    } catch (e) {
        return Promise.reject(e);
    }
};

const getObjectPermissions = async (objectId: string) => {
    try {
        const remoteResult = await getRemoteObjectPermissions(objectId);
        await postUsers(remoteResult?.user_data);
        await postLocalObjectPermissions(objectId, remoteResult?.permissions);

        return remoteResult;
    } catch (e) {
        return Promise.reject(e);
    }
};

const postObjectProperties = async (objectId: string, properties: { [string: string]: any }, originProps: OriginPropsType) => {
    const epoch = originProps.epoch || new Date().getTime();

    properties = addEpochToProperties(properties, epoch);

    const source = originProps?.source ? originProps.source as string : undefined;
    const clientId = originProps.clientId || null;

    // originProps.event = SubscriptionType.PROPERTIES;
    // ObjectsServiceSubscription.pubPropertiesChanges(objectId, properties, originProps);

    const inputProperties = copy(properties);

    try {
        const response = await updateLocalObjectProperties(objectId, properties, epoch, originProps.origin);

        if (isAllowedUpdateRemoteDatabase(response.object, originProps.origin)) {
            // post to Remote DB only updated properties on Local DB
            for (const propertyKey in inputProperties) {
                if (!inputProperties.hasOwnProperty(propertyKey)) {
                    continue;
                }

                if (!inputProperties[propertyKey].removed && !response.object.properties.hasOwnProperty(propertyKey)) {
                    delete inputProperties[propertyKey];
                }
            }

            postObjectPropertiesToRemote(objectId, inputProperties, {
                clientId: clientId,
                epoch: epoch,
                source: source || response.object.source,
                encryptionKey: response.encryption?.encryptionKey || null,
                isPublic: response.encryption?.isPublic,
                objectName: response.object.object_name
            }, originProps);

            // send notifications
            if (response.object.notifications?.state !== false) {
                for (const propertyKey in inputProperties) {
                    if (!inputProperties.hasOwnProperty(propertyKey)) {
                        continue;
                    }

                    if (!inputProperties[propertyKey].removed) {
                        const property = response?.object?.properties[propertyKey];
                        const property_type = inputProperties[propertyKey].type || response.object.properties[propertyKey]?.type;
                        let notification = {
                            alert: '',
                            payload: {}
                        };

                        if (PROPERTIES_WITH_NOTIFICATION.includes(property_type)) {
                            notification = {
                                alert: `Property ${property.name} for ${response.object.object_name} changed value`,
                                payload: {
                                    "type": APN_NOTIFICATION_TYPE.PROPERTY,
                                    "object_id": response.object.object_id
                                }
                            };
                        } else if (CHAT_NOTIFICATION.includes(property_type)) {
                            const senderTitle = await buildSenderTitle(property.value || {});
                            const trimmedMessage = trimChatMessage(property.value || {});

                            notification = {
                                alert: `${response.object.object_name}. ${senderTitle}: ${trimmedMessage}`,
                                payload: {
                                    "type": APN_NOTIFICATION_TYPE.CHAT_MESSAGE,
                                    "object_id": response.object.object_id
                                }
                            };
                        }

                        if (notification.alert) {
                            postRemoteObjectNotification(
                                objectId,
                                notification,
                                getUserDeviceToken() ?? null,
                                getPlatform() ?? null
                            ).then(() => {}).catch(() => {});
                        }
                    }
                }
            }
        }
        return responseHandlerOLS(response, originProps);
    } catch (err) {
        return Promise.reject(err);
    }
};

const postObjectNotificationState = async (client_id: string | null, object_id: string, notification: { [state: string]: boolean }) => {
    try {
        await postLocalObject({object_id, notifications: notification});

        postRemoteObjectNotificationState(client_id, object_id, notification);
    } catch (err) {
        return Promise.reject(err);
    }
};

const postObjectFavoriteState = async (object_id: any, state: boolean, user_id: string, originProps: OriginPropsType) => {
    try {
        const favorites: FavoritesInfoType = {
            [user_id]: {
                state: state,
                updated: Date.now()
            }
        };

        const response = await postLocalObject({object_id, favorites});

        const object = {
            object_id: response.object.object_id,
            favorites: response.object.favorites,
            source: response.object.source,
            object_type: response.object.object_type,
            native_identification_data: response.object?.native_identification_data
        };

        const options = {
            clientId: getClientId(),
            encryptionKey: response.encryption?.encryptionKey || null,
            saveKeyRequired: !!response.encryption?.saveKeyRequired,
            isOwner: response.object.owner_id === getUserId(),
            permissions: response.object?.permissions || null
        };

        postObjectToRemote(object, options, originProps);
    } catch (err) {
        return Promise.reject(err);
    }
};

const postObjectAccessRequest = async (objectId: string) => {
    try {
        return await postRemoteObjectAccessRequest(objectId);
    } catch (e) {
        return Promise.reject(e);
    }
};

const updateObjectProperties = async (objectId: string, properties: { [string: string]: any }, epoch: number, originProps: OriginPropsType) => {
    epoch = epoch || Date.now();
    // originProps.event = SubscriptionType.PROPERTIES;

    properties = addEpochToProperties(properties, epoch);

    // ObjectsServiceSubscription.pubPropertiesChanges(objectId, properties, originProps);

    try {
        const response = await updateLocalObjectProperties(objectId, properties, epoch, originProps.origin);
        return responseHandlerOLS(response, originProps);
    } catch (e) {
        return Promise.reject(e);
    }
};

const saveObjectPropertiesToS3 = async (objectId: string, propertyKey: string, properties: PropertiesType, epoch: number, originProps: OriginPropsType) => {
    if (!properties || !LIST_OF_PROPERTIES_STORED_ON_S3.includes(propertyKey)) {
        return;
    }

    epoch = epoch || Date.now();

    properties = addEpochToProperties(properties, epoch);

    try {
        const response = await updateLocalObjectProperties(objectId, properties, epoch, originProps.origin);
        if (isAllowedUpdateRemoteDatabase(response.object, originProps?.origin as OriginType)) {
            await saveRemoteObjectPropertiesToS3(objectId, propertyKey, properties, {
                epoch: epoch,
                encryptionKey: response.encryption?.encryptionKey || null,
                isPublic: response.encryption?.isPublic
            });
        }
    } catch (e) {
        return Promise.reject(e);
    }
};

const getCachedData = async (objectId: string, propertyImage: PropertyItem) => {
    try {
        return getRemoteCachedData(objectId, propertyImage);
    } catch (e) {
        console.error(e);
    }
};

const postObjectChildren = async (parent_id: string, child_id: string, originProps: OriginPropsType) => {
    let epoch = new Date().getTime();
    const source = originProps?.source ? originProps.source as string : undefined;
    try {
        const response = await bindLocalObjectChildren(parent_id, [child_id], epoch);
        if (response.hasOwnProperty("isChildrenUpdated") && response.isChildrenUpdated === false) {
            return response.object;
        }
        if (isAllowedUpdateRemoteDatabase(response.object, originProps?.origin as OriginType)) {
            void postRemoteObjectChildren(parent_id, child_id, { epoch: epoch, source: source || response.object.source });
        }
        return responseHandlerOLS(response, originProps);
    } catch (err) {
        return Promise.reject(err);
    }
};

const postSeveralObjectChildren = async (parent_id: string, child_ids: string[], originProps: OriginPropsType) => {
    let epoch = new Date().getTime();
    try {
        const response = await bindLocalObjectChildren(parent_id, child_ids, epoch);
        if (response.hasOwnProperty("isChildrenUpdated") && response.isChildrenUpdated === false) {
            return response.object;
        }
        if (isAllowedUpdateRemoteDatabase(response.object, originProps?.origin as OriginType)) {
            postRemoteObject({
                object_id: response.object.object_id,
                native_identification_data: response.object.native_identification_data,
                source: response.object.source,
                children: response.object.children,
                object_type: response.object.object_type,
                object_name: response.object.object_name
            }, {
                encryptionKey: response.encryption?.encryptionKey || null,
                saveKeyRequired: !!response.encryption?.saveKeyRequired,
                isPublic: response.encryption?.isPublic,
                isOwner: response.object.owner_id === getUserId(),
                permissions: response.object?.permissions || null
            });
        }
        return responseHandlerOLS(response, originProps);
    } catch (err) {
        return Promise.reject(err);
    }
};

const deleteObjectChildren = async (parent_id: string, child_id: string, originProps: OriginPropsType) => {
    let epoch = new Date().getTime();
    try {
        const response = await unBindLocalObjectChildren(parent_id, [child_id], epoch);
        if (isAllowedUpdateRemoteDatabase(response.object, originProps?.origin as OriginType)) {
            deleteRemoteObjectChildren(parent_id, child_id, epoch);
        }
        return responseHandlerOLS(response, originProps);
    } catch (err) {
        return Promise.reject(err);
    }
};

const deleteChildObjects = async (parent_id: string, childIds: string[], originProps: OriginPropsType) => {
    let epoch = new Date().getTime();
    try {
        const response = await unBindLocalObjectChildren(parent_id, childIds, epoch);
        if (isAllowedUpdateRemoteDatabase(response.object, originProps?.origin as OriginType)) {
            postRemoteObject({
                object_id: response.object.object_id,
                native_identification_data: response.object.native_identification_data,
                source: response.object.source,
                children: response.object.children,
                object_type: response.object.object_type,
                object_name: response.object.object_name
            }, {
                encryptionKey: response.encryption?.encryptionKey || null,
                saveKeyRequired: !!response.encryption?.saveKeyRequired,
                isPublic: response.encryption?.isPublic,
                isOwner: response.object.owner_id === getUserId(),
                permissions: response.object?.permissions || null
            });
        }
        return responseHandlerOLS(response, originProps);
    } catch (err) {
        return Promise.reject(err);
    }
};

export {
    getObjects,
    getObjectById,
    postObject,
    postObjectKeepAlive,
    processKeepAlive,
    deleteObject,
    deleteObjects,
    postObjectPermissions,
    postObjectPermissionsInvite,
    saveObjectEncryptKeyForExistingUser,
    getObjectPermissions,
    postObjectProperties,
    postObjectToRemote,
    postObjectAccessRequest,
    updateObjectProperties,
    saveObjectPropertiesToS3,
    getCachedData,
    postObjectNotificationState,
    postObjectFavoriteState,
    postObjectChildren,
    postSeveralObjectChildren,
    deleteObjectChildren,
    deleteChildObjects,
    getObjectPropertyHistory,
};
