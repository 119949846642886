import { checkIsOnline, copy } from "./Utils";
import { BodyObjectItem } from "./ObjectsService/Types";

class keepAliveCache {
    private cache: { [key: string]: number } = {};

    push(object: BodyObjectItem): void {
        if (object.object_id && object.last_active) {
            this.cache[object.object_id] = object.last_active;
        }
    }

    pop(): BodyObjectItem[] {
        const _cache = copy(this.cache);

        this.clear();

        let _result: BodyObjectItem[] = [];

        for (let key in _cache) {
            if (_cache.hasOwnProperty(key)) {
                const _last_active = _cache[key];

                if (_last_active && checkIsOnline(_last_active)) {
                    _result.push({
                        object_id: key,
                        last_active: _last_active
                    });
                }
            }
        }

        return _result;
    }

    clear(): void {
        this.cache = {};
    }
}

export {
    keepAliveCache
}
