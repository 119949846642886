import { getVersion } from "./Utils";
import { isDevice } from "./DeviceService";
import { getClientId, getDeviceHostType, getDeviceId, getUserId } from "./UserService";
import { authRequest, REQUEST_TYPE, METHOD } from "./RequestService";

// [IM] Replacing type from react
type ErrorInfo = { componentStack: string; };

export type SystemErrorType = {
    error: null | Error;
    errorInfo: null | ErrorInfo;
    errorDate: null | number;
};

const postRemoteReportToSupport = async (data: SystemErrorType) => {
    const { errorInfo, error, errorDate } = data;

    const reportDate = Date.now();

    const body = {
        error:                 error ? error.toString() : "",
        error_info:            errorInfo?.componentStack || "",
        version:               getVersion() || "",
        is_device:             isDevice(),
        host:                  getDeviceHostType() || "",
        error_date_timestamp:  errorDate || "",
        error_date:            errorDate ? new Date(errorDate).toISOString() : "",
        report_date:           new Date(reportDate).toISOString(),
        report_date_timestamp: reportDate,
        time_zone_offset:      new Date().getTimezoneOffset(),
        user_id:               getUserId() || "",
        client_id:             getClientId() || "",
        device_id:             getDeviceId() || "",
        path:                  window.location.href.toString() || "",
    };

    return authRequest("support/report", {
        method: METHOD.POST,
        body: JSON.stringify(body),
        requestType: REQUEST_TYPE.POST_REPORT_TO_SUPPORT,
    });
};

const postReportToSupport = async (data: SystemErrorType) => {
    try {
        return await postRemoteReportToSupport(data);
    } catch (e) {
        console.error(e);
        return Promise.reject(e);
    }
};

export {
    postReportToSupport
}
