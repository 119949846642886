import React, {useCallback, useEffect, useMemo, useState} from "react";
import {MarkerIconProps} from "../MarkerTypes";
// import {makeStyles} from "@material-ui/core/styles";
import {PropertyItem, PropertyItemValue} from "../../../Services/ObjectsService";
import {getPrimeProperty} from "../../Common/PropertiesPriority";
// import {Box, Grid} from "@material-ui/core";
import Property from "../../Objects/Property/Property";
import MarkerIconLight from "./MarkerIconLight/MarkerIconLight";

const MarkerIcon = (props: MarkerIconProps) => {

    const {
        object,
        // object_id,
        // object_icon,
        // object_type,
        iconSize,
        // selected,
        userInfo,
        visibilityType,
        // handleClickOnMarker,
    } = props

    // const useStyles = makeStyles({
    //     // block: {
    //     //     marginBottom: 4
    //     // },
    //     // icon: {
    //     //     width: "fit-content",
    //     //     filter: "drop-shadow(1px 0px 1px #000) drop-shadow(-1px 0px 1px #000) drop-shadow(0px -1px 1px #000) drop-shadow(0px 1px 1px #000)",
    //     //     fontSize: iconSize,
    //     // },
    //     // iconElement: {
    //     //     cursor: "pointer",
    //     // },
    //     // iconSelected: {
    //     //     padding: '2px',
    //     //     border: "1px #fff solid",
    //     //     background: "rgba(0, 0, 0, .1)",
    //     // },
    // })

    // const classes = useStyles()

    // const iconStyle = selected
    //     ? [classes.icon, classes.iconSelected].join(' ')
    //     : classes.icon

    // const iconAwesomeClass = object_icon || (object_type === "Zone"
    //     ? "far fa-object-group"
    //     : "fas fa-info-circle")

    const [primeProperty, setPrimeProperty] = useState<PropertyItem | undefined>();

    useEffect(() => {
        if (object) {
            const property = getPrimeProperty(Object.values(object.properties), visibilityType)
            setPrimeProperty(
                property ? Object.values(object.properties).find(
                    (el: PropertyItem) => el.property_id === property.property_id) : undefined
            )
        }
    }, [object, visibilityType])

    const onChangeHandler = useCallback((key: string, value: PropertyItemValue) => {
        setPrimeProperty((prev) => {
            if (!prev) {
                return prev;
            }

            return {
                ...prev,
                value: value
            }
        })
    }, [setPrimeProperty])

    const iconLabel = useMemo(() => {
        if (primeProperty) {
            return <Property
                property={primeProperty as PropertyItem}
                object={object}
                variant={"marker"}
                fontSize={iconSize}
                userInfo={userInfo}
                onChange={onChangeHandler}
            />
        } else {
            return null
        }
    }, [primeProperty, iconSize, object, onChangeHandler, userInfo]);

    // const iconEl = <Box
    //     className={classes.iconElement}
    //     component="span"
    //     onClick={() => handleClickOnMarker ? handleClickOnMarker(object_id) : null}>
    //     <i className={iconAwesomeClass}/>
    // </Box>


    return (
        <MarkerIconLight {...props}>
            {iconLabel}
        </MarkerIconLight>
    )

    // return (
    //     <Box
    //         className={classes.block}>
    //         <Grid
    //             container
    //             direction="row"
    //             justifyContent="center"
    //             alignItems="center"
    //             spacing={1}
    //             className={iconStyle}
    //         >
    //             <Grid item>
    //                 {iconEl}
    //             </Grid>
    //             {iconLabel && <Grid item>
    //                 {iconLabel}
    //             </Grid>}
    //         </Grid>
    //     </Box>
    // )
}

export default MarkerIcon