import { PopoverOrigin } from "@material-ui/core/Popover";
import { makeStyles } from "@material-ui/core/styles";

const useCommonStyles = makeStyles((theme) => ({
    cursorPointer: {
        cursor: "pointer",
    },
    insideWrapper: {
        position: "relative",
        // height: "inherit",
        height: "inherit",
        width: "inherit",
        maxHeight: "fit-content",
        maxWidth: "fit-content",
        margin: "0 auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    coveredImage: {
        width: "auto",
        maxHeight: "100%",
        maxWidth: "100%",
        margin: "0 auto",
        position: "relative",
    },
    coveringCanvas: {
        position: "absolute",
        top: 0,
        left: 0,
        // backgroundColor: 'rgba(255,255,255,.1)',
        border: 0,
        padding: 0,
    },
    leafletContainer: {
        width: "100%",
        height: "100%",
    },
    border: {
        borderRadius: 7,
        borderColor: theme.palette.divider,
        padding: "20px",
    },
    boxBottomSpacing: {
        paddingBottom: "8px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    popoverContent: {
        minWidth: "175px"
    },
    fullWidth: {
        width: "100%"
    }
}));

const popoverPosition: { anchorOrigin: PopoverOrigin; transformOrigin: PopoverOrigin } = {
    anchorOrigin: {
        vertical: "center",
        horizontal: "center",
    },
    transformOrigin: {
        vertical: "center",
        horizontal: "center",
    },
};

export { useCommonStyles, popoverPosition };
