import React, {useRef, useState} from "react";
import { Map, TileLayer } from "react-leaflet";
import { LatLngTuple } from "leaflet";
import AddIcon from "@material-ui/icons/Add";
import { Box, Button, Grid, Fab } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { PointFields } from "../common/PointFields";
import { useCommonStyles } from "../common/Common";
import MapPolygonImageOverlay from "../common/MapPolygonImageOverlay";
import { MapPolygonComponentProps } from "./MapPolygonTypes";
import { MAP_BOUNDS, MAP_CENTER } from "Components/Objects/SiteMap/Constants";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        switch_layers: {
            display: 'none',
            margin: 5,
            position: "absolute",
            bottom: 5,
            left: 5,
            zIndex: theme.zIndex.appBar - 1,
            borderRadius: "4px",
            backgroundColor: "#fff",
            width: "26px",
            height: "26px",
        },
        box_relative: {
            position: "relative",
            height: "inherit",
        },
        box_property_card: {
            width: "100%",
            overflow: "hidden",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
        }
    })
);

const MapPolygonComponent = (props: MapPolygonComponentProps) => {
    const { mapWidth, object, saveValue, isDisabled, isPropertyCard } = props;

    const bounds = Array.isArray(props.value) ? props.value : [];

    const commonClasses = useCommonStyles();
    const classes = useStyles();

    const mapRef = useRef<any>(null);
    const startRef = useRef<boolean>(false);
    const [selectedPosition, setSelectedPosition] = useState<number>(0);
    const [center, setCenter] = useState<LatLngTuple>(bounds[selectedPosition] || MAP_CENTER);
    const [zoom, setZoom] = useState<number>(bounds[selectedPosition] ? 14 : 1);
    const [layer, setLayer] = React.useState<string>("map");

    // [[51.505, -0.09], [51.4958128370432, -0.10728836059570314], [51.49602657961649, -0.09956359863281251]]

    // const handleClickMap = (e: LeafletMouseEvent) => {
    //     setZoom(e.target._zoom);
    //     // setSelectedPositionValue([e.latlng.lat, e.latlng.lng]);
    //     // updateSelectedPosition(selectedPosition, [e.latlng.lat, e.latlng.lng]);
    // };

    const removePositionPoint = (index: number) => {
        if (isDisabled) return;
        const filtered = bounds.filter((item, i) => i !== index);
        if (filtered.length >= selectedPosition || selectedPosition === index) {
            setSelectedPosition(0);
            if (filtered[0]) {
                // setCenter(filtered[0]);
            }
        }
        saveValue(filtered);
    };

    const addNewPoint = () => {
        if (isDisabled) return;
        const _center = mapRef.current.leafletElement.getCenter();
        if (bounds.length === 0) {
            saveValue([[_center.lat, _center.lng]]);
            return;
        }
        const newBounds = JSON.parse(JSON.stringify(bounds));
        newBounds.push([_center.lat, _center.lng]);
        setSelectedPosition(newBounds.length - 1);
        saveValue(newBounds);
    };

    const changeSelectedPosition = (index: number) => {
        if (isDisabled) return;
        setSelectedPosition(index);
    };

    // const onMoveMapOrZoomChange = (e: LeafletEvent) => {
    //     setZoom(e.target.getZoom());
    //     const _center = e.target.getCenter();
    //     setCenter([_center.lat, _center.lng]);
    // };

    // const saveSelectedValue = () => {
    //     // saveValue(getListPosition());
    // };

    const handleFieldsChange = (index: number, value: LatLngTuple) => {
        if (isDisabled) return;
        const newBounds = JSON.parse(JSON.stringify(bounds));
        newBounds[index] = value;
        saveValue(newBounds);
    };

    const dragPolygon = (e: any) => {
        let points = e.target.getLatLngs();
        saveValue(points[0].map((point: L.LatLng) => [point.lat, point.lng]))
    };

    const switchLayers = () => {
        setLayer((prev) => (prev === "map" ? "satellite" : "map"));
    };

    React.useEffect(()=>{
        if (props.value?.length && !startRef.current) {
            mapRef.current.leafletElement.fitBounds(bounds, {paddingTopLeft: [5, 5], paddingBottomRight: [5, 5]});
            // startRef.current = true;
            // const _center = mapRef.current.leafletElement.getCenter()
            // setCenter([_center.lat, _center.lng]);
        }
        startRef.current = true;
    },[props.value]);

    const displayMap = () => {
        return (
            <>
                <Map
                    center={center}
                    zoom={zoom}
                    // onClick={isDisabled ? null : handleClickMap}
                    // onZoomEnd={onMoveMapOrZoomChange}
                    // onBlur={saveSelectedValue}
                    // onMove={onMoveMapOrZoomChange}
                    scrollWheelZoom={false}
                    className={commonClasses.leafletContainer}
                    ref={mapRef}
                    maxBounds={MAP_BOUNDS}
                >
                    <MapPolygonImageOverlay
                        bounds={bounds}
                        saveBounds={saveValue}
                        object={object}
                        changeSelectedPoint={setSelectedPosition}
                        isDraggablePolygon={!isDisabled}
                        createMode={!!isDisabled && !isPropertyCard}
                        userInfo={props.userInfo}
                        dragPolygon={dragPolygon}
                        selected={isPropertyCard}
                    />
                    {layer === "map" ? (
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        />
                    ) : (
                        [
                            <TileLayer
                                url="https://server.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            />,
                            <TileLayer
                                url="https://server.arcgisonline.com/arcgis/rest/services/Reference/World_Boundaries_and_Places/MapServer/tile/{z}/{y}/{x}"
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            />,
                        ]
                    )}
                </Map>
                {!isPropertyCard && (
                    <Fab
                        aria-label="switch layers"
                        key={layer}
                        component="div"
                        className={classes.switch_layers}
                        onClick={switchLayers}
                        children={<a className={`fas fa-${layer === "map" ? "satellite" : "map"}`} />}
                    />
                )}
            </>
        );
    };

    return (
        <Box width={mapWidth} className={`${classes.box_relative} ${isPropertyCard ? classes.box_property_card : ""}`}>
            <Grid container spacing={1} justifyContent="center" className={classes.box_relative}>
                <Grid item xs={12} className={classes.box_relative}>
                    {!isPropertyCard ? (
                        <Box height={300}>{displayMap()}</Box>
                    ) : (
                        <Box className={`${classes.box_relative} ${classes.box_property_card}`}>{displayMap()}</Box>
                    )}
                </Grid>
                <Grid item xs={12}>
                    {!isPropertyCard &&
                        bounds.map((point: LatLngTuple, index: number) => (
                            <PointFields
                                key={`latlngfields_${index}`}
                                index={index}
                                point={point}
                                isSelected={selectedPosition === index}
                                label={`p${index}`}
                                labelX={"Latitude"}
                                labelY={"Longitude"}
                                enableDelete={!isDisabled}
                                enableSelect={!isDisabled}
                                controlInputProps={{ inputProps: { step: "0.0001" } }}
                                handleRemove={removePositionPoint.bind(null, index)}
                                handleSelect={changeSelectedPosition.bind(null, index)}
                                handleUpdate={handleFieldsChange.bind(null, index)}
                                isDisabled={isDisabled}
                            />
                        ))}
                </Grid>
                {!isDisabled && !isPropertyCard ? (
                    <Grid container spacing={1}>
                        <Grid item container justifyContent="center" xs={12}>
                            <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={addNewPoint}>
                                Add
                            </Button>
                        </Grid>
                    </Grid>
                ) : null}
            </Grid>
        </Box>
    );
};

export { MapPolygonComponent };
