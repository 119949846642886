import useSystemEvent from "../useSystemEvent";
import {EVENT_TYPE} from "../../../Services/SystemEventsService";
import type {SystemEventCallbackType} from "../../../Services/SystemEventsService";

export const useSubRedo =    (callback: SystemEventCallbackType) => { useSystemEvent(EVENT_TYPE.TOOLBAR_REDO,     callback); };
export const useSubUndo =    (callback: SystemEventCallbackType) => { useSystemEvent(EVENT_TYPE.TOOLBAR_UNDO,     callback); };
export const useSubRemove =  (callback: SystemEventCallbackType) => { useSystemEvent(EVENT_TYPE.TOOLBAR_REMOVE,   callback); };
export const useSubSubmit =  (callback: SystemEventCallbackType) => { useSystemEvent(EVENT_TYPE.TOOLBAR_SUBMIT,   callback); };
export const useSubZoomIn =  (callback: SystemEventCallbackType) => { useSystemEvent(EVENT_TYPE.TOOLBAR_ZOOM_IN,  callback); };
export const useSubZoomOut = (callback: SystemEventCallbackType) => { useSystemEvent(EVENT_TYPE.TOOLBAR_ZOOM_OUT, callback); };
export const useSubShowDocumentStructure = (callback: SystemEventCallbackType) => { useSystemEvent(EVENT_TYPE.TOOLBAR_SHOW_DOCUMENT_STRUCTURE, callback); };
export const useSubUnsavedChangesToObject = (callback: SystemEventCallbackType) => { useSystemEvent(EVENT_TYPE.UNSAVED_CHANGES_TO_OBJECT, callback); };
