import React, {useState} from "react";
import Box from "@material-ui/core/Box";
import {Grid, Container, Collapse, useTheme, useMediaQuery} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {GroupContainerProps} from "./UserProfileTypes";
import CollapseButton from "./CollapseButton";
import {useUserProfileStyles} from "./UserProfileStyles";


const GroupContainer = (props: GroupContainerProps) => {
    const theme = useTheme();
    const classes = useUserProfileStyles();
    const downXs = useMediaQuery(theme.breakpoints.down("xs"));

    const {title, action, collapse} = props;

    const [showChildren, setShowChildren] = useState<boolean>(true);

    const handleOnClick = () => {
        setShowChildren((value) => {
            return !value;
        })
    };

    const groupContainerRootStyle = downXs
        ? [classes.groupContainerRoot, classes.groupContainerRootLeftPaddingXs].join(" ")
        : [classes.groupContainerRoot].join(" ")

    return (
        <Container component="main" disableGutters>
            <Box m={2} border={1} pl={2} borderColor="primary" className={groupContainerRootStyle}>
                <Grid container alignItems="center" className={classes.groupContainerTitle}>
                    {title && (
                        <Grid
                            container
                            item
                            xs={action || collapse ? 6 : 12}
                            sm={action || collapse ? 10 : 12}
                            justifyContent="flex-start"
                        >
                            <Typography variant="h5" gutterBottom>
                                {title}
                            </Typography>
                        </Grid>
                    )}
                    <Grid
                        item
                        xs={action || collapse ? 6 : undefined}
                        sm={action || collapse ? 2 : undefined}
                        container
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        {action ?
                            action :
                            (collapse ?
                                    <CollapseButton state={showChildren} onClick={handleOnClick}/> :
                                    null
                            )
                        }
                    </Grid>
                </Grid>
                <Collapse in={showChildren} timeout="auto" unmountOnExit mountOnEnter>
                    <React.Fragment>{props.children}</React.Fragment>
                </Collapse>
            </Box>
        </Container>
    );
};

export default GroupContainer;
