import package_json from "../../package.json";

const REACT_APP_ID: string = process.env.REACT_APP_ID || "";

// export const API_BASE_URL = "http://localhost:3001/api/v1.0/";
export const API_BASE_URL = "https://api.sandbox.localmachines.io/v1.0/"; //FOR PRODUCTION: https://api.localmachines.io/v1.0/
// export const API_BASE_URL = "https://ramu8uy3g9.execute-api.us-west-2.amazonaws.com/v2/";

export const MQTT_HOST_URL = "iot.sandbox.localmachines.io"; //FOR PRODUCTION: iot.localmachines.io

export const APP_URL = "https://www.sandbox.localmachines.io/"; //FOR PRODUCTION: "https://www.localmachines.io/"

export const MQTT_PORT = 8883;

export const MQTT_TOPIC_CORE = "io.localmachines.sandbox"; // FOR PRODUCTION: io.localmachines

export const APP_PRIVACY_POLICY = `${APP_URL}privacy-policy`;

export const APP_TERMS_OF_USE = "https://www.apple.com/legal/internet-services/itunes/dev/stdeula/"

export const VERSION = package_json.version;
// List of possible values for APP_ID
//"localmachines"
//"expirium"
export const APP_ID = REACT_APP_ID;
// List of possible values for SITE_NAME
//"LocalMachines.io"
//"Expirium.io"
// export const SITE_NAMES: { [key: string]: string } = {
//     localmachines: "LocalMachines.io",
//     expirium: "Expirium.io"
// }

// Keys for hiding/displaying  and disable/enable sharing apple_home objects [AS]
export const SHARING_APPLE_HOME = true;
export const DISPLAYING_APPLE_HOME = true;

// DEBUG FLAGS
export const SPAM_LOGS = false;
export const NATIVE_TIME_LOGS = false;
export const NATIVE_BACKGROUND_QUEUE_TIME_LOGS = false;

export const LIST_OF_PROPERTIES_STORED_ON_S3 = ["image", "page", "file"];

export enum RESOLUTIONS {
    SD = "SD 480p",
    HD = "HD 720p",
    FULL_HD = "Full HD 1080p"
}
