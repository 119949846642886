import {
    FavoritesInfoType,
    NotificationStateType,
    ObjectActivityStatus,
    ObjectItem,
    OriginPropsType,
    PrimaryClient,
    PropertiesType,
    SubFunctionType,
    SubscriptionCallbackType,
    SubscriptionItemType
} from "./Types";
import {OriginType, SubscriptionType, CHAT_NOTIFICATION, PROPERTIES_WITH_NOTIFICATION} from "./Constants";
import {convertChildrenToStringArray, getExistingObjectIds} from "../ObjectsService";
import {LatLngBounds} from "leaflet";
import {
    APN_NOTIFICATION_TYPE,
    NOTIFICATION_TYPE,
    pubServiceNotification as showNotification
} from "../NotificationService";
import {buildSenderTitle, trimChatMessage} from "../Utils";

const subscriptions: { [key: string]: SubscriptionItemType[] } = {};

const listParentByChildren: { [key: string]: string[] } = {};

const listChildrenByParent: { [key: string]: string[] } = {};

const MAP_SUBSCRIPTION = "MAP";

// ========== SUBSCRIPTION UTILS ==========
const buildChildrenParentDependencies = (parentId: string, children: string[]) => {
    if (!Array.isArray(children)) {
        return;
    }

    listChildrenByParent[parentId] = children;

    children.forEach((item) => {
        if (!listParentByChildren.hasOwnProperty(item)) {
            listParentByChildren[item] = [];
        }

        if (!listParentByChildren[item].includes(parentId)) {
            listParentByChildren[item].push(parentId);
        }
    });
};
// ========== SUBSCRIPTION UTILS ==========

const sub: SubFunctionType = (objectId, type, subId, keys, origin, callback) => {

    if (!objectId) {
        return;
    }

    if (!subscriptions.hasOwnProperty(objectId)) {
        subscriptions[objectId] = [];
    }

    subscriptions[objectId].unshift({
        type: type,
        keys: keys,
        subscriptionId: subId,
        objectId: objectId,
        origin: origin,
        callback: callback
    });
};

const unsub = (objectId: string, subscriptionId: string, type: SubscriptionType) => {
    if (!subscriptions.hasOwnProperty(objectId)) {
        return;
    }

    subscriptions[objectId] = subscriptions[objectId].filter((item) => {
        if (item.type === type) {
            return item.subscriptionId !== subscriptionId
        }

        return true;
    });

};

const pub = async (events: SubscriptionType[], object: ObjectItem, originProps: OriginPropsType) => {
    const objectId = object.object_id;
    if (!objectId) {
        return;
    }

    await pubDeleteOrNewObjectEvent(object, events);

    if (events.includes(SubscriptionType.OBJECTS_TYPE) && Array.isArray(subscriptions[object.object_type])) {
        const typeSubscriptions = subscriptions[object.object_type];

        for (const item of typeSubscriptions) {
            item.callback(item.keys, object);
        }
    }

    if (events.includes(SubscriptionType.DELETE) ||
        (events.includes(SubscriptionType.NEW) && (object.properties?.map_point || object.properties?.map_polygon)))
    {
        pubMapChanges(object.properties, objectId, events.includes(SubscriptionType.DELETE), object);
    }

    // NOTIFICATION. NOTIFICATION_DETECT_OBJECT
    if (events.includes(SubscriptionType.NOTIFICATION_DETECT_OBJECT) && object?.observer_name && originProps?.origin !== OriginType.USER) {
        showNotification({
            message: `A ${object?.object_name} has been detected by ${object?.observer_name}`,
            type: NOTIFICATION_TYPE.SUCCESS,
            notification_payload: {
                type: APN_NOTIFICATION_TYPE.OBJECT,
                parent_id: object?.observer_id,
                child_id: object?.object_id,
            }
        });
    }

    // NOTIFICATION. NOTIFICATION_LOST_OBJECT
    if (events.includes(SubscriptionType.NOTIFICATION_LOST_OBJECT) && object?.observer_name && originProps?.origin !== OriginType.USER) {
        showNotification({
            message: `A ${object?.object_name} has been lost by ${object?.observer_name}`,
            type: NOTIFICATION_TYPE.SUCCESS,
            notification_payload: {
                type: APN_NOTIFICATION_TYPE.OBJECT,
                parent_id: object?.observer_id,
                child_id: object?.object_id,
            }
        });
    }

    // // NOTIFICATION. NOTIFICATION_STATUS
    // if (events.includes(SubscriptionType.NOTIFICATION_STATUS) && originProps?.origin !== OriginType.USER) {
    //     showNotification({
    //         message: `The ${object?.object_name} is online`,
    //         type: NOTIFICATION_TYPE.SUCCESS,
    //     });
    // }

    // NOTIFICATION. NOTIFICATION_CHAT_MESSAGE
    if (events.includes(SubscriptionType.NOTIFICATION_CHAT_MESSAGE) && object?.properties && originProps?.origin !== OriginType.USER) {
        for (const el of Object.values(object?.properties || {})) {
            if (CHAT_NOTIFICATION.includes(el.type)) {
                const senderTitle = await buildSenderTitle(el.value || {});
                const trimmedMessage = trimChatMessage(el.value || {});

                showNotification({
                    message: `${object.object_name}. ${senderTitle}: ${trimmedMessage}`,
                    type: NOTIFICATION_TYPE.SUCCESS,
                    notification_payload: {
                        type: APN_NOTIFICATION_TYPE.CHAT_MESSAGE,
                        object_id: object?.object_id,
                    }
                });
            }
        }
    }

    // NOTIFICATION. NOTIFICATION_PROPERTIES
    if (events.includes(SubscriptionType.NOTIFICATION_PROPERTIES) && object?.properties && originProps?.origin !== OriginType.USER) {
        Object.values(object?.properties || {}).forEach((el) => {
            if (PROPERTIES_WITH_NOTIFICATION.includes(el.type)) {
                showNotification({
                    message: `Property ${el.name} for ${object?.object_name} changed value`,
                    type: NOTIFICATION_TYPE.SUCCESS,
                    notification_payload: {
                        type: APN_NOTIFICATION_TYPE.PROPERTY,
                        object_id: object?.object_id,
                    }
                });
            }
        });
    }

    if (!subscriptions.hasOwnProperty(objectId)) {
        return;
    }

    const listSubscriptions = subscriptions[objectId];

    const existingChildrenByParentId: { [key: string]: string[] } = {};

    for (const item of listSubscriptions) {
        if (item.origin === originProps?.origin) {
            const isUserOrigin = item.origin === OriginType.USER;
            if ((isUserOrigin && item.subscriptionId === originProps?.sid) || !isUserOrigin) {
                continue;
            }
        }

        // CHILDREN
        if (events.includes(item.type) && SubscriptionType.CHILDREN === item.type) {
            if (Array.isArray(object.children)) {

                const listChildren = convertChildrenToStringArray(object?.children) || [];

                buildChildrenParentDependencies(objectId, listChildren);

                if (!existingChildrenByParentId.hasOwnProperty(objectId)) {
                    existingChildrenByParentId[objectId] = await getExistingObjectIds(listChildren);
                }

                item.callback(item.keys, existingChildrenByParentId[objectId]);
            }
        }

        // STATUS
        if (events.includes(item.type) && item.type === SubscriptionType.STATUS) {
            const data: ObjectActivityStatus = {};

            if (object.hasOwnProperty("primary_client")) {
                data.primary_client = object.primary_client as PrimaryClient;
            }

            if (object.hasOwnProperty("reachable")) {
                data.reachable = object.reachable as boolean;
            }

            if (object.hasOwnProperty("last_active")) {
                data.last_active = object.last_active as number;
            }
            if (Object.keys(data).length !== 0) {
                item.callback(item.keys, data as ObjectActivityStatus);
            }
        }

        // PROPERTIES
        if (events.includes(item.type) && item.type === SubscriptionType.PROPERTIES) {
            if (item.keys.propertyKey && object.properties.hasOwnProperty(item.keys.propertyKey)) {
                item.callback(item.keys, object.properties[item.keys.propertyKey]);
            }
        }

        // NOTIFICATION_STATE
        if (events.includes(item.type) && item.type === SubscriptionType.NOTIFICATION_STATE) {
            item.callback(item.keys, object.notifications as NotificationStateType);
        }

        // FAVORITES
        if (events.includes(item.type) && item.type === SubscriptionType.FAVORITES) {
            item.callback(item.keys, object.favorites as FavoritesInfoType);
        }
    }
};

// CHILDREN
const subChildrenChanges = (objectId: string, subscriptionId: string, origin: OriginType, initChildrenList: string[], callback: SubscriptionCallbackType) => {
    const keys = {
        objectId: objectId
    };

    buildChildrenParentDependencies(objectId, initChildrenList);

    sub(objectId, SubscriptionType.CHILDREN, subscriptionId, keys, origin, callback);
};

const unsubChildrenChanges = (objectId: string, subscriptionId: string) => {
    unsub(objectId, subscriptionId, SubscriptionType.CHILDREN);
};

const pubChildrenChanges = (object: ObjectItem, originProps: OriginPropsType) => {
    return pub([SubscriptionType.CHILDREN], object, originProps);
};

// DELETE_OBJECT
// NEW_OBJECT
const pubDeleteOrNewObjectEvent = async (object: ObjectItem, events: SubscriptionType[]) => {
    if (!events.includes(SubscriptionType.NEW) && !events.includes(SubscriptionType.DELETE)) {
        return;
    }

    for (const item of subscriptions[object.object_id] || []) {
        if (SubscriptionType.NEW === item.type || SubscriptionType.DELETE === item.type) {
            item.callback(item.keys, object);
        }
    }

    if (!Array.isArray(listParentByChildren[object.object_id])) {
        return;
    }

    const listParentIds = listParentByChildren[object.object_id];

    const existingChildrenByParentId: { [key: string]: string[] } = {};

    for (const parentId of listParentIds) {
        if (!Array.isArray(subscriptions[parentId])) {
            continue;
        }

        for (const item of subscriptions[parentId]) {
            if (SubscriptionType.CHILDREN !== item.type) {
                continue;
            }

            if (!existingChildrenByParentId.hasOwnProperty(parentId)) {
                existingChildrenByParentId[parentId] = await getExistingObjectIds(listChildrenByParent[parentId])
            }

            item.callback(item.keys, existingChildrenByParentId[parentId]);
        }
    }

    delete listParentByChildren[object.object_id];

};

// const subNewObject = (objectId: string, subscriptionId: string, origin: OriginType, callback: SubscriptionCallbackType) => {
//     const keys = {
//         objectId: objectId
//     };
//     sub(objectId, SubscriptionType.NEW, subscriptionId, keys, origin, callback);
// };

// const unsubNewObject = (objectId: string, subscriptionId: string) => {
//     unsub(objectId, subscriptionId, SubscriptionType.NEW);
// };

// STATUS
const subObjectStatusChanges = (objectId: string, subscriptionId: string, origin: OriginType, callback: SubscriptionCallbackType) => {
    const keys = {
        objectId: objectId
    };

    sub(objectId, SubscriptionType.STATUS, subscriptionId, keys, origin, callback);
};

const unsubObjectStatusChanges = (objectId: string, subscriptionId: string) => {
    unsub(objectId, subscriptionId, SubscriptionType.STATUS);
};

// PROPERTIES
const subPropertiesValueByKey = (objectId: string, propertyKey: string, subscriptionId: string, origin: OriginType, callback: SubscriptionCallbackType) => {
    const keys = {
        objectId: objectId,
        propertyKey: propertyKey
    };
    sub(objectId, SubscriptionType.PROPERTIES, subscriptionId, keys, origin, callback);
};

const unsubPropertiesValueByKey = (objectId: string, subscriptionId: string) => {
    unsub(objectId, subscriptionId, SubscriptionType.PROPERTIES);
};

const pubPropertiesChanges = (objectId: string, properties: PropertiesType, object: ObjectItem, originProps: OriginPropsType) => {

    if (!objectId || !Object.keys(properties || {}).length || originProps?.event !== SubscriptionType.PROPERTIES || (!subscriptions.hasOwnProperty(objectId) && !properties.map_point && !properties.map_polygon)) {
        return;
    }

    const listSubscriptions = subscriptions[objectId] || [];

    for (const item of listSubscriptions) {
        if (item.type !== SubscriptionType.PROPERTIES) {
            continue;
        }

        if (item.origin === originProps.origin) {
            const isUserOrigin = item.origin === OriginType.USER;
            if ((isUserOrigin && item.subscriptionId === originProps?.sid) || !isUserOrigin) {
                continue;
            }
        }

        if (!item.keys.propertyKey || !properties.hasOwnProperty(item.keys.propertyKey)) {
            continue;
        }

        item.callback(item.keys, properties[item.keys.propertyKey]);
    }

    if (properties.map_point || properties.map_polygon) {
        pubMapChanges(properties, objectId, false, object);
    }
};

const pubMapChanges = (properties: PropertiesType, objectId: string, isDeleted: boolean, object: ObjectItem) => {

    const listSubscriptions = subscriptions[MAP_SUBSCRIPTION] || [];

    for (const item of listSubscriptions) {
        if (item.type !== SubscriptionType.MAP) {
            continue;
        }

        if (isDeleted || (properties.map_point?.value && item.keys.mapBounds?.contains(properties.map_point?.value as any)) ||
            (properties.map_polygon?.value && (properties.map_polygon?.value as any).length &&
            (item.keys.mapBounds?.contains(properties.map_polygon?.value as any) || item.keys.mapBounds?.intersects(properties.map_polygon?.value as any))))
        {
            item.callback(item.keys, {objectId: objectId, object: object, isDeleted: isDeleted});
        }
    }
};

const subMap = (objectId: string = MAP_SUBSCRIPTION, mapBounds: LatLngBounds, subscriptionId: string, origin: OriginType, callback: SubscriptionCallbackType) => {
    const keys = {
        objectId: objectId,
        mapBounds: mapBounds
    };

    sub(objectId, SubscriptionType.MAP, subscriptionId, keys, origin, callback);
};

const unsubMap = (objectId: string = MAP_SUBSCRIPTION, subscriptionId: string) => {
    unsub(objectId, subscriptionId, SubscriptionType.MAP);
};

// NOTIFICATION_STATE
const subObjectNotificationState = (
    objectId: string,
    subscriptionId: string,
    origin: OriginType,
    callback: SubscriptionCallbackType) => {

    const keys = {
        objectId,
    };

    sub(objectId, SubscriptionType.NOTIFICATION_STATE, subscriptionId, keys, origin, callback);
};

const unsubObjectNotificationState = (objectId: string, subscriptionId: string) => {
    unsub(objectId, subscriptionId, SubscriptionType.NOTIFICATION_STATE);
};

// DELETE
const subObjectDelete = (
    objectId: string,
    subscriptionId: string,
    origin: OriginType,
    callback: SubscriptionCallbackType) => {

    const keys = {
        objectId,
    };

    sub(objectId, SubscriptionType.DELETE, subscriptionId, keys, origin, callback);
};

const unsubObjectDelete = (objectId: string, subscriptionId: string) => {
    unsub(objectId, subscriptionId, SubscriptionType.DELETE);
};

// OBJECTS_TYPE
const subObjectsType = (
    objectsType: string,
    subscriptionId: string,
    origin: OriginType,
    callback: SubscriptionCallbackType) => {

    const keys = {
        objectsType,
    };

    sub(objectsType, SubscriptionType.OBJECTS_TYPE, subscriptionId, keys, origin, callback);
};

const unsubObjectsType = (objectsType: string, subscriptionId: string) => {
    unsub(objectsType, subscriptionId, SubscriptionType.OBJECTS_TYPE);
};

//FAVORITES
const subObjectFavoriteState = (
    objectId: string,
    subscriptionId: string,
    origin: OriginType,
    callback: SubscriptionCallbackType) => {

    const keys = {
        objectId,
    };

    sub(objectId, SubscriptionType.FAVORITES, subscriptionId, keys, origin, callback);
};

const unsubObjectFavoriteState = (objectId: string, subscriptionId: string) => {
    unsub(objectId, subscriptionId, SubscriptionType.FAVORITES);
};

export {
    MAP_SUBSCRIPTION,
    pub,
    subChildrenChanges,
    unsubChildrenChanges,
    pubChildrenChanges,
    // subNewObject,
    // unsubNewObject,
    subObjectStatusChanges,
    unsubObjectStatusChanges,
    subPropertiesValueByKey,
    unsubPropertiesValueByKey,
    pubPropertiesChanges,
    subObjectFavoriteState,
    unsubObjectFavoriteState,
    subObjectNotificationState,
    unsubObjectNotificationState,
    subObjectDelete,
    unsubObjectDelete,
    subObjectsType,
    unsubObjectsType,
    subMap,
    unsubMap
}
