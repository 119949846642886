import React, {FormEvent, useRef} from "react";
import {useHistory} from "react-router-dom";
import { Button, TextField, Link, Grid } from "@material-ui/core";
import {useGlobalNotificationContext} from "../../../Notification/NotificationProvider";
import type {SnackBarType} from "../../../../Services/NotificationService";
import { signUp } from "../../../../Services/AuthenticationService";
import {UserInfoType} from "../../../../Hooks/useUserProfile";
import useAuthorizationStyles from "../../AuthorizationStyles";
import {useGlobalLoaderActionsContext, useGlobalLoaderContext} from "../../../../GlobalContext/GlobalContext";
import {isEmail} from "../../../../Services/Utils";


type SignUpProps = {
    userInfo: UserInfoType;
    toggleLogin():void;
};

const SignUp = (props: SignUpProps) => {
    const classes = useAuthorizationStyles();
    const history = useHistory();

    const notify: SnackBarType = useGlobalNotificationContext();
    const loader = useGlobalLoaderContext();
    const setLoader = useGlobalLoaderActionsContext();

    const emailRef = useRef<HTMLInputElement>(null);
    const passwordRef = useRef<HTMLInputElement>(null);
    const firstnameRef = useRef<HTMLInputElement>(null);
    const lastnameRef = useRef<HTMLInputElement>(null);

    const signUpButtonClicked = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!emailRef.current?.value) {
            return notify.errorNotify("Email is required");
        }

        if (!passwordRef.current?.value) {
            return notify.errorNotify("Password is required");
        }

        if (!firstnameRef.current?.value) {
            return notify.errorNotify("Firstname is required");
        }

        if (!lastnameRef.current?.value) {
            return notify.errorNotify("Lastname is required");
        }

        if (!isEmail(emailRef.current.value)) {
            return notify.errorNotify("Email is invalid");
        }

        try {
            setLoader(true);
            await signUp(
                emailRef.current.value,
                firstnameRef.current.value,
                lastnameRef.current.value,
                passwordRef.current.value
            );
            notify.successNotify("User created successfully. Please check your email.");
            history.replace('/');
        } catch (err: any) {
            notify.errorNotify(err?.message || JSON.stringify(err));
        } finally {
            setLoader(false);
        }
    };

    return (
        <form className={classes.form} noValidate onSubmit={signUpButtonClicked}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        autoComplete="fname"
                        name="firstname"
                        variant="outlined"
                        required
                        fullWidth
                        id="firstname"
                        label="First Name"
                        autoFocus
                        disabled={loader}
                        inputRef={firstnameRef}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="lastname"
                        label="Last Name"
                        name="lastname"
                        autoComplete="lname"
                        disabled={loader}
                        inputRef={lastnameRef}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        disabled={loader}
                        type="email"
                        inputRef={emailRef}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="new-password"
                        disabled={loader}
                        inputRef={passwordRef}
                    />
                </Grid>
            </Grid>
            <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                type="submit"
                disabled={loader}
            >
                Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
                <Grid item>
                    <Link variant="body2" onClick={props.toggleLogin}>
                        Already have an account? Sign in
                    </Link>
                </Grid>
            </Grid>
        </form>
    );
};

export default SignUp;
