import React, { useCallback, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { blue } from "@material-ui/core/colors";
import {
    Avatar,
    Dialog,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText
} from "@material-ui/core";
import {
    Add as AddIcon,
    Person as PersonIcon,
    Delete as DeleteIcon,
    Edit as EditIcon,
    MoreHoriz as MoreHorizIcon,
    Share as ShareIcon
} from "@material-ui/icons";

import { useGlobalNotificationContext } from "Components/Notification/NotificationProvider";
import AlertDialog from "Components/Dialogs/AlertDialog/AlertDialog";
import DialogLoader from "Components/Dialogs/DialogUtils/DialogLoader";
import DialogTitle from "Components/Dialogs/DialogUtils/DialogTitle";
import ShareDialog from "Components/Dialogs/ShareDialog/ShareDialog";

import type { SnackBarType } from "Services/NotificationService";
import { AccessLevel, EMPTY_OBJECT, OBJECT_TYPE, OriginType } from "Services/ObjectsService/Constants";
import { deleteObject, getObjects } from "Services/ObjectsService/CRUD";
import type { ObjectItem, Params } from "Services/ObjectsService/Types";
import { isGuest } from "Services/UserService";
import { isUserHasAccess, getTextAvatar } from "Services/Utils";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        avatar: {
            backgroundColor: blue[100],
            color: blue[600],
            paddingTop: theme.spacing(0.25)
        },
        triplePadding: {
            paddingRight: theme.spacing(14)
        }
    })
);

const ORG_LIMIT: number = 3;

const PERSONAL_ACCOUNT: ObjectItem = {
    object_id: 'personal',
    object_name: 'Personal Account',
    object_type: OBJECT_TYPE.ORGANIZATION,
    description: '',
    properties: {}
};

type OrganizationsDialogProps = {
    open: boolean;
    onOrganizationUpsert(): void;
    onClose(object: ObjectItem | null): void;
}

const OrganizationsDialog = (props: OrganizationsDialogProps) => {
    const { onClose, onOrganizationUpsert, open } = props;

    const classes = useStyles();

    const notify: SnackBarType = useGlobalNotificationContext();

    const [listObjects, setListObjects] = useState<ObjectItem[]>([]);
    const [loader, setLoader] = useState<boolean>(false);
    const [openAlertDialog, setOpenAlertDialog] = useState<ObjectItem | null>(null);
    const [openShareDialog, setOpenShareDialog] = useState<boolean>(false);
    const [objectItem, setObjectItem] = useState<ObjectItem>(EMPTY_OBJECT);

    const lastId = useRef<string | null>(null);

    const handleAlertDialogOpen = (org: ObjectItem) => {
        setOpenAlertDialog(org);
    };

    const handleAlertDialogClose = () => {
        setOpenAlertDialog(null);
    };

    const handleShareDialogOpen = (object: ObjectItem) => {
        if (isGuest()) {
            notify.warningNotify("Sharing is not allowed for Guest user. Please sign-up");
            return;
        }

        setObjectItem(object);
        setOpenShareDialog(true);
    };

    const handleShareDialogClose = () => {
        setOpenShareDialog(false);
    };

    const deleteObjectButtonClicked = useCallback(async () => {
        if (!openAlertDialog) {
            return;
        }

        setLoader(true);

        setOpenAlertDialog(null);

        try {
            await deleteObject(openAlertDialog.object_id, { origin: OriginType.USER });
            setListObjects((prev) => {
                return prev.filter((item) => {
                    return item.object_id !== openAlertDialog.object_id;
                });
            });
        } catch (err: any) {
            notify.errorNotify(err?.message || JSON.stringify(err));
        } finally {
            setLoader(false);
        }
    }, [openAlertDialog, notify]);

    const loadMoreObjects = () => {
        getObjectsList(false).then(() => {});
    };

    const getObjectsList = async (init: boolean) => {
        if (!init && !lastId.current) {
            return;
        }

        setLoader(true);

        const params: Params = {
            object_type: [OBJECT_TYPE.ORGANIZATION],
            by_page: true,
            limit: ORG_LIMIT
        };

        if (!init && lastId.current) {
            params.last_id = lastId.current;
        }

        try {
            const res = await getObjects(params);

            if (res) {
                res.objects = Array.isArray(res.objects) ? res.objects : [];
                lastId.current = res.objects.length === ORG_LIMIT ? res.last_id : null;
                setListObjects((prevState) => (!init ? prevState.concat(res.objects) : res.objects));
            }
        } catch (err) {
            console.error(err);
        } finally {
            setLoader(false);
        }
    };

    useEffect(() => {
        getObjectsList(true).then(() => {});
    }, []);

    return (
        <>
            <Dialog onClose={onClose.bind(null, null)} open={open}>
                <DialogTitle titleText={"Organizations"} handleCloseDialog={onClose.bind(null, null)}/>
                {loader && (
                    <DialogLoader state={loader}/>
                )}
                {!loader && (
                    <List>
                        <ListItem button className={classes.triplePadding} key={'personal'} onClick={onClose.bind(null, PERSONAL_ACCOUNT)}>
                            <ListItemAvatar>
                                <Avatar className={classes.avatar}><PersonIcon/></Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Personal Account"/>
                        </ListItem>
                        {listObjects.map((object, index) => (
                            <ListItem button className={classes.triplePadding} key={index} onClick={onClose.bind(null, object)}>
                                <ListItemAvatar>
                                    <Avatar className={classes.avatar}>{getTextAvatar(object.object_name)}</Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={object.object_name}/>
                                {isUserHasAccess(object, AccessLevel.WRITE) && (
                                    <ListItemSecondaryAction>
                                        <IconButton size={'small'} component={Link} to={`/organizations/${object.object_id}/edit`} onClick={onOrganizationUpsert}><EditIcon/></IconButton>
                                        <IconButton size={'small'} edge={'end'} onClick={handleShareDialogOpen.bind(null, object)}><ShareIcon/></IconButton>
                                        {isUserHasAccess(object, AccessLevel.OWNER) && (
                                            <IconButton size={'small'} edge={'end'} onClick={handleAlertDialogOpen.bind(null, object)}><DeleteIcon/></IconButton>
                                        )}
                                    </ListItemSecondaryAction>
                                )}
                            </ListItem>
                        ))}
                        {lastId.current && (
                            <ListItem button onClick={loadMoreObjects} key={'more'}>
                                <ListItemAvatar>
                                    <Avatar><MoreHorizIcon/></Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="Load More"/>
                            </ListItem>
                        )}
                        <ListItem button component={Link} to={`/organizations/create-object`} onClick={onOrganizationUpsert} key={'add'}>
                            <ListItemAvatar>
                                <Avatar><AddIcon/></Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Add Organization"/>
                        </ListItem>
                    </List>
                )}
            </Dialog>
            {!!openAlertDialog && (
                <AlertDialog
                    open={!!openAlertDialog}
                    dialogTitle={"Are you sure you want to delete " + openAlertDialog?.object_name + " organization?"}
                    dialogContent={"All users will lose access to this organization."}
                    handleAgree={deleteObjectButtonClicked}
                    handleDisagree={handleAlertDialogClose}
                />
            )}
            {openShareDialog && (
                <ShareDialog
                    open={openShareDialog}
                    object_item={objectItem}
                    handleDisagree={handleShareDialogClose}
                />
            )}
        </>
    );
};

export default OrganizationsDialog;
