import React, {useEffect, useRef} from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import {useGlobalNotificationContext} from "../../Notification/NotificationProvider";
import type {SnackBarType} from "../../../Services/NotificationService";
import {useHistory} from "react-router-dom";
import {verifiedEmail} from "../../../Services/AuthenticationService";
import Copyright from "../Copyright/Copyright";
import {useGlobalLoaderActionsContext} from "../../../GlobalContext/GlobalContext";
import {useQuery} from "../../../Hooks/useQuery";


const VerifiedEmail = () => {
    const history = useHistory();
    const query = useQuery();
    const notify: SnackBarType = useGlobalNotificationContext();

    const isVerifiedEmail = useRef<boolean>(false);

    const setLoader = useGlobalLoaderActionsContext();

    let token = query.get("token");

    useEffect(() => {
        if (!token || isVerifiedEmail.current) {
            return;
        }

        (async () => {
            try {
                setLoader(true);
                isVerifiedEmail.current = true;
                await verifiedEmail(token);
                notify.successNotify("Email address verified success");
                history.replace("/login");
            } catch (err: any) {
                notify.errorNotify(err?.message || JSON.stringify(err));
            } finally {
                history.replace("/");
                setLoader(false);
            }
        })();
    }, [token]);

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <Box mt={5}>
                <Copyright/>
            </Box>
        </Container>
    );
};

export default VerifiedEmail;
