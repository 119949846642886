import {ElementType} from "./tools/Elements/AbstractElement";
import {Point, PointText} from "paper";
import {DIMENSIONS_FONT_SIZE, hitOptionsStroke, MEASUREMENTS_COLOR} from "./Constants";
import type {SystemOfMeasuresType} from "../../Helpers/SystemOfMeasures";
import {getValueWithUnits} from "../../Helpers/SystemOfMeasures";

export class MeasurementsViewController {
    private drawingLayer: any = null;
    private measurementsLayer: any = null;
    private theme: "dark" | "light";
    private system_of_measures: SystemOfMeasuresType


    constructor(drawingLayer: any,
                measurementsLayer: any,
                theme: "dark" | "light",
                system_of_measures: SystemOfMeasuresType = 'metric') {

        this.drawingLayer = drawingLayer;
        this.measurementsLayer = measurementsLayer;

        this.theme = theme;
        this.system_of_measures = system_of_measures
    }

    // TODO: This code is temporary solution for the demonstrational purposes only.
    // Proper handing of measurements objects should be implemented
    public updateMeasurements() {
        // console.log("updateMeasurements");
        this.measurementsLayer.removeChildren();
        this.measurementsLayer.activate();
        this.drawingLayer.children.forEach((child1: any) => {
            if (child1.data.type === ElementType.SOLID_WALL) {
                // console.log("Wall Found", child1);
                let intersections = this.drawingLayer.children
                    .map((child2: any) => {
                        if (child2.data.type === ElementType.SOLID_WALL && child1 !== child2) {
                            let intersections = child1.getIntersections(child2).map((intersection: any) => {
                                //console.log(intersection);
                                return {
                                    curveLocation: intersection,
                                    path: child2,
                                };
                            });
                            intersections = intersections.concat(
                                child2.segments
                                    .map((segment: any) => {
                                        let hitResult = child1.hitTest(segment.point, hitOptionsStroke);
                                        if (hitResult) {
                                            return [
                                                {
                                                    curveLocation: hitResult.location,
                                                    path: child2,
                                                },
                                            ];
                                        } else {
                                            return [];
                                        }
                                    })
                                    .flat()
                            );

                            return intersections;
                        } else {
                            return [];
                        }
                    })
                    .flat();

                let leftIntersections = intersections.filter((intersection: any) => {
                    return intersection.path.segments.some((segment: any) => {
                        if (child1.hitTest(segment.point) === null) {
                            let child1vector = new Point(
                                child1.segments[1].point.x - child1.segments[0].point.x,
                                child1.segments[1].point.y - child1.segments[0].point.y
                            );
                            let child1to2vector = new Point(
                                segment.point.x - child1.segments[0].point.x,
                                segment.point.y - child1.segments[0].point.y
                            );
                            //console.log(child1vector);
                            let angle = child1vector.getDirectedAngle(child1to2vector);
                            return angle > 0;
                        } else {
                            // Laying on the child1 segment. Orientation should be determined by another segment.
                            return false;
                        }
                    });
                });

                let rightIntersections = intersections.filter((intersection: any) => {
                    return intersection.path.segments.some((segment: any) => {
                        if (child1.hitTest(segment.point) === null) {
                            let child1vector = new Point(
                                child1.segments[1].point.x - child1.segments[0].point.x,
                                child1.segments[1].point.y - child1.segments[0].point.y
                            );
                            let child1to2vector = new Point(
                                segment.point.x - child1.segments[0].point.x,
                                segment.point.y - child1.segments[0].point.y
                            );
                            //console.log(child1vector);
                            let angle = child1vector.getDirectedAngle(child1to2vector);
                            return angle < 0;
                        } else {
                            // Laying on the child1 segment. Orientation should be determined by another segment.
                            return false;
                        }
                    });
                });

                // Sort the intersections in ascending order by their offset within the current (child1) wall

                leftIntersections = leftIntersections.sort((intersection1: any, intersection2: any) => {
                    return (
                        child1.getOffsetOf(intersection1.curveLocation.point) -
                        child1.getOffsetOf(intersection2.curveLocation.point)
                    );
                });

                // Add the first and the last point of the wall to the list of intersections
                // Even though the end of the way may not be an intersection on it's own, the presence of this object
                // in the array should allow to estimate the length of wall segments in the unified way.

                leftIntersections = [
                    {
                        curveLocation: child1.segments[0].location,
                        path: child1,
                    },
                ]
                    .concat(leftIntersections)
                    .concat([
                        {
                            curveLocation: child1.segments[1].location,
                            path: child1,
                        },
                    ]);

                // console.log("Left Intersections", leftIntersections);

                // Same as above, but for right intersections

                rightIntersections = rightIntersections.sort((intersection1: any, intersection2: any) => {
                    return (
                        child1.getOffsetOf(intersection1.curveLocation.point) -
                        child1.getOffsetOf(intersection2.curveLocation.point)
                    );
                });

                rightIntersections = [
                    {
                        curveLocation: child1.segments[0].location,
                        path: child1,
                    },
                ]
                    .concat(rightIntersections)
                    .concat([
                        {
                            curveLocation: child1.segments[1].location,
                            path: child1,
                        },
                    ]);

                // console.log("Right Intersections", rightIntersections);

                let renderIntersections = (intersections: any, oppositeIntersections: any, textPositionCoef: any) => {
                    intersections.forEach((intersection: any, index: any) => {
                        if (index < intersections.length - 1) {
                            let distance = intersection.curveLocation.point.getDistance(
                                intersections[index + 1].curveLocation.point
                            );
                            if (distance > 5) {
                                let point = new Point(
                                    (intersection.curveLocation.point.x +
                                        intersections[index + 1].curveLocation.point.x) /
                                    2,
                                    (intersection.curveLocation.point.y +
                                        intersections[index + 1].curveLocation.point.y) /
                                    2
                                );

                                let normalVector = child1.getNormalAt(child1.getOffsetOf(point));

                                let tangentVector: any = child1.getTangentAt(child1.getOffsetOf(point));

                                let textPoint = new Point(
                                    point.x +
                                    textPositionCoef *
                                    normalVector.x *
                                    (child1.data.currentValues.thickness / 2 + DIMENSIONS_FONT_SIZE / 2),
                                    point.y +
                                    DIMENSIONS_FONT_SIZE / 3 +
                                    textPositionCoef *
                                    normalVector.y *
                                    (child1.data.currentValues.thickness / 2 + DIMENSIONS_FONT_SIZE / 2)
                                );


                                // If the intersection object is not the end of the wall - the width of the
                                // wall being intersected should be subtracted from the wall segment length
                                if (intersection.path !== child1) {
                                    distance -= intersection.path.data.currentValues.thickness / 2;
                                }

                                if (intersections[index + 1].path !== child1) {
                                    distance -= intersections[index + 1].path.data.currentValues.thickness / 2;
                                }

                                // If the intersection object is represents the end of the wall,
                                // and this end of the wall has the intersection with another wall on the
                                // opposite side - the width of the opposite wall being intersected should be
                                // added to the length of the current segment of wall.

                                if (intersection.path === child1) {
                                    let oppositeWall = oppositeIntersections.find((oppositeIntersection: any) => {
                                        return (
                                            child1.getOffsetOf(intersection.curveLocation.point) ===
                                            child1.getOffsetOf(oppositeIntersection.curveLocation.point) &&
                                            oppositeIntersection.path !== child1
                                        );
                                    });
                                    if (oppositeWall) {
                                        distance += oppositeWall.path.data.currentValues.thickness / 2;
                                    }
                                }

                                if (intersections[index + 1].path === child1) {
                                    let oppositeWall = oppositeIntersections.find((oppositeIntersection: any) => {
                                        return (
                                            child1.getOffsetOf(intersections[index + 1].curveLocation.point) ===
                                            child1.getOffsetOf(oppositeIntersection.curveLocation.point) &&
                                            oppositeIntersection.path !== child1
                                        );
                                    });
                                    if (oppositeWall) {
                                        distance += oppositeWall.path.data.currentValues.thickness / 2;
                                    }
                                }

                                // Distance is calculated, Generating the length label
                                let text = new PointText({
                                    point: textPoint,
                                    content: getValueWithUnits(
                                        this.system_of_measures,
                                        Math.round(distance * 20),
                                        child1.data.properties.thickness.units
                                    ),
                                    fillColor: MEASUREMENTS_COLOR[this.theme],
                                    justification: "center",
                                    fontSize: DIMENSIONS_FONT_SIZE
                                })

                                // Aligning the label along the wall
                                if (textPositionCoef < 0) {
                                    text.rotate(180 + tangentVector.angle);
                                } else {
                                    text.rotate(tangentVector.angle);
                                }
                            }
                        }
                    });
                };

                renderIntersections(leftIntersections, rightIntersections, -1);
                renderIntersections(rightIntersections, leftIntersections, 1);
            }
        });
        this.drawingLayer.activate();
    }
}
