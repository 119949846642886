import React, {useEffect, useState} from "react";
import { Container } from "@material-ui/core";
import Grid, { GridSize } from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { checkPropertyCardData } from "../../../../../Services/Utils";
import ObjectCard from "../../../Card/ObjectCard/ObjectCard";
import PropertyCard from "../../../Card/PropertyCard/PropertyCard";
import { DetailsContentProps } from "../../TypesObjectDetails";
import { useSubChildren } from "../../../../../Hooks/Subscriptions/useSubChildren";
import { getExistingObjectIds } from "../../../../../Services/ObjectsService";


const useStyles = makeStyles((theme) => ({
    cardGrid: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(10),
        margin: "0 auto",
    },
    mainFeaturedPost: {
        position: "relative",
        backgroundColor: theme.palette.grey[800],
        color: theme.palette.common.white,
        marginBottom: theme.spacing(4),
        backgroundImage: "url(https://source.unsplash.com/random)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
    },
    overlay: {
        position: "absolute",
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: "rgba(0,0,0,.3)",
    },
    mainFeaturedPostContent: {
        position: "relative",
        padding: theme.spacing(3),
        [theme.breakpoints.up("md")]: {
            padding: theme.spacing(6),
        },
        backgroundColor: "rgba(0, 0, 0, .25)",
    },
    loader: {
        paddingTop: theme.spacing(2),
    },
}));

export default function ShowListSubObjects(props: DetailsContentProps) {
    const classes = useStyles();

    const [listChildren, setListChildren] = useState<string[]>([]);

    const propertyCards = React.useMemo(() => {
        return Object.values(props.object.properties)
            .filter(
                (property) =>
                    property &&
                    property.visibility?.includes("details") &&
                    checkPropertyCardData(property)
            ).sort((property1, property2) => {
                if (property1.name < property2.name) { return -1; }
                if (property1.name > property2.name) { return 1; }
                if (property1.property_id < property2.property_id) { return -1; }
                if (property1.property_id > property2.property_id) { return 1; }
                return 0;
            })
    }, [props.object.properties]);

    const gridProps = React.useMemo(() =>
        props.viewInDrawer ? {xs: 12 as GridSize} : {
            xs: 12 as GridSize,
            sm: 6 as GridSize,
            md: 4 as GridSize,
            lg: 3 as GridSize,
            xl: 2 as GridSize
        }, [props.viewInDrawer]);

    useEffect(() => {
        if (!Array.isArray(props.object?.children)) {
            return;
        }

        let active = true;

        (async () => {
            const existingChildren = await getExistingObjectIds(props.object.children);

            if (active) {
                setListChildren(existingChildren);
            }
        })();

        return () => {
          active = false;
        };

    }, [props.object?.children]);

    useSubChildren( props.object.children || [], props.object.object_id, (data) => {
        setListChildren(data as string[]);
    });

    return (
        <Container className={classes.cardGrid} maxWidth="xl">
            <Grid container spacing={4}>
                {propertyCards
                    .map((property) => (
                        <Grid item key={property.property_id} {...gridProps}>
                            <PropertyCard
                                property={property}
                                notify={props.notify}
                                currentObject={props.object}
                                parentId={props.parentId}
                                userInfo={props.userInfo}
                                viewInDrawer={props.viewInDrawer}
                            />
                        </Grid>
                    ))}
                {listChildren.map((object_id) => (
                    <Grid item key={object_id} {...gridProps}>
                        <ObjectCard
                            // object_item={item}
                            object_id={object_id}
                            handleDeleteObject={props.handleDeleteObject}
                            root={false}
                            userInfo={props.userInfo}
                            viewInDrawer={props.viewInDrawer}
                            parentId={props.object.object_id}
                            setObject={props.setObject}
                        />
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
}
