import React, {useCallback, useEffect, useState} from "react";
import {Dialog, DialogProps, useMediaQuery, useTheme} from "@material-ui/core";
import {UserInfoType} from "../../../Hooks/useUserProfile";
import {DEFAULT_USER_ITEM, postProfile} from "../../../Services/UserService";
import type {UserItem} from "../../../Services/UserService";
import WelcomeDialogActions from "./WelcomeDialogActions";
import WelcomeDialogContent from "./WelcomeDialogContent";
import DialogTitle from "../DialogUtils/DialogTitle";
import {useDialogStyles} from "../DialogStyles";
import {useGlobalDialogContext, useUpdateGlobalDialogActionsContext} from "../../../GlobalContext/GlobalContext";

const slideHeaders = [
    '',
    '',
    'Place it',
    'Share it',
    'Share it more',
    'Keep the history',
    '',
    'Build 360° view',
    'Be Notified',
    'Get the objects to the map',
    'Compatibility',
    'Have a question?',
]
type WelcomeDialogProps = {
    userInfo: UserInfoType;
}

const WelcomeDialog = (props: WelcomeDialogProps) => {
    const {userInfo} = props;
    const classes = useDialogStyles();

    const theme = useTheme();
    const downXs = useMediaQuery(theme.breakpoints.down("xs"));

    const [userProfile, setUserProfile] = useState<UserItem>(userInfo.user ? {
        ...userInfo.user,
        email: !userInfo.user.guest ? userInfo.user.email : ""
    } : DEFAULT_USER_ITEM);

    const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('md');

    // Context
    const globalDialog = useGlobalDialogContext()
    const setGlobalDialog = useUpdateGlobalDialogActionsContext()

    const [dialogOpen, setDialogOpen] = useState<boolean>(false)

    const [slideHeader, setSlideHeader] = useState("")

    const [slidePage, setSlidePage] = useState<number>(1)

    const handleDontShowDialogChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setUserProfile((prevState) => (
            {
                ...prevState,
                show_welcome_dialog: !prevState.show_welcome_dialog
            }
        ))
    }, [])

    const handleCloseDialog = useCallback(() => {

        if (globalDialog) setGlobalDialog(undefined);

        setDialogOpen(false)
        if (!userProfile.show_welcome_dialog) {
            (async () => {
                try {
                    const res = await postProfile(userProfile)
                    await userInfo.setUser(res.user)
                } catch (error: any) {
                    console.error(error?.message || JSON.stringify(error))
                }
            })();
        }
        userInfo.firstShowWelcomeDialog = false
    }, [userProfile, userInfo, globalDialog])

    const handlePageChange = useCallback((event: React.ChangeEvent<unknown>, value: number) => {
        setSlidePage(value)
    }, [])

    useEffect(() => {
        setMaxWidth(downXs ? "xs" : "md")
    }, [downXs])

    useEffect(() => {
        setSlideHeader(slideHeaders[slidePage-1]);
    }, [slidePage])

    useEffect(() => {
        setDialogOpen(
            (userInfo.user?.show_welcome_dialog && userInfo.firstShowWelcomeDialog) || globalDialog === "WelcomeDialog")
    }, [userInfo, globalDialog])

    useEffect(()=>{
        if (dialogOpen) setSlidePage(1)
    }, [dialogOpen])


    return (
        <Dialog
            fullWidth
            maxWidth={maxWidth}
            open={dialogOpen}
            classes={{paper: downXs ? classes.dialogPaperXs : classes.dialogPaper}}
        >
            <DialogTitle
                titleText={slideHeader}
                handleCloseDialog={handleCloseDialog}
            />
            <WelcomeDialogContent
                slidePage={slidePage}
                xsBreakpoint={downXs}
                handlePageChange={handlePageChange}
            />
            <WelcomeDialogActions
                slidePage={slidePage}
                numberOfSlides={12}
                dontShowDialog={!userProfile.show_welcome_dialog}
                handleCheckboxChange={handleDontShowDialogChange}
                handlePageChange={handlePageChange}
                handleCloseDialog={handleCloseDialog}
            />
        </Dialog>
    )
}

export default WelcomeDialog;
