export enum EVENT_TYPE {
    DISABLE_UI,

    TOOLBAR_ZOOM_IN,
    TOOLBAR_ZOOM_OUT,
    TOOLBAR_UNDO,
    TOOLBAR_REDO,
    TOOLBAR_REMOVE,
    TOOLBAR_SUBMIT,
    // TOOLBAR_EDIT_MODE,
    // TOOLBAR_BACK

    FILTER_PUBLIC_OBJECTS,
    FILTER_OWN_OBJECTS,
    TOOLBAR_SHOW_DOCUMENT_STRUCTURE,

    CONTEXT_ORGANIZATION,

    UNSAVED_CHANGES_TO_OBJECT,
    KEY_DOWN_CMD_S
}

// export enum ACTION_TYPE {
//     CLICK,
//     SYSTEM_MESSAGE
// }
