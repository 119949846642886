import {useDialogStyles} from "../DialogStyles";
import {
    Button,
    Dialog,
    FormControlLabel,
    Switch,
    Typography
} from "@material-ui/core";
import React, {useEffect, useMemo, useState} from "react";
import {Theme, withStyles} from "@material-ui/core/styles";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import DateFnsUtils from "@date-io/date-fns";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import {isValid} from "date-fns";
import {
    useGlobalDialogContentContext,
    useGlobalDialogContext,
    useUpdateGlobalDialogActionsContext, useUpdateGlobalDialogContentActionsContext
} from "../../../GlobalContext/GlobalContext";
import DialogTitle from "../DialogUtils/DialogTitle";

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const RenewAndEditServiceLifeDialog = () => {

    const classes = useDialogStyles();

    const globalDialog = useGlobalDialogContext()
    const setGlobalDialog = useUpdateGlobalDialogActionsContext()

    const globalDialogContent = useGlobalDialogContentContext()
    const setGlobalDialogContent = useUpdateGlobalDialogContentActionsContext()

    const open = useMemo(() => globalDialog ? globalDialog.split("_")[0] === "RenewAndEditServiceLifeDialog" : false
        , [globalDialog])

    const [selectedStartDate, setSelectedStartDate] = useState<Date>(new Date());
    const [numberLifeTime, setNumberLifeTime] = useState<number>(globalDialogContent?.value?.life_time || 0);
    const [numberNotificationTime, setNumberNotificationTime] = useState<number>(globalDialogContent?.value?.notification_time || 0);
    const [showPercentageChecked, setShowPercentageChecked] = useState<boolean>(globalDialogContent?.value?.show_percentage || true);

    useEffect(() => {
        if (open && globalDialogContent) {
            setNumberLifeTime(globalDialogContent.value?.life_time || 0);
            setNumberNotificationTime(globalDialogContent.value?.notification_time || 0);
            setShowPercentageChecked(globalDialogContent.value?.hasOwnProperty("show_percentage") ? globalDialogContent.value.show_percentage : true);
        }
    }, [globalDialogContent?.value?.life_time, globalDialogContent?.value?.notification_time, globalDialogContent?.value?.show_percentage]);

    const handleCloseDialog = () => {
        setGlobalDialog(undefined);
        setGlobalDialogContent(undefined);
    };

    const handleStartDateChange = (value: Date | null) => {
        if (value && isValid(value)) {
            setSelectedStartDate(value);
        }
    };

    const handleOnLifeTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNumberLifeTime(parseFloat(event.target.value));
    };

    const handleOnNotificationTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNumberNotificationTime(parseFloat(event.target.value));
    };

    const handleShowPercentageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShowPercentageChecked(event.target.checked);
    };

    const handleUpdateClick = () => {
        globalDialogContent.buildAndSaveValue({
            start_time: selectedStartDate,
            life_time: numberLifeTime,
            notification_time: numberNotificationTime,
            show_percentage: showPercentageChecked
        });
        handleCloseDialog();
    };

    return (
        <Dialog
            fullWidth
            open={open}
            classes={{paper: classes.dialogPaper}}
        >
            <DialogTitle
                titleText={"Renew and Edit Service Life"}
                handleCloseDialog={handleCloseDialog}
            />
            <DialogContent dividers>
                {open && (
                    <Typography component="div">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                margin="normal"
                                id="date-picker-control"
                                label={"Start of service life"}
                                format="MM/dd/yyyy"
                                value={selectedStartDate}
                                onChange={handleStartDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                fullWidth
                            />
                        </MuiPickersUtilsProvider>

                        <Grid container spacing={1} justifyContent="center">
                            <Grid item xs={6}>
                                <Box p={0.5}>
                                    <TextField
                                        margin="normal"
                                        variant="outlined"
                                        type="number"
                                        value={numberLifeTime}
                                        label={"Life time"}
                                        onChange={handleOnLifeTimeChange}
                                        fullWidth
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box p={0.5}>
                                    <TextField
                                        margin="normal"
                                        variant="outlined"
                                        type="number"
                                        value={numberNotificationTime}
                                        label={"Notify expiration date"}
                                        onChange={handleOnNotificationTimeChange}
                                        fullWidth
                                    />
                                </Box>
                            </Grid>
                        </Grid>

                        <FormControlLabel
                            value="end"
                            control={
                                <Switch
                                    color="primary"
                                    checked={showPercentageChecked}
                                    onChange={handleShowPercentageChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                            label="Show remaining life as a percentage"
                            labelPlacement="end"
                        />
                    </Typography>
                )}
            </DialogContent>
            <DialogActions
                style={{justifyContent: 'center'}}
            >
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleUpdateClick}
                >
                    UPDATE
                </Button>
            </DialogActions>

        </Dialog>)
}

export default RenewAndEditServiceLifeDialog;