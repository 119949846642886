import {AbstractTool} from './AbstractTool';
import {AbstractElement} from './Elements/AbstractElement';
import {Point} from 'paper'

export class ScrollTool extends AbstractTool {
    element?: AbstractElement;
    // Mouse Event Handlers Implementation
    onMouseDown(event: any): void {
       
    }

    onMouseDrag(event: any): void {
        if (!event) {
            return;
        }

        let delta = event.downPoint.subtract(event.point);
        this.paperScope.view.scrollBy(new Point(delta))
    }

    onMouseUp(event: any): void {
        if (!event) {
            return;
        }

        let delta = event.downPoint.subtract(event.point);
        this.paperScope.view.scrollBy(new Point(delta))
    }
}