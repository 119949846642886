import React, {useEffect, useRef, useState} from "react";
import MarkerLabel from "../../MarkerLabel/MarkerLabel";
import {makeStyles} from "@material-ui/core/styles";
import CustomTooltip from "Components/Common/CustomTooltip/CustomTooltip";

const fontSizeZoom: { [index: number]: number } = {
    0.5: 10,
    0.75: 12,
    1: 14,
    1.25: 16,
    1.5: 18,
    1.75: 20,
    2: 22,
    2.25: 24,
    2.5: 26,
    2.75: 28,
    3: 30,
    3.25: 32,
    3.5: 34,
    3.75: 36,
    4: 38,
    4.25: 40,
    4.5: 42,
    4.75: 44,
    5: 46,
};

const iconSizeZoom: { [index: number]: number } = {
    0.5: 20,
    0.75: 24,
    1: 28,
    1.25: 32,
    1.5: 36,
    1.75: 40,
    2: 44,
    2.25: 48,
    2.5: 52,
    2.75: 56,
    3: 60,
    3.25: 64,
    3.5: 68,
    3.75: 72,
    4: 76,
    4.25: 80,
    4.5: 84,
    4.75: 88,
    5: 92,
};

type PlanControlMarkerProps = {
    centerPoint: { x: number, y: number };
    object_type: string;
    icon: string | null;
    object_name: string;
    zoom: number;
    tooltip?: string;
}

const PlanControlMarker = (props: PlanControlMarkerProps) => {
    const {
        centerPoint,
        object_type,
        icon,
        object_name,
        zoom,
        tooltip,
    } = props;

    const markerLabelRef = useRef<HTMLDivElement | null>(null)
    const [widthOffsetX, setWidthOffsetX] = useState<number>(0)

    const useStyles = makeStyles({
        position: {
            position: "absolute",
            zIndex: 500,
            transform: `translate(${centerPoint.x - widthOffsetX / 2}px, ${centerPoint.y - iconSizeZoom[zoom] / 2}px)`,
            "&:hover": {
                zIndex: 501,
            }
        }
    })
    const classes = useStyles()

    const markerPosition = [classes.position].join(' ')

    useEffect(() => {
        if (markerLabelRef.current) {
            setWidthOffsetX(markerLabelRef.current.offsetWidth);
        }
    }, [markerLabelRef, zoom]);

    return (
        <div
            ref={markerLabelRef}
            className={markerPosition}
        >
            <CustomTooltip tooltip={tooltip}>
                <MarkerLabel
                    object_type={object_type}
                    icon={icon}
                    object_name={object_name}
                    properties={{}}
                    selected={false}
                    presentation={"plan"}
                    fontSize={fontSizeZoom[zoom]}
                    iconSize={iconSizeZoom[zoom]}
                    showData={zoom > 0.75}
                />
            </CustomTooltip>
        </div>
    )
}

export default React.memo(PlanControlMarker)