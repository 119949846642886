import React, {Fragment} from "react";
import {format} from "date-fns";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(() => ({
    customTooltip: {
        background: "white",
        padding: 5,
        textAlign: "left",
    },
    label: {
        color: "black",
    },
}));

export default function TooltipContent({active, payload, label}: any) {
    const classes = useStyles();

    if (active) {
        return (
            <Fragment>
                {label && payload && (
                    <div className={classes.customTooltip}>
                        <p
                            className={classes.label}>
                            Time : {format(new Date(label), "P HH:mm")}
                        </p>
                        <p
                            className={classes.label}
                        >{`${payload[0].name} :  ${payload[0].value}`}
                        </p>
                    </div>
                )}
            </Fragment>
        )
    }

    return null
}
