import React from "react";
import { format } from "date-fns";
import { makeStyles } from "@material-ui/core";

type ChartTooltipProps = {
    active?: boolean;
    label?: string;
    payload?: {
        name: string;
        value: string;
    }[];
};

const useStyles = makeStyles(() => ({
    customTooltip: {
        background: "white",
        padding: 5,
        textAlign: "left",
    },
    label: {
        color: "black",
    },
}));

const ChartTooltipContent = (props: ChartTooltipProps) => {
    const { active, payload, label } = props;
    const classes = useStyles();

    if (!active) return null;

    return (
        <>
            { label && payload && (
                <div className={classes.customTooltip}>
                    <p
                        className={classes.label}>
                        Time : {format(new Date(label), "P HH:mm")}
                    </p>
                    <p
                        className={classes.label}
                    >{`${payload[0].name} :  ${payload[0].value}`}
                    </p>
                </div>
            ) }
        </>
    )
}

export default ChartTooltipContent;
