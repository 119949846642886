import {makeStyles} from "@material-ui/core/styles";
const useDialogStyles = makeStyles((theme) => ({
    dialogPaper: {
        minHeight: '40vh',
        maxHeight: '90vh',
    },
    dialogPaperXs: {
        minHeight: '40vh',
        maxHeight: '90vh',
    },
    tabPanel: {
      padding: (theme.spacing(0) + "!important") as any,
    },
    iconCompatibility: {
        fontSize: 90,
        display: "block",
        margin: "auto"
    }
}))

export {
    useDialogStyles
}
