import React, {useCallback, useState} from "react";
import {Divider, Drawer, Grid, IconButton, List, ListItem, TextField, Typography} from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {ObjectItem, PropertyItem, PropertyItemValue} from "../../../Services/ObjectsService";
import Property from "../Property/Property";
import {Settings as SettingsIcon} from "@material-ui/icons";
import ModalAddProperty from "../ObjectDetails/ModalAddProperty";
import ObjectDetails from "../ObjectDetails/ObjectDetails";
import {useSiteMapStyles} from "./SiteMapStyles";
import {isUserHasAccess} from "../../../Services/Utils";
import {AccessLevel, OBJECT_TYPE} from "../../../Services/ObjectsService/Constants";
// import {SiteMapController} from "./SiteMapController";
import {UserInfoType} from "../../../Hooks/useUserProfile";
import {useMapControllerType} from "../../../Hooks/MapController/useMapController";
import ObjectConversation from "../Conversation/ObjectConversation";

export type ObjectMapPropertiesProps = {
    open: boolean;
    closeDrawer(): void;
    object: ObjectItem | null | undefined;
    setObject(object: ObjectItem): void;
    saveObjectByKey(key: string, value: any): void;
    handleBackButton(): void;
    displayModalBind(state: boolean): void;
    openModalBind: boolean;
    parentId?: string;
    siteMapController: useMapControllerType | null;
    isDisabled?: boolean;
    userInfo: UserInfoType;
};

export default function ObjectMapProperties(props: ObjectMapPropertiesProps) {
    const classes = useSiteMapStyles({public_map: false, open: props.open});
    const {open, closeDrawer, object, siteMapController, isDisabled} = props;

    const [openPropertyDialog, setOpenPropertyDialog] = useState<boolean>(false);
    const [editProperty, setEditProperty] = useState<PropertyItem | undefined>();

    // TODO update SiteMapController -> selectObject (type)
    const handleEditPropertyDialogOpen = (property: PropertyItem) => {
        setEditProperty(property);
        setOpenPropertyDialog(true);
    };

    const handleDialogClose = useCallback(() => {
        setEditProperty(undefined);
        setOpenPropertyDialog(false);
    }, [setEditProperty, setOpenPropertyDialog]);

    const saveObjectByKey = (key: string, value: any) => {
        if (key !== "properties" || !object) return;
        siteMapController?.updateSelectedObjectProperties(value);
    };

    const callbackDeleteObject = (id: string) => {
        if (id) {
            siteMapController?.deleteChildrenObjects([id]);
            siteMapController?.setSelectObject(null);
            closeDrawer();
        }
    };

    const displayEditControls = () => {
        return object && open ? (
            <React.Fragment key={object.object_id}>
                <Grid className={classes.textFieldsContainer}>
                    <Grid item xs={12} className={classes.textField}>
                        <TextField
                            variant="outlined"
                            value={object.object_name}
                            name={"Name"}
                            type="text"
                            label="Name"
                            fullWidth={true}
                            onChange={(e: any) => {
                                siteMapController?.updateSelectedObject("object_name", e.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.textField}>
                        <TextField
                            variant="outlined"
                            value={object.description}
                            name={"Description"}
                            type="text"
                            label="Description"
                            fullWidth={true}
                            onChange={(e: any) => {
                                siteMapController?.updateSelectedObject("description", e.target.value);
                            }}
                        />
                    </Grid>
                </Grid>

                <List>
                    {Object.values(object.properties).map((property) => (
                        <ListItem key={property.key}>
                            <Grid item xs={12}>
                                <Property
                                    isDisabled={isDisabled && !isUserHasAccess(object, AccessLevel.CONTROL)}
                                    property={property}
                                    onChange={(key: string, value: PropertyItemValue) => {
                                        siteMapController?.updateValueOfProperty(key, value);
                                    }}
                                    object={object}
                                    settings={
                                        !isDisabled && isUserHasAccess(object, AccessLevel.CONTROL) ? (
                                            <IconButton
                                                color="primary"
                                                children={<SettingsIcon/>}
                                                onClick={handleEditPropertyDialogOpen.bind(null, property)}
                                            />
                                        ) : undefined
                                    }
                                    userInfo={props.userInfo}
                                />
                            </Grid>
                        </ListItem>
                    ))}
                </List>
                <Divider/>
            </React.Fragment>
        ) : (
            <Typography gutterBottom variant="h5" component="h2">
                No objects selected ...
            </Typography>
        );
    };

    const displayObjectDetails = () => {
        return (
            open &&
            object &&
            ( object.object_type === OBJECT_TYPE.CONVERSATION ? (
                <ObjectConversation object={object} />
            ) : (
                <ObjectDetails
                    callbackDeleteObject={callbackDeleteObject}
                    displayModalBind={props.displayModalBind}
                    openModalBind={props.openModalBind}
                    object={object}
                    userInfo={props.userInfo}
                    parentId={props.parentId}
                    handleBackButton={() => {
                    }}
                    setObject={props.setObject}
                    viewInDrawer
                />
            ))
        );
    };

    return (
        <React.Fragment>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="right"
                open={open}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>
                    <IconButton onClick={closeDrawer}>
                        <ChevronRightIcon/>
                    </IconButton>
                </div>
                <Divider/>
                {isDisabled ? displayObjectDetails() : displayEditControls()}
            </Drawer>
            {object && !isDisabled ? (
                <ModalAddProperty
                    open={openPropertyDialog}
                    onClose={handleDialogClose}
                    object={object}
                    saveObjectByKey={saveObjectByKey}
                    propertyItem={editProperty}
                    userInfo={props.userInfo}
                />
            ) : null}
        </React.Fragment>
    );
}
