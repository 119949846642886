import {Layer, PaperScope} from "paper";
import {GridViewController} from "./GridViewController";
import {MenuEventsController} from "./MenuEventsController";
import {MeasurementsViewController} from "./MeasurementsViewController";
import {VisualisationController} from "./VisualisationController";

import {AbstractElement, ElementType} from "./tools/Elements/AbstractElement";
import {AbstractTool, AbstractToolListener} from "./tools/AbstractTool";
import {CreateElementTool} from "./tools/CreateElementTool";
import {EditElementTool} from "./tools/EditElementTool";
import {AbstractBinding} from "./bindings/AbstractBinding";
import {GridBinding} from "./bindings/GridBinding";
import {ElementsFactory} from "./tools/Elements/ElementsFactory";
import {ObjectItem} from "../../Services/ObjectsService";
import type {SystemOfMeasuresType} from "../../Helpers/SystemOfMeasures";
import {zoneElementObjectTypes} from "./Constants";

import {v4} from "uuid";
import {ZoneElement} from "./tools/Elements/objects/ZoneElement";
import {ObjectElement} from "./tools/Elements/objects/ObjectElement";
import {sequenceGenerator} from "../../Services/Utils";
import { GuidesBinding } from "./bindings/GuidesBinding";

// TODO: Common visualisation features should be moved to superclass
// "Edit" class should only add Undo/Redo history and editing tools

export class PlanEditController implements AbstractToolListener {
    protected elementsFactory: ElementsFactory;
    // Elements
    protected elements: AbstractElement[] = [];

    protected initialElements: string[] = [];

    // Bindings
    protected bindings: AbstractBinding[] = [];
    protected gridBinding: GridBinding;
    protected guidesBinding: GuidesBinding;

    // Sub-controllers
    protected gridViewController: GridViewController;
    protected menuEventsController: MenuEventsController;
    protected measurementsViewController: MeasurementsViewController;
    protected visualisationController: VisualisationController;

    // Tools
    protected createElementTool: CreateElementTool;
    protected editElementTool: EditElementTool;

    // TODO should it be here?
    // Zooming
    protected currentZoom: number = 1;

    // Canvas the PaperJS should be inited on
    protected canvas: HTMLCanvasElement;

    // Current color theme of the app
    protected theme: "dark" | "light"

    //system of measures
    protected system_of_measures: SystemOfMeasuresType

    // PaperJS Scope
    protected scope: any = null;

    // PaperJS Layers
    protected elementsLayer: any = null;
    protected visualisationLayer: any = null;
    protected measurementsLayer: any = null;
    protected gridLayer: any = null;
    protected guidesLayer: any = null;

    // History
    protected undoHistory: any[] = [];
    protected redoHistory: any[] = [];

    //forceUpdate
    protected forceUpdate: () => void;

    constructor(canvas: HTMLCanvasElement,
                theme: "dark" | "light",
                system_of_measures: SystemOfMeasuresType = 'metric',
                forceUpdate: () => void,
                parentId?: string) {
        // Init PaperJS
        this.canvas = canvas;
        this.theme = theme
        this.system_of_measures = system_of_measures

        this.forceUpdate = forceUpdate;

        this.elementsFactory = new ElementsFactory(parentId);

        // PaperScope() is used here instead of the standard paper.setup()
        // This is done according to the example:
        //  - https://gist.github.com/donpark/f5e46b6afd1a8b166dc742f012b26a41
        // This helps to avoid the crash on the init.

        // Most likely the problem is that Javascript code loader which
        // Loads the NPM module does not run the initialisation code,
        // and thereby does not create the default PaperScope() object.
        this.scope = new PaperScope();
        this.scope.setup(canvas);

        // Create the working PaperJS Layers
        this.elementsLayer = this.scope.project.activeLayer;
        this.visualisationLayer = new Layer([]);
        this.measurementsLayer = new Layer([]);
        this.gridLayer = new Layer([]);
        this.guidesLayer = new Layer([]);

        // Align the layers
        this.gridLayer.sendToBack();
        this.elementsLayer.activate();

        // Init the history
        this.undoHistory.push(this.elementsLayer.exportJSON());
        this.redoHistory = [];

        // Init Sub-Controllers
        this.gridViewController = new GridViewController(this.gridLayer, this.theme);
        this.measurementsViewController = new MeasurementsViewController(
            this.elementsLayer, this.measurementsLayer, this.theme, this.system_of_measures);
        this.menuEventsController = new MenuEventsController(this);
        this.visualisationController = new VisualisationController(this.elementsLayer, this.visualisationLayer);

        // Render the Grid
        this.gridViewController.renderGrid();

        // Init the Bindings
        this.gridBinding = new GridBinding();
        this.guidesBinding = new GuidesBinding(this, this.guidesLayer)
        this.bindings = [this.gridBinding, this.guidesBinding];

        // Init the Tools
        this.createElementTool = new CreateElementTool(this.scope, parentId);
        this.editElementTool = new EditElementTool(this.scope);
        //this.selectAndDragTool = new SelectAndDragTool();
        this.createElementTool.addListener(this);
        this.createElementTool.setBindings(this.bindings);
        this.editElementTool.addListener(this);
        this.editElementTool.setBindings(this.bindings);
        this.editElementTool.activate();

        // TODO: Elements Layer should be activated by element create/edit tools
        this.elementsLayer.activate();

        // TODO: Remove this demonstration code
        this.createElementTool.setElementType(ElementType.SOLID_WALL);
        this.createElementTool.setElementProperties({
            thickness: {
                key: "thickness",
                value: 400,
                name: "thickness",
                type: "number",
                readable: true,
                writable: false,
                visibility: ["card"],
            },
        });
    }

    // Elements Access Method
    getElements(): AbstractElement[] {
        return this.elements;
    }

    getSelectedElement(): AbstractElement | null {
        const selectedElements = this.elements.filter((element) => element.isSelected())
        return selectedElements.length ? selectedElements[0] : null
    }

    getSelectedElements(): AbstractElement[] {
        return this.elements.filter((element) => element.isSelected());
    }

    setElementSelected(element_id: string | null): void {
        this.elements.forEach((element) => {
            element.setSelect(element.getPaperJSItem().data.id === element_id);
        });
    }

    // Alignment
    // =======================================================================

    // Update Elements Geometry
    updateElementsGeometry(): void {
        this.elements.forEach((element: AbstractElement) => {
            element.updateGeometry();
        });
    }

    // Apply The Alignment Rules
    alignElements(): void {
        this.elements.forEach((element: AbstractElement) => {
            element.alignElement(this.elements);
        });
    }

    protected planDidUpdate: (() => void) | null = null;

    // Plan Update Listener
    public setPlanDidUpdate(planDidUpdate: () => void) {
        this.planDidUpdate = planDidUpdate;
    }

    // AbstractToolListener interface implementation
    // =======================================================================

    abstractElementsUpdated(_tool: AbstractTool, _elements: AbstractElement[]): void {
        // Invoke Measurements, Alignment and Rendering update
        this.updatePlanView();
    }

    abstractElementsAdded(_tool: AbstractTool, elements: AbstractElement[]): void {
        // Add the newly created elements to the elements list:
        this.elements = this.elements.concat(elements);

        this.createElementTool.setElements(this.elements);
        this.editElementTool.setElements(this.elements);

        this.updatePlanView();
    }

    abstractElementsRemoved(_tool: AbstractTool, elements: AbstractElement[]): void {
        // Remove the just removed elements from the elements list:
        elements.forEach((element: AbstractElement) => {
            let index = this.elements.indexOf(element);
            if (index > -1) {
                this.elements.splice(index, 1);
            }
        });

        this.createElementTool.setElements(this.elements);
        this.editElementTool.setElements(this.elements);

        this.updatePlanView();
    }

    abstractElementsCompleted(tool: AbstractTool, elements: AbstractElement[]): void {
        this.updatePlanViewAndRecordState();
        if (this.createElementTool.getElementType() !== ElementType.ZONE_ELEMENT) {
            this.selectEditElementTool(null);
        }
    }

    // Tools Management
    // =======================================================================
    public selectCreateWallTool(event: any): void {
        // Clearing the previous selection
        this.elements.forEach((element: AbstractElement) => {
            element.setSelect(false);
        });
        // Activate Create Element tool
        this.createElementTool.activate();
        // Type: SolidWall
        this.createElementTool.setElementType(ElementType.SOLID_WALL);
        // Properties: Thickness
        this.createElementTool.setElementProperties({
            thickness: {
                key: "thickness",
                value: 400,
                name: "thickness",
                type: "number",
                readable: true,
                writable: false,
                visibility: ["card"],
            },
        });
    }

    public selectEditElementTool(event: any): void {
        this.editElementTool.activate();
    }

    public selectCreateDoorTool(event: any): void {
        // Clearing the previous selection
        this.elements.forEach((element: AbstractElement) => {
            element.setSelect(false);
        });
        console.log("Create Door Tool Selected");
        // Activate Create Element tool
        this.createElementTool.activate();
        // Type: SolidWall
        this.createElementTool.setElementType(ElementType.SINGLE_DOOR);
        // Properties: Thickness
        this.createElementTool.setElementProperties({
            thickness: {
                key: "thickness",
                value: 400,
                name: "thickness",
                type: "number",
                readable: true,
                writable: false,
                visibility: ["card"],
            },
        });
    }

    public selectCreateWindowTool(event: any): void {
        // Clearing the previous selection
        this.elements.forEach((element: AbstractElement) => {
            element.setSelect(false);
        });
        console.log("Create Window Tool Selected");
        // Activate Create Element tool
        this.createElementTool.activate();
        // Type: SolidWall
        this.createElementTool.setElementType(ElementType.SINGLE_WINDOW);
        // Properties: Thickness
        this.createElementTool.setElementProperties({
            thickness: {
                key: "thickness",
                value: 400,
                name: "thickness",
                type: "number",
                readable: true,
                writable: false,
                visibility: ["card"],
            },
        });
    }

    public selectCreateObjectTool(object: ObjectItem): void {
        // Clearing the previous selection
        this.elements.forEach((element: AbstractElement) => {
            element.setSelect(false);
        });
        if (ElementType[object.object_type as keyof typeof ElementType] !== undefined) {
            const elementType = ElementType[object.object_type as keyof typeof ElementType];
            const element = this.elementsFactory.createWithObject(elementType, object);
            element.setObjectType(elementType);
            this.elements.push(element);
            element.updateGeometry();
            element.updateAlignment(this.elements);
            this.createElementTool.setElements(this.elements);
            this.editElementTool.setElements(this.elements);

            this.updatePlanViewAndRecordState();
        } else if (zoneElementObjectTypes.includes(object.object_type)) {
            let plan;
            try {
                plan = JSON.parse(object.plan);
            } catch (e) {
            }

            if (Array.isArray(plan) && plan[1] && Array.isArray(plan[1].children)) {
                plan[1].children.forEach((child: any) => {
                    if (
                        !Array.isArray(child) ||
                        !child[1] ||
                        !child[1].data ||
                        child[1].data.type !== ElementType.ZONE_ELEMENT
                    ) {
                        return;
                    }

                    if (!this.elements.find((element) => child[1].data.id === element.getId())) {
                        this.elementsLayer.importJSON(child);
                    }
                });

                this.elements = this.elementsFactory.createWithPaperJSItems(this.elementsLayer.children);
                this.createElementTool.setElements(this.elements);
                this.editElementTool.setElements(this.elements);

                this.updatePlanViewAndRecordState();

                this.selectEditElementTool(null);
            } else {
                this.selectCreateZoneTool(null, object.object_type);
                this.createElementTool.setObject(object);
            }
        } else {
            this.createElementTool.activate();
            // Type: SolidWall
            this.createElementTool.setElementType(ElementType.OBJECT_ELEMENT);
            this.createElementTool.setObject(object);
        }
    }

    public selectCreateZoneTool(event: any, objectType: string): void {
        // Clearing the previous selection
        this.elements.forEach((element: AbstractElement) => {
            element.setSelect(false);
        });
        console.log("Create Zone Tool Selected");

        const zoneColorsByType: { [key: string]: number } = {
            Room: 1,
            Zone: 115,
            CameraPlanZone: 230,
        };

        // Activate Create Element tool
        this.createElementTool.activate();
        // Type: Zone
        this.createElementTool.setElementType(ElementType.ZONE_ELEMENT);
        this.createElementTool.setObjectType(objectType);

        this.createElementTool.setElementProperties({
            plan_polygon: {
                key: "plan_polygon",
                property_id: v4(),
                name: "PlanBounds",
                value: [],
                visibility: ["card", "details"],
                type: "PlanPolygon",
                readable: true,
                writable: false,
            },
            color: {
                key: "color",
                value: zoneColorsByType.hasOwnProperty(objectType) ? zoneColorsByType[objectType] : 230,
                visibility: ["card", "details"],
                property_id: v4(),
                name: "Color",
                type: "Hue",
                readable: true,
                writable: false,
            },
        });
    }

    // Zooming
    // =======================================================================

    // Zoom should be a property of PlanController (superclass for PlanEditController)
    // It is applicable to the viewer as well
    public getCurrentZoom(): number {
        return this.currentZoom;
    }

    public changeCurrentZoom(predictedChangeValue: number): void {

        const predictedZoom = this.currentZoom + predictedChangeValue

        if (predictedZoom > 5) {
            this.currentZoom = 5
            this.scope.view.zoom = this.currentZoom
        } else if (predictedZoom < 0.5) {
            this.currentZoom = 0.5
            this.scope.view.zoom = this.currentZoom
        } else {
            this.currentZoom = predictedZoom
            this.scope.view.zoom = this.currentZoom
        }
    }

    public zoomIn(): void {
        if (this.currentZoom % 0.25 > 0) {
            this.currentZoom += parseFloat((0.25 - this.currentZoom % 0.25).toFixed(2))
        } else {
            this.currentZoom += 0.25;
        }
        if (this.currentZoom > 5) {
            this.currentZoom = 5;
        }
        this.scope.view.zoom = this.currentZoom;
    }

    public zoomOut(): void {
        if (this.currentZoom % 0.25 > 0) {
            this.currentZoom -= parseFloat((this.currentZoom % 0.25).toFixed(2))
        } else {
            this.currentZoom -= 0.25;
        }
        if (this.currentZoom < 0.5) {
            this.currentZoom = 0.5;
        }
        this.scope.view.zoom = this.currentZoom;
    }

    //

    public updatePlanView() {
        this.alignElements();
        this.updateElementsGeometry();
        // Make the controllers to update the data they are responsible for accordingly
        this.measurementsViewController.updateMeasurements();
        this.visualisationController.drawJoins();

        this.planDidUpdate && this.planDidUpdate();
        this.forceUpdate()
    }

    public updatePlanViewAndRecordState() {
        this.updatePlanView();
        this.addStateToHistory();
    }

    // Removing Element
    public removeSelectedElements(): void {
        this.elements.forEach((element) => {
            if (element.isSelected()) {
                element.removeElement();
            }
        });

        this.elements = this.elements.filter((element) => !element.isRemoved());

        this.createElementTool.setElements(this.elements);
        this.editElementTool.setElements(this.elements);

        this.updatePlanViewAndRecordState();
    }

    // UNDO/REDO support
    public makeUndo() {
        if (this.undoHistory.length > 1) {
            let currentStateJSON = this.undoHistory.pop();
            this.redoHistory.push(currentStateJSON);
            let previousStateJSON = this.undoHistory[this.undoHistory.length - 1];
            this.elementsLayer.removeChildren();
            this.elementsLayer.importJSON(previousStateJSON);
            this.elements = this.elementsFactory.createWithPaperJSItems(this.elementsLayer.children);

            this.createElementTool.setElements(this.elements);
            this.editElementTool.setElements(this.elements);

            this.updatePlanView();
        }
    }

    public makeRedo() {
        if (this.redoHistory.length > 0) {
            let nextStateJSON = this.redoHistory.pop();
            this.undoHistory.push(nextStateJSON);
            this.elementsLayer.removeChildren();
            this.elementsLayer.importJSON(nextStateJSON);

            this.elements = this.elementsFactory.createWithPaperJSItems(this.elementsLayer.children);

            this.createElementTool.setElements(this.elements);
            this.editElementTool.setElements(this.elements);

            this.updatePlanView();
        }
    }

    protected addStateToHistory() {
        this.undoHistory.push(this.elementsLayer.exportJSON());
        this.redoHistory = [];
    }

    public getInitialElementIds() {
        return this.initialElements;
    }

    public loadJSON(json: any) {
        this.undoHistory = [];
        this.redoHistory = [];
        this.elementsLayer.removeChildren();
        this.elementsLayer.importJSON(json);

        this.elements = this.elementsFactory.createWithPaperJSItems(this.elementsLayer.children);

        this.createElementTool.setElements(this.elements);
        this.editElementTool.setElements(this.elements);

        this.updatePlanViewAndRecordState();
    }

    public getJSON(): any {
        return this.elementsLayer.exportJSON();
    }

    public getAllZones(): AbstractElement[] {
        return this.elements.filter((element) => element instanceof ZoneElement);
    }

    public getAllMarkedObjects(): AbstractElement[] {
        return this.elements.filter((element) => element instanceof ZoneElement || element instanceof ObjectElement);
    }

    public getViewBounds() {
        return this.scope.view.bounds;
    }

    public getLastPoint() {
        return this.scope.tool._lastPoint
    }

    public updatePlanObjects(objects: ObjectItem[]) {
        objects.forEach((object) => {
            let matchingElements = this.elements.filter((element) => element.getId() === object.object_id);
            if (matchingElements.length > 0) {
                matchingElements[0].setObjectData(object);
            }
        });
    }

    public loadPlanObjects(objects: ObjectItem[]) {
        this.scope.activate();
        this.undoHistory = [];
        this.redoHistory = [];
        this.elementsLayer.removeChildren();
        this.initialElements = [];

        this.elements = this.elementsFactory.createWithObjectItems(objects);
        this.elements.forEach((item) => {
            this.initialElements.push(item.getId());
            item.updateGeometry();
            item.updateAlignment(this.elements);
            item.subUpdateProperty(()=>{
                item.updateAlignment(this.elements);
                this.updatePlanView();
            });
        });

        this.createElementTool.setElements(this.elements);
        this.editElementTool.setElements(this.elements);

        this.updatePlanViewAndRecordState();
    }

    public removePlanObjects() {
        this.elements.forEach((item) => {
            item.removeElement();
        });
    }

    public getPlanObjects(): ObjectItem[] {
        return this.elements.map((element) => element.getObjectInstance());
    }

    // Called when the plan is first drawn
    // Allows to display all elements in the center and select the appropriate zoom
    public moveCanvasToCenter() {
        if (this.elements.length) {
            const coords = this.elements.reduce((prev: any, current: AbstractElement) => {
                let bounds = current.getPaperJSItem().bounds
                return [...prev, {x: bounds.x, y: bounds.y}, {x: bounds.x + bounds.width, y: bounds.y + bounds.height}]
            }, [])

            const xCoords = coords.map(c => c.x)
            const yCoords = coords.map(c => c.y)

            const minX = Math.min(...xCoords)
            const minY = Math.min(...yCoords)
            const maxX = Math.max(...xCoords)
            const maxY = Math.max(...yCoords)

            const averageX = minX + (maxX - minX) / 2
            const averageY = minY + (maxY + 76 - minY) / 2 // 76 px bottom navigation bar

            this.scope.view.center = {x: averageX, y: averageY}

            const elementsWidth = maxX - minX + 50
            const elementsHeight = maxY - minY + 50

            const zoomRatioX = this.scope.view.bounds.width / elementsWidth
            const zoomRatioY = this.scope.view.bounds.height / elementsHeight

            const zoomVariants = [...sequenceGenerator(0.5, 5, 0.25)]

            let targetZoom = Math.min(...[
                Math.max(...zoomVariants.filter(v => v <= zoomRatioX)),
                Math.max(...zoomVariants.filter(v => v <= zoomRatioY)),
            ])

            if (targetZoom < 0.5) targetZoom = 0.5
            if (targetZoom > 1) targetZoom = 1

            this.currentZoom = targetZoom
            this.scope.view.zoom = this.currentZoom
        }
    }


    public getEditElementToolPinchingEnd(): boolean {
        return this.editElementTool.getPinchingEnd()
    }

    public setEditElementToolPinchingEnd(value: boolean): void {
        this.editElementTool.setPinchingEnd(value)
    }

    public setGridBindingEnabled(): void {
        this.gridBinding.enable();
    }

    public setGridBindingDisabled(): void {
        this.gridBinding.disable();
    }
    
    public setGuidesBindingEnabled(): void {
        this.guidesBinding.enable();
    }
    
    public setGuidesBindingDisabled(): void {
        this.guidesBinding.disable();
    }
}
