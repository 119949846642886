import {useEffect, useRef} from "react";
import {PropertyItem} from "../../Services/ObjectsService/Types";
import {subPropertiesValueByKey, unsubPropertiesValueByKey} from "../../Services/ObjectsService";
import {v4} from "uuid";
import {OriginType} from "../../Services/ObjectsService/Constants";

type PropertyCallbackType = (data: PropertyItem) => void;
type UseSubChildrenChangesType = (objectId: string | undefined | null, propertyKey: string, callback: PropertyCallbackType) => { subscriptionId: string };

export const useSubPropertyValue: UseSubChildrenChangesType = (objectId, propertyKey, callback) => {

    const subscriptionId = useRef<string>(v4());

    useEffect(() => {
        if (!objectId || !propertyKey) {
            return;
        }

        // subscriptionId.current = v4();
        let active = true;

        subPropertiesValueByKey(objectId, propertyKey, subscriptionId.current, OriginType.USER, (keys, data) => {
            if (keys.objectId !== objectId || !active) {
                console.error("Incorrect Object ID");
                return;
            }

            callback(data as PropertyItem);
        });

        return () => {
            active = false;
            unsubPropertiesValueByKey(objectId, subscriptionId.current);
        }

    }, [objectId, propertyKey, callback]);

    return { subscriptionId: subscriptionId.current };

}