import { PropertyHistoryItem } from "Services/ObjectsService/Types";
import { convertValueToTargetMetricSystem, SystemOfMeasuresType } from "Helpers/SystemOfMeasures";


type ChartDataType = {
    x: number;
    y: number;
}

const createChartData = (x: number, y: number): ChartDataType => {
    return {x, y};
};

const getChartData = (data: PropertyHistoryItem[], units: string | undefined, system_of_measures: SystemOfMeasuresType): ChartDataType[] => {
    if (!data.length) return [];

    return data
        .map((history: PropertyHistoryItem) => {
            const value = history.value as number;
            const x = units && system_of_measures ? convertValueToTargetMetricSystem(system_of_measures, value, units) : value;
            return createChartData(Number(history.epoch_time), x);
        })
        .sort((a: ChartDataType, b: ChartDataType) => a.x - b.x);
}

export {
    getChartData,
}
