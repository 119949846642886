import React from "react";
import {Grid, Icon, Typography} from "@material-ui/core";
import {SlideType} from "../WelcomeDialogContent";

export const Slide11 = (props: SlideType) => {
    return (
        <>
            <Typography
                variant={props.xsSize ? "body1" : "h6"}
                align="left"
                gutterBottom={true}>
                Supported Platforms
            </Typography>
            <Grid
                container
                direction="row"
                spacing={4}
                justifyContent="flex-start"
                alignItems="flex-start">
                <Grid item xs={6} sm={2}>
                    <Typography
                        variant={props.xsSize ? "body1" : "h6"}
                        align="center"
                        gutterBottom={true}>
                        <Icon
                            className={"icon-iOS"}
                            style={{margin: "12px auto auto"}}
                        />
                    </Typography>
                </Grid>
                <Grid item xs={6} sm={2}>
                    <Typography
                        variant={props.xsSize ? "body1" : "h6"}
                        align="center"
                        gutterBottom={true}>
                        <Icon className={"fab fa-apple"}/><br/>
                        Mac OS
                    </Typography>
                </Grid>
                <Grid item xs={6} sm={2}>
                    <Typography
                        variant={props.xsSize ? "body1" : "h6"}
                        align="center"
                        gutterBottom={true}>
                        <Icon className={"icon-Web"}/><br/>
                        Web
                    </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Typography
                        variant={props.xsSize ? "body1" : "h6"}
                        align="center"
                        gutterBottom={true}>
                        <Icon className={"icon-Android"}/><br/>
                        Android (Coming Soon)
                    </Typography>
                </Grid>
            </Grid>

            <Typography
                variant={props.xsSize ? "body1" : "h6"}
                align="left"
                gutterBottom={true}>
                Supported Protocols
            </Typography>
            <Grid
                container
                direction="row"
                spacing={4}
                justifyContent="flex-start"
                alignItems="flex-start">
                <Grid item xs={6} sm={2}>
                    <Typography
                        variant={props.xsSize ? "body1" : "h6"}
                        align="center"
                        gutterBottom={true}>
                        <Icon className={"icon-AppleHomeKit"}/><br/>
                        Apple HomeKit
                    </Typography>
                </Grid>
                <Grid item xs={6} sm={2}>
                    <Typography
                        variant={props.xsSize ? "body1" : "h6"}
                        align="center"
                        gutterBottom={true}>
                        <Icon className={"fas fa-video"}/><br/>
                        RTSP
                    </Typography>
                </Grid>
            </Grid>
        </>
    )
}
