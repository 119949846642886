import {Button, Dialog, useMediaQuery, useTheme} from "@material-ui/core";
import DialogTitle from "../DialogUtils/DialogTitle";
import React, {useState, useCallback} from "react";
import {useDialogStyles} from "../DialogStyles";
import {Theme, withStyles} from "@material-ui/core/styles";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import {ObjectItem} from "../../../Services/ObjectsService/Types";
import TreeViewWithSearch from "../../ListObjects/TreeViewWithSearch/TreeViewWithSearch";
import {UserInfoType} from "../../../Hooks/useUserProfile";

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

type ObjectsTreeDialogProps = {
    show: boolean;
    filterObjectsIds: string[];
    filterType?: string[];
    userInfo: UserInfoType;
    childrenObjectsIds?: string[];
    handleBindChild(object: ObjectItem | null): void;
}

const ObjectsTreeDialog = (props: ObjectsTreeDialogProps) => {
    const {
        show,
        filterObjectsIds,
        filterType,
        userInfo,
        childrenObjectsIds,
        handleBindChild
    } = props;

    const classes = useDialogStyles();
    const theme = useTheme();
    const downXs = useMediaQuery(theme.breakpoints.down("xs"));

    const [selectedObject, setSelectedObject] = useState<ObjectItem | null>(null);

    const handleCloseDialog = () => {
        handleBindChild(null);
        setSelectedObject(null);
    };

    const handleConfirmSelection = () => {
        handleBindChild(selectedObject);
        setSelectedObject(null);
    };

    const handleSelectObject = useCallback((object: ObjectItem | null) => {
        if (object) {
            setSelectedObject(object);
        }
    }, []);

    return (
        <Dialog
            fullWidth
            open={show}
            classes={{paper: downXs ? classes.dialogPaperXs : classes.dialogPaper}}
        >
            <DialogTitle
                titleText={"Select object"}
                handleCloseDialog={handleCloseDialog}
            />
            <DialogContent dividers>
                {show && (
                    <TreeViewWithSearch
                        filterObjectsIds={filterObjectsIds}
                        handleSelectObject={handleSelectObject}
                        filterType={filterType}
                        userInfo={userInfo}
                        childrenObjectsIds={childrenObjectsIds}
                    />
                )}
            </DialogContent>
            <DialogActions
                style={{justifyContent: 'center'}}
            >
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleConfirmSelection}
                    disabled={!selectedObject?.object_id}
                >
                    Select
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export default ObjectsTreeDialog;
