import React, {Fragment} from 'react';
import {Badge} from "@material-ui/core";
import {BadgeProps} from "@material-ui/core/Badge/Badge";
import {useCommonStyles} from "./Common";


type ComponentWithBadgeProps = {
    showBadge?: boolean
    badgeProps?: BadgeProps
    children: React.ReactNode,
    fullWidth?: boolean, 
}

const ComponentWithBadge = (props: ComponentWithBadgeProps) => {
    const {showBadge, badgeProps, children, fullWidth} = props;
    const commonClasses = useCommonStyles();

    return (
        <Fragment>
            {showBadge && badgeProps ? (
                <Badge {...badgeProps} className={fullWidth ? commonClasses.fullWidth : ""} overlap={"rectangular"}>
                    {children}
                </Badge>
            ) : (
                <Fragment>
                    {children}
                </Fragment>
            )}
        </Fragment>
    )
}


export default React.memo(ComponentWithBadge);
