import {useEffect} from "react";
import {v4} from "uuid";
import { getUserInfo, setUserInfo, subscribeIAPUserStatus, unsubscribeIAPUserStatus } from "../../Services/UserService";

type useSubIAPUserStatusType = (userId: string | undefined) => void;

export const useSubIAPUserStatus : useSubIAPUserStatusType = (userId) => {

    useEffect(() => {
        if (!userId) {
            return;
        }

        let active = true;
        const subscriptionId = v4();

        subscribeIAPUserStatus(userId, subscriptionId, (keys, payload) => {
            if (!payload || !active) {
                return;
            }

            const userInfo = getUserInfo(userId);
            userInfo.iap = {
                environment: payload.environment,
                product_id: payload.product_id,
                end_date: payload.end_date
            };

            setUserInfo(userId, userInfo);
        });

        return () => {
            active = false;
            unsubscribeIAPUserStatus(userId, subscriptionId);
        };

    }, [userId]);
};