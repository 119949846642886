import React from "react";
import { Box, Typography } from "@material-ui/core";

import { getVersion } from "Services/Utils";

const Version = () => {
    return (
        <Box m={1}>
            <Typography variant="body2" align="center" gutterBottom>
                {getVersion()}
            </Typography>
        </Box>
    );
};

export default Version;
