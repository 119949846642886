import React, {useState} from "react";
import {
    Box,
    Container,
    Grid,
    Typography,
    useMediaQuery,
    useTheme
} from "@material-ui/core";
import ButtonWithLoader from "../../ButtonWithLoader/ButtonWithLoader";
import CachedIcon from "@material-ui/icons/Cached";
import BugReportIcon from "@material-ui/icons/BugReport";
import { postReportToSupport } from "../../../../Services/SupportService";
import type { SystemErrorType } from "../../../../Services/SupportService";

const ErrorBoundaryComponent = (props: SystemErrorType) => {

    const {error, errorInfo, errorDate} = props;

    const theme = useTheme();
    const downXs = useMediaQuery(theme.breakpoints.down("xs"));

    const [reportLoader, setReportLoader] = useState<boolean>(false);
    const [reloadPage, setReloadPage] = useState<boolean>(false);

    const onClickReloadPage = () => {
        setReloadPage(true);
        window.location.reload();
        setReloadPage(false);
    };

    const onClickSendReport = async () => {
        try {
            setReportLoader( true);
            await postReportToSupport({
                error: error,
                errorInfo: errorInfo,
                errorDate: errorDate
            });
        } catch (e) {
            console.error(e);
        } finally {
            setReportLoader( false);
        }
    };

    return (
        <Box pl={2} pr={2}>
            <Grid container justifyContent="center" spacing={0}>
                <Typography variant={downXs ? "subtitle1" : "h4"} color="secondary" paragraph>
                    Application crashed unexpectedly. Please send the report to the developers
                </Typography>
                <Grid item xs={12} sm={6} container justifyContent={downXs ? "center" : "flex-end"}>
                    <Grid item>
                        <ButtonWithLoader onClick={onClickReloadPage} loading={reloadPage} value="Reload page" buttonProps={{startIcon: <CachedIcon/>, color: "primary", disabled: reportLoader}} />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6} container justifyContent={downXs ? "center" : "flex-start"}>
                    <Grid item>
                        <ButtonWithLoader onClick={onClickSendReport} loading={reportLoader} value="Send Report" buttonProps={{startIcon: <BugReportIcon/>, color: "primary", disabled: reloadPage}}/>
                    </Grid>
                </Grid>
            </Grid>
            <Container maxWidth="lg">
                <details style={{ whiteSpace: 'pre-wrap' }}>
                    {error ? error.toString() : null}
                    <br />

                    {errorInfo?.componentStack}
                </details>
            </Container>
        </Box>
    );
};

export default ErrorBoundaryComponent;
