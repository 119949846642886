import {makeStyles} from "@material-ui/core";

const useShareDialogStyles = makeStyles((theme) => ({
    rightAlign: {
        textAlign: "right",
        margin: "auto",
    },
    marginLeft10: {
        marginLeft: "10px",
    },
    marginRight: {
        marginRight: theme.spacing(1)
    },
    button: {
        margin: theme.spacing(0.5),
    },
    select: {
        [theme.breakpoints.down("xs")]: {
            ...theme.typography.body2,
            marginTop: theme.spacing(1),
        },
        // [IM] Hide button (?) for toolbar for selected item
        "& > * > button": {
            display: 'none'
        }
    },
    iconContainer: {
        borderLeft: `1px solid ${theme.palette.divider}`,
        padding: theme.spacing(0.5),
        margin: theme.spacing(0.5)
    },
    accessLevelForm: {
        [theme.breakpoints.up("sm")]: {
            paddingLeft: theme.spacing(1),
        }
    }
}));

export {useShareDialogStyles};
