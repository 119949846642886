import {AbstractTool} from "./AbstractTool";
import {AbstractElement} from "./Elements/AbstractElement";
import {ElementType} from "./Elements/AbstractElement";
import {Point} from "paper";

export class ViewElementTool extends AbstractTool {
    element?: AbstractElement;

    // with the help of this functionality,
    // we are trying to deal with throttling when clicking on a plan
    private layerIsMoving: boolean

    private pinchingEnd: boolean


    constructor(paperScape: any, movable: boolean = true) {
        super(paperScape);
        this.layerIsMoving = false
        this.pinchingEnd = false
    }

    public getPinchingEnd(): boolean {
        return this.pinchingEnd
    }

    public setPinchingEnd(value: boolean): void {
        this.pinchingEnd = value
    }

    // Mouse Event Handlers Implementation
    onMouseDown(event: any): void {

        this.paperScope.project.activeLayer.selected = false;

        // The Greater Index - the higher priority
        let elementHitPriority = [
            ElementType.SOLID_WALL,
            ElementType.SINGLE_DOOR,
            ElementType.SINGLE_WINDOW,
            ElementType.OBJECT_ELEMENT,
            ElementType.ZONE_ELEMENT,
        ];

        let hitElements = this.elements
            .filter((element: AbstractElement) => {
                return element.testElementHit(event);
            })
            .sort((e1, e2) => {
                let index1 = elementHitPriority.indexOf(e1.getElementType());
                let index2 = elementHitPriority.indexOf(e2.getElementType());
                return index2 - index1;
            });

        // console.log("Hit Elements", hitElements);

        if (hitElements.length > 0) {
            let hitElement = hitElements[0];
            this.element = hitElement;

            this.element.onMouseDown(event);
            this.element.updateAlignment(this.elements);
            this.notifyListenersOnElementsUpdated([this.element]);
        } else {
            this.element?.updateGeometry();
            this.element = undefined;
            this.notifyListenersOnElementsUpdated([]);
        }
    }

    onMouseDrag(event: any): void {

        this.layerIsMoving = true

        if (!event) {
            return;
        }

        let delta = event.downPoint.subtract(event.point);
        if (Math.round(delta.x) !== 0 && Math.round(delta.y) !== 0)
            this.paperScope.view.scrollBy(new Point(delta));
    }

    onMouseUp(event: any): void {

        if (this.pinchingEnd) {
            this.pinchingEnd = false
            return;
        }
        if (!event) {
            return;
        }

        if (this.layerIsMoving) {
            this.layerIsMoving = false
            let delta = event.downPoint.subtract(event.point);
            if (Math.round(delta.x) !== 0 && Math.round(delta.y) !== 0)
                this.paperScope.view.scrollBy(new Point(delta));
        }
    }
}
