import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Popover from "@material-ui/core/Popover";
import { useCommonStyles, popoverPosition } from "../common/Common";
import { MapComponent } from "./MapComponent";
import { MapPointProps } from "./MapPointTypes";

const MapPoint = ({ propertyKey, label, value, variant, mapWidth, saveValue, isDisabled, settings }: MapPointProps) => {
    const commonClasses = useCommonStyles();

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const [mapPointValue, setMapPointValue] = React.useState<L.LatLngTuple>(value);

    const localVariant =
        !variant || !["control", "popover", "propertyCard", "title"].includes(variant) ? "control" : variant;

    const handleSavedValue = (_value: L.LatLngTuple) => {
        saveValue(propertyKey, _value);
    };

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
        saveValue(propertyKey, mapPointValue);
    };

    const displayPopover = Boolean(anchorEl);
    const popoverId = displayPopover ? propertyKey : undefined;

    if (localVariant === "control") {
        return (
            <React.Fragment>
                <Grid item container direction="row" justifyContent="center" alignItems="center">
                    {settings}
                    <Typography>{label || "Location"}</Typography>
                </Grid>
                <MapComponent
                    value={value}
                    mapWidth={mapWidth}
                    saveValue={handleSavedValue}
                    isDisabled={isDisabled}
                />
            </React.Fragment>
        );
    } else if (["propertyCard", "title"].includes(variant as string)) {
        return (
            <MapComponent
                value={value}
                mapWidth={mapWidth}
                saveValue={handleSavedValue}
                isDisabled
                isPropertyCard
            />
        );
    } else {
        return (
            <React.Fragment>
                <Typography component="div">
                    <Grid
                        component="label"
                        container
                        alignItems="center"
                        spacing={1}
                        onClick={handlePopoverOpen}
                        className={commonClasses.cursorPointer}
                        wrap="nowrap"
                    >
                        <Grid item container direction="row" justifyContent="center" alignItems="center">
                            {settings}
                            <Box fontSize="small">{label || "Location"}</Box>
                        </Grid>
                        <Grid item>
                            <Box fontSize="small">{value.join(", ")}</Box>
                        </Grid>
                    </Grid>
                    <Popover
                        id={popoverId}
                        open={displayPopover}
                        anchorEl={anchorEl}
                        onClose={handlePopoverClose}
                        {...popoverPosition}
                    >
                        <Box width={"100%"} p={1}>
                            <MapComponent
                                value={value || [0, 0]}
                                saveValue={setMapPointValue}
                                isDisabled={isDisabled}
                            />
                        </Box>
                    </Popover>
                </Typography>
            </React.Fragment>
        );
    }
};

export { MapPoint };
