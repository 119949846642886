import { BOTTOM_NAVIGATION_ITEMS, NAVIGATION_VIEW_MODE, NAVIGATION_VIEW_REGEXP, VIEW_TYPES } from "./constants";
import { BottomNavigationItemsType, ViewType } from "./types";
import { OBJECT_TYPE, PLAN_RELATED_TYPES } from "Services/ObjectsService/Constants";

const setStorage = (view: ViewType, id: string): void => {
    if (!id) return;
    let item:any = localStorage.getItem(NAVIGATION_VIEW_MODE);
    item = item ? JSON.parse(item) : item;
    if (!item || typeof(item) !== 'object') {
        item = {};
    }
    item[id] = view;
    localStorage.setItem(NAVIGATION_VIEW_MODE, JSON.stringify(item));
};

const getLocationFromStorage = (type: string, id: string): string => {
    const view_item = localStorage.getItem(NAVIGATION_VIEW_MODE);
    let view_type: ViewType | undefined;

    try {
        view_type = view_item && JSON.parse(view_item)?.[id];
    } catch(err) {}

    if (view_type === VIEW_TYPES.VIEW_PAGE || type === OBJECT_TYPE.DOCUMENT && !view_type) return "/page";
    if (view_type === VIEW_TYPES.VIEW_PLAN) return "/plan";
    if (view_type === VIEW_TYPES.VIEW_MAP) return "/map";
    if (view_type === VIEW_TYPES.VIEW_CHAT) return "/chat";
    if (view_type === VIEW_TYPES.VIEW_HISTORY) return "/history";
    if (type === OBJECT_TYPE.CONVERSATION && !view_type) return "/chat";
    return "";
}

const changeLocationByViewMode = (newValue: ViewType, id: string, pathname: string): string | null => {
    const cleanPath = pathname.replace(NAVIGATION_VIEW_REGEXP, "");

    if (newValue === VIEW_TYPES.VIEW_LIST) {
        setStorage(newValue, id);
        return cleanPath;
    } else if (!pathname.includes("/page") && newValue === VIEW_TYPES.VIEW_PAGE) {
        setStorage(newValue, id);
        return cleanPath + "/page";
    } else if (!pathname.includes("/plan") && newValue === VIEW_TYPES.VIEW_PLAN) {
        setStorage(newValue, id);
        return cleanPath + "/plan";
    } else if (!pathname.includes("/map") && newValue === VIEW_TYPES.VIEW_MAP) {
        setStorage(newValue, id);
        return cleanPath + "/map";
    } else if (!pathname.includes("/chat") && newValue === VIEW_TYPES.VIEW_CHAT) {
        setStorage(newValue, id);
        return cleanPath + "/chat";
    } else if (!pathname.includes("/history") && newValue === VIEW_TYPES.VIEW_HISTORY) {
        setStorage(newValue, id);
        return cleanPath + "/history";
    }

    return null;
};

const getDefaultViewByLocation = (pathname: string) => {
    return pathname.includes("/page")
        ? VIEW_TYPES.VIEW_PAGE
        : pathname.includes("/plan")
            ? VIEW_TYPES.VIEW_PLAN
            : pathname.includes("/map")
                ? VIEW_TYPES.VIEW_MAP
                : pathname.includes("/chat")
                    ? VIEW_TYPES.VIEW_CHAT
                    : pathname.includes("/history")
                        ? VIEW_TYPES.VIEW_HISTORY
                        : VIEW_TYPES.VIEW_LIST
};

const getBottomNavigationItems = (type: string) => {
    let items: BottomNavigationItemsType[] = [];

    if (type !== OBJECT_TYPE.DOCUMENT) {
        items.push(BOTTOM_NAVIGATION_ITEMS.LIST);
    } else {
        items.push(BOTTOM_NAVIGATION_ITEMS.DOCUMENT);
    }

    if (type === OBJECT_TYPE.SITE) items.push(BOTTOM_NAVIGATION_ITEMS.MAP);
    if (type === OBJECT_TYPE.CONVERSATION) items.push(BOTTOM_NAVIGATION_ITEMS.CHAT);
    if (PLAN_RELATED_TYPES.includes(type)) items.push(BOTTOM_NAVIGATION_ITEMS.PLAN);

    if (type !== OBJECT_TYPE.DOCUMENT) {
        items.push(BOTTOM_NAVIGATION_ITEMS.HISTORY);
    } else {
        items.push(BOTTOM_NAVIGATION_ITEMS.CHILDREN);
    }

    return items;
};

export {
    setStorage,
    getLocationFromStorage,
    changeLocationByViewMode,
    getDefaultViewByLocation,
    getBottomNavigationItems,
}
