import React from "react";
import {MAP_ZONE_ELEMENT_OBJECT_TYPE} from "../Constants";
import {ChildrenOnMapProps} from "../TypesSiteMap";
import MapPointChild from "./MapPointChild/MapPointChild";
import MapPolygonChild from "./MapPolygonChild/MapPolygonChild";

function ChildrenOnMap(props: ChildrenOnMapProps) {
    const {
        mapRef,
        selectObject,
        editMode,
        siteMapController,
        createMode,
        setMapDraggable,
        zoomed,
        showMarkers,
        openProperties,
        selectionHistory
    } = props;

    const map = mapRef.current ? mapRef.current.leafletElement : null;

    return map && (
        <>
            {siteMapController?.getChildrenObject().map((child) => {
                if (MAP_ZONE_ELEMENT_OBJECT_TYPE.includes(child.object_type)) {
                    return (
                        <MapPolygonChild
                            editMode={editMode}
                            child={child}
                            siteMapController={siteMapController}
                            zoomed={zoomed}
                            map={map}
                            createMode={createMode}
                            selectObject={selectObject}
                            userInfo={props.userInfo}
                            key={child.object_id}
                            showMarkers={showMarkers}
                            openProperties={openProperties}
                            selectionHistory={selectionHistory}
                        />
                    );
                } else {
                    return (
                        <MapPointChild
                            editMode={editMode}
                            child={child}
                            siteMapController={siteMapController}
                            setMapDraggable={setMapDraggable}
                            zoomed={zoomed}
                            map={map}
                            userInfo={props.userInfo}
                            key={child.object_id}
                            showMarkers={showMarkers}
                            openProperties={openProperties}
                            selectionHistory={selectionHistory}
                        />
                    );
                }
            })}
        </>
    );
}

export default React.memo(ChildrenOnMap)