import {Point} from "./Point";

export const distance = (startPoint: Point, endPoint: Point) => {

    const x = startPoint.x - endPoint.x,
        y = startPoint.y - endPoint.y

    return Math.sqrt(x * x + y * y)
}



