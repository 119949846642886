import React from "react";
import {CardMedia, useTheme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    coveredImage: {
        maxHeight: "100%",
        maxWidth: "100%",
    }
}))

type WelcomeDialogContentBodyImgProps = {
    src: string;
    themePaletteType?: "dark" | "light";
}

// TODO image props type
const WelcomeDialogContentBodyImg = (props: WelcomeDialogContentBodyImgProps) => {


    const {
        themePaletteType,
        src
    } = props;

    const classes = useStyles();
    const theme = useTheme();

    return !themePaletteType || theme.palette.type === themePaletteType
        ? (
            <CardMedia
                className={classes.coveredImage}
                component="img"
                src={src}
            />
        ) : null
}

export default WelcomeDialogContentBodyImg
