import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { Badge, createTheme, Grid, ThemeProvider } from "@material-ui/core";
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { getPlanInfo } from '../../../Services/PurchasesService';
import {
    useUpdateGlobalDialogActionsContext,
    useUpdateGlobalDialogContentActionsContext,
} from '../../../GlobalContext/GlobalContext';
import useDarkMode from "@fisch0920/use-dark-mode";

type DateRangeProps = {
    startDateRange: Date;
    endDateRange: Date;
    setStartDateRange: React.Dispatch<React.SetStateAction<Date>>;
    setEndDateRange: React.Dispatch<React.SetStateAction<Date>>;
};

const lightTheme = createTheme({
    overrides: {
        MuiInput: {
            underline: {
                color: "#fff",
                transition: "none",
                '&:before': {
                    borderBottomColor: "#fff",
                },
                '&:after': {
                    borderBottomColor: "#fff",
                },
                "&:hover:not(.Mui-disabled)": {
                    '&:before': {
                        borderBottomColor: "#fff",
                    },
                }
            }
        }
    }
});

const DateRange: React.FC<DateRangeProps> = (props: DateRangeProps) => {
    const { startDateRange, endDateRange, setStartDateRange, setEndDateRange } = props;

    const darkMode = useDarkMode();

    const product_id = getPlanInfo().product_id;

    const setGlobalDialog = useUpdateGlobalDialogActionsContext();
    const setGlobalDialogContent = useUpdateGlobalDialogContentActionsContext();

    const handleOnOpenDateChange = (): void => {
        if (product_id === 'free') {
            setGlobalDialog('UpgradeToProDialog')
            setGlobalDialogContent('property_history_pro')
        }
    }

    return (
        <ThemeProvider theme={!darkMode.value ? lightTheme : {}}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            {/*{ product_id === 'free' ?*/}
            {/*    (*/}
            {/*        <Grid item xs={12} sm={6} container spacing={5}>*/}
            {/*            <Grid item xs={6}>*/}
            {/*                <Badge*/}
            {/*                    anchorOrigin={{*/}
            {/*                        vertical: 'top',*/}
            {/*                        horizontal: 'left',*/}
            {/*                    }}*/}
            {/*                    overlap='rectangular'*/}
            {/*                    badgeContent='Pro'*/}
            {/*                    color='primary'>*/}
            {/*                    <DateTimePicker*/}
            {/*                        autoOk*/}
            {/*                        ampm={false}*/}
            {/*                        disableFuture*/}
            {/*                        value={startDateRange}*/}
            {/*                        open={false}*/}
            {/*                        onOpen={handleOnOpenDateChange}*/}
            {/*                        onChange={(value: Date | null) => value && setStartDateRange(value)}*/}
            {/*                        format='MM/dd HH:mm'*/}
            {/*                        maxDate={endDateRange}*/}
            {/*                    />*/}
            {/*                </Badge>*/}
            {/*            </Grid>*/}
            {/*            <Grid item xs={6}>*/}
            {/*                <Badge*/}
            {/*                    anchorOrigin={{*/}
            {/*                        vertical: 'top',*/}
            {/*                        horizontal: 'left',*/}
            {/*                    }}*/}
            {/*                    overlap='rectangular'*/}
            {/*                    badgeContent='Pro'*/}
            {/*                    color='primary'>*/}
            {/*                    <DateTimePicker*/}
            {/*                        autoOk*/}
            {/*                        ampm={false}*/}
            {/*                        disableFuture*/}
            {/*                        value={endDateRange}*/}
            {/*                        open={false}*/}
            {/*                        onOpen={handleOnOpenDateChange}*/}
            {/*                        onChange={(value: Date | null) => value && setEndDateRange(value)}*/}
            {/*                        format='MM/dd HH:mm'*/}
            {/*                        minDate={startDateRange}*/}
            {/*                    />*/}
            {/*                </Badge>*/}
            {/*            </Grid>*/}
            {/*        </Grid>*/}
            {/*    ) : (*/}
                    <Grid item xs={12} sm={6} container spacing={5}>
                        <Grid item xs={6}>
                            <DateTimePicker
                                autoOk
                                ampm={false}
                                disableFuture
                                value={startDateRange}
                                onChange={(value: Date | null) => value && setStartDateRange(value)}
                                format='MM/dd HH:mm'
                                maxDate={endDateRange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DateTimePicker
                                autoOk
                                ampm={false}
                                disableFuture
                                value={endDateRange}
                                onChange={(value: Date | null) => value && setEndDateRange(value)}
                                format='MM/dd HH:mm'
                                minDate={startDateRange}
                            />
                        </Grid>
                    </Grid>
                    {/*)*/}
            {/*}*/}
        </MuiPickersUtilsProvider>
        </ThemeProvider>
    )
}

export default DateRange;
