import React, {useCallback} from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import InfoIcon from "@material-ui/icons/Info";
import {PlanEditComponent} from "../FloorPlan/PlanEditComponent";
import PlanElementProperties from "../FloorPlan/PlanElementProperties";
import FloorPlanEditorToolbar from "../FloorPlan/FloorPlanEditorToolbar";
import {PlanEditController} from "../FloorPlanEditor/PlanEditController";
import {Route, Switch} from "react-router-dom";
import {ChildItemType, ObjectItem, postObject} from "../../Services/ObjectsService";
import type {SnackBarType} from "../../Services/NotificationService";
import ObjectsTreeDialog from "../Dialogs/ObjectsTreeDialog/ObjectsTreeDialog";
import {PlanViewComponent} from "../FloorPlan/PlanViewComponent";
import {PlanViewController} from "../FloorPlanEditor/PlanViewController";
import {Fab} from "@material-ui/core";
import {UserInfoType} from "../../Hooks/useUserProfile";
import useForceUpdate from "../../Hooks/ForceUpdate/useForseUpdate";
import {ElementType} from "../FloorPlanEditor/tools/Elements/AbstractElement";
import {OriginType} from "../../Services/ObjectsService/Constants";
import {getClientId, getUserId} from "../../Services/UserService";
import {copy} from "../../Services/Utils";

const useStyles = makeStyles((theme: Theme) => createStyles({
        properties_open: {
            margin: 5,
            position: "fixed",
            top: 112,
            right: 10,
            zIndex: 502
        }
    }
));

type ObjectFloorPlanProps = {
    displayLoader(value: boolean): void;
    setObject(object: ObjectItem): void;
    displayModalBind(state: boolean): void;
    handleBackButton(): void;
    saveObjectByKey(key: string, value: any): void;
    openModalBind: boolean;
    object: ObjectItem;
    notify: SnackBarType;
    userInfo: UserInfoType;
};

const filterObjectsIds: string[] = [];

export default function ObjectFloorPlan(props: ObjectFloorPlanProps) {

    const classes = useStyles();

    const {
        displayModalBind,
        displayLoader,
        handleBackButton,
        saveObjectByKey,
        openModalBind,
        object,
        setObject,
        notify,
        userInfo,
    } = props;

    const forceUpdate = useForceUpdate();

    const [elementPropertiesOpened, setElementPropertiesOpened] = React.useState(false);
    const [elementObjectsOpened, setElementObjectsOpened] = React.useState(false);
    const [filterObjectsType, setFilterObjectsType] = React.useState<string>("all_available");
    const [selectedElementId, setSelectedElementId] = React.useState<string | null>(null);

    const [planEditItem, setPlanEditItem] = React.useState<PlanEditController | null>(null);
    const [planViewItem, setPlanViewItem] = React.useState<PlanViewController | null>(null);
    const [planZoom, setPlanZoom] = React.useState<number>(1);

    // TODO [IM] Keep it here until removing filterObjectsIds prop from ObjectsTreeDialog
    // const planVisibleObjectsIds = useRef<string[]>([]);
    //
    // const planChildren = planEditItem?.getPlanObjects()
    //     .filter(obj => obj.properties.plan_point ? obj.properties.plan_point.value[object.object_id] : false)
    //     .map((_object) => _object.object_id) || [];
    // if (!equalsIgnoreOrder(planChildren, planVisibleObjectsIds.current)) {
    //     planVisibleObjectsIds.current = planChildren;
    // }

    const handleUpdateSelectedElementId = useCallback((element_id: string | null) => {
        setSelectedElementId(element_id)
    }, [])

    const elementPropertiesClosed = useCallback(() => {
        setElementPropertiesOpened(false)
    }, [])

    const elementPropertiesOpen = useCallback(() => {
        setElementPropertiesOpened(true);
    }, []);

    const handleOpenDrawerObjects = (filterType: string) => {
        setElementObjectsOpened(true);
        setFilterObjectsType(filterType);
    };

    const handleCloseDrawerObjects = useCallback(() => {
        setElementObjectsOpened(false);
        setFilterObjectsType("all_available");
    }, [])

    const handleBindChild = useCallback((bindObject: ObjectItem | null) => {
        if (planEditItem && bindObject) {
            const planChildren = planEditItem?.getPlanObjects()
                .filter((obj) => (obj.properties.plan_point && obj.properties.plan_point.value[object.object_id]) ||
                                           (obj.properties.plan_polygon && obj.properties.plan_polygon.value[object.object_id]))
                .map((_object) => _object.object_id) || [];

            if (planChildren.includes(bindObject.object_id)) {
                planEditItem?.setElementSelected(bindObject.object_id);
                handleUpdateSelectedElementId(bindObject.object_id);
            } else {
                planEditItem.selectCreateObjectTool(bindObject);
            }
        }

        setElementObjectsOpened(false);
    }, [planEditItem, handleUpdateSelectedElementId, object.object_id]);

    const handleZoomUpdate = useCallback(() => {
        if (planViewItem) {
            setPlanZoom(planViewItem.getCurrentZoom());
        } else if (planEditItem) {
            setPlanZoom(planEditItem.getCurrentZoom());
        }
        forceUpdate();
    }, [planViewItem, planEditItem, forceUpdate]);

    const savePlanEditClicked = async() => {
        console.log("Saving JSON Edit Plan");

        if (!planEditItem) {
            return;
        }

        displayLoader(true);

        const listPlanObjects = planEditItem.getPlanObjects();
        const listInitialElementIds = planEditItem.getInitialElementIds();
        planEditItem.removePlanObjects();

        let _children = object.children?.concat([]) || [];
        let listPlanObjectsIds: string[] = [];

        try {
            for (let item of listPlanObjects) {
                if (!_children.includes(item.object_id)) {
                    _children.push(item.object_id);
                }

                if (typeof item.object_type === "number" && ElementType[item.object_type as number]) {
                    item.object_type = ElementType[item.object_type as number];
                }

                if (object.owner_id === getUserId() && !item.permissions) {
                    item.permissions = copy(object.permissions);
                }

                await postObject(item, {origin: OriginType.USER, clientId: getClientId(), parentOwnerId: object.owner_id});
                listPlanObjectsIds.push(item.object_id);

                if (listPlanObjectsIds.length) {
                    _children = _children.filter((item: string) => {
                        return !(listInitialElementIds.includes(item) && !listPlanObjectsIds.includes(item));
                    });
                }
            }

            let allChildren: any = [...new Set([..._children, ...listInitialElementIds])];
            let updatedChildren: ChildItemType[] = [];
            const epoch = Date.now();

            for (let childId of allChildren) {
                if (listInitialElementIds.includes(childId) && !_children.includes(childId)) {
                    updatedChildren.push({id: childId, unbind: epoch});
                } else if (!listInitialElementIds.includes(childId) && _children.includes(childId)) {
                    updatedChildren.push({id: childId, bind: epoch});
                }
            }

            await postObject({object_id: object.object_id, children: updatedChildren}, {origin: OriginType.USER, clientId: getClientId()});

            console.log("Success");
            saveObjectByKey("children", _children);
            handleBackButton();
            displayLoader(false);
        } catch(err: any) {
            console.error(err);
            notify.errorNotify(err?.message || JSON.stringify(err));
            displayLoader(false);
            return;
        }
    };

    return (
        <>
            <Switch>
                <Route exact path={`(.*)/plan/edit`}>
                    <PlanEditComponent
                        planJSON={object.plan}
                        setPlanEditItem={setPlanEditItem}
                        displayLoader={displayLoader}
                        planEditItem={planEditItem}
                        notify={notify}
                        objectChildren={object.children || []}
                        objectId={object.object_id}
                        planZoom={planZoom}
                        userInfo={userInfo}
                        handleUpdateSelectedElementId={handleUpdateSelectedElementId}
                        savePlanEditClicked={savePlanEditClicked}
                        handleZoomUpdate={handleZoomUpdate}
                    />
                    <FloorPlanEditorToolbar
                        planEditItem={planEditItem}
                        openDrawerObjects={handleOpenDrawerObjects}
                        closeDrawerObjects={handleCloseDrawerObjects}
                    />
                    {elementPropertiesOpened && (
                        <PlanElementProperties
                            open={elementPropertiesOpened}
                            elementPropertiesClosed={elementPropertiesClosed}
                            displayModalBind={displayModalBind}
                            openModalBind={openModalBind}
                            planController={planEditItem}
                            userInfo={props.userInfo}
                            setObject={setObject}
                            parentId={object.object_id}
                            selectedElementId={selectedElementId}
                        />
                    )}
                    <ObjectsTreeDialog
                        show={elementObjectsOpened}
                        filterObjectsIds={filterObjectsIds}
                        filterType={[filterObjectsType]}
                        userInfo={userInfo}
                        handleBindChild={handleBindChild}
                    />
                    <Fab
                        color="primary"
                        size="small"
                        aria-label="open properties"
                        component="span"
                        className={classes.properties_open}
                        onClick={elementPropertiesOpen}
                        children={<InfoIcon/>}
                    />
                </Route>
                <Route>
                    <PlanViewComponent
                        planJSON={object.plan}
                        setPlanViewItem={setPlanViewItem}
                        displayLoader={displayLoader}
                        planViewItem={planViewItem}
                        notify={notify}
                        objectChildren={object.children || []}
                        objectId={object.object_id}
                        planZoom={planZoom}
                        userInfo={userInfo}
                        handleUpdateSelectedElementId={handleUpdateSelectedElementId}
                        handleZoomUpdate={handleZoomUpdate}
                        openDetails={elementPropertiesOpen}
                    />
                    {elementPropertiesOpened && (
                        <PlanElementProperties
                            open={elementPropertiesOpened}
                            elementPropertiesClosed={elementPropertiesClosed}
                            displayModalBind={displayModalBind}
                            openModalBind={openModalBind}
                            planController={planViewItem}
                            isDisabled={true}
                            userInfo={props.userInfo}
                            setObject={setObject}
                            parentId={object.object_id}
                            selectedElementId={selectedElementId}
                        />
                    )}
                    <Fab
                        color="primary"
                        size="small"
                        aria-label="open properties"
                        component="span"
                        className={classes.properties_open}
                        onClick={elementPropertiesOpen}
                        children={<InfoIcon/>}
                    />
                </Route>
            </Switch>
        </>
    );
}
