import {PropertyItem} from "../../Services/ObjectsService";

const propertiesPriority: { [key: string]: number } = {
    ServiceLife: 11,
    ImagePoint: 10,
    Image: 9,
    ImagePolygon: 8,
    MapPoint: 7,
    MapPolygon: 6,
    PlanPoint: 5,
    PlanPolygon: 4,
    PowerState: 3,
    numericValue: 2,
    File: 1,
}

const excludePropertiesTypes: string[] = ["Image", "ImageBounds", "MapPoint", "MapPolygon", "CameraMapZone"]

const comparePropertiesPriority = (a: PropertyItem, b: PropertyItem) =>
    propertiesPriority[b.type] - propertiesPriority[a.type]

const getPrimeProperty = (
    properties: PropertyItem[], visibilityType?: string): PropertyItem | undefined => properties
    .map((property: PropertyItem) =>
        typeof property.value === "number" ? {...property, type: "numericValue"} : property)
    .filter((property: PropertyItem) =>
        property.visibility?.includes("title")
        && (visibilityType
        ? (property.visibility?.includes(visibilityType) && !excludePropertiesTypes.includes(property.type))
        : true)
        && Object.keys(propertiesPriority).includes(property.type))
    .sort(comparePropertiesPriority)[0]


export {
    getPrimeProperty
}
