import React, {useEffect} from "react";

import {MainContent} from "./Components/MainContent";
import {createTheme, CssBaseline, ThemeProvider} from "@material-ui/core";
import useDarkMode, {DarkMode} from "@fisch0920/use-dark-mode";
import "leaflet.path.drag";

import {BrowserRouter as Router} from "react-router-dom";

import "./App.css";
import {darkTheme, lightTheme} from "./Helpers/CustomThemes";
import {GlobalContextProvider} from "./GlobalContext/GlobalContext";
import {NotificationProvider} from "./Components/Notification/NotificationProvider";
import LogsContainer from "./Components/Common/LogsContainer/LogsContainer";
import ErrorBoundary from "./Components/Common/ErrorBoundary/ErrorBoundary";
import {APP_ID} from "./Services/Constants";

function App() {
    const darkMode: DarkMode = useDarkMode();
    const [themeVariant, setThemeVariant] = React.useState<any>(darkMode.value ? darkTheme : lightTheme);

    useEffect(() => {
        setThemeVariant(darkMode.value ? darkTheme : lightTheme);

        const favicon = document.getElementById('favicon') as HTMLLinkElement;
        const manifest = document.getElementById('manifest') as HTMLLinkElement;
        if (!favicon || !manifest) { return; }

        if (darkMode.value) {
            favicon.href = `/${APP_ID}/favicon-black.ico?v=black`;
            manifest.href = `/${APP_ID}/manifest-black.json?v=black`;
        } else {
            favicon.href = `/${APP_ID}/favicon.ico?v=light`;
            manifest.href = `/${APP_ID}/manifest.json?v=light`;
        }
    }, [darkMode.value]);

    return (
        <ThemeProvider theme={createTheme(themeVariant)}>
            <ErrorBoundary>
                <GlobalContextProvider>
                    <Router>
                        <NotificationProvider>
                            <div className="App">
                                <CssBaseline/>
                                <MainContent />
                                <LogsContainer/>
                            </div>
                        </NotificationProvider>
                    </Router>
                </GlobalContextProvider>
            </ErrorBoundary>
        </ThemeProvider>
    );
}

export default App;
