import React from "react";
import { CardContent, CardHeader, Icon } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    card: {
        minHeight: "425px",
        display: "flex",
        flexDirection: "column",
        margin: "0 auto",
    },
    cardContent: {
        height: "380px",
    },
}));

export type PropertyCardProps = {
    name?: string;
    type?: string;
    icon?: string;
    children?: React.ReactNode;
};

const HistoryCard = (props: PropertyCardProps): JSX.Element => {
    const { name, type, icon, children } = props;

    const classes = useStyles();

    return (
        <Card className={ classes.card }>
            <CardHeader
                avatar={
                    <Icon
                        fontSize="small"
                        className={ icon || "fas fa-info-circle" }
                    />
                }
                title={ name || type }
            />
            <CardContent className={ classes.cardContent }>
                { children }
            </CardContent>
        </Card>
    );
};

export default HistoryCard;
