import {STATE_TYPE} from "./Constants"
import {pubSetOrganization, pubSetUnsavedChangesToObject} from "../SystemEventsService";

const states: { [key in keyof typeof STATE_TYPE]?: any } = {};

const setSystemState = (stateType: STATE_TYPE, state: any) => {
    states[stateType] = state;

    switch (stateType) {
        case STATE_TYPE.ORGANIZATION:
            pubSetOrganization();
            // TODO [IM] subscribe to org changes(name)?
            break;

        case STATE_TYPE.UNSAVED_CHANGES_TO_OBJECT:
            pubSetUnsavedChangesToObject();
            break;
    }
};

const getSystemState = (stateType: STATE_TYPE) => {
    if (!states.hasOwnProperty(stateType)) {
        return null;
    }

    return states[stateType];
};

const cleanSystemStates = () => {
    for (let key in STATE_TYPE) {
        if (states.hasOwnProperty(key)) {
            delete states[key];
        }
    }
};

export {
    setSystemState,
    getSystemState,

    cleanSystemStates
};
