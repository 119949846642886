import {ReactNode, useEffect, useState, useMemo} from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import {ObjectItem, PointTuple} from "../../Services/ObjectsService";
import type {SystemOfMeasuresType} from "../../Helpers/SystemOfMeasures";
import {PLAN_RELATED_TYPES} from "../../Services/ObjectsService/Constants";
import {PointFields} from "./common/PointFields";
import {useCommonStyles} from "./common/Common";
import {PlanControl} from "./common/PlanControl";
import ObjectsFromTreeView from "../ListObjects/ObjectsFromTreeView/ObjectsFromTreeView";
import {UserInfoType} from "../../Hooks/useUserProfile";
import { copy } from "../../Services/Utils";

type PointsFieldsControlProps = {
    propertyKey: string;
    label: string;
    value?: { [key: string]: PointTuple[] };
    enableSelect?: boolean;
    enableDelete?: boolean;
    saveValue(key: string, value: { [key: string]: PointTuple[] }): void;
    isDisabled?: boolean;
    settings?: ReactNode;
    parentId?: string;
    variant?: string;
    object?: ObjectItem;
    userInfo: UserInfoType;
    systemOfMeasures: SystemOfMeasuresType;
};

const PointsFieldsControl = (props: PointsFieldsControlProps) => {
    const classes = useCommonStyles();

    const { propertyKey, label, value, enableSelect, enableDelete, saveValue, isDisabled, settings, parentId, variant, object, userInfo, systemOfMeasures } = props;

    let parentIds = (value && Object.keys(value)) || [];

    const [selectedId, setSelectedId] = useState<string>(parentId || parentIds[0]);
    const [listPoints, setListPoints] = useState<PointTuple[]>([]);

    const updatedObject = useMemo<ObjectItem>(() => {
        if (!object) return {}
        const _object = copy(object);
        _object.properties[propertyKey].value[selectedId] = listPoints;
        return _object;
    }, [object, propertyKey, listPoints, selectedId]);

    const updatePoints = (index: number, _value: PointTuple) => {
        setListPoints((prev) => {
            const newPoints = [...prev.slice(0, index), _value, ...prev.slice(index + 1)];
            saveValue(propertyKey, {...value, [selectedId]: newPoints});
            return newPoints;
        });
    };

    const handleRemovePoint = (index: number) => {
        setListPoints((prev) => {
            const newPoints = [...prev.slice(0, index), ...prev.slice(index + 1)];
            saveValue(propertyKey, {...value, [selectedId]: newPoints});
            return newPoints;
        });
    };

    const changeSelectParentId = (object: ObjectItem | null) => {
        setSelectedId(object?.object_id || "");
    };

    const addNewPoint = () => {
        setListPoints((prev) => {
            const x = prev.reduce((acc, point) => acc += point[0], 0) / prev.length;
            const y = prev.reduce((acc, point) => acc += point[1], 0) / prev.length;
            const newPoints = [...prev, [x,y] as PointTuple];
            saveValue(propertyKey, {...value, [selectedId]: newPoints});
            return newPoints;
        });
    };

    useEffect(() => {
        if (value) {
            setListPoints(value[selectedId] || []);
        }
    }, [selectedId, value]);

    if (["propertyCard", "title"].includes(variant as string)) {
        return <PlanControl
            variant={variant}
            object={object}
            parentId={parentId}
            systemOfMeasures={systemOfMeasures}
        />;
    } else {
        return (
            <>
                <Grid item container direction="row" justifyContent="center" alignItems="center">
                    {settings}
                    <Typography>{label || "Plan Polygon"}</Typography>
                </Grid>
                <Box border={1} className={classes.border}>
                    <ObjectsFromTreeView
                        handleObject={changeSelectParentId}
                        currentObjectId={selectedId}
                        filterType={PLAN_RELATED_TYPES}
                        userInfo={userInfo}
                        childrenObjectsIds={parentIds}
                    />
                    <Box mt={1}>
                        <PlanControl
                            object={updatedObject}
                            parentId={selectedId}
                            systemOfMeasures={systemOfMeasures}
                        />
                    </Box>
                    {listPoints.map((point: PointTuple, index: number) => (
                        <PointFields
                            key={`planpolygonfield_${index}`}
                            point={point as PointTuple}
                            label={`p${index}`}
                            enableSelect={enableSelect}
                            enableDelete={enableDelete}
                            handleUpdate={updatePoints.bind(null, index)}
                            handleRemove={handleRemovePoint.bind(null, index)}
                            isDisabled={isDisabled}
                        />
                    ))}
                    <Grid container spacing={1}>
                        <Grid item container justifyContent="center" xs={12}>
                            <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={addNewPoint}>
                                Add
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </>
        );
    }
};

export {PointsFieldsControl};
