const withThrottle = (func: Function, ms: number):any => {
    let isThrottled = false
    let savedArgs: any = null

    if (func === undefined && ms === undefined) console.error('callback function and the timeout must be supplied')

    function wrapper() {
        if (isThrottled) {
            savedArgs = arguments
            return
        }

        func.apply(null, arguments)

        isThrottled = true

        setTimeout(function () {
            isThrottled = false
            if (savedArgs) {
                wrapper.apply(null, savedArgs)
                savedArgs = null
            }
        }, ms)
    }

    return wrapper
}

const withDebounce = (func: Function, ms: number) => {
    let isCooldown = false

    function wrapper() {
        if (isCooldown) return
        func.apply(null, arguments)

        isCooldown = true
        setTimeout(() => isCooldown = false, ms)
    }

    return wrapper
}

export {withThrottle, withDebounce}