import {UserInfoType} from "../../Hooks/useUserProfile";
import UpgradeToProDialog from "./UpgradeToProDialog/UpgradeToProDialog";
import WelcomeDialog from "./WelcomeDialog/WelcomeDialog";
import FullscreenImageDialog from "./FullscreenImageDialog/FullscreenImageDialog";
import React, {Fragment} from "react";
import RenewAndEditServiceLifeDialog from "./RenewAndEditServiceLifeDialog/RenewAndEditServiceLifeDialog";
import {APP_ID} from "../../Services/Constants";
import {APPLICATION_ID} from "../../Constants";

type AppGlobalDialogsProps = {
    userInfo: UserInfoType;
}
const AppGlobalDialogs = (props: AppGlobalDialogsProps) => {

    const {userInfo} = props;
    return (
        <Fragment>
            <UpgradeToProDialog/>
            {APP_ID === APPLICATION_ID.LOCAL_MACHINES && <WelcomeDialog
                userInfo={userInfo}
            />}
            <FullscreenImageDialog/>
            <RenewAndEditServiceLifeDialog/>
        </Fragment>

    )
}

export default AppGlobalDialogs;
