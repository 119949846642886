import {useState} from "react";
import type {NotificationPayload, ToastOptionsType, ToastType} from "../Services/NotificationService";

export const useNotify = () => {

    //function to add toast message
    const addNotify = (message: string, options: ToastOptionsType, notification_payload?: NotificationPayload) => {
        let uniqueId;
        setStateNotify((curState: any) => {
            uniqueId = curState.idCounter + 1;
            let default_options = {
                type: "success",
                autoDismiss: true,
                autoDismissTime: 6000
            };

            let toast: ToastType = {
                message: message,
                id: uniqueId,
                options: Object.assign(default_options, (options || {})),
                notification_payload
            };

            return {
                ...curState,
                idCounter: uniqueId,
                toast: toast
            };
        });
        return uniqueId;
    };

    const removeNotify = () => {
        setStateNotify(curState => {
            //remove the toast message using unique id
            return {
                ...curState,
                toast: null
            };
        });
    };

    //remove all active toast
    const removeAllNotify = () => {
        setStateNotify(curState => {
            return {
                ...curState,
                toasts: []
            };
        });
    };

    const successNotify = (message: string, notification_payload?: NotificationPayload) => {
        addNotify(message, {type: "success"}, notification_payload);
    };

    const errorNotify = (message: string) => {
        console.error(message);
        addNotify(message || "Error has occurred", {type: "error"});
    };

    const warningNotify = (message: string) => {
        console.warn(message);
        addNotify(message, {type: "warning"});
    };

    let [stateNotify, setStateNotify] = useState({
        toast: null,
        idCounter: 0,
        addNotify: addNotify,
        removeNotify: removeNotify,
        removeAllNotify: removeAllNotify,
        successNotify: successNotify,
        errorNotify: errorNotify,
        warningNotify: warningNotify
    });

    return stateNotify;
};
