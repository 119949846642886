import React, {useEffect, useState} from "react";
import {TreeView} from "@material-ui/lab";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ObjectTreeItem from "./ObjectTreeItem/ObjectTreeItem";
import {UserInfoType} from "../../../Hooks/useUserProfile";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

const useTreeViewStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: 264,
            flexGrow: 1,
            maxWidth: "100%",
        },
    })
)

type ObjectsTreeViewProps = {
    tree: any
    search: string
    userInfo: UserInfoType;
    displayOptions?: string[];
    selectObjectHandler(object_id: string): void
}

const ObjectTreeView = (props: ObjectsTreeViewProps) => {
    const classes = useTreeViewStyles();
    const {tree, search, userInfo, displayOptions, selectObjectHandler} = props;

    const [expanded, setExpanded] = useState<string[]>([])

    useEffect(() => {
        if (search) {
            setExpanded(getExpandedItems(tree, "root"));
        } else {
            setExpanded([]);
        }
    }, [search]);


    useEffect(() => {
        if (tree) {
            setExpanded(getExpandedItems(tree, "root"));
        }
    }, [tree]);

    const handleToggle = (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
        setExpanded(nodeIds);
    }

    const getNodeId = (parent_id: string, object_id: string) => {
        return parent_id + "_" + object_id;
    };

    const getStyledTreeViewItems = (currentTree: any, parent_id: string) => {
        let items = []
        for (const node of currentTree) {
            const nodeId = getNodeId(parent_id, node.object_id);

            if (node.hasOwnProperty("children")) {
                items.push(
                    <ObjectTreeItem
                        key={nodeId}
                        object_id={node.object_id}
                        nodeId={nodeId}
                        labelText={node.name}
                        labelIcon={node.icon}
                        displayOptions={displayOptions}
                        properties={node.properties}
                        userInfo={userInfo}
                        lastActivityEpoch={node.last_active}
                        objectDeviceName={node.primary_client?.name}
                        selectObjectHandler={selectObjectHandler}
                    >
                        {getStyledTreeViewItems(node.children, nodeId)}
                    </ObjectTreeItem>
                )
            } else {
                items.push(
                    <ObjectTreeItem
                        key={nodeId}
                        object_id={node.object_id}
                        nodeId={nodeId}
                        labelText={node.name}
                        labelIcon={node.icon}
                        displayOptions={displayOptions}
                        properties={node.properties}
                        userInfo={userInfo}
                        lastActivityEpoch={node.last_active}
                        objectDeviceName={node.primary_client?.name}
                        selectObjectHandler={selectObjectHandler}
                    />
                )
            }
        }
        return items
    }

    const getExpandedItems = (currentTree: any, parent_id: string) => {
        let expandedItems: string[] = []
        for (const node of currentTree) {
            if (node.hasOwnProperty("children")) {
                const nodeId = getNodeId(parent_id, node.object_id);

                expandedItems.push(nodeId);
                expandedItems = [...expandedItems, ...getExpandedItems(node.children, nodeId)];
            }
        }
        return expandedItems
    }


    return (
        <TreeView
            className={classes.root}
            defaultCollapseIcon={<ExpandMoreIcon/>}
            defaultExpandIcon={<ChevronRightIcon/>}
            expanded={expanded}
            onNodeToggle={handleToggle}
        >
            {getStyledTreeViewItems(tree, "root")}
        </TreeView>
    )
}

export default ObjectTreeView
