import React from "react";
import {makeStyles, Theme, createStyles, LinearProgress} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backdrop: ({open}: {open:boolean}) => ({
            width: '100%',
            '& > * + *': {
                marginTop: theme.spacing(2),
            },
            visibility: open ? "visible" : "hidden",
            zIndex: theme.zIndex.tooltip + 1,
            position: 'fixed',
        }),
    })
);
export default function Loader({ state }: { state: boolean }) {
    const classes = useStyles({open: state});
    return (
        <div className={classes.backdrop}>
            <LinearProgress />
        </div>
    );
}
