import React, {useEffect, useState} from "react";
import {getPlanInfo} from "../../../Services/PurchasesService";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Popover from "@material-ui/core/Popover";
import {popoverPosition, useCommonStyles} from "../common/Common";
import Typography from "@material-ui/core/Typography";
import SelectControlElement from "./SelectControlElement";
import ComponentWithBadge from "../common/ComponentWithBadge";
import {
    useUpdateGlobalDialogActionsContext,
    useUpdateGlobalDialogContentActionsContext
} from "../../../GlobalContext/GlobalContext";
import {PROPERTIES_EDITABLE_ONLY_IN_PRO_VERSION} from "../../../Services/ObjectsService/Constants";

type SelectControlProps = {
    propertyKey: string;
    label: string;
    value: string | number;
    valueOptionsList: any[];
    onChangeHandler?: boolean;
    variant?: string;
    controlInputProps?: object;
    saveValue(key: string, value: string | number | boolean): void;
    isHMCharacteristicValue?: boolean;
    isWritable?: boolean;
};

const SelectControl = (props: SelectControlProps) => {
    const commonClasses = useCommonStyles();

    const {propertyKey, label, value, valueOptionsList, variant, onChangeHandler, controlInputProps, saveValue, isHMCharacteristicValue, isWritable} = props;

    const product_id = getPlanInfo().product_id
    const setGlobalDialog = useUpdateGlobalDialogActionsContext()
    const setGlobalDialogContent = useUpdateGlobalDialogContentActionsContext()

    const [selectedComponentState, setSelectedComponentState] = useState<string | number>(
            isHMCharacteristicValue ? (value) : ((value && valueOptionsList.includes(value)) ? value : valueOptionsList[0])
    );
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | undefined>(undefined);

    const localVariant = !variant || !["control", "popover"].includes(variant) ? "control" : variant;

    const handleOnSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (isHMCharacteristicValue) {
            setSelectedComponentState(event.target.value);
            if (onChangeHandler) {
                saveValue(event.target.name, event.target.value);
            }
        } else {
            if (event.target.value && valueOptionsList.includes(event.target.value)) {
                setSelectedComponentState(event.target.value);
                if (onChangeHandler) {
                    saveValue(event.target.name, event.target.value);
                }
            }
        }
    };

    const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        if (!onChangeHandler) {
            saveValue(event.target.name, event.target.value);
        }
    }

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        if (product_id === "free" && PROPERTIES_EDITABLE_ONLY_IN_PRO_VERSION.includes(propertyKey)) {
            setGlobalDialog("UpgradeToProDialog")
            setGlobalDialogContent("camera_resolution_pro")
        } else {
            setAnchorEl(event.currentTarget);
        }
    };

    const handlePopoverClose = () => {
        setAnchorEl(undefined);
    };

    const handleClickOnSelectControlElement = (event: any) => {
        if (product_id === "free" && PROPERTIES_EDITABLE_ONLY_IN_PRO_VERSION.includes(propertyKey)) {
            event.preventDefault()
            event.stopPropagation()
            setGlobalDialog("UpgradeToProDialog")
            setGlobalDialogContent("camera_resolution_pro")
        }
    }

    const displayPopover = Boolean(anchorEl);
    const _propertyKey = displayPopover ? propertyKey : undefined;

    useEffect(() => {
        setSelectedComponentState(value);
    }, [value]);


    if (localVariant === "control") {

        return (
            <ComponentWithBadge
                showBadge={product_id === "free" && PROPERTIES_EDITABLE_ONLY_IN_PRO_VERSION.includes(propertyKey)}
                badgeProps={{
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                    badgeContent: "Pro",
                    color: "primary"
                }}
            >
                <SelectControlElement
                    propertyKey={propertyKey}
                    label={label}
                    value={selectedComponentState}
                    valueOptionsList={valueOptionsList}
                    disabled={!isWritable || (product_id === "free" && PROPERTIES_EDITABLE_ONLY_IN_PRO_VERSION.includes(propertyKey))}
                    controlInputProps={controlInputProps}
                    handleOnSelect={handleOnSelect}
                    handleOnBlur={handleOnBlur}
                    handleOnClick={handleClickOnSelectControlElement}
                    isHMCharacteristicValue={isHMCharacteristicValue}
                />
            </ComponentWithBadge>
        )
    }

    return (
        <Typography component="div">
            <Grid
                component="label"
                container
                alignItems="center"
                spacing={1}
                onClick={handlePopoverOpen}
                wrap="nowrap"
                className={commonClasses.cursorPointer}
            >
                <Grid item>
                    <Box fontSize="small">{label}:</Box>
                </Grid>
                <Grid item>
                    <ComponentWithBadge
                        showBadge={product_id === "free" && PROPERTIES_EDITABLE_ONLY_IN_PRO_VERSION.includes(propertyKey)}
                        fullWidth={true}
                        badgeProps={{
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                            },
                            badgeContent: "Pro",
                            color: "primary"
                        }}
                    >
                        <Box fontSize="small">
                            {isHMCharacteristicValue ? ((valueOptionsList.find((option) => option.value === value))?.label || value) : value}
                        </Box>
                    </ComponentWithBadge>
                </Grid>
            </Grid>
            <Popover
                id={_propertyKey}
                open={displayPopover}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                {...popoverPosition}
            >
                <Box p={1} className={commonClasses.popoverContent}
                >
                    <SelectControlElement
                        propertyKey={propertyKey}
                        label={label}
                        value={selectedComponentState}
                        valueOptionsList={valueOptionsList}
                        disabled={!isWritable || (product_id === "free" && PROPERTIES_EDITABLE_ONLY_IN_PRO_VERSION.includes(propertyKey))}
                        controlInputProps={controlInputProps}
                        handleOnSelect={handleOnSelect}
                        handleOnBlur={handleOnBlur}
                        handleOnClick={handleClickOnSelectControlElement}
                        isHMCharacteristicValue={isHMCharacteristicValue}
                    />
                </Box>
            </Popover>
        </Typography>
    )
}

export default SelectControl
