import { PointTuple } from "./ObjectsService";
import { arrayReducer } from "./Utils";

// [IM] Moved from area-polygon 1.0.1 node module
const area = (points: any[], signed?: boolean) => {
    const l = points.length;
    let det = 0;
    const isSigned = signed || false;

    points = points.map(normalize);
    if (points[0] !== points[points.length -1]) {
        points = points.concat(points[0]);
    }

    for (let i = 0; i < l; i++) {
        det += points[i].x * points[i + 1].y - points[i].y * points[i + 1].x;
    }

    if (isSigned) {
        return det / 2;
    } else {
        return Math.abs(det) / 2;
    }
}

const normalize = (point: any) => {
    if (Array.isArray(point)) {
        return {
            x: point[0],
            y: point[1]
        };
    } else {
        return point;
    }
}
// [IM] End of moved code

const OUTPUT_IMAGE_WIDTH = 1024//64//1024;
const OUTPUT_IMAGE_HEIGHT = 1024//64//1024;

// [IM] Replacing type from leaflet
type LatLngTuple = [number, number];

type Callback = (outputImage: HTMLCanvasElement | null) => void;

type HomographyMatrixParams = {
    w: number;
    h: number;
    input: PointTuple[];
    output: PointTuple[];
    inverted: boolean;
    height?: number;
    width?: number;
};

type HomographyMatrixResult = {
    homographyMatrix: any;
    minOutPoint: LatLngTuple;
    maxOutPoint: LatLngTuple;
    dstLength: number;
    convertedOutputPoints: number[][];
}

type LocationDetectedObjectParams = {
    relativePoint: PointTuple;
    inImage: string;
    inPoints: PointTuple[];
    outPoints: PointTuple[];
    invertedPoints: boolean;
};

const transformImage = (
    inImage: string,
    inPoints: PointTuple[],
    outPoints: PointTuple[],
    invertedPoints: boolean,
    callback: Callback
) => {
    const cv = (window as any).cv;

    if (!inImage || !Array.isArray(inPoints) || !Array.isArray(outPoints)) {
        return callback(null);
    }
    
    if (outPoints.length < 3 || inPoints.length < 3) {
        return callback(null);
    }

    if (!cv) {
        console.error("openCV is not defined");
        return callback(null);
    }

    const imageOnLoad = () => {

        const homographyParams = {
            w: i.width,
            h: i.height,
            input: inPoints,
            output: outPoints,
            inverted: invertedPoints
        };

        let { homographyMatrix, convertedOutputPoints } = getHomographyMatrix(homographyParams);

        let src = cv.imread(i);
        let dst = new cv.Mat();
        let dsize = new cv.Size(OUTPUT_IMAGE_WIDTH, OUTPUT_IMAGE_HEIGHT);

        // Create black image with output image size
        let mask = cv.Mat.zeros(OUTPUT_IMAGE_WIDTH, OUTPUT_IMAGE_HEIGHT, cv.CV_8UC3);
        // Add alpha channel and Set this image as transparent
        cv.cvtColor(mask, mask, cv.COLOR_BGR2BGRA, 0);
        mask.convertTo(mask, -1, 0);
        // Get polygon contours from output points
        let polygonContours = cv.matFromArray(
            convertedOutputPoints.length,
            1,
            cv.CV_32SC2,
            convertedOutputPoints.reduce(arrayReducer, [])
        );
        let contours = new cv.MatVector();
        contours.push_back(polygonContours);
        // Set color for drawing contours as visible (alpha channel 255)
        let color = new cv.Scalar(255, 255, 255, 255);
        for (let i = 0; i < contours.size(); ++i) {
            cv.drawContours(mask, contours, i, color, -1, cv.LINE_8);
        }

        // Applying Perspective Transform
        // let M = cv.findHomography(srcTri, dstTri, 0);
        cv.warpPerspective(src, dst, homographyMatrix, dsize, cv.INTER_LINEAR, cv.BORDER_CONSTANT, new cv.Scalar());

        // Get separately on R,G,B,A channels of output image
        let rgbaPlanesSrc = new cv.MatVector();
        cv.split(dst, rgbaPlanesSrc);
        // Get separately on R,G,B,A channels of mask image
        let rgbaPlanesMask = new cv.MatVector();
        cv.split(mask, rgbaPlanesMask);

        // Get R G B channels from output image and alpha channel from mask
        // (Should be displaying only drawn an area of Contours)
        let rgbaPlanesNew = new cv.MatVector();
        rgbaPlanesNew.push_back(rgbaPlanesSrc.get(0));
        rgbaPlanesNew.push_back(rgbaPlanesSrc.get(1));
        rgbaPlanesNew.push_back(rgbaPlanesSrc.get(2));
        rgbaPlanesNew.push_back(rgbaPlanesMask.get(3));

        // Set new channels to source image
        cv.merge(rgbaPlanesNew, dst);

        let canvas = document.createElement("canvas");

        cv.imshow(canvas, dst);
        // const dataURL = canvas.toDataURL();

        src.delete();
        dst.delete();
        mask.delete();
        homographyMatrix.delete();
        // srcTri.delete();
        // dstTri.delete();
        polygonContours.delete();
        contours.delete();
        rgbaPlanesSrc.delete();
        rgbaPlanesMask.delete();
        rgbaPlanesNew.delete();

        callback(canvas);
    };

    const i = new Image();
    i.onload = imageOnLoad;
    i.src = inImage as string;
};

const _transformImage = (
    inImage: string,
    inPoints: PointTuple[],
    outPoints: PointTuple[],
    invertedPoints: boolean,
    callback: Callback,
    pixelPoints?: LatLngTuple[],
) => {
    const cv = (window as any).cv;

    if (!inImage || !Array.isArray(inPoints) || !Array.isArray(outPoints)) {
        return callback(null);
    }

    if (outPoints.length < 3 || inPoints.length < 3) {
        return callback(null);
    }

    if (!cv) {
        console.error("openCV is not defined");
        return callback(null);
    }

    const imageOnLoad = () => {
        let _OUTPUT_IMAGE_WIDTH = OUTPUT_IMAGE_WIDTH;
        let _OUTPUT_IMAGE_HEIGHT = OUTPUT_IMAGE_HEIGHT;

        if (pixelPoints) {
            const minOutPoint = getMinPoint(pixelPoints);
            const maxOutPoint = getMaxPoint(pixelPoints);

            if (!isNaN(minOutPoint[0]) && !isNaN(maxOutPoint[0]) && !isNaN(minOutPoint[1]) && !isNaN(maxOutPoint[1])) {
                _OUTPUT_IMAGE_WIDTH = maxOutPoint[0] - minOutPoint[0];
                _OUTPUT_IMAGE_WIDTH = _OUTPUT_IMAGE_WIDTH > OUTPUT_IMAGE_WIDTH ? OUTPUT_IMAGE_WIDTH : _OUTPUT_IMAGE_WIDTH > 64 ? _OUTPUT_IMAGE_WIDTH : 64;
                _OUTPUT_IMAGE_HEIGHT = maxOutPoint[1] - minOutPoint[1];
                _OUTPUT_IMAGE_HEIGHT = _OUTPUT_IMAGE_HEIGHT > OUTPUT_IMAGE_HEIGHT ? OUTPUT_IMAGE_HEIGHT : _OUTPUT_IMAGE_HEIGHT > 64 ? _OUTPUT_IMAGE_HEIGHT : 64;
            }
        }

        const homographyParams = {
            w: i.width,
            h: i.height,
            input: inPoints,
            output: outPoints,
            inverted: invertedPoints,
            width: _OUTPUT_IMAGE_WIDTH,
            height: _OUTPUT_IMAGE_HEIGHT
        };


        const { homographyMatrix, dstLength, convertedOutputPoints } = getHomographyMatrix(homographyParams);

        let deletes = [];
        deletes.push(homographyMatrix);
        let src = cv.imread(i);
        deletes.push(src);
        let dst = new cv.Mat();
        deletes.push(dst);
        let dsize = new cv.Size(_OUTPUT_IMAGE_WIDTH, _OUTPUT_IMAGE_HEIGHT);

        // Create black image with output image size
        let mask = cv.Mat.zeros(dsize, cv.CV_8UC3);
        deletes.push(mask);
        // Add alpha channel and Set this image as transparent
        cv.cvtColor(mask, mask, cv.COLOR_BGR2BGRA, 0);
        mask.convertTo(mask, -1, 0);
        // Get polygon contours from output points
        let polygonContours = cv.matFromArray(
            convertedOutputPoints.length,
            1,
            cv.CV_32SC2,
            convertedOutputPoints.reduce(arrayReducer, [])
        );
        deletes.push(polygonContours);
        let contours = new cv.MatVector();
        deletes.push(contours);
        contours.push_back(polygonContours);
        // Set color for drawing contours as visible (alpha channel 255)
        let color = new cv.Scalar(255, 255, 255, 255);
        for (let i = 0; i < contours.size(); ++i) {
            deletes.push(cv.drawContours(mask, contours, i, color, -1, cv.LINE_8));
        }

        // Applying Perspective Transform
        if (dstLength > 3) {
            cv.warpPerspective(src, dst, homographyMatrix, dsize, cv.INTER_LINEAR, cv.BORDER_CONSTANT, new cv.Scalar())
        } else {
            cv.warpAffine(src, dst, homographyMatrix, dsize, cv.INTER_LINEAR, cv.BORDER_CONSTANT, new cv.Scalar());
        }

        // Get separately on R,G,B,A channels of output image
        let rgbaPlanesSrc = new cv.MatVector();
        cv.split(dst, rgbaPlanesSrc);
        deletes.push(rgbaPlanesSrc);
        // Get separately on R,G,B,A channels of mask image
        let rgbaPlanesMask = new cv.MatVector();
        cv.split(mask, rgbaPlanesMask);
        deletes.push(rgbaPlanesMask);

        // Get R G B channels from output image and alpha channel from mask
        // (Should be displaying only drawn an area of Contours)
        let rgbaPlanesNew = new cv.MatVector();
        deletes.push(rgbaPlanesSrc.get(0));
        rgbaPlanesNew.push_back(deletes[deletes.length-1]);
        deletes.push(rgbaPlanesSrc.get(1));
        rgbaPlanesNew.push_back(deletes[deletes.length-1]);
        deletes.push(rgbaPlanesSrc.get(2));
        rgbaPlanesNew.push_back(deletes[deletes.length-1]);
        deletes.push(rgbaPlanesMask.get(3));
        rgbaPlanesNew.push_back(deletes[deletes.length-1]);

        // Set new channels to source image
        cv.merge(rgbaPlanesNew, dst);
        deletes.push(rgbaPlanesNew);

        let canvas = document.createElement("canvas");

        cv.imshow(canvas, dst);
        deletes.forEach((del)=>del?.delete());

        return callback(canvas);
    };

    const i = new Image();
    i.onload = imageOnLoad;
    i.src = inImage as string;
};

const simplifyByArea = (points: any[], targetCount: number) => {
    
    const simplifyByAreaWithStartingPoints = (addedPoints: any[], remainingPoints: any[], remainingCount: number): any[] => {
        if (remainingCount === 1) {
            let polygon = addedPoints.concat(remainingPoints.slice(-1))
            return polygon
        } else {
            let polygon1 = simplifyByAreaWithStartingPoints(
                                addedPoints.concat(remainingPoints.slice(0,1)),
                                remainingPoints.slice(1),
                                remainingCount-1)
            if (remainingCount < remainingPoints.length) {
                let polygon2 = simplifyByAreaWithStartingPoints(
                                addedPoints,
                                remainingPoints.slice(1),
                                remainingCount
                    )
                let area1 = area(polygon1)
                let area2 = area(polygon2)
                
                if(area1>area2){
                    return polygon1
                } else {
                    return polygon2
                }
            } else {
                return polygon1
            }
        }
    }
    
    return simplifyByAreaWithStartingPoints(points.slice(0,1)/* HEAD*/, points.slice(1)/* TAIL */, targetCount - 1)
}




const getHomographyMatrix = (params: HomographyMatrixParams): HomographyMatrixResult => {
    const { w, h, input, output, inverted, width, height} = params;

    const cv = (window as any).cv;

    const convertedInputPoints = convertRelativePointsToActualPixel(w, h, input);

    const minOutPoint = getMinPoint(output);
    const maxOutPoint = getMaxPoint(output);

    const convertedOutputPoints = output.map((point: PointTuple) => {
        const xInd = inverted ? 1 : 0;
        const yInd = inverted ? 0 : 1;

        const x = ((point[xInd] - minOutPoint[xInd]) * (width || OUTPUT_IMAGE_WIDTH)) / (maxOutPoint[xInd] - minOutPoint[xInd]);
        const y = ((point[yInd] - minOutPoint[yInd]) * (height || OUTPUT_IMAGE_HEIGHT)) / (maxOutPoint[yInd] - minOutPoint[yInd]);

        return [x, y];
    });

   

    const srcLength = convertedInputPoints.length;
    const dstLength = convertedOutputPoints.length;
    
    
    const minLength = Math.min(srcLength,dstLength)
    
    
    const targetLength = minLength > 3 ? 4 : 3 
    
    
    // Apply Visvalingam–Whyatt algorithm to the polygons 
    // to cast them to either 4 point or 3-point simplified polygons
    
    let simplifiedInputPoints = simplifyByArea(convertedInputPoints.concat([convertedInputPoints[0]]),targetLength+1).slice(0,-1)
    
    let simplifiedOutputPoints = simplifyByArea(convertedOutputPoints.concat([convertedOutputPoints[0]]),targetLength+1).slice(0,-1)
    

    // let srcTri = cv.matFromArray(srcLength, 1, cv.CV_64FC2, convertedInputPoints.reduce(arrayReducer, []));
    // let dstTri = cv.matFromArray(dstLength, 1, cv.CV_64FC2, convertedOutputPoints.reduce(arrayReducer, []));
    //
    // const homographyMatrix = cv.findHomography(srcTri, dstTri, 0);
    
    // Find Homography or Affine transform for the 
    // simplified 3 or 4 point polygons 

    let srcTri = cv.matFromArray(targetLength, 1, simplifiedOutputPoints.length > 3 ? cv.CV_64FC2 : cv.CV_32FC2, simplifiedInputPoints.reduce(arrayReducer, []));
    let dstTri = cv.matFromArray(targetLength, 1, simplifiedOutputPoints.length > 3 ? cv.CV_64FC2 : cv.CV_32FC2, simplifiedOutputPoints.reduce(arrayReducer, []));

    const homographyMatrix = simplifiedOutputPoints.length > 3 ? cv.findHomography(srcTri, dstTri, 0) : cv.getAffineTransform( srcTri, dstTri );

    srcTri.delete();
    dstTri.delete();

    return {
        homographyMatrix: homographyMatrix,
        minOutPoint: minOutPoint,
        maxOutPoint: maxOutPoint,
        dstLength: targetLength,
        // Returning the original array of output points, not the simplified one
        convertedOutputPoints: convertedOutputPoints
    };
};

const getMinPoint = (bounds: LatLngTuple[]): LatLngTuple => {
    const [first, second] = getDetachedArraysFromPoints(bounds);

    return [Math.min(...first), Math.min(...second)];
};

const getMaxPoint = (bounds: LatLngTuple[]): LatLngTuple => {
    const [first, second] = getDetachedArraysFromPoints(bounds);

    return [Math.max(...first), Math.max(...second)];
};

const getDetachedArraysFromPoints = (bounds: LatLngTuple[]) => {
    const listFirst: number[] = [];
    const listSecond: number[] = [];

    bounds.forEach((point: LatLngTuple) => {
        listFirst.push(point[0]);
        listSecond.push(point[1]);
    });

    return [listFirst, listSecond];
};

const changeImageSize = (image: string | null, width: number, height: number, callback: Callback) => {
    const cv = (window as any).cv;

    if (!cv) {
        console.error("openCV is not defined");
        return callback(null);
    }

    if (!image) {
        return callback(null);
    }

    const imageOnLoad = () => {
        let src = cv.imread(i);
        let dst = new cv.Mat();
        let dsize = new cv.Size(Math.abs(width), Math.abs(height));

        cv.resize(src, dst, dsize, 0, 0, cv.INTER_AREA);

        let canvas = document.createElement("canvas");

        cv.imshow(canvas, dst);
        // const dataURL = canvas.toDataURL();
        callback(canvas);
        src.delete();
        dst.delete();
    };

    const i = new Image();
    i.onload = imageOnLoad;
    i.src = image as string;
};

const convertRelativePointsToActualPixel  = (w: number, h: number, points: PointTuple[]) => {
    return points.map((point: PointTuple) => {
        const x = point[0] * w;
        const y = point[1] * h;

        return [x, y];
    });
};

const getImageSize = (img: string): Promise<{w: number; h: number;}> => {
    return new Promise((resolved, rejected) => {
        const i = new Image();
        i.onload = () => {
            resolved({w: i.width, h: i.height});
        };
        i.onerror = (e) => {
            rejected(e);
        };

        i.src = img as string;
    });
};

const calculateRelativePointForDetectedObject = (bounds: PointTuple[]): PointTuple => {
    let maxXYPoint = bounds[0];
    let minXMaxYPoint = bounds[0];

    bounds.forEach((point: LatLngTuple) => {
        if (point[0] >= maxXYPoint[0] && point[1] >= maxXYPoint[1]) {
            maxXYPoint = point;
        }

        if (point[0] <= minXMaxYPoint[0] && point[1] >= minXMaxYPoint[1]) {
            minXMaxYPoint = point;
        }
    });

    const relativeX = ((minXMaxYPoint[0] + maxXYPoint[0]) / 2);
    const relativeY = ((minXMaxYPoint[1] + maxXYPoint[1]) / 2);

    return [relativeX , relativeY];
};

const getLocationDetectedObject = async (params: LocationDetectedObjectParams) => {
    const { relativePoint, inImage, inPoints, outPoints, invertedPoints } = params;

    const cv = (window as any).cv;

    if (!inImage || !Array.isArray(inPoints) || !Array.isArray(outPoints)) {
        return null;
    }

   if (outPoints.length < 3 || inPoints.length < 3) {
        return null;
    }

    if (!cv) {
        return null;
    }

    const { w, h } = await getImageSize(inImage);

    const point = [
        w * relativePoint[0],
        h * relativePoint[1],
        1
    ];

    let vector = cv.matFromArray(3, 1, cv.CV_64FC1, point);

    const homographyParams = {
        w: w,
        h: h,
        input: inPoints,
        output: outPoints,
        inverted: invertedPoints
    };

    let { homographyMatrix, minOutPoint, maxOutPoint } = getHomographyMatrix(homographyParams);

    // Calculate vector with image transformation matrix
    let outputVector = new cv.Mat();
    cv.gemm(homographyMatrix, vector, 1, new cv.Mat(), 0, outputVector, 0);

    // normalize calculated vector
    const normOutputVector = new cv.Mat();
    const n = 1 / outputVector.doubleAt(0, 2);
    let norm = cv.matFromArray(3, 1, cv.CV_64FC1, [n, n, n]);
    cv.multiply(outputVector, norm, normOutputVector);

    // calculate the actual point
    const xInd = invertedPoints ? 1 : 0;
    const yInd = invertedPoints ? 0 : 1;
    const x = normOutputVector.doubleAt(0, 0);
    const y = normOutputVector.doubleAt(0, 1);
    const outPointX = minOutPoint[xInd] + (x * (maxOutPoint[xInd] - minOutPoint[xInd])) / OUTPUT_IMAGE_WIDTH;
    const outPointY = minOutPoint[yInd] + (y * (maxOutPoint[yInd] - minOutPoint[yInd])) / OUTPUT_IMAGE_HEIGHT;

    const location = [];

    location[xInd] = outPointX;
    location[yInd] = outPointY;

    outputVector.delete();
    normOutputVector.delete();
    norm.delete();
    homographyMatrix.delete();

    return location as PointTuple;
};

export {
    transformImage,
    _transformImage,
    getMaxPoint,
    getMinPoint,
    changeImageSize,
    getLocationDetectedObject,
    calculateRelativePointForDetectedObject,
};
