import {HELP_MENU_ITEM, MENU_ITEM} from "../Constants";

export const MENU_ITEM_KEYS: string[] = [
    MENU_ITEM.CONSUMABLES, MENU_ITEM.BUILDINGS, MENU_ITEM.SITES, MENU_ITEM.FAVORITES, MENU_ITEM.HELP
];

export const HELP_MENU_ITEM_CONTENT: { [key: string]: string }[] = [
    {key: HELP_MENU_ITEM.USERS_GUIDES,      link: "https://kb.localmachines.io/users-guides"},
    {key: HELP_MENU_ITEM.LIMITATIONS,       link: "https://kb.localmachines.io/limitations"},
    {key: HELP_MENU_ITEM.ACKNOWLEDGEMENTS,  link: "https://kb.localmachines.io/acknowledgements"},
    {key: HELP_MENU_ITEM.WHATS_NEW,         link: "https://kb.localmachines.io/release-notes"},
    {key: HELP_MENU_ITEM.SUPPORT,           link: "https://support.localmachines.io"},
];

export const DEFAULT_PROPERTIES_BY_MACHINES = ["address", "image", "service_life"];
