import React, {useEffect, useState} from "react";
import {
    Box, Button, DialogContent, Grid, List, ListItem,
    ListItemText, Tooltip, useMediaQuery, useTheme
} from "@material-ui/core";
import NoEncryptionIcon from '@material-ui/icons/NoEncryption';
import HttpsIcon from '@material-ui/icons/Https';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import AccessLevelSelect from "../AccessLevelSelect/AccessLevelSelect";
import {useShareDialogStyles} from "../ShareDialogStyles";
import { PermissionsItem} from "../../../../Services/ObjectsService/Types";
import {AccessLevel, AccessType, OriginType} from "../../../../Services/ObjectsService/Constants";
import Actions from "../Actions/Actions";
import {getClientId, getUserId, postUsers} from "../../../../Services/UserService";
import { postObjectPermissions, saveObjectEncryptKeyForExistingUser } from "../../../../Services/ObjectsService/CRUD";
import {v4} from "uuid";
import {useGlobalNotificationContext} from "../../../Notification/NotificationProvider";
import type {SnackBarType} from "../../../../Services/NotificationService";
import {ListUsersPermissionsProps, UserPermissionItem} from "../ShareDialogTypes";
import ProgressComponent from "../../../Common/ProgressComponent/ProgressComponent";
import {asyncParallelLimit, isUserHasAccess, wait} from "../../../../Services/Utils";

const ListUsersPermissions = (props: ListUsersPermissionsProps) => {

    const {
        userAccessLevel,
        objectItem,
        loader,
        accessType,
        isAccessTypeChanged,
        corePermissions,
        listObjectId,
        addPeopleMode,
        setLoader,
        onClose
    } = props;

    const classes = useShareDialogStyles();

    const theme = useTheme();
    const downXs = useMediaQuery(theme.breakpoints.down("xs"));

    const notify: SnackBarType = useGlobalNotificationContext();

    const [userData, setUserData] = useState<UserPermissionItem[] | null>(props.userData);
    const [encryptedCount, setEncryptedCount] = useState<number>(0);
    const [permissionsSaveState, setPermissionsSaveState] = useState<"init" | "in_progress" | "completed">("init");
    const [saveDataMode, setSaveDataMode] = useState<boolean>(false);

    const onChange = (id: string, accessLevel: AccessLevel) => {
        setUserData((prev) => {
            if (!prev) {
                return prev;
            }
            return prev.map((user) => {
                if (user.user_id !== id) {
                    return user;
                }
                return {
                    ...user,
                    access_level: accessLevel,
                    updated: true
                }
            });
        });
    };

    const onClickAddPeople = () => {
        props.onClickAddPeople();
    };

    const onCloseClicked = () => {
        onClose();
    };

    const onAgreeClicked = async () => {

        // setLoader(true);
        setSaveDataMode(true);
        const epoch = Date.now();
        const clientId = getClientId();

        const updatedPrivateACL: {[k: string]: PermissionsItem} = {};
        const usersWithEncryption =  [];

        for (const user of userData || []) {
            if (user?.encryption?.public_key) {
                usersWithEncryption.push(user);
            }

            if (!user.is_owner) { // [IM] Excluding owner from private_acl
                if (!user.updated && corePermissions.private_acl[user.user_id]) {
                    // [IM] keep exist updated for user permission in private_acl
                    updatedPrivateACL[user.user_id] = {
                        access_level: corePermissions.private_acl[user.user_id].access_level ,
                        updated: corePermissions.private_acl[user.user_id].updated
                    };
                } else {
                    updatedPrivateACL[user.user_id] = {
                        access_level: user.access_level ,
                        updated: epoch
                    };
                }
            }
        }

        try {
            if (usersWithEncryption.length && userData) {
                await postUsers(userData);
            }

            if (Object.keys(updatedPrivateACL).length || isAccessTypeChanged) {
                const data: any = {
                    perm_id: corePermissions?.perm_id || v4(),
                    updated: corePermissions?.updated || 0,
                    private_acl: updatedPrivateACL
                };

                if (corePermissions?.public_access && corePermissions?.public_access?.access_level !== AccessLevel.REVOKED) {
                    data.public_access = {
                        access_level: AccessLevel.REVOKED,
                        updated: epoch
                    };
                }

                await asyncParallelLimit(listObjectId || [], 5, async (id) => {
                    try {
                        await saveObjectEncryptKeyForExistingUser(id, data, {origin: OriginType.USER});
                    } catch (e) {
                        console.error(e);
                    }
                    setEncryptedCount((prev) => {
                        return prev + 1;
                    });
                    return;
                });
                // await Promise.all((listObjectId || []).map(async (id) => {
                //     console.time(id);
                //     await saveObjectEncryptKeyForExistingUser(id, data, {origin: OriginType.USER});
                //     setEncryptedCount((prev) => {
                //         return prev + 1;
                //     });
                //     console.timeEnd(id);
                //     return;
                //
                // }));
                // for (const id of listObjectId || []) {
                //     try {
                //         console.time("1");
                //         await saveObjectEncryptKeyForExistingUser(id, data, {origin: OriginType.USER});
                //         setEncryptedCount((prev) => {
                //             return prev + 1;
                //         });
                //         console.timeEnd("1");
                //     } catch (e) {
                //         console.error(e);
                //     }
                // }

                setPermissionsSaveState("in_progress");
                await postObjectPermissions(objectItem.object_id, data, listObjectId,{
                    origin: OriginType.USER,
                    clientId: clientId
                });
                setPermissionsSaveState("completed");
                await wait(1500);
            }
        } catch (e: any) {
            console.error(e);
            notify?.errorNotify(e.message || e);
            setPermissionsSaveState("init");
            setSaveDataMode(false);
            setLoader(false);
        }

        onCloseClicked();
        // setLoader(false);
    };

    const onChangeAccessType = (value: AccessType) => {
        props.onChangeAccessType(value);
    };

    useEffect(() => {
        setUserData(props.userData || []);
    }, [props.userData]);

    if (saveDataMode) {
        return (<>
            <ProgressComponent variant="determinate" value={encryptedCount} max={listObjectId?.length} label={"Objects key encryption..."} endAdornmentVariant="shareDialog"/>
            {
                permissionsSaveState === "in_progress" && <ProgressComponent variant="query" label={"Save permissions data..."} endAdornmentVariant="shareDialog"/>
            }
            {
                permissionsSaveState !== "in_progress" &&
                <ProgressComponent variant="determinate" value={permissionsSaveState === "init" ? 0 : 100} max={100} label={"Save permissions data..."} endAdornmentVariant="shareDialog"/>
            }
        </>);
    }

    if (accessType !== AccessType.RESTRICTED || loader || addPeopleMode) {
        return null;
    }

    return (
        <>
            <DialogContent>
                <List>
                    {userData?.map((user) => (
                        <ListItem disableGutters divider key={user.user_id}>
                            <Grid container>
                                <Grid container item sm={8} xs={12}>
                                    <Grid item xs={11}>
                                        <ListItemText
                                            className={classes.marginRight}
                                            primary={`${user.firstname} ${user.lastname}${user.user_id === getUserId() ? ' (you)' : ''}`}
                                            secondary={user.email}
                                            primaryTypographyProps={{variant: downXs ? "body2" : "body1"}}
                                            secondaryTypographyProps={{variant: downXs ? "caption" : "body2"}}
                                        />
                                    </Grid>
                                    <Grid container item xs={1} direction="column" >
                                        <Box className={classes.iconContainer}>
                                            <Grid item>
                                                {user?.encryption?.public_key ?
                                                    <Tooltip title="Public Key exists" placement="left"><VpnKeyIcon fontSize="small"/></Tooltip> : null
                                                }
                                            </Grid>
                                            <Grid item>
                                                {user?.encryption?.object_key_encrypted ?
                                                    <Tooltip title="Object encrypted for user" placement="left"><HttpsIcon fontSize="small"/></Tooltip> :
                                                    <Tooltip title="Object NOT encrypted for user" placement="left"><NoEncryptionIcon fontSize="small"/></Tooltip>
                                                }
                                            </Grid>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid item sm={4} xs={12}>
                                    <AccessLevelSelect
                                        isOwner={!!user.is_owner}
                                        isWriter={isUserHasAccess(objectItem, AccessLevel.WRITE)}
                                        userAccessLevel={userAccessLevel}
                                        accessLevel={user.access_level}
                                        onChange={onChange.bind(null, user.user_id)}
                                    />
                                </Grid>
                            </Grid>
                        </ListItem>
                    ))}
                </List>
                {isUserHasAccess(objectItem, AccessLevel.WRITE) && (
                    <Grid container justifyContent={"center"}>
                        <Grid container item xs={8} sm={4} justifyContent={"center"}>
                            <Button
                                className={classes.button}
                                onClick={onClickAddPeople}
                                disabled={loader}
                                color="primary"
                                variant="contained"
                                size={downXs ? "small" : "medium"}
                                startIcon={<PersonAddIcon/>}
                            >
                                Add people
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </DialogContent>
            <Actions
                disabled={loader}
                isOwner={objectItem.owner_id === getUserId()}
                isWriter={isUserHasAccess(objectItem, AccessLevel.WRITE)}
                onClose={onCloseClicked}
                accessType={accessType}
                onAgree={onAgreeClicked}
                onChangeAccessType={onChangeAccessType}
            />
        </>
    );
};

export default ListUsersPermissions;
