import React from "react";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import {deleteUserClientById} from "../../../../../../../Services/UserService";
import {useHistory} from "react-router-dom";
import {SignOutProps} from "../../../../UserProfileTypes";
import {Button, Grid, useMediaQuery, useTheme} from "@material-ui/core";
import {useUserProfileStyles} from "../../../../UserProfileStyles";


const SignOut = (props: SignOutProps) => {
    const classes = useUserProfileStyles();
    const history = useHistory();
    const theme = useTheme();
    const downXs = useMediaQuery(theme.breakpoints.down("xs"));

    const {setLoader, client, userInfo, notify, loader} = props;

    const isDark = theme.palette.type === "dark"

    const signOutClient = async () => {
        setLoader(true);

        try {
            await deleteUserClientById(client.client_id);
            if (client.client_id === userInfo.clientId) {
                await userInfo.signOut();
                history.replace("/login")
            } else {
                userInfo.updateClients((userInfo?.user?.clients || []).filter((_client: any) => {
                    return _client.client_id !== client.client_id;
                }));
            }
            setLoader(false);
        } catch (e: any) {
            notify.errorNotify(e?.message || JSON.stringify(e));
            setLoader(false);
        }
    };

    return (
        <Grid container justifyContent="center">
            <Grid container item sm={2} xs={6}>
                <Button
                    className={classes.actionButton}
                    variant="outlined"
                    color={isDark ? "inherit" : "primary"}
                    size={downXs ? "small" : "large"}
                    startIcon={<ExitToAppIcon/>}
                    onClick={signOutClient}
                    fullWidth
                    disabled={loader}
                >
                    Sign out
                </Button>
            </Grid>
        </Grid>
    );
};

export default SignOut;