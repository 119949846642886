import React from "react";
import { Box, CircularProgress } from "@material-ui/core";

const HistoryLoaderContent = () => {
    return (
        <Box pt={2}>
            <CircularProgress/>
        </Box>
    )
}

export default HistoryLoaderContent;
