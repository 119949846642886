import ObjectsTreeDialog from "../../Dialogs/ObjectsTreeDialog/ObjectsTreeDialog";
import React, {useState, useCallback} from "react";
import {ObjectItem} from "../../../Services/ObjectsService/Types";
import {postObjectChildren} from "../../../Services/ObjectsService";
import type {SnackBarType} from "../../../Services/NotificationService";
import {useSubChildren} from "../../../Hooks/Subscriptions/useSubChildren";
import {OriginType} from "../../../Services/ObjectsService/Constants";
import {UserInfoType} from "../../../Hooks/useUserProfile";
import {useGlobalLoaderActionsContext} from "../../../GlobalContext/GlobalContext";

type ModalBindWithTreeProps = {
    show: boolean
    parentObject: ObjectItem;
    userInfo: UserInfoType;
    notify: SnackBarType;
    handleShowDialog(value: boolean): void;
}

const filterObjectsIds: string[] = [];

const ModalBindWithTree = (props: ModalBindWithTreeProps) => {
    const {
        show,
        handleShowDialog,
        parentObject,
        userInfo,
        notify,
    } = props;

    const setLoader = useGlobalLoaderActionsContext();

    const [listChildren, setListChildren] = useState<string[]>(Array.isArray(parentObject.children) ? parentObject.children : []);

    const bindChild = useCallback(async (object: ObjectItem | null) => {
        if (object?.object_id && !listChildren.includes(object.object_id)) {
            setLoader(true);

            try {
                await postObjectChildren(parentObject.object_id, object.object_id, {origin: OriginType.USER});
                console.log("Object updated successfully ", parentObject.object_id);
            } catch (err: any) {
                notify.errorNotify(err?.message || JSON.stringify(err));
            }

            setLoader(false);
        }

        handleShowDialog(false);
    }, [handleShowDialog, parentObject.object_id, listChildren]);

    useSubChildren(parentObject.children || [], parentObject.object_id, (data) => {
        setListChildren(data);
    });

    return (
        <ObjectsTreeDialog
            show={show}
            filterObjectsIds={filterObjectsIds}
            userInfo={userInfo}
            handleBindChild={bindChild}
        />
    )
};

export default ModalBindWithTree;
