import React from "react";
import { Icon, IconButton, Menu, MenuItem, Button, Typography } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { ObjectItem, rootControllers } from "../../../Services/ObjectsService";
import { Link, useRouteMatch, useLocation, matchPath } from "react-router-dom";
import {isUserHasAccess} from "../../../Services/Utils";
import {AccessLevel, pagesException, PLAN_RELATED_TYPES, OBJECT_TYPE} from "../../../Services/ObjectsService/Constants";
import { pubZoomInEvent, pubZoomOutEvent, pubSubmitEvent, pubShowDocumentStructureEvent } from "../../../Services/SystemEventsService";
import CreateChatPopup from "../PopupMenu/CreateChatPopup";

const useStyles = makeStyles(() =>
    createStyles({
        title: {
            flexGrow: 1,
        },
    })
);

type ObjectsGridNavbarToolsProps = {
    displayModalBind(state: boolean): void;
    displayModalDirect(state: boolean): void;
    displayModalShare(state: boolean): void;
    object: ObjectItem;
};

export default function ObjectsGridNavbarTools(props: ObjectsGridNavbarToolsProps) {
    const classes = useStyles();
    let location = useLocation();
    let { url } = useRouteMatch();

    const {
        displayModalBind,
        // object
    } = props;

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [anchorElConv, setAnchorElConv] = React.useState<null | HTMLElement>(null);

    const _locationPathname = location.pathname.replace(/(org\/[0-9a-z-]+\/)/, "");

    const controllerMatch: any = matchPath(_locationPathname, {
        path: ["/:controller"],
    });

    const pageTitle = React.useMemo(() => {
        if (controllerMatch) {
            switch (controllerMatch.url) {
                case "/machines":
                    return "Machines";
                case "/consumables":
                    return "Consumables";
                case "/sites":
                    return "Sites";
                case "/documents":
                    return "Documents";
                case "/files":
                    return "Files";
                case "/buildings":
                    return "Buildings";
                case "/organizations":
                    return "Organizations";
                case "/cameras":
                    return "Cameras";
                case "/favorites":
                    return "Favorites";
                case "/conversations":
                    return "Conversations";
                case "/shared-with-me":
                    return "Shared with me";
            }
        }
        return "";
    }, [controllerMatch]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClickConv = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElConv(event.currentTarget);
    };

    const handleClickAddPeople = () => {
        props.displayModalShare(true);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setAnchorElConv(null);
    };

    const handleOpenModalBind = () => {
        displayModalBind(true);
        handleClose();
    };

    const handleOpenModalDirect = () => {
        props.displayModalDirect(true);
        handleClose();
    };

    const checkFormShow = () => {
        return location.pathname.includes("edit") || location.pathname.includes("create-object");
    };

    const showPostObjectButton = () => {
        if (
            (PLAN_RELATED_TYPES.includes(props.object.object_type) && location.pathname.includes("/plan")) ||
            (props.object.object_type === "Site" && location.pathname.includes("/map")) ||
            (location.pathname.includes("/objects") && location.pathname.includes("/map"))
        ) {
            return isUserHasAccess(props.object, AccessLevel.WRITE) || location.pathname.includes("/objects") ? (
                <IconButton color="inherit" component={Link} to={`${location.pathname}/edit`}>
                    <Icon className={"fa fa-pencil-alt"} fontSize="small"/>
                </IconButton>
            ) : null;
        }

        if (props.object.object_type === OBJECT_TYPE.CONVERSATION && location.pathname.includes("/chat")) {
            return (
                <IconButton color="inherit" onClick={handleClickAddPeople}>
                    <Icon className={"fas fa-user-plus"} fontSize="small"/>
                </IconButton>
            );
        }

        if (
            (["Service", "IPCamera", "BackCamera", "FrontCamera"].includes(props.object.object_type) && !checkFormShow()) ||
            (location.pathname.includes("objects") && !props.object.object_type) ||
            (!rootControllers.includes(location.pathname.split('/').pop() as string) && location.pathname.includes("/conversations") && !checkFormShow()) ||
            pagesException.includes(location.pathname.split('/').pop() as string)
        ) {
            return null;
        }

        return (
            checkFormShow() ? (
                <Button color="inherit" onClick={pubSubmitEvent} disabled={!!props?.object?.object_id && !isUserHasAccess(props.object, AccessLevel.WRITE)}>
                    {props.object.object_id ? "Update" : "Create"}
                </Button>
            ) : !rootControllers.includes(location.pathname.split('/').pop() as string) ? (

                props.object.object_type === OBJECT_TYPE.DOCUMENT && location.pathname.includes("/page") && isUserHasAccess(props.object, AccessLevel.WRITE) ? (
                    <div>
                        <IconButton
                            color="inherit"
                            component={Link}
                            to={`${location.pathname}/edit`}
                        >
                            <Icon className={"fa fa-pencil-alt"} fontSize="small"/>
                        </IconButton>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="end"
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={handleClick}
                        >
                            <Icon className="fa fa-plus" fontSize="small"/>
                        </IconButton>
                    </div>
                ) : (
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="end"
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={handleClick}
                    >
                        <Icon className="fa fa-plus" fontSize="small"/>
                    </IconButton>
                )
            ) : rootControllers.includes(location.pathname.split('/').pop() as string) && location.pathname.includes("/conversations") ? (
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="end"
                    aria-controls="conv-menu"
                    aria-haspopup="true"
                    onClick={handleClickConv}
                >
                    <Icon className="fa fa-plus" fontSize="small"/>
                </IconButton>
            ) : (
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="end"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    component={Link} to={`${url}/create-object`}
                >
                    <Icon className="fa fa-plus" fontSize="small"/>
                </IconButton>
            )
        )
    };

    const showViewPlanAction = () => {
        if (location.pathname.includes("/plan")) {
            return (
                <React.Fragment>
                    <IconButton color="inherit" aria-label="open drawer" edge="end" onClick={pubZoomInEvent}>
                        <Icon className="fa fa-search-plus" fontSize="small"/>
                    </IconButton>
                    <IconButton color="inherit" aria-label="open drawer" edge="end" onClick={pubZoomOutEvent}>
                        <Icon className="fa fa-search-minus" fontSize="small"/>
                    </IconButton>
                </React.Fragment>
            );
        }

        return null;
    };

    const ShowDocumentStructure = () => {
        if (location.pathname.includes("/page")) {
            return (
                <React.Fragment>
                    <IconButton color="inherit" aria-label="open drawer" edge="end" onClick={pubShowDocumentStructureEvent}>
                        <Icon className="fab fa-elementor" fontSize="small"/>
                    </IconButton>
                </React.Fragment>
            );
        }
        return null;
    };

    return (
        <React.Fragment>
            {showViewPlanAction()}
            <ShowDocumentStructure/>

            <Typography variant="h6" noWrap className={classes.title}>
                {props.object?.object_id ? props.object.object_name : props.object?.object_type ? "New Object" : pageTitle}
            </Typography>

            {showPostObjectButton()}
            <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem component={Link} to={`${url}/create-object`} onClick={handleClose}>
                    Create New Object
                </MenuItem>
                {props.object.object_type && <MenuItem onClick={handleOpenModalBind}>Bind Existing Object</MenuItem>}
            </Menu>
            <CreateChatPopup
                anchorElConv={anchorElConv}
                handleClose={handleClose}
                handleOpenModalDirect={handleOpenModalDirect}
                handleCreateConversation={handleClose}
            />
        </React.Fragment>
    );
}
