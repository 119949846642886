import React, {ReactNode, useState} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import {convertValueToTargetMetricSystem} from "../../../../Helpers/SystemOfMeasures";
import type {SystemOfMeasuresType} from "../../../../Helpers/SystemOfMeasures";

type SliderComponentProps = {
    value: number;
    label: string;
    min?: number;
    max?: number;
    width?: number;
    units?: string;
    system_of_measures: SystemOfMeasuresType;
    handleSaveValue(value: number): void;
    isDisabled?: boolean;
    settings?: ReactNode;
};

export const SliderComponent = (props: SliderComponentProps) => {

    const {
        value,
        label,
        min,
        max,
        units,
        system_of_measures,
        handleSaveValue,
        isDisabled,
        settings
    } = props

    const [sliderComponentValue, setSliderComponentValue] = useState<number>(value);

    const handleChangeCommitted = (event: object, value: number | number[]) => {
        const _value: number = Array.isArray(value) ? value[0] : value;
        if (_value === sliderComponentValue) return
        handleSaveValue(_value);
        setSliderComponentValue(_value);
    };

    return (
        <Grid container direction="column" justifyContent="center">
            <Grid item container direction="row" justifyContent="center" alignItems="center">
                {settings}
                <Typography>{label}</Typography>
            </Grid>
            <Slider
                value={sliderComponentValue}
                aria-labelledby="discrete-slider-custom"
                step={1}
                valueLabelDisplay="auto"
                max={max}
                min={min}
                onChange={handleChangeCommitted}
                onChangeCommitted={handleChangeCommitted}
                disabled={isDisabled}
                valueLabelFormat={(x) => `${convertValueToTargetMetricSystem(system_of_measures, x, units)}`}
            />
        </Grid>
    );
};