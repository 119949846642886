import React, {Fragment, ReactNode, useCallback} from "react";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Popover from "@material-ui/core/Popover";
import {popoverPosition, useCommonStyles} from "../common/Common";
import {getValueWithUnits} from "../../../Helpers/SystemOfMeasures";
import {SliderComponent} from "./SliderComponent/SliderComponent";
import {withThrottle} from "../../../Helpers/Decorators";
import type {SystemOfMeasuresType} from "../../../Helpers/SystemOfMeasures";
import {makeStyles} from "@material-ui/core/styles";

type SliderControlProps = {
    value: number;
    label: string;
    propertyKey: string;
    min?: number;
    max?: number;
    width?: number;
    variant?: string;
    units?: string;
    system_of_measures: SystemOfMeasuresType;
    saveValue(key: string, value: string | number | boolean): void;
    isDisabled?: boolean;
    settings?: ReactNode;
};


const sliderControlVariants = ["control", "popover", "propertyCard", "marker"];

const useStyles = makeStyles(() => ({
    sliderValue: {
        minWidth: 33,
    },
}));


// TODO Break into smaller components according to display variants
export const SliderControl = (props: SliderControlProps) => {

    const {
        value,
        label,
        propertyKey,
        min,
        max,
        variant,
        units,
        system_of_measures,
        saveValue,
        isDisabled,
        settings
    } = props

    const commonClasses = useCommonStyles();
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const control_variant =
        !variant || !sliderControlVariants.includes(variant) ? "control" : variant;

    const handleSaveValue = (_value: number) => {
        saveValue(propertyKey, _value);
    };

    const handleSaveValueWithThrottle = useCallback(withThrottle(handleSaveValue, 250), [saveValue])


    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const displayPopoverSlider = Boolean(anchorEl);
    // const slider_id = displayPopoverSlider ? propertyKey : undefined;

    const getPopover = () => (
        <Popover
            id={"popover"}
            open={displayPopoverSlider}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            {...popoverPosition}
        >
            <Box width={"100%"} p={2}>
                <SliderComponent
                    value={value}
                    label={label}
                    min={min}
                    max={max}
                    units={units}
                    system_of_measures={system_of_measures}
                    handleSaveValue={handleSaveValueWithThrottle}
                    isDisabled={isDisabled}
                    settings={settings}
                />
            </Box>
        </Popover>
    )

    const getContextVariant = () => {
        return control_variant === "popover" ? (
            <Grid
                component="label"
                container
                alignItems="center"
                spacing={1}
                onClick={handlePopoverOpen}
                className={commonClasses.cursorPointer}
                wrap="nowrap"
            >
                <Grid item container direction="row" justifyContent="center" alignItems="center">
                    {settings}
                    <Box fontSize="small">{label}:</Box>
                </Grid>
                <Grid item>
                    <Box fontSize="small" className={classes.sliderValue}>
                        {getValueWithUnits(system_of_measures, value, units)}
                    </Box>
                </Grid>
            </Grid>
        ) : (
            <Typography noWrap gutterBottom variant="h3" onClick={handlePopoverOpen}>
                {getValueWithUnits(system_of_measures, value.toString(), units)}
            </Typography>
        )
    }


    if (control_variant === "control") {
        return (
            <React.Fragment>
                <SliderComponent
                    value={value}
                    label={label}
                    min={min}
                    max={max}
                    units={units}
                    system_of_measures={system_of_measures}
                    handleSaveValue={handleSaveValueWithThrottle}
                    isDisabled={isDisabled}
                    settings={settings}
                />
            </React.Fragment>
        );
    }

    if (control_variant === "marker") {
        return (
            <Fragment>
                <Typography noWrap gutterBottom variant="inherit" onClick={handlePopoverOpen}>
                    {getValueWithUnits(system_of_measures, value.toString(), units)}
                </Typography>
                {getPopover()}
            </Fragment>
        )

    }

    return (
        <Fragment>
            <Typography component="div">
                {getContextVariant()}
                {getPopover()}
            </Typography>
        </Fragment>
    )
}
