// TODO: Get rid of this class. Each element should be responsible for it's own visualisation
// Current Solution is temporary and is for demonstrational purposes only.
import { Path, Point } from "paper";
import { ElementType } from "./tools/Elements/AbstractElement";
import { hitOptionsAllPrecise, WALL_ELEMENT_TYPES } from "./Constants";
export class VisualisationController {
    private drawingLayer: any = null;
    private visualisationLayer: any = null;

    constructor(drawingLayer: any, visualisationLayer: any) {
        this.drawingLayer = drawingLayer;
        this.visualisationLayer = visualisationLayer;
    }

    private renderJoins(color: any, widthExtension: any) {
        this.drawingLayer.children.forEach((child: any) => {
            if (child.data.type === ElementType.SOLID_WALL) {
                let startPoint = new Point(child.segments[0].point);
                let endPoint = new Point(child.segments[1].point);

                let normal = child.getNormalAt(child.getOffsetOf(startPoint));
                let tangent = child.getTangentAt(child.getOffsetOf(startPoint));

                let joinPath = new Path([
                    new Point(
                        startPoint.x +
                            normal.x * (child.data.currentValues.thickness / 2 + widthExtension) -
                            tangent.x * widthExtension,
                        startPoint.y +
                            normal.y * (child.data.currentValues.thickness / 2 + widthExtension) -
                            tangent.y * widthExtension
                    ),
                    new Point(
                        endPoint.x +
                            normal.x * (child.data.currentValues.thickness / 2 + widthExtension) +
                            tangent.x * widthExtension,
                        endPoint.y +
                            normal.y * (child.data.currentValues.thickness / 2 + widthExtension) +
                            tangent.y * widthExtension
                    ),
                    new Point(
                        endPoint.x -
                            normal.x * (child.data.currentValues.thickness / 2 + widthExtension) +
                            tangent.x * widthExtension,
                        endPoint.y -
                            normal.y * (child.data.currentValues.thickness / 2 + widthExtension) +
                            tangent.y * widthExtension
                    ),
                    new Point(
                        startPoint.x -
                            normal.x * (child.data.currentValues.thickness / 2 + widthExtension) -
                            tangent.x * widthExtension,
                        startPoint.y -
                            normal.y * (child.data.currentValues.thickness / 2 + widthExtension) -
                            tangent.y * widthExtension
                    ),
                ]);
                joinPath.closed = true;
                joinPath.fillColor = color;
            }
        });

        this.drawingLayer.children.forEach((child1: any, child1index: any) => {
            if (child1.data.type === ElementType.SOLID_WALL) {
                this.drawingLayer.children.forEach((child2: any, child2index: any) => {
                    if (child2.data.type === ElementType.SOLID_WALL && child1 !== child2 && child2index > child1index) {
                        child2.segments.forEach((segment: any) => {
                            let hitResult = child1.hitTest(segment.point, hitOptionsAllPrecise);
                            if (hitResult) {
                                let oppositeSegment = child2.segments.find((segmentToFind: any) => {
                                    return segment !== segmentToFind;
                                });
                                child1.segments.forEach((child1Segment: any) => {
                                    if (child1Segment.point.getDistance(segment.point) > 10) {
                                        let middlePath1 = new Path([
                                            new Point(child1Segment.point),
                                            new Point(segment.point),
                                        ]);
                                        let middlePath2 = new Path([
                                            new Point(segment.point),
                                            new Point(oppositeSegment.point),
                                        ]);

                                        let child1normal = middlePath1.getNormalAt(
                                            middlePath1.getOffsetOf(child1Segment.point)
                                        );
                                        let child2normal = middlePath2.getNormalAt(
                                            middlePath2.getOffsetOf(oppositeSegment.point)
                                        );

                                        let child1tangent = middlePath1.getTangentAt(
                                            middlePath1.getOffsetOf(child1Segment.point)
                                        );
                                        let child2tangent = middlePath2.getTangentAt(
                                            middlePath2.getOffsetOf(oppositeSegment.point)
                                        );

                                        let angle = child1tangent.getAngle(child2tangent);
                                        // console.log(angle);
                                        let miterLimit =
                                            angle < 45 || angle > 135
                                                ? 0
                                                : Math.max(
                                                      child1.data.currentValues.thickness * 2,
                                                      child2.data.currentValues.thickness * 2
                                                  );

                                        let leftPath1 = new Path([
                                            new Point(
                                                child1Segment.point.x +
                                                    (child1normal.x * child1.data.currentValues.thickness) / 2 +
                                                    child1normal.x * widthExtension,
                                                child1Segment.point.y +
                                                    (child1normal.y * child1.data.currentValues.thickness) / 2 +
                                                    child1normal.y * widthExtension
                                            ),
                                            new Point(
                                                segment.point.x +
                                                    (child1normal.x * child1.data.currentValues.thickness) / 2 +
                                                    child1tangent.x *
                                                        (child1.data.currentValues.thickness / 2 +
                                                            widthExtension +
                                                            miterLimit) +
                                                    child1normal.x * widthExtension,
                                                segment.point.y +
                                                    (child1normal.y * child1.data.currentValues.thickness) / 2 +
                                                    child1tangent.y *
                                                        (child1.data.currentValues.thickness / 2 +
                                                            widthExtension +
                                                            miterLimit) +
                                                    child1normal.y * widthExtension
                                            ),
                                        ]);
                                        let leftPath2 = new Path([
                                            new Point(
                                                segment.point.x +
                                                    (child2normal.x * child2.data.currentValues.thickness) / 2 +
                                                    child2normal.x * widthExtension -
                                                    child2tangent.x *
                                                        (child2.data.currentValues.thickness / 2 +
                                                            widthExtension +
                                                            miterLimit),
                                                segment.point.y +
                                                    (child2normal.y * child2.data.currentValues.thickness) / 2 +
                                                    child2normal.y * widthExtension -
                                                    child2tangent.y *
                                                        (child2.data.currentValues.thickness / 2 +
                                                            widthExtension +
                                                            miterLimit)
                                            ),
                                            new Point(
                                                oppositeSegment.point.x +
                                                    (child2normal.x * child2.data.currentValues.thickness) / 2 +
                                                    child2normal.x * widthExtension,
                                                oppositeSegment.point.y +
                                                    (child2normal.y * child2.data.currentValues.thickness) / 2 +
                                                    child2normal.y * widthExtension
                                            ),
                                        ]);

                                        let rightPath1 = new Path([
                                            new Point(
                                                child1Segment.point.x -
                                                    (child1normal.x * child1.data.currentValues.thickness) / 2 -
                                                    child1normal.x * widthExtension,
                                                child1Segment.point.y -
                                                    (child1normal.y * child1.data.currentValues.thickness) / 2 -
                                                    child1normal.y * widthExtension
                                            ),
                                            new Point(
                                                segment.point.x -
                                                    (child1normal.x * child1.data.currentValues.thickness) / 2 -
                                                    child1normal.x * widthExtension +
                                                    child1tangent.x *
                                                        (child1.data.currentValues.thickness / 2 +
                                                            widthExtension +
                                                            miterLimit),
                                                segment.point.y -
                                                    (child1normal.y * child1.data.currentValues.thickness) / 2 -
                                                    child1normal.y * widthExtension +
                                                    child1tangent.y *
                                                        (child1.data.currentValues.thickness / 2 +
                                                            widthExtension +
                                                            miterLimit)
                                            ),
                                        ]);
                                        let rightPath2 = new Path([
                                            new Point(
                                                segment.point.x -
                                                    (child2normal.x * child2.data.currentValues.thickness) / 2 -
                                                    child2normal.x * widthExtension -
                                                    child2tangent.x *
                                                        (child2.data.currentValues.thickness / 2 +
                                                            widthExtension +
                                                            miterLimit),
                                                segment.point.y -
                                                    (child2normal.y * child2.data.currentValues.thickness) / 2 -
                                                    child2normal.y * widthExtension -
                                                    child2tangent.y *
                                                        (child2.data.currentValues.thickness / 2 +
                                                            widthExtension +
                                                            miterLimit)
                                            ),
                                            new Point(
                                                oppositeSegment.point.x -
                                                    (child2normal.x * child2.data.currentValues.thickness) / 2 -
                                                    child2normal.x * widthExtension,
                                                oppositeSegment.point.y -
                                                    (child2normal.y * child2.data.currentValues.thickness) / 2 -
                                                    child2normal.y * widthExtension
                                            ),
                                        ]);

                                        let leftIntersections = leftPath1.getIntersections(leftPath2);
                                        let rightIntersections = rightPath1.getIntersections(rightPath2);

                                        let joinPathPoints = [];

                                        joinPathPoints.push(
                                            new Point(
                                                oppositeSegment.point.x +
                                                    child2normal.x *
                                                        (child2.data.currentValues.thickness / 2 + widthExtension),
                                                oppositeSegment.point.y +
                                                    child2normal.y *
                                                        (child2.data.currentValues.thickness / 2 + widthExtension)
                                            )
                                        );
                                        joinPathPoints.push(
                                            new Point(
                                                segment.point.x +
                                                    child2normal.x *
                                                        (child2.data.currentValues.thickness / 2 + widthExtension),
                                                segment.point.y +
                                                    child2normal.y *
                                                        (child2.data.currentValues.thickness / 2 + widthExtension)
                                            )
                                        );
                                        if (leftIntersections.length > 0) {
                                            joinPathPoints.push(
                                                new Point(leftIntersections[0].point.x, leftIntersections[0].point.y)
                                            );
                                        } else {
                                        }
                                        joinPathPoints.push(
                                            new Point(
                                                segment.point.x +
                                                    child1normal.x *
                                                        (child1.data.currentValues.thickness / 2 + widthExtension),
                                                segment.point.y +
                                                    child1normal.y *
                                                        (child1.data.currentValues.thickness / 2 + widthExtension)
                                            )
                                        );
                                        joinPathPoints.push(
                                            new Point(
                                                child1Segment.point.x +
                                                    child1normal.x *
                                                        (child1.data.currentValues.thickness / 2 + widthExtension),
                                                child1Segment.point.y +
                                                    child1normal.y *
                                                        (child1.data.currentValues.thickness / 2 + widthExtension)
                                            )
                                        );
                                        joinPathPoints.push(
                                            new Point(
                                                child1Segment.point.x -
                                                    child1normal.x *
                                                        (child1.data.currentValues.thickness / 2 + widthExtension),
                                                child1Segment.point.y -
                                                    child1normal.y *
                                                        (child1.data.currentValues.thickness / 2 + widthExtension)
                                            )
                                        );

                                        joinPathPoints.push(
                                            new Point(
                                                segment.point.x -
                                                    child1normal.x *
                                                        (child1.data.currentValues.thickness / 2 + widthExtension),
                                                segment.point.y -
                                                    child1normal.y *
                                                        (child1.data.currentValues.thickness / 2 + widthExtension)
                                            )
                                        );
                                        if (rightIntersections.length > 0) {
                                            joinPathPoints.push(
                                                new Point(rightIntersections[0].point.x, rightIntersections[0].point.y)
                                            );
                                        } else {
                                        }
                                        joinPathPoints.push(
                                            new Point(
                                                segment.point.x -
                                                    child2normal.x *
                                                        (child2.data.currentValues.thickness / 2 + widthExtension),
                                                segment.point.y -
                                                    child2normal.y *
                                                        (child2.data.currentValues.thickness / 2 + widthExtension)
                                            )
                                        );
                                        joinPathPoints.push(
                                            new Point(
                                                oppositeSegment.point.x -
                                                    child2normal.x *
                                                        (child2.data.currentValues.thickness / 2 + widthExtension),
                                                oppositeSegment.point.y -
                                                    child2normal.y *
                                                        (child2.data.currentValues.thickness / 2 + widthExtension)
                                            )
                                        );

                                        let joinPath = new Path(joinPathPoints);
                                        //console.log('Render Path', joinPath);
                                        //let joinPath = new Path([new Point(oppositeSegment.point),new Point(segment.point), new Point(child1Segment.point) ]);
                                        //joinPath.strokeWidth = child1.data.currentValues.thickness<child2.data.currentValues.thickness?child1.data.currentValues.thickness+widthExtension*2:child2.data.currentValues.thickness+widthExtension*2;
                                        joinPath.fillColor = color;
                                        joinPath.miterLimit = 2;
                                        joinPath.closed = true;
                                    }
                                });
                            }
                        });
                    }
                });
            }
        });

        this.drawingLayer.children.forEach((child: any) => {
            if (WALL_ELEMENT_TYPES.includes(child.data.type)) {
                //console.log('clonning window');
                let cloneWindow = child.clone();
                this.visualisationLayer.addChild(cloneWindow);
            }
        });
    }

    public drawJoins() {
        this.visualisationLayer.removeChildren();
        this.visualisationLayer.activate();

        this.renderJoins("black", 1);
        this.renderJoins("grey", 0);

        this.drawingLayer.activate();
    }
}
