import React, {createContext, useContext, useEffect} from 'react';
import {useHistory} from "react-router-dom";
import {v4} from "uuid";
import SnackBarMessage from "./SnackBarMessage";
import {useNotify} from "../../Hooks/useNotify";
import {
    NOTIFICATION_TYPE,
    SUBSCRIPTION_TYPE,
    APN_NOTIFICATION_TYPE,
    NotificationPayload,
    ServiceNotificationsCallbackType,
    subNotification,
    unSubNotification
} from "../../Services/NotificationService";

// Global NOTIFICATION
const GlobalNotificationContext = createContext<any>(undefined)
const useGlobalNotificationContext = () => useContext(GlobalNotificationContext)

type NotificationProviderProps = {
    children: React.ReactNode
}

const NotificationProvider = (props: NotificationProviderProps) => {
    const notify = useNotify();
    const history = useHistory();

    const showServiceNotification: ServiceNotificationsCallbackType = (data) => {
        const {type, notification_payload, message} = data;

        if (notification_payload?.object_id && window.location.pathname.includes(notification_payload.object_id)) {
            // [IM] Suppressing notification for opened object
            return;
        }

        if (type === NOTIFICATION_TYPE.SUCCESS) {
            notify.successNotify(message, notification_payload);
        }

        if (type === NOTIFICATION_TYPE.ERROR) {
            notify.errorNotify(message);
        }

        if (type === NOTIFICATION_TYPE.WARNING) {
            notify.warningNotify(message);
        }
    };

    const handleRemoteNotification = (notification: NotificationPayload) => {
        if (notification.type === APN_NOTIFICATION_TYPE.PROPERTY) {
            const object_id = notification.object_id;
            history.push(`/objects/${object_id}`);
        } else if (notification.type === APN_NOTIFICATION_TYPE.CHAT_MESSAGE) {
            const object_id = notification.object_id;
            history.push(`/conversations/${object_id}/chat`);
        } else if (notification.type === APN_NOTIFICATION_TYPE.OBJECT) {
            const parent_id = notification.parent_id;
            const child_id = notification.child_id;
            history.push(`/objects/${parent_id}/${child_id}`);
        }
    };

    useEffect(() => {
        const subscriptionId = v4();

        subNotification(subscriptionId, showServiceNotification, SUBSCRIPTION_TYPE.SERVICE_NOTIFICATION);
        subNotification(subscriptionId, handleRemoteNotification, SUBSCRIPTION_TYPE.APN_NOTIFICATION);

        return () => {
            unSubNotification(subscriptionId, SUBSCRIPTION_TYPE.SERVICE_NOTIFICATION);
            unSubNotification(subscriptionId, SUBSCRIPTION_TYPE.APN_NOTIFICATION);
        }
    }, [])

    return (
        <GlobalNotificationContext.Provider value={notify}>
            <SnackBarMessage toast={notify.toast} dismiss={notify.removeNotify}/>
            {props.children}
        </GlobalNotificationContext.Provider>
    )
}

export {NotificationProvider, useGlobalNotificationContext};
