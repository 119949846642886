import React, {useRef, useState} from "react";
import {Button, Dialog, DialogContent, DialogActions} from "@material-ui/core";
import {v4} from "uuid";
import DialogTitle from "../DialogUtils/DialogTitle";
import DialogLoader from "../DialogUtils/DialogLoader";
import {matchPath, useHistory, useLocation} from "react-router-dom";
import {asyncParallelLimit, copy, isEmail, generatePropertyItem} from "../../../Services/Utils";
import {useGlobalNotificationContext} from "../../Notification/NotificationProvider";
import type {SnackBarType} from "../../../Services/NotificationService";
import {getClientId, getUsers, getUserId} from "../../../Services/UserService";
import {BodyObjectItem, Permissions} from "../../../Services/ObjectsService/Types";
import {AccessLevel, EMPTY_OBJECT, OBJECT_TYPE, OriginType} from "../../../Services/ObjectsService/Constants";
import {
    getObjectPermissions,
    postObject,
    postObjectPermissionsInvite,
    saveObjectEncryptKeyForExistingUser
} from "../../../Services/ObjectsService/CRUD";
import {getListAllowedForSharingObjectIds} from "../../../Services/ObjectsService";
import AddPeopleDropdown from "../../Common/AddPeopleDropdown/AddPeopleDropdown";
import {UserInfoType} from "../../../Hooks/useUserProfile";

type AddPeopleDialogProps = {
    open: boolean;
    userInfo: UserInfoType;
    handleCloseDialog(): void;
};

const AddPeopleDialog = (props: AddPeopleDialogProps) => {

    const {
        open,
        userInfo,
        handleCloseDialog,
    } = props;

    const history = useHistory();
    const location = useLocation();

    let { params }: any = matchPath(`/${location.pathname?.replace(/(\/(plan\/edit|map\/edit|edit|plan|map|chat)$)/, "")}`, [
        "(.*)/:id",
    ]) || { params: {} };

    const notify: SnackBarType = useGlobalNotificationContext();

    const [addedEmails, setAddedEmails] = useState<string[]>([]);
    const [emailsInput, setEmailsInput] = useState<string>("");
    const [loader, setLoader] = useState<boolean>(false);

    const usersDict = useRef<{ [key: string]: string }>({});

    const buildDirectChat = (emails: string[], now_ts: number) => {
        let conversationTitle = userInfo?.user ? userInfo.user?.firstname + " " + userInfo.user?.lastname : userInfo.user?.email;
        emails.forEach((email_item) => {
            if (email_item) {
                conversationTitle = conversationTitle + "," + (usersDict.current[email_item] || email_item);
            }
        });

        const directChat: BodyObjectItem = {
            ...EMPTY_OBJECT,
            object_name: conversationTitle,
            object_type: OBJECT_TYPE.CONVERSATION,
            properties: {
                chat_message: generatePropertyItem({
                    key: "chat_message",
                    name: "Chat Message",
                    type: "ChatMessage",
                    value: {
                        text: "",
                        timestamp: now_ts,
                        data: {},
                        user_id: getUserId()
                    },
                    visibility: ["card", "details", "parent", "title"],
                }),
                image: {
                    property_id: v4(),
                    key: "image",
                    name: "Image",
                    readable: true,
                    type: "Image",
                    value: "",
                    writable: false,
                    visibility: ["card", "details", "parent", "title"],
                    persistent: true
                }
            }
        };

        return directChat;
    };

    const handleAddPeopleToChat = async (create_chat: boolean) => {
        setLoader(true);

        let _emails: string[] = copy(addedEmails);

        if (emailsInput && isEmail(emailsInput) && userInfo?.user?.email !== emailsInput && (!addedEmails || !addedEmails.includes(emailsInput))) {
            _emails = [...addedEmails, emailsInput];
        }

        const _now = Date.now();
        let _object_id: string = params?.id;
        let _list_object_id: string[] = [];

        try {
            if (create_chat) {
                const objectToSave: BodyObjectItem = buildDirectChat(_emails, _now);

                const res = await postObject(objectToSave, {
                    origin: OriginType.USER,
                    clientId: getClientId()
                });

                _object_id = res?.object_id;
                _list_object_id = [_object_id];
            } else {
                const res = await getObjectPermissions(_object_id);
                res.list_object_id = await getListAllowedForSharingObjectIds(res?.list_object_id || []);

                if (Array.isArray(res?.list_object_id) && !res?.list_object_id?.includes(_object_id)) {
                    res.list_object_id.push(_object_id);
                } else if (!Array.isArray(res?.list_object_id)) {
                    res.list_object_id = [_object_id];
                }

                _list_object_id = res.list_object_id;
            }

            if (_object_id && _emails.length) {
                const users = await getUsers({email_list: _emails});

                const tempPermissions: Permissions = {
                    perm_id: v4(),
                    updated: _now,
                    private_acl: {}
                };

                for (const user of users || []) {
                    if (!user?.id || !tempPermissions?.private_acl) {
                        continue;
                    }
                    tempPermissions.private_acl[user.id] = {
                        updated: _now,
                        access_level: AccessLevel.WRITE
                    }
                }

                await asyncParallelLimit(_list_object_id || [], 5, async (obj_id) => {
                    try {
                        await saveObjectEncryptKeyForExistingUser(obj_id, tempPermissions, {origin: OriginType.USER});
                    } catch (e) {
                        console.error(e);
                    }

                    return;
                });

                await postObjectPermissionsInvite(
                    _object_id,
                    AccessLevel.WRITE,
                    _emails,
                    "",
                    _list_object_id,
                    {
                        origin: OriginType.USER,
                        clientId: getClientId()
                    }
                );
            }

            setLoader(false);
            handleCloseDialog();

            if (create_chat && _object_id) {
                history.push("/conversations/" + _object_id + "/chat");
            }
        } catch (e: any) {
            notify.errorNotify(e?.message || JSON.stringify(e));
            setLoader(false);
        }
    };

    // const addMode = location.pathname.endsWith('/chat') && params.id;
    const addMode = false; // [IM] temporary blocked to always create a new chat while opening this dialog

    return (
        <Dialog
            open={open}
            onClose={handleCloseDialog}
            aria-labelledby="add-people-title"
            aria-describedby="add-people-description"
            fullWidth={true}
            maxWidth={"sm"}
        >
            <DialogTitle
                titleText={'Add People'}
                handleCloseDialog={handleCloseDialog}
            />
            <DialogLoader state={loader}/>
            {!loader && <DialogContent>
                <AddPeopleDropdown
                    usersDict={usersDict}
                    addedEmails={addedEmails}
                    setAddedEmails={setAddedEmails}
                    emailsInput={emailsInput}
                    setEmailsInput={setEmailsInput}
                />
            </DialogContent>}
            <DialogActions>
                <Button onClick={handleCloseDialog} variant="outlined" color="secondary">
                    {"Cancel"}
                </Button>
                {addMode ? (
                    <Button onClick={handleAddPeopleToChat.bind(null, false)} variant="contained" color="primary" disabled={loader || (!emailsInput && !addedEmails.length)}>
                        {"Add"}
                    </Button>
                ) : (
                    <Button onClick={handleAddPeopleToChat.bind(null, true)} variant="contained" color="primary" disabled={loader || (!emailsInput && !addedEmails.length)}>
                        {"Chat"}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default AddPeopleDialog;
