import React, {Fragment} from "react";
import {ZoneElement} from "../tools/Elements/objects/ZoneElement";
import {ObjectElement} from "../tools/Elements/objects/ObjectElement";
import {ObjectItem} from "../../../Services/ObjectsService";
import PlanPointChild from "./PlanPointChild/PlanPointChild";
import {PlanViewController} from "../PlanViewController";
import {CanvasOffsetProps} from "../TypesPlanView";
import {PlanEditController} from "../PlanEditController";
import {UserInfoType} from "../../../Hooks/useUserProfile";

type ChildrenOnPlanProps = {
    planMarkers?: any[]
    object_items?: ObjectItem[]
    planItem: PlanViewController | PlanEditController | null
    canvasOffset: CanvasOffsetProps
    planZoom: number
    optimizedZoom: number
    editMode: boolean
    userInfo: UserInfoType
    handleUpdateSelectedElementId(element_id: string | null): void
    openDetails?(): void
};

const ChildrenOnPlan = (props: ChildrenOnPlanProps) => {

    const {
        object_items,
        planItem,
        canvasOffset,
        planZoom,
        optimizedZoom,
        editMode,
        userInfo,
        handleUpdateSelectedElementId,
        openDetails
    } = props

    return (
        <Fragment>
            {planItem?.getAllMarkedObjects().map((child) => {
                const item = child.getPaperJSItem()
                let centerPoint: { x: number, y: number } | undefined = undefined

                if (child instanceof ZoneElement) {
                    const polygon_points: [number, number] = Object.values(item.data.properties.plan_polygon.value)[0] as [number, number]
                    if (polygon_points.length > 2) {
                        const {x, y} = item.bounds.center
                        centerPoint = {x, y}
                    }
                } else if (child instanceof ObjectElement) {
                    if (typeof item.data.properties.plan_point.value !== 'undefined') {
                        const plan_point = item.data.properties.plan_point.value[child.getParentId()]
                        if (plan_point) {
                            centerPoint = {x: plan_point[0], y: plan_point[1]}
                        }
                    }
                }
                const object_item = object_items?.find((elem: ObjectItem) => elem.object_id === item.data.id)

                return (
                    <Fragment
                        key={item.data.id}
                    >
                        {centerPoint && (
                            <PlanPointChild
                                object_id={item.data.id}
                                object_type={item.data.object_type}
                                object_name={item.data.info.object_name}
                                object_icon={item.data.icon}
                                object_item={object_item}
                                centerPointX={centerPoint.x}
                                centerPointY={centerPoint.y}
                                planItem={planItem}
                                canvasOffset={canvasOffset}
                                planZoom={planZoom}
                                optimizedZoom={optimizedZoom}
                                userInfo={userInfo}
                                editMode={editMode}
                                handleUpdateSelectedElementId={handleUpdateSelectedElementId}
                                openDetails={openDetails}
                            />
                        )}
                    </Fragment>
                )
            })}
        </Fragment>
    )
};

export default ChildrenOnPlan
