import React, {Fragment, useCallback, useEffect, useRef, useState} from "react";
import {PlanViewController} from "../FloorPlanEditor/PlanViewController";
import {
    getExistingObjectIds,
    getObjects,
    isObjectAvailableToDisplay,
    ObjectItem,
    Params
} from "../../Services/ObjectsService";
import {CanvasOffsetProps, PlanViewProps} from "../FloorPlanEditor/TypesPlanView";
import ChildrenOnPlan from "../FloorPlanEditor/ChildrenOnPlan/ChidrenOnPlan";
import {BASE_PLAN_ELEMENTS} from "../MarkerLabel/MarkerUtils";
import {useTheme} from "@material-ui/core";
import {withDebounce} from "../../Helpers/Decorators";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Point} from "../Common/Point";
import {distance} from "../Common/Distance";
import useForceUpdate from "../../Hooks/ForceUpdate/useForseUpdate";
import {getObjectById} from "../../Services/ObjectsService/CRUD";
import {useSubChildren} from "../../Hooks/Subscriptions/useSubChildren";
import {useSubZoomIn, useSubZoomOut} from "../../Hooks/SystemEvent/Toolbar/useToolbar";

const useStyles = makeStyles((theme: Theme) => createStyles({
    planBgColor: {
        backgroundColor: theme.palette.type === "dark" ? theme.palette.background.paper : theme.palette.background.default,
    }
}));

export const PlanViewComponent = (props: PlanViewProps) => {

    const {
        planViewItem,
        objectChildren,
        notify,
        objectId,
        setPlanViewItem,
        displayLoader,
        planZoom,
        userInfo,
        handleUpdateSelectedElementId,
        handleZoomUpdate,
        openDetails
    } = props;

    const forceUpdate = useForceUpdate();
    const theme = useTheme();
    const classes = useStyles();

    const [canvasOffset, setCanvasOffset] = useState<CanvasOffsetProps>({x: 0, y: 0});

    const canvasPlan = useRef<any>(null)
    const canvasMovable = useRef<boolean>(false)

    let _planViewItem = useRef<any>(null);

    const [objectsWithMarker, setObjectsWithMarker] = useState<ObjectItem[]>([])

    const [prevPredictedZoomChange, setPrevPredictedZoomChange] = useState<number>(0)
    const [optimizedZoom, setOptimizedZoom] = useState<number>(0)

    const prevTouchDistance = useRef<number>(0)

    const systemOfMeasures = userInfo.user?.system_of_measures || "imperial";

    useEffect(() => {
        const zoom = (planZoom % 0.25 > 0) ? parseFloat(
            (planZoom - planZoom % 0.25).toFixed(2)) : planZoom
        setOptimizedZoom(zoom)
        updateCanvasOffset();
    }, [planZoom])

    useEffect(() => {
        if (!objectId) {
            return;
        }

        (async () => {
            const _object = await getObjectById(objectId);
            const listChildren = await getExistingObjectIds(_object.children);
            void displayChildrenOnPlan(listChildren);
        })();

        return () => {
            setPlanViewItem(null);
        };
    }, [objectId]);

    useEffect(() => {
        if (!planViewItem) {
            return;
        }

        _planViewItem.current = planViewItem;
        _planViewItem.current.moveCanvasToCenter();
        handleZoomUpdate();
        updateCanvasOffset();

        return () => {
            if (planViewItem) {
                planViewItem.removePlanObjects();
            }
        };
    }, [planViewItem]);

    useSubChildren(objectChildren || [], objectId, (data) => {
        void displayChildrenOnPlan(data);
    });

    const displayChildrenOnPlan = async (listChildren: string[]) => {
        let _new_view_plan = false;

        if (_planViewItem.current) {
            _planViewItem.current.removePlanObjects();
        } else {
            _planViewItem.current = new PlanViewController(canvasPlan.current, theme.palette.type, systemOfMeasures, forceUpdate, objectId);
            _new_view_plan = true;
        }

        if (!Array.isArray(listChildren) || listChildren.length === 0) {
            if (_new_view_plan) {
                setPlanViewItem(_planViewItem.current);
            }

            return;
        }

        displayLoader(true);

        try {
            const params: Params = {
                children: listChildren
            };
            const res = await getObjects(params);
            const filtered = res.filter((item: ObjectItem) => {
                if (!isObjectAvailableToDisplay(item)) {
                    return false;
                }

                if (!item.properties) {
                    return false;
                }

                const keys = Object.keys(item.properties);

                return (keys.includes("plan_polygon") || keys.includes("plan_point"));
            });

            _planViewItem.current.loadPlanObjects(filtered);

            setObjectsWithMarker(filtered.filter((item: ObjectItem) => !BASE_PLAN_ELEMENTS.includes(item.object_type)));
        } catch (err: any) {
            notify.errorNotify(err?.message || JSON.stringify(err));
        }

        if (_new_view_plan) {
            setPlanViewItem(_planViewItem.current);
        }

        displayLoader(false);
    };

    const updateCanvasOffset = () => {
        if (planViewItem) {
            const bounds = planViewItem.getViewBounds()
            const newOffset = {x: bounds.x, y: bounds.y}
            if (newOffset.x !== canvasOffset.x || newOffset.y !== canvasOffset.y) {
                setCanvasOffset(newOffset)
                forceUpdate()
            }
        }
    };

    const handlePinchChange = (e: React.TouchEvent) => {
        const p1: Point = {x: e.touches[0].clientX, y: e.touches[0].clientY},
            p2: Point = {x: e.touches[1].clientX, y: e.touches[1].clientY}

        const newTouchDistance = distance(p1, p2)
        const scale = newTouchDistance / prevTouchDistance.current

        const currentPredictedZoomChange = parseFloat((Math.log(scale) / Math.LN2 / 2).toFixed(2))
        const predictedZoomChange = currentPredictedZoomChange - prevPredictedZoomChange

        setPrevPredictedZoomChange(currentPredictedZoomChange)

        prevTouchDistance.current = newTouchDistance

        planViewItem?.changeCurrentZoom(predictedZoomChange)
        handleZoomUpdate()

        updateCanvasOffset()
    }

    const handlePinchEnd = () => {
        planViewItem?.setViewElementToolPinchingEnd(true)

        setPrevPredictedZoomChange(0)
        canvasPlan.current.ontouchmove = null
        canvasPlan.current.ontouchend = null

        updateCanvasOffset()
    }

    const handleOnMouseDown = (e: any) => {
        const selectedElementId = planViewItem?.getSelectedElement()?.getId()
        handleUpdateSelectedElementId(selectedElementId)

        if (e.touches && e.touches.length === 2 && planViewItem) {

            const p1: Point = {x: e.touches[0].clientX, y: e.touches[0].clientY},
                p2: Point = {x: e.touches[1].clientX, y: e.touches[1].clientY}

            canvasPlan.current.ontouchmove = handlePinchChange
            canvasPlan.current.ontouchend = handlePinchEnd

            prevTouchDistance.current = distance(p1, p2)
        }

        canvasMovable.current = true
    }

    const handleOnMouseMove = () => {
        if (canvasMovable.current) {
            updateCanvasOffset();
        }
    }

    const handleOnMouseUp = () => {
        canvasMovable.current = false
        updateCanvasOffset();
    }

    const handleOnWheel = (e: any) => {
        if (planViewItem && Math.abs(e.deltaY) > 0) {
            e.deltaY < 0 ? planViewItem.zoomIn() : planViewItem.zoomOut()
            handleZoomUpdate()
        }
    }

    const handleOnWheelWithThrottle = useCallback(withDebounce(handleOnWheel, 500), [planViewItem])

    useSubZoomIn(() => {
        if (planViewItem) {
            planViewItem.zoomIn();
            handleZoomUpdate();
        }
    });

    useSubZoomOut( () => {
        if (planViewItem) {
            planViewItem.zoomOut();
            handleZoomUpdate();
        }
    });

    return (
        <Fragment>
            {objectsWithMarker && (
                <ChildrenOnPlan
                    object_items={objectsWithMarker}
                    planItem={planViewItem}
                    canvasOffset={canvasOffset}
                    planZoom={planZoom}
                    optimizedZoom={optimizedZoom}
                    editMode={false}
                    userInfo={userInfo}
                    handleUpdateSelectedElementId={handleUpdateSelectedElementId}
                    openDetails={openDetails}
                />
            )}
            {/*<DoubleTap*/}
            <canvas
                ref={canvasPlan}
                data-paper-resize
                className={classes.planBgColor}
                onWheel={handleOnWheelWithThrottle}
                onMouseDown={handleOnMouseDown}
                onMouseMove={handleOnMouseMove}
                onMouseUp={handleOnMouseUp}
                onTouchStart={handleOnMouseDown}
                onTouchMove={handleOnMouseMove}
                onTouchEnd={handleOnMouseUp}
            />
        </Fragment>
    );
};
