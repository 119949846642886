import { getItem, setItem, removeItem, KEY_LS } from "../StorageService";
import type { DeviceInfoType, UserItem, UserInfo } from "./Types";

// GET
const getUserId = () => {
    return getItem(KEY_LS.USER_ID, { local: true });
};

const getDeviceInfo = () => {
    return getItem(KEY_LS.DEVICE_INFO, { parsing: true, local: true });
};

const getGuestUser = () => {
    return getItem(KEY_LS.GUEST_USER, { parsing: true, local: true });
};

const getDeviceId = () => {
    return getItem(KEY_LS.DEVICE_ID, { local: true });
};

const getUserInfo = (id: string) => {
    return getItem(id, { parsing: true, local: true });
};

const getAppleDeviceTokenId = () => {
    const lsKey = `${KEY_LS.APPLE_DEVICE_TOKEN}-${getUserId()}`;
    return getItem(lsKey, { local: true });
};

// SET
const setUserId = (id: string) => {
    setItem(KEY_LS.USER_ID, id, { local: true });
};

const setDeviceInfo = (info: DeviceInfoType) => {
    setItem(KEY_LS.DEVICE_INFO, info, { local: true });
};

const setGuestUser = (guest: UserItem) => { // [IM] Storing guest_user_id only?
    setItem(KEY_LS.GUEST_USER, guest, { local: true });
};

const setDeviceId = (id: string) => {
    setItem(KEY_LS.DEVICE_ID, id, { local: true });
};

const setUserInfo = (id: string, info: UserInfo) => {
    setItem(id, info, { local: true });
};

const setAppleDeviceTokenId = (token: string) => {
    const lsKey = `${KEY_LS.APPLE_DEVICE_TOKEN}-${getUserId()}`;
    setItem(lsKey, token, { local: true });
};

// REMOVE
const removeUserId = () => {
    removeItem(KEY_LS.USER_ID, { local: true });
};

const removeAppleDeviceTokenId = () => {
    const lsKey = `${KEY_LS.APPLE_DEVICE_TOKEN}-${getUserId()}`;
    removeItem(lsKey, { local: true });
};

export {
    // GET
    getUserId,
    getDeviceInfo,
    getGuestUser,
    getDeviceId,
    getUserInfo,
    getAppleDeviceTokenId,

    // SET
    setUserId,
    setDeviceInfo,
    setGuestUser,
    setDeviceId,
    setUserInfo,
    setAppleDeviceTokenId,

    // REMOVE
    removeUserId,
    removeAppleDeviceTokenId
}
