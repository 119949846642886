import React, {useMemo, useState} from "react";
import {CardMedia, alpha, makeStyles, Paper, Fab, Icon} from "@material-ui/core";
import {imagePropertyKey, isSharedObject} from "../../../../../Services/ObjectsService";
import {useSubPropertyValue} from "../../../../../Hooks/Subscriptions/useSubPropertyValue";
import useUpdateCameraImage from "../../../../../Hooks/UpdateCameraImage/useUpdateCameraImage";
import { ObjectDetailsHeaderImgProps } from "../../TypesObjectDetails";

const useStyles = makeStyles((theme) => ({
    mainFeaturedPost: {
        position: "relative",
        backgroundColor: theme.palette.grey[800],
        color: theme.palette.common.white,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
    },
    overlay: {
        position: "absolute",
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: alpha(theme.palette.common.black, 0.3),
    },
    peopleIconWrapper: {
        width: "35px",
        height: "30px",
        position: "absolute",
        top: theme.spacing(0.9),
        right: theme.spacing(0.9),
    },
}));

export default function ObjectDetailsHeaderImg(props: ObjectDetailsHeaderImgProps) {
    const { object, children } = props;

    const classes = useStyles();

    const [currentImage, setCurrentImage] = useState<string|undefined>(object.properties?.image?.value);

    useUpdateCameraImage(object?.object_id, object?.object_type);

    const isShared = useMemo(() => {
        return isSharedObject(object);
    }, [object, isSharedObject]);

    useSubPropertyValue(object?.object_id, imagePropertyKey, (data) => {
        if (data?.value) {
            setCurrentImage(data.value as string);
        }
    });

    return (
        <Paper
            className={classes.mainFeaturedPost}
            style={{
                backgroundImage: `url(${currentImage})`,
            }}
        >
            <CardMedia
                style={{display: "none"}}
                image={currentImage}
                component="img"
                alt={object.object_name}
            />

            {isShared && (
                <Fab disabled color="primary" className={classes.peopleIconWrapper}>
                    <Icon className="fas fa-users" style={{ fontSize: 17 }}/>
                </Fab>
            )}

            <div className={classes.overlay}/>
            { children }
        </Paper>
    );
}
