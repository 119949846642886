import {useEffect} from "react";
import {v4} from "uuid";
import {subChildrenChanges, unsubChildrenChanges} from "../../Services/ObjectsService";
import {OriginType} from "../../Services/ObjectsService/Constants";

type ChildrenCallbackType = (data: string[]) => void;
type UseSubChildrenChangesType = (listChildren: string[], object_id: string | undefined, callback: ChildrenCallbackType) => void;

export const useSubChildren: UseSubChildrenChangesType = (listChildren, object_id, callback) => {

    useEffect(() => {

        if (!object_id) {
            return;
        }

        let active = true;

        const subscriptionId = v4();

        subChildrenChanges(object_id, subscriptionId, OriginType.USER, listChildren, async (keys, data) => {
            if (keys.objectId !== object_id || !active) {
                return;
            }

            callback(data as string[]);
        });

        return () => {
            active = false;
            unsubChildrenChanges(object_id, subscriptionId);
        }

    }, [object_id]);
};
