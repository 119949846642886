import React from "react";
import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import { Divider, Drawer } from "@material-ui/core";
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

import UserAvatar from "./UserAvatar";
import PurchasesStatus from "./PurchasesStatus";
import OrgSelector from "./OrgSelector";
import ListMenuItems from "./ListMenuItems";
import AuthActions from "./AuthActions";
import Version from "./Version";

import type { UserInfoType } from "Hooks/useUserProfile";
import { isDevice, isiDeviceHost } from "Services/DeviceService";
import type { ObjectItem } from "Services/ObjectsService";
import { getPlanInfo } from "Services/PurchasesService";
import { isGuest } from "Services/UserService";

const useStyles = makeStyles(() =>
    createStyles({
        drawerPaper: {
            width: '250px'
        }
    })
);

type SideMenuProps = {
    open: boolean;
    userInfo: UserInfoType;
    menuOpenToggled(): void;
    setObject(object: ObjectItem): void;
    displayModalDirect(state: boolean): void;
};

const SideMenu = (props: SideMenuProps) => {
    const { open, userInfo, setObject, menuOpenToggled, displayModalDirect } = props;

    const classes = useStyles();
    const theme = useTheme();

    const showPurchasesStatus = !getPlanInfo().isValid && isDevice() && isiDeviceHost();

    return (
        <Drawer
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={open}
            onClose={menuOpenToggled}
            classes={{
                paper: classes.drawerPaper,
            }}
            ModalProps={{
                keepMounted: true, // Better open performance on mobile.
            }}
        >
            <SimpleBar style={{ maxHeight: '100%' }}>
                {userInfo.checkAuthToken() && (
                    <UserAvatar user={userInfo.user} menuOpenToggled={menuOpenToggled}/>
                )}
                {userInfo.checkAuthToken() && showPurchasesStatus && (
                    <PurchasesStatus menuOpenToggled={menuOpenToggled}/>
                )}
                {userInfo.checkAuthToken() && !isGuest() && (
                    <OrgSelector setObject={setObject} menuOpenToggled={menuOpenToggled}/>
                )}
                {userInfo.checkAuthToken() && (
                    <ListMenuItems
                        open={open}
                        setObject={setObject}
                        menuOpenToggled={menuOpenToggled}
                        displayModalDirect={displayModalDirect}
                    />
                )}
                <Divider/>
                <AuthActions userInfo={userInfo} menuOpenToggled={menuOpenToggled}/>
                <Divider/>
                <Version/>
            </SimpleBar>
        </Drawer>
    );
};

export default React.memo(SideMenu, (prev, next) => {
    // [IM] detailed user comparison required, like by: firstname, lastname, email, avatar?
    return prev.open === next.open && prev.userInfo.user === next.userInfo.user;
});
