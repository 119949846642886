import React from "react"
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {CircularProgress, CircularProgressProps} from "@material-ui/core";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: 'relative',
        },
        bottom: {
            color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
        },
        top: {
            color: theme.palette.primary.main,
            animationDuration: '550ms',
            position: 'absolute',
            left: 0,
        },
        circle: {
            strokeLinecap: 'round',
        },
    }),
);

const BorderCircularProgress = (props: CircularProgressProps) => {
    const classes = useStyles();

    const {size, thickness} = props;

    return (
        <div className={classes.root}>
            <CircularProgress
                variant="determinate"
                className={classes.bottom}
                {...props}
                size={size || 50}
                thickness={thickness || 4}
                value={100}
            />
            <CircularProgress
                variant="determinate"
                disableShrink
                className={classes.top}
                classes={{
                    circle: classes.circle,
                }}
                {...props}
                thickness={thickness || 4}
                size={size || 50}
            />
        </div>
    );

};

export default BorderCircularProgress;