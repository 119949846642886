import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ObjectItem, Params, PropertyHistoryItem } from "Services/ObjectsService/Types";
import { getExistingObjectIds, getObjectById, getObjects } from "Services/ObjectsService";
import { useSubChildren } from "Hooks/Subscriptions/useSubChildren";
import HistoryPlanControl from "./HistoryPlanControl";
import { copy } from "Services/Utils";
import { useNotify } from "Hooks/useNotify";
import { SystemOfMeasuresType } from "Helpers/SystemOfMeasures";

type HistoryPlanProps = {
    historyData: PropertyHistoryItem[];
    systemOfMeasures: SystemOfMeasuresType;
    parentId?: string;
    object: ObjectItem;
}

const HistoryPlanContainer = (props: HistoryPlanProps): JSX.Element | null => {
    const { historyData, systemOfMeasures, parentId, object } = props;

    const notify = useNotify();

    const [displayedObjects, setDisplayedObjects] = useState<ObjectItem[]>([]);

    const getDisplayedObjects: (parentId: string) => Promise<ObjectItem[]> = useCallback(async (parentId: string) => {
        const parentObject: ObjectItem = await getObjectById(parentId);
        if (!Array.isArray(parentObject.children)) {
            return [];
        }

        const existingDisplayedObjects: string[] = await getExistingObjectIds(parentObject.children);
        const params: Params = {
            children: existingDisplayedObjects
        };
        const objects: ObjectItem[] = await getObjects(params);
        return objects.filter((obj) => obj.object_id !== object.object_id);
    }, [object.object_id]);

    const objectsFromHistory = useMemo(() => {
        return historyData.map((item, index) => {
            const _object: ObjectItem = copy(object);
            _object.properties[item.key].value = item.value;
            _object.properties[item.key].value_epoch = item.value_epoch;
            return _object;
        })
    }, [object, historyData]);

    useEffect(() => {
        let active = true;

        (async () => {
            if (!parentId) return;

            try {
                const objects = await getDisplayedObjects(parentId);

                if (active) {
                    setDisplayedObjects([...objects, ...objectsFromHistory]);
                }
            } catch(err: any) {
                notify.errorNotify(err.message);
            }
        })();

        return () => {
            active = false;
        };
    }, [parentId, getDisplayedObjects, objectsFromHistory, notify]);

    useEffect(()=>{
        console.log('displayedObjects', displayedObjects);
    },[displayedObjects]);

    useSubChildren( [], parentId, async () => {
        if (parentId) {
            const objects = await getDisplayedObjects(parentId);
            setDisplayedObjects([...objects, ...objectsFromHistory]);
        }
    });

    return <HistoryPlanControl
        objects={displayedObjects}
        currentObjectId={object.object_id}
        parentId={parentId}
        systemOfMeasures={systemOfMeasures}
    />;
};

export default HistoryPlanContainer;
