import {
    GRID_HIGHER_BORDER,
    GRID_LOWER_BORDER,
    GRID_STEP_LARGE,
    GRID_STEP_SMALL,
    LINE_WITH_MAX_STEP_COLOR,
    LINE_WITH_MIN_STEP_COLOR
} from "./Constants";

import {Color, Path, Point} from "paper";

export class GridViewController {
    public gridLayer: any;
    protected theme: "dark" | "light";

    constructor(gridLayer: any, theme: "dark" | "light") {
        this.gridLayer = gridLayer;
        this.theme = theme;
    }

    public renderGrid(): void {
        this.gridLayer.removeChildren();
        this.gridLayer.activate();
        for (let x = GRID_LOWER_BORDER; x < GRID_HIGHER_BORDER; x += GRID_STEP_SMALL) {
            let line = new Path.Line(new Point(x, GRID_LOWER_BORDER), new Point(x, GRID_HIGHER_BORDER));
            if (x % GRID_STEP_LARGE === 0) {
            } else {
                line.strokeColor = new Color(LINE_WITH_MIN_STEP_COLOR[this.theme]);
            }
        }

        for (let y = GRID_LOWER_BORDER; y < GRID_HIGHER_BORDER; y += GRID_STEP_SMALL) {
            let line = new Path.Line(new Point(GRID_LOWER_BORDER, y), new Point(GRID_HIGHER_BORDER, y));
            if (y % GRID_STEP_LARGE === 0) {
            } else {
                line.strokeColor = new Color(LINE_WITH_MIN_STEP_COLOR[this.theme]);
            }
        }

        for (let x = GRID_LOWER_BORDER; x < GRID_HIGHER_BORDER; x += GRID_STEP_SMALL) {
            let line = new Path.Line(new Point(x, GRID_LOWER_BORDER), new Point(x, GRID_HIGHER_BORDER));
            if (x % GRID_STEP_LARGE === 0) {
                line.strokeColor = new Color(LINE_WITH_MAX_STEP_COLOR[this.theme]);
            } else {
            }
        }

        for (let y = GRID_LOWER_BORDER; y < GRID_HIGHER_BORDER; y += GRID_STEP_SMALL) {
            let line = new Path.Line(new Point(GRID_LOWER_BORDER, y), new Point(GRID_HIGHER_BORDER, y));
            if (y % GRID_STEP_LARGE === 0) {
                line.strokeColor = new Color(LINE_WITH_MAX_STEP_COLOR[this.theme]);
            } else {
            }
        }
    }
}
