import React from "react";
import { controlMapPointType, controlPlanPointType } from "../Property/PropertyControls/PropertyControlsTypes";
import { ObjectItem, PropertyHistoryItem, PropertyItem } from "Services/ObjectsService/Types";
import HistoryMap from "./HistoryMap/HistoryMap";
import HistoryChart from "./HistoryChart/HistoryChart";
import HistoryLoaderContent from "./HistoryLoaderContent";
import HistoryCardGuestContent from "./HistoryCardGuestContent";
import HistoryPlanContainer from "./HistoryPlan/HistoryPlanContainer";
import { SystemOfMeasuresType } from "Helpers/SystemOfMeasures";

type HistoryCardContentProps = {
    property: PropertyItem;
    historyData: PropertyHistoryItem[];
    systemOfMeasures: SystemOfMeasuresType;
    object: ObjectItem;
    parentId?: string;
    loading: boolean;
    userIsGuest: boolean;
    dateRange: {
        startDate: Date;
        endDate: Date;
    }
}

const HistoryCardContent = (props: HistoryCardContentProps): JSX.Element | null => {
    const { property, historyData, dateRange, loading, userIsGuest, systemOfMeasures, object, parentId } = props;

    if (loading) {
        return (
            <HistoryLoaderContent/>
        );
    }

    if (userIsGuest) {
        return (
            <HistoryCardGuestContent/>
        )
    }

    if (controlMapPointType.includes(property.type)) {
        return (
            <HistoryMap
                historyData={historyData}
            />
        );
    }

    if (controlPlanPointType.includes(property.type)) {
        return (
            <HistoryPlanContainer
                historyData={historyData}
                systemOfMeasures={systemOfMeasures}
                object={object}
                parentId={parentId}
            />
        );
    }

    if (typeof property.value === "number") {
        return (
            <HistoryChart
                chartName={property.name}
                startDate={dateRange.startDate}
                endDate={dateRange.endDate}
                historyData={historyData}
                units={property.units}
                systemOfMeasures={systemOfMeasures}
            />
        )
    }

    return null;
}

export default HistoryCardContent;
