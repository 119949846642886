import { isSignedIn } from "./AuthenticationService";
import {
    encryptObjectEncryptionKey,
    encryptPropertiesWithAES,
    decryptPropertiesWithAES,
    getObjectEncryptionKey,
    decryptPropertiesHistoryWithAES,
    decryptObjectCryptoKeys,
    generateKeyIdAES,
    decryptObjectEncryptionKey
} from "./CryptoService";
import {
    copy,
    getObjectExpirationTime,
    getObjectServiceLifeNotifications,
    isActiveAccessLevel,
    replaceAll,
    wait
} from "./Utils";
import {
    BodyObjectItem,
    EncryptionKeyAES,
    forceSyncObjectById,
    GetObjectPropertyHistoryResponseType,
    NotificationType,
    imagePropertyKey,
    ObjectItem,
    ObjectPropertyFromCacheType,
    Params,
    Permissions,
    PropertiesType,
    PropertyItem,
    PropertyItemValue,
    updateObjectProperties,
    ValueEncryptedAESType,
    ValueEncryptedType,
    PropertyHistoryItem,
    RemoteObjectCallbackType,
    ObjectActivityStatus
} from "./ObjectsService";
import {
    getClientId,
    getDeviceId,
    getDeviceName,
    getUserId,
    getUserById
} from "./UserService";
import {
    subscribe as subscribeMqtt,
    unsubscribe as unsubscribeMqtt,
    MQTT_TOPIC_PUBLIC_OBJECT,
    TOPIC_TYPES
} from "./MqttService";
import type { MQTTCallbackType } from "./MqttService";
import {OriginType, SubscriptionType} from "./ObjectsService/Constants";
import {authRequest, nonAuthRequest, downloadFromS3, uploadToS3, REQUEST_TYPE, METHOD} from "./RequestService";
import {LIST_OF_PROPERTIES_STORED_ON_S3} from "./Constants";

const getRemoteObjects = async (params: Params) => {
    try {
        if (isSignedIn()) {
            const decryptObjectProperties = async (list: ObjectItem[]) => {
                try {
                    // decryptPropertiesWithAES
                    return Promise.all(
                        list.map(async (obj: ObjectItem) => {
                            if (!isSignedIn()) {
                                return obj;
                            }

                            try {
                                obj.encryption_key = await decryptObjectCryptoKeys(obj?.crypto_keys);
                                if (!obj.hasOwnProperty("crypto_keys") && !obj.encryption_key && obj.hasOwnProperty("encryption_keys")) {
                                    obj.encryption_key = await decryptObjectEncryptionKey((obj as any).encryption_keys);
                                }
                                delete obj.crypto_keys;
                                delete (obj as any).encryption_keys;

                                obj.properties = await decryptPropertiesWithAES(obj.properties, obj.encryption_key);
                                return obj;
                            } catch (e) {
                                return obj;
                            }
                        })
                    );
                } catch (e) {

                }
            };

            params.key_id = "AES";

            let result = await authRequest("objects", {
                method: METHOD.GET,
                query: params,
                requestType: REQUEST_TYPE.GET_LIST_OBJECT,
            });

            // !isSignedIn() Checking case if user click logout in the process getting data from API [AS]
            if (!result || !isSignedIn()) {
                return result;
            }

            if (params.by_page) {
                result.objects = await decryptObjectProperties(result.objects);
            } else {
                result = await decryptObjectProperties(result);
            }

            return result;
        } else {
            return nonAuthRequest("public/objects", {
                method: METHOD.GET,
                query: params,
                requestType: REQUEST_TYPE.GET_LIST_PUBLIC_OBJECT,
            });
        }
    } catch (err) {
        console.error(err);
        return Promise.reject(err);
    }
};

const getRemoteObjectById = async (objectId: string, params?: Params) => {
    try {
        if (isSignedIn()) {
            if (!params) {
                params = {};
            }

            params.key_id = "AES";

            const response = await authRequest(`objects/${objectId}`, {
                method: METHOD.GET,
                query: params,
                requestType: REQUEST_TYPE.GET_OBJECT_BY_ID,
            });
            // !isSignedIn() Checking case if user click logout in the process getting data from API [AS]
            if (!response || !isSignedIn()) {
                return response;
            }

            for (const propertyKey of LIST_OF_PROPERTIES_STORED_ON_S3) {
                if (response?.properties?.hasOwnProperty(propertyKey)) {
                    const property = copy(response.properties[propertyKey]);
                    try {
                        void getRemoteCachedData(response.object_id, property);
                    } catch (e) {}
                }
            }

            response.encryption_key = await decryptObjectCryptoKeys(response?.crypto_keys);
            if (!response.hasOwnProperty("crypto_keys") && !response.encryption_key && response.hasOwnProperty("encryption_keys")) {
                response.encryption_key = await decryptObjectEncryptionKey((response as any).encryption_keys);
            }
            delete response.crypto_keys;
            delete (response as any).encryption_keys;

            response.properties = await decryptPropertiesWithAES(response.properties, response.encryption_key);

            return response;
        } else {
            return nonAuthRequest(`public/objects/${objectId}`, {
                method: METHOD.GET,
                query: params,
                requestType: REQUEST_TYPE.GET_PUBLIC_OBJECT_BY_ID,
            });
        }
    } catch (err) {
        console.error(err);
        return Promise.reject(err);
    }
};

const postRemoteKeepAlive = async (objects: BodyObjectItem[]) => {
    try {
        const body = {
            client_id: getClientId(),
            primary_client: {
                id: getDeviceId(),
                name: getDeviceName()
            },
            objects: objects
        };

        return authRequest("objects/status", {
            method: METHOD.POST,
            body: JSON.stringify(body),
            requestType: REQUEST_TYPE.POST_OBJECT_STATUS,
        });
    } catch (err) {
        console.error(err);
        return Promise.reject(err);
    }
};

const getRemoteKeepAlive = async (objects: string[]) => {
    try {
        const body = {
            objects: objects
        };

        return authRequest("objects/status/poll", {
            method: METHOD.POST,
            body: JSON.stringify(body),
            requestType: REQUEST_TYPE.POST_OBJECT_STATUS_POLL,
        });
    } catch (err) {
        console.error(err);
        return Promise.reject(err);
    }
};

const postRemoteObject = async (object: BodyObjectItem, options: { clientId?: string, parentOwnerId?: string, encryptionKey: EncryptionKeyAES, saveKeyRequired: boolean, isPublic?: boolean, isOwner: boolean, permissions: Permissions | null }) => {
    try {
        const body = copy(object);

        const {encryptionKey, saveKeyRequired, isPublic, permissions, isOwner, parentOwnerId, clientId} = options;

        const expirationTime = getObjectExpirationTime(body.properties);

        if (expirationTime) {
            body.expiration_time = expirationTime;
        }

        if (parentOwnerId) {
            body.parent_owner_id = parentOwnerId;
        }

        const serviceLifeNotifications = getObjectServiceLifeNotifications(body.object_name || "", body.properties);
        if (serviceLifeNotifications) {
            body.service_life_notifications = serviceLifeNotifications;
        }

        if (clientId) {
            body.client_id = clientId;
        }

        delete body.notifications;
        delete body.primary_client;
        delete body.last_active;
        delete body.owner_id;

        body.properties = await encryptPropertiesWithAES(body.properties, encryptionKey, !!isPublic);

        if (object.object_id) {

            const object_id = object.object_id;

            for (const propertyKey of LIST_OF_PROPERTIES_STORED_ON_S3) {
                if (!body.properties?.hasOwnProperty(propertyKey)) {
                    continue;
                }

                const object_property = object.properties?.[propertyKey] && copy(object.properties?.[propertyKey]);
                const cached_property = copy(body.properties[propertyKey]);
                const epoch = cached_property.value_epoch || object_property.value_epoch || Date.now();
                const persistent = cached_property.persistent;

                postObjectPropertyToS3(object_id, cached_property, epoch, 60 * 60 * 24, persistent)
                    .catch((err) =>  console.error(err));

                if (body.properties[propertyKey].hasOwnProperty("value")) {
                    body.properties[propertyKey].value = null;
                }

                if (body.properties[propertyKey].hasOwnProperty("value_encrypted")) {
                    for (const keyId in body.properties[propertyKey].value_encrypted) {
                        if (!body.properties[propertyKey].value_encrypted.hasOwnProperty(keyId)) {
                            continue;
                        }
                        body.properties[propertyKey].value_encrypted[keyId] = "";
                    }
                }
            }
        }

        // TODO Temp solution. We encrypt and save the encryption key to API every time [AS] 20210917
        if (saveKeyRequired) {
            if (!encryptionKey) {
                return Promise.reject(`This object (${object.object_id}) require to encrypt data, but the encryption key is missed!`);
            }

            const userId = getUserId();

            const listUserIds = [...Object.keys(permissions?.private_acl || {})];
            if (isOwner && !listUserIds.includes(userId)) {
                listUserIds.push(userId);
            }
            if (parentOwnerId && !listUserIds.includes(parentOwnerId)) {
                listUserIds.push(parentOwnerId);
            }

            const encryption_keys: ValueEncryptedType = {};
            const object_key_id = generateKeyIdAES(encryptionKey);

            for (const user_id of listUserIds) {
                let user;

                try {
                    user = await getUserById(user_id);
                } catch (e) {
                    continue;
                }

                if (!user?.encryption?.publicJWK) {
                    continue;
                }

                const encryption = await encryptObjectEncryptionKey(encryptionKey, user.encryption.publicJWK);

                if ((isOwner && user.user_id === userId) || (parentOwnerId && user.user_id === parentOwnerId) || isActiveAccessLevel(permissions?.private_acl?.[user.user_id]?.access_level)) {
                    encryption_keys[encryption.keyId] = encryption.encryptedKey;
                    continue;
                }

                encryption_keys[encryption.keyId] = ["removed"];

            }

            body.crypto_keys = {
                [object_key_id]: {
                    encryption_keys: encryption_keys,
                    status: {
                        active: true,
                        updated: Date.now()
                    }
                }
            }

            // body.encryption_keys = encryption_keys;
        }


        delete body.encryption_key;

        if (!body.properties) {
            delete body.properties;
        }

        if (!body.source) {
            body.source = "manual_input";
        }

        return authRequest("objects", {
            method: METHOD.POST,
            body: JSON.stringify(body),
            requestType: REQUEST_TYPE.POST_OBJECT,
        });
    } catch (err) {
        console.error(object?.object_id, err);
        return Promise.reject(err);
    }
};

const deleteRemoteObject = async (objectId: string, epoch: number) => {

    const body = JSON.stringify({client_id: getClientId(), epoch: epoch});

    try {
        console.log("DELETE");
        return authRequest(`objects/${objectId}`, {
            method: METHOD.DELETE,
            body: body,
            requestType: REQUEST_TYPE.DELETE_OBJECT_BY_ID,
        });
    } catch (err) {
        try {
            console.log("To delete again");
            await wait(3000);
            return authRequest(`objects/${objectId}`, {
                method: METHOD.DELETE,
                body: body,
                requestType: REQUEST_TYPE.DELETE_OBJECT_BY_ID,
            });
        } catch (error) {
            return Promise.reject(error);
        }
    }
};

const postRemoteObjectProperties = async (objectId: string, properties: PropertiesType, options: { clientId: string | null, epoch: number, source?: string, encryptionKey: EncryptionKeyAES, isPublic?: boolean, objectName: string }) => {
    try {
        if (!Object.keys(properties).length) {
            return;
        }

        const {clientId, epoch, source, encryptionKey, isPublic, objectName} = options;

        const expirationTime = getObjectExpirationTime(properties);
        const serviceLifeNotifications = getObjectServiceLifeNotifications(objectName, properties);

        properties = await encryptPropertiesWithAES(properties, encryptionKey, !!isPublic);

        if (objectId) {
            for (const propertyKey of LIST_OF_PROPERTIES_STORED_ON_S3) {
                if (!properties.hasOwnProperty(propertyKey)) {
                    continue;
                }
                const cached_property = {...properties[propertyKey]};
                const persistent = (cached_property as any).persistent;

                (async () => {
                    try {
                        await postObjectPropertyToS3(objectId, cached_property, epoch || Date.now(), 60 * 60 * 24, persistent);
                    } catch (e) {
                        console.error(e);
                    }
                })();

                if (properties[propertyKey].hasOwnProperty("value")) {
                    (properties as any)[propertyKey].value = null;
                }

                const value_encrypted = properties[propertyKey].value_encrypted || {};
                for (const keyId in value_encrypted) {
                    if (!value_encrypted.hasOwnProperty(keyId)) {
                        continue;
                    }
                    value_encrypted[keyId] = "";
                }
            }
        }

        const body: { [k: string]: any } = {
            client_id: clientId,
            properties: properties,
            epoch: epoch,
            source: source || "manual_input",
        };

        if (expirationTime) {
            body.expiration_time = expirationTime;
        }

        if (serviceLifeNotifications) {
            body.service_life_notifications = serviceLifeNotifications;
        }

        return authRequest(`objects/${objectId}/properties`, {
            method: METHOD.POST,
            body: JSON.stringify(body),
            requestType: REQUEST_TYPE.POST_OBJECT_PROPERTIES,
        });
    } catch (err) {
        return Promise.reject(err);
    }
};

const postRemoteObjectNotificationState = async (client_id: string | null, objectId: string, notificationState: { [state: string]: boolean }) => {

    try {
        // TODO, if no value is specified, reject with error
        const body = {client_id, notificationState};

        return authRequest(`objects/${objectId}/notification/state`, {
            method: METHOD.POST,
            body: JSON.stringify(body),
            requestType: REQUEST_TYPE.POST_OBJECT_NOTIFICATION_STATE,
        });
    } catch (err) {
        return Promise.reject(err);
    }
};

const postRemoteObjectNotification = async (objectId: string, notification: NotificationType, excluded_token: string | null = null, platform: string | null = null) => {
    try {
        const body = {
            notification,
            excluded_token,
            platform
        };

        return authRequest(`objects/${objectId}/notification`, {
            method: METHOD.POST,
            body: JSON.stringify(body),
            requestType: REQUEST_TYPE.POST_OBJECT_NOTIFICATION,
        });
    } catch (err) {
        return Promise.reject(err)
    }
};

// const postObjectPropertyToCache = async (objectId: string, property: PropertyItem, epoch_value: number, lifetime: number, persistent?: boolean) => {
//     try {
//         // TODO, if no value is specified, reject with error
//         const body = {
//             value: property.value || "",
//             value_encrypted: property.value_encrypted || null,
//             epoch_value: epoch_value,
//             lifetime: lifetime || 3600,
//             persistent: persistent || false
//         };
//
//         return authRequest(`objects/${objectId}/properties/${property.key}/cache`, {
//             method: METHOD.POST,
//             body: JSON.stringify(body),
//             requestType: REQUEST_TYPE.POST_OBJECT_PROPERTIES_TO_CACHE,
//         });
//     } catch (err) {
//         return Promise.reject(err)
//     }
// };

const postObjectPropertyToS3 = async (objectId: string, property: PropertyItem, epoch_value: number, lifetime: number, persistent?: boolean) => {
    try {
        const copyProperty = copy(property);
        const params = {
            operation: "putObject",
            property_id: copyProperty.property_id || "",                                                                //[AM]temporary solution (only for develop)
            limited_lifetime: copyProperty.key === imagePropertyKey ? !persistent : false,
        };

        const signedUrl = await authRequest(`objects/${objectId}/properties/${copyProperty.key}/signed-url`, {
            method: METHOD.GET,
            query: params,
            requestType: REQUEST_TYPE.GET_OBJECT_PROPERTIES_SIGNED_URL,
        });
        // const body = {
        //     value: copyProperty.value || "",
        //     value_encrypted: copyProperty.value_encrypted || null,
        //     epoch_value: epoch_value,
        //     lifetime: lifetime || 3600,
        //     persistent: persistent || false
        // };
        let data: {[key: string]: PropertyItemValue | number | ValueEncryptedAESType} = {
            epoch_value: epoch_value
        };

        if (copyProperty.value) {
            data.value = copyProperty.value;
        }

        if (copyProperty.value_encrypted) {
            data.value_encrypted = copyProperty.value_encrypted;
        }

        let blobData = new Blob([JSON.stringify(data)], {type: 'application/json'})
        return await uploadToS3(signedUrl, blobData);
    } catch (err) {
        console.log("Catch postObjectPropertyToS3");
        return Promise.reject(err);
    }
};

const getObjectPropertyFromS3 = async (objectId: string, property: PropertyItem) => {
    try {
        const params: { [key: string]: string | boolean } = {
            operation: "getObject",
            property_id: property.property_id || ""                                                                     //[AM]temporary solution (only for develop)
        };

        const signedUrl = await authRequest(`objects/${objectId}/properties/${property.key}/signed-url`, {
            method: METHOD.GET,
            query: params,
            requestType: REQUEST_TYPE.GET_OBJECT_PROPERTIES_SIGNED_URL,
        });

        if (!signedUrl) {
            return null;
        }

        const result = await downloadFromS3(signedUrl);

        if (!result.hasOwnProperty("image")) {
            return result;
        }

        const rebuildData: {[key: string]: any} = {
            epoch_value: result.image?.epoch_value
        }

        if (result.image?.value) {
            rebuildData.value = result.image.value;
        }

        if (result.image?.value_encrypted) {
            rebuildData.value_encrypted = result.image.value_encrypted;
        }

        return rebuildData;
    } catch (err) {
        console.log("Catch getObjectPropertyFromS3.");
        return Promise.reject(err);
    }
};

// const getRemoteObjectPropertyFromCache = async (objectId: string, key: string) => {
//     try {
//         return authRequest(`objects/${objectId}/properties/${key}/cache`, {
//             method: METHOD.GET,
//             requestType: REQUEST_TYPE.GET_OBJECT_PROPERTIES_FROM_CACHE,
//         });
//     } catch (err) {
//         return Promise.reject(err);
//     }
// };

const postRemoteObjectChildren = async (parentId: string, childId: string, options: { epoch: number, source?: string }) => {
    try {
        const body = {
            child_id: childId,
            epoch: options.epoch,
            source: options.source || "manual_input",
            client_id: getClientId()
        };

        return authRequest(`objects/${parentId}/children`, {
            method: METHOD.POST,
            body: JSON.stringify(body),
            requestType: REQUEST_TYPE.POST_OBJECT_CHILDREN,
        });
    } catch (err) {
        return Promise.reject(err);
    }
};

const deleteRemoteObjectChildren = async (parentId: string, childId: string, epoch: number) => {
    try {
        return authRequest(`objects/${parentId}/children/${childId}`, {
            method: METHOD.DELETE,
            query: {epoch: epoch, client_id: getClientId()},
            requestType: REQUEST_TYPE.DELETE_OBJECT_CHILDREN,
        });
    } catch (err) {
        return Promise.reject(err);
    }
};

const getRemoteObjectPermissions = async (objectId: string) => {
    try {
        return authRequest(`objects/${objectId}/permissions`, {
            method: METHOD.GET,
            requestType: REQUEST_TYPE.GET_OBJECT_PERMISSIONS,
        });
    } catch (err) {
        return Promise.reject(err);
    }
};

const postRemoteObjectPermissions = async (objectId: string, permissions: any, list_object_id: string[] | null, options: { clientId: string | null }) => {
    try {
        const { clientId } = options;

        const body: { [k: string]: any } = {
            permissions: permissions,
            list_object_id: list_object_id,
            client_id: clientId
        };

        return authRequest(`objects/${objectId}/permissions`, {
            method: METHOD.POST,
            body: JSON.stringify(body),
            requestType: REQUEST_TYPE.POST_OBJECT_PERMISSIONS,
        });
    } catch (e) {
        return Promise.reject(e);
    }
};

const postRemoteObjectPermissionsInvite = async (objectId: string, accessLevel: string, emails: string[], comment: string | undefined, list_object_id: string[] | null, options: { clientId: string | null }) => {
    try {
        const { clientId } = options;

        const body = {
            usernames: emails,
            access_level: accessLevel,
            comment: comment,
            epoch: Date.now(),
            list_object_id: list_object_id,
            client_id: clientId
        };

        return authRequest(`objects/${objectId}/permissions/invite`, {
            method: METHOD.POST,
            body: JSON.stringify(body),
            requestType: REQUEST_TYPE.POST_OBJECT_PERMISSIONS_INVITE,
        });
    } catch (e) {
        return Promise.reject(e);
    }
};

const getRemoteObjectPropertyHistory = async (objectId: string, key: string, params: Params): Promise<GetObjectPropertyHistoryResponseType> => {
    try {
        const result: GetObjectPropertyHistoryResponseType = await authRequest(`objects/${objectId}/history/${key}`, {
            method: METHOD.GET,
            query: params,
            requestType: REQUEST_TYPE.GET_PROPERTY_HISTORY_BY_KEY,
        });
        // !isSignedIn() Checking case if user click logout in the process getting data from API [AS]
        if (!result || !isSignedIn()) {
            return result;
        }

        const decryptedHistories: PropertyHistoryItem[] = await decryptPropertiesHistoryWithAES(objectId, result.histories);

        return {
            ...result,
            histories: decryptedHistories
        };
    } catch (err) {
        return Promise.reject(err);
    }
};

const postRemoteObjectAccessRequest = async (objectId: string) => {
    try {
        return authRequest(`objects/${objectId}/access-request`, {
            method: METHOD.POST,
            requestType: REQUEST_TYPE.POST_OBJECT_ACCESS_REQUEST,
        });
    } catch (e) {
        console.error(e);
        return Promise.reject(e);
    }
};

const saveRemoteObjectPropertiesToS3 = async (objectId: string, propertyKey: string, properties: PropertiesType, options: { epoch: number, encryptionKey: EncryptionKeyAES, isPublic?: boolean }) => {

    if (!LIST_OF_PROPERTIES_STORED_ON_S3.includes(propertyKey)) {
        console.error(`Can not save property. Property Key : ${propertyKey}`);
        return;
    }

    try {
        const epoch = options?.epoch || Date.now();

        const {isPublic, encryptionKey} = options;

        properties = await encryptPropertiesWithAES(properties, encryptionKey, !!isPublic);

        await postObjectPropertyToS3(objectId, properties[propertyKey], epoch, 60 * 60 * 24);
    } catch (e) {
        console.error(objectId, e);
    }
};


const getRemoteCachedData = async (objectId: string, property: PropertyItem) => {
    const propertyKey = property.key;

    try {
        property.value = "";

        const cachedData: ObjectPropertyFromCacheType = await getObjectPropertyFromS3(objectId, property);

        if (!cachedData) {
            return;
        }

        if (cachedData?.value_encrypted) {
            property.value_encrypted = cachedData.value_encrypted;
        }

        if (cachedData?.value) {
            property.value = cachedData.value;
        }

        let encryptionKey;
        try {
            encryptionKey = await getObjectEncryptionKey(objectId);
        } catch (e: any) {
            if (e?.code === 404) {
                if (property.value) {
                    await updateObjectProperties(objectId, {[propertyKey]: property}, property.value_epoch || 0, {
                        origin: OriginType.REMOTE
                    });
                }
                return;
            } else {
                throw e;
            }
        }

        const data = await decryptPropertiesWithAES({[propertyKey]: property}, encryptionKey);
        const epoch = data[propertyKey]?.value_epoch;

        if (data[propertyKey]?.hasOwnProperty("value")) {
            await updateObjectProperties(objectId, data, epoch, {
                origin: OriginType.REMOTE
            });
        }
    } catch (e) {
        console.error(`Something went wrong for getting cached ${propertyKey}...`, e);
    }
};

const subscribeRemoteObjectsData = (subscriptionId: string, callback: RemoteObjectCallbackType) => {
    subscribeRemoteMqttObjectsData(subscriptionId, async (objectId, data, originProps) => {
        // [IM] combine this callback function with a callback in subscribeRemoteMqttObjectsData?
        if (!objectId) {
            return;
        }

        try {
            if (originProps.event === SubscriptionType.NEW) {
                const object = data as ObjectItem;
                const _objectId = object.object_id;

                if (!_objectId) {
                    return;
                }

                for (const propertyKey of LIST_OF_PROPERTIES_STORED_ON_S3) {
                    if (object.properties?.hasOwnProperty(propertyKey)) {
                        const property = copy((object.properties as PropertiesType)[propertyKey]);
                        try {
                            void getRemoteCachedData(_objectId, property);
                        } catch (e) {}
                    }
                }

                object.encryption_key = await decryptObjectCryptoKeys(object?.crypto_keys);
                if (!object.hasOwnProperty("crypto_keys") && !object.encryption_key && object.hasOwnProperty("encryption_keys")) {
                    object.encryption_key = await decryptObjectEncryptionKey((object as any).encryption_keys);
                }
                delete object.crypto_keys;
                delete (object as any).encryption_keys;

                object.properties = await decryptPropertiesWithAES(object.properties, object.encryption_key);

                return callback(objectId, object, originProps);
            }

            await forceSyncObjectById(objectId);

            if (originProps.event === SubscriptionType.PROPERTIES) {

                for (const propertyKey of LIST_OF_PROPERTIES_STORED_ON_S3) {
                    if (data.hasOwnProperty(propertyKey)) {
                        const property = copy((data as PropertiesType)[propertyKey]);
                        delete (data as PropertiesType)[propertyKey];
                        try {
                            void getRemoteCachedData(objectId, property);
                        } catch (e) {}
                    }
                }

                let encryptionKey;
                try {
                    encryptionKey = await getObjectEncryptionKey(objectId);
                } catch (e) {
                }

                data = await decryptPropertiesWithAES(data, encryptionKey);
            }

            callback(objectId, data, originProps);
        } catch (e) {
            console.error("subscribeObjectsData-CALLBACK",e);
        }
    });
};

const unsubscribeRemoteObjectsData = (subscriptionId: string) => {
    unsubscribeRemoteMqttObjectsData(subscriptionId);
};

const subscribeRemotePublicObjects = (subscriptionId: string, tileNames: string[], callback: RemoteObjectCallbackType) => {
    subscribeRemoteMqttPublicObjects(subscriptionId, tileNames, async (objectId, data, originProps) => {
        // [IM] combine this callback function with a callback in subscribeRemoteMqttPublicObjects?
        if (!objectId) {
            return;
        }

        try {
            if (originProps.event === SubscriptionType.NEW) {
                const object = data as ObjectItem;
                const _objectId = object.object_id;

                if (!_objectId) {
                    return;
                }

                for (const propertyKey of LIST_OF_PROPERTIES_STORED_ON_S3) {
                    if (object.properties?.hasOwnProperty(propertyKey)) {
                        const property = copy((object.properties as PropertiesType)[propertyKey]);
                        try {
                            void getRemoteCachedData(_objectId, property);
                        } catch (e) {}
                    }
                }

                object.encryption_key = await decryptObjectCryptoKeys(object?.crypto_keys);
                if (!object.hasOwnProperty("crypto_keys") && !object.crypto_keys && !object.encryption_key && object.hasOwnProperty("encryption_keys")) {
                    object.encryption_key = await decryptObjectEncryptionKey((object as any).encryption_keys);
                }
                delete object.crypto_keys;
                delete (object as any).encryption_keys;

                object.properties = await decryptPropertiesWithAES(object.properties, object.encryption_key);

                return callback(objectId, object, originProps);
            }

            await forceSyncObjectById(objectId);

            if (originProps.event === SubscriptionType.PROPERTIES) {
                const object = data as BodyObjectItem;

                for (const propertyKey of LIST_OF_PROPERTIES_STORED_ON_S3) {
                    if (object.properties?.hasOwnProperty(propertyKey)) {
                        const property = copy((object.properties as PropertiesType)[propertyKey]);
                        delete (object.properties as PropertiesType)[propertyKey];
                        try {
                            void getRemoteCachedData(objectId, property);
                        } catch (e) {}
                    }
                }

                let encryptionKey;
                try {
                    encryptionKey = await getObjectEncryptionKey(objectId);
                } catch (e) {
                }

                object.properties = await decryptPropertiesWithAES(object.properties, encryptionKey);

                return callback(objectId, object, originProps);
            }

            callback(objectId, data, originProps);
        } catch (e) {
        }
    });
};

const unsubscribeRemotePublicObjects = (subscriptionId: string, tileNames: string[]) => {
    unsubscribeRemoteMqttPublicObjects(subscriptionId, tileNames);
};

// ObjectsRemoteMqttService
const subscribeRemoteMqttObjectsData = (subscriptionId: string, callback: RemoteObjectCallbackType) => {
    subscribeObjectsData(subscriptionId, (topicKeys, payload) => {
        // [IM] combine this callback function with a callback in subscribeRemoteObjectsData?
        const copyKeys = copy(topicKeys);
        copyKeys.object_id = copyKeys?.object_id || payload.object.object_id;

        if (!copyKeys.object_id) {
            return;
        }

        delete payload.client_id;

        if (payload.message_type === "properties") {
            return callback(copyKeys.object_id, payload.properties, {
                origin: OriginType.REMOTE,
                event: SubscriptionType.PROPERTIES
            });
        } else if (payload.message_type === "permissions") {
            return callback(copyKeys.object_id, { object_id: copyKeys.object_id }, { // [IM] remove object_id from payload???
                origin: OriginType.REMOTE,
                event: SubscriptionType.PERMISSIONS
            });
        } else if (payload.message_type === "deleted") {
            const date = new Date(payload.updated);

            return callback(copyKeys.object_id, { updated: date }, {
                origin: OriginType.REMOTE,
                epoch: date.getTime(),
                event: SubscriptionType.DELETE
            });
        } else if (payload.message_type === "children") {
            return callback(copyKeys.object_id, { object_id: copyKeys.object_id, children: payload.children }, { // [IM] remove object_id from payload???
                origin: OriginType.REMOTE,
                event: SubscriptionType.CHILDREN
            });
        } else if (payload.message_type === "status") {
            const data: ObjectActivityStatus = {
                object_id: copyKeys.object_id,  // [IM] remove object_id from payload???
            };

            if (payload.hasOwnProperty("primary_client")) {
                data.primary_client = payload.primary_client;
            }

            if (payload.hasOwnProperty("reachable")) {
                data.reachable = payload.reachable;
            }

            if (payload.hasOwnProperty("last_active")) {
                data.last_active = payload.last_active;
            }

            return callback(copyKeys.object_id, data, {
                origin: OriginType.REMOTE,
                event: SubscriptionType.STATUS
            });
        } else if (payload.message_type === "new_object") {
            return callback(copyKeys.object_id, payload.object as ObjectItem, {
                origin: OriginType.REMOTE,
                event: SubscriptionType.NEW,
            });
        } else if (payload.message_type === "notification_state") {
            const notification_state = payload.notification_state[getUserId()];

            if (!notification_state) { return; }

            return callback(copyKeys.object_id, { object_id: copyKeys.object_id, notifications: notification_state } as ObjectItem,{ // [IM] remove object_id from payload???
                origin: OriginType.REMOTE,
                event: SubscriptionType.NOTIFICATION_STATE,
            });
        } else if (payload.message_type === "favorites") {
            if (!payload.favorites?.[getUserId()]) { return; }

            return callback(copyKeys.object_id, { object_id: copyKeys.object_id, favorites: payload.favorites }, { // [IM] remove object_id from payload???
                origin: OriginType.REMOTE,
                event: SubscriptionType.FAVORITES
            });
        }
    });
};

const unsubscribeRemoteMqttObjectsData = (subscriptionId: string) => {
    unsubscribeObjectsData(subscriptionId);
};

const subscribeRemoteMqttPublicObjects = (subscriptionId: string, tileNames: string[], callback: RemoteObjectCallbackType) => {
    subscribePublicObjects(subscriptionId, tileNames, (topicKeys, payload) => {
        // [IM] combine this callback function with a callback in subscribeRemotePublicObjects?
        const copyKeys = copy(topicKeys);
        copyKeys.object_id = copyKeys?.object_id || payload.object.object_id;

        if (!copyKeys.object_id) {
            return;
        }

        delete payload.client_id;

        if (payload.message_type === "properties_public_object") {
            return callback(copyKeys.object_id, payload.object, {
                origin: OriginType.REMOTE,
                event: SubscriptionType.PROPERTIES
            });
        } else if (payload.message_type === "deleted_public_object") {
            return callback(copyKeys.object_id, payload.object, {
                origin: OriginType.REMOTE,
                event: SubscriptionType.DELETE
            });
        } else if (payload.message_type === "children_public_object") {
            return callback(copyKeys.object_id, payload.object, {
                origin: OriginType.REMOTE,
                event: SubscriptionType.CHILDREN
            });
        } else if (payload.message_type === "status_public_object") {
            return callback(copyKeys.object_id, payload.object, {
                origin: OriginType.REMOTE,
                event: SubscriptionType.STATUS
            });
        } else if (payload.message_type === "new_public_object") {
            return callback(copyKeys.object_id, payload.object as ObjectItem, {
                origin: OriginType.REMOTE,
                event: SubscriptionType.NEW,
            });
        }
    });
};

const unsubscribeRemoteMqttPublicObjects = (subscriptionId: string, tileNames: string[]) => {
    unsubscribePublicObjects(subscriptionId, tileNames);
};

const subscribeObjectsData = (subscriptionId: string, callback: MQTTCallbackType) => {
    const keys = {
        subscription_id: subscriptionId,
    };

    subscribeMqtt(TOPIC_TYPES.OBJECTS, keys, callback);
};

const unsubscribeObjectsData = (subscriptionId: string) => {
    const keys = {
        subscription_id: subscriptionId,
    };

    unsubscribeMqtt(TOPIC_TYPES.OBJECTS, keys);
};

const subscribePublicObjects = (subscriptionId: string, tileNames: string[], callback: MQTTCallbackType) => {
    const topics = tileNames.map((tileName) => {
        return replaceAll(MQTT_TOPIC_PUBLIC_OBJECT, "{tile}", tileName);
    });

    const keys = {
        subscription_id: subscriptionId,
        topics: topics
    };

    subscribeMqtt(TOPIC_TYPES.PUBLIC_OBJECTS, keys, callback);
};

const unsubscribePublicObjects = (subscriptionId: string, tileNames: string[]) => {
    const topics = tileNames.map((tileName) => {
        return replaceAll(MQTT_TOPIC_PUBLIC_OBJECT, "{tile}", tileName);
    });

    const keys = {
        subscription_id: subscriptionId,
        topics: topics
    };

    unsubscribeMqtt(TOPIC_TYPES.PUBLIC_OBJECTS, keys);
};
// ObjectsRemoteMqttService

export {
    getRemoteObjects,
    getRemoteObjectById,
    getRemoteKeepAlive,
    postRemoteKeepAlive,
    postRemoteObject,
    deleteRemoteObject,
    postRemoteObjectProperties,
    postRemoteObjectNotificationState,
    postRemoteObjectNotification,
    postRemoteObjectChildren,
    deleteRemoteObjectChildren,

    getRemoteObjectPermissions,
    postRemoteObjectPermissions,
    postRemoteObjectPermissionsInvite,

    getRemoteObjectPropertyHistory,
    postRemoteObjectAccessRequest,
    saveRemoteObjectPropertiesToS3,
    getRemoteCachedData,

    subscribeRemoteObjectsData,
    unsubscribeRemoteObjectsData,
    subscribeRemotePublicObjects,
    unsubscribeRemotePublicObjects,
};
