import {useEffect} from "react";
import {EVENT_TYPE, pubKeyDownCmdSEvent, SystemEventCallbackType} from "../../Services/SystemEventsService";
import useSystemEvent from "../SystemEvent/useSystemEvent";

export const usePressButtonsOnKeyboard = () => {
    const keyDownHandler = (event: KeyboardEvent) => {
        if (event.code === "KeyS" && (event.ctrlKey || event.metaKey)) {
            event.preventDefault();
            event.stopPropagation();

            pubKeyDownCmdSEvent();
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", keyDownHandler, false);

        return () => {
            document.removeEventListener("keydown", keyDownHandler, false);
        };
    }, []);
};

export const useSubKeyDownCmdS = (callback: SystemEventCallbackType) => { useSystemEvent(EVENT_TYPE.KEY_DOWN_CMD_S, callback); };
