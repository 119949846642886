import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {$insertNodeToNearestRoot} from '@lexical/utils';
import {COMMAND_PRIORITY_EDITOR, createCommand, LexicalCommand, LexicalEditor} from 'lexical';
import {useEffect, useRef, useState} from 'react';
import * as React from "react";

import {$createLocalMachinesNode, LocalMachinesNode} from '../../nodes/LocalMachinesNode';
import {UserInfoType} from "../../../../Hooks/useUserProfile";
import {FileType, ObjectItem, Permissions} from "../../../../Services/ObjectsService/Types";
import {postObject, postObjectChildren} from "../../../../Services/ObjectsService/CRUD";
import {OBJECT_TYPE, OriginType} from "../../../../Services/ObjectsService/Constants";
import TreeViewWithSearch from "../../../ListObjects/TreeViewWithSearch/TreeViewWithSearch";
import Button from "../../ui/Button";
import {v4} from "uuid";
import {getClientId} from "../../../../Services/UserService";
import FileInput from "../../ui/FileInput";
import {DialogActions, DialogButtonsList} from "../../ui/Dialog";


export const INSERT_LOCALMACHINES_COMMAND: LexicalCommand<string> = createCommand(
    'INSERT_LOCALMACHINES_COMMAND',
);

export function InsertLMFileUploadedDialogBody({
    onClick,
    userInfo,
    objectId,
    filterObjectsIds,
}: {
    onClick: (payload: string) => void;
    userInfo: UserInfoType;
    objectId: string;
    filterObjectsIds: string[];
}) {
    const [src, setSrc] = useState<string>('');

    const isDisabled = src === '';
    const handleSelectObject = (object: ObjectItem | null) => {
        if (object) {
          setSrc(object.object_id);
        }
    }

    const handleConfirmSelection = async () => {
        if (objectId && src) {
          await postObjectChildren(objectId, src, {origin: OriginType.USER});
        }

        onClick(src);
    };

    return (
        <>
            <div style={{overflowY: "scroll", whiteSpace: 'nowrap', overflowX: "hidden", textOverflow: 'ellipsis'}}>
                <TreeViewWithSearch
                    filterObjectsIds={filterObjectsIds}
                    handleSelectObject={handleSelectObject}
                    filterType={[OBJECT_TYPE.FILE]}
                    userInfo={userInfo}
                />
            </div>
            <DialogActions>
                <Button
                    disabled={isDisabled}
                    onClick={handleConfirmSelection}>
                    Confirm
                </Button>
            </DialogActions>
        </>
    );
}

export function InsertFileUploadedDialogBody({
    onClick,
    objectId,
    objectOwnerId,
    objectPermissions,
}: {
    onClick: (payload: string) => void;
    objectId: string;
    objectOwnerId?: string;
    objectPermissions?: Permissions;
}) {
    const [newFile, setNewFile] = useState<FileType>({name: '', type: '', content: ''});
    const isDisabled = newFile.name === '';

    const loadFile = (files: FileList | null) => {

        if (!files || !files.length) {
            setNewFile({name: '', type: '', content: ''});
            return;
        }

        let reader = new FileReader();
        let file = files[0];

        reader.onloadend = async () => {
            if (!file.name) {
                return;
            }

            setNewFile({
                name: file.name as string,
                type: file.type as string,
                content: reader.result as string
            });
        };

        reader.readAsDataURL(new Blob([file], {type: file.type}));
    };

    const confirmFileLoad = async () => {

        let _permissions: Permissions = {
        };

        if (objectPermissions) {
            const _epoch = Date.now();

            _permissions = {
                perm_id: v4(),
                updated: _epoch
            };

            if (objectPermissions.private_acl) {
                for (let key in objectPermissions.private_acl) {
                    if (!objectPermissions.private_acl.hasOwnProperty(key)) {
                        continue;
                    }

                    if (!_permissions.private_acl) {
                        _permissions.private_acl = {};
                    }

                    _permissions.private_acl[key] = {
                        access_level: objectPermissions.private_acl[key].access_level,
                        updated: _epoch
                    }
                }
            }
        }

        const newObject: ObjectItem = {
            object_id: "",
            object_type: OBJECT_TYPE.FILE,
            object_name: newFile.name,
            description: "",
            children: [],
            plan: null,
            properties: {
                file: {
                    type: "File",
                    key: "file",
                    icon: "fas fa-file",
                    name: "File",
                    value: newFile,
                    property_id: v4(),
                    protection: true,
                    readable: true,
                    visibility: ["card", "details", "parent", "title"],
                    writable: false,
                }
            },
            permissions: {..._permissions},
        }

        const res = await postObject(newObject, {
            origin: OriginType.USER,
            parentId: objectId,
            parentOwnerId: objectOwnerId,
            clientId: getClientId()
        });

        if (objectId && res?.object_id) {
            await postObjectChildren(objectId, res.object_id, {origin: OriginType.USER});
        }

        onClick(res.object_id)
    }

    return (
        <>
            <FileInput
                label="File Upload"
                onChange={loadFile}
            />
            <DialogActions>
                <Button
                    disabled={isDisabled}
                    onClick={confirmFileLoad}>
                    Confirm
                </Button>
            </DialogActions>
        </>
    );
}

export function InsertFileDialog({
    activeEditor,
    onClose,
    userInfo,
    objectId,
    objectOwnerId,
    objectPermissions,
}: {
    activeEditor: LexicalEditor;
    onClose: () => void;
    userInfo: UserInfoType;
    objectId: string;
    objectOwnerId?: string;
    objectPermissions?: Permissions;
}): JSX.Element {
    const [mode, setMode] = useState<null | 'file' | 'lm-file'>(null);
    const hasModifier = useRef(false);
    let filterObjectsIds: string[];
    filterObjectsIds = [];

    useEffect(() => {
        hasModifier.current = false;
        const handler = (e: KeyboardEvent) => {
            hasModifier.current = e.altKey;
        };
        document.addEventListener('keydown', handler);
        return () => {
            document.removeEventListener('keydown', handler);
        };
    }, [activeEditor]);

    const onClick = (payload: string) => {
        activeEditor.dispatchCommand(INSERT_LOCALMACHINES_COMMAND, payload);
        onClose();
    };

    return (
        <>
            {!mode && (
                <DialogButtonsList>
                    <Button
                        data-test-id="file-modal-option-lm-file"
                        onClick={() => setMode('lm-file')}>
                        LocalMachines File
                    </Button>
                    <Button
                        data-test-id="file-modal-option-file"
                        onClick={() => setMode('file')}>
                        File from your computer
                    </Button>
                </DialogButtonsList>
            )}
            {
                mode === 'lm-file' &&
                <InsertLMFileUploadedDialogBody
                    onClick={onClick}
                    userInfo={userInfo}
                    objectId={objectId}
                    filterObjectsIds={filterObjectsIds}
                />
            }
            {
                mode === 'file' &&
                <InsertFileUploadedDialogBody
                    onClick={onClick}
                    objectId={objectId}
                    objectOwnerId={objectOwnerId}
                    objectPermissions={objectPermissions}
                />
            }
        </>
    );
}

export default function LocalMachinesPlugin(): JSX.Element | null {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
        if (!editor.hasNodes([LocalMachinesNode])) {
            throw new Error('LocalMachinesPlugin: LocalMachinesNode not registered on editor');
        }

        return editor.registerCommand<string>(
            INSERT_LOCALMACHINES_COMMAND,
            (payload) => {
                const LocalMachinesNode = $createLocalMachinesNode(payload);
                $insertNodeToNearestRoot(LocalMachinesNode);
                return true;
            },
            COMMAND_PRIORITY_EDITOR,
        );
    }, [editor]);

    return null;
}
