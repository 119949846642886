import React, {Fragment, useState} from "react";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import UserPropertyItemContent from "../UserPropertyItem/UserPropertyItemContent/UserPropertyItemContent";
import UserPropertyItemAction from "../UserPropertyItem/UserPropertyItemAction/UserPropertyItemAction";
import {Skeleton} from "@material-ui/lab";
import {useGlobalLoaderActionsContext, useGlobalLoaderContext} from "../../../../../GlobalContext/GlobalContext";
import {resetPassword} from "../../../../../Services/AuthenticationService";
import {PasswordItemProps} from "../../UserProfileTypes";
import {isEmail} from "../../../../../Services/Utils";


const PasswordItem = (props: PasswordItemProps) => {

    const { email, disabled, isGuest, notify } = props;

    const loader = useGlobalLoaderContext();
    const setLoader = useGlobalLoaderActionsContext();

    const [editMode, setEditMode] = useState<boolean>(false);
    const [oldPassword, setOldPassword] = useState<string>("");
    const [newPassword, setNewPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");

    const handleOldPasswordChange = (event: React.FocusEvent<HTMLInputElement>) => {
        setOldPassword(event.target.value);
    };

    const handleNewPasswordChange = (event: React.FocusEvent<HTMLInputElement>) => {
        setNewPassword(event.target.value);
    };

    const handleConfirmPasswordChange = (event: React.FocusEvent<HTMLInputElement>) => {
        setConfirmPassword(event.target.value);
    };

    const clearData = () => {
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
    };

    const onClickAgree = async () => {
        if (!editMode || loader) {
            return;
        }

        if (!newPassword && !confirmPassword) {
            setNewPassword("");
            setConfirmPassword("");
            return;
        }

        if (newPassword !== confirmPassword) {
            notify?.errorNotify("Password Confirmation Does Not Match");
            setNewPassword("");
            setConfirmPassword("");
            return;
        }

        if (!isGuest && !oldPassword) {
            return notify?.errorNotify("Old Password Required");
        }

        if (!isEmail(email)) {
            return notify?.errorNotify("Email is invalid");
        }

        setEditMode(false);
        setLoader(true);

        try {
            await resetPassword(email, oldPassword, newPassword);
            clearData();
        } catch (e: any) {
            notify.errorNotify(e?.message || JSON.stringify(e));
        }

        setLoader(false);
    };

    const onClickDisagree = () => {
        clearData();
        setEditMode(false);
    };

    const onClickChangeMode = () => {
        if (editMode || loader) {
            return;
        }
        clearData();
        setEditMode(true);
    };

    return (
        <UserPropertyItemContent
            actions={
                <UserPropertyItemAction
                    editMode={editMode}
                    handleAgree={onClickAgree}
                    handleChangeMode={onClickChangeMode}
                    handleDisagree={onClickDisagree}
                    disabled={disabled}
                />
            }
            content={
                editMode ?
                    <Fragment>
                        {!isGuest && (
                            <TextField fullWidth value={oldPassword} type="password" label={"Old Password"}
                                       onChange={handleOldPasswordChange}/>)}
                        <TextField fullWidth value={newPassword} type="password" label={"New Password"}
                                   onChange={handleNewPasswordChange}/>
                        <TextField fullWidth value={confirmPassword} type="password" label={"Confirm Password"}
                                   onChange={handleConfirmPasswordChange}/>
                    </Fragment>
                    :
                    <Typography variant="subtitle1" color="initial">
                        {!loader ? "" : (<Skeleton animation="wave" height={30} width={150}/>)}
                    </Typography>
            }
            title={"Password"}
            editMode={editMode}
            divider={true}
            handleChangeMode={onClickChangeMode}
            disabled={disabled}
        />
    );
};

export default PasswordItem;