import {useState, MouseEvent} from "react";
import { LatLngTuple } from "leaflet";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Popover from "@material-ui/core/Popover";
import { useCommonStyles, popoverPosition } from "../common/Common";
import { MapPolygonProps } from "./MapPolygonTypes";
import { MapPolygonComponent } from "./MapPolygonComponent";

const MapPolygon = (props: MapPolygonProps) => {
    const { propertyKey, label, value, variant, object, mapWidth, saveValue, isDisabled, settings, userInfo } = props;

    const commonClasses = useCommonStyles();

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [mapPolygonValue, setMapPolygonValue] = useState<LatLngTuple[]>(value);

    const localVariant =
        !variant || !["control", "popover", "propertyCard", "title"].includes(variant) ? "control" : variant;

    const handleSavedValue = (_value: LatLngTuple[]) => {
        saveValue(propertyKey, _value);
    };

    const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
        saveValue(propertyKey, mapPolygonValue);
    };

    const displayPopover = Boolean(anchorEl);
    const popoverId = displayPopover ? propertyKey : undefined;

    if (localVariant === "control") {
        return (
            <Grid container direction="column" justifyContent="center">
                <Grid item container direction="row" justifyContent="center" alignItems="center">
                    {settings}
                    <Typography>{label || "Map Polygon"}</Typography>
                </Grid>
                <Box border={1} className={commonClasses.border}>
                    <MapPolygonComponent
                        value={value}
                        mapWidth={mapWidth}
                        object={object}
                        saveValue={handleSavedValue}
                        isDisabled={isDisabled}
                        userInfo={userInfo}
                    />
                </Box>
            </Grid>
        );
    } else if (["propertyCard", "title"].includes(variant as string)) {
        return (
            <MapPolygonComponent
                value={value}
                object={object}
                saveValue={setMapPolygonValue}
                isDisabled
                isPropertyCard
                userInfo={userInfo}
            />
        );
    } else {
        return (
            <>
                <Typography component="div">
                    <Grid
                        component="label"
                        container
                        alignItems="center"
                        spacing={1}
                        onClick={handlePopoverOpen}
                        className={commonClasses.cursorPointer}
                        wrap="nowrap"
                    >
                        <Grid item>
                            <Box fontSize="small">{label}</Box>
                        </Grid>
                        <Grid item>
                            <Box fontSize="small">{value}</Box>
                        </Grid>
                    </Grid>
                    <Popover
                        id={popoverId}
                        open={displayPopover}
                        anchorEl={anchorEl}
                        onClose={handlePopoverClose}
                        {...popoverPosition}
                    >
                        <Box width={"100%"} p={1}>
                            <MapPolygonComponent
                                value={value}
                                object={object}
                                saveValue={setMapPolygonValue}
                                isDisabled={isDisabled}
                                userInfo={userInfo}
                            />
                        </Box>
                    </Popover>
                </Typography>
            </>
        );
    }
};

export { MapPolygon };
