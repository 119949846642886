import Queue, {ProcessFunctionCb} from "better-queue";
// @ts-ignore
import MemoryStore from "better-queue-memory";

import {
    calculateRelativePointForDetectedObject,
    getLocationDetectedObject,
} from "./ImageTransformService";
import {QueueWorkerPool} from "./Queue";
import {
    getClientId,
    getDeviceHostType,
    getDeviceId,
    postUserDeviceToken,
    setDeviceId,
    setDeviceInfo
} from "./UserService";
import {isSignedIn} from "./AuthenticationService";
import {generatePropertyItem, getEpochTime} from "./Utils";
import {NATIVE_BACKGROUND_QUEUE_TIME_LOGS, NATIVE_TIME_LOGS} from "./Constants";
import {BodyObjectItem, ObjectItem, Params, Permissions, PointTuple, PropertyItem} from "./ObjectsService/Types";
import {
    deleteChildObjectsWithoutConvertChildObjects,
    deleteNativeObjects,
    getDetectedObjectById,
    getNativeObjectById,
    getNativeObjects,
    handleEnterForeground,
    postNativeObjectKeepAlive,
    postNativeObject,
    postNativeObjectProperties,
    postNativeObjectsHierarchy,
    postNativeObjectChildren,
    postObjectChildrenWOChildConvert,
    postSeveralObjectChildrenWithoutConversion,
    pubNativeObjectProperties,
    saveNativeObjectPropertiesToS3,
} from "./ObjectsService/ObjectsNativeService/ObjectsNativeService";
import {handleRemoteNotification} from "./ObjectsService";
import type {IAPData} from "./PurchasesService";
import {AccessLevel, DeviceHandler, imagePropertyKey} from "./ObjectsService/Constants";
import {CONSTRAINTS_BY_PLAN, CAMERA_STREAM_TIMEOUT, getPlanInfo} from "./PurchasesService";
import {convertNativeIdToStoredId} from "./ObjectsService/ObjectsNativeService/ObjectsIdentificationService/ObjectsIdentificationService";
import {v4} from "uuid";


const WEBKIT = (window as any).webkit;
const WINDOW = (window as any);

let objectPropertyToCacheTimeout = CAMERA_STREAM_TIMEOUT.free;

export type Message = {
    id: string;
    handler: string;
    data: any;
}

export type Notification = {
    code: string;
    type: string;
    msg?: string;
    data?: any;
    wasShown: boolean;
}

let imageToCacheQueueWorkerPool = undefined;

const isDevice = () => {
    return !!WEBKIT;
};

const isHandlerExist = (handler: DeviceHandler) => {
    return !!WEBKIT?.messageHandlers?.[handler];
};

// const isiOSHost = () => {
//     return getDeviceHostType() === "iOS";
// };

// const isMacOSHost = () => {
//     return getDeviceHostType() === "MacOS";
// };

const isiDeviceHost = () => {
    const hostType = getDeviceHostType();

    return ["MacOS", "iOS"].includes(hostType);
};

if (isDevice()) {
    WINDOW.sendMessageToQueueForProcessing = (inputMessage: string) => {
        let message = JSON.parse(inputMessage) as Message;

        if (message.handler && message.handler === "createOrUpdateDetectedObjects") {
            let objects = message.data.objects;
            for (const object of objects) {
                if (object.first_detection) {
                    message.id = message.id + "_" + v4();
                    break;
                }
            }
        }
        message.id = message.id + "_" + message.handler;
        nativePartMessageQueue.push(message);
    };

    WINDOW.callbackForRemoteNotificationToken = (inputMessage: string) => {
        let message = JSON.parse(inputMessage) as Message;
        const token = message.data.token ?? undefined;
        const platform = message.data.platform ?? undefined;
        if (token && platform) {
            void postUserDeviceToken(token, platform.toLowerCase());
        }
    };

    WINDOW.receivedRemoteNotification = (inputMessage: string) => {
        let message = JSON.parse(inputMessage) as Message;
        const notification = message.data.notification;
        if (notification) {
            void handleRemoteNotification(notification);
        }
    }
}

const nativePartMessageQueue: Queue = new Queue((message: Message, cb: ProcessFunctionCb<any>) => {
    // console.log("_Run_task_ ", message.id, message.handler, Date.now());

    if (NATIVE_TIME_LOGS) {
        console.time(message.id);
    }

    switch (message.handler) {
        case "updatePropertiesOfObject":
            void updatePropertiesOfObject(message.data, cb);
            break;
        case "createOrUpdateObject":
            void createOrUpdateObject(message.data, cb);
            break;
        case "updateObjects":
            void updateObjects(message.data, cb);
            break;
        case "updateObjectImage":
            void updateObjectImage(message.data, cb);
            break;
        case "initialCreateOrUpdateIDevice":
            void initialCreateOrUpdateIDevice(message.data, cb);
            break;
        case "setIDeviceInformation":
            setIDeviceInformation(message.data, cb);
            break;
        case "createOrUpdateDetectedObjects":
            void createOrUpdateDetectedObjects(message.data, cb);
            break;
        case "deleteDetectedObjects":
            void deleteDetectedObjects(message.data, cb);
            break;
        case "willEnterForeground":
            willEnterForeground(message.data, cb);
            break;
        default:
            console.log("Unhandled message type");
            cb(null, true);
            break;
    }
}, {
    concurrent: 1, //Max: 3
    store: new MemoryStore()
});

const propertyToCacheWorker = async (message: Message, cb: ProcessFunctionCb<any>) => {

    const objectId = message.handler;

    try {
        await saveNativeObjectPropertiesToS3(objectId, imagePropertyKey, message?.data?.properties, message.data.epoch);
    } catch (err) {
        console.log("PropertyToCacheQueue err: ", err)
    }
    cb(null, true);
};

const keepAliveQueue: Queue = new Queue(async (message: Message, cb: ProcessFunctionCb<any>) => {
    try {
        if (NATIVE_BACKGROUND_QUEUE_TIME_LOGS) {
            console.time("Update Keep Alive in QUEUE");
        }

        await postNativeObjectKeepAlive(message.data);

        if (NATIVE_BACKGROUND_QUEUE_TIME_LOGS) {
            console.timeEnd("Update Keep Alive in QUEUE");
        }
        cb(null, true);
    } catch (e) {
        handleErrorUpdate(e, message.data.object_id, cb);
    }
}, {
    concurrent: 1,
    store: new MemoryStore()
});

const detectedObjectsQueueWorker = async (message: Message, cb: ProcessFunctionCb<any>) => {
    switch (message.handler) {
        case "CreateOrUpdateDetectedObject":
            try {
                await postNativeObject(message.data, {skipConvert: true});

                let listParent: string[] = [];

                if (message.data.properties?.plan_point?.value) {
                    listParent = listParent.concat(Object.keys(message.data.properties.plan_point.value));
                }

                for (const parentId of listParent) {
                    if (parentId === message.data.observer_id) {
                        continue
                    }
                    await postObjectChildrenWOChildConvert(parentId, message.data.object_id);
                }
            } catch (e) {
            }
            break;
        case "DeleteDetectedObject":
            try {
                await deleteChildObjectsWithoutConvertChildObjects(message.data.storedObserverID, message.data.noDetectableObjects);
                const parentsAndChildren: { [key: string]: string[] } = {};
                for (const noDetectableObjectId of message.data.noDetectableObjects) {
                    try {
                        const noDetectableObject = await getDetectedObjectById(noDetectableObjectId, {skipChildrenTransform: true});
                        if (!noDetectableObject?.properties?.plan_point?.value) {
                            continue;
                        }
                        let parents = Object.keys(noDetectableObject.properties.plan_point.value);
                        for (const parent of parents) {
                            if (parent === message.data.storedObserverID) {
                                continue;
                            }
                            if (!parentsAndChildren.hasOwnProperty(parent)) {
                                parentsAndChildren[parent] = [];
                            }
                            parentsAndChildren[parent].push(noDetectableObjectId);
                        }

                    } catch (e) {
                        console.error("Error DeleteDetectedObject ", e);
                    }
                }
                for (const parentId of Object.keys(parentsAndChildren)) {
                    await deleteChildObjectsWithoutConvertChildObjects(parentId, parentsAndChildren[parentId]);
                }
                await deleteNativeObjects(message.data.noDetectableObjects);
            } catch (err) {
                console.log("deleteChildObjectsWithoutConvertChildObjects Err:", err);
            }
            break;
        case "AddChildrenToObserver":
            try {
                await postSeveralObjectChildrenWithoutConversion(message.data.storedObserverID, message.data.childrenIds);
            } catch (err) {
                console.log("PostSeveralObjectChildrenWithoutConversion Err:", err);
            }
            break;
        case "ClearQueue":
            break;
        default:
            console.log("Unhandled message type");
            break;
    }
    cb(null, true);
};

const detectedObjectsQueue: Queue = new Queue(detectedObjectsQueueWorker, {
    concurrent: 1,
    store: new MemoryStore()
});

if (NATIVE_TIME_LOGS) {
    nativePartMessageQueue.on('task_finish', function (taskId) {
        console.timeEnd(taskId);
    });

    nativePartMessageQueue.on('task_failed', function (taskId) {
        console.timeEnd(taskId);
    });
}

const handleErrorUpdate = (err: any, objectId: string, cb: ProcessFunctionCb<any>) => {
    sendMessageToDevice(DeviceHandler.INIT_OBJECT_BY_ID, {
        objectId: objectId,
    });

    return cb(err);
};

const registerDevice = (source: String) => {
    if (source === "ObjectService" && isSignedIn()) {
        console.log("====> Init DeviceService");
        void getAndSendCameras(getDeviceId());
    }
    if (source === "MQTT" && isSignedIn()) {
        console.log("====> Init DeviceService v0.2.4");
        sendMessageToDevice(DeviceHandler.RUN_UPDATING_DATABASES);
    }
};

const currentUserChanged = () => {
    console.log("====> Init WebApplication v0.1.0");
    // WINDOW.sendMessageToQueueForProcessing = sendMessageToQueueForProcessing;
    // sendMessageToDevice(DeviceHandler.WEB_APP_INIT);

    // if ((window as any).webkit?.messageHandlers?.handlerForWebAppInit) {
    //     (window as any).webkit.messageHandlers.handlerForWebAppInit.postMessage({});
    // }
};

// Functions for processing the message queue of the native part of the application
const createOrUpdateDetectedObjects = async (data: any, cbCreateOrUpdateDetectedObjects: ProcessFunctionCb<any>) => {

    let objects = data.objects;
    let native_observer_id = data.observerID;
    let visibilityDetectedObject: string = "";
    let parentPermissions: Permissions | undefined = undefined;

    if (!native_observer_id) {
        return cbCreateOrUpdateDetectedObjects("The observer ID is missing");
    }

    const cameraZoneParams = {
        object_type: ["CameraPlanZone", "CameraMapZone"],
        limit: 0
    };

    let listCameraZone: ObjectItem[];

    let observer = await getNativeObjectById(native_observer_id, {skipChildrenTransform: true});
    const stored_observer_id = observer.object_id;
    if (observer?.properties?.["visibility_detected_object"].value) {
        visibilityDetectedObject = observer.properties?.["visibility_detected_object"].value as string;

        if (visibilityDetectedObject === "As a parent" && observer.permissions) {
            parentPermissions = {...observer.permissions}
        }
    }

    try {
        listCameraZone = await getNativeObjects(cameraZoneParams);

        listCameraZone = listCameraZone.filter((item) => {
            return item.properties?.image_polygon?.value?.object_id === stored_observer_id;
        });
    } catch (e) {
        listCameraZone = [];
    }

    let childrenIds: string[] = [];
    let newObjectDetected = false;

    for (const object of objects) {
        childrenIds.push(object.object_id);
        if (!newObjectDetected && object.first_detection) {
            newObjectDetected = true;
        }
    }

    if (newObjectDetected && childrenIds.length) {
        const message: Message = {
            id: v4(),
            handler: "AddChildrenToObserver",
            data: {
                childrenIds: childrenIds,
                storedObserverID: stored_observer_id
            }
        };
        detectedObjectsQueue.push(message);
    }

    for (const object of objects) {
        try {
            const locationProperties = await addPointOnPlanMapForDetectedObject(object, listCameraZone, observer);
            Object.assign(object.properties, locationProperties);
            object.observer_id = stored_observer_id;
            if (observer.notifications?.state !== false) {
                object.observer_name = observer.object_name;
            }
            // console.log("Detected-Object->>>", object);

            if (object.properties?.image_bounds?.value?.object_id) {
                object.properties.image_bounds.value.object_id = stored_observer_id;
            }

            const messageId = object.object_id + (object.first_detection ? "_first" : "");
            if (object.first_detection) {

                switch (visibilityDetectedObject) {
                    case "As a parent":
                        if (!!parentPermissions) {
                            object.permissions = {...parentPermissions}
                        }
                        break;
                    case "Public":
                        const epoch = Date.now();
                        object.permissions = {
                            perm_id: v4(),
                            updated: epoch,
                            public_access: {
                                updated: epoch,
                                access_level: AccessLevel.READ
                            }
                        };
                        break;
                    default:
                        break;
                }
            }

            const message: Message = {
                id: messageId,
                handler: "CreateOrUpdateDetectedObject",
                data: object
            };

            detectedObjectsQueue.push(message);
        } catch (err) {
            console.error("CreateOrUpdateDetectedObjects Err:", err);
        }
    }

    cbCreateOrUpdateDetectedObjects(null, true);
};

export const deleteDetectedObjects = async (data: any, cbDeleteDetectedObjects: ProcessFunctionCb<any>) => {

    let noDetectableObjects = data.noDetectableObjects;
    let nativeObserverID = data.observerID;

    if (!nativeObserverID) {
        return cbDeleteDetectedObjects("The observer ID is missing");
    }
    if (!noDetectableObjects || !noDetectableObjects.length) {
        return cbDeleteDetectedObjects("List of objects to delete is empty");
    }
    const storedObserverID = await convertNativeIdToStoredId(nativeObserverID);

    const message: Message = {
        id: v4(),
        handler: "DeleteDetectedObject",
        data: {
            noDetectableObjects: noDetectableObjects,
            storedObserverID: storedObserverID
        }
    };
    detectedObjectsQueue.push(message);

    for (let noDetectableObject of noDetectableObjects) {
        const message: Message = {
            id: noDetectableObject,
            handler: "ClearQueue",
            data: null
        };

        detectedObjectsQueue.push(message);
    }

    cbDeleteDetectedObjects(null, true);
};

const createOrUpdateObject = async (object: any, cbCreateOrUpdateObject: ProcessFunctionCb<any>) => {
    const parentObjectId = object.parent_id;
    const nativeObjectId = object.object_id;
    delete object.parent_id;

    try {
        await postNativeObject(object);
        if (parentObjectId) {
            await postNativeObjectChildren(parentObjectId, [nativeObjectId]);
        }
        cbCreateOrUpdateObject(null, true)
    } catch (err) {
        console.log("CreateOrUpdateObject err ", err);
        cbCreateOrUpdateObject(err)
    }
};

const getAndSendCameras = async (iDeviceID: string) => {
    console.log("iDeviceID_ ", iDeviceID);

    try {
        const result = await getNativeObjectById(iDeviceID);

        if (!result?.children) {//[AM] this is correct for Mac ?
            throw new Error(`iDevice ${iDeviceID} does not contain a cameras.`);
        }

        let params: Params = {
            children: result.children,
        };

        let _objects = await getNativeObjects(params);

        sendMessageToDevice(DeviceHandler.SET_CAMERAS, _objects);

    } catch (e) {
        console.log("getAndSendCameras err: ", e);
        sendMessageToDevice(DeviceHandler.INIT_OBJECT_BY_ID, {
            objectId: iDeviceID,
        });
    }
};

const initialCreateOrUpdateIDevice = async (initialData: any, cbInitialCreateOrUpdateIDevice: ProcessFunctionCb<any>) => {
    let {objects, links} = initialData;

    try {
        for (const link of links) {
            const listIds = [...link.childId, link.parentId];
            const linkedObjects: { [key: string]: ObjectItem } = {};

            try {
                for (const id of listIds) {
                    const foundObject = objects.find((object: ObjectItem) => {
                        return object.object_id === id;
                    });

                    if (!foundObject) {
                        continue;
                    }

                    linkedObjects[id] = foundObject;
                }

                await postNativeObjectsHierarchy(linkedObjects, links);
            } catch (e) {
                return Promise.reject(e);
            }
        }

    } catch (err) {
        return cbInitialCreateOrUpdateIDevice(err);
    }

    if (isSignedIn()) {
        void getAndSendCameras(getDeviceId());
    }

    cbInitialCreateOrUpdateIDevice(null, true);
};

const setIDeviceInformation = (device: any, cbSetIDeviceInformation: ProcessFunctionCb<any>) => {
    console.log("iDevice (object)", device);

    setDeviceInfo(device);
    setDeviceId(device.deviceId);
    cbSetIDeviceInformation(null, true);
};

const willEnterForeground = (data: any, cbWillEnterForeground: ProcessFunctionCb<any>) => {
    // console.log("willEnterForeground " + JSON.stringify(data));

    handleEnterForeground();

    cbWillEnterForeground(null, true);
};

const updateObjectImage = async (object: any, cbUpdateTheObjectImage: ProcessFunctionCb<any>) => {
    try {
        if (!object.notPostToCloud) {
            const epoch = new Date().getTime();
            const message: Message = {
                id: object.object_id + "_image",
                handler: object.object_id,
                data: {
                    properties: object.properties,
                    epoch: epoch
                }
            };

            imageToCacheQueueWorkerPool.push(message);
        }

        delete object.notPostToCloud;

        // In current logic, images will be published to UI for each received from the iOS message.
        // Without saving to LDB.
        // If it will be slow down UI, then should be added Queue with delay, ex. 200ms [AS]
        await pubNativeObjectProperties(object.object_id, object.properties, object);

        cbUpdateTheObjectImage(null, true);
    } catch (err) {
        console.log("updateObjectImage err ", err);
        cbUpdateTheObjectImage(err);
    }
};

const updateObjects = async (objects: any, cbUpdateObjects: ProcessFunctionCb<any>) => {

    for (const object of objects) {
        try {
            delete object.parent_id;

            const message: Message = {
                id: object.object_id + '_keepalive',
                handler: object.object_id,
                data: object
            };

            keepAliveQueue.push(message);
        } catch (err) {
            console.log("updateObjects err ", err);
        }
    }
    // //[AM]it is necessary to provide for the division into groups of no more than 100 objects
    // // console.log(">>>>> Number of objects is " + objects.length);
    // const message: Message = {
    //     id: v4(),
    //     handler: "batch",
    //     data: objects
    // };
    //
    // keepAliveQueue.push(message);

    cbUpdateObjects(null, true);
};

const updatePropertiesOfObject = async (object: any, cbUpdatePropertiesOfObject: ProcessFunctionCb<any>) => {

    // console.log(">>>>> updatePropertiesOfObject >>>>> " + object.object_id + " : " + JSON.stringify(object.properties));
    if (!object.object_id || !object.properties) {
        return cbUpdatePropertiesOfObject("Incorrect input data for postNativeObjectProperties");
    }

    try {
        await postNativeObjectProperties(object.object_id, object.properties, object.client_id);
        cbUpdatePropertiesOfObject(null, true);
    } catch (err) {
        console.log("updatePropertiesOfObject err: ", err);
        handleErrorUpdate(err, object.object_id, cbUpdatePropertiesOfObject);
    }
};

//Other and auxiliary functions

// [IM] copy of inPolygon from "../Components/Objects/SiteMap/MapUtils"
// to remove dependency from Components
// Should this be moved to Utils file?
const inPolygon = (pointOnMap: { x: number; y: number }, polygon: { x: number; y: number }[]): boolean => {
    let {x, y} = pointOnMap;
    let inPolygon = false;

    let j = polygon.length - 1;
    for (let i = 0; i < polygon.length; i++) {
        const point_x = polygon[i].x;
        const point_y = polygon[i].y;
        const prev_point_x = polygon[j].x;
        const prev_point_y = polygon[j].y;
        if (
            ((point_y <= y && y < prev_point_y) || (prev_point_y <= y && y < point_y)) &&
            x > ((prev_point_x - point_x) * (y - point_y)) / (prev_point_y - point_y) + point_x
        ) {
            inPolygon = !inPolygon;
        }
        j = i;
    }
    return inPolygon;
};

const checkPointInPolygon = (point: PointTuple, bounds: PointTuple[]) => {
    const inputPoint = {
        x: point[0],
        y: point[1]
    };

    const inputBounds = bounds.map((item) => {
        return {x: item[0], y: item[1]}
    });

    return inPolygon(inputPoint, inputBounds);
};

// [IM] copy of MM_PER_PIXEL from "../Components/FloorPlanEditor/Constants"
// to remove dependency from Components
// Should we research an ability to completely exclude using this constant here?
const MM_PER_PIXEL = 20;

// [IM] Should these calculations be done out of main queue?
const addPointOnPlanMapForDetectedObject = async (object: ObjectItem, listCameraZone: ObjectItem[], observer: ObjectItem) => {
    const relativePoint = calculateRelativePointForDetectedObject(object.properties.image_bounds.value.points);

    const pointProperties: { [key: string]: PropertyItem } = {};

    for (const zone of listCameraZone) {
        if (pointProperties.hasOwnProperty("map_point") && pointProperties.hasOwnProperty("plan_polygon")) {
            continue;
        }

        const insidePolygon = checkPointInPolygon(relativePoint, zone.properties.image_polygon.value.points);

        if (insidePolygon && zone.object_type === "CameraMapZone" && !pointProperties.hasOwnProperty("map_point")) {
            try {
                const map_point = await getLocationDetectedObject({
                    relativePoint: relativePoint,
                    inImage: observer?.properties?.image?.value,
                    inPoints: zone.properties.image_polygon.value.points,
                    outPoints: zone.properties.map_polygon.value,
                    invertedPoints: true,
                });

                if (Array.isArray(map_point)) {
                    pointProperties.map_point = generatePropertyItem({
                        key: "map_point",
                        name: "Map Location",
                        type: "MapPoint",
                        value: map_point,
                        visibility: ["card", "parent", "details"],
                    });
                }
            } catch (e) {
            }
        }

        if (insidePolygon && zone.object_type === "CameraPlanZone" && !pointProperties.hasOwnProperty("plan_polygon")) {
            const commonLocationOnPlanDetectedParams = {
                relativePoint: relativePoint,
                inImage: observer?.properties?.image?.value,
                inPoints: zone.properties.image_polygon.value.points,
                invertedPoints: false,
            };

            const planPointValue: { [key: string]: PointTuple } = {};

            for (let parentId in zone.properties.plan_polygon.value) {
                if (!zone.properties.plan_polygon.value.hasOwnProperty(parentId)) {
                    continue;
                }

                const locationOnPlanDetectedParams = JSON.parse(JSON.stringify(commonLocationOnPlanDetectedParams));

                locationOnPlanDetectedParams.outPoints = zone.properties.plan_polygon.value[parentId].map((point: PointTuple) => {
                    return [point[0] / MM_PER_PIXEL, point[1] / MM_PER_PIXEL];
                });

                try {
                    const plan_point = await getLocationDetectedObject(locationOnPlanDetectedParams);
                    if (Array.isArray(plan_point)) {
                        planPointValue[parentId] = plan_point;
                    }
                } catch (e) {
                }
            }

            if (Object.keys(planPointValue).length !== 0) {
                pointProperties.plan_point = generatePropertyItem({
                    key: "plan_point",
                    name: "Plan Location",
                    type: "PlanPoint",
                    value: planPointValue,
                    visibility: ["card", "parent", "details"],
                });
            }
        }
    }

    return pointProperties;
};

const setCurrentClientConfig = () => {

    const product_id = getPlanInfo().name.toLocaleLowerCase();
    const constraints = CONSTRAINTS_BY_PLAN[product_id];

    objectPropertyToCacheTimeout = product_id !== "free" ? CAMERA_STREAM_TIMEOUT.pro : CAMERA_STREAM_TIMEOUT.free;
    imageToCacheQueueWorkerPool = new QueueWorkerPool(propertyToCacheWorker, objectPropertyToCacheTimeout);

    if (isSignedIn()) {
        sendMessageToDevice(DeviceHandler.SET_CLIENT_ID, {clientID: getClientId()});
        sendMessageToDevice(DeviceHandler.SET_CLIENT_CONFIG, {
            clientID: getClientId(),
            maxResolution: constraints.maxResolution,
            maxNumberOfActiveIPCameras: constraints.maxNumberOfActiveIPCameras,
        });
    }
};

const addingNewCamera = async (newCamera: ObjectItem, parentId: String) => {
    let reattempt = 0;
    const isCameraOfCurrentDevice = async () => {
        try {
            reattempt += 1;
            const iDevice = await getNativeObjectById(getDeviceId());
            if (iDevice?.children.includes(newCamera.object_id)) {
                sendMessageToDevice(DeviceHandler.ADD_NEW_CAMERA, {newCamera: newCamera});
            } else if (reattempt < 7) {
                setTimeout(isCameraOfCurrentDevice, 5000);
            }
        } catch (e) {
        }
    };

    if (parentId) {
        const storedDeviceId = await convertNativeIdToStoredId(getDeviceId());

        if (storedDeviceId === parentId) {
            sendMessageToDevice(DeviceHandler.ADD_NEW_CAMERA, {newCamera: newCamera});
        }
    } else {
        void isCameraOfCurrentDevice();
    }
};

const updateDeviceCameras = async (device: ObjectItem) => {
    const childrenList = (device.children || [])
        .filter((child: any) => {
            const unbind = getEpochTime(child.unbind);
            const bind = getEpochTime(child.bind);

            return !unbind || bind > unbind;
        })
        .map((child: any) => child.id);

    let params: Params = {
        children: childrenList,
    };

    let children = await getNativeObjects(params);

    const cameras = children.filter((child: BodyObjectItem) => {
        return child.object_type && ["IPCamera", "FrontCamera", "BackCamera"].includes(child.object_type);
    });

    sendMessageToDevice(DeviceHandler.SET_CAMERAS, cameras);
};

const sendMessageToDevice = (handler: DeviceHandler, message?: Object) => {
    if (!isHandlerExist(handler)) {
        return;
    }

    WEBKIT.messageHandlers[handler].postMessage(message || {});
};

const signOutUser = () => {
    sendMessageToDevice(DeviceHandler.SIGN_OUT);
};

const changeDesignMode = (darkMode: Boolean) => {
    sendMessageToDevice(DeviceHandler.SET_CLIENT_CONFIG, {
        designMode: darkMode ? "Dark" : "Light"
    });
};

// Purchases
const getiOSPurchasesSubscriptions = () => {
    return new Promise((resolve, reject) => {
        if (!isDevice() || !isiDeviceHost()) {
            reject("Device not defined");
        }

        WINDOW.callbackForGetiOSSubscriptions = (data: string) => {
            try {
                let message = JSON.parse(data) as Message;

                resolve(message.data);
            } catch (e) {
                reject(e);
            }
        };

        sendMessageToDevice(DeviceHandler.GET_SUBSCRIPTIONS);
    });
};

const upgradeiOSPurchaseSubscription = (id: string): Promise<IAPData> => {
    return new Promise((resolve, reject) => {
        if (!isDevice() || !isiDeviceHost()) {
            reject("Device not defined");
        }

        WINDOW.callbackForPostiOSSubscriptionUpgrade = (data: string) => {
            try {
                let message = JSON.parse(data) as Message;

                resolve(message.data);
            } catch (e) {
                reject(e);
            }
        };

        sendMessageToDevice(DeviceHandler.UPGRADE_SUBSCRIPTION, {
            productIdentifier: id
        });
    });
};

const restoreiOSPurchases = (): Promise<IAPData> => {
    return new Promise((resolve, reject) => {
        if (!isDevice() || !isiDeviceHost()) {
            reject("Device not defined");
        }

        WINDOW.callbackForRestoreiOSPurchases = (data: string) => {
            try {
                let message = JSON.parse(data) as Message;

                resolve(message.data);
            } catch (e) {
                reject(e);
            }
        };

        sendMessageToDevice(DeviceHandler.RESTORE_PURCHASES);
    });
};
// Purchases

const requestDeviceToken = () => {
    sendMessageToDevice(DeviceHandler.GET_DEVICE_TOKEN);
};

const configureDeviceInfo = () => {
    sendMessageToDevice(DeviceHandler.GET_DEVICE_INFO);

    if (!isDevice()) {
        if (WEBKIT?.messageHandlers?.handlerForWebAppInit) {
            return;
        }

        setDeviceId();
    }
};

export {
    registerDevice,
    currentUserChanged,
    isDevice,
    isHandlerExist,
    // isiOSHost,
    // isMacOSHost,
    isiDeviceHost,
    setCurrentClientConfig,
    signOutUser,
    changeDesignMode,
    addingNewCamera,
    updateDeviceCameras,
    sendMessageToDevice,

    // Purchases
    getiOSPurchasesSubscriptions,
    upgradeiOSPurchaseSubscription,
    restoreiOSPurchases,

    requestDeviceToken,
    configureDeviceInfo,
};
