import React from "react";
import {Button, Dialog, Typography, useMediaQuery, useTheme} from "@material-ui/core";
import DialogTitle from "../DialogUtils/DialogTitle";
import {useDialogStyles} from "../DialogStyles";
import {Theme, withStyles} from "@material-ui/core/styles";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import {Link} from "react-router-dom";

const DialogActions = withStyles((theme: Theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

type InstallAppDialogProps = {
    showInstallAppDialog: boolean;
    handleShowInstallAppDialog(value: boolean): void;
}
const InstallAppDialog = (props: InstallAppDialogProps) => {

    const {showInstallAppDialog, handleShowInstallAppDialog} = props;

    const classes = useDialogStyles();
    const theme = useTheme();
    const downXs = useMediaQuery(theme.breakpoints.down("xs"));

    const handleCloseDialog = () => handleShowInstallAppDialog(false);

    return (
        <Dialog
            fullWidth
            open={showInstallAppDialog}
            classes={{paper: downXs ? classes.dialogPaperXs : classes.dialogPaper}}
        >
            <DialogTitle
                titleText={"Please install the App"}
                handleCloseDialog={handleCloseDialog}
            />
            <DialogContent dividers>
                <Typography variant="body1" gutterBottom>
                    To purchase the Pro plan, you need to install the IOS application using this link.
                </Typography>
            </DialogContent>
            <DialogActions
                style={{justifyContent: 'center'}}
            >
                <Button
                    component={Link}
                    to="/purchases"
                    variant="contained"
                    color="primary"
                    onClick={handleCloseDialog}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>)
}

export default InstallAppDialog;
