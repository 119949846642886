import React from "react";
import WelcomeDialogContentBodyImg from "../WelcomeDialogContentBodyImg";
import {Grid, Typography} from "@material-ui/core";
import Link from "@material-ui/core/Link";
import {SlideType} from "../WelcomeDialogContent";

export const Slide3 = (props: SlideType) => {
    return (
        <>
            <Grid
                container
                spacing={3}
                direction="row"
                justifyContent="center"
                alignItems="flex-start">
                <Grid item xs={12} sm={6}>
                    <WelcomeDialogContentBodyImg
                        src={"/img/welcome_dialog/img_slide_3_dark.png"}
                        themePaletteType="dark"/>
                    <WelcomeDialogContentBodyImg
                        src={"/img/welcome_dialog/img_slide_3_light.png"}
                        themePaletteType="light"/>
                </Grid>
                <Grid item xs={12} sm>
                    <Typography
                        variant={props.xsSize ? "body1" : "h6"}
                        align="left"
                        gutterBottom={true}>
                        It’s always good to know in what part of your home or site the certain device is located. Set
                        the location of your device on the map or the floor plan and understand quickly to what part of
                        your place this device is related.
                    </Typography>
                    <Typography
                        variant={props.xsSize ? "body1" : "h6"}
                        align="left"
                        gutterBottom={true}>
                        <Link
                            href="https://kb.localmachines.io/users-guides/floor-plan/"
                            rel="noreferrer" target="_blank">
                            Local Machines. How to create a floor plan and place the devices on it</Link>
                    </Typography>
                    <Typography
                        variant={props.xsSize ? "body1" : "h6"}
                        align="left"
                        gutterBottom={true}>
                        <Link
                            href="https://kb.localmachines.io/users-guides/place-on-map/"
                            rel="noreferrer" target="_blank">
                            Local Machines. How to place the device on map</Link>
                    </Typography>

                </Grid>
            </Grid>
        </>
    )
}
