import type { ClientItem, UserItem, GetUserClientByIdParamsType, GetUsersParamsType } from "./Types";
import { getUserDeviceToken } from "./UserService";
import { copy } from "../Utils";
import { authRequest, REQUEST_TYPE, METHOD } from "../RequestService";
import {
    subscribe as subscribeMqtt,
    unsubscribe as unsubscribeMqtt,
    TOPIC_TYPES
} from "../MqttService";
import type { MQTTCallbackType } from "../MqttService";

const getRemoteProfile = async () => {
    return authRequest("user/profile", {
        method: METHOD.GET,
        requestType: REQUEST_TYPE.GET_USER_PROFILE
    });
};

const postRemoteProfile = async (user: UserItem) => {
    const body = copy(user);

    if (body.clients) {
        delete body.clients;
    }

    return authRequest("user/profile", {
        method: METHOD.POST,
        body: JSON.stringify(body),
        requestType: REQUEST_TYPE.POST_USER_PROFILE,
    });
};

const postRemoteUserStatus = async (client_id: string | null) => {
    const body = {
        client_id: client_id,
        device_token: getUserDeviceToken() ?? null,
        date: Date.now(),
    };

    return authRequest("user/status", {
        method: METHOD.POST,
        body: JSON.stringify(body),
        requestType: REQUEST_TYPE.POST_USER_STATUS,
    });
};

const getRemoteUserClientById = async (clientId: string, params: GetUserClientByIdParamsType) => {
    return authRequest(`user/client/${clientId}`, {
        method: METHOD.GET,
        query: params,
        requestType: REQUEST_TYPE.GET_USER_CLIENT_BY_ID,
    });
};

const postRemoteUserClientById = async (client: ClientItem, isLogin?: boolean) => {
    const body = {
        client_info: client,
        is_login: isLogin ? isLogin : undefined,
    };

    return authRequest(`user/client/${client.client_id}`, {
        method: METHOD.POST,
        body: JSON.stringify(body),
        requestType: REQUEST_TYPE.POST_USER_CLIENT,
    });
};

const deleteRemoteUserClientById = async (clientId: string) => {
    return authRequest(`user/client/${clientId}`, {
        method: METHOD.DELETE,
        requestType: REQUEST_TYPE.DELETE_USER_CLIENT,
    });
};

const getRemoteUsers = async (params: GetUsersParamsType) => {
    return authRequest("user/list", {
        method: METHOD.GET,
        query: params,
        requestType: REQUEST_TYPE.GET_LIST_USER,
    });
};

// APPLE DEVICE TOKEN
const postRemoteUserDeviceToken = async (device_token: string, platform: string) => {
    const body = {
        token: device_token,
        platform: platform
    };

    return authRequest("user/devicetoken", {
        method: METHOD.POST,
        body: JSON.stringify(body),
        requestType: REQUEST_TYPE.POST_USER_DEVICE_TOKEN,
    });
};

const deleteRemoteUserDeviceToken = async (device_token: string, platform: string) => {
    const body = {
        token: device_token,
        platform: platform
    };

    return authRequest("user/devicetoken", {
        method: METHOD.DELETE,
        body: JSON.stringify(body),
        requestType: REQUEST_TYPE.DELETE_USER_DEVICE_TOKEN,
    });
};

// UserRemoteMqttService
const subscribeIAPUserStatus = (user_id: string, subscriptionId: string, callback: MQTTCallbackType) => {
    if (!user_id) {
        return;
    }

    const keys = {
        user_id: user_id,
        subscription_id: subscriptionId,
    };

    subscribeMqtt(TOPIC_TYPES.IAP, keys, callback);
};

const unsubscribeIAPUserStatus = (user_id: string, subscriptionId: string) => {
    if (!user_id) {
        return;
    }

    const keys = {
        user_id: user_id,
        subscription_id: subscriptionId,
    };

    unsubscribeMqtt(TOPIC_TYPES.CLIENTS, keys);
};

const subscribeClientLastActive = (clientId: string, subscriptionId: string, callback: MQTTCallbackType) => {
    const keys = {
        client_id: clientId,
        subscription_id: subscriptionId,
    };

    subscribeMqtt(TOPIC_TYPES.CLIENTS, keys, callback);
};

const unsubscribeClientLastActive = (clientId: string, subscriptionId: string) => {
    const keys = {
        client_id: clientId,
        subscription_id: subscriptionId,
    };

    unsubscribeMqtt(TOPIC_TYPES.CLIENTS, keys);
};
// UserRemoteMqttService

export {
    getRemoteProfile,
    postRemoteProfile,
    postRemoteUserStatus,
    getRemoteUserClientById,
    postRemoteUserClientById,
    deleteRemoteUserClientById,
    getRemoteUsers,
    postRemoteUserDeviceToken,
    deleteRemoteUserDeviceToken,

    subscribeIAPUserStatus,
    unsubscribeIAPUserStatus,
    subscribeClientLastActive,
    unsubscribeClientLastActive,
}
