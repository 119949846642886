import React from "react";
import {createStyles, Theme, withStyles} from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import {LinearProgressProps} from "@material-ui/core/LinearProgress/LinearProgress";

const CustomLinearProgress = withStyles((theme: Theme) =>
    createStyles({
        root: {
            height: 10,
            borderRadius: 5,
        },
        colorPrimary: {
            backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
        },
        bar: {
            borderRadius: 5,
            backgroundColor: '#1a90ff',
        },
    }),
)(LinearProgress);


const BorderLinearProgress = (props: LinearProgressProps) => {
    return <CustomLinearProgress {...props}/>;
};

export default BorderLinearProgress;
