import {DecoratorBlockNode, SerializedDecoratorBlockNode} from "@lexical/react/LexicalDecoratorBlockNode";
import {EditorConfig, ElementFormatType, LexicalEditor, NodeKey, Spread} from "lexical";
import * as React from "react";
import {BlockWithAlignableContents} from "@lexical/react/LexicalBlockWithAlignableContents";
import {useEffect, useState} from "react";
import {DefaultExtensionType, defaultStyles, FileIcon} from "react-file-icon";
import {Link} from "@material-ui/core";
import {getObjectById} from "../../../Services/ObjectsService/CRUD";
import {downloadFile} from "../../../Services/Utils";


type LocalMachinesComponentProps = Readonly<{
    className: Readonly<{
        base: string;
        focus: string;
    }>;
    format: ElementFormatType | null;
    nodeKey: NodeKey;
    objectID: string;
}>;

function LocalMachinesComponent({
    className,
    format,
    nodeKey,
    objectID,
}: LocalMachinesComponentProps) {
    const [isLMFileLoading, setIsLMFileLoading] = useState(true);
    const [fileName, setFileName] = useState("");
    const [fileType, setFileType] = useState("");
    const [fileExtension, setFileExtension] = useState("");
    const [fileContent, setFileContent] = useState("");

    const getFileById = async (fileId: string) => {
        const res = await getObjectById(fileId, {skipChildrenTransform: true});
        const value = res?.properties?.file?.value
        if (!value) {
            return;
        }
        setIsLMFileLoading(false);
        setFileName(value.name || "");
        setFileExtension(value?.name?.split('.').pop() || "");
        setFileType(value.type || "");
        setFileContent(value.content || "");
    }

    useEffect(() => {
        if (objectID) {
            getFileById(objectID).then(() => {});
        }
    }, [objectID]);



    const handleFileDownload = (e: any) => {
        e.preventDefault();

        if (!fileName) {
            return;
        }

        const arr = fileContent.split(',');
        const decodeStr = window.atob(arr[1] || '');
        let n = decodeStr.length;
        const u8arr = new Uint8Array(n);

        while(n--){
            u8arr[n] = decodeStr.charCodeAt(n);
        }

        downloadFile(fileName, u8arr, fileType);
    };

    return (
        <BlockWithAlignableContents
            className={className}
            format={format}
            nodeKey={nodeKey}>
            {isLMFileLoading ?
                "Loading LM File..." :
                <>
                    <div style={{width: '50px', padding: "10px", display: "inline-block", verticalAlign: "middle"}}>
                        <FileIcon extension={fileExtension} {...defaultStyles[fileExtension as DefaultExtensionType]} />
                    </div>
                    <Link onClick={(e) => handleFileDownload(e)} variant="body1" style={{cursor: "pointer"}}>
                        {fileName}
                    </Link>
                </>}
        </BlockWithAlignableContents>
    );
}

export type SerializedLocalMachinesNode = Spread<
    {
        objectID: string;
        type: 'localmachines';
        version: 1;
    },
    SerializedDecoratorBlockNode
    >;

export class LocalMachinesNode extends DecoratorBlockNode {
    __id: string;

    static getType(): string {
        return 'localmachines';
    }

    static clone(node: LocalMachinesNode): LocalMachinesNode {
        return new LocalMachinesNode(node.__id, node.__format, node.__key);
    }

    static importJSON(serializedNode: SerializedLocalMachinesNode): LocalMachinesNode {
        const node = $createLocalMachinesNode(serializedNode.objectID);
        node.setFormat(serializedNode.format);
        return node;
    }

    exportJSON(): SerializedLocalMachinesNode {
        return {
            ...super.exportJSON(),
            type: 'localmachines',
            version: 1,
            objectID: this.__id,
        };
    }

    constructor(id: string, format?: ElementFormatType, key?: NodeKey) {
        super(format, key);
        this.__id = id;
    }

    updateDOM(): false {
        return false;
    }

    getId(): string {
        return this.__id;
    }

    getTextContent(
        _includeInert?: boolean | undefined,
        _includeDirectionless?: false | undefined,
    ): string {
        return `https://www.localmachines.io/files/${this.__id}`;
    }

    decorate(_editor: LexicalEditor, config: EditorConfig): JSX.Element {
        const embedBlockTheme = config.theme.embedBlock || {};
        const className = {
            base: embedBlockTheme.base || '',
            focus: embedBlockTheme.focus || '',
        };
        return (
            <LocalMachinesComponent
                className={className}
                format={this.__format}
                nodeKey={this.getKey()}
                objectID={this.__id}
            />
        );
    }

    isInline(): false {
        return false;
    }
}

export function $createLocalMachinesNode(objectID: string): LocalMachinesNode {
    return new LocalMachinesNode(objectID);
}
