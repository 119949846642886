import React, {useCallback, useEffect, useState} from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import {getPurchasesSubscriptions, getPlanInfo, restorePurchases} from "../../Services/PurchasesService";
import type {PurchaseSubscriptionItem} from "../../Services/PurchasesService";
import PurchaseCard from "./PurchaseCard";
import {useGlobalNotificationContext} from "../Notification/NotificationProvider";
import type {SnackBarType} from "../../Services/NotificationService";
import {UserInfoType} from "../../Hooks/useUserProfile";
import {useHistory} from "react-router-dom";
import InstallAppDialog from "../Dialogs/InstallAppDialog/InstallAppDialog";
import {isDevice, isiDeviceHost} from "../../Services/DeviceService";
import {Box, Link, Typography} from "@material-ui/core";
import {APP_PRIVACY_POLICY, APP_TERMS_OF_USE} from "../../Services/Constants";

type PurchasesProps = {
    displayLoader(value: boolean): void;
    loader: boolean;
    userInfo: UserInfoType;
};

const useStyles = makeStyles((theme) => ({
    heroContent: {
        paddingTop: theme.spacing(4),
    },
}));

const free: PurchaseSubscriptionItem = {
    productIdentifier: "free",
    localizedTitle: "Free",
    price: 0,
    formattedPrice: "$0",
    localizedDescription: "Free, forever",
    listDescription: ""
};

const pro: PurchaseSubscriptionItem = {
    productIdentifier: "io.localmachines.app.sub.1monthpro",
    localizedTitle: "Pro",
    price: 24.49,
    formattedPrice: "$24.49",
    localizedDescription: "Raise the limits",
    listDescription: "Additionally to Free plan:"
}

const Purchases = (props: PurchasesProps) => {
    const classes = useStyles();
    const notify: SnackBarType = useGlobalNotificationContext();

    const {displayLoader} = props;

    const history = useHistory();

    const [subscriptions, setSubscriptions] = useState<PurchaseSubscriptionItem[]>([]);
    const [activeSubscriptionId, setActiveSubscriptionId] = useState<string>();

    const [showInstallAppDialog, setShowInstallAppDialog] = useState<boolean>(false)

    const onClickRestorePurchases = async () => {
        if (!isDevice()) {
            return handleShowInstallAppDialog(true);
        }

        try {
            displayLoader(true);
            await restorePurchases();
            displayLoader(false);
            history.replace("/profile");
        } catch (e: any) {
            displayLoader(false);
            console.error(e);
            notify.errorNotify(e?.message || JSON.stringify(e));
        }
    };

    const handleShowInstallAppDialog = useCallback((value: boolean) => setShowInstallAppDialog(value), []);

    useEffect(() => {

        displayLoader(true);

        let active = true;
        (async () => {
            let res: any = null;
            try { res = await getPurchasesSubscriptions(); } catch (e) {}

            displayLoader(false);
            if (!active) {
                return;
            }

            const listSubscription = (res || []).map((item: any) => {
                return {
                    productIdentifier: item.productIdentifier,
                    localizedTitle: !item.localizedTitle && pro.productIdentifier === item.productIdentifier ? pro.localizedTitle : item.localizedTitle,
                    formattedPrice: item.formattedPrice,
                    localizedDescription: !item.localizedDescription && pro.productIdentifier === item.productIdentifier ? pro.localizedDescription : item.localizedDescription
                }
            });

            if (!listSubscription.length && !isiDeviceHost()) {
                listSubscription.push(pro)
            }

            listSubscription.unshift(free);

            const {product_id} = getPlanInfo();

            setActiveSubscriptionId(product_id);

            setSubscriptions(listSubscription);

        })();

        return () => {
            active = false;
        };
    }, [displayLoader]);

    return (
        <>
            <CssBaseline/>
            <Container maxWidth="lg" component="main" className={classes.heroContent}>
                <Grid container spacing={5} alignItems="flex-end" justifyContent="center">
                    {subscriptions.map((tier: PurchaseSubscriptionItem) => (
                            <PurchaseCard
                                key={tier.productIdentifier}
                                title={tier.localizedTitle}
                                description={tier.localizedDescription}
                                listDescription={tier.listDescription}
                                identifier={tier.productIdentifier}
                                pricing={tier.formattedPrice}
                                active={activeSubscriptionId === tier.productIdentifier}
                                displayLoader={displayLoader}
                                handleShowInstallAppDialog={handleShowInstallAppDialog}
                            />
                        )
                    )}
                    <Grid item container xs={12} justifyContent="center">
                        <Typography>
                            By continue your accept our:
                            <Box>
                                <Typography component="span">
                                    <Link href={APP_TERMS_OF_USE}>
                                        {"Terms of Use   "}
                                    </Link>
                                    <Link href={APP_PRIVACY_POLICY}>
                                        {"   Privacy Policy"}
                                    </Link>
                                </Typography>
                            </Box>
                        </Typography>
                    </Grid>
                    {subscriptions.length !== 0 &&
                    <Grid item container xs={12} justifyContent="center">
                        <Grid item>
                            <Button variant="contained" color="primary" onClick={onClickRestorePurchases}>
                                Restore Purchases
                            </Button>
                        </Grid>
                    </Grid>
                    }
                </Grid>
            </Container>
            <InstallAppDialog
                showInstallAppDialog={showInstallAppDialog}
                handleShowInstallAppDialog={handleShowInstallAppDialog}
            />
        </>
    );
}

export default Purchases;
