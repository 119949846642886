import type {PointTuple, ServiceLifeType} from "../../Services/ObjectsService";
import {format} from "date-fns";

export type TypeListType = {
    type: string;
    key: string;
    name: string;
    def_value: string | number | PointTuple | PointTuple[] | boolean | ServiceLifeType;
};

const TypeList: TypeListType[] = [
    {type: "Image", name: "Image", key: "image", def_value: ""},
    {type: "Address", name: "Address", key: "address", def_value: ""},
    {type: "Elevation", name: "Elevation", key: "elevation", def_value: 0},
    {type: "MapPoint", name: "MapPoint", key: "map_point", def_value: ""},
    {type: "PlanPoint", name: "PlanPoint", key: "plan_point", def_value: []},
    {type: "MapPolygon", name: "MapBounds", key: "map_polygon", def_value: []},
    {type: "PlanPolygon", name: "PlanBounds", key: "plan_polygon", def_value: []},
    {type: "ImagePolygon", name: "ImageBounds", key: "image_polygon", def_value: []},
    {type: "ServiceLife", name: "ServiceLife", key: "", def_value: {start_time: format(new Date(), "MM/dd/yyyy"), life_time: 365, notification_time: 14, show_percentage: true}},
    {type: "Number", name: "Number", key: "", def_value: 0},
    {type: "String", name: "String", key: "", def_value: ""},
    {type: "Boolean", name: "Boolean", key: "", def_value: false}
];

export {
    TypeList,
}
