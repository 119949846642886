import {ReactNode, MouseEvent, useState} from "react";
import {ImagePolygonItem} from "../../../Services/ObjectsService";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Popover from "@material-ui/core/Popover";
import {popoverPosition, useCommonStyles} from "../common/Common";
import {ImagePolygonComponent} from "./ImagePolygonComponent";
import {UserInfoType} from "../../../Hooks/useUserProfile";

export const emptyImagePolygonItem: ImagePolygonItem = {
    points: [],
    object_id: "",
};

export type ImagePolygonProps = {
    propertyKey: string;
    label: string;
    value?: ImagePolygonItem;
    variant?: string;
    maxWidth?: number | string;
    saveValue(key: string, value: ImagePolygonItem): void;
    isDisabled?: boolean;
    property_id?: string;
    settings?: ReactNode;
    userInfo: UserInfoType;
};

const ImagePolygon = (props: ImagePolygonProps) => {
    const commonClasses = useCommonStyles();

    const {
        propertyKey,
        property_id,
        variant,
        label,
        maxWidth,
        value,
        saveValue,
        isDisabled,
        settings,
        userInfo
    } = props;

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [imagePolygonValue, setImagePolygonValue] = useState<ImagePolygonItem>(value || emptyImagePolygonItem);

    const localVariant =
        !variant || !["control", "popover", "propertyCard", "title"].includes(variant) ? "control" : variant;

    const handleSavedValue = (_value: ImagePolygonItem) => {
        saveValue(propertyKey, _value);
    };

    const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
        saveValue(propertyKey, imagePolygonValue);
    };

    const displayPopover = Boolean(anchorEl);
    const popoverId = displayPopover ? propertyKey : undefined;

    if (localVariant === "control") {
        return (
            <Grid container direction="column" justifyContent="center">
                <Grid item container direction="row" justifyContent="center" alignItems="center">
                    {settings}
                    <Typography>{label || "Image Polygon"}</Typography>
                </Grid>
                <Box border={1} className={commonClasses.border}>
                    <ImagePolygonComponent
                        value={value}
                        property_id={property_id}
                        maxWidth={maxWidth}
                        saveValue={handleSavedValue}
                        isDisabled={isDisabled}
                        userInfo={userInfo}
                    />
                </Box>
            </Grid>
        );
    } else if (["propertyCard", "title"].includes(variant as string)) {
        return (
            <ImagePolygonComponent
                value={value}
                property_id={property_id}
                maxWidth={maxWidth}
                saveValue={handleSavedValue}
                isDisabled
                isPropertyCard
                userInfo={userInfo}
            />
        );
    } else {
        return (
            <>
                <Typography component="div">
                    <Grid
                        component="label"
                        container
                        alignItems="center"
                        spacing={1}
                        onClick={handlePopoverOpen}
                        className={commonClasses.cursorPointer}
                        wrap="nowrap"
                    >
                        <Grid item>
                            <Box fontSize="small">{label}</Box>
                        </Grid>
                        <Grid item>
                            <Box fontSize="small">{imagePolygonValue.points.join(", ")}</Box>
                        </Grid>
                    </Grid>
                    <Popover
                        id={popoverId}
                        open={displayPopover}
                        anchorEl={anchorEl}
                        onClose={handlePopoverClose}
                        {...popoverPosition}
                    >
                        <Box width={"100%"} p={1}>
                            <ImagePolygonComponent
                                value={value}
                                property_id={property_id}
                                maxWidth={maxWidth}
                                saveValue={setImagePolygonValue}
                                isDisabled={isDisabled}
                                userInfo={userInfo}
                            />
                        </Box>
                    </Popover>
                </Typography>
            </>
        );
    }
};

export {ImagePolygon};
