import React from "react";
import { Button, DialogActions, FormControl, Grid, MenuItem, Select, useMediaQuery, useTheme } from "@material-ui/core";
import { Close as CloseIcon, Done as DoneIcon } from '@material-ui/icons';
import { useShareDialogStyles } from "../ShareDialogStyles";
import { ActionsProps } from "../ShareDialogTypes";

const Actions = (props: ActionsProps) => {

    const {
        disabled,
        isOwner,
        isWriter,
        accessType,
        agreeText,
        disagreeText,
        agreeIcon,
        disagreeIcon,
        onAgree,
        onClose,
        onChangeAccessType,
    } = props;

    const classes = useShareDialogStyles();

    const theme = useTheme();
    const downXs = useMediaQuery(theme.breakpoints.down("xs"));

    const onClickAgree = () => {
        onAgree();
    };

    const onClickDisagree = () => {
        onClose();
    };

    const obChangeSelect = (e: any) => {
        onChangeAccessType(e.target.value);
    };

    return (
        <DialogActions>
            <Grid container>
                <Grid item sm={4} xs={5}>
                    {isOwner && (
                        <FormControl variant="outlined" fullWidth={true} className={classes.marginLeft10}>
                            <Select
                                className={classes.select}
                                value={accessType}
                                disabled={disabled}
                                onChange={obChangeSelect}
                                margin={downXs ? "dense" : "none"}
                            >
                                <MenuItem key="restricted_acl" value="restricted">
                                    Restricted
                                </MenuItem>
                                <MenuItem key="public_acl" value="public">
                                    Public
                                </MenuItem>
                            </Select>
                        </FormControl>
                    )}
                </Grid>
                <Grid item sm={8} xs={7} className={classes.rightAlign}>
                    <Button
                        onClick={onClickDisagree}
                        disabled={disabled}
                        color="secondary"
                        variant="contained"
                        // size={downXs ? "small" : "medium"}
                        className={classes.button}
                        startIcon={ disagreeIcon ? disagreeIcon : <CloseIcon/>}

                    >
                        {downXs ? "" : disagreeText ? disagreeText : "Close"}
                    </Button>
                    {(isWriter === undefined || isWriter) && (
                        <Button
                            onClick={onClickAgree}
                            disabled={disabled}
                            color="primary"
                            variant="contained"
                            // size={downXs ? "small" : "medium"}
                            className={classes.button}
                            endIcon={agreeIcon ? agreeIcon : <DoneIcon/>}
                        >
                            {downXs ? "" : agreeText ? agreeText : "Done"}
                        </Button>
                    )}
                </Grid>
            </Grid>
        </DialogActions>
    );
};

export default Actions;
