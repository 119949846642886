import AsyncLock from "async-lock";
import {
    getLocalItemById,
    postLocalItem,
    getLocalItems
} from "../DatabaseService";
import type { UserDbItem } from "./Types";

let lockUserCache = new AsyncLock();

const postLocalUser = async (user: UserDbItem) => {
    return lockUserCache.acquire(user.user_id, async () => {
        return postLocalItem(user.user_id, user, { storeNames: "users", mode: "readwrite" });
    });
};

const getLocalUserById = async (userId: string) => {
    return getLocalItemById(userId, { storeNames: "users", mode: "readonly" });
};

const getLocalUsers = async () => {
    return getLocalItems({ storeNames: "users", mode: "readonly" });
};

export {
    postLocalUser,
    getLocalUserById,
    getLocalUsers
}
