import React, {Fragment} from "react";
import ListItem from "@material-ui/core/ListItem";
import {Grid, useMediaQuery, useTheme} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {UserPropertyItemContentProps} from "../../../UserProfileTypes";


const UserPropertyItemContent = (props: UserPropertyItemContentProps) => {
    const theme = useTheme();
    const downXs = useMediaQuery(theme.breakpoints.down("xs"));

    const { actions, content, title, editMode, disabled, divider, handleChangeMode } = props;

    let button = {button: true as true};
    if (editMode) {
        delete (button as {button?: true|undefined}).button;
    }

    return (
        <ListItem
            divider={divider}
            onClick={handleChangeMode}
            disabled={disabled}
            // disableGutters
            {...button}
        >
            <Grid
                container
                alignItems="center">
                <Grid item
                      xs={5} sm={2}
                      justifyContent="flex-start"
                      container>
                    <Typography variant={downXs ? "caption" : "overline"} color="textSecondary">
                        {title.toLocaleUpperCase()}
                    </Typography>
                </Grid>
                <Grid item
                      xs={6} sm={9}
                      justifyContent="flex-start" style={{wordBreak: "break-word"}}
                      container>
                    {content}
                </Grid>
            </Grid>
            <Fragment> {actions} </Fragment>
        </ListItem>
    )
};

export default UserPropertyItemContent;