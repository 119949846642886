import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
export const useSiteMapStyles = makeStyles((theme: Theme) =>
    createStyles({
        properties_open: {
            margin: 10,
            position: "fixed",
            top: 112,
            right: 0,
            zIndex: theme.zIndex.drawer + 1,
        },
        switch_layers: (props: {public_map: boolean}) => ({
            display: 'none',
            margin: 10,
            position: "fixed",
            bottom: props.public_map ? 10 : 80,
            left: 0,
            zIndex: theme.zIndex.drawer - 1,
            borderRadius: "4px",
            backgroundColor: "#fff",
            width: "30px",
            height: "30px",
        }),
        leafletContainer: {
            width: "100%",
            [theme.breakpoints.up("xs")]: {
                height: `calc(100% - 64px)`,
            },
            [theme.breakpoints.down("xs")]: {
                height: `calc(100% - 56px)`,
            },
        },
        leafletContainerEdit: {
            width: "100%",
            [theme.breakpoints.up("xs")]: {
                height: `calc(100% - 128px)`,
            },
            [theme.breakpoints.down("xs")]: {
                height: `calc(100% - 120px)`,
            },
        },
        icon: {
            paddingBottom: "15px",
        },
        currentLocation: {
            fontSize: "30px",
            color: "rgb(41, 98, 255)",
            filter: "drop-shadow(0 0 2px #000)",
        },
        markersContainer: {
            position: "absolute",
            zIndex: 501,
        },
        drawer: (props: {public_map: boolean, open: boolean}) => ({
            width: props.open ? "100%" : 0,
            flexShrink: 0,
            zIndex: theme.zIndex.drawer + 1,
            [theme.breakpoints.up("xsm")]: {
                width: props.open ? 350 : 0,
            },
        }),
        drawerPaper: {
            width: "100%",
            overflowX: "hidden",
            [theme.breakpoints.up("xsm")]: {
                width: 350,
            },
        },
        drawerHeader: {
            display: "flex",
            alignItems: "center",
            right: 0,
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
            justifyContent: "flex-start",
        },
        textFieldsContainer: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
        },
        textField: {
            marginTop: "15px",
            width: "90%",
        }
    })
);

export const useEditElementsStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            top: "auto",
            bottom: 0,
        },
        iconButton: {
            flexDirection: "column",
            padding: theme.spacing(1),
        },
        iconRoot: {
            padding: 0,
        },
    })
);
