import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

export const useUserProfileStyles = makeStyles((theme: Theme) =>
    createStyles({
        input: {
            display: "none"
        },
        largeAvatar: {
            width: theme.spacing(8),
            height: theme.spacing(8),
        },
        iconSecondaryColor: {
            color: theme.palette.text.secondary
        },
        publicKey: {
            wordBreak: "break-word"
        },
        nested: {
            paddingLeft: theme.spacing(2),
        },
        opened: {
            background: theme.palette.divider,
        },
        actionButton: {
            margin: theme.spacing(1),
        },
        dialogTitle: {
            marginRight: theme.spacing(4)
        },
        dialogTitleRoot: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        groupContainerRoot: {
            backgroundColor: theme.palette.background.paper,
            borderRadius: 7,
            borderColor: theme.palette.divider,

        },
        groupContainerRootLeftPaddingXs: {
            paddingLeft: theme.spacing(1),
        },
        groupContainerTitle: {
            padding: theme.spacing(2),
        },
        clientItemPaddingLeft: {
            paddingLeft: theme.spacing(2),
        },
        boldTypography: {
            fontWeight: 'bold'
        }
    })
);
