import React from "react";
import {Box, Chip, CircularProgress} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {SubObjectComponentProps} from "../../TypesCard";


 function CardContentItem(_props: SubObjectComponentProps) {

    const useStyles = makeStyles((theme) => ({
            icons: {
                display: "flex",
            },
            chipOutlined: {
                border: "none",
                color: (`${_props.isMarker ? "white" : theme.palette.text.primary} !important`) as any,
                display: 'grid',
                gridColumn: 2,
                gridTemplateColumns: '15px 100%',
                width: '100%',
                '&>.MuiChip-labelSmall': {
                    display: 'grid',
                    justifyContent: 'flex-start'
                },
            },
            chipMuiDisabled: {
                opacity: (`1 !important`) as any,
            },
            markerIcon: {
                color: (`${_props.isMarker ? "white" : theme.palette.text.primary} !important`) as any,
            }
        }
    ));

    const classes = useStyles();

    let icon = _props.isMarker ? (
        <div className={classes.markerIcon}>
            <span><i className={_props.icon}/></span>
        </div>
    ) : (
        <div>
            <span><i className={_props.icon}/></span>
        </div>
    )

    if (_props.loader && !_props.isDisabled) {
        icon = _props.isMarker ? (
            <div className={classes.markerIcon}>
                <CircularProgress size={17} color={'inherit'}/>
            </div>
        ) : (
            <div>
                <CircularProgress size={17}/>
            </div>
        )
    }

    return (
        <>
            <Box className={classes.icons}>
                <Chip
                    size="small"
                    variant="outlined"
                    icon={icon}
                    label={_props.label}
                    clickable={false}
                    disabled={_props.isDisabled}
                    classes={{outlined: classes.chipOutlined, disabled: classes.chipMuiDisabled}}
                />
            </Box>
        </>
    );
}

export default React.memo(CardContentItem)