export const BASE_PLAN_ELEMENTS = [
    'SOLID_WALL',
    'SINGLE_DOOR',
    'DOUBLE_DOOR',
    'SLIDING_DOOR',
    'SINGLE_WINDOW',
    'DOUBLE_WINDOW',
    'SLIDING_WINDOW',
    'TABLE',
    'CHAIR',
];

export const markerIconNameRatio: { [key: string]: { [key: string]: number } } = {
    plan: {
        default: 1.5,
        selected: 1.9
    },
    map: {
        default: 1.8,
        selected: 2.6
    }
};

export const planMarkerFontSizes: { [index: number]: number } = {
    0.5: 10,
    0.75: 12,
    1: 14,
    1.25: 16,
    1.5: 18,
    1.75: 20,
    2: 22,
    2.25: 24,
    2.5: 26,
    2.75: 28,
    3: 30,
    3.25: 32,
    3.5: 34,
    3.75: 36,
    4: 38,
    4.25: 40,
    4.5: 42,
    4.75: 44,
    5: 46,
};

export const planMarkerIconSizes: { [index: number]: number } = {
    0.5: 20,
    0.75: 24,
    1: 28,
    1.25: 32,
    1.5: 36,
    1.75: 40,
    2: 44,
    2.25: 48,
    2.5: 52,
    2.75: 56,
    3: 60,
    3.25: 64,
    3.5: 68,
    3.75: 72,
    4: 76,
    4.25: 80,
    4.5: 84,
    4.75: 88,
    5: 92,
};

export const mapMarkerFontSizes: { [index: number]: number } = {
    13: 10,
    14: 10,
    15: 11,
    16: 11,
    17: 12,
    18: 12,
    19: 12,
    20: 12,
    21: 12,
    22: 12,
};

export const mapMarkerIconSizes: { [index: number]: number } = {
    0: 8,
    1: 8,
    2: 8,
    3: 8,
    4: 9,
    5: 9,
    6: 9,
    7: 9,
    8: 10,
    9: 10,
    10: 10,
    11: 10,
    12: 11,
    13: 11,
    14: 11,
    15: 11,
    16: 12,
    17: 12,
    18: 12,
    19: 12,
    20: 12,
    21: 12,
    22: 12,
};
