export abstract class AbstractBinding {
    
    enabled: boolean;
    
    constructor() {
        this.enabled = true;
    }
    
    /**
    * Accepts the raw coordinates of the point and returns the 
    * bound coordinates calculated with the binding specific algorithm
    * @param point - PaperJS Point class
    * @return PaperJS point class with the bound coordinates
    */
    public bind(point: any): any {
        if(this.enabled) {
            return this.doBinding(point);
        }
        return point;
    }
    
    // This method to be overridden in the child class 
    // It must contain the actual binding algorithm
    protected abstract doBinding(point: any):any;
    public abstract clear():any;
    
    public enable(): void {
        this.enabled = true;
    }
    
    public disable(): void {
        this.enabled = false; 
    } 
    
    protected isEnabled(): boolean {
        return this.enabled;
    }
}