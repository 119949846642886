import React, {useCallback, useState} from "react";
import {Button, Grid} from "@material-ui/core";
import {Add as AddIcon, Delete as DeleteIcon} from "@material-ui/icons";
import {ObjectItem, PropertiesType, PropertyItem} from "../../../../Services/ObjectsService/Types";
import {UserInfoType} from "../../../../Hooks/useUserProfile";
import AlertDialog from "../../../Dialogs/AlertDialog/AlertDialog";
import ModalAddProperty from "../../ObjectDetails/ModalAddProperty";
import {deleteObject} from "../../../../Services/ObjectsService/CRUD";
import {OriginType} from "../../../../Services/ObjectsService/Constants";
import {useGlobalNotificationContext} from "../../../Notification/NotificationProvider";
import type {SnackBarType} from "../../../../Services/NotificationService";
import {useGlobalLoaderActionsContext} from "../../../../GlobalContext/GlobalContext";
import {getUserId} from "../../../../Services/UserService";

type ObjectFormActionsProps = {
    object: ObjectItem;
    userInfo: UserInfoType;
    parentId?: string;
    updateObjectByKey(key: string, value: string | number | boolean | PropertiesType): void;
    handleBackButton(): void;
};

const ObjectFormActions = (props: ObjectFormActionsProps) => {

    const notify: SnackBarType = useGlobalNotificationContext();
    const setLoader = useGlobalLoaderActionsContext();

    const {object, userInfo, parentId, updateObjectByKey, handleBackButton} = props;

    const [openAlertDialog, setOpenAlertDialog] = useState<boolean>(false);
    const [openPropertyDialog, setOpenPropertyDialog] = useState<boolean>(false);
    const [editProperty, setEditProperty] = useState<PropertyItem | undefined>();

    const handleAddPropertyDialogOpen = () => {
        setEditProperty(undefined);
        setOpenPropertyDialog(true);
    };

    const handleAlertDialogOpen = () => {
        setOpenAlertDialog(true);
    };

    const handleDialogClose = useCallback(() => {
        setEditProperty(undefined);
        setOpenAlertDialog(false);
        setOpenPropertyDialog(false);
    }, [setEditProperty, setOpenAlertDialog, setOpenPropertyDialog]);

    const deleteObjectButtonClicked = async () => {
        if (!openAlertDialog || !object.object_id)  {
            return;
        }

        setLoader(true);
        handleDialogClose();

        try {
            const res = await deleteObject(object.object_id, {origin: OriginType.USER});
            setLoader(false);
            if (res) {
                handleBackButton();
            }
            console.log("Object deleted successfully ", object.object_id);
            // notify.successNotify("Object deleted successfully");
        } catch (err: any) {
            setLoader(false);
            notify.errorNotify(err?.message || JSON.stringify(err));
        }
    };

    return (
        <>
            <Grid container item xs={12} sm={10} md={8} justifyContent={"space-between"}>
                <Grid item>
                    {(!object.object_id || object.owner_id === getUserId()) && (
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<AddIcon/>}
                            onClick={handleAddPropertyDialogOpen}
                        >
                            Add Property
                        </Button>
                    )}
                </Grid>
                <Grid item>
                    {object.object_id && object.owner_id === getUserId() && (
                        <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<DeleteIcon/>}
                            onClick={handleAlertDialogOpen}
                        >
                            Delete
                        </Button>
                    )}
                </Grid>
            </Grid>
            <AlertDialog
                open={openAlertDialog}
                dialogTitle={
                    "Are you sure you want to delete this " + object.object_type.toLocaleLowerCase() + "?"
                }
                handleAgree={deleteObjectButtonClicked}
                handleDisagree={handleDialogClose}
            />
            <ModalAddProperty
                open={openPropertyDialog}
                onClose={handleDialogClose}
                object={object}
                saveObjectByKey={updateObjectByKey}
                propertyItem={editProperty}
                userInfo={userInfo}
                parentId={parentId}
            />
        </>
    )
};

export default ObjectFormActions;
