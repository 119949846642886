import {differenceInSeconds, isValid, parse, add, subDays} from "date-fns";
import {v4} from "uuid";

import {
    ChatMessageType,
    ChildInfoItemType,
    ChildInfoType,
    ChildItemType,
    CryptoKeysType,
    generatePropertyItemParams,
    NativeIdInfo,
    ObjectItem,
    Permissions,
    PermissionsInfo,
    PermissionsItem,
    PermissionsItemInfo,
    PropertiesType,
    PropertyItem,
    ServiceLifeType,
    ValueEncryptedAESType,
    ValueEncryptedEpochType
} from "./ObjectsService/Types";

import { VERSION } from "./Constants";
import {getDeviceInfo, getUserId, getUserById} from "./UserService";
import {isDevice, sendMessageToDevice} from "./DeviceService";
import {
    AccessLevel,
    AVAILABLE_LOCAL_OBJECT_KEYS,
    AVAILABLE_NATIVE_PROPERTY_KEYS,
    AVAILABLE_OBJECT_KEYS,
    AVAILABLE_PRIVATE_ACL_KEYS,
    AVAILABLE_PROPERTY_KEYS,
    DeviceHandler,
    OBJECT_TYPE,
} from "./ObjectsService/Constants";

const BIND = "bind";
const UNBIND = "unbind";
const REACT_APP_SITE_NAME: string = process.env.REACT_APP_SITE_NAME || "";

const arrayReducer = (prev: any, next: any) => {
    return prev.concat(next);
};

const isEmail = (email: string) => {
    return !!email.match(
        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
    );
};

const filterObjectProperties = (a: PropertyItem, b: PropertyItem) => {
    const propertyRating: string[] = [
        "Icon",
        "PlanPolygon",
        "PlanPoint",
        "MapPolygon",
        "MapPoint",
        "Image",
        "ImagePolygon",
        "ImagePoint",
    ];

    let a_index = propertyRating.indexOf(a.type);
    let b_index = propertyRating.indexOf(b.type);

    if (a_index < b_index) {
        return 1;
    }
    if (a_index > b_index) {
        return -1;
    }
    return 0;
};

const checkPathIncludes = (pathname: string, paths: string[]) => {
    let isInclude = false;

    paths.forEach((item) => {
        if (pathname.includes(item)) {
            isInclude = true;
        }
    });

    return isInclude;
};

const checkIsOnline = (epoch: number | null | undefined, isOffline?: boolean) => {
    if (!epoch || !isValid(epoch) || isOffline) {
        return false;
    }

    return differenceInSeconds(new Date(), new Date(epoch)) <= 60;
};

const isObjectPublic = (permissions: Permissions | null | undefined): boolean => {
    return isActiveAccessLevel(permissions?.public_access?.access_level);
};

const accessLevelPriority = (accessLevel?: AccessLevel) => {
    switch (accessLevel) {
        case AccessLevel.OWNER:
            return 5;
        case AccessLevel.WRITE:
            return 4;
        case AccessLevel.CONTRIBUTE:
        case AccessLevel.CONTROL:
            return 3;
        case AccessLevel.COMMENT:
            return 2;
        case AccessLevel.READ:
            return 1;
        case AccessLevel.REVOKED:
        default:
            return 0;
    }
};

const isAllowedOperation = (accessLevel: AccessLevel | undefined, operation: AccessLevel): boolean => {
    return !!(accessLevel && accessLevelPriority(accessLevel) >= accessLevelPriority(operation));
};

const getUserAccessLevel = (permissions?: Permissions) => {
    const userId = getUserId();
    let privateAccessLevel = permissions?.private_acl?.[userId]?.access_level;
    const publicAccessLevel = permissions?.public_access?.access_level;

    if (privateAccessLevel === AccessLevel.CONTRIBUTE && permissions?.private_acl?.[userId] && !permissions.private_acl[userId].root) {
        // [IM] Up access level for objects from contributed hierarchy
        privateAccessLevel = AccessLevel.WRITE;
    }

    return accessLevelPriority(publicAccessLevel) > accessLevelPriority(privateAccessLevel) ? publicAccessLevel : privateAccessLevel;
};

const isUserHasAccess = (object: ObjectItem | undefined, operation: AccessLevel) => {
    if (object?.owner_id === getUserId()) {
        return true;
    }

    if (operation === AccessLevel.OWNER) {
        return false;
    }

    const userAccessLevel = getUserAccessLevel(object?.permissions);

    return isAllowedOperation(userAccessLevel, operation);
};

const isActiveAccessLevel = (access_level: AccessLevel | undefined): boolean => {
    return !!(access_level && access_level !== AccessLevel.REVOKED);
};

const chunkString = (str: string, length: number): string[] => {
    const result = str.match(new RegExp(".{1," + length + "}", "g"));

    if (!result) {
        return [];
    }

    return result;
};

const checkPropertyCardData = (_property: PropertyItem) => {
    return (
        ["MapPoint", "MapPolygon", "Image", "ImagePoint", "ImagePolygon", "PlanPoint", "PlanPolygon", "ServiceLife", "File"].includes(
            _property.type
        ) || (typeof _property.value === "number" && _property.type !== "Hue")
    );
};

const checkPropertyDetailsHeaderData = (_property: PropertyItem) => {
    return (
        ["MapPoint", "MapPolygon", "Image", "ImagePoint", "ImagePolygon", "PlanPoint", "PlanPolygon", "ServiceLife", "File"].includes(
            _property.type
        )
    );
};

const convertArrayToObject = (array: any, key: string) => {
    const initialValue = {};
    return array.reduce((obj: any, item: any) => {
        return {
            ...obj,
            [item[key]]: item,
        };
    }, initialValue);
};

const getDataExpirationTime = (dateNow?: number) => {
    return (dateNow || Date.now()) - 30 * 24 * 60 * 60 * 1000;
};

const mergeObjectChildren = (srcObject: ObjectItem, dstObject: any) => {
    let isChildrenUpdated: boolean = false;

    if (!dstObject.children) {
        dstObject.children = [];
    }

    const merged_children = dstObject.children.reduce((db_children: any, update_child: any) => {
        let type_child = typeof update_child;
        if (type_child === "string") {
            if (!db_children.find((child: any) => child.id === update_child)) {
                isChildrenUpdated = true;
                db_children.push({
                    id: update_child,
                    bind: (dstObject as ObjectItem)?.updated || new Date().toISOString(),
                });
            }
        } else if (type_child === "object") {
            let index = db_children.findIndex((child: any) => child.id === update_child.id);
            if (index === -1) {
                isChildrenUpdated = true;
                db_children.push(update_child);
            } else {
                const dbBind = getEpochTime(db_children[index].bind);
                const updateBind = getEpochTime(update_child.bind);

                if (dbBind < updateBind) {
                    isChildrenUpdated = true;
                    db_children[index].bind = new Date(update_child.bind).toISOString();
                }

                const dbUnbind = getEpochTime(db_children[index].unbind);
                const updateUnbind = getEpochTime(update_child.unbind);

                if (dbUnbind < updateUnbind) {
                    isChildrenUpdated = true;
                    db_children[index].unbind = new Date(update_child.unbind).toISOString();
                }
            }
        }
        return db_children;
    }, srcObject.children || []);

    // logic to removing old unbind children (older 1 month)
    const month_ago = getDataExpirationTime();

    dstObject.children = merged_children.filter((child: any) => {
        const unbind = getEpochTime(child.unbind);
        const bind = getEpochTime(child.bind);

        return !unbind || bind > unbind || (unbind > bind && month_ago < unbind);
    });

    return isChildrenUpdated;
};

const addChildrenFieldToDictionaryByKey = (id: string, key: "bind" | "unbind", value: number | undefined, dictionary: ChildInfoType) => {
    const epoch = getEpochTime(value);
    if (![BIND, UNBIND].includes(key) || !epoch || getEpochTime(dictionary?.[id]?.[key]) >= epoch) {
        return false;
    }

    if (!dictionary.hasOwnProperty(id)) {
        dictionary[id] = {};
    }
    dictionary[id][key] = epoch;
    return true;
};

const buildChildrenInfoFromChildren = (children: ChildItemType[] | undefined) => {
    const childrenInfo: ChildInfoType = {};

    for (const child of children || []) {
        if (!childrenInfo.hasOwnProperty(child.id)) {
            childrenInfo[child.id] = {};
        }

        addChildrenFieldToDictionaryByKey(child.id, BIND, child.bind, childrenInfo);
        addChildrenFieldToDictionaryByKey(child.id, UNBIND, child.unbind, childrenInfo);

        if (child.hasOwnProperty("removed")) {
            childrenInfo[child.id].removed = child.removed;
        }
    }

    return childrenInfo;
};

const buildChildrenFromChildrenInfo = (childrenInfo: ChildInfoType) => {
    const children: ChildItemType[] = [];

    for (const childId in childrenInfo || {}) {
        if (!childrenInfo.hasOwnProperty(childId)) {
            continue;
        }

        const childInfoItem = childrenInfo[childId];
        const child: ChildItemType = {
            id: childId
        };

        if (childInfoItem.hasOwnProperty(BIND)) {
            child.bind = childInfoItem.bind;
        }
        if (childInfoItem.hasOwnProperty(UNBIND)) {
            child.unbind = childInfoItem.unbind;
        }
        if (childInfoItem.hasOwnProperty("removed")) {
            child.removed = childInfoItem.removed;
        }

        children.push(child);
    }

    return children;
};

const cleanPermissionObjectFromRootKey = (permissions: Permissions) => {
    const cleanedPermissions: any = {
        private_acl: {},
        public_access: copy(permissions.public_access)
    };

    for (let i in permissions.private_acl) {
        cleanedPermissions.private_acl[i] = copy(permissions.private_acl[i]);
        delete cleanedPermissions.private_acl[i].root;
    }

    return cleanedPermissions;
};

const mergePermissions = (originalData: Permissions | undefined, inputData: Permissions | undefined) => {
    let mergedPermissions: Permissions;
    const updatedOriginal: PermissionsInfo = {};
    const updatedInput: PermissionsInfo = {};
    let original = originalData;
    let input = inputData;

    const expirationTime = getDataExpirationTime();

    let processedUsers: string[] = [];

    const checkForEmptyPermissions = (permissions: PermissionsInfo) => {
        if (Object.keys(permissions).length === 2 && permissions.perm_id && permissions.updated) {
            return {};
        }

        return permissions;
    };

    if (!original && !input) {
        return {
            updatedOriginal:    undefined,
            updatedInput:       undefined,
            mergedPermissions:  undefined
        }
    } else if (original && !input) {
        mergedPermissions = {...original};

        updatedInput.perm_id = original.perm_id;
        updatedInput.updated = original.updated;

        if (original.public_access) {
            updatedInput.public_access = {...original.public_access}
        }

        if (original.private_acl && Object.keys(original.private_acl).length) {

            let _actualPrivateAcl: { [key: string]: PermissionsItem } = {};

            for (const userId in original.private_acl) {
                if (original.private_acl.hasOwnProperty(userId)) {
                    if (original.private_acl[userId].access_level === AccessLevel.REVOKED && getEpochTime(original.private_acl[userId].updated) < expirationTime) {
                        if (!updatedOriginal.private_acl) {
                            updatedOriginal.private_acl = {}
                        }

                        updatedOriginal.private_acl[userId] = { "removed": true }
                    } else {
                        _actualPrivateAcl[userId] = {...original.private_acl[userId]}
                    }
                }
            }

            if (Object.keys(_actualPrivateAcl).length) {
                updatedInput.private_acl = {..._actualPrivateAcl};
                mergedPermissions.private_acl = {..._actualPrivateAcl}
            }
        }

        return {
            updatedOriginal:    checkForEmptyPermissions(updatedOriginal),
            updatedInput:       checkForEmptyPermissions(updatedInput),
            mergedPermissions:  mergedPermissions
        }
    } else if (!original && input) {
        mergedPermissions = {...input};

        updatedOriginal.perm_id = input.perm_id;
        updatedOriginal.updated = input.updated;

        if (input.public_access) {
            updatedOriginal.public_access = {...input.public_access}
        }

        if (input.private_acl && Object.keys(input.private_acl).length) {

            let _actualPrivateAcl: { [key: string]: PermissionsItem } = {};

            for (const userId in input.private_acl) {
                if (input.private_acl.hasOwnProperty(userId)) {
                    if (input.private_acl[userId].access_level === AccessLevel.REVOKED && getEpochTime(input.private_acl[userId].updated) < expirationTime) {
                        if (!updatedInput.private_acl) {
                            updatedInput.private_acl = {}
                        }

                        updatedInput.private_acl[userId] = { "removed": true }
                    } else {
                        _actualPrivateAcl[userId] = {...input.private_acl[userId]}
                    }
                }
            }

            if (Object.keys(_actualPrivateAcl).length) {
                updatedOriginal.private_acl = {..._actualPrivateAcl};
                mergedPermissions.private_acl = {..._actualPrivateAcl}
            }
        }

        return {
            updatedOriginal:    checkForEmptyPermissions(updatedOriginal),
            updatedInput:       checkForEmptyPermissions(updatedInput),
            mergedPermissions:  mergedPermissions
        }
    }

    original = original as Permissions;
    input = input as Permissions;

    const isDataDifferent = (getEpochTime(input?.updated) !== getEpochTime(original?.updated));
    const isInputDataUpToDate = (getEpochTime(input?.updated) > getEpochTime(original?.updated));

    mergedPermissions = {
        perm_id: (isDataDifferent && isInputDataUpToDate ? input?.perm_id : original?.perm_id) || v4(),
        updated: (isDataDifferent && isInputDataUpToDate ? input?.updated : original?.updated) || Date.now()
    };
    if (isDataDifferent) {
        if (isInputDataUpToDate) {
            updatedOriginal.perm_id = mergedPermissions.perm_id;
            updatedOriginal.updated = mergedPermissions.updated
        } else {
            updatedInput.perm_id = mergedPermissions.perm_id;
            updatedInput.updated = mergedPermissions.updated
        }
    }

    //block for merging "public_access" field
    if (original?.public_access || input?.public_access) {

        if (getEpochTime(original.public_access?.updated) !== getEpochTime(input.public_access?.updated)) {
            if (getEpochTime(input.public_access?.updated) > getEpochTime(original.public_access?.updated)) {
                mergedPermissions.public_access = input?.public_access || {
                    "updated": Date.now(),
                    "access_level": AccessLevel.REVOKED
                };
                updatedOriginal.public_access = {...mergedPermissions.public_access}
            } else {
                mergedPermissions.public_access = original?.public_access || {
                    "updated": Date.now(),
                    "access_level": AccessLevel.REVOKED
                };
                updatedInput.public_access = {...mergedPermissions.public_access}
            }
        } else {
            mergedPermissions.public_access = original?.public_access
        }
    }

    //block for merging "private_acl" field
    let _privateAcl: { [key: string]: PermissionsItem } = {};

    if (original?.private_acl && !input?.private_acl) {

        _privateAcl = {...original.private_acl}
    } else if (!original?.private_acl && input?.private_acl) {

        _privateAcl = {...input.private_acl}
    } else if (original?.private_acl && input?.private_acl) {

        for (const userId in original.private_acl) {
            if (original.private_acl.hasOwnProperty(userId)) {
                const originalUserPermission =  original.private_acl?.[userId] as PermissionsItem;
                if (input.private_acl.hasOwnProperty(userId)) {
                    const inputUserPermission =  input.private_acl?.[userId] as PermissionsItem;
                    if (getEpochTime(originalUserPermission?.updated) > getEpochTime(inputUserPermission?.updated)) {
                        _privateAcl[userId] = originalUserPermission
                    } else {
                        _privateAcl[userId] = inputUserPermission
                    }

                    if (originalUserPermission.root || inputUserPermission.root) {
                        _privateAcl[userId].root = true;
                    }
                } else if (Object.keys(originalUserPermission).length > 1) {
                    _privateAcl[userId] = originalUserPermission
                }
                processedUsers.push(userId);
            }
        }

        for (const userId in input.private_acl) {
            if (input.private_acl.hasOwnProperty(userId) && !processedUsers.includes(userId)) {
                _privateAcl[userId] = input.private_acl?.[userId] as PermissionsItem;
                processedUsers.push(userId);
            }
        }
    }

    if (Object.keys(_privateAcl).length) {
        let _actualPrivateAcl: { [key: string]: PermissionsItem } = {};

        for (const userId in _privateAcl) {
            if (_privateAcl.hasOwnProperty(userId) &&
               (_privateAcl[userId].access_level !== AccessLevel.REVOKED || getEpochTime(_privateAcl[userId].updated) >= expirationTime)) {

                _actualPrivateAcl[userId] = _privateAcl[userId]
            }
        }

        if (Object.keys(_actualPrivateAcl).length) {
            mergedPermissions.private_acl = {..._actualPrivateAcl}
        }
    }

    if (original?.private_acl && !input?.private_acl) {

        if (mergedPermissions.private_acl && Object.keys(mergedPermissions.private_acl).length) {
            updatedInput.private_acl = {...mergedPermissions.private_acl}
        }

        let updatedOriginalPrivateAcl: { [key: string]: PermissionsItemInfo } = {};
        for (const userId in  original.private_acl) {
            if (original.private_acl.hasOwnProperty(userId) &&
               (!mergedPermissions.private_acl || !mergedPermissions.private_acl.hasOwnProperty(userId))) {

                updatedOriginalPrivateAcl[userId] = { "removed": true }
            }
        }
        if (Object.keys(updatedOriginalPrivateAcl).length) {
            updatedOriginal.private_acl = {...updatedOriginalPrivateAcl}
        }

    } else if (!original?.private_acl && input?.private_acl) {

        if (mergedPermissions.private_acl && Object.keys(mergedPermissions.private_acl).length) {
            updatedOriginal.private_acl = {...mergedPermissions.private_acl}
        }

        let updatedInputPrivateAcl: { [key: string]: PermissionsItemInfo } = {};
        for (const userId in  input.private_acl) {
            if (input.private_acl.hasOwnProperty(userId) &&
               (!mergedPermissions.private_acl || !mergedPermissions.private_acl.hasOwnProperty(userId))) {

                 updatedInputPrivateAcl[userId] = { "removed": true }
            }
        }
        if (Object.keys(updatedInputPrivateAcl).length) {
            updatedInput.private_acl = {...updatedInputPrivateAcl}
        }
    } else if (original?.private_acl && input?.private_acl) {
        let updatedInputPrivateAcl: { [key: string]: PermissionsItemInfo } = {};
        let updatedOriginalPrivateAcl: { [key: string]: PermissionsItemInfo } = {};

        for (const userId in  input.private_acl) {
            if (input.private_acl.hasOwnProperty(userId)) {
                if (!mergedPermissions.private_acl || !mergedPermissions.private_acl.hasOwnProperty(userId)) {
                    updatedInputPrivateAcl[userId] = { "removed": true }
                }

                if (mergedPermissions.private_acl && mergedPermissions.private_acl.hasOwnProperty(userId) &&
                    (!original.private_acl.hasOwnProperty(userId) || getEpochTime(original.private_acl[userId].updated) < getEpochTime(mergedPermissions.private_acl[userId].updated)))
                {
                    updatedOriginalPrivateAcl[userId] = {...mergedPermissions.private_acl[userId]}
                }
            }
        }

        for (const userId in original.private_acl) {
            if (original.private_acl.hasOwnProperty(userId)) {
                if (!mergedPermissions.private_acl || !mergedPermissions.private_acl.hasOwnProperty(userId)) {
                    updatedOriginalPrivateAcl[userId] = { "removed": true }
                }

                if (mergedPermissions.private_acl && mergedPermissions.private_acl.hasOwnProperty(userId) &&
                    (!input.private_acl.hasOwnProperty(userId) || getEpochTime(input.private_acl[userId].updated) < getEpochTime(mergedPermissions.private_acl[userId].updated)))
                {
                    updatedInputPrivateAcl[userId] = {...mergedPermissions.private_acl[userId]};
                    updatedInputPrivateAcl[userId] = removeUnacceptableFields(updatedInputPrivateAcl[userId], "private_acl");
                }
            }
        }

        if (Object.keys(updatedInputPrivateAcl).length) {
            updatedInput.private_acl = {...updatedInputPrivateAcl}
        }
        if (Object.keys(updatedOriginalPrivateAcl).length) {
            updatedOriginal.private_acl = {...mergedPermissions.private_acl, ...updatedOriginalPrivateAcl}
        }
    }

    if (Object.keys(updatedOriginal).length && updatedOriginal.private_acl && !updatedOriginal.public_access && mergedPermissions.public_access) {
        updatedOriginal.public_access = {...mergedPermissions.public_access};
    }

    return {
        updatedOriginal:    checkForEmptyPermissions(updatedOriginal),
        updatedInput:       checkForEmptyPermissions(updatedInput),
        mergedPermissions:  mergedPermissions
    }
};

const mergeChildren = (current: ChildItemType[] | undefined, input: ChildItemType[] | undefined) => {
    const currentChildrenInfo = buildChildrenInfoFromChildren(current);
    const inputChildrenInfo = buildChildrenInfoFromChildren(input);

    const updatedCurrent: ChildInfoType = {};
    const updatedInput: ChildInfoType = {};
    const merged: ChildInfoType = {};
    const wasBound = new Set();
    const wasUnbound = new Set();

    for (const childrenInfo of [currentChildrenInfo, inputChildrenInfo]) {
        for (const childId in childrenInfo || {}) {
            if (!childrenInfo.hasOwnProperty(childId)) {
                continue;
            }

            const currentBind = getEpochTime(currentChildrenInfo?.[childId]?.bind);
            const currentUnbind = getEpochTime(currentChildrenInfo?.[childId]?.unbind);
            const inputBind = getEpochTime(inputChildrenInfo?.[childId]?.bind);
            const inputUnbind = getEpochTime(inputChildrenInfo?.[childId]?.unbind);

            const child: ChildInfoItemType = {};

            if (inputBind > currentBind) {
                child.bind = inputBind;
                if (addChildrenFieldToDictionaryByKey(childId, BIND, inputBind, updatedCurrent)) {
                    wasBound.add(childId);
                }
            }

            if (currentBind > inputBind) {
                child.bind = currentBind;
                addChildrenFieldToDictionaryByKey(childId, BIND, currentBind, updatedInput);
            }

            if (currentBind === inputBind) {
                child.bind = currentBind;
            }

            if (inputUnbind > currentUnbind) {
                child.unbind = inputUnbind;
                if (addChildrenFieldToDictionaryByKey(childId, UNBIND, inputUnbind, updatedCurrent)) {
                    wasUnbound.add(childId);
                }
            }

            if (currentUnbind > inputUnbind) {
                child.unbind = currentUnbind;
                addChildrenFieldToDictionaryByKey(childId, UNBIND, currentUnbind, updatedInput);
            }

            if (currentUnbind === inputUnbind) {
                child.unbind = currentUnbind;
            }

            addChildrenFieldToDictionaryByKey(childId, BIND, child?.bind, merged);
            addChildrenFieldToDictionaryByKey(childId, UNBIND, child?.unbind, merged);
            delete currentChildrenInfo[childId];
            delete inputChildrenInfo[childId];

            // handleChildItem(childId, currentChildrenInfo[childId], inputChildrenInfo[childId]);
        }
    }

    // logic to removing old unbind children (older 30 days)
    const expirationTime = getDataExpirationTime();

    for (const childId in merged) {
        if (!merged.hasOwnProperty(childId)) {
            continue;
        }

        const bind = getEpochTime(merged[childId].bind);
        const unbind = getEpochTime(merged[childId].unbind);

        if (!unbind || bind > unbind || (unbind > bind && expirationTime < unbind)) {
            continue;
        }

        for (const dictionary of [updatedCurrent, updatedInput]) {
            if (!dictionary.hasOwnProperty(childId)) {
                dictionary[childId] = {};
            }
            dictionary[childId].removed = true;
        }

        delete merged[childId];
    }

    return {
        updatedCurrentChildrenInfo: updatedCurrent,
        updatedInputChildrenInfo: updatedInput,
        mergedChildrenInfo: merged,
        updatedCurrentChildren: buildChildrenFromChildrenInfo(updatedCurrent),
        updatedInputChildren: buildChildrenFromChildrenInfo(updatedInput),
        mergedChildren: buildChildrenFromChildrenInfo(merged),
        wasBound: wasBound,
        wasUnbound: wasUnbound
    };
};

const mergeProperties = (originalData: PropertiesType | undefined, inputData: PropertiesType | undefined) => {
    const merged: PropertiesType = {};
    const updatedOriginal: PropertiesType = {};
    const updatedInput: PropertiesType = {};
    const original = originalData || {};
    const input = inputData || {};

    for (const propertyKey in input) {
        if (!input.hasOwnProperty(propertyKey)) {
            continue;
        }

        if (!original.hasOwnProperty(propertyKey) && propertyKey !== 'undefined') {
            updatedOriginal[propertyKey] = {...input[propertyKey]};
            merged[propertyKey] = {...input[propertyKey]};
            continue;
        }

        if (propertyKey === 'undefined') {
            updatedInput[propertyKey] = { ...input[propertyKey], removed: true };
            if (original.hasOwnProperty(propertyKey)) {
                updatedOriginal[propertyKey] = { ...input[propertyKey], removed: true };
            }
            continue;
        }

        const originalValueEpoch = getEpochTime(original[propertyKey].value_epoch);
        const inputValueEpoch = getEpochTime(input[propertyKey].value_epoch);
        const originalReportedValueEpoch = getEpochTime(original[propertyKey].reported_value_epoch);
        const inputReportedValueEpoch = getEpochTime(input[propertyKey].reported_value_epoch);

        // const originalFields = getPropertyFields(original[propertyKey]);
        const inputFields = getPropertyFields(input[propertyKey]);

        let value = {} as any;

        if (inputValueEpoch > originalValueEpoch) {
            value = {
                value: input[propertyKey].value,
                value_epoch: inputValueEpoch
            };
            updatedOriginal[propertyKey] = { ...inputFields, ...value };
            merged[propertyKey] = { ...inputFields, ...value };
        }

        if (originalValueEpoch > inputValueEpoch) {
            value = {
                value: original[propertyKey].value,
                value_epoch: originalValueEpoch
            };
            updatedInput[propertyKey] = {...inputFields, ...value};
            merged[propertyKey] = {...inputFields, ...value};
        }

        if (originalValueEpoch === inputValueEpoch) {
            value = {
                value: input[propertyKey].value,
                value_epoch: inputValueEpoch
            };
            merged[propertyKey] = { ...inputFields, ...value };
        }

        if (inputReportedValueEpoch > originalReportedValueEpoch) {
            if (!updatedOriginal.hasOwnProperty(propertyKey)) {
                updatedOriginal[propertyKey] = { ...inputFields, ...value };
            }
            updatedOriginal[propertyKey].reported_value = input[propertyKey].reported_value;
            updatedOriginal[propertyKey].reported_value_epoch = inputReportedValueEpoch;
            merged[propertyKey].reported_value = input[propertyKey].reported_value;
            merged[propertyKey].reported_value_epoch = inputReportedValueEpoch;
        }

        if (originalReportedValueEpoch > inputReportedValueEpoch) {
            if (!updatedInput.hasOwnProperty(propertyKey)) {
                updatedInput[propertyKey] = { ...inputFields, ...value };
            }
            updatedInput[propertyKey].reported_value = original[propertyKey].reported_value;
            updatedInput[propertyKey].reported_value_epoch = originalReportedValueEpoch;
            merged[propertyKey].reported_value = original[propertyKey].reported_value;
            merged[propertyKey].reported_value_epoch = originalReportedValueEpoch;
        }

        if (originalReportedValueEpoch === inputReportedValueEpoch && input[propertyKey].reported_value !== undefined) {
            merged[propertyKey].reported_value = input[propertyKey].reported_value;
            merged[propertyKey].reported_value_epoch = inputReportedValueEpoch;
        }
    }

    for (const propertyKey in original) {
        if (!original.hasOwnProperty(propertyKey) || input.hasOwnProperty(propertyKey)) {
            continue;
        }

        updatedInput[propertyKey] = {...original[propertyKey]};
        merged[propertyKey] = {...original[propertyKey]};
    }

    return {
        merged: merged,
        updatedInput: updatedInput,
        updatedOriginal: updatedOriginal
    };
};

const generatePropertyItem = (params: generatePropertyItemParams) => {
    const propertyItem: PropertyItem = {
        property_id: v4(),
        key: params.key,
        name: params.name,
        readable: params.readable !== undefined ? params.readable : true,
        type: params.type,
        value: params.value,
        visibility: params.visibility,
        writable: params.writable !== undefined ? params.writable : false,
    };

    if (params.hasOwnProperty("reported_value")) {
        propertyItem.reported_value = params.reported_value;
    }

    if (params.hasOwnProperty("icon")) {
        propertyItem.icon = params.icon;
    }

    if (params.hasOwnProperty("units")) {
        propertyItem.units = params.units;
    }

    if (params.hasOwnProperty("min")) {
        propertyItem.min = params.min;
    }

    if (params.hasOwnProperty("max")) {
        propertyItem.max = params.max;
    }

    return propertyItem;
};

function* sequenceGenerator(minVal: number, maxVal: number, step: number = 1) {
    let currentVal = minVal
    while (currentVal <= maxVal) {
        yield currentVal
        currentVal += step
    }
}

function hideCredentials (value: string) {
    return value.replace(/(rtsp):\/\/(?:([^\s@\/]+)@)?([^\s\/:]+)(?::([0-9]+))?(?:\/(.*))?/, '$1://$3:$4/$5');
}

const getVersion = (): string => {
    let version = `v${VERSION}`;

    const deviceInfo = getDeviceInfo();
    if (isDevice() && deviceInfo.hasOwnProperty("appVersion") && deviceInfo.hasOwnProperty("appBuild")) {
        version = `App v${deviceInfo.appVersion}(${deviceInfo.appBuild}), ${version}`;
    }

    return version;
};

const getSiteName = (): string => {
    return REACT_APP_SITE_NAME;
};

const wait = async (ms: number) => {
    return new Promise((resolve) => {
        setTimeout(resolve, ms)
    });
};

const getEpochTime = (value: Date | string | number | undefined): number => {
    if (!value) {
        return 0;
    }

    return new Date(value).getTime();
};

const copy = (value: any) => {
    if (!value) {
        return value;
    }

    return JSON.parse(JSON.stringify(value));
}

const equalsInOrder = (a: string[], b: string[]) => {
    a.length === b.length &&
    a.every((v, i) => v === b[i]);
};

const equalsIgnoreOrder = (a: string[], b: string[]) => {
    if (a.length !== b.length) { return false; }

    const uniqueValues = new Set([...a, ...b]);

    for (const v of uniqueValues) {
        const aCount = a.filter(e => e === v).length;
        const bCount = b.filter(e => e === v).length;
        if (aCount !== bCount) { return false; }
    }

    return true;
};

const sendMessageToNativeConsole = (messageText: string) => {
    sendMessageToDevice(DeviceHandler.IOS_NOTIFICATION, { messageText: messageText });
};

const sendMessageToNativeDebugConsole = (messageText: string) => {
    sendMessageToDevice(DeviceHandler.IOS_NOTIFICATION_DEBUG_MANAGER, { messageText: messageText });
};

const mergeDictionary = (original: {[k: string]: any} | undefined, input: {[k: string]: any} | undefined) => {
    const originalData = original || {};
    const inputData = input || {};

    return {...originalData, ...inputData};
};

const mergeObjectNativeIdData = (inputData: NativeIdInfo | undefined, dbData: NativeIdInfo | undefined ) => {
    return mergeDictionary(dbData, inputData);
};

const needUpdateUnknownFields = (original: string | undefined, input: string | undefined) => {
    return original === OBJECT_TYPE.UNKNOWN && input && original !== input;
};

const removeUnacceptableFields = (data: {[key: string]: any}, type: "properties" | "local_object" | "native_properties" | "private_acl" | undefined) => {
    let available_keys: string[];

    switch (type) {
        case "properties":
            available_keys = copy(AVAILABLE_PROPERTY_KEYS);
            break;
        case "local_object":
            available_keys = copy([...AVAILABLE_OBJECT_KEYS, ...AVAILABLE_LOCAL_OBJECT_KEYS]);
            break;
        case "native_properties":
            available_keys = copy([...AVAILABLE_NATIVE_PROPERTY_KEYS]);
            break;
        case "private_acl":
            available_keys = copy([...AVAILABLE_PRIVATE_ACL_KEYS]);
            break;
        default:
            available_keys = [];
            break;
    }

    if (!data || !Array.isArray(available_keys)) {
        return data;
    }

    const response: {[key: string]: any} = {};

    for (const key of available_keys) {
        if (data.hasOwnProperty(key)) {
            response[key] = data[key]
        }
    }

    return response;
};

const addEpochToEveryEncKey = (encrypted: ValueEncryptedAESType, epoch: number) => {
    const epochByKey: ValueEncryptedEpochType = {};

    for (const keyId in encrypted || {}) {
        if (!encrypted.hasOwnProperty(keyId)) {
            continue;
        }

        epochByKey[keyId] = epoch;
    }

    return epochByKey;
};

const addEpochToProperty = (inputProperty: PropertyItem, epoch: number) => {
    const property = copy(inputProperty);

    if (property.hasOwnProperty("value")) {
        property.value_epoch = epoch;
    }

    if (property.hasOwnProperty("reported_value")) {
        property.reported_value_epoch = epoch;
    }

    if (property.value_encrypted) {
        property.value_encrypted_epoch = addEpochToEveryEncKey(property.value_encrypted, epoch);
    }

    if (property.hasOwnProperty("reported_value_encrypted")) {
        property.reported_value_encrypted_epoch = addEpochToEveryEncKey(property.reported_value_encrypted, epoch);
    }

    return property;
};

const addEpochToProperties = (inputProperties: PropertiesType, epoch: number) => {
    if (!inputProperties || !Object.keys(inputProperties).length) {
        return inputProperties;
    }

    const properties = copy(inputProperties);

    for (const propertyKey in properties || {}) {
        if (!properties.hasOwnProperty(propertyKey)) {
            continue;
        }

        properties[propertyKey] = addEpochToProperty(properties[propertyKey], epoch);
    }

    return properties;
};

// Get body of properties without fields value and reported_value, and related epoch fields
const getPropertyFields = (property: PropertyItem) => {
    const {value, reported_value, reported_value_epoch, value_epoch, ...propertyFields} = property;

    return propertyFields;
};

const getObjectFields = (object: ObjectItem | undefined | null) => {

    const { properties, children, children_info, object_id, native_id, favorites, encryption_key, permissions, last_active, primary_client, reachable, ...object_fields } = object || {};

    return object_fields;
};

const isObjectsHasEqualFieldsValue = (original: {[k: string]: any}, input: {[k: string]: any}, fullComparison?: boolean) => {
    if (fullComparison && Object.keys(original).length !== Object.keys(input).length) {
         return false;
    }

    return Object.keys(input).every((key) => {
        if (input.hasOwnProperty(key) !== original.hasOwnProperty(key)) {
            return false;
        }

        const isObjectType = typeof input[key] === "object";

        if (isObjectType && !isObjectsHasEqualFieldsValue(original[key], input[key])) {
            return false;
        }

        if (!isObjectType && input[key] !== original[key]) {
            return false;
        }

        return true;
    });
};

const getObjectExpirationTime = (properties: PropertiesType) => {
    return properties?.["expiration_time"]?.value ? new Date(properties?.["expiration_time"]?.value as number).toISOString() : null;
};

const getObjectServiceLifeNotifications = (object_name: string, properties: PropertiesType) => {
    let serviceLifeNotifications: { [string: string]: any } = {};

    if (!properties) {
        return null;
    }

    Object.values(properties).forEach((property) => {
        if (property.type !== "ServiceLife") {
            return;
        }

        let buildNotifications: Array<{alert_time: number, message: string}> = [];
        const serviceLifeValue = property.value as ServiceLifeType;

        const startDate = parse(serviceLifeValue?.start_time as string, 'MM/dd/yyyy', new Date());
        const serviceLifeExpire = add(
            startDate,
            {days: serviceLifeValue.life_time, hours: 9}
        ).getTime();
        let serviceLifeNotification = subDays(serviceLifeExpire, serviceLifeValue?.notification_time).getTime();

        buildNotifications.push({
            alert_time: serviceLifeExpire,
            message: `${object_name} ${property.name} expired`
        });

        buildNotifications.push({
            alert_time: serviceLifeNotification,
            message: `${object_name} ${property.name} expires in ${serviceLifeValue?.notification_time} ${serviceLifeValue?.notification_time === 1 ? "day" : "days"}`
        });

        serviceLifeNotifications[property.key] = {
            updated: property.value_epoch,
            notifications: buildNotifications
        }
    });

    return Object.keys(serviceLifeNotifications) ? serviceLifeNotifications : null;
};

const copyToClipboard = async (text: string) => {
    try {
        await navigator.clipboard.writeText(text);
    } catch (e) {
        return Promise.reject(e);
    }
};

const replaceAll = (target: string, search: string, replacement: string) => {
    const _target = copy(target);
    return _target.replace(new RegExp(search, "g"), replacement || "");
};

const asyncParallelCall = async (data: any[] = [], callback: (item: any) => Promise<void>) => {
    try {
        return await Promise.all((data).map(callback));
    } catch (e) {
        return Promise.reject(e);
    }
};

const asyncParallelLimit = async (data: any[] = [], limit: number = 1, callback: (item: any) => Promise<void>) => {
    try {
        const chunk_data = [];
        for (let i = 0; i < Math.ceil(data.length/limit); i++){
            chunk_data[i] = data.slice((i*limit), (i*limit) + limit);
        }

        for (const chunk of chunk_data) {
            await asyncParallelCall(chunk, callback);
        }
    } catch (e) {
        return Promise.reject(e);
    }
};

const toRadians = (number: number) => {
    return number * Math.PI / 180;
};

const longitudeToXTile = (longitude: number, zoom: number) => {
    return (Math.floor((longitude + 180) / 360 * Math.pow(2, zoom)));
};

const latitudeToYTile = (latitude: number, zoom: number) => {
    return (Math.floor((1 - Math.log(Math.tan(toRadians(latitude)) + 1 / Math.cos(toRadians(latitude))) / Math.PI) / 2 * Math.pow(2, zoom)));
};

const getActiveObjectCryptoKeyId = (crypto_keys: CryptoKeysType) => {
    const object_key_ids = Object.keys(crypto_keys || {});

    return object_key_ids.find((id) => {
        return crypto_keys.hasOwnProperty(id) && crypto_keys[id]?.status?.active;
    });
};

const convertENUMValueToName = (value: number, ENUM: any) => {
    if (!ENUM || !ENUM[value]) {
        return null;
    }

    return ENUM[value];
};

const buildSenderTitle = async (chat_message: ChatMessageType) => {
    if (!chat_message.user_id) {
        return "Unknown User";
    }

    const user_id = chat_message.user_id;
    let received_user_info: any = {};
    try { received_user_info = await getUserById(user_id); } catch (e) {}

    return received_user_info.firstname && received_user_info.lastname ? (received_user_info.firstname + " " + received_user_info.lastname) : "Unknown User";
};

const trimChatMessage = (chat_message: ChatMessageType) => {
    const trimToLength = 30;
    const message = chat_message.text || "";

    return message.length > trimToLength ? (message.substr(0, trimToLength) + '...') : message;
};

const getTextAvatar = (name_source: string) => {
    return name_source
        .split(" ")
        .map((item) => { return item[0]; })
        .join("")
        .substr(0, 2)
        .toUpperCase();
};

const copyDeepOrReference = (value: any): any => {
    // Handle `null` and `undefined` early
    if (value === null || value === undefined) {
        return value;
    }

    // Plain object
    if (value.constructor === Object) {
        const object: any = {};
        for (const [k, v] of Object.entries(value)) {
            object[k] = copyDeepOrReference(v);
        }
        return object;
    }

    // Plain array
    if (value instanceof Array) {
        return value.map((item) => copyDeepOrReference(item));
    }

    // ArrayBuffer
    if (value instanceof ArrayBuffer) {
        return value.slice(0);
    }

    // Uint8Array
    if (value instanceof Uint8Array) {
        // Note: To mimic the byte offset, we copy the whole underlying buffer.
        const buffer = value.buffer.slice(0);
        return new Uint8Array(buffer, value.byteOffset, value.byteLength);
    }

    // Reference everything else
    return value;
}

const downloadFile = (name: string, value: Uint8Array, type?: string | null) => {
    let fileUrl;

    if (value) {
        let options = {};
        if (type) {
            options = {
                type: type,
            };
        }
        const file = new Blob([value], options);
        fileUrl = URL.createObjectURL(file);
    }

    if (!fileUrl) {
        return;
    }

    const element = document.createElement("a");
    element.href = fileUrl;
    element.download = name;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
};

export {
    arrayReducer,
    isEmail,
    filterObjectProperties,
    checkPathIncludes,
    checkIsOnline,
    isObjectPublic,
    accessLevelPriority,
    isAllowedOperation,
    getUserAccessLevel,
    isUserHasAccess,
    isActiveAccessLevel,
    chunkString,
    checkPropertyCardData,
    checkPropertyDetailsHeaderData,
    convertArrayToObject,
    cleanPermissionObjectFromRootKey,
    mergePermissions,
    mergeObjectChildren,
    mergeChildren,
    mergeProperties,
    generatePropertyItem,
    sequenceGenerator,
    hideCredentials,
    getVersion,
    getSiteName,
    wait,
    getEpochTime,
    copy,
    equalsInOrder,
    equalsIgnoreOrder,
    sendMessageToNativeConsole,
    sendMessageToNativeDebugConsole,
    mergeDictionary,
    mergeObjectNativeIdData,
    needUpdateUnknownFields,
    removeUnacceptableFields,
    addEpochToEveryEncKey,
    addEpochToProperties,
    getPropertyFields,
    getObjectFields,
    isObjectsHasEqualFieldsValue,
    getDataExpirationTime,
    getObjectExpirationTime,
    getObjectServiceLifeNotifications,
    copyToClipboard,
    replaceAll,
    asyncParallelCall,
    asyncParallelLimit,
    longitudeToXTile,
    latitudeToYTile,
    getActiveObjectCryptoKeyId,
    convertENUMValueToName,
    buildSenderTitle,
    trimChatMessage,
    getTextAvatar,
    downloadFile,
};
