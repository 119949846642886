import md5 from "md5";
import {addEpochToEveryEncKey, chunkString, copy, getActiveObjectCryptoKeyId, isObjectPublic} from "./Utils";
import {
    getObjectById,
    CryptoKeysType,
    EncryptionKeyAES,
    PropertiesType,
    PropertyHistoryItem,
    PropertyItem
} from "./ObjectsService";
import { getUserId, getUserCryptoInfo } from "./UserService";
import {LIST_OF_PROPERTIES_STORED_ON_S3} from "./Constants";

// const CHUNK_DATA_LENGTH = 189;
// 445

export enum CHUNK_DATA_LENGTH {
    l2048 = 189,
    l4096 = 445
}

const CRYPTO = window.crypto.subtle;

export type JsonWebKeyPair = {
    privateJWK: JsonWebKey;
    publicJWK: JsonWebKey;
}

type UserCryptoItem = {
    privateKey: string;
    publicKey: string;
    iv: string;
    salt: string;
};

type ExtendedUserCryptoItem = UserCryptoItem & JsonWebKeyPair;

export type UserCrypto = {
    encryption: UserCryptoItem;
    signing: UserCryptoItem;
};

export type ExtendedUserCrypto = {
    encryption: ExtendedUserCryptoItem;
    signing: ExtendedUserCryptoItem;
}

const ab2str = (buf: ArrayBuffer) => {
    let binary = '';
    const bytes = new Uint8Array( buf );
    for (const item of bytes) {
        binary += String.fromCharCode( item );
    }

    return binary;
    // return String.fromCharCode.apply(null, new Uint8Array(buf) as any);
};

const str2ab = (str: string): ArrayBuffer => {
    const buf = new ArrayBuffer(str.length); // 2 bytes for each char
    const bufView = new Uint8Array(buf);
    for (let i = 0, strLen = str.length; i < strLen; i++) {
        bufView[i] = str.charCodeAt(i);
    }
    return buf;
};

const ab2base64 = (buf: ArrayBuffer): string => {
    const str = ab2str(buf);
    return window.btoa(str);
};

const base64toAB = (base64: string): ArrayBuffer => {
    const str = window.atob(base64);
    return str2ab(str);
};

// LM-316
const RSA_OAEP = {
    name: "RSA-OAEP",
    modulusLength: 4096,
    publicExponent: new Uint8Array([1, 0, 1]),
    hash: "SHA-256",
};

const AES_GCM = {
    name: "AES-GCM",
    length: 256
};

const RS256 = {
    name: "RSASSA-PKCS1-v1_5",
    modulusLength: 4096,
    publicExponent: new Uint8Array([1, 0, 1]),
    hash: "SHA-256",
};

const generateKeyIdRSA = async (publicJWK: JsonWebKey) => {
    try {
        const publicKey = await CRYPTO.importKey(
            "jwk",
            publicJWK,
            RSA_OAEP,
            true,
            ["encrypt"]
        );

        const exported = await CRYPTO.exportKey("spki", publicKey);
        const exportedAsString = ab2str(exported);
        const exportedAsBase64 = window.btoa(exportedAsString);
        return md5(exportedAsBase64);
    } catch (e) {
        return Promise.reject(e);
    }
};

const generateKeyIdAES = (key: string): string => {
    return md5(key);
};

const getKeyIdAndCryptoKeyAES = async (key: EncryptionKeyAES) => {
    if (!key) {
        return {
            keyId: null,
            cryptoKeyAES: null
        };
    }

    try {
        const keyId = generateKeyIdAES(key);

        const cryptoKeyAES = await CRYPTO.importKey(
            "raw",
            base64toAB(key),
            AES_GCM,
            true,
            ["encrypt", "decrypt"]
        );

        return {
            keyId: keyId,
            cryptoKeyAES: cryptoKeyAES
        };
    } catch (e) {
        return {
            keyId: null,
            cryptoKeyAES: null
        };
    }
};

const generateCryptoKeyRSA = async (): Promise<CryptoKeyPair|CryptoKey> => {
    try {
        return CRYPTO.generateKey(
            RSA_OAEP,
            true,
            ["encrypt", "decrypt"]
        );
    } catch (e) {
        console.error("Something went wrong in the process of generating Crypto Key");
        console.error(e);
        throw e;
    }
};

const generateCryptoKeyAES = async (): Promise<CryptoKeyPair|CryptoKey> => {
    return CRYPTO.generateKey(
        AES_GCM,
        true,
        ["encrypt", "decrypt"]
    );
};

const generateObjectEncryptionKey = async () => {
    try {
        const key = await generateCryptoKeyAES() as CryptoKey;

        // console.log(key);

        const exported = await CRYPTO.exportKey("raw", key);
        const exportedKeyBuffer = new Uint8Array(exported);

        // console.log(exportedKeyBuffer);

        return ab2base64(exportedKeyBuffer);

        // console.log(keyBase64);

        // return keyBase64;
    } catch (e) {
        console.error(e);
        return Promise.reject("Something went wrong. Cannot be generated object crypto key. Please try later again");
    }
};

const generateListObjectEncryptionKey = async (count: number): Promise<string[]> => {
    try {
        return await Promise.all(new Array(count).fill(null).map(async () => {
            return await generateObjectEncryptionKey();
        }));

    } catch (e) {
        console.error(e);
        return Promise.reject("Something went wrong. Cannot be generated object crypto key. Please try later again");
    }
};

const encryptObjectEncryptionKey = async (objectKey: string, publicJWK: JsonWebKey) => {
    try {
        const publicKey = await CRYPTO.importKey(
            "jwk",
            publicJWK,
            RSA_OAEP,
            true,
            ["encrypt"]
        );

        const encryptedKey = await encryptChunkedDataWithRSA(publicKey, objectKey, CHUNK_DATA_LENGTH.l4096);

        const keyId = await generateKeyIdRSA(publicJWK);

        return {
            keyId: keyId,
            encryptedKey: encryptedKey
        }
        // return {
        //     [keyId]: encryptedKey
        // }
    } catch (e) {
        return Promise.reject(e);
    }
};

const decryptObjectEncryptionKey = async (encryptedKey: {[key: string]: string[]}): Promise<EncryptionKeyAES> => {
    try {
        const userId = getUserId();
        const cryptoInfo = getUserCryptoInfo(userId);

        if (!cryptoInfo) {
            return null;
        }

        const privateKey = await CRYPTO.importKey(
            "jwk",
            cryptoInfo.encryption.privateJWK,
            RSA_OAEP,
            true,
            ["decrypt"]
        );

        const keyId = await generateKeyIdRSA(cryptoInfo.encryption.publicJWK);

        return await decryptChunkedDataWithRSA(privateKey, encryptedKey[keyId]);
    } catch (e) {
        // console.error(e);
        return null;
        // return Promise.reject(e);
    }
};

const decryptObjectCryptoKeys = async (cryptoKeys: CryptoKeysType | undefined) => {
    if (!cryptoKeys) {
        return null;
    }

    try {
        const objectKeyId = getActiveObjectCryptoKeyId(cryptoKeys);

        if (!objectKeyId) {
            return null;
        }

        return decryptObjectEncryptionKey(cryptoKeys[objectKeyId].encryption_keys);
    } catch (e) {
        return null;
    }
};

// const encryptObjectEncryptionKeyForUsers = async (encryptionKey: EncryptionKeyAES, permissions: Permissions | undefined) => {
//     try {
//         const userId = getUserId();
//
//         const listUserIds = [...Object.keys(permissions?.private_acl || {})];
//     } catch (e) {
//         return Promise.reject(e);
//     }
// };

// Get some key material to use as input to the deriveKey method.
// The key material is a password supplied by the user.
const getKeyMaterial = (password: string) => {
    try {
        const enc = new TextEncoder();
        return CRYPTO.importKey(
            "raw",
            enc.encode(password),
            "PBKDF2",
            false,
            ["deriveBits", "deriveKey"]
        );
    } catch (e) {
        console.error(e);
        return Promise.reject(e);
    }
}

// Given some key material and some random salt derive an AES-GCM key using PBKDF2.
const getKey = (keyMaterial: CryptoKey, salt: Uint8Array) => {
    try {
        return CRYPTO.deriveKey(
            {
                "name": "PBKDF2",
                salt: salt,
                "iterations": 100000,
                "hash": "SHA-256"
            },
            keyMaterial,
            { "name": "AES-GCM", "length": 256},
            true,
            [ "wrapKey", "unwrapKey" ]
        );
    } catch (e) {
        console.error(e);
        return Promise.reject(e);
    }
}

const wrapCryptoKey = async (keyToWrap: CryptoKey, password: string) => {

    // get the key encryption key
    try {
        const keyMaterial = await getKeyMaterial(password);
        let salt: Uint8Array = window.crypto.getRandomValues(new Uint8Array(16));
        const wrappingKey = await getKey(keyMaterial, salt);
        let iv: Uint8Array = window.crypto.getRandomValues(new Uint8Array(12));

        const wrapped = await CRYPTO.wrapKey(
            "jwk",
            keyToWrap,
            wrappingKey,
            {
                ...AES_GCM,
                iv: iv
            } as AesGcmParams
        );

        return {
            iv: iv,
            salt: salt,
            wrapped: wrapped
        }
    } catch (e) {
        console.error(e);
        return Promise.reject(e);
    }
}

const unwrapCryptoKey = async (wrappedKey: ArrayBuffer, password: string, salt: Uint8Array, iv: Uint8Array, alg: RsaHashedKeyGenParams, keyUsages: KeyUsage[]) => {
    try {
        const keyMaterial = await getKeyMaterial(password);

        const unwrappingKey = await getKey(keyMaterial, salt);

        return CRYPTO.unwrapKey(
            "jwk",                                          // import format
            wrappedKey,                                            // ArrayBuffer representing key to unwrap
            unwrappingKey,                                         // CryptoKey representing key encryption key
            {                                                      // algorithm params for key encryption key
                ...AES_GCM,
                iv: iv
            } as AesGcmParams,
            alg,
            true,                                       // extractability of key to unwrap
            keyUsages                                             // key usages for key to unwrap
        );
    } catch (e) {
        console.error(e);
        return Promise.reject(e);
    }
}

const generateUserCryptoKeys = async (password: string): Promise<ExtendedUserCrypto> => {
    if (!password) return Promise.reject({message: 'Password required for generate crypto keys.'})
    try {
        const signKeyPair = await CRYPTO.generateKey(RS256,true, ["sign", "verify"]) as CryptoKeyPair;
        const cryptoKeyPair = await CRYPTO.generateKey(RSA_OAEP,true, ["encrypt", "decrypt"]) as CryptoKeyPair;

        const wrappedSigning = await wrapKeyPair(password, signKeyPair);
        const wrappedEncryption = await wrapKeyPair(password, cryptoKeyPair);

        const signingJWK = await exportKeyPairAsJWK(signKeyPair);
        const cryptoJWK = await exportKeyPairAsJWK(cryptoKeyPair);

        return {
            encryption: {
                ...wrappedEncryption,
                ...cryptoJWK
            },
            signing: {
                ...wrappedSigning,
                ...signingJWK
            }
        };
    } catch (e) {
        console.error(e);
        return Promise.reject(e);
    }
};

const wrapKeyPair = async (password: string, keyPair: CryptoKeyPair): Promise<UserCryptoItem> => {
    try {

        const { privateKey, publicKey } = keyPair;

        if (!privateKey || !publicKey) {
            console.error("Private or public key does not exist");
            return Promise.reject("Private or public key does not exist");
        }

        const { wrapped, iv, salt } = await wrapCryptoKey(privateKey, password);

        const exportedPublicKey = await CRYPTO.exportKey("spki", publicKey);

        return {
            privateKey: ab2base64(wrapped),
            publicKey: ab2base64(exportedPublicKey),
            iv: ab2base64(iv),
            salt: ab2base64(salt)
        }
    } catch (e) {
        console.error(e);
        return Promise.reject(e);
    }
}

const importAndWrapKeyPair = async (password: string, keyPairJWK: JsonWebKeyPair, algName: string) => {
    try {
        let alg;
        let usages;

        switch (algName) {
            case "RSA-OAEP":
                alg = {
                    name: "RSA-OAEP",
                    hash: "SHA-256",
                };
                usages = {
                    publicKey: "encrypt",
                    privateKey: "decrypt"
                };
                break;
            case "RS256":
                alg = {
                    name: "RSASSA-PKCS1-v1_5",
                    // modulusLength: 4096,
                    // publicExponent: new Uint8Array([1, 0, 1]),
                    hash: "SHA-256",
                };
                usages = {
                    publicKey: "verify",
                    privateKey: "sign"
                };
                break;
            default:
                throw new Error("Incorrect Algorithm Name");
        }

        const privateKey = await crypto.subtle.importKey(
            "jwk",
            keyPairJWK.privateJWK,
            alg,
            true,
            [usages.privateKey as KeyUsage]
        );

        const publicKey = await crypto.subtle.importKey(
            "jwk",
            keyPairJWK.publicJWK,
            alg,
            true,
            [usages.publicKey as KeyUsage]
        );


        return await wrapKeyPair(password, {
            privateKey: privateKey,
            publicKey: publicKey
        });

    } catch (e) {
        console.error(e);
        return Promise.reject(e);
    }
}

const unwrapCryptoData = async (data: { private_key: string, public_key: string, iv: string, salt: string }, password: string, alg: RsaHashedKeyGenParams, keyUsages: KeyUsage[]) => {

    try {
        const privateKey = base64toAB(data.private_key);
        // const publicKey = base64toAB(data.public_key);
        const iv = base64toAB(data.iv);
        const salt = base64toAB(data.salt);

        return await unwrapCryptoKey(privateKey, password, new Uint8Array(salt), new Uint8Array(iv), alg, keyUsages);

    } catch (e) {
        console.error(e);
        return Promise.reject(e);
    }
};

const exportJwkFromSpki = async (key: string, alg: RsaHashedImportParams, keyUsages: KeyUsage[]): Promise<JsonWebKey> => {

    // base64 decode the string to get the binary data
    const binaryDerString = window.atob(key);
    // convert from a binary string to an ArrayBuffer
    const binaryDer = str2ab(binaryDerString);

    const publicKey = await window.crypto.subtle.importKey(
        "spki",
        binaryDer,
        alg,
        true,
        keyUsages
    );

    return await CRYPTO.exportKey("jwk", publicKey);
};

const exportPublicJWKFromSPKI = async (key: string) => {
    try {
        return await exportJwkFromSpki(key, RSA_OAEP, ["encrypt"]);
    } catch (e) {
        return Promise.reject(e);
    }
};

const exportKeyPairAsJWK = async (keyPair: CryptoKeyPair): Promise<JsonWebKeyPair> => {
    try {
        const { publicKey, privateKey } = keyPair;

        if (!privateKey || !publicKey) {
            console.error("Private or public key does not exist");
            return Promise.reject("Private or public key does not exist");
        }

        const publicJWK = await CRYPTO.exportKey("jwk", publicKey);
        const privateJWK = await CRYPTO.exportKey("jwk", privateKey);

        return {
            publicJWK: publicJWK,
            privateJWK: privateJWK
        }
    } catch (e) {
        console.error(e);
        return Promise.reject(e);
    }
};

const signMessage = async (message: string, key: CryptoKey): Promise<string> => {
    try {
        let enc = new TextEncoder();
        const signature = await CRYPTO.sign(
            "RSASSA-PKCS1-v1_5",
            key,
            enc.encode(message)
        );

        return ab2base64(signature);
    } catch (e) {
        console.error(e);
        return Promise.reject(e);
    }
};

const unwrapUserSigningKeyPairAndSignMessage = async (data: { private_key: string, public_key: string, iv: string, salt: string }, password: string, message: string) => {
    try {
        let unwrapped;

        try {
            unwrapped = await unwrapCryptoData(data, password, RS256, ["sign"]);
        } catch (e) {
            console.error(e);
            throw new Error("Passed incorrect data to verify the user");
        }

        const signature = await signMessage(message, unwrapped);

        const publicJWK = await exportJwkFromSpki(data.public_key, RS256, ["verify"]);
        const privateJWK = await CRYPTO.exportKey("jwk", unwrapped);

        return {
            signature: signature,
            signing: {
                publicJWK: publicJWK,
                privateJWK: privateJWK
            }
        };

    } catch (e) {
        console.error(e);
        return Promise.reject(e);
    }
}

const unwrapUserEncryptionKeyPair = async (data: { private_key: string, public_key: string, iv: string, salt: string }, password: string): Promise<JsonWebKeyPair> => {
    try {
        const unwrapped = await unwrapCryptoData(data, password, RSA_OAEP, ["decrypt"]);

        const publicJWK = await exportPublicJWKFromSPKI(data.public_key);
        const privateJWK = await CRYPTO.exportKey("jwk", unwrapped);

        return {
            publicJWK,
            privateJWK
        }
    } catch (e) {
        console.error(e);
        return Promise.reject(e);
    }
};

const encryptDataWithRSA = async (publicKey: CryptoKey, value: string) => {
    try {
        const encoder = new TextEncoder();
        const valueUTF8 = encoder.encode(value);

        const encrypted = await CRYPTO.encrypt(RSA_OAEP, publicKey, valueUTF8);

        const asString = ab2str(encrypted);
        return window.btoa(asString);
    } catch (e) {
        return Promise.reject(e);
    }
};

const encryptChunkedDataWithRSA = async (publicKey: CryptoKey, data: string, chunkLength: number) => {
    try {
        const chunkedData = chunkString(JSON.stringify(data), chunkLength);

        return await Promise.all(chunkedData.map(async (chunk) => {
            return await encryptDataWithRSA(publicKey, chunk);
        }));
    } catch (e) {
        return Promise.reject(e);
    }
};

const decryptDataWithRSA = async (privateKey: CryptoKey, value: string) => {
    try {
        const base64 = window.atob(value);
        const valueUTF8 = str2ab(base64);
        const decrypted = await CRYPTO.decrypt(RSA_OAEP, privateKey, valueUTF8);

        const decoder = new TextDecoder();

        return decoder.decode(decrypted);
    } catch (e) {
        return Promise.reject(e);
    }
};

const decryptChunkedDataWithRSA = async(privateKey: CryptoKey, data: string[]) => {
    try {
        const result = await Promise.all(data.map(async (chunk) => {
            return await decryptDataWithRSA(privateKey, chunk);
        }));

        return JSON.parse(result.join(""));
    } catch (e) {
        return Promise.reject(e);
    }
};

const AES_SEPARATOR = ":";

const encryptDataWithAES = async (key: CryptoKey, data: string): Promise<string> => {
    try {
        const encoder = new TextEncoder();
        const valueUTF8 = encoder.encode(data);

        const iv = window.crypto.getRandomValues(new Uint8Array(12));

        const encrypted = await CRYPTO.encrypt(
            {
                ...AES_GCM,
                iv: iv
            },
            key,
            valueUTF8
        );

        const encryptedStr = ab2base64(encrypted);
        const ivStr = ab2base64(iv);

        return window.btoa(`${encryptedStr}${AES_SEPARATOR}${ivStr}`);

    } catch (e) {
        return Promise.reject(e);
    }
};

const decryptDataWithAES = async (key: CryptoKey, data: string) => {
    try {
        const [cipherStr, ivStr] = window.atob(data).split(AES_SEPARATOR);

        const ciphertext = base64toAB(cipherStr);
        const iv = base64toAB(ivStr);

        const decrypted = await CRYPTO.decrypt(
            {
                ...AES_GCM,
                iv: iv
            },
            key,
            ciphertext
        );

        const decoder = new TextDecoder();

        return JSON.parse(decoder.decode(decrypted));
    } catch (e) {
        return Promise.reject(e);
    }
};

const encryptPropertiesWithAES = async (inProperties: PropertiesType | undefined, encryptionKey: EncryptionKeyAES, isPublic: boolean) => {
    if (!inProperties) {
        return inProperties;
    }

    if (!encryptionKey) {
        return Promise.reject(`This object require to encrypt data, but the encryption key is missed`);
    }

    try {
        const properties = copy(inProperties);

        const { keyId, cryptoKeyAES } = await getKeyIdAndCryptoKeyAES(encryptionKey);

        for (const key in properties || {}) {
            if (!properties.hasOwnProperty(key)) {
                continue;
            }

            const valueEpoch = properties[key].value_epoch;
            const reportedValueEpoch = properties[key].reported_value_epoch;

            if (properties[key].hasOwnProperty("value") && keyId && cryptoKeyAES) {
                const encryptedValue = await encryptDataWithAES(cryptoKeyAES, JSON.stringify(properties[key].value));

                const valueEncrypted = {
                    [keyId]: encryptedValue
                };
                properties[key].value_encrypted = valueEncrypted;
                properties[key].value_encrypted_epoch = addEpochToEveryEncKey(valueEncrypted, valueEpoch);
            }

            if (properties[key].hasOwnProperty("reported_value") && keyId && cryptoKeyAES) {
                const encryptedReportedValue = await encryptDataWithAES(cryptoKeyAES, JSON.stringify(properties[key].reported_value));

                const reportedValueEncrypted = {
                    [keyId]: encryptedReportedValue
                };
                properties[key].reported_value_encrypted = reportedValueEncrypted;
                properties[key].reported_value_encrypted_epoch = addEpochToEveryEncKey(reportedValueEncrypted, reportedValueEpoch);
            }

            if (!isPublic) {
                delete properties[key].value;
                delete properties[key].reported_value;
                delete properties[key].value_epoch;
                delete properties[key].reported_value_epoch;
            }
        }

        return properties;
    } catch (e) {
        return Promise.reject(e);
    }
};

const decryptPropertyItemWithAES = async (property: PropertyItem | PropertyHistoryItem, keyId: string | null, cryptoKeyAES: CryptoKey | null) => {
    const removeEncryptedFields = (value: PropertyItem | PropertyHistoryItem) => {
        delete value.value_encrypted;
        delete value.reported_value_encrypted;
        delete value.value_encrypted_epoch;
        delete value.reported_value_encrypted_epoch;
        return value;
    };

    if (!keyId || !cryptoKeyAES) {
        return removeEncryptedFields(property);
    }

    if (property.value_encrypted && property.value_encrypted.hasOwnProperty(keyId)) {
        const encrypted = property.value_encrypted[keyId];

        try {
            if (encrypted) {
                property.value = await decryptDataWithAES(cryptoKeyAES, encrypted);
            } else if (LIST_OF_PROPERTIES_STORED_ON_S3.includes(property.key) && encrypted === "") {
                property.value = encrypted;
            }

            if (property?.value_encrypted_epoch?.[keyId] && property.hasOwnProperty("value")) {
                property.value_epoch = property.value_encrypted_epoch[keyId];
            }
        } catch (e) {}
    }

    if (property.reported_value_encrypted && property.reported_value_encrypted.hasOwnProperty(keyId)) {
        const encrypted = property.reported_value_encrypted[keyId];

        try {
            if (encrypted) {
                property.reported_value = await decryptDataWithAES(cryptoKeyAES, encrypted);
            } else if (LIST_OF_PROPERTIES_STORED_ON_S3.includes(property.key) && encrypted === ""){
                property.reported_value = encrypted;
            }

            if (property.reported_value_encrypted_epoch?.[keyId] && property.hasOwnProperty("reported_value")) {
                property.reported_value_epoch = property.reported_value_encrypted_epoch[keyId];
            }
        } catch (e) {}
    }

    return removeEncryptedFields(property);
};

const decryptPropertiesWithAES = async (inProperties: { [key: string]: any } | undefined, encryptionKey: EncryptionKeyAES) => {
    if (!inProperties) {
        return {};
    }

    try {
        const properties = JSON.parse(JSON.stringify(inProperties));

        const { keyId, cryptoKeyAES } = await getKeyIdAndCryptoKeyAES(encryptionKey);

        for (const key in properties) {
            if (!properties.hasOwnProperty(key)) {
                continue;
            }

            properties[key] = await decryptPropertyItemWithAES(properties[key], keyId, cryptoKeyAES);
        }

        return properties;
    } catch (e) {
        return Promise.reject(e);
    }
};

const getObjectEncryptionKey = async (objectId: string) => {
    try {
        const object = await getObjectById(objectId, {includeDeleted: true, includePublic: true, skipChildrenTransform : true});

        if (!object.encryption_key) {
            if (isObjectPublic(object.permissions) && object.owner_id !== getUserId()) {
                return Promise.reject({
                    code: 404,
                    message: "Public Object, Not own, Object encryption key not found"
                });
            }
            return Promise.reject("Object encryption key not found");
        }

        return object.encryption_key;
    } catch (e) {
        return Promise.reject("Object encryption key not found ! " + e);
    }
};

const decryptPropertiesHistoryWithAES = async (objectId: string, listPropertiesHistory: PropertyHistoryItem[]): Promise<PropertyHistoryItem[]> => {

    try {
        const encryptionKey = await getObjectEncryptionKey(objectId);

        const { keyId, cryptoKeyAES } = await getKeyIdAndCryptoKeyAES(encryptionKey);

        const decryptedData = await Promise.all((listPropertiesHistory || []).map(async (historyItem) => {
            return await decryptPropertyItemWithAES(historyItem, keyId, cryptoKeyAES) as PropertyHistoryItem;
        }));

        return decryptedData.filter((item) => {
            return item.hasOwnProperty("value");
        });
    } catch (e) {
        console.error(e);
        return [];
    }
};

const buildCryptoData = ({ signing, encryption }: ExtendedUserCrypto): ExtendedUserCrypto => {
    return {
        signing: {
            publicJWK: signing.publicJWK,
            privateJWK: signing.privateJWK
        },
        encryption: {
            privateJWK: encryption.privateJWK,
            publicJWK: encryption.publicJWK
        }
    } as ExtendedUserCrypto;
};

export {
    generateKeyIdRSA,
    generateKeyIdAES,
    generateCryptoKeyRSA,
    generateCryptoKeyAES,
    generateObjectEncryptionKey,
    generateListObjectEncryptionKey,
    encryptObjectEncryptionKey,
    decryptObjectEncryptionKey,
    decryptObjectCryptoKeys,
    wrapCryptoKey,
    unwrapCryptoKey,
    importAndWrapKeyPair,
    generateUserCryptoKeys,
    exportPublicJWKFromSPKI,
    unwrapUserSigningKeyPairAndSignMessage,
    unwrapUserEncryptionKeyPair,
    encryptDataWithAES,
    decryptDataWithAES,
    encryptPropertiesWithAES,
    decryptPropertiesWithAES,
    getObjectEncryptionKey,
    decryptPropertiesHistoryWithAES,
    buildCryptoData,
}
