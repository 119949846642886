import React, {useState, useEffect} from "react";
import Typography from "@material-ui/core/Typography";
import useRecursiveTimeout from "../../../Hooks/useRecursiveTimeout";
import {differenceInHours, formatDistanceToNow, isValid} from "date-fns";

type FormattedActivityTimeProps= {
    propsTypography?: Object;
    options?: OptionsType;
    activityEpoch: number | null;
};

type OptionsType = {
    disableAutoUpdating?: boolean;
    disableFormatDistance?: boolean;
    label?: string;
    short?: boolean;
};

type DateTimeFormatOptions = {
    localeMatcher?: 'lookup' | 'best fit';
    weekday?: 'long' | 'short' | 'narrow';
    era?:  'long' | 'short' | 'narrow';
    year?: 'numeric' | '2-digit';
    month?: 'numeric' | '2-digit' | 'long' | 'short' | 'narrow';
    day?: 'numeric' | '2-digit';
    hour?: 'numeric' | '2-digit';
    minute?: 'numeric' | '2-digit';
    second?: 'numeric' | '2-digit';
    timeZoneName?: 'long' | 'short';
    formatMatcher?: 'basic' | 'best fit';
    hour12?: boolean;
    timeZone?: string; // this is more complicated than the others, not sure what I expect here
}

const getFormattedDateString = (epoch: number | null, opt?: OptionsType) => {
    if (!epoch || !isValid(epoch)) {
        return "";
    }
    const label = opt?.label || "";

    const epochDate = new Date(epoch);
    if (!opt?.disableFormatDistance && differenceInHours(new Date(), epochDate) < 24) {
        return `${label || ""} ${formatDistanceToNow(epochDate,{addSuffix: true})}`;
    }

    const options: DateTimeFormatOptions = {
        year: "numeric",
        month: "long",
        day: "numeric",
        weekday: "long",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        timeZoneName: "short",
        hour12: false,
    };

    if (opt?.short) {
        options.month = "short";
        delete options.weekday;
    }

    const activityTime = new Date(epoch).toLocaleString("en-US", options);

    return `${label || ""} ${activityTime}`;
};

const FormattedActivityTime = (props: FormattedActivityTimeProps) => {

    const {propsTypography, activityEpoch, options} = props;

    const [formattedTime, setFormattedTime] = useState<string>(getFormattedDateString(activityEpoch, options));

    const tick = options?.disableAutoUpdating ? null : 25000;

    useRecursiveTimeout(() => {
        setFormattedTime(getFormattedDateString(activityEpoch, options));
    }, tick);

    useEffect(() => {
        setFormattedTime(getFormattedDateString(activityEpoch, options));
    }, [activityEpoch]);

    return (
        <Typography {...propsTypography} >
            {formattedTime}
        </Typography>
    )
}

export default FormattedActivityTime;