import React, {Fragment, ReactNode} from "react";
import {getValueWithUnits} from "../../Helpers/SystemOfMeasures";
import type {SystemOfMeasuresType} from "../../Helpers/SystemOfMeasures";

type TextControlWithSettingProps = {
    label: string
    value: string
    units: string | undefined
    settings: ReactNode;
    systemOfMeasures: SystemOfMeasuresType
}

export const TextControlWithSetting = (props: TextControlWithSettingProps) => {
    const {
        label,
        value,
        units,
        settings,
        systemOfMeasures
    } = props;

    return (
        <Fragment>
            {settings}
            <Fragment>
                {`${label}: ${getValueWithUnits(systemOfMeasures, Number(value), units)}`}
            </Fragment>
        </Fragment>
    )
}
