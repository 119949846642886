import React, {useState} from 'react';
import { deleteUserClientById } from '../../../../Services/UserService';
import type { ClientItem } from '../../../../Services/UserService';
import ClientItemComponent from "./ClientItemComponent/ClientItemComponent";
import GroupContainer from "../GroupContainer";
import {UserClientsListProps} from "../UserProfileTypes";
import {
    Grid,
    ListItem,
    ListItemAvatar,
    Button,
    ListItemText,
    List
} from '@material-ui/core';
import {Skeleton} from "@material-ui/lab";
import { createStyles, makeStyles } from '@material-ui/core/styles';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { differenceInDays } from 'date-fns';
import AlertDialog from '../../../Dialogs/AlertDialog/AlertDialog';

const useStyles = makeStyles(() =>
    createStyles({
        marginBottom: {
            marginBottom: '20px',
        },
    })
);

const UserClientsList = (props: UserClientsListProps) => {
    const { notify, setLoader, userInfo, loader } = props;

    const classes = useStyles();

    const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);

    const removedClientsId = userInfo.user?.clients
        ?.filter((client) => {
            return client.last_activity_epoch && differenceInDays(new Date(), client.last_activity_epoch) > 30
        })
        .map((client) => client.client_id) || [];

    const openConformationDialog = () => {
        setIsOpenDialog(true);
    }

    const closeConformationDialog = () => {
        setIsOpenDialog(false);
    }

    const batchRemoveOldClients = async () => {
        if (!removedClientsId.length) return;
        setLoader(true);

        try {
            for (const client_id of removedClientsId) {
                await deleteUserClientById(client_id);
            }
            userInfo.updateClients(
                (userInfo?.user?.clients || []).filter((client: any) => {
                    return !removedClientsId.includes(client.client_id);
                })
            );
        } catch (e: any) {
            notify.errorNotify(e?.message || JSON.stringify(e));
        } finally {
            closeConformationDialog();
            setLoader(false);
        }
    };

    return !userInfo.user?.clients && loader ? (
        <GroupContainer
            title="Clients List"
        >
            <List aria-label="main mailbox folders">
                {
                    new Array(7).fill(null).map((el, index, arr)=>(
                        <ListItem divider={index !== (arr.length - 1)} key={index}>
                            <Skeleton animation="wave" height={30} width="5%"/>
                            <ListItemAvatar>
                                <Skeleton animation="wave" variant="circle" height={40} width={40}/>
                            </ListItemAvatar>
                            <ListItemText>
                                <Grid container justifyContent={"space-between"}>
                                    <Skeleton animation="wave" height={40} width="20%"/>
                                    <Skeleton animation="wave" height={40} width="40%"/>
                                </Grid>
                            </ListItemText>
                        </ListItem>
                    ))
                }
            </List>
            <Button
                className={classes.marginBottom}
                disabled
                variant="contained"
                endIcon={<DeleteForeverIcon />}
            >
                Remove old clients
            </Button>
        </GroupContainer>
    ) : (
        <GroupContainer
            title="Clients List"
            collapse
        >
            <List aria-label="main mailbox folders">
                {
                    userInfo.user?.clients?.sort((a:ClientItem, b:ClientItem) => {
                        if (userInfo.clientId === a.client_id) {
                            return -1;
                        }
                        if (userInfo.clientId === b.client_id) {
                            return 1;
                        }
                        if (a.last_activity_epoch < b.last_activity_epoch) {
                            return 1;
                        }
                        if (a.last_activity_epoch > b.last_activity_epoch) {
                            return -1;
                        }

                        return 0;
                    }).map((client: ClientItem, index: number) => {
                        return (
                            <ClientItemComponent
                                key={client.client_id}
                                client={client}
                                divider={userInfo.user?.clients.length !== (index + 1)}
                                setLoader={setLoader}
                                userInfo={userInfo}
                                notify={notify}
                                loader={loader}
                            />
                        )
                    })
                }

            </List>
            <Button
                className={classes.marginBottom}
                disabled={!removedClientsId.length}
                variant="contained"
                endIcon={<DeleteForeverIcon />}
                onClick={openConformationDialog}
                color="secondary"
            >
                Remove old clients
            </Button>
            <AlertDialog
                dialogTitle={"Remove old clients?"}
                dialogContent={"All clients inactive more than 30 days will be removed."}
                open={isOpenDialog}
                handleAgree={batchRemoveOldClients}
                handleDisagree={closeConformationDialog}
            />
        </GroupContainer>
    )
};

export default UserClientsList;