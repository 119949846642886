import {MenuItem, TextField} from "@material-ui/core";
import React from "react";
import {useCommonStyles} from "../common/Common";

type SelectControlElementProps = {
    propertyKey: string;
    label: string;
    value: string | number;
    disabled: boolean;
    controlInputProps?: object;
    valueOptionsList: any[];
    handleOnSelect(e: any): void;
    handleOnBlur(e: any): void;
    handleOnClick?(e: any): void;
    isHMCharacteristicValue?: boolean
}

const SelectControlElement = (props: SelectControlElementProps) => {
    const {propertyKey, label, value, valueOptionsList, disabled, handleOnSelect, handleOnBlur, controlInputProps, handleOnClick, isHMCharacteristicValue} = props;
    const commonClasses = useCommonStyles();

    type HMCharacteristicValueItem = {value: number, label: string}

    return (
        <TextField
            {...controlInputProps}
            select
            name={propertyKey}
            label={label}
            value={value}
            disabled={disabled}
            onChange={handleOnSelect}
            onBlur={handleOnBlur}
            onClick={handleOnClick}
            className={commonClasses.fullWidth}
        >
            {
                isHMCharacteristicValue ?
                    (Array.isArray(valueOptionsList) ? valueOptionsList : []).map((option: HMCharacteristicValueItem) => (
                        <MenuItem key={option.label} value={option.value}>
                            <div>{option.label}</div>
                        </ MenuItem>
                    ))
                    :
                    (Array.isArray(valueOptionsList) ? valueOptionsList : []).map((option: string) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </ MenuItem>
                    ))
            }
        </TextField>
    )
}

export default SelectControlElement
