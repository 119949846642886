import {PlanEditController} from './PlanEditController';


export class MenuEventsController {
    protected planEditController: PlanEditController;
    
    constructor(planEditController: PlanEditController) {
        this.planEditController = planEditController;
        
         window.addEventListener('message', (event: MessageEvent) => {
            this.handleMessage(event);
        }, false);
    }
    
    public handleMessage(event: any): void {
        // console.log('handleMessage', event.data);

        let data = event.data;

        if (!data.type) {
            return;
        }
        
        console.log(data);
        switch (data.type) {
            case 'selectAndDragToolSelected': {
                this.editElementToolSelected(event);
                break;
            }
            case 'createWallToolSelected': {
                this.createWallToolSelected(event);
                break;
            }
            case 'zoomInButtonClicked': {
                this.zoomInSelected(event);
                break;
            }
            case 'zoomOutButtonClicked': {
                this.zoomOutSelected(event);
                break;
            }
            case 'undoButtonClicked': {
                this.undoClicked(event);
                break;
            }
            case 'redoButtonClicked': {
                this.redoClicked(event);
                break;
            }
            case 'deleteButtonClicked': {
                this.removeClicked(event);
                break;
            }
            case 'createDoorToolSelected': {
                this.createDoorToolSelected(event);
                break;
            }
            case 'createWindowToolSelected': {
                this.createWindowToolSelected(event);
                break;
            }
            case 'updateElementProperties': {
                this.updateElementProperties(event);
                break;
            }
        }
    }

    public selectAndDragToolSelected(event: any): void {
        // Activate Select & Drag tool
    }
    
    public editElementToolSelected(event: any): void {
        this.planEditController.selectEditElementTool(event);
    }

    public createWallToolSelected(event: any): void {
        // Activate Create Element tool
        // Type: SolidWall
        // Properties: Thickness
        this.planEditController.selectCreateWallTool(event);
    }

    public zoomInSelected(event: any): void {
        this.planEditController.zoomIn();
    }

    public zoomOutSelected(event: any): void {
        this.planEditController.zoomOut();
    }

    public undoClicked(event: any): void {
        console.log("undoClicked")
        this.planEditController.makeUndo();
    }

    public redoClicked(event: any): void {
        this.planEditController.makeRedo();
        /*
        // console.log('Editor: REDO');
        if (this.redoHistory.length > 0) {
            let nextStateJSON = this.redoHistory.pop();
            this.undoHistory.push(nextStateJSON);
            this.drawingLayer.removeChildren();
            this.drawingLayer.importJSON(nextStateJSON);
            this.floorRendering.duplicatePathsCleanUp();
            this.floorRendering.drawJoins();
            this.floorRendering.drawMeasurements();
        }
        */
    }

    public removeClicked(event: any): void {
        /*
        // console.log('Editor: Remove');
        project.activeLayer.children.forEach(
            function (child) {
                if (child.selected) {
                    child.remove();
                }
            }
        );

        Alignment.alignAllElements(this.drawingLayer);
        this.floorRendering.duplicatePathsCleanUp();
        this.floorRendering.drawJoins();
        this.floorRendering.drawMeasurements();

        this.undoHistory.push(this.drawingLayer.exportJSON());
        this.redoHistory = [];
        */
        this.planEditController.removeSelectedElements();
        
    }

    public createDoorToolSelected(event: any): void {
        this.planEditController.selectCreateDoorTool(event);
        
        /*
        // console.log(event);
        new CreateElementTool(this, ElementType.SINGLE_DOOR)
            .activate();
            */
    }

    public createWindowToolSelected(event: any): void {
        /*
        // console.log(event);
        new CreateElementTool(this, ElementType.SINGLE_WINDOW)
            .activate();
        */
    }
    
    public updateElementProperties(event: any): void {
        /*let data = event.data.data;
        let elementId = data.elementId
        // console.log('[updateElementProperties]', event);
        // console.log('[updateElementProperties]', elementId);

        let elements = this.drawingLayer.children.filter(
            (element: any) => {
                return element && element.data.id == elementId;
            }
        );

        // console.log('[updateElementProperties]', elements.length);

        if (elements && elements.length > 0) {
            let element = elements[0];
            // console.log('[updateElementProperties]', element);
            data.properties.forEach(
                (eventDataProperty: any) => {
                    element.data.properties.forEach(
                        (elementProperty: any) => {
                            if (eventDataProperty.name == elementProperty.name) {
                                elementProperty.value = eventDataProperty.value;
                            }
                        });
                }
            );
            //element.data.properties = data.properties;
        }

        Alignment.alignAllElements(this.drawingLayer);
        this.floorRendering.drawJoins();
        this.floorRendering.drawMeasurements();
        */
    }

    public zoomIn(): void {
        this.planEditController.zoomIn();
    }

    public zoomOut(): void {
        this.planEditController.zoomOut();
    }
}