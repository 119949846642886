import React from "react";
import {Theme, withStyles} from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import {createStyles, Grid, IconButton, Typography, WithStyles} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
            minHeight: 64
        },
        titleText: {
            paddingLeft: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    });

interface SubDialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const SubDialogTitle = withStyles(styles)((props: SubDialogTitleProps) => {

    const {children, classes, onClose, ...other} = props;

    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Grid container direction="row">
                <Grid item xs={10}>
                    <Typography display="block" className={classes.titleText} variant="h5">
                        {children}
                    </Typography>
                </Grid>
                <Grid item>
                    {onClose ? (
                        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                            <CloseIcon/>
                        </IconButton>
                    ) : null}
                </Grid>
            </Grid>
        </MuiDialogTitle>
    );

});

type DialogTitleProps = {
    titleText?: string
    handleCloseDialog(): void
}

const DialogTitle = (props: DialogTitleProps) => {

    const {titleText, handleCloseDialog} = props;

    return (
        <SubDialogTitle id="customized-dialog-title" onClose={handleCloseDialog}>
            {titleText ? titleText : ""}
        </SubDialogTitle>
    );

};

export default DialogTitle;
