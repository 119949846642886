import {AbstractBinding} from './AbstractBinding'
import {gridSize} from '../Constants';

export class GridBinding extends AbstractBinding {
    constructor(){
        super();
    }
    
    doBinding(point: any):any {
        return point.divide(gridSize).round().multiply(gridSize);        
    }

    clear():any{};
}