import React, { useCallback, useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, useTheme } from "@material-ui/core";
import { PlanViewController } from "Components/FloorPlanEditor/PlanViewController";
import { ObjectItem } from "Services/ObjectsService";
import PlanControlMarker from "Components/FloorPlanEditor/PlanControlMarker/PlanControlMarker";
import useForceUpdate from "Hooks/ForceUpdate/useForseUpdate";
import Icon from "@material-ui/core/Icon";
import { format } from "date-fns";
import { SystemOfMeasuresType } from "Helpers/SystemOfMeasures";

const useStyles = makeStyles((theme) => ({
    insideWrapper: {
        position: "relative",
        height: "95%",
    },
    planBgColor: {
        backgroundColor: theme.palette.type === "dark" ? theme.palette.background.paper : theme.palette.background.default,
    },
    zoomPanel: {
        margin: theme.spacing(0.5),
    },
    iconButton: {
        flexDirection: "column",
        padding: theme.spacing(1),
    }
}));

type PlanControlTypes = {
    currentObjectId: string;
    objects: ObjectItem[];
    parentId?: string;
    systemOfMeasures: SystemOfMeasuresType;
};

const HistoryPlanControl = (props: PlanControlTypes): JSX.Element | null => {

    const { objects, parentId, systemOfMeasures, currentObjectId } = props;

    const theme = useTheme();
    const classes = useStyles();

    const forceUpdate: () => void = useForceUpdate();

    const canvasRef = useRef<HTMLCanvasElement>(null);
    const canvasBlock = useRef<HTMLDivElement>(null);

    const [planViewItem, setPlanViewItem] = useState<PlanViewController | null>(null);
    const [planZoom, setPlanZoom] = useState<number>(1);

    useEffect(() => {
        if (canvasRef.current) {
            setPlanViewItem(new PlanViewController(canvasRef.current, theme.palette.type, systemOfMeasures, forceUpdate, parentId));
        }

        return () => {
            setPlanViewItem(null);
        }
    }, [parentId, systemOfMeasures, forceUpdate, theme.palette.type]);

    useEffect(() => {
        if (planViewItem && objects) {
            planViewItem.loadPlanObjects(objects);
            planViewItem.moveToCenter();
            forceUpdate();
        }
    }, [planViewItem, objects, forceUpdate]);

    const zoomInButtonClicked = useCallback(() => {
        if (planViewItem) {
            planViewItem.zoomIn();
            setPlanZoom(planViewItem.getCurrentZoom());
        }
    }, [planViewItem]);

    const zoomOutButtonClicked = useCallback(() => {
        if (planViewItem) {
            planViewItem.zoomOut();
            setPlanZoom(planViewItem.getCurrentZoom());
        }
    }, [planViewItem]);

    const correctedCenterMarker = useCallback((item, key: "x" | "y") => Math.round((item.position[key] - item.view.bounds[key]) * planZoom), [planZoom]);

    const getMarkers: ()=>(JSX.Element[] | null) = useCallback(() => {
        if (!planViewItem?.getAllMarkedObjects().length) return null;
        if (!parentId) return null;

        return planViewItem?.getAllMarkedObjects().map((_item, _index) => {
            const item = _item.getPaperJSItem();
            const currentItem = item.data.id === currentObjectId;
            const icon = currentItem ? "fas fa-map-marker-alt" : item.data.icon;
            const tooltip = currentItem ? format(new Date(item.data.properties.plan_point.value_epoch), "P HH:mm") : "";
            return (
                <PlanControlMarker
                    centerPoint={{
                        x: correctedCenterMarker(item, "x"),
                        y: correctedCenterMarker(item, "y"),
                    }}
                    object_type={item.data.info.object_type}
                    icon={icon}
                    object_name={''}
                    zoom={planZoom}
                    key={`${currentObjectId}_${_index}`}
                    tooltip={tooltip}
                />

            )
        })
    },[planViewItem, currentObjectId, planZoom, correctedCenterMarker, parentId]);

    return (
        <>
            <div
                className={classes.insideWrapper}
                ref={canvasBlock}
            >
                { getMarkers() }
                <canvas
                    ref={canvasRef}
                    data-paper-resize
                    className={classes.planBgColor}
                />
            </div>
            <div className={classes.zoomPanel}>
                <IconButton color="inherit" onClick={zoomInButtonClicked} className={classes.iconButton}>
                    <Icon className="fa fa-search-plus" fontSize="small"/>
                </IconButton>
                <IconButton color="inherit" onClick={zoomOutButtonClicked} className={classes.iconButton}>
                    <Icon className="fa fa-search-minus" fontSize="small"/>
                </IconButton>
            </div>
        </>
    );
};

export default HistoryPlanControl;
