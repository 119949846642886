import React, { useCallback, useEffect, useRef, useState } from "react";
import {
    Avatar,
    Dialog,
    DialogTitle,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    useMediaQuery,
    useTheme,
} from "@material-ui/core";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import {
    getListAllowedForSharingObjectIds,
    isAllowedObjectSharing,
    Permissions
} from "../../../Services/ObjectsService";
import { AccessLevel, AccessType } from "../../../Services/ObjectsService/Constants";
import { getUserId } from "../../../Services/UserService";
import { getUserAccessLevel } from "../../../Services/Utils";
import PublicContent from "./PublicContent/PublicContent";
import DialogLoader from "../DialogUtils/DialogLoader";
import ListUsersPermissions from "./ListUsersPermissions/ListUsersPermissions";
import AddUsers from "./AddUsers/AddUsers";
import { useGlobalNotificationContext } from "../../Notification/NotificationProvider";
import type { SnackBarType } from "../../../Services/NotificationService";
import { ShareDialogProps, UserPermissionItem } from "./ShareDialogTypes";
import { getObjectPermissions } from "../../../Services/ObjectsService/CRUD";

const ShareDialog = (props: ShareDialogProps) => {
    const {
        open,
        object_item,
        handleDisagree,
    } = props;

    const theme = useTheme();
    const downXs = useMediaQuery(theme.breakpoints.down("xs"));

    const notify: SnackBarType = useGlobalNotificationContext();

    const corePermissions = useRef<Permissions | null>(null);
    const coreUserData = useRef<UserPermissionItem[] | null>(null);
    const coreListObjectID = useRef<string[] | null>(null);

    const [loader, setLoader] = useState<boolean>(false);
    const [userAccessLevel, setUserAccessLevel] = useState<AccessLevel>(AccessLevel.READ);
    const [userData, setUserData] = useState<UserPermissionItem[] | null>(null);
    const [accessType, setAccessType] = useState<{type: AccessType, updated: boolean}>({
        type: AccessType.RESTRICTED,
        updated: false
    });
    const [addPeopleMode, setAddPeopleMode] = useState<boolean>(false);

    const getAccessType = (permissions: Permissions | null) => {
        if (permissions?.public_access?.access_level && permissions.public_access.access_level !== "revoked" && Object.values(AccessLevel).includes(permissions.public_access.access_level)) {
            return AccessType.PUBLIC;
        }

        return AccessType.RESTRICTED;
    };

    const onChangeAccessType =  useCallback((value: AccessType) => {
        setAccessType({
            type: value,
            updated: getAccessType(corePermissions.current) !== value
        });
    },[]);

    const onClickAddPeopleMode = () => {
        setAddPeopleMode((prev) => {
            return !prev;
        })
    };

    const handleLoader =  useCallback((value: boolean) => {
        setLoader(value);
    },[]);

    useEffect(() => {
        if (!open || !object_item?.object_id) {
            return;
        }

        corePermissions.current = null;
        coreUserData.current = null;
        coreListObjectID.current = null;

        setAddPeopleMode(false);
        setUserData(null);
        setAccessType({
            type: AccessType.RESTRICTED,
            updated: false
        });
        setUserAccessLevel(AccessLevel.READ);

        let active = true;
        handleLoader(true);

        (async () => {
            try {
                if (!isAllowedObjectSharing(object_item)) {
                    throw new Error("You can not share this object");
                }

                const res = await getObjectPermissions(object_item.object_id);
                res.list_object_id = await getListAllowedForSharingObjectIds(res?.list_object_id || []);

                if (!res || !active) {
                    return;
                }

                if (Array.isArray(res?.list_object_id) && !res?.list_object_id?.includes(object_item.object_id)) {
                    res.list_object_id.push(object_item.object_id);
                }

                corePermissions.current = {...res.permissions};
                coreListObjectID.current = Array.isArray(res?.list_object_id) ? res?.list_object_id : [object_item.object_id];

                let isOwner = false;

                const user_data: UserPermissionItem[] = res.user_data.map((user: UserPermissionItem) => {
                    if (user.is_owner) {
                        isOwner = user.user_id === getUserId()
                        return user;
                    }

                    return {
                        ...user,
                        access_level: res.permissions.private_acl[user.user_id].access_level
                    };
                });

                coreUserData.current = [...user_data];
                setUserData([...user_data]);
                setUserAccessLevel(isOwner ? AccessLevel.OWNER : (getUserAccessLevel(corePermissions.current || undefined) || AccessLevel.READ));

                setAccessType({
                    type: getAccessType(res?.permissions),
                    updated: false
                });

            } catch (e: any) {
                notify?.errorNotify(e.message || e);
            } finally {
                handleLoader(false);
            }
        })();

        return () => {
            active = false;
        };
    }, [open, object_item.object_id]);

    useEffect(() => {
        if (!coreUserData.current) {
            return;
        }

        setUserData([...coreUserData.current]);
    }, [accessType.type]);

    if (!isAllowedObjectSharing(object_item)) {
        notify.warningNotify("You can not share this object");
        return null;
    }

    return (
        <Dialog
            open={open}
            onClose={handleDisagree}
            aria-labelledby="share-dialog-title"
            aria-describedby="share-dialog-description"
            fullScreen={downXs}
            fullWidth={true}
            maxWidth={"sm"}
        >
            <DialogTitle id="share-dialog-title">
                <List component="div" disablePadding>
                    <ListItem disableGutters>
                        <ListItemAvatar>
                            <Avatar>
                                <PersonAddIcon/>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary="Share With People and Groups"
                            primaryTypographyProps={{variant: downXs ? "subtitle2": "h6"}}
                        />
                    </ListItem>
                </List>
            </DialogTitle>
            <DialogLoader state={loader}/>
            <PublicContent
                userAccessLevel={userAccessLevel}
                publicAccessLevel={corePermissions?.current?.public_access?.access_level || AccessLevel.READ}
                objectItem={object_item}
                accessType={accessType.type}
                onChangeAccessType={onChangeAccessType}
                onClose={handleDisagree}
                setLoader={handleLoader}
                loader={loader}
                listObjectId={coreListObjectID.current}
            />

            <AddUsers
                userData={userData}
                userAccessLevel={userAccessLevel}
                onChangeAccessType={onChangeAccessType}
                loader={loader}
                setLoader={handleLoader}
                objectItem={object_item}
                accessType={accessType.type}
                onClose={handleDisagree}
                addPeopleMode={addPeopleMode}
                listObjectId={coreListObjectID.current}
                onClickAddPeople={onClickAddPeopleMode}
            />
            <ListUsersPermissions
                userData={userData}
                userAccessLevel={userAccessLevel}
                // updateUserData={updateUserData}
                objectItem={object_item}
                isAccessTypeChanged={accessType.updated}
                onChangeAccessType={onChangeAccessType}
                onClickAddPeople={onClickAddPeopleMode}
                loader={loader}
                setLoader={handleLoader}
                onClose={handleDisagree}
                accessType={accessType.type}
                corePermissions={corePermissions.current}
                listObjectId={coreListObjectID.current}
                addPeopleMode={addPeopleMode}
            />
        </Dialog>
    );
};

export default ShareDialog;
