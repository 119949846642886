import type { PropertyItemValue } from "../Services/ObjectsService";

export type SystemOfMeasuresType = "imperial" | "metric";

export type MeasurementUnitsListType = {
    name: string;
    method: string;
    symbol: string;
    coefficient: number | string;
};

export const MEASUREMENT_UNITS_LIST: { [string: string]: MeasurementUnitsListType } = {
    //Units from Apple documentation https://developer.apple.com/documentation/foundation/units_and_measurement
    "squareMegameters": {
        name: "Square Megameters",
        method: "squareMegameters",
        symbol: "Mm²",
        coefficient: 1.00E+12
    },
    "squareKilometers": {
        name: "Square Kilometers",
        method: "squareKilometers",
        symbol: "km²",
        coefficient: 1000000
    },
    "squareMeters": {
        name: "Square Meters",
        method: "squareMeters",
        symbol: "m²",
        coefficient: 1
    },
    "squareCentimeters": {
        name: "Square Centimeter",
        method: "squareCentimeters",
        symbol: "cm²",
        coefficient: 0.0001
    },
    "squareMillimeters": {
        name: "Square Millimeters",
        method: "squareMillimeters",
        symbol: "mm²",
        coefficient: 0.000001
    },
    "squareMicrometers": {
        name: "Square Micrometers",
        method: "squareMicrometers",
        symbol: "µm²",
        coefficient: 1.00E-12
    },
    "squareNanometers": {
        name: "Square Nanometers",
        method: "squareNanometers",
        symbol: "nm²",
        coefficient: 1.00E-18
    },
    "squareInches": {
        name: "Square Inches",
        method: "squareInches",
        symbol: "in²",
        coefficient: 0.00064516
    },
    "squareFeet": {
        name: "Square Feet",
        method: "squareFeet",
        symbol: "ft²",
        coefficient: 0.092903
    },
    "squareYards": {
        name: "Square Yards",
        method: "squareYards",
        symbol: "yd²",
        coefficient: 0.836127
    },
    "squareMiles": {
        name: "Square Miles",
        method: "squareMiles",
        symbol: "mi²",
        coefficient: 2.59E+06
    },
    "acres": {
        name: "Acres",
        method: "acres",
        symbol: "ac",
        coefficient: 4046.86
    },
    "ares": {
        name: "Ares",
        method: "ares",
        symbol: "a",
        coefficient: 100
    },
    "hectares": {
        name: "Hectares",
        method: "hectares",
        symbol: "ha",
        coefficient: 10000
    },
    "megameters": {
        name: "Megameters",
        method: "megameters",
        symbol: "Mm",
        coefficient: 1.00E+06
    },
    "kilometers": {
        name: "Kilometers",
        method: "kilometers",
        symbol: "kM",
        coefficient: 1.00E+03
    },
    "hectometers": {
        name: "Hectometers",
        method: "hectometers",
        symbol: "hm",
        coefficient: 100
    },
    "decameters": {
        name: "Decameters",
        method: "decameters",
        symbol: "dam",
        coefficient: 10
    },
    "meters": {
        name: "Meters",
        method: "meters",
        symbol: "m",
        coefficient: 1
    },
    "decimeters": {
        name: "Decimeters",
        method: "decimeters",
        symbol: "dm",
        coefficient: 1.00E-01
    },
    "centimeters": {
        name: "Centimeters",
        method: "centimeters",
        symbol: "cm",
        coefficient: 0.01
    },
    "millimeters": {
        name: "Millimeters",
        method: "millimeters",
        symbol: "mm",
        coefficient: 0.001
    },
    "micrometers": {
        name: "Micrometers",
        method: "micrometers",
        symbol: "µm",
        coefficient: 0.000001
    },
    "nanometers": {
        name: "Nanometers",
        method: "nanometers",
        symbol: "nm",
        coefficient: 0.000000001
    },
    "picometers": {
        name: "Picometers",
        method: "picometers",
        symbol: "pm",
        coefficient: 0
    },
    "inches": {
        name: "Inches",
        method: "inches",
        symbol: "in",
        coefficient: 2.54E-02
    },
    "feet": {
        name: "Feet",
        method: "feet",
        symbol: "ft",
        coefficient: 0.3048
    },
    "yards": {
        name: "Yards",
        method: "yards",
        symbol: "yd",
        coefficient: 0.9144
    },
    "miles": {
        name: "Miles",
        method: "miles",
        symbol: "mi",
        coefficient: 1609.34
    },
    "scandinavianMiles": {
        name: "Scandinavian Miles",
        method: "scandinavianMiles",
        symbol: "smi",
        coefficient: 1.00E+04
    },
    "lightyears": {
        name: "Light Years",
        method: "lightyears",
        symbol: "ly",
        coefficient: 9.46E+15
    },
    "nauticalMiles": {
        name: "Nautical Miles",
        method: "nauticalMiles",
        symbol: "NM",
        coefficient: 1852
    },
    "fathoms": {
        name: "Fathoms",
        method: "fathoms",
        symbol: "ftm",
        coefficient: 1.8288
    },
    "furlongs": {
        name: "Furlongs",
        method: "furlongs",
        symbol: "fur",
        coefficient: 201.168
    },
    "astronomicalUnits": {
        name: "Astronomical Units",
        method: "astronomicalUnits",
        symbol: "ua",
        coefficient: 1.50E+11
    },
    "parsecs": {
        name: "Parsecs",
        method: "parsecs",
        symbol: "pc",
        coefficient: 3.086E+16
    },
    "megaliters": {
        name: "Megaliters",
        method: "megaliters",
        symbol: "ML",
        coefficient: 1000000
    },
    "kiloliters": {
        name: "Kiloliters",
        method: "kiloliters",
        symbol: "kL",
        coefficient: 1000
    },
    "liters": {
        name: "Liters",
        method: "liters",
        symbol: "L",
        coefficient: 1
    },
    "deciliters": {
        name: "Deciliters",
        method: "deciliters",
        symbol: "dL",
        coefficient: 0.1
    },
    "centiliters": {
        name: "Centiliters",
        method: "centiliters",
        symbol: "cL",
        coefficient: 1.00E-02
    },
    "milliliters": {
        name: "Milliliters",
        method: "milliliters",
        symbol: "mL",
        coefficient: 0.001
    },
    "cubicKilometers": {
        name: "Cubic Kilometers",
        method: "cubicKilometers",
        symbol: "km³",
        coefficient: 1000000000000
    },
    "cubicMeters": {
        name: "Cubic Meters",
        method: "cubicMeters",
        symbol: "m³",
        coefficient: 1000
    },
    "cubicDecimeters": {
        name: "Cubic Decimeters",
        method: "cubicDecimeters",
        symbol: "dm³",
        coefficient: 1.00E+00
    },
    "cubicMillimeters": {
        name: "Cubic Millimeters",
        method: "cubicMillimeters",
        symbol: "mm³",
        coefficient: 1.00E-06
    },
    "cubicInches": {
        name: "Cubic Inches",
        method: "cubicInches",
        symbol: "in³",
        coefficient: 0.0163871
    },
    "cubicFeet": {
        name: "Cubic Feet",
        method: "cubicFeet",
        symbol: "ft³",
        coefficient: 28.3168
    },
    "cubicYards": {
        name: "Cubic Yards",
        method: "cubicYards",
        symbol: "yd³",
        coefficient: 764.555
    },
    "cubicMiles": {
        name: "Cubic Miles",
        method: "cubicMiles",
        symbol: "mi³",
        coefficient: 4.17E+12
    },
    "acreFeet": {
        name: "Acre Feet",
        method: "acreFeet",
        symbol: "af",
        coefficient: 1233000
    },
    "bushels": {
        name: "Bushels",
        method: "bushels",
        symbol: "bsh",
        coefficient: 35.2391
    },
    "teaspoons": {
        name: "Teaspoons",
        method: "teaspoons",
        symbol: "tsp",
        coefficient: 0.00492892
    },
    "tablespoons": {
        name: "Tablespoons",
        method: "tablespoons",
        symbol: "tbsp",
        coefficient: 0.0147868
    },
    "fluidOunces": {
        name: "Fluid Ounces",
        method: "fluidOunces",
        symbol: "fl oz",
        coefficient: 2.96E-02
    },
    "cups": {
        name: "Cups",
        method: "cups",
        symbol: "cup",
        coefficient: 2.40E-01
    },
    "pints": {
        name: "Pints",
        method: "pints",
        symbol: "pt",
        coefficient: 0.473176
    },
    "quarts": {
        name: "Quarts",
        method: "quarts",
        symbol: "qt",
        coefficient: 0.946353
    },
    "gallons": {
        name: "Gallons",
        method: "gallons",
        symbol: "gal",
        coefficient: 3.78541
    },
    "imperialTeaspoons": {
        name: "Imperial Teaspoons",
        method: "imperialTeaspoons",
        symbol: "tsp",
        coefficient: 5.92E-03
    },
    "imperialTablespoons": {
        name: "Imperial Tablespoons",
        method: "imperialTablespoons",
        symbol: "tbsp",
        coefficient: 0.0177582
    },
    "imperialFluidOunces": {
        name: "Imperial Fluid Ounces",
        method: "imperialFluidOunces",
        symbol: "fl oz",
        coefficient: 0.0284131
    },
    "imperialPints": {
        name: "Imperial Pints",
        method: "imperialPints",
        symbol: "pt",
        coefficient: 0.568261
    },
    "imperialQuarts": {
        name: "Imperial Quarts",
        method: "imperialQuarts",
        symbol: "qt",
        coefficient: 1.13652
    },
    "imperialGallons": {
        name: "Imperial Gallons",
        method: "imperialGallons",
        symbol: "gal",
        coefficient: 4.55E+00
    },
    "metricCups": {
        name: "Metric Cups",
        method: "metricCups",
        symbol: "metric cup",
        coefficient: 2.50E-01
    },
    "degrees": {
        name: "Degrees",
        method: "degrees",
        symbol: "°",
        coefficient: 1.00E+00
    },
    "arcMinutes": {
        name: "Arc Minutes",
        method: "arcMinutes",
        symbol: "ʹ",
        coefficient: 0.016667
    },
    "arcSeconds": {
        name: "Arc Seconds",
        method: "arcSeconds",
        symbol: "ʺ",
        coefficient: 0.00027778
    },
    "radians": {
        name: "Radians",
        method: "radians",
        symbol: "rad",
        coefficient: 57.2958
    },
    "gradians": {
        name: "Gradians",
        method: "gradians",
        symbol: "grad",
        coefficient: 0.9
    },
    "revolutions": {
        name: "Revolutions",
        method: "revolutions",
        symbol: "rev",
        coefficient: 3.60E+02
    },
    "kilograms": {
        name: "Kilograms",
        method: "kilograms",
        symbol: "kg",
        coefficient: 1.00E+00
    },
    "grams": {
        name: "Grams",
        method: "grams",
        symbol: "g",
        coefficient: 0.001
    },
    "decigrams": {
        name: "Decigrams",
        method: "decigrams",
        symbol: "dg",
        coefficient: 0.0001
    },
    "centigrams": {
        name: "Centigrams",
        method: "centigrams",
        symbol: "cg",
        coefficient: 0.00001
    },
    "milligrams": {
        name: "Milligrams",
        method: "milligrams",
        symbol: "mg",
        coefficient: 1.00E-06
    },
    "micrograms": {
        name: "Micrograms",
        method: "micrograms",
        symbol: "µg",
        coefficient: 1000000000
    },
    "nanograms": {
        name: "Nanograms",
        method: "nanograms",
        symbol: "ng",
        coefficient: 0
    },
    "picograms": {
        name: "Picograms",
        method: "picograms",
        symbol: "pg",
        coefficient: 0
    },
    "ounces": {
        name: "Ounces",
        method: "ounces",
        symbol: "oz",
        coefficient: 0.0283495
    },
    "pounds": {
        name: "Pounds",
        method: "pounds",
        symbol: "lb",
        coefficient: 4.54E-01
    },
    "stones": {
        name: "Stones",
        method: "stones",
        symbol: "st",
        coefficient: 1.57E-01
    },
    "metricTons": {
        name: "Metric Tons",
        method: "metricTons",
        symbol: "t",
        coefficient: 1.00E+03
    },
    "shortTons": {
        name: "Short Tons",
        method: "shortTons",
        symbol: "ton",
        coefficient: 907.185
    },
    "carats": {
        name: "Carats",
        method: "carats",
        symbol: "ct",
        coefficient: 0.0002
    },
    "ouncesTroy": {
        name: "Ounces Troy",
        method: "ouncesTroy",
        symbol: "oz t",
        coefficient: 0.03110348
    },
    "slugs": {
        name: "Slugs",
        method: "slugs",
        symbol: "slug",
        coefficient: 14.5939
    },
    "newtonsPerMetersSquared": {
        name: "Newtons Per Meter Squared (Equivalent to Pascals)",
        method: "newtonsPerMetersSquared",
        symbol: "N/m²",
        coefficient: 1
    },
    "gigapascals": {
        name: "Gigapascals",
        method: "gigapascals",
        symbol: "GPa",
        coefficient: 1.00E+09
    },
    "megapascals": {
        name: "Megapascals",
        method: "megapascals",
        symbol: "MPa",
        coefficient: 1000000
    },
    "kilopascals": {
        name: "Kilopascals",
        method: "kilopascals",
        symbol: "kPa",
        coefficient: 1000
    },
    "hectopascals": {
        name: "Hectopascals",
        method: "hectopascals",
        symbol: "hPa",
        coefficient: 100
    },
    "inchesOfMercury": {
        name: "Inches of Mercury",
        method: "inchesOfMercury",
        symbol: "inHg",
        coefficient: 3386.39
    },
    "bars": {
        name: "Bars",
        method: "bars",
        symbol: "bar",
        coefficient: 1.00E+05
    },
    "millibars": {
        name: "Millibars",
        method: "millibars",
        symbol: "mbar",
        coefficient: 1.00E+02
    },
    "millimetersOfMercury": {
        name: "Millimeters of Mercury",
        method: "millimetersOfMercury",
        symbol: "mmHg",
        coefficient: 1.33E+02
    },
    "poundsForcePerSquareInch": {
        name: "Pounds Per Square Inch",
        method: "poundsForcePerSquareInch",
        symbol: "psi",
        coefficient: 6894.76
    },
    "metersPerSecondSquared": {
        name: "Meters Per Second Squared",
        method: "metersPerSecondSquared",
        symbol: "m/s²",
        coefficient: 1.00E+00
    },
    "gravity": {
        name: "Gravity",
        method: "gravity",
        symbol: "g",
        coefficient: 9.81E+00
    },
    "seconds": {
        name: "Seconds",
        method: "seconds",
        symbol: "sec",
        coefficient: 1
    },
    "minutes": {
        name: "Minutes",
        method: "minutes",
        symbol: "min",
        coefficient: 60
    },
    "hours": {
        name: "Hours",
        method: "hours",
        symbol: "hr",
        coefficient: 3600
    },
    "terahertz": {
        name: "Terahertz",
        method: "terahertz",
        symbol: "THz",
        coefficient: 1000000000000
    },
    "gigahertz": {
        name: "Gigahertz",
        method: "gigahertz",
        symbol: "GHz",
        coefficient: 1000000000
    },
    "megahertz": {
        name: "Megahertz",
        method: "megahertz",
        symbol: "MHz",
        coefficient: 1.00E+06
    },
    "kilohertz": {
        name: "Kilohertz",
        method: "kilohertz",
        symbol: "kHz",
        coefficient: 1000
    },
    "hertz": {
        name: "Hertz",
        method: "hertz",
        symbol: "Hz",
        coefficient: 1
    },
    "millihertz": {
        name: "Millihertz",
        method: "millihertz",
        symbol: "mHz",
        coefficient: 0.001
    },
    "microhertz": {
        name: "Microhertz",
        method: "microhertz",
        symbol: "µHz",
        coefficient: 0.000001
    },
    "nanohertz": {
        name: "Nanohertz",
        method: "nanohertz",
        symbol: "nHz",
        coefficient: 1.00E-09
    },
    "metersPerSecond": {
        name: "Meters Per Second",
        method: "metersPerSecond",
        symbol: "m/s",
        coefficient: 1.00E+00
    },
    "kilometersPerHour": {
        name: "Kilometers Per Hour",
        method: "kilometersPerHour",
        symbol: "km/h",
        coefficient: 2.78E-01
    },
    "milesPerHour": {
        name: "Miles Per Hour",
        method: "milesPerHour",
        symbol: "mph",
        coefficient: 0.44704
    },
    "knots": {
        name: "Knots",
        method: "knots",
        symbol: "kn",
        coefficient: 5.14E-01
    },
    "kelvin": {
        name: "Kelvin",
        method: "kelvin",
        symbol: "K",
        coefficient: 1
    },
    "celsius": {
        name: "Degree Celsius",
        method: "celsius",
        symbol: "°C",
        coefficient: 1
    },
    "fahrenheit": {
        name: "Degree Fahrenheit",
        method: "fahrenheit",
        symbol: "°F",
        coefficient: 5.56E-01
    },
    "lux": {
        name: "Lux",
        method: "lux",
        symbol: "lx",
        coefficient: ""
    },
    "partsPerMillion": {
        name: "Parts Per Million",
        method: "partsPerMillion",
        symbol: "ppm",
        coefficient: ""
    },
    "kilobits": {
        name: "kilobits",
        method: "kilobits",
        symbol: "kilobits",
        coefficient: 1000
    },
    "megabits": {
        name: "megabits",
        method: "megabits",
        symbol: "mb",
        coefficient: 100000
    },
    "milesPerGallon": {
        name: "Miles Per Gallon",
        method: "milesPerGallon",
        symbol: "mpg",
        coefficient: ""
    },
    "milesPerImperialGallon": {
        name: "Miles Per Imperial Gallon",
        method: "milesPerImperialGallon",
        symbol: "mpig",
        coefficient: ""
    },
    "gigabits": {
        name: "gigabits",
        method: "gigabits",
        symbol: "gb",
        coefficient: 1000000
    },
    "terabits": {
        name: "terabits",
        method: "terabits",
        symbol: "tb",
        coefficient: 10000000
    },
    "petabits": {
        name: "petabits",
        method: "petabits",
        symbol: "pb",
        coefficient: 100000000
    },
    "exabits": {
        name: "exabits",
        method: "exabits",
        symbol: "eb",
        coefficient: ""
    },
    "zettabits": {
        name: "zettabits",
        method: "zettabits",
        symbol: "zb",
        coefficient: 10000000000
    },
    "yottabits": {
        name: "yottabits",
        method: "yottabits",
        symbol: "yb",
        coefficient: 100000000000
    },
    "kilobytes": {
        name: "kilobytes",
        method: "kilobytes",
        symbol: "KB",
        coefficient: 1000
    },
    "megabytes": {
        name: "megabytes",
        method: "megabytes",
        symbol: "MB",
        coefficient: 100000
    },
    "gigabytes": {
        name: "gigabytes",
        method: "gigabytes",
        symbol: "GB",
        coefficient: 1000000
    },
    "terabytes": {
        name: "terabytes",
        method: "terabytes",
        symbol: "TB",
        coefficient: 10000000
    },
    "petabytes": {
        name: "petabytes",
        method: "petabytes",
        symbol: "PB",
        coefficient: 100000000
    },
    "exabytes": {
        name: "exabytes",
        method: "exabytes",
        symbol: "EB",
        coefficient: 1000000000
    },
    "zettabytes": {
        name: "zettabytes",
        method: "zettabytes",
        symbol: "ZB",
        coefficient: 10000000000
    },
    "yottabytes": {
        name: "yottabytes",
        method: "yottabytes",
        symbol: "YB",
        coefficient: 100000000000
    },
    "kibibits": {
        name: "kibibits",
        method: "kibibits",
        symbol: "kbb",
        coefficient: 1.02E+03
    },
    "mebibits": {
        name: "mebibits",
        method: "mebibits",
        symbol: "mbb",
        coefficient: 102400
    },
    "gibibits": {
        name: "gibibits",
        method: "gibibits",
        symbol: "gbb",
        coefficient: 1024000
    },
    "tebibits": {
        name: "tebibits",
        method: "tebibits",
        symbol: "tbb",
        coefficient: 10240000
    },
    "pebibits": {
        name: "pebibits",
        method: "pebibits",
        symbol: "pbb",
        coefficient: 1.02E+08
    },
    "exbibits": {
        name: "exbibits",
        method: "exbibits",
        symbol: "ebb",
        coefficient: 1.02E+09
    },
    "zebibits": {
        name: "zebibits",
        method: "zebibits",
        symbol: "zbb",
        coefficient: 10240000000
    },
    "yobibits": {
        name: "yobibits",
        method: "yobibits",
        symbol: "ybb",
        coefficient: 102400000000
    },
    "kibibytes": {
        name: "kibibytes",
        method: "kibibytes",
        symbol: "KBB",
        coefficient: 1.02E+03
    },
    "mebibytes": {
        name: "mebibytes",
        method: "mebibytes",
        symbol: "MBB",
        coefficient: 102400
    },
    "gibibytes": {
        name: "gibibytes",
        method: "gibibytes",
        symbol: "GBB",
        coefficient: 1024000
    },
    "tebibytes": {
        name: "tebibytes",
        method: "tebibytes",
        symbol: "TBB",
        coefficient: 10240000
    },
    "pebibytes": {
        name: "pebibytes",
        method: "pebibytes",
        symbol: "PBB",
        coefficient: 1.02E+08
    },
    "exbibytes": {
        name: "exbibytes",
        method: "exbibytes",
        symbol: "EBB",
        coefficient: 1.02E+09
    },
    "zebibytes": {
        name: "zebibytes",
        method: "zebibytes",
        symbol: "ZBB",
        coefficient: 10240000000
    },
    "yobibytes": {
        name: "yobibytes",
        method: "yobibytes",
        symbol: "YBB",
        coefficient: 102400000000
    },
    //Custom units
    "percentage": {
        name: "Percentage",
        method: "percentage",
        symbol: "%",
        coefficient: ""
    }
};

const SYSTEM_OF_MEASURES_MAP: { [key: string]: string } = {
    "celsius": "metric",
    "mm": "metric",
    "fahrenheit": "imperial",
    "inch": "imperial",
}

const UNITS_MAP: { [key: string]: string } = {
    "celsius": "fahrenheit",
    "fahrenheit": "celsius",
    // "mm": "inch",
    // "inch": "mm"
}

const getConvertUnits = (units?: string) => {
    if (!units) {
        return units;
    }

    if (MEASUREMENT_UNITS_LIST.hasOwnProperty(units)) {
        return MEASUREMENT_UNITS_LIST?.[units].symbol;
    }

    return " " + units || "";
};

const convertImperialSizeValueToMetric = (ft: number, inch: number): number => Math.round((ft * 12 + inch) * 25.4)

const convertMetricSizeToImperialValue = (value: number): [number, number] => {
    let inch = Math.round(value / 25.4)
    let ft = Math.floor(inch / 12)
    inch = inch % 12
    return [ft, inch]
}

const convertSizeValueToTargetMetricSystem = (target_system: SystemOfMeasuresType, value: number, units: string | undefined): string => {
    if (target_system === "imperial") {
        switch (units) {
            case "mm":
                let inch = Math.round(value / 25.4)
                let ft = Math.floor(inch / 12)
                inch = inch % 12
                return ft > 0 ? `${ft}ft ${inch}in` : `${inch}in`
            default:
                return String(value)
        }
    } else {
        return String(value)
    }
}

// Returns values converted to a given measurement system
const convertValueToTargetMetricSystem = (target_system: SystemOfMeasuresType, value: number, units: string | undefined): number => {
    if (target_system === "imperial") {
        switch (units) {
            case "celsius":
                return Number((value * 1.8 + 32).toFixed(1))
            default:
                return value
        }
    } else {
        switch (units) {
            case "fahrenheit":
                return Number(((value - 32) / 1.8).toFixed(1))
            default:
                return value
        }
    }
}

// Returns the value and unit of measure (symbol)
const getValueWithUnits = (user_system_of_measures: SystemOfMeasuresType = "imperial", value: PropertyItemValue, units: string | undefined) => {
    if (!units) return `${value} ${units || ""}`

    if (Object.keys(SYSTEM_OF_MEASURES_MAP).includes(units) && SYSTEM_OF_MEASURES_MAP[units] !== user_system_of_measures) {
        // for plan view
        if (["mm", "inch"].includes(units)) {
            return convertSizeValueToTargetMetricSystem(user_system_of_measures, Number(value), units)
        }

        return `${convertValueToTargetMetricSystem(user_system_of_measures, Number(value), units)}${getConvertUnits(UNITS_MAP[units])}`
    }

    return `${value}${getConvertUnits(units)}`
}

export {
    convertImperialSizeValueToMetric,
    convertMetricSizeToImperialValue,
    convertValueToTargetMetricSystem,
    getValueWithUnits
}
