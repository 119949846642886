import React, {useEffect, useState} from "react";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Popover from "@material-ui/core/Popover";
import TextField from "@material-ui/core/TextField";
import { useCommonStyles, popoverPosition } from "./common/Common";
import {hideCredentials} from "../../Services/Utils";


type TextControlProps = {
    propertyKey: string;
    label: string;
    value: string;
    onChangeHandler?: boolean;
    variant?: string;
    controlInputProps?: object;
    saveValue(key: string, value: string | number | boolean): void;
};

const TextControl = (props: TextControlProps) => {
    const commonClasses = useCommonStyles();

    const { propertyKey, label, value, variant, onChangeHandler, controlInputProps, saveValue } = props;

    const [textComponentState, setTextComponentState] = useState(value);
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const localVariant = !variant || !["control", "popover"].includes(variant) ? "control" : variant;

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTextComponentState(event.target.value);
        if (onChangeHandler) {
            saveValue(event.target.name, event.target.value);
        }
    };

    const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        if (!onChangeHandler) {
            saveValue(event.target.name, event.target.value);
        }
    };

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const displayPopover = Boolean(anchorEl);
    const _propertyKey = displayPopover ? propertyKey : undefined;

    useEffect(() => {
        setTextComponentState(value);
    }, [value]);

    if (localVariant === "control") {
        return (
            <React.Fragment>
                <TextField
                    {...controlInputProps}
                    value={textComponentState}
                    name={propertyKey}
                    label={label}
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                />
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <Typography component="div">
                <Grid
                    component="label"
                    container
                    alignItems="center"
                    spacing={1}
                    onClick={handlePopoverOpen}
                    wrap="nowrap"
                    className={commonClasses.cursorPointer}
                >
                    <Grid item>
                        <Box fontSize="small">{label}</Box>
                    </Grid>
                    <Grid item>
                        <Box fontSize="small">{hideCredentials(String(value))}</Box>
                    </Grid>
                </Grid>
                <Popover
                    id={_propertyKey}
                    open={displayPopover}
                    anchorEl={anchorEl}
                    onClose={handlePopoverClose}
                    {...popoverPosition}
                >
                    <Box p={1}>
                        <TextField
                            {...controlInputProps}
                            value={textComponentState}
                            name={propertyKey}
                            label={label}
                            onChange={handleOnChange}
                            onBlur={handleOnBlur}
                        />
                    </Box>
                </Popover>
            </Typography>
        </React.Fragment>
    );
};

export {
    TextControl
};