import React, {useEffect, useState} from "react";
import {DialogContent, FormControl, IconButton, Input, InputAdornment, InputLabel,} from "@material-ui/core";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {copyToClipboard} from "../../../../Services/Utils";
import {useGlobalNotificationContext} from "../../../Notification/NotificationProvider";
import type {SnackBarType} from "../../../../Services/NotificationService";
import Actions from "../Actions/Actions";
import {postObject, postObjectPermissions} from "../../../../Services/ObjectsService/CRUD";
import {AccessLevel, AccessType, OriginType} from "../../../../Services/ObjectsService/Constants";
import {getClientId, getUserId} from "../../../../Services/UserService";
import AccessLevelSelect from "../AccessLevelSelect/AccessLevelSelect";
import {PublicContentProps} from "../ShareDialogTypes";

const PublicContent = (props: PublicContentProps) => {
    const {objectItem,  loader, accessType, publicAccessLevel, userAccessLevel, listObjectId, setLoader, onClose,} = props;

    const notify: SnackBarType = useGlobalNotificationContext();

    const [publicUrl, setPublicUrl] = useState<string>("");
    const [accessLevel, setAccessLevel] = useState<AccessLevel>(AccessLevel.READ);

    const onClickCopy = async () => {
        try {
            await copyToClipboard(publicUrl);
            notify.successNotify(`Public URL ${publicUrl} copied!`);
        } catch (e) {
            notify.errorNotify(e);
        }
    };

    const onCloseClicked = () => {
        onClose();
    };

    const onAgreeClicked = async () => {
        if (accessType !== AccessType.PUBLIC || accessLevel === publicAccessLevel) {
            return;
        }
        setLoader(true);

        const epoch = Date.now();

        try {
            // await postRemoteObjectPermissions(object_item.object_id, {public_access: {
            //         access_level: "read",
            //         updated: epoch
            //     }});

            const clientId = getClientId();

            await postObjectPermissions(
                objectItem.object_id,
                {
                    perm_id: "",
                    updated: 0,
                    public_access: {
                        access_level: accessLevel,
                        updated: epoch
                    }
                },
                listObjectId,
                {
                    origin: OriginType.USER,
                    clientId: clientId
                }
            );

            await postObject({
                object_id: objectItem.object_id,
                properties: objectItem.properties
            },{
                origin: OriginType.USER,
                clientId: clientId
            });

            // notify?.successNotify("ACL updated successfully");
            console.log("ACL updated successfully ", objectItem.object_id);
            onCloseClicked();
            // setLoader(false);
        } catch (e: any) {
            console.error(e);
            notify?.errorNotify(e.message || e);
            setLoader(false);
        }

        // return setLoader(false);
    };

    const onChangeAccessType = (value: AccessType) => {
        props.onChangeAccessType(value);
    };

    const onChangeAccessLevel = (value: string) => {
        setAccessLevel(value as AccessLevel);
    };

    useEffect(() => {
        if (!publicAccessLevel) {
            return;
        }

        setAccessLevel(publicAccessLevel === AccessLevel.REVOKED ? AccessLevel.READ : publicAccessLevel);
    }, [publicAccessLevel]);

    useEffect(() => {
        if (!objectItem?.object_id) {
            return;
        }

        setPublicUrl(`${window.location.protocol}//${window.location.host}/objects/${objectItem.object_id}`);

        return () => {
            setPublicUrl("");
        }
    }, [objectItem.object_id]);

    if (accessType !== AccessType.PUBLIC || loader) {
        return null;
    }

    return (<>
        <DialogContent>
            <FormControl fullWidth margin="normal">
                <InputLabel htmlFor="standard-adornment-password">Public Link</InputLabel>
                <Input
                    id="public-link"
                    type="text"
                    value={publicUrl}
                    fullWidth
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                color="primary"
                                aria-label="copy"
                                component="span"
                                onClick={onClickCopy}
                            >
                                <FileCopyIcon/>
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>
            <AccessLevelSelect
                accessLevel={accessLevel}
                userAccessLevel={userAccessLevel}
                onChange={onChangeAccessLevel}
            />
        </DialogContent>
        <Actions
            disabled={loader}
            isOwner={objectItem.owner_id === getUserId()}
            onClose={onCloseClicked}
            accessType={accessType}
            onAgree={onAgreeClicked}
            onChangeAccessType={onChangeAccessType}
        />
    </>);
};

export default PublicContent