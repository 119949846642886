import { Point, Path, Size, Group, Color } from "paper";
import { v4 } from "uuid";
import { AbstractElement, ElementData, ElementType } from "../AbstractElement";
import {
    WALL_ELEMENT_WIDTH,
    WALL_ELEMENT_HEIGHT,
    DOOR_OPEN_ANGLE,
    DOOR_CLOSE_ANGLE,
    MM_PER_PIXEL,
} from "../../../Constants";
import { SolidWall } from "../walls/SolidWall";

export class SingleDoor extends AbstractElement {
    /*
        export type PropertyItem = {
        key: string,
        name: string,
        readable: boolean,
        type: string,
        value: string | number | boolean,
        icon?: string,
        units?: string,
        min?: number,
        max?: number,
        writable: boolean,
        visibility: string[]
    }
    */

    public isSelected(): Boolean {
        return this.paperJSItem.selected;
    }

    protected setDefaultData(): void {
        let data: ElementData = {
            id: v4(),
            type: ElementType.SINGLE_DOOR,
            properties: {
                plan_point: {
                    key: "plan_point",
                    property_id: v4(),
                    name: "Plan Point",
                    value: {},
                    type: "PlanPoint",
                    readable: true,
                    writable: false,
                    visibility: ["details"],
                },
                width: {
                    key: "width",
                    property_id: v4(),
                    name: "width",
                    value: WALL_ELEMENT_WIDTH,
                    type: "number",
                    units: "mm",
                    min: 100,
                    max: 5000,
                    readable: true,
                    writable: false,
                    visibility: ["details"],
                },
                thickness: {
                    key: "thickness",
                    property_id: v4(),
                    name: "thickness",
                    value: WALL_ELEMENT_HEIGHT,
                    type: "number",
                    units: "mm",
                    min: 1,
                    max: 3000,
                    readable: true,
                    writable: false,
                    visibility: ["details"],
                },
                angle: {
                    key: "angle",
                    property_id: v4(),
                    name: "angle",
                    value: 0,
                    type: "number",
                    units: String.fromCharCode(176),
                    min: 0,
                    max: 360,
                    readable: true,
                    writable: false,
                    visibility: ["details"],
                },
            },
            info: { object_name: "Single Door", description: "Single Door Element" },
            formulas: {},
            currentValues: {
                rotation: 0,
            },
            alignment: {},
        };

        this.paperJSItem.data = data;
    }
    protected generatePaperJSItem(): void {
        // console.log("Creating PaperJS item for Door");
        let arc = new Path.Arc({
            from: new Point(
                WALL_ELEMENT_WIDTH * Math.cos((DOOR_CLOSE_ANGLE * Math.PI) / 180),
                WALL_ELEMENT_WIDTH * Math.sin((DOOR_CLOSE_ANGLE * Math.PI) / 180)
            ),
            through: new Point(
                WALL_ELEMENT_WIDTH * Math.cos((0.5 * DOOR_OPEN_ANGLE * Math.PI) / 180),
                WALL_ELEMENT_WIDTH * Math.sin((0.5 * DOOR_OPEN_ANGLE * Math.PI) / 180)
            ),
            to: new Point(
                WALL_ELEMENT_WIDTH * Math.cos((DOOR_OPEN_ANGLE * Math.PI) / 180),
                WALL_ELEMENT_WIDTH * Math.sin((DOOR_OPEN_ANGLE * Math.PI) / 180)
            ),
        });
        
        

        let door = new Path.Line({
            from: new Point(0, 0),
            to: new Point(
                WALL_ELEMENT_WIDTH * Math.cos((DOOR_OPEN_ANGLE * Math.PI) / 180),
                WALL_ELEMENT_WIDTH * Math.sin((DOOR_OPEN_ANGLE * Math.PI) / 180)
            ),
        });

        let outerElement = new Group({
            children: [arc, door],
        });

        let box = new Path.Rectangle({
            point: new Point(0, 0),
            size: new Size(WALL_ELEMENT_WIDTH, WALL_ELEMENT_HEIGHT),
        });

        // console.log('Get Door Path v1');
        box.data.translateX = "0";
        box.data.translateY = "-(1/" + MM_PER_PIXEL + ")*thickness/2";
        box.data.scaleX = "(1/" + MM_PER_PIXEL + ")*width/" + WALL_ELEMENT_WIDTH;
        box.data.scaleY = "(1/" + MM_PER_PIXEL + ")*thickness/" + WALL_ELEMENT_HEIGHT;
        box.pivot = new Point(WALL_ELEMENT_WIDTH / 2, 0);

        outerElement.data.translateX = "0";
        outerElement.data.translateY = "(1/" + MM_PER_PIXEL + ")*thickness/2";
        outerElement.data.scaleX = "(1/" + MM_PER_PIXEL + ")*width/" + WALL_ELEMENT_WIDTH;
        outerElement.data.scaleY = "(1/" + MM_PER_PIXEL + ")*width/" + WALL_ELEMENT_WIDTH;
        outerElement.pivot = new Point(WALL_ELEMENT_WIDTH / 2, 0);

        arc.dashArray = [10, 4];
        let path: paper.Group = new Group({
            children: [box, outerElement],
            fillColor: "white",
            strokeColor: "black",
        });
        
        arc.fillColor = new Color(0,0,0,0)
        
        path.pivot = new Point(WALL_ELEMENT_WIDTH / 2, 0);
        this.paperJSItem = path;
    }
    public updateGeometry(): void {
        // console.log("updating Door Geometry");

        this.paperJSItem.position = new Point(this.getProperty("plan_point"));

        // temporary restore the original rotation
        this.paperJSItem.rotate(-this.paperJSItem.data.currentValues.rotation);

        this.paperJSItem.children.forEach((child: any) => {
            let calculateExpression = (expressionString: any) => {
                let expressionStringWithConstantsReplaced = expressionString;

                // (!) TODO. refactoring needed. No direct access to paperJSItem object should be done
                Object.keys(this.paperJSItem.data.properties).forEach((key: string) => {
                    expressionStringWithConstantsReplaced = expressionStringWithConstantsReplaced.replace(
                        key,
                        this.paperJSItem.data.properties[key].value
                    );
                });
                return eval(expressionStringWithConstantsReplaced);
            };

            // populate unfilled transform fields
            // should moved to function?
            if (!child.data.translateX) {
                child.data.translateX = "0";
            }
            if (!child.data.translateY) {
                child.data.translateY = "0";
            }
            if (!child.data.currentTranslateX) {
                child.data.currentTranslateX = 0;
            }
            if (!child.data.currentTranslateY) {
                child.data.currentTranslateY = 0;
            }
            if (!child.data.scaleX) {
                child.data.scaleX = "width/1000";
            }
            if (!child.data.scaleY) {
                child.data.scaleY = "height/1000";
            }
            if (!child.data.currentScaleX) {
                child.data.currentScaleX = 1;
            }
            if (!child.data.currentScaleY) {
                child.data.currentScaleY = 1;
            }

            // Calculate the values of scale and translate expressions
            child.translate(new Point(-child.data.currentTranslateX, -child.data.currentTranslateY));
            child.scale(1 / child.data.currentScaleX, 1 / child.data.currentScaleY);
            child.data.currentScaleX = calculateExpression(child.data.scaleX);
            child.data.currentScaleY = calculateExpression(child.data.scaleY);
            child.scale(child.data.currentScaleX, child.data.currentScaleY);
            child.data.currentTranslateX = calculateExpression(child.data.translateX);
            child.data.currentTranslateY = calculateExpression(child.data.translateY);
            child.translate(new Point(child.data.currentTranslateX, child.data.currentTranslateY));
        });

        this.paperJSItem.data.currentValues.rotation = this.getProperty("angle");

        this.paperJSItem.rotate(this.paperJSItem.data.currentValues.rotation);
    }
    public alignElement(elements: AbstractElement[]): void {
        if (this.paperJSItem.data.alignment.centerAlignment.length > 0) {
            let alignment = this.paperJSItem.data.alignment.centerAlignment[0];
            let wallElements: SolidWall[] = (elements.filter((element: AbstractElement) => {
                return element.getElementType() === ElementType.SOLID_WALL && alignment.wallId === element.getId();
            }) as unknown) as SolidWall[];

            if (wallElements.length > 0) {
                let wall = wallElements[0];

                let elementPosition = wall.getPointForAlignment(alignment);

                this.setProperty("plan_point", [elementPosition.x, elementPosition.y]);

                let tangent = wall.getTangentForAlignmnet(alignment);

                if (tangent) {
                    this.setProperty("angle", tangent.getAngle() /*+ element.data.orientation*/);
                }

                this.setProperty("thickness", wall.getProperty("thickness"));
            }
        }
    }
    public updateAlignment(elements: AbstractElement[]): void {
        let wallElements: SolidWall[] = (elements.filter((element: AbstractElement) => {
            return element.getElementType() === ElementType.SOLID_WALL && this.getId() !== element.getId();
        }) as unknown) as SolidWall[];

        let centerAligments = wallElements
            .map((wall: SolidWall) => {
                let alignment = wall.getAlignmentForPoint(this.paperJSItem.position);
                /*if(alignment) {
                    if(alignment.wallTime==0 || alignment.wallTime==1) {
                        wall.updateAlignment([this]);
                    }
                }*/
                return alignment;
            })
            .filter((alignment: any) => {
                return alignment !== null;
            });

        this.paperJSItem.data.alignment = {
            centerAlignment: centerAligments,
        };
    }
    public onMouseUp(event: any): void {
        this.paperJSItem.position = new Point(event.point);

        this.setProperty("plan_point", [event.point.x, event.point.y]);
    }
    public onMouseDrag(event: any): void {
        // console.log("Door Mouse Drag", event);
        this.paperJSItem.position = new Point(event.point);
        this.setProperty("plan_point", [event.point.x, event.point.y]);
    }

    public onMouseDown(event: any): void {
        this.paperJSItem.selected = true;
        if (this.isDisabled) return;
        this.paperJSItem.position = new Point(event.point);
        this.setProperty("plan_point", [event.point.x, event.point.y]);
    }
    
    public getDragStartDifference(event: any): any {
        console.log("Event:",event)
        return new Point(event.point).subtract(this.paperJSItem.position)
    }
}

/*
import {Group, Path, Point, Size} from 'paper';
import {AbstractElement, ElementData, ElementType} from '../AbstractElement';
import {
    DOOR_CLOSE_ANGLE,
    DOOR_OPEN_ANGLE,
    MM_PER_PIXEL,
    WALL_ELEMENT_HEIGHT,
    WALL_ELEMENT_WIDTH
} from '../../../Constants';
import {v4} from 'uuid';

export class SingleDoor extends AbstractElement {
    data: ElementData = {
        id: v4(),
        type: ElementType.SINGLE_DOOR,
        properties: [
            {
                name: 'x',
                value: 0,
                type: 'number',
                units: 'mm'
            },
            {
                name: 'y',
                value: 0,
                type: 'number',
                units: 'mm'
            },
            {
                name: 'width',
                value: WALL_ELEMENT_WIDTH,
                type: 'number',
                units: 'mm',
                min: 100,
                max: 5000
            },
            {
                name: 'thickness',
                value: WALL_ELEMENT_HEIGHT,
                type: 'number',
                read_only: true,
                units: 'mm',
                min: 1,
                max: 3000
            },
            {
                name: 'angle',
                value: 0,
                type: 'number',
                read_only: true,
                units: String.fromCharCode(176),
                min: 0,
                max: 360
            }
        ],
        info: {object_name: 'Single Door', description: 'Single Door Element'}
    };

    constructor() {
        super();
        if (!this.data) {
            throw new Error('Element data not set.');
        }

        this.element = this.generateElement();
        this.element.data = this.data;
    }

    generateElement(): paper.Group {
        let arc = new Path.Arc({
            from: new Point(WALL_ELEMENT_WIDTH * Math.cos(DOOR_CLOSE_ANGLE * Math.PI / 180), WALL_ELEMENT_WIDTH * Math.sin(DOOR_CLOSE_ANGLE * Math.PI / 180)),
            through: new Point(WALL_ELEMENT_WIDTH * Math.cos(0.5 * DOOR_OPEN_ANGLE * Math.PI / 180), WALL_ELEMENT_WIDTH * Math.sin(0.5 * DOOR_OPEN_ANGLE * Math.PI / 180)),
            to: new Point(WALL_ELEMENT_WIDTH * Math.cos(DOOR_OPEN_ANGLE * Math.PI / 180), WALL_ELEMENT_WIDTH * Math.sin(DOOR_OPEN_ANGLE * Math.PI / 180))
        });

        let door = new Path.Line({
            from: new Point(0, 0),
            to: new Point(WALL_ELEMENT_WIDTH * Math.cos(DOOR_OPEN_ANGLE * Math.PI / 180), WALL_ELEMENT_WIDTH * Math.sin(DOOR_OPEN_ANGLE * Math.PI / 180))
        });

        let outerElement = new Group({
            children: [arc, door]
        });

        let box = new Path.Rectangle({
            point: new Point(0, 0),
            size: new Size(WALL_ELEMENT_WIDTH, WALL_ELEMENT_HEIGHT),
        });


        // console.log('Get Door Path v1');
        box.data.translateX = '0';
        box.data.translateY = '-(1/' + MM_PER_PIXEL + ')*thickness/2';
        box.data.scaleX = '(1/' + MM_PER_PIXEL + ')*width/' + WALL_ELEMENT_WIDTH;
        box.data.scaleY = '(1/' + MM_PER_PIXEL + ')*thickness/' + WALL_ELEMENT_HEIGHT;
        box.pivot = new Point(WALL_ELEMENT_WIDTH / 2, 0);

        outerElement.data.translateX = '0';
        outerElement.data.translateY = '(1/' + MM_PER_PIXEL + ')*thickness/2';
        outerElement.data.scaleX = '(1/' + MM_PER_PIXEL + ')*width/' + WALL_ELEMENT_WIDTH;
        outerElement.data.scaleY = '(1/' + MM_PER_PIXEL + ')*width/' + WALL_ELEMENT_WIDTH;
        outerElement.pivot = new Point(WALL_ELEMENT_WIDTH / 2, 0);

        arc.dashArray = [10, 4];
        let path: paper.Group = new Group({
            children: [
                box,
                outerElement
            ],
            fillColor: 'white',
            strokeColor: 'black'
        });
        path.pivot = new Point(WALL_ELEMENT_WIDTH / 2, 0);
        return path;
    }
}
*/
