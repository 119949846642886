import React, {useEffect, useRef, useState} from "react";
import {TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import {isEmail} from "../../../Services/Utils";
import {getPlanInfo, SHARING_LIMIT} from "../../../Services/PurchasesService";
import {useUpdateGlobalDialogActionsContext} from "../../../GlobalContext/GlobalContext";
import {getUserId, getUserById, getUsers} from "../../../Services/UserService";
import {UserPermissionItem} from "../../Dialogs/ShareDialog/ShareDialogTypes";

type AddPeopleDropdownProps = {
    sideAdding?: string;
    userData?: UserPermissionItem[] | null;
    usersDict?: any;
    addedEmails: string[];
    setAddedEmails(value: ((prev: string[]) => string[]) | string[]): void;
    emailsInput: string;
    setEmailsInput(value: string): void;
};

const AddPeopleDropdown = (props: AddPeopleDropdownProps) => {

    const {
        sideAdding,
        userData,
        usersDict,
        addedEmails,
        setAddedEmails,
        emailsInput,
        setEmailsInput
    } = props;

    const product_id = getPlanInfo().product_id;

    const setGlobalDialog = useUpdateGlobalDialogActionsContext();

    const [loadingUserList, setLoadingUserList] = useState<boolean>(true);
    const [userList, setUserList] = useState<string[]>([]);

    const current_user = useRef<any>(null);

    useEffect(() => {
        let active = true;

        (async () => {
            try {
                const users = await getUsers();
                current_user.current = await getUserById(getUserId());

                if (!active) {
                    return;
                }

                let usersForList: any[] = [];

                users.forEach((user_item: any) => {
                    let duplicate = false;
                    if (userData) {
                        duplicate = !!userData.find((user: UserPermissionItem) => { return user.email === user_item.email; });
                    }

                    if (!duplicate && user_item.email !== current_user.current.email && !usersForList.includes(user_item.email)) {
                        usersForList.push(user_item.email);
                        if (usersDict) {
                            usersDict.current[user_item.email] = user_item.firstname || user_item.lastname ? user_item.firstname + " " + user_item.lastname : user_item.email;
                        }
                    }
                });

                setUserList(usersForList);
                setLoadingUserList(false);
            } catch (e) {
                console.error(e);
            }
        })();

        return () => {
            active = false;
        }
    }, []);

    useEffect(() => {
        if (!sideAdding) {
            return;
        }

        onInputChangeEmail({type: "change"}, sideAdding + " ");
    }, [sideAdding]);

    const onChangeEmailsList = (e: any, value: any[], reason: any) => {
        if (reason === 'remove-option' || reason === 'clear' || reason === 'blur') {
            return setAddedEmails(value);
        }

        const new_email = value.pop();
        const emails = value;

        let duplicate = false;
        if (userData) {
            duplicate = !!userData.find((user: UserPermissionItem) => { return user.email === new_email; });
        }

        if (isEmail(new_email as string) && !duplicate && new_email !== current_user.current.email && (!addedEmails || !addedEmails.includes(new_email))) {
            if (product_id === "free" && addedEmails.length + (userData?.length || 0) + 1 > SHARING_LIMIT.free) {
                setGlobalDialog("UpgradeToProDialog");
            } else {
                emails.push(new_email);
                setAddedEmails(emails);
            }
        }

        setEmailsInput("");
    }

    const onInputChangeEmail = (e: any, value: any) => {
        const type = e?.type;

        if (type === "change" && product_id === "free" && addedEmails.length + (userData?.length || 0) + 1 > SHARING_LIMIT.free) {
            setGlobalDialog("UpgradeToProDialog");
            setEmailsInput("");
            return;
        }

        if (type === "click" && value === '') {
            setEmailsInput("");
            return;
        }

        if (type !== "change") {
            return;
        }

        if (!value || (value.indexOf(",") === -1 && value.indexOf(" ") === -1)) {
            return setEmailsInput(value);
        }

        let emails = value?.split(/,\s*|\s+/) as string[];

        emails = emails.filter((email) => {
            let duplicate = false;
            if (userData) {
                duplicate = !!userData.find((user: UserPermissionItem) => { return user.email === email; });
            }

            return email && isEmail(email) && !duplicate && email !== current_user.current.email && (!addedEmails || !addedEmails.includes(email));
        });

        if (emails.length) {
            setAddedEmails((prev) => {
                return [...(prev || []), ...emails];
            });
        }

        setEmailsInput("");
    }

    return (
        <Autocomplete
            id="add_people"
            options={userList}
            getOptionLabel={(option) => option}
            multiple
            freeSolo
            filterSelectedOptions
            loading={loadingUserList}
            value={addedEmails}
            onChange={onChangeEmailsList}
            inputValue={emailsInput}
            onInputChange={onInputChangeEmail}
            renderInput={(params) => (
                <TextField
                    {...params}
                    fullWidth={true}
                    variant={"outlined"}
                    label="Add people"
                    placeholder="Add people"
                />
            )}
        />
    );
};

export default AddPeopleDropdown;
