import { Tool } from "paper";
import { AbstractElement } from "./Elements/AbstractElement";
import { AbstractBinding } from "../bindings/AbstractBinding";
import { Point } from "paper";

export interface AbstractToolListener {
    abstractElementsUpdated(tool: AbstractTool, elements: AbstractElement[]): void;
    abstractElementsAdded(tool: AbstractTool, elements: AbstractElement[]): void;
    abstractElementsCompleted(tool: AbstractTool, elements: AbstractElement[]): void;
    abstractElementsRemoved(tool: AbstractTool, elements: AbstractElement[]): void;
}

export abstract class AbstractTool {
    protected paperTool: any;
    protected paperScope: any;
    protected toolListeners: AbstractToolListener[] = [];

    /**
     * An array of currenrly existing elements
     * can be used for either element selection, or for alignment.
     */

    protected elements: AbstractElement[];

    /**
     * The array of bindings to be applied during the tool operation
     * this array is to be set by the class which is managing the tool
     */
    protected bindings: AbstractBinding[];

    constructor(paperScope: any) {
        // Aggregate the PaperJS tool
        this.paperTool = new Tool();

        this.paperTool.onMouseDown = (event: any) => {
            this.onMouseDown(event);
        };
        this.paperTool.onMouseUp = (event: any) => {
            this.onMouseUp(event);
            this.clearBindings();
        };
        this.paperTool.onMouseDrag = (event: any) => {
            this.onMouseDrag(event);
        };

        this.elements = [];
        this.bindings = [];
        this.paperScope = paperScope;
    }

    activate(): void {
        this.paperTool.activate();
    }

    remove(): void {
        this.paperTool.remove();
    }

    /**
        ELEMENTS MANAGEMENT
     */

    /**
        Sets the array of current elements. Can be used by subclasses 
        to perform the selection of the element or alignment of the newly created
        elements
    */

    public setElements(elements: AbstractElement[]): void {
        this.elements = elements;
    }

    /**
      MOUSE EVENTS HANDLERS
     */
    /**
     onMouseDown handler. 
     Invoked by the aggregated PaperJS class.
     Must be implemented in the AbstractTool subclass
     @param event - PaperJS ToolEvent class http://paperjs.org/reference/toolevent/ 
     */
    abstract onMouseDown(event: any): void;

    /**
     onMouseDrag handler. 
     Invoked by the aggregated PaperJS class.
     Must be implemented in the AbstractTool subclass
     @param event - PaperJS ToolEvent class http://paperjs.org/reference/toolevent/ 
     */
    abstract onMouseDrag(event: any): void;

    /**
     onMouseUp handler. 
     Invoked by the aggregated PaperJS class.
     Must be implemented in the AbstractTool subclass
     @param event - PaperJS ToolEvent class http://paperjs.org/reference/toolevent/ 
     */
    abstract onMouseUp(event: any): void;

    /**
     Sets the bindings to be applied
     @param bindings - array of the bindings to be applied. Pass an empty array
     if no bindings are needed
     */
    setBindings(bindings: AbstractBinding[]): void {
        this.bindings = bindings;
    }

    /**
     applys the current bindings to the point
     @param point - PaperJS Point class http://paperjs.org/reference/point/ with the original coordinates
     @returns PaperJS Point class with the new coordinates of the point after the bindings applied
     */
    protected applyBindings(point: any) {
        var boundPoint = new Point(point);
        this.bindings.forEach((binding: AbstractBinding) => {
            // console.log("Make Binding");
            boundPoint = binding.bind(boundPoint);
            // console.log("Made Binding", boundPoint);
        });
        return boundPoint;
    }
    protected clearBindings() {
        this.bindings.forEach((binding: AbstractBinding) => {
            binding.clear();
        });
    }

    // Observer/Observable Pattern
    addListener(listener: AbstractToolListener) {
        if (!this.toolListeners.includes(listener)) {
            this.toolListeners.push(listener);
        }
    }

    removeListener(listener: AbstractToolListener) {
        const index = this.toolListeners.indexOf(listener);
        if (index > -1) {
            this.toolListeners.splice(index, 1);
        }
    }

    protected notifyListenersOnElementsUpdated(elements: AbstractElement[]) {
        this.toolListeners.forEach((listener: AbstractToolListener) => {
            listener.abstractElementsUpdated(this, elements);
        });
    }
    protected notifyListenersOnElementsAdded(elements: AbstractElement[]) {
        this.toolListeners.forEach((listener: AbstractToolListener) => {
            listener.abstractElementsAdded(this, elements);
        });
    }
    protected notifyListenersOnElementsRemoved(elements: AbstractElement[]) {
        this.toolListeners.forEach((listener: AbstractToolListener) => {
            listener.abstractElementsRemoved(this, elements);
        });
    }

    protected notifyListenersOnElementsCompleted(elements: AbstractElement[]) {
        this.toolListeners.forEach((listener: AbstractToolListener) => {
            listener.abstractElementsCompleted(this, elements);
        });
    }
}
