import React, {ReactNode, useEffect, useState} from 'react';
import {PROPERTIES_EDITABLE_ONLY_IN_PRO_VERSION} from "../../../Services/ObjectsService/Constants";
import MultipleSelectControlElement from "./MultipleSelectControlElement";
import {Box, Grid, Popover, Typography} from "@material-ui/core";
import ComponentWithBadge from "../common/ComponentWithBadge";
import {getPlanInfo} from "../../../Services/PurchasesService";
import {popoverPosition, useCommonStyles} from "../common/Common";
import {
    useUpdateGlobalDialogActionsContext,
    // useUpdateGlobalDialogContentActionsContext
} from "../../../GlobalContext/GlobalContext";

type MultipleSelectControlProps = {
    propertyKey: string;
    label: string;
    value: string[];
    valueOptionsList: string[];
    variant?: string;
    saveValue(key: string, value: string[]): void;
    settings?: ReactNode;
};

const MultipleSelectControl = (props: MultipleSelectControlProps) => {
    const commonClasses = useCommonStyles();

    const {propertyKey, label, value, valueOptionsList, variant, saveValue, settings} = props;

    const product_id = getPlanInfo().product_id
    const setGlobalDialog = useUpdateGlobalDialogActionsContext()
    // const setGlobalDialogContent = useUpdateGlobalDialogContentActionsContext()
    const [selectedValues, setSelectedValues] = useState<string[]>(value || []);
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | undefined>(undefined);
    const localVariant = !variant || !["control", "popover"].includes(variant) ? "control" : variant;
    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSelectedValues(event.target.value as string[]);
        saveValue(propertyKey, event.target.value as string[]);
    };
    const displayPopover = Boolean(anchorEl);
    const _propertyKey = displayPopover ? propertyKey : undefined;

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        if (product_id === "free" && PROPERTIES_EDITABLE_ONLY_IN_PRO_VERSION.includes(propertyKey)) {
            setGlobalDialog("UpgradeToProDialog")
            // setGlobalDialogContent("camera_resolution_pro")
        } else {
            setAnchorEl(event.currentTarget);
        }
    };

    const handlePopoverClose = () => {
        setAnchorEl(undefined);
    };

    useEffect(() => {
        setSelectedValues(value);
    }, [value]);


    if (localVariant === "control") {

        return (
            <ComponentWithBadge
                showBadge={product_id === "free" && PROPERTIES_EDITABLE_ONLY_IN_PRO_VERSION.includes(propertyKey)}
                badgeProps={{
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                    badgeContent: "Pro",
                    color: "primary"
                }}
            >
                <MultipleSelectControlElement
                    propertyKey={propertyKey}
                    label={label}
                    value={selectedValues}
                    valueOptionsList={valueOptionsList}
                    disabled={product_id === "free" && PROPERTIES_EDITABLE_ONLY_IN_PRO_VERSION.includes(propertyKey)}
                    handleChange={handleChange}
                    settings={settings}
                />
            </ComponentWithBadge>
        )
    }

    return (
        <Typography component="div">
            <Grid
                component="label"
                container
                alignItems="center"
                spacing={1}
                onClick={handlePopoverOpen}
                wrap="nowrap"
                className={commonClasses.cursorPointer}
            >
                <Grid item>
                    <Box fontSize="small">{label}:</Box>
                </Grid>
                <Grid item>
                    <ComponentWithBadge
                        showBadge={product_id === "free" && PROPERTIES_EDITABLE_ONLY_IN_PRO_VERSION.includes(propertyKey)}
                        fullWidth={true}
                        badgeProps={{
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                            },
                            badgeContent: "Pro",
                            color: "primary"
                        }}
                    >
                        <Box fontSize="small">{(value || []).join(", ")}</Box>
                    </ComponentWithBadge>
                </Grid>
            </Grid>
            <Popover
                id={_propertyKey}
                open={displayPopover}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                {...popoverPosition}
            >
                <Box p={1} className={commonClasses.popoverContent}
                >
                    <MultipleSelectControlElement
                        propertyKey={propertyKey}
                        label={label}
                        value={selectedValues}
                        valueOptionsList={valueOptionsList}
                        disabled={product_id === "free" && PROPERTIES_EDITABLE_ONLY_IN_PRO_VERSION.includes(propertyKey)}
                        handleChange={handleChange}
                        settings={settings}
                    />
                </Box>
            </Popover>
        </Typography>
    );
}

export default MultipleSelectControl