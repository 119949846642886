import React, {useEffect, useRef, useState} from "react";
import {Color, CompoundPath, Group, PaperScope, Path, Point, PointText, Tool} from "paper";
import {ImagePolygonItem, imagePropertyKey, ObjectItem, PointTuple} from "../../../Services/ObjectsService";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import {PointFields} from "../common/PointFields";
import {CardMedia, Collapse, useTheme} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import {useSubPropertyValue} from "../../../Hooks/Subscriptions/useSubPropertyValue";
import ObjectsFromTreeView from "../../ListObjects/ObjectsFromTreeView/ObjectsFromTreeView";
import {UserInfoType} from "../../../Hooks/useUserProfile";

const useCommonStyles = makeStyles((theme) => ({
    cursorPointer: {
        cursor: "pointer",
    },
    insideWrapper: {
        position: "relative",
        // height: "inherit",
        height: "inherit",
        width: "inherit",
        //maxHeight: "fit-content",
        //maxWidth: "fit-content",
        margin: "0 auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    coveredImage: {
        width: "auto",
        maxHeight: "100%",
        maxWidth: "100%",
        margin: "0 auto",
        position: "relative",
    },
    coveringCanvas: {
        position: "absolute",
        top: 0,
        left: 0,
        // backgroundColor: 'rgba(255,255,255,.1)',
        border: 0,
        padding: 0,
    },
    leafletContainer: {
        width: "100%",
        height: "100%",
    },
    border: {
        borderRadius: 7,
        borderColor: theme.palette.divider,
        padding: "20px",
    },
    boxBottomSpacing: {
        paddingBottom: "8px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    popoverContent: {
        minWidth: "175px"
    },
    fullWidth: {
        width: "100%"
    }
}));

export type ImagePolygonComponentProps = {
    maxWidth?: number | string;
    value?: ImagePolygonItem;
    saveValue(value: ImagePolygonItem): void;
    isDisabled?: boolean;
    property_id?: string;
    isPropertyCard?: boolean;
    userInfo: UserInfoType;
    hideTreeView?: boolean;
};

const ImagePolygonComponent = ((props: ImagePolygonComponentProps) => {
    const classes = useCommonStyles();

    const {maxWidth, value, saveValue, userInfo, isDisabled, isPropertyCard} = props;

    const [currentImage, setCurrentImage] = useState("");
    const [loader, setLoader] = useState<boolean>(true);
    const [currentFloatSegments, setCurrentFloatSegments] = useState<PointTuple[]>(value?.points || []);
    const [objectId, setObjectId] = useState<string | undefined>(props.value?.object_id);
    const theme = useTheme();

    const refScope = useRef<any>(new PaperScope());
    const refTool = useRef<any>(new Tool());
    const refPath = useRef<any>(null);
    const refProject = useRef<any>(null);
    const refMarkers = useRef<any[]>([]);
    const refCanvas = useRef<HTMLCanvasElement>(null);
    const refImg = useRef<HTMLImageElement>(null);
    const refImgWidth = useRef<any>(null);
    const refImgHeight = useRef<any>(null);
    const mounted = React.useRef(false);

    useSubPropertyValue(objectId, imagePropertyKey, (data) => {
        if (data?.value) {
            setCurrentImage(data.value as string);
        }
    });

    useEffect(() => {
        mounted.current = true; // Will set it to true on mount ...
        return () => {
            mounted.current = false;
        }; // ... and to false on unmount
    }, []);

    const mathCount = {
        label: isDisabled ? 20 : 30,
        marker: isDisabled ? 9 : 13
    }

    const generateFloatSegments = (_path: any) => {
        const exportedJSON = _path.exportJSON({asString: false});
        if (!Array.isArray(exportedJSON) || !exportedJSON[1] || !exportedJSON[1].segments) {
            return [];
        }

        return exportedJSON[1].segments.map((segment: PointTuple) => {
            return [segment[0] / refImgWidth.current, segment[1] / refImgHeight.current];
        });
    };

    const createNewLabel = (point: any, index: number) => {
        const newPoint = new Point(point.x, point.y + (point.y > 50 ? -mathCount.label : (5 + mathCount.label)));

        let delta: any;

        let marker: any;
        if (isDisabled) {
            marker = new CompoundPath(`M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-6 400H54c-3.3 0-6-2.7-6-6V86c0-3.3 2.7-6 6-6h340c3.3 0 6 2.7 6 6v340c0 3.3-2.7 6-6 6z`);
            marker.scale(1 / 30)
        } else {
            marker = new CompoundPath(`M500 224L469.636 224C 455.724 130.325 381.675 56.276 288 42.364L288 42.364L288 12C 288 5.373 282.627 0 276 0L276 0L236 0C 229.373 0 224 5.373 224 12L224 12L224 42.364C 130.325 56.276 56.276 130.325 42.364 224L42.364 224L12 224C 5.373 224 0 229.373 0 236L0 236L0 276C 0 282.627 5.373 288 12 288L12 288L42.364 288C 56.276 381.675 130.325 455.724 224 469.636L224 469.636L224 500C 224 506.627 229.373 512 236 512L236 512L276 512C 282.627 512 288 506.627 288 500L288 500L288 469.636C 381.675 455.724 455.724 381.675 469.636 288L469.636 288L500 288C 506.627 288 512 282.627 512 276L512 276L512 236C 512 229.373 506.627 224 500 224zM288 404.634L288 364C 288 357.373 282.627 352 276 352L276 352L236 352C 229.373 352 224 357.373 224 364L224 364L224 404.634C 165.826 392.232 119.783 346.243 107.366 288L107.366 288L148 288C 154.627 288 160 282.627 160 276L160 276L160 236C 160 229.373 154.627 224 148 224L148 224L107.366 224C 119.768 165.826 165.757 119.783 224 107.366L224 107.366L224 148C 224 154.627 229.373 160 236 160L236 160L276 160C 282.627 160 288 154.627 288 148L288 148L288 107.366C 346.174 119.768 392.217 165.757 404.634 224L404.634 224L364 224C 357.373 224 352 229.373 352 236L352 236L352 276C 352 282.627 357.373 288 364 288L364 288L404.634 288C 392.232 346.174 346.243 392.217 288 404.634zM288 256C 288 273.673 273.673 288 256 288C 238.327 288 224 273.673 224 256C 224 238.327 238.327 224 256 224C 273.673 224 288 238.327 288 256z`);
            marker.scale(1 / 15)
        }
        marker.set({
            position: point,
            fillColor: "blue",
            strokeColor: 'black',
            locked: true,
            selected: false,
        });

        const label = new PointText({
            fontSize: 12,
            justification: "center",
            point: newPoint,
            fillColor: "black",
            content: `p${index}`,
            locked: true,
            selected: false,
            opacity: 0.5,
            style: {
                backgroundColor: "white",
                border: "1px solid black",
                borderRadius: 6,
                padding: 3
            },
        });

        const backLabel = new Path.Rectangle({
            point: [label.bounds.x - 5, label.bounds.y - 7.5],
            size: [20 + 5 * index.toString().length, 30],
            strokeColor: "black",
            radius: 6,
            fillColor: "white",
            opacity: 0.5,
            locked: true,
            selected: false,
        });

        const backMarker = new Path.Circle({
            center: point,
            strokeColor: "black",
            radius: 20,
            fillColor: "white",
            opacity: 0,
            locked: false,
            selected: false,
        });

        return new Group({
            children: [backMarker, marker, backLabel, label],
            locked: isDisabled,
            selected: false,
            //position: [point.x, point.y + (point.y > 50 ? -mathCount.marker : mathCount.marker)],
            onMouseEnter: (event: any) => {
                if (isDisabled) return;
                marker.set({fillColor: "white"});
                label.opacity = 1;
                backLabel.opacity = 1;
            },
            onMouseLeave: (event: any) => {
                if (isDisabled) return;
                marker.set({fillColor: "blue"});
                label.opacity = 0.5;
                backLabel.opacity = 0.5;
            },
            onMouseDrag: (event: any) => {
                if (isDisabled || (event.event.button && event.event.button !== 0) || !delta) return;
                const _point = new Point([event.point.x + delta.x, event.point.y]);
                refScope.current.activate();
                refPath.current.segments[index].point = new Point([_point.x, _point.y]);

                refMarkers.current[index].remove();
                refMarkers.current[index] = createNewLabel(refPath.current.segments[index].point, index);
                refMarkers.current[index].children[1].set({fillColor: "white"});
                refMarkers.current[index].children[2].opacity = 1;
                refMarkers.current[index].children[3].opacity = 1;
            },
            onMouseDown: (event: any) => {
                if (isDisabled || (event.event.button && event.event.button !== 0)) return;
                delta = new Point([event.target.position.x - event.point.x, event.target.position.y - event.point.y]);
            },
            onMouseUp: () => {
                delta = null;
                const floatSegments = generateFloatSegments(refPath.current)

                // setCurrentFloatSegments(floatSegments);
                saveValue({
                    points: floatSegments,
                    object_id: objectId,
                });
            }
        });
    }

    const displayLabelForPoints = () => {
        refScope.current.activate();
        refMarkers.current.forEach((el) => el.remove())
        refPath.current.segments.forEach((segment: paper.Segment, index: number) => {
            const x = segment.point.x;
            const y = segment.point.y;
            refMarkers.current[index] = createNewLabel(new Point(x, y), index);
        });
    };

    const handleOnLoadImage = () => {
        if (!refCanvas.current || !refImg.current) return;
        console.log("OnLoad");

        const hitOptions = {
            segments: false,
            stroke: true,
            fill: true,
            tolerance: 10,
        };

        const imgWidth = refImg.current.width;
        const imgHeight = refImg.current.height;
        refImgWidth.current = imgWidth;
        refImgHeight.current = imgHeight;

        const canvas = refCanvas.current;

        canvas.width = imgWidth;
        canvas.height = imgHeight;
        canvas.style.width = `${imgWidth}px`;
        canvas.style.height = `${imgHeight}px`;
        canvas.style.top = `calc(50% - ${imgHeight / 2}px)`;
        canvas.style.left = `calc(50% - ${imgWidth / 2}px)`;

        // refScope.current = new PaperScope();
        refScope.current.activate();
        refScope.current.setup(canvas);

        refProject.current = refScope.current.project;
        // refTool.current = new Tool();

        let delta: any;
        let index: any;

        refPath.current = new Path({
            segments: currentFloatSegments.map((item) => {
                const x = item[0] * imgWidth;
                const y = item[1] * imgHeight;
                return new Point(x, y);
            }),
            strokeColor: new Color(`${theme.palette.primary[theme.palette.type]}`),
            fillColor: new Color("rgba(63, 81, 181, .5)"),
            strokeWidth: 1,
            closed: true,
            onMouseDrag: (event: any) => {
                if (isDisabled || (event.event.button && event.event.button !== 0)) return;
                if (delta) {
                    const _point = new Point([event.point.x + delta.x, event.point.y + delta.y]);
                    event.target.set({position: _point});
                    displayLabelForPoints();
                } else {
                    const _point = new Point([event.point.x, event.point.y]);
                    refPath.current.segments[index].point = new Point([_point.x, _point.y + mathCount.marker]);
                    displayLabelForPoints();
                    refMarkers.current[index].children[1].set({fillColor: "white"});
                    refMarkers.current[index].children[2].opacity = 1;
                    refMarkers.current[index].children[3].opacity = 1;
                }
            },
            onMouseDown: (event: any) => {
                index = null;
                delta = null;
                if (isDisabled || (event.event.button && event.event.button !== 0)) return;

                const hitResult: any = event.target.hitTest(event.point, hitOptions);

                if (hitResult) {
                    if (hitResult.type === "fill") {
                        delta = new Point([event.target.position.x - event.point.x, event.target.position.y - event.point.y]);
                    }

                    if (hitResult.type === "stroke") {
                        index = hitResult.location.index + 1;
                        refPath.current.insert(index, event.point);
                        // setCurrentFloatSegments(generateFloatSegments(path));
                        displayLabelForPoints();
                    }
                }
            },
            onMouseUp: () => {
                index = null;
                delta = null;
                const floatSegments = generateFloatSegments(refPath.current);
                // setCurrentFloatSegments(floatSegments);
                saveValue({
                    points: floatSegments,
                    object_id: objectId,
                });
            },
            onMouseEnter: (event: any) => {
                event.target.set({strokeWidth: 2});
            },
            onMouseLeave: (event: any) => {
                event.target.set({strokeWidth: 1});
            }
        });

        refTool.current.onMouseDown = (event: any) => {
            if (isDisabled || (event.event.button && event.event.button !== 0)) return refCanvas.current?.click();

            if (!refProject.current.hitTest(event.point, hitOptions)) {
                refPath.current.add(event.point);
                refMarkers.current.push(createNewLabel(event.point, refMarkers.current.length));
                // const floatSegments = generateFloatSegments(path);
                // setCurrentFloatSegments(floatSegments);

                /*saveValue({
                    points: generateFloatSegments(refPath.current),
                    object_id: objectId,
                });*/
            }
        };

        refTool.current.onMouseUp = (event: any) => {
            if (isDisabled || (event.event.button && event.event.button !== 0)) {
                return refCanvas.current?.click();
            }

            if (!refProject.current.hitTest(event.point, hitOptions)) {
                saveValue({
                    points: generateFloatSegments(refPath.current),
                    object_id: objectId,
                });
            }
        };

        // refScope.current = scope;
        // refTool.current = tool;
        // refPath.current = path;
        // refProject.current = project;

        displayLabelForPoints();
    };

    const handleChangeSelectedObject = (object: ObjectItem | null, reason?: string) => {
        if (!mounted.current)  return;

        if (reason === "clear") {
            saveValue({
                points: [],
                object_id: "",
            });
            setCurrentImage("");
            // setCurrentFloatSegments([]);
            cleanRefMarkers();
            return;
        }

        if (object) {
            setCurrentImage(object.properties?.image?.value);
            setObjectId(object.object_id);

            let newSegments: PointTuple[] = [];
            //TODO will be change to Object property, contain ImagePolygon
            if (props.value?.object_id === object.object_id) {
                newSegments = props.value.points;
            }

            // setCurrentFloatSegments(newSegments);

            saveValue({
                points: newSegments,
                object_id: object.object_id,
            });
        }
    };

    const handleChangeFieldsFloatSegments = (index: number, coordinates: PointTuple) => {
        if (isDisabled) return;
        refScope.current.activate();
        const newFloatX: number = coordinates[0] || 0;
        const newFloatY: number = coordinates[1] || 0;
        const newX: number = newFloatX * refImgWidth.current;
        const newY: number = newFloatY * refImgHeight.current;
        refPath.current.removeSegment(index);
        refPath.current.insert(index, new Point(newX, newY));
        saveValue({
            points: generateFloatSegments(refPath.current),
            object_id: objectId,
        });

        refMarkers.current[index]?.set({position: new Point(newX, newY - mathCount.marker)});
    };

    const handleRemoveSegment = (index: number) => {
        if (isDisabled) return;
        refScope.current.activate();
        refPath.current.removeSegment(index);
        refMarkers.current[index]?.remove();
        refMarkers.current.splice(index, 1);
        const floatSegments = generateFloatSegments(refPath.current);
        // setCurrentFloatSegments(floatSegments);
        // displayLabelForPoints();
        saveValue({
            points: floatSegments,
            object_id: objectId,
        });
    };

    useEffect(() => setObjectId(value?.object_id), [value?.object_id]);

    useEffect(() => {
        if (value?.points && JSON.stringify(value.points) !== JSON.stringify(currentFloatSegments)) {
            setCurrentFloatSegments(value.points);

            const imgWidth = refImg.current?.width;
            const imgHeight = refImg.current?.height;

            if (imgHeight && imgWidth && refPath.current) {
                refScope.current.activate();
                refPath.current.segments = value.points.map((item) => {
                    const x = item[0] * imgWidth;
                    const y = item[1] * imgHeight;
                    return new Point(x, y);
                });

                displayLabelForPoints();
            }

        }
    }, [value?.points]);

    useEffect(() => {
        window.addEventListener('resize', handleOnLoadImage, {passive: true});
        return () => {
            window.removeEventListener('resize', handleOnLoadImage);
        }
    }, []);

    const cleanRefMarkers = () => {
        if (refMarkers.current.length) {
            refMarkers.current.forEach((marker, index) => refMarkers.current[index]?.remove());
            refMarkers.current = [];
        }
    }

    return (
        <Box height="inherit" width={"100%"} maxWidth={maxWidth}>
            <Collapse in={!isPropertyCard}>
                <Box className={classes.boxBottomSpacing}>
                    <ObjectsFromTreeView
                        displayLoader={setLoader}
                        handleObject={handleChangeSelectedObject}
                        filterType={['contains_image']}
                        currentObjectId={objectId}
                        userInfo={userInfo}
                    />
                </Box>
            </Collapse>
            <Box height="inherit" width="inherit" className={classes.boxBottomSpacing}>
                {loader && <CircularProgress/>}
                {!loader && currentImage && (
                    <Box className={classes.insideWrapper} id={"BoxMediaWrapper"}>
                        <CardMedia
                            classes={{root: classes.coveredImage}}
                            image={currentImage}
                            component="img"
                            alt=""
                            onLoad={handleOnLoadImage}
                            onError={() => {
                                setCurrentImage("");
                            }}
                            ref={refImg}
                        />
                        <canvas ref={refCanvas} className={classes.coveringCanvas} data-paper-resize/>
                    </Box>
                )}
                {!loader && !currentImage && <Typography align="left">Image does not exist</Typography>}
            </Box>
            <Grid container item xs={12} direction={"column"} className={classes.boxBottomSpacing}>
                {!isPropertyCard &&
                currentFloatSegments.map((point: PointTuple, index: number) => (
                    <PointFields
                        key={`floatPointCoordinates${index}`}
                        point={point}
                        index={index}
                        label={`p${index}`}
                        enableDelete={!isDisabled}
                        isDisabled={loader || isDisabled}
                        controlInputProps={{inputProps: {step: "0.0001"}}}
                        handleRemove={handleRemoveSegment.bind(null, index)}
                        handleUpdate={handleChangeFieldsFloatSegments.bind(null, index)}
                    />
                ))}
            </Grid>
        </Box>
    );
});

export {ImagePolygonComponent};
