import React, {ReactNode} from "react";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {OnOffSwitch} from "./OnOffSwitch/OnOffSwitch";
import {MarkerOnOffSwitch} from "./MarkerOnOffSwitch/MarkerOnOffSwitch";

type SwitchControlProps = {
    propertyKey: string;
    label: string;
    value: boolean;
    fontSize?: string | number | undefined;
    variant?: string;
    saveValue(key: string, value: string | number | boolean): void;
    isDisabled?: boolean;
    settings?: ReactNode;
};

const SwitchControl = (props: SwitchControlProps) => {
    const {propertyKey, label, value, fontSize, variant, saveValue, isDisabled, settings} = props;

    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();
        // event.preventDefault();
        saveValue(event.target.name, event.target.checked);
    };

    const handleClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();
        // event.preventDefault();
    }


    if (variant === "marker") {
        return (
            <MarkerOnOffSwitch
                checked={!!value}
                onChange={handleSwitchChange}
                onClick={handleClick}
                name={propertyKey}
                disabled={isDisabled}
                fontSize={fontSize as number}
            />
        )
    }
    // We were forced to wrap the Switch in a div to keep it working on the map.
    // Unfortunately, this breaks the standard logic, but makes it workable on different views
    return (
        <div>
            <Typography component="div">
                <Grid component="label" container alignItems="center" spacing={1} wrap="nowrap">
                    <Grid item container direction="row" justifyContent="center" alignItems="center">
                        {settings}
                        <Box fontSize={fontSize}>{label}</Box>
                    </Grid>
                    <Grid item>
                        <Box
                            onClick={(e: any) => {
                                e.stopPropagation()
                                saveValue(propertyKey, !value);
                            }}>
                            <OnOffSwitch
                                checked={!!value}
                                name={propertyKey}
                                disabled={isDisabled}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Typography>
        </div>
    );
};

export {SwitchControl};
