import React from "react";
import { Link } from "react-router-dom";
import {
    Divider,
    Icon,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from "@material-ui/core";
import {
    AttachFile as AttachFileIcon,
    Clear as ClearIcon,
    Description as DescriptionIcon,
    HomeWork as HomeWorkIcon,
    Map as MapIcon,
    Public as PublicIcon,
    Share as ShareIcon,
    Star as StarIcon,
    Sync as SyncIcon,
    TimerSharp as TimerIcon,
    Videocam as VideoCamIcon,
} from "@material-ui/icons";

import ListMenuConvItem from "./ListMenuConvItem";
import ListMenuHelpItem from "./ListMenuHelpItem";

import { getOrganization } from "Helpers/OrganizationsHelper";
import { clearDB, forceSync, ObjectItem } from "Services/ObjectsService";
import { isGuest } from "Services/UserService";
import { clearIdentificationCache } from "Services/ObjectsService/ObjectsNativeService/ObjectsIdentificationService/ObjectsIdentificationService";

import { MENU_ITEM_KEYS, MENU_ITEM } from "Constants";

const DEFAULT_PAGE_LINK: string = process.env.REACT_APP_DEFAULT_PAGE_LINK || "";

type ListMenuItemsProps = {
    open: boolean;
    menuOpenToggled(): void;
    setObject(object: ObjectItem): void;
    displayModalDirect(state: boolean): void;
};

const MENU_ITEMS: { [key: string]: string | React.ReactNode }[] = [
    {text: "Public",         key: MENU_ITEM.PUBLIC,         link: "/map",            icon: <PublicIcon/>},
    {text: "Machines",       key: MENU_ITEM.MACHINES,       link: "/machines",       icon: <Icon className={"icon-LocalMachines"}/>},
    {text: "Consumables",    key: MENU_ITEM.CONSUMABLES,    link: "/consumables",    icon: <TimerIcon/>},
    {text: "Cameras",        key: MENU_ITEM.CAMERAS,        link: "/cameras",        icon: <VideoCamIcon/>},
    {text: "Buildings",      key: MENU_ITEM.BUILDINGS,      link: "/buildings",      icon: <HomeWorkIcon/>},
    {text: "Documents",      key: MENU_ITEM.DOCUMENTS,      link: "/documents",      icon: <DescriptionIcon/>},
    {text: "Files",          key: MENU_ITEM.FILES,          link: "/files",          icon: <AttachFileIcon/>},
    {text: "Sites",          key: MENU_ITEM.SITES,          link: "/sites",          icon: <MapIcon/>},
    {text: "Favorites",      key: MENU_ITEM.FAVORITES,      link: "/favorites",      icon: <StarIcon/>},
    {text: "Shared with me", key: MENU_ITEM.SHARED_WITH_ME, link: "/shared-with-me", icon: <ShareIcon/>},
];

const ListMenuItems = (props: ListMenuItemsProps) => {
    const { displayModalDirect, menuOpenToggled, setObject, open } = props;

    const forceSyncDB = async () => {
        await forceSync();
    };

    const onClickClearDB = () => {
        clearIdentificationCache()
        clearDB();
    };

    let orgRouting = "";
    const selectedOrg = getOrganization();

    if (selectedOrg?.object_id) {
        orgRouting = "/org/" + selectedOrg.object_id;
    }

    return (
        <>
            <Divider/>
            <List>
                {MENU_ITEMS.map((menu_item) => (
                    MENU_ITEM_KEYS.includes(menu_item.key as string) && (
                        <ListItem button key={menu_item.key as string} component={Link} to={(menu_item.link === DEFAULT_PAGE_LINK ? '' : orgRouting) + menu_item.link as string} onClick={menuOpenToggled}>
                            <ListItemIcon>{menu_item.icon}</ListItemIcon>
                            <ListItemText primary={menu_item.text}/>
                        </ListItem>
                    )
                ))}
                {!isGuest() && MENU_ITEM_KEYS.includes(MENU_ITEM.CONVERSATIONS) && (
                    <ListMenuConvItem open={open} menuOpenToggled={menuOpenToggled} setObject={setObject} displayModalDirect={displayModalDirect}/>
                )}
                <ListItem button key="ForceSync" onClick={forceSyncDB}>
                    <ListItemIcon><SyncIcon/></ListItemIcon>
                    <ListItemText primary="Force Sync"/>
                </ListItem>
                <ListItem button key="ClearDB" onClick={onClickClearDB}>
                    <ListItemIcon><ClearIcon/></ListItemIcon>
                    <ListItemText primary="Clear DB"/>
                </ListItem>
                {MENU_ITEM_KEYS.includes(MENU_ITEM.HELP) && (
                    <ListMenuHelpItem menuOpenToggled={menuOpenToggled}/>
                )}
            </List>
        </>
    );
};

export default ListMenuItems;
