import Queue, {ProcessFunction, ProcessFunctionCb} from "better-queue";
// @ts-ignore
import MemoryStore from "better-queue-memory";
import {Message} from "./DeviceService";


// Creates its own unique queue for each passed object
class QueueWorkerPool {

    protected queue_pool = new Map();
    protected queue_handler: ProcessFunction<Message, ProcessFunctionCb<any>>;
    protected sendTimeout: number;

    constructor(handler: ProcessFunction<Message, ProcessFunctionCb<any>>,
                sendTimeout: number) {
        this.queue_handler = handler;
        this.sendTimeout = sendTimeout;
    }

    push(message: Message) {
        const queue_id = message.handler;
        const queue_worker = this.queue_pool.get(queue_id);

        if (queue_worker) {
            queue_worker.push(message);
        } else {
            const new_worker = new Queue(this.queue_handler, {
                concurrent: 1,
                store: new MemoryStore(),
                afterProcessDelay: this.sendTimeout
            });
            new_worker.push(message);
            this.queue_pool.set(queue_id, new_worker);
        }
    }

}

export {
    QueueWorkerPool,
}
