import React from "react";
import {Icon, Link, Typography} from "@material-ui/core";
import {SlideType} from "../WelcomeDialogContent";

export const Slide2 = (props: SlideType) => {
    const handleClickOnLink = (event: any)=>{
        event.preventDefault();
        props.handlePageChange(event);
    }

    return (
        <>
            <Typography
                variant={props.xsSize ? "h6" : "h3"}
                align="center"
                gutterBottom={true}>
                Got an IoT device?
            </Typography>

            <Typography
                variant={props.xsSize ? "body1" : "h6"}
                align="center"
                gutterBottom={true}>
                <Icon className={"icon-AppleHomeKit"}/><br/>
                Apple HomeKit
            </Typography>
            <Typography
                variant={props.xsSize ? "body1" : "h5"}
                align="center"
                gutterBottom={true}>
                There are plenty of cool things you can do with it.
            </Typography>
            <Typography
                variant={props.xsSize ? "body1" : "h5"}
                align="center"
                gutterBottom={true}>
                Click <Link onClick={handleClickOnLink}>Next</Link> to explore...
            </Typography>
        </>

    )
}
