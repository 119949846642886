import React, {useState} from "react";
import {
    Chip,
    Divider,
    Grid,
    Typography,
    ListItem,
    Collapse,
    ListItemAvatar,
    ListItemText,
    useMediaQuery,
    useTheme
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ClientItemDetails from "./ClientItemDetails/ClientItemDetails";
import Status from "../../../../Common/Status/Status";
import FormattedActivityTime from "../../../../Common/FormattedActivityTime/FormattedActivityTime";
import {ClientItemComponentProps} from "../../UserProfileTypes";
import CollapseButton from "../../CollapseButton";
import SignOut from "./ClientItemDetails/SignOut/SignOut";
import {useUserProfileStyles} from "../../UserProfileStyles";
import {useSubClientLastActive} from "../../../../../Hooks/Subscriptions/useSubClientLastActive";


const ClientItemComponent = (props: ClientItemComponentProps) => {
    const theme = useTheme();
    const classes = useUserProfileStyles();
    const downXs = useMediaQuery(theme.breakpoints.down("xs"));

    const {client, divider, notify, setLoader, userInfo, loader} = props;
    const [openClientInfo, setOpenClientInfo] = useState<boolean>(false);

    const clientName = client.name || client.os + " " + client.browser_name + " " + client.browser_version;
    const isThisDevice = userInfo.clientId === client.client_id;

    const handleClick = () => {
        setOpenClientInfo((prev) => !prev);
    };

    const { lastActivityEpoch, loginEpoch } = useSubClientLastActive(client.client_id, client.last_activity_epoch,client.login_epoch);

    return (
        <>
            <ListItem
                divider={divider}
                button
                classes={{container: openClientInfo ? classes.opened : undefined}}
                onClick={handleClick}
            >
                <ListItemAvatar>
                    <Status lastActivityEpoch={lastActivityEpoch}/>
                </ListItemAvatar>
                <ListItemText
                    primary={
                        <Grid
                            container
                            direction={downXs ? "column" : "row"}
                            alignItems="center">
                            <Grid
                                item
                                container
                                direction={downXs ? "column" : "row"}
                                alignItems={downXs ? "baseline" : "center"}
                                justifyContent="center"
                                md={9} sm={8} xs={12}>
                                <Grid
                                    container
                                    alignItems={downXs ? "baseline" : "center"}
                                    item md={9} sm={6} xs={12}
                                    >

                                    <Typography variant={downXs ? "caption" : "body1"} color="initial" className={downXs ? classes.boldTypography : ""}>
                                        {clientName}
                                    </Typography>
                                    {
                                        isThisDevice && (
                                            <Chip icon={<CheckCircleIcon color="action"/>} label="This device"
                                                  size="small"/>
                                        )
                                    }
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                item
                                alignItems={downXs ? "baseline" : "center"}
                                md={3} sm={4} xs={12}
                                style={{
                                    width: downXs ? "100%" : "auto"
                                }}
                                onClick={handleClick}
                            >
                                <FormattedActivityTime
                                    propsTypography={{
                                        variant: downXs ? "caption" : "body1",
                                        color: downXs ? "textSecondary" : "initial",
                                    }}
                                    activityEpoch={lastActivityEpoch}
                                    options={{
                                        label: "last active",
                                    }}
                                />
                            </Grid>
                        </Grid>
                    }
                    disableTypography
                    primaryTypographyProps={{variant: "body2", color: "initial"}}
                    classes={{root: classes.clientItemPaddingLeft}}
                />
                <ListItemSecondaryAction>
                    <CollapseButton state={openClientInfo} onClick={handleClick}/>
                </ListItemSecondaryAction>
            </ListItem>
            <Collapse in={openClientInfo} timeout="auto" unmountOnExit mountOnEnter>
                <ClientItemDetails
                    client={{
                        ...client,
                        last_activity_epoch: lastActivityEpoch || client.last_activity_epoch,
                        login_epoch: loginEpoch || client.login_epoch,
                    }}
                    userInfo={userInfo}
                />
                <SignOut client={client} notify={notify} setLoader={setLoader} userInfo={userInfo} loader={loader}/>
                <Divider/>
            </Collapse>
        </>
    );
};

export default ClientItemComponent;