import React from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import BeenhereIcon from "@material-ui/icons/Beenhere";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import {makeStyles} from "@material-ui/core/styles";
import {
    getListFeatures,
    getPlanType,
    upgradePurchaseSubscription
} from "../../Services/PurchasesService";
import {isDevice} from "../../Services/DeviceService";
import {useGlobalNotificationContext} from "../Notification/NotificationProvider";
import type {SnackBarType} from "../../Services/NotificationService";
import {useHistory} from "react-router-dom";
import {Chip} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    cardHeader: {
        backgroundColor:
            theme.palette.type === "light" ? theme.palette.grey[200] : theme.palette.grey[700],
    },
    cardPricing: {
        display: "flex",
        justifyContent: "center",
        alignItems: "baseline",
        marginBottom: theme.spacing(2),
    },
    listDescription: {
        marginLeft: theme.spacing(2),
    }
}));

type PurchaseCardProps = {
    title: string;
    identifier: string;
    pricing: string;
    description: string;
    listDescription: string;
    active: boolean;
    displayLoader(value: boolean): void;
    handleShowInstallAppDialog(value: boolean): void;
};

const PurchaseCard = (props: PurchaseCardProps) => {

    const classes = useStyles();
    const notify: SnackBarType = useGlobalNotificationContext();

    const {title, identifier, pricing, description, listDescription, active, displayLoader} = props;

    const history = useHistory();

    const upgradeSubscription = async (id: string) => {
        if (!isDevice()) {
            props.handleShowInstallAppDialog(true)
        } else {
            try {
                displayLoader(true);

                await upgradePurchaseSubscription(id);
                displayLoader(false);
                history.replace("/profile");
            } catch (e) {
                console.error(e);
                displayLoader(false);
            }
        }
    }

    const handleOnClick = async (id: string) => {

        try {
            const plan = getPlanType(id);
            switch (plan) {
                case "free":
                    notify.successNotify("Downgrade action (only message)");
                    break;
                case "pro":
                    await upgradeSubscription(id);
                    break;
                default:
            }
        } catch (e) {

        }
    }

    return (
        <Grid item xs={12} md={4}>
            <Card>
                <CardHeader
                    title={title}
                    subheader={description}
                    titleTypographyProps={{align: "center"}}
                    subheaderTypographyProps={{align: "center"}}
                    action={
                        active ?
                            <Chip
                                label="Your plan"
                                icon={<BeenhereIcon/>}
                                size="small"
                                color="primary"
                            />
                            : null
                    }
                    className={classes.cardHeader}
                />
                <CardContent>
                    <div className={classes.cardPricing}>
                        <Typography component="h2" variant="h3" color="textPrimary">
                            {pricing}
                        </Typography>
                        <Typography variant="h6" color="textSecondary">
                            /mo
                        </Typography>
                    </div>
                    <Grid container alignItems="flex-start">
                        {listDescription?.length ? (
                            <Typography variant="h6" gutterBottom align="left" className={classes.listDescription}>
                                {listDescription}
                            </Typography>
                        ) : null}
                        {getListFeatures(identifier).map((line, index) => (
                            <Grid key={`feature_${index}`} item container xs={12} alignItems="flex-start">
                                <Grid item xs={1}>
                                    <ArrowRightIcon/>
                                </Grid>
                                <Grid item xs={11}>
                                    <Typography variant="body2" gutterBottom align="left" key={line}>
                                        {line}
                                    </Typography>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </CardContent>
                <CardActions>
                    <Grid container justifyContent="center">
                        {
                            !active &&
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleOnClick.bind(null, identifier)}
                            >
                                {identifier === "free" ? "Downgrade" : "Upgrade"}
                            </Button>
                        }
                    </Grid>
                </CardActions>
            </Card>
        </Grid>
    );
};

export default PurchaseCard;
