import React from "react";
import {Grid, Typography} from "@material-ui/core";
import WelcomeDialogContentBodyImg from "../WelcomeDialogContentBodyImg";
import {SlideType} from "../WelcomeDialogContent";

export const Slide9 = (props: SlideType) => {
    return (
        <>
            <Grid
                container
                spacing={3}
                direction="row"
                justifyContent="space-around"
                alignItems="flex-start">
                <Grid item xs={12} sm={4}>
                    <WelcomeDialogContentBodyImg
                        src={"/img/welcome_dialog/img_slide_9_dark.png"}
                        themePaletteType="dark"
                    />
                    <WelcomeDialogContentBodyImg
                        src={"/img/welcome_dialog/img_slide_9_light.png"}
                        themePaletteType="light"
                    />
                </Grid>
                <Grid item xs={12} sm>
                    <Typography
                        variant={props.xsSize ? "body1" : "h6"}
                        align="left"
                        gutterBottom={true}>
                        Enable the ObjectDetection feature of LocalMachines, and get the notification on all of your iOS or
                        Web LocalMachines installations whenever car, person or other object appears within the camera view.
                    </Typography>
                </Grid>
            </Grid>
        </>
    )
}
