import React from "react";
import Box from "@material-ui/core/Box/Box";
import Grid from "@material-ui/core/Grid/Grid";
import { alpha, makeStyles } from "@material-ui/core/styles";
import FormattedActivityTime from "../../../../../../Common/FormattedActivityTime/FormattedActivityTime";
import Status from "../../../../../../Common/Status/Status";
import {useSubObjectStatus} from "../../../../../../../Hooks/Subscriptions/useSubObjectStatus";

const useStyles = makeStyles((theme) => ({
    state: {
        backgroundColor: alpha("#999999", 0.2),
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(0.5),
    },
    stateInDetails: {
        paddingLeft: theme.spacing(2),
    },
    status: {
        color: theme.palette.success.main,
    },
}));

export type CardActivityBlockProps = {
    objectId: string;
    lastActivityEpoch?: number | null;
    objectDeviceName?: string;
    inDetails?: boolean;
};

const CardActivityBlock = (props: CardActivityBlockProps) => {
    const classes = useStyles();

    const { objectId, lastActivityEpoch, objectDeviceName, inDetails} = props;

    const { lastActive, isOffline, deviceName } = useSubObjectStatus(objectId, lastActivityEpoch, objectDeviceName);

    if (!lastActive) {
        return null;
    }

    return inDetails ? (
        <Box className={[classes.state, classes.stateInDetails].join(" ")}>
            <Grid container spacing={2} direction="row" alignItems="center">
                <Grid item>
                    <Status lastActivityEpoch={lastActive} isOffline={isOffline} />

                </Grid>
                <Grid item>
                    <Status lastActivityEpoch={lastActive} isOffline={isOffline} text={`via ${deviceName}`} />

                </Grid>
                <Grid item>
                    <FormattedActivityTime
                        activityEpoch={lastActive}
                        propsTypography={{
                            gutterBottom: false,
                            variant: "caption",
                        }}
                        options={{
                            short: true,
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
        ) :(
        <Box className={classes.state}>
            <Grid container justifyContent="flex-start" alignItems="center">
                <Grid container item xs={12} justifyContent="center" alignItems="center">
                    <Grid item container xs={3} justifyContent="flex-start">
                        <Status lastActivityEpoch={lastActive} isOffline={isOffline} />
                    </Grid>
                    <Grid item container xs={9} justifyContent="flex-end" alignItems="center">
                        <FormattedActivityTime
                            activityEpoch={lastActive}
                            propsTypography={{
                                gutterBottom: false,
                                variant: "caption",
                            }}
                            options={{
                                short: true,
                            }}
                        />
                    </Grid>
                </Grid>
                {deviceName &&
                <Grid item container xs={12} justifyContent="flex-start">
                    <Status lastActivityEpoch={lastActive} isOffline={isOffline} text={`via ${deviceName}`} />
                </Grid>
                }
            </Grid>
        </Box>
    );
};

export default CardActivityBlock;
