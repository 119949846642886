import {
    getiOSPurchasesSubscriptions,
    upgradeiOSPurchaseSubscription,
    restoreiOSPurchases,
} from "../DeviceService";
import { getClientId, getUserIAPData, setUserIAPData } from "../UserService";
import { FEATURES_BY_PLAN, PRODUCTS_BY_PLAN } from "./Constants";
import { authRequest, REQUEST_TYPE, METHOD } from "../RequestService";
import type { IAPData } from "./Types";

const upgradePurchaseSubscription = async (id: string) => {
    try {
        const res = await upgradeiOSPurchaseSubscription(id);
        return await handlePurchaseResponse(res);
    } catch (e) {
        console.error(e);
        return Promise.reject(e);
    }
};

const restorePurchases = async () => {
    try {
        const res = await restoreiOSPurchases();
        return await handlePurchaseResponse(res);
    } catch (e) {
        console.error(e);
        return Promise.reject(e);
    }
};

const getPurchasesSubscriptions = async () => {
    try {
        return await getiOSPurchasesSubscriptions();
    } catch (e) {
        console.error(e);
        return Promise.reject(e);
    }
};

const handlePurchaseResponse = async (data: IAPData) => {
    if (data.status === "error") {
        return Promise.reject({ message: data.error || "Something went wrong" });
    }

    const result = await postRemoteVerifyReceipt(data);

    setUserIAPData(result);
};

const postRemoteVerifyReceipt = async (data: IAPData) => {
    const body = {
        receipt_data: data["receipt-data"],
        environment: data.environment,
        client_id: getClientId()
    }

    return authRequest("iap/verifyReceipt", {
        method: METHOD.POST,
        body: JSON.stringify(body),
        requestType: REQUEST_TYPE.POST_IAP_VERIFY_RECEIPT,
    });
}

const getPlanInfo = () => {
    const iap = getUserIAPData();

    const isValid = iap?.end_date ? new Date(iap.end_date).getTime() > Date.now() : false;

    return {
        isValid: isValid,
        name: isValid ? "PRO" : "Free",
        end_date: isValid ? new Date(iap.end_date).getTime() : "",
        product_id: isValid ? iap.product_id : "free"
    };
}

const getPlanType = (id: string): string => {
    let plan = "free";

    for (const key in PRODUCTS_BY_PLAN) {
        if (!PRODUCTS_BY_PLAN.hasOwnProperty(key)) {
            continue;
        }

        if (!PRODUCTS_BY_PLAN[key].includes(id)) {
            continue;
        }

        plan = key;
    }

    return plan;
}

const getListFeatures = (id: string) => {
    const plan = getPlanType(id);

    if (!FEATURES_BY_PLAN.hasOwnProperty(plan)) {
        return [];
    }

    return FEATURES_BY_PLAN[plan];
}

export {
    upgradePurchaseSubscription,
    getPurchasesSubscriptions,
    restorePurchases,
    getPlanInfo,
    getPlanType,
    getListFeatures
};
