import {AbstractTool} from "./AbstractTool";
import {ElementsFactory} from "./Elements/ElementsFactory";
import {AbstractElement, ElementType} from "./Elements/AbstractElement";
import {ObjectItem, PropertyItem} from "../../../Services/ObjectsService";

export class CreateElementTool extends AbstractTool {
    protected elementsFactory: ElementsFactory;
    protected elementType: ElementType;
    protected elementProperties: { [index: string]: PropertyItem };
    protected element?: AbstractElement;
    protected object?: ObjectItem;
    protected objectType?: string;

    constructor(paperScope: any, parentId?: string) {
        super(paperScope);
        this.elementsFactory = new ElementsFactory(parentId);
        this.elementType = ElementType.SOLID_WALL;
        this.elementProperties = {};
    }

    // Mouse Event Handlers Implementation
    onMouseDown(event: any): void {
        // TODO: Snap To Grid and Guides should be implemented.
        // Commenting out the legacy code for now.
        //let point = this.floorPlanEditor.snapToGrid(event.point);
        if (this.object && this.elementType !== ElementType.ZONE_ELEMENT) {
            console.log("Creating Element with Object");
            this.element = this.elementsFactory.createWithObject(this.elementType, this.object);

            console.log("Created Element with Object");

            // Only instantiate the same object once
            this.object = undefined;
        } else if (this.elementType === ElementType.ZONE_ELEMENT) {
            this.element = this.elementsFactory.createZone({
                elementType: this.elementType,
                properties: this.elementProperties,
                element: this.element,
                objectType: this.objectType,
                object: this.object,
            });
            this.objectType = undefined;
            this.object = undefined;
        } else {
            this.element = this.elementsFactory.create(this.elementType, this.elementProperties);
        }

        // Do binding
        console.log("Before Binding", event.point);
        event.point = this.applyBindings(event.point);
        console.log("After Binding", event.point);

        // Pass the event to the element
        this.element.onMouseDown(event);
        // Update the element alignment
        this.element.updateAlignment(this.elements);

        if (this.elementType === ElementType.ZONE_ELEMENT) {
            if (!this.elements.includes(this.element)) {
                this.notifyListenersOnElementsAdded([this.element]);
            }
        } else {
            this.notifyListenersOnElementsAdded([this.element]);
        }
    }

    onMouseDrag(event: any): void {
        // Do binding
        event.point = this.applyBindings(event.point);

        if (this.element) {
            // Do binding
            event.point = this.applyBindings(event.point);
            // Pass the event to the element
            this.element.onMouseDrag(event);
            // Update the element alignment
            this.element.updateAlignment(this.elements);
            this.notifyListenersOnElementsUpdated([this.element]);
        }
    }

    onMouseUp(event: any): void {
        // Do binding
        event.point = this.applyBindings(event.point);

        if (this.element) {
            // Do binding
            event.point = this.applyBindings(event.point);
            // Pass the event to the element
            this.element.onMouseUp(event);
            // Update the element alignment
            this.element.updateAlignment(this.elements);
            this.notifyListenersOnElementsCompleted([this.element]);
        }
    }

    // Create Element Tool Specific Methods.
    // Set the type of new element
    setElementType(elementType: ElementType) {
        this.elementType = elementType;
    }

    getElementType(): ElementType {
        return this.elementType;
    }

    // Set the optional properties of new element
    setElementProperties(elementProperties: { [index: string]: PropertyItem }) {
        this.elementProperties = elementProperties;
    }

    // Sets the object to be assigned to the newly created element
    setObject(object: ObjectItem) {
        this.object = object;
    }

    // Sets the object_type to the newly created element
    setObjectType(objectType: string) {
        this.objectType = objectType;
    }
}
