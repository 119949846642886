import React from "react";
import {Grid, Typography} from "@material-ui/core";
import WelcomeDialogContentBodyImg from "../WelcomeDialogContentBodyImg";
import {SlideType} from "../WelcomeDialogContent";

export const Slide6 = (props: SlideType) => {
    return (
        <>
            <Grid
                container
                spacing={3}
                direction="row"
                justifyContent="center"
                alignItems="flex-start">
                <Grid item xs={12} sm={6}>
                    <WelcomeDialogContentBodyImg
                        src={"/img/welcome_dialog/img_slide_6_dark.png"}
                        themePaletteType="dark"/>
                    <WelcomeDialogContentBodyImg
                        src={"/img/welcome_dialog/img_slide_6_light.png"}
                        themePaletteType="light"/>
                </Grid>
                <Grid item xs={12} sm>
                    <Typography
                        variant={props.xsSize ? "body1" : "h6"}
                        align="left"
                        gutterBottom={true}>
                        The history is recorded for all states of all devices observed by the LocalMachines, independently of their technology or vendor.
                    </Typography>
                </Grid>
            </Grid>
        </>
    )
}
