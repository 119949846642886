import React from "react";

import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";

import SignIn from "./SignIn/SignIn";
import SignUp from "./SignUp/SignUp";
import {CssBaseline, Tab, Tabs} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Grid from "@material-ui/core/Grid";
import {UserInfoType} from "../../../Hooks/useUserProfile";
import useAuthorizationStyles from "../AuthorizationStyles";
import Copyright from "../Copyright/Copyright";
import { useGlobalLoaderContext } from "../../../GlobalContext/GlobalContext";


type LoginProps = {
    userInfo: UserInfoType;
};

const Login = (props: LoginProps) => {

    const { userInfo } = props;
    const classes = useAuthorizationStyles();

    const loader = useGlobalLoaderContext();

    const [toggleLogin, setToggleLogin] = React.useState<string>("signin");

    const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        if (loader) return;
        setToggleLogin(newValue);
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Grid container className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Grid container justifyContent={"space-evenly"}>
                    <Tabs
                        value={toggleLogin}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleChange}
                        aria-label="disabled tabs example"
                        disabled={loader}
                    >
                        <Tab label="Sign in" value={"signin"}/>
                        <Tab label=" Sign up" value={"signup"}/>
                    </Tabs>
                </Grid>
            { toggleLogin === "signin" ? (
                <SignIn userInfo={userInfo} toggleLogin={()=>setToggleLogin("signup")}/>
            ) : (
                <SignUp userInfo={userInfo} toggleLogin={()=>setToggleLogin("signin")}/>
            )}
            </Grid>
            <Box mt={5}>
                <Copyright />
            </Box>
        </Container>
    );
};

export default Login;
