import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
    Collapse,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText
} from "@material-ui/core";
import {
    Add as AddIcon,
    Chat as ChatIcon,
    ExpandLess as ExpandLessIcon,
    ExpandMore as ExpandMoreIcon,
    Forum as ForumIcon,
    MoreHoriz as MoreHorizIcon
} from "@material-ui/icons";

import CreateChatPopup from "Components/Menu/PopupMenu/CreateChatPopup";

import { getOrganization, getOrgHierarchy } from "Helpers/OrganizationsHelper";
import { useSubOrganization } from "Hooks/SystemEvent/useContext";
import { EMPTY_OBJECT, OBJECT_TYPE } from "Services/ObjectsService/Constants";
import { getObjects } from "Services/ObjectsService/CRUD";
import type { Params, ObjectItem } from "Services/ObjectsService/Types";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        nested: {
            paddingLeft: theme.spacing(4)
        }
    })
);

type ListMenuConvItemProps = {
    open: boolean;
    menuOpenToggled(): void;
    setObject(object: ObjectItem): void;
    displayModalDirect(state: boolean): void;
};

const CONV_LIMIT: number = 3;

const additionalItems: { [key: string]: string }[] = [{ text: "Browse All", link: "/conversations" }];

const nowrap: { [key: string]: string } = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
};

const ListMenuConvItem = (props: ListMenuConvItemProps) => {
    const { open, menuOpenToggled, setObject, displayModalDirect } = props;

    const classes = useStyles();

    const [openConvSubMenu, setOpenConvSubMenu] = useState<boolean>(false);
    const [anchorElConv, setAnchorElConv] = useState<null | HTMLElement>(null);
    const [listObjects, setListObjects] = useState<ObjectItem[]>([]);

    const orgObjects = useRef<string[]>([]);
    const lastId = useRef<string | null>(null);

    const handleClose = () => {
        setAnchorElConv(null);
        menuOpenToggled();
    };

    const handleCreateConversation = () => {
        setObject(EMPTY_OBJECT);
        handleClose();
    };

    const handleOpenModalDirect = () => {
        displayModalDirect(true);
        handleClose();
    };

    const handleClickConv = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElConv(event.currentTarget);
    };

    const onClickConvMenu = () => {
        setOpenConvSubMenu(prev => !prev);
    };

    const loadMoreObjects = () => {
        getObjectsList(false).then(() => {});
    };

    const getObjectsList = async (init: boolean) => {
        if (!init && !lastId.current) {
            return;
        }

        const params: Params = {
            object_type: [OBJECT_TYPE.CONVERSATION],
            by_page: true,
            limit: CONV_LIMIT
        };

        params.insideHierarchy = orgObjects.current;

        if (!init && lastId.current) {
            params.last_id = lastId.current;
        }

        try {
            const res = await getObjects(params);

            if (res) {
                res.objects = Array.isArray(res.objects) ? res.objects : [];
                lastId.current = res.objects.length === CONV_LIMIT ? res.last_id : null;
                setListObjects((prevState) => (!init ? prevState.concat(res.objects) : res.objects));
            }
        } catch (err) {
            console.error(err);
        }
    };

    const initGrid = useCallback(async () => {
        if (!open) {
            return;
        }

        orgObjects.current = await getOrgHierarchy();
        getObjectsList(true).then(() => {});
    }, [open]);

    useSubOrganization(() => {
        initGrid().then(() => {});
    });

    useEffect(() => {
        initGrid().then(() => {});
    }, [initGrid]);

    let orgRouting = "";
    const selectedOrg = getOrganization();

    if (selectedOrg?.object_id) {
        orgRouting = "/org/" + selectedOrg.object_id;
    }

    return (
        <>
            <ListItem button key="Conversations" onClick={onClickConvMenu}>
                <ListItemIcon><ForumIcon/></ListItemIcon>
                <ListItemText primary="Conversations"/>
                <ListItemSecondaryAction>
                    <IconButton size={'small'} onClick={handleClickConv}><AddIcon/></IconButton>
                    <CreateChatPopup
                        anchorElConv={anchorElConv}
                        handleClose={handleClose}
                        handleOpenModalDirect={handleOpenModalDirect}
                        handleCreateConversation={handleCreateConversation}
                        additionalItems={additionalItems}
                    />
                    <IconButton size={'small'} edge={'end'} onClick={onClickConvMenu}>
                        {openConvSubMenu ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
            <Collapse in={openConvSubMenu} timeout="auto">
                <List disablePadding>
                    {listObjects.map((object, index) => (
                        <ListItem button className={classes.nested} key={index} component={Link} to={`${orgRouting}/conversations/${object.object_id}/chat`} onClick={menuOpenToggled}>
                            <ListItemIcon><ChatIcon/></ListItemIcon>
                            <ListItemText primary={object.object_name} secondary={object.properties?.chat_message?.value?.text} primaryTypographyProps={{ style: nowrap }}/>
                        </ListItem>
                    ))}
                    {lastId.current && (
                        <ListItem button className={classes.nested} onClick={loadMoreObjects}>
                            <ListItemIcon><MoreHorizIcon/></ListItemIcon>
                            <ListItemText primary="Load More"/>
                        </ListItem>
                    )}
                </List>
            </Collapse>
        </>
    )
}

export default ListMenuConvItem;
