import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { filterObjectProperties } from "../../Services/Utils";
import { PropertyItem } from "../../Services/ObjectsService";

const NOT_VISIBLE_ITEMS = ["MapPoint", "MapBounds", "Image"];

export interface MarkerLabelProps {
    object_type: string;
    icon: string | null;
    object_name: string;
    properties: { [string: string]: any };
    selected: boolean;
    presentation: string;
    fontSize: number;
    iconSize: number;
    showData: boolean;
}

const MarkerLabel = ({ ...markerLabel }: MarkerLabelProps) => {
    const useStyles = useMemo(() => {
        return makeStyles({
            // label
            labelStyle: {
                fontSize: markerLabel.fontSize,
                color: "#fff",
                filter:
                    "drop-shadow(1px 0px 1px #000) drop-shadow(-1px 0px 1px #000) drop-shadow(0px -1px 1px #000) drop-shadow(0px 1px 1px #000)",
                msUserSelect: "none",
                userSelect: "none",
                width: "max-content",
            },
            labelPropertiesSelected: {
                border: "1px #fff solid",
                borderRadius: "2px",
                padding: "5px",
                fontWeight: "bold",
                background: "rgba(0, 0, 0, .1)",
            },
            // icon
            iconSize: {
                fontSize: markerLabel.iconSize,
            },
            iconSelected: {
                padding: "2px",
                border: "1px #fff solid",
                background: "rgba(0, 0, 0, .1)",
            },
            // properties
            listStyle: {
                fontSize: markerLabel.iconSize,
                padding: 0,
                margin: 0,
                textAlign: "center",
                listStyleType: "none",
            },
        });
    }, [markerLabel.fontSize, markerLabel.iconSize]);

    const classes = useStyles();

    // label styles
    const labelSelectedStyle = markerLabel.selected ? [classes.labelPropertiesSelected].join(" ") : "";

    //icon styles
    const iconStyle = markerLabel.selected
        ? [classes.iconSize, classes.iconSelected].join(" ")
        : [classes.iconSize].join(" ");

    const iconAwesomeClass =
        markerLabel.icon || (markerLabel.object_type === "Zone" ? "far fa-object-group" : "fas fa-info-circle");

    // properties
    const listProperties = useMemo(() => {
        return Object.values(markerLabel.properties)
            .sort(filterObjectProperties)
            .map((item: PropertyItem) => {
                if (
                    !item.visibility?.includes(markerLabel.presentation) ||
                    !item.readable ||
                    NOT_VISIBLE_ITEMS.includes(item.type) ||
                    item.value === undefined
                ) {
                    return null;
                }

                let label = "";
                if (item.type === "PowerState") {
                    label = item.value === true ? "On" : "Off";
                } else if (item.type === "CurrentTemperature" && item.units === "celsius") {
                    label = `${item.value.toString()} ${String.fromCharCode(0x2103)}`;
                } else {
                    label = item.value.toString();
                }

                return (
                    <li key={item.property_id}>
                        <span>{label}</span>
                    </li>
                );
            })
            .filter((item) => item !== null);
    }, [markerLabel.properties]);

    return (
        <div className={classes.labelStyle}>
            <span className={iconStyle}>
                <i className={iconAwesomeClass} />
            </span>
            {markerLabel.showData && (
                <div className={labelSelectedStyle}>
                    <div>
                        <span>{markerLabel.object_name}</span>
                    </div>
                    {listProperties.length > 0 && (
                        <div>
                            <ul className={classes.listStyle}>{listProperties}</ul>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default React.memo(MarkerLabel);
