import md5 from "md5";
import { v4 } from "uuid";
import { UAParser } from "ua-parser-js";
import { exportPublicJWKFromSPKI } from "../CryptoService";
import type { ExtendedUserCrypto, JsonWebKeyPair } from "../CryptoService";
import {
    deleteRemoteUserClientById,
    deleteRemoteUserDeviceToken,
    getRemoteUsers,
    getRemoteProfile,
    getRemoteUserClientById,
    postRemoteProfile,
    postRemoteUserClientById,
    postRemoteUserDeviceToken,
    postRemoteUserStatus,
    subscribeIAPUserStatus,
    unsubscribeIAPUserStatus,
    subscribeClientLastActive,
    unsubscribeClientLastActive,
} from "./UserRemoteService";
import {
    postLocalUser,
    getLocalUserById,
    getLocalUsers
} from "./UserLocalService";
import type {
    UserItem,
    UserDbItem,
    UserInfo,
    ClientItem,
    GetUserClientByIdParamsType,
    GetUsersParamsType,
    postUserParamsType
} from "./Types";
import { PROFILE_FIELDS_TO_STORE, USER_INFO_KEY_LS } from "./Constants";
import {
    getUserId,
    getDeviceInfo as getDeviceInfoLS,
    getGuestUser,
    getDeviceId as getDeviceIdLS,
    getUserInfo as getUserInfoLS,
    getAppleDeviceTokenId,
    setUserId,
    setDeviceInfo,
    setGuestUser,
    setDeviceId as setDeviceIdLS,
    setUserInfo as setUserInfoLS,
    setAppleDeviceTokenId,
    removeUserId,
    removeAppleDeviceTokenId
} from "./UserStorageService";

const responseWrapper = async (response: Promise<any>, prettyError?: string) => {
    try {
        return await response;
    } catch (e) {
        console.error(e);
        return Promise.reject(prettyError || e);
    }
};

const getProfile = async () => {
    try {
        const local_user = getUserInfo(getUserId());
        let user: any = {}; // [IM] UserInfo type is being assumed here, issue appears in a cycle below
        let get_remote = false;

        PROFILE_FIELDS_TO_STORE.forEach((user_field) => {
            if (local_user.hasOwnProperty(user_field)) {
                user[user_field] = local_user[user_field];
            } else {
                get_remote = true;
            }
        });

        if (local_user.hasOwnProperty('iap'))                                  { user.iap = local_user.iap; }
        if (local_user.hasOwnProperty('clients') && local_user.clients.length) { user.clients = local_user.clients; }

        if (get_remote) {
            user = await getProfileFromRemote();
        } else {
            getProfileFromRemote().then(() => {}).catch((e) => { console.error(e); });
        }

        console.log("user", user);

        return user;
    } catch (e) {
        console.error(e);
        return Promise.reject(e);
    }
};

const getProfileFromRemote = async () => {
    try {
        const user = await getRemoteProfile();
        const user_id = user.id;

        const userInfo = getUserInfo(user_id);
        userInfo.clients =             user.clients || [];
        userInfo.iap =                 user.iap || {};
        userInfo.api_calls =           user.api_calls || 0;
        userInfo.picture =             user.picture || "";
        userInfo.gender =              user.gender || "";
        userInfo.age =                 user.age || "";
        userInfo.system_of_measures =  user.system_of_measures || "metric";
        userInfo.show_welcome_dialog = !!user.show_welcome_dialog;
        userInfo.firstname =           user.firstname || userInfo.firstname;
        userInfo.lastname =            user.lastname || userInfo.lastname;
        userInfo.guest =               user.guest || userInfo.guest;

        setUserInfo(user_id, userInfo);

        return user;
    } catch (e) {
        return Promise.reject(e);
    }
};

const postProfile = async (user: UserItem) => {
    return responseWrapper(postRemoteProfile(user), "Updating User Profile failed");
};

const postUserStatus = async (client_id: string | null) => {
    return responseWrapper(postRemoteUserStatus(client_id), "Sending User Status failed");
};

// [IM] Not used since Spring 2022, should we wait a bit and remove an API route?
// noinspection JSUnusedLocalSymbols
const getUserClientById = async (clientId: string, params: GetUserClientByIdParamsType) => { // eslint-disable-line
    return responseWrapper(getRemoteUserClientById(clientId, params), "Getting User Client failed");
};

const postUserClientById = async (client: ClientItem, isLogin?: boolean) => {
    return responseWrapper(postRemoteUserClientById(client, isLogin), "Updating User Client failed");
};

const deleteUserClientById = async (client_id: string) => {
    return responseWrapper(deleteRemoteUserClientById(client_id), "Removing User Client failed");
};

const getUsers = async (params?: GetUsersParamsType) => {
    try {
        if (!params) {
            return await getLocalUsers();
        }

        const users = await getRemoteUsers(params);
        await postUsers(users);
        return users;
    } catch (e) {
        console.error(e);
        return Promise.reject(e);
    }
};

// APPLE DEVICE TOKEN
// [IM] Move this section to DeviceService?
let deviceToken: string | undefined = undefined;
let devicePlatform: string | undefined = undefined;

const postUserDeviceToken = async (device_token: string, platform: string) => {
    setAppleDeviceTokenId(device_token);
    deviceToken = device_token;
    devicePlatform = platform;

    return responseWrapper(postRemoteUserDeviceToken(device_token, platform));
}

const getUserDeviceToken = () => {
    return deviceToken || getAppleDeviceTokenId();
};

const getPlatform = () => {
    return devicePlatform;
};

const deleteUserDeviceToken = async () => {
    const userDeviceToken = getUserDeviceToken();

    if (!userDeviceToken) {
        console.log("Skipped removing apple device token");
        return;
    }

    try {
        await deleteRemoteUserDeviceToken(userDeviceToken as string, devicePlatform as string);
        deviceToken = undefined;
        devicePlatform = undefined;
        removeAppleDeviceTokenId();
    } catch (e) {
        console.error(e);
        return Promise.reject(e);
    }
}
// APPLE DEVICE TOKEN

const getUserInfo = (id: string) => {
    let userInfo = getUserInfoLS(id);

    if (!userInfo) {
        userInfo = { clients: [] };
        setUserInfo(id, userInfo);
    }

    return userInfo;
};

const setUserInfo = (id: string, value: UserInfo) => {
    if (!id) {
        return;
    }

    setUserInfoLS(id, value);
}

const getUserInfoByKey = (id: string, key: USER_INFO_KEY_LS) => {
    let userInfo = getUserInfo(id);
    return userInfo[key];
};

const setUserInfoByKey = (id: string, key: USER_INFO_KEY_LS, value: string | { [key: string]: string }) => {
    let userInfo = getUserInfo(id);
    userInfo[key] = value;
    setUserInfo(id, userInfo);
};

// DEVICE INFO
// [IM] Move this section to DeviceService?
const getDeviceInfo = () => {
    return getDeviceInfoLS() || {};
};

const userAgentParser = new UAParser();

const getOsName = () => {
    const deviceInfo = getDeviceInfo();
    return deviceInfo?.systemName ? deviceInfo.systemName : userAgentParser.getOS().name;
};

const getDeviceName = () => {
    const deviceInfo = getDeviceInfo();
    return deviceInfo?.name ? deviceInfo.name : "";
};

const getDeviceOsVersion = () => {
    const deviceInfo = getDeviceInfo();
    return deviceInfo?.systemVersion ? deviceInfo.systemVersion : userAgentParser.getOS().version;
};

const getDeviceHostType = () => {
    const deviceInfo = getDeviceInfo();
    return deviceInfo?.hostType ? deviceInfo.hostType : "";
};
// DEVICE INFO

const getUserIAPData = () => {
    const userId = getUserId();
    return getUserInfoByKey(userId, USER_INFO_KEY_LS.IAP);
};

const setUserIAPData = (value: { [key: string]: string }) => {
    const userId = getUserId();
    return setUserInfoByKey(userId, USER_INFO_KEY_LS.IAP, value);
};

const getClientId = () => {
    const userId = getUserId();
    let clientId = getUserInfoByKey(userId, USER_INFO_KEY_LS.CLIENT_ID);

    if (!clientId) {
        clientId = v4();
        setUserInfoByKey(userId, USER_INFO_KEY_LS.CLIENT_ID, clientId);
    }

    return clientId;
};

// [IM] Move this method to DeviceService?
const getDeviceId = () => {
    let deviceId = getDeviceIdLS();

    if (!deviceId) {
        deviceId = v4();
        setDeviceIdLS(deviceId);
    }

    return deviceId;
};

// [IM] Move this method to DeviceService?
const setDeviceId = (id?: string) => {
    let deviceId = getDeviceIdLS();

    if (deviceId && !id) {
        console.log("Device Id Exist:", deviceId);
        return;
    }

    deviceId = id || v4();

    console.log("New Device Id:", deviceId);

    setDeviceIdLS(deviceId);
};

const setUserCryptoInfo = (userId: string, crypto: { encryption?: JsonWebKeyPair, signing?: JsonWebKeyPair }) => {
    if (!userId) {
        return;
    }

    const userInfo = getUserInfo(userId);

    if (crypto.hasOwnProperty("encryption")) {
        userInfo.encryption = crypto.encryption;
    }

    if (crypto.hasOwnProperty("signing")) {
        userInfo.signing = crypto.signing;
    }

    setUserInfo(userId, userInfo);
};

const getUserCryptoInfo = (userId: string): ExtendedUserCrypto | null => {
    const userInfo = getUserInfo(userId);

    if (!userInfo.hasOwnProperty("encryption") || !userInfo.hasOwnProperty("signing")) {
        return null;
    }

    return {
        encryption: userInfo.encryption,
        signing: userInfo.signing
    } as ExtendedUserCrypto;
};

// [IM] Move this method to DeviceService?
const getClientInfo = (clientId: string | null) => {
    return {
        client_id: clientId,
        device_id: getDeviceId(),
        device_token: getUserDeviceToken() ?? null,
        name: getDeviceName(),
        os: getOsName(),
        os_version: getDeviceOsVersion(),
        browser_name: userAgentParser.getBrowser().name,
        browser_version: userAgentParser.getBrowser().version,
    };
};

const getUserById = async (userId: string) => {
    return responseWrapper(getLocalUserById(userId), "Getting User failed");
};

const postUsers = async (users: postUserParamsType[]) => {
    try {
        if (!Array.isArray(users)) {
            return;
        }

        await Promise.all(users.map(async (user: postUserParamsType) => {
            try {
                const userData: UserDbItem = {
                    email: user.email,
                    firstname: user.firstname,
                    lastname: user.lastname,
                    user_id: user.id || user.user_id
                };

                if (user.encryption?.public_key) {
                    const publicJWK = await exportPublicJWKFromSPKI(user.encryption.public_key);
                    const key_id = user.encryption.key_id || md5(user.encryption.public_key);
                    userData.encryption = {
                        key_id: key_id,
                        publicJWK: publicJWK
                    }
                }

                await postLocalUser(userData);
            } catch (e) {
                console.error(e);
            }

            return;
        }));
    } catch (e) {
        console.error(e);
        return Promise.reject(e);
    }
};

const isGuest = (): boolean => {
    const guest = getGuestUser();
    const userId = getUserId();
    return !!(guest?.id && userId && userId === guest?.id);
};

export {
    // UserStorageService
    getUserId,
    getDeviceInfo,
    // getGuestUser,
    getDeviceId,
    getUserInfo,
    setUserId,
    setDeviceInfo,
    setGuestUser,
    setDeviceId,
    setUserInfo,
    removeUserId,

    // UserRemoteService
    getProfile,
    postProfile,
    postUserStatus,
    // getUserClientById,
    postUserClientById,
    deleteUserClientById,
    getUsers,
    subscribeIAPUserStatus,
    unsubscribeIAPUserStatus,
    subscribeClientLastActive,
    unsubscribeClientLastActive,

    // UserLocalService
    getUserById,
    postUsers,

    // DeviceToken
    getPlatform,
    getUserDeviceToken,
    postUserDeviceToken,
    deleteUserDeviceToken,

    // DeviceInfo
    getDeviceName,
    getDeviceHostType,

    // UserInfoKey
    getClientId,
    setUserIAPData,
    getUserIAPData,
    setUserCryptoInfo,
    getUserCryptoInfo,

    // Other
    getClientInfo,
    isGuest,
};
