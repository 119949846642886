import React, {useCallback} from "react";
import {MarkerProps} from "./MarkerTypes";
import MarkerIcon from "./MarkerIcon/MarkerIcon";
import {makeStyles} from "@material-ui/core/styles";
import {Grid} from "@material-ui/core";
import {markerIconNameRatio} from "./MarkerUtils";

const useStyles = makeStyles({
    marker: {
        color: "#fff",
        msUserSelect: "none",
        userSelect: "none",
        width: "max-content",
        position: "absolute",
        // transform: `translate(${props.centerPointX - props.iconSize / 2}px, ${props.centerPointY - props.iconSize / 2}px)`,
        zIndex: 500,
    },
    markerSelected: {
        // transform: `translate(${props.centerPointX - props.iconSize / 2 - 3}px, ${props.centerPointY - props.iconSize / 2 - 3}px)`,
        zIndex: 501,
    },
    markerName: (props: MarkerProps) => ({
        fontSize: props.fontSize,
        color: "#fff",
        position: "absolute",
        top: `${props.iconSize * (markerIconNameRatio[props.visibilityType]?.default || 1)}px`,
        filter: "drop-shadow(1px 0px 1px #000) drop-shadow(-1px 0px 1px #000) drop-shadow(0px -1px 1px #000) drop-shadow(0px 1px 1px #000)",
        boxShadow: "none",
        msUserSelect: "none",
        userSelect: "none",
        background: "transparent",
        display: "flex",
        flexDirection: "column",
        maxWidth: 250,
        maxHeight: "max-content",
        margin: "0px auto",
        cursor: "pointer"
    }),
    markerNameSelected: (props: MarkerProps) => ({
        top: `${props.iconSize * (markerIconNameRatio[props.visibilityType]?.selected || 1)}px`,
        border: "1px #fff solid",
        borderRadius: "2px",
        fontWeight: "bold",
        background: "rgba(0, 0, 0, .1)",
    })
});

const Marker = (props: MarkerProps) => {

    const {
        object_id,
        object_name,
        selected,
        showData,
        centerPointX,
        centerPointY,
        iconSize,
        handleClickOnMarker
    } = props;

    // styles
    const classes = useStyles(props);

    // [IM] Workaround to exclude marker 'floating'
    const transformStyle = selected ?
        ({ transform: `translate(${centerPointX - iconSize / 2 - 3}px, ${centerPointY - iconSize / 2 - 3}px)` }) :
        ({ transform: `translate(${centerPointX - iconSize / 2}px, ${centerPointY - iconSize / 2}px)` });

    const markerStyle = selected ? [classes.marker, classes.markerSelected].join(' ') : classes.marker;
    const markerNameStyle = selected ? [classes.markerName, classes.markerNameSelected].join(' ') : classes.markerName;

    const handleClick = useCallback(() => handleClickOnMarker ? handleClickOnMarker(object_id) : null, [handleClickOnMarker, object_id]);

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={markerStyle}
            style={transformStyle}
            onClick={handleClick}
        >
            <Grid item>
                <MarkerIcon {...props}/>
            </Grid>

            {showData && <Grid
                className={markerNameStyle}
                onClick={handleClick}
            >
                {object_name}
            </Grid>}
        </Grid>
    )
};

const areMarkersEqual = (prevProps: MarkerProps, nextProps: MarkerProps) => {
    return prevProps.centerPointX === nextProps.centerPointX &&
        prevProps.centerPointY === nextProps.centerPointY &&
        prevProps.selected === nextProps.selected &&
        prevProps.iconSize === nextProps.iconSize &&
        prevProps.showData === nextProps.showData &&
        prevProps.object_icon === nextProps.object_icon &&
        prevProps.object === nextProps.object;
};

export default React.memo(Marker, areMarkersEqual);
