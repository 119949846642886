import {MapControlProps, MapProps, withLeaflet} from "react-leaflet";
import {InteractiveLayerOptions, MapOptions, PathOptions} from "leaflet";
import {Component} from "react";

import Locate from "leaflet.locatecontrol";

export type LocateControlProps = MapControlProps &
    {
        options?: {
            position?: string,
            onActivate?(): void,
            layer?: InteractiveLayerOptions,
            setView?: boolean | string,
            flyTo?: boolean,
            keepCurrentZoomLevel?: boolean,
            initialZoomLevel?: false | number,
            clickBehavior?: {
                inView?: string,
                inViewNotFollowing?: string,
                outOfView?: string
            },
            returnToPrevBounds?: boolean,
            cacheLocation?: boolean,
            showCompass?: boolean,
            drawCircle?: boolean,
            drawMarker?: boolean,
            markerClass?: string,
            compassClass?: string,
            circleStyle?: PathOptions,
            markerStyle?: PathOptions,
            compassStyle?: PathOptions,
            followCircleStyle?: PathOptions,
            followCompassStyle?: PathOptions,
            icon?: string,
            iconElementTag?: string,
            circlePadding?: number[],
            createButtonCallback?(): void,
            getLocationBounds?(): void,
            onLocationError?(): void,
            onLocationOutsideMapBounds?(): void,
            showPopup?: boolean,
            strings?: {
                title?: string,
                meterUnit?: string,
                feetUInit?: string,
                popup?: string | void,
                outsideMapBoundsMsg?: string
            },
            locateOptions?: MapOptions
        };
        startDirectly?: boolean;
        leaflet:{map: MapProps};
};

class LocateControl  extends Component<LocateControlProps> {
    componentDidMount() {
        const { options, startDirectly } = this.props;
        const { map } = this.props.leaflet;

        // @ts-ignore
        const lc = new Locate(options);
        lc.addTo(map);

        if (startDirectly) {
            // request location update and set location
            lc.start();
        }
    }

    componentWillUnmount() {}

    render() {
        return null;
    }
}

export default withLeaflet(LocateControl);