import React from "react";
import {SlideType} from "../WelcomeDialogContent";
import {Grid, Typography} from "@material-ui/core";

export const Slide12 = (props: SlideType) => {
    return (
        <>
            <Grid
                container
                spacing={3}
                direction="row"
                justifyContent="center"
                alignItems="center">

                <Grid item xs={12} sm>
                    <Typography
                        variant={props.xsSize ? "body1" : "h6"}
                        align="left"
                        gutterBottom={true}>
                        Please contact our support team.
                    </Typography>
                    <Typography
                        variant={props.xsSize ? "body1" : "h6"}
                        align="left"
                        gutterBottom={true}>
                        We are always happy to help!
                    </Typography>
                </Grid>

            </Grid>
        </>
    )
}
