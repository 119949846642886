import {v4} from "uuid";
import {ObjectItem} from "../../Types";
import {
    getObjectById,
    getObjectByNativeIdentificationDataAndObjectType,
    getObjects,
    postObject,
} from "../../../ObjectsService";
import {getDeviceId} from "../../../UserService";
import {AccessLevel, OriginType} from "../../Constants";
import {isUserHasAccess} from "../../../Utils";

let storedToNativeCache: { [key: string]: string } = {};
let nativeToStoredCache: { [key: string]: string } = {};

export async function nativeObjectIdentification(nativeObject:any) {

    if (!nativeObject.native_id) {
        nativeObject.native_id = {};
    }
    nativeObject.native_id[getDeviceId()] = nativeObject.object_id;

    if (nativeObject.native_identification_data && nativeObject.object_type) {

        try {
            const localObject = await findLocalObjectByNativeData(nativeObject.native_identification_data, nativeObject.object_type);
            //console.log("nativeObjectIdentification find with some native_identification_data", localObject);
            nativeObject.object_id = localObject.object_id;

            return nativeObject;

        } catch (err) {}
    } else {
        try{
            nativeObject.object_id = await convertNativeIdToStoredId(nativeObject.object_id);
            return nativeObject;
        } catch (err) {}
    }

    nativeObject.object_id = v4();

    // I think no need to call post there
    // const res: any = await postObject(nativeObject);
    // nativeObject.object_id = res.object_id;
    return nativeObject;
}

async function findLocalObjectByNativeData(native_identification_data:any, object_type:string) {
    try {
        const matchedObjects = await getObjectByNativeIdentificationDataAndObjectType(native_identification_data, object_type);

        if (matchedObjects?.length) {
            return matchedObjects[0];
        }
    } catch (err) {}
    try {
        const all_stored_objects = (await getObjects({source:"apple_home"}) as ObjectItem[]).filter((object) => {
            return isUserHasAccess(object, AccessLevel.OWNER);
        });

        let minMatchedPercent = 0.5;
        let matchedObject:any = null;

        for (let storedObject of all_stored_objects) {

            if (object_type === storedObject.object_type && storedObject.native_identification_data?.length) {

                const matchedSerialNumbers = storedObject.native_identification_data.filter(element =>  {
                    return native_identification_data.includes(element)
                });

                if (!matchedSerialNumbers?.length) {
                    continue;
                }

                const matchedPercent = matchedSerialNumbers.length / Math.min(storedObject.native_identification_data.length, native_identification_data.length);

                if (matchedPercent > minMatchedPercent) {

                    minMatchedPercent = matchedPercent;
                    matchedObject = storedObject;
                }
            }
        }
        if (matchedObject) {
            return matchedObject;
        } else {
            return Promise.reject("object not found");
        }
    } catch (err) {
        console.log("findLocalObjectByNativeData err:", err);
        return Promise.reject(err);
    }
}

export async function convertNativeIdToStoredId(nativeId:string, getFullObject?:boolean, nativeObject?:any) {
    try {
        if (nativeToStoredCache[nativeId]) {
            if (!getFullObject && (!nativeObject || !nativeObject.object_type)) {
                // console.log("<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< Get Stored from Cache " + nativeToStoredCache[nativeId] + " for " + nativeId);
                return nativeToStoredCache[nativeId];
            }
        } else if (storedToNativeCache[nativeId]) { // [IM] handle case when nativeId it is stored id
            return nativeId;
        }

        const clientId = getDeviceId();
        const all_stored_objects = (await getObjects({source:"apple_home"}) as ObjectItem[]).filter((object) => {
            return isUserHasAccess(object, AccessLevel.OWNER);
        });
        //console.log(all_stored_objects)
        for (let storedObject of all_stored_objects) {
            if (storedObject.native_id && storedObject.native_id[clientId] === nativeId) {
                //console.log(`convertNativeId: ${nativeId} ToStoredId: ${storedObject.object_id}`);
                addToCache(nativeId, storedObject.object_id);
                return getFullObject ? storedObject : storedObject.object_id;
            } else if (storedObject.object_id === nativeId) {    // [IM] handle case when nativeId it is stored id, but missed in cache
                addToCache(nativeId, storedObject.object_id);
                return getFullObject ? storedObject : storedObject.object_id;
            }
        }
        try {       // [IM] Does it try-catch section make sense at all? If so, uncomment 2x 'else if' above
            // if native id was not found, checking if nativeId == storedId
            const objectById = await getObjectById(nativeId, { includeDeleted: false, includePublic: false, skipChildrenTransform: true });
            if (objectById) {
                return getFullObject ? objectById : objectById.object_id;
            }
        } catch (err) {}
        if (nativeObject && nativeObject.object_type) {
            // create new object in DB
            const nativeId = nativeObject.object_id;
            delete nativeObject.object_id;
            delete nativeObject.children;
            nativeObject.native_id = {};
            nativeObject.native_id[clientId] = nativeId;
            console.log("create new native object:",nativeObject);
            const res: any = await postObject(nativeObject, {origin: OriginType.NATIVE});
            addToCache(nativeId, res.object_id);
            return getFullObject ? res : res.object_id;
        }
        // console.log(`convertNativeId: ${nativeId} ToStoredId: ${nativeId}`);
        // TODO: return native id or reject ???
        return Promise.reject(`Stored Id not found for native id ${nativeId}`);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
}

export async function convertStoredIdToNativeId(storedId: string, storedObject?: ObjectItem) {
    try {
        // console.log("convertStoredIdToNativeId:", storedId);

        if (storedToNativeCache[storedId]) {
            // console.log("<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< Get Native from Cache " + storedToNativeCache[storedId] + " for " + storedId);
            return storedToNativeCache[storedId];
        }

        const clientId = getDeviceId();
        const _storedObject: ObjectItem = storedObject || await getObjectById(storedId, { includeDeleted: false, includePublic: false, skipChildrenTransform: true });

        if (_storedObject.native_id && _storedObject.native_id[clientId]) {
            addToCache(_storedObject.native_id[clientId], storedId);
            return _storedObject.native_id[clientId];
        } else {
            return storedId;
        }
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
}

const addToCache = (nativeId: string, storedId: string) => {
    // console.log("<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< Add to Cache " + storedId + " for " + nativeId);

    nativeToStoredCache[nativeId] = storedId;
    storedToNativeCache[storedId] = nativeId;
};

export const updateCache = (oldStoredId: string, newStoredId: string) => {
    // console.log("<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< Update in Cache " + oldStoredId + " to " + newStoredId + " for " + storedToNativeCache[oldStoredId]);

    if (storedToNativeCache[oldStoredId]) {
        const _nativeId = storedToNativeCache[oldStoredId];
        delete nativeToStoredCache[_nativeId];
        delete storedToNativeCache[oldStoredId];

        storedToNativeCache[newStoredId] = _nativeId;
        nativeToStoredCache[_nativeId] = newStoredId;
    }
};

export const clearIdentificationCache = () => {
    storedToNativeCache = {};
    nativeToStoredCache = {};
};
