import {Theme, withStyles} from "@material-ui/core/styles";
import MuiDialogActions from "@material-ui/core/DialogActions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {Button, Grid} from "@material-ui/core";
import React from "react";
import {ChevronLeft, ChevronRight} from "@material-ui/icons";


const DialogActions = withStyles((theme: Theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
}))(MuiDialogActions);

type WelcomeDialogActionsProps = {
    slidePage: number;
    numberOfSlides: number;
    dontShowDialog: boolean;
    handleCheckboxChange(event: React.ChangeEvent<HTMLInputElement>): void;
    handlePageChange(event: React.ChangeEvent<unknown>, value: number): void;
    handleCloseDialog(): void
}

const WelcomeDialogActions = (props: WelcomeDialogActionsProps) => {
    const {
        slidePage,
        numberOfSlides,
        dontShowDialog,
        handleCheckboxChange,
        handlePageChange,
        handleCloseDialog
    } = props;

    const handlerNextOnClick = (e: any) => {
        // Hide slide with Sharing info. Temp solution, indexes for Slide4 and Slide5 will be skipped in Actions Component [AS]
        if ([3,4,5].includes(slidePage)) {
            handlePageChange(e, 6);
        } else {
            handlePageChange(e, slidePage + 1);
        }
    };

    const handlerPrevOnClick = (e: any) => {
        // Hide slide with Sharing info. Temp solution, indexes for Slide4 and Slide5 will be skipped in Actions Component [AS]
        if ([4,5,6].includes(slidePage)) {
            handlePageChange(e, 3);
        } else {
            handlePageChange(e, slidePage - 1);
        }
    };


    return (
        <DialogActions>

            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Grid item sm={6} xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox checked={dontShowDialog} color="primary" onChange={handleCheckboxChange}/>
                        }
                        label="Don't show again"
                    />
                </Grid>
                <Grid
                    item
                    spacing={1}
                    sm={6}
                    xs={12}
                    container
                    direction="row"
                    justifyContent="flex-end"
                >
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<ChevronLeft/>}
                            disabled={slidePage === 1}
                            onClick={handlerPrevOnClick}>
                            Prev
                        </Button>
                    </Grid>
                    <Grid item>
                        {slidePage === numberOfSlides ?
                            (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleCloseDialog}>
                                    Close
                                </Button>
                            ) : (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    endIcon={<ChevronRight/>}
                                    onClick={handlerNextOnClick}>
                                    Next
                                </Button>
                            )
                        }
                    </Grid>
                </Grid>
            </Grid>
        </DialogActions>
    )
}

export default WelcomeDialogActions;
