import { pubSystemEvent } from "./Subscription";
import { EVENT_TYPE } from "./Constants"

export const pubUndoEvent =    () => { pubSystemEvent(EVENT_TYPE.TOOLBAR_UNDO); };
export const pubRedoEvent =    () => { pubSystemEvent(EVENT_TYPE.TOOLBAR_REDO); };
export const pubRemoveEvent =  () => { pubSystemEvent(EVENT_TYPE.TOOLBAR_REMOVE); };
export const pubSubmitEvent =  () => { pubSystemEvent(EVENT_TYPE.TOOLBAR_SUBMIT); };
export const pubZoomInEvent =  () => { pubSystemEvent(EVENT_TYPE.TOOLBAR_ZOOM_IN); };
export const pubZoomOutEvent = () => { pubSystemEvent(EVENT_TYPE.TOOLBAR_ZOOM_OUT); };
export const pubShowDocumentStructureEvent = () => { pubSystemEvent(EVENT_TYPE.TOOLBAR_SHOW_DOCUMENT_STRUCTURE); };

export const pubSetOrganization = () => { pubSystemEvent(EVENT_TYPE.CONTEXT_ORGANIZATION) };

export const pubSetUnsavedChangesToObject = () => { pubSystemEvent(EVENT_TYPE.UNSAVED_CHANGES_TO_OBJECT) };

export const pubKeyDownCmdSEvent = () => { pubSystemEvent(EVENT_TYPE.KEY_DOWN_CMD_S) };

export const pubPublicObjectsFilterEvent = (filterState: boolean) => {
    pubSystemEvent(EVENT_TYPE.FILTER_PUBLIC_OBJECTS, { boolState: filterState });
};

export const pubOwnObjectsFilterEvent = (filterState: boolean) => {
    pubSystemEvent(EVENT_TYPE.FILTER_OWN_OBJECTS, { boolState: filterState });
};

export const pubDisableUIEvent = (disableState: boolean) => {
    pubSystemEvent(EVENT_TYPE.DISABLE_UI, { boolState: disableState });
};
