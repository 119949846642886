import React, {useEffect, useState} from "react";
import {LatLngTuple} from "leaflet";
import {getMaxPoint, getMinPoint, /*transformImage,*/ _transformImage} from "../../../Services/ImageTransformService";
import {
    getObjectById,
    ImagePolygonItem,
    imagePropertyKey,
    ObjectItem,
    PointTuple,
    PropertyItem
} from "../../../Services/ObjectsService";
import {ImageOverlay} from "react-leaflet";
import {UserInfoType} from "../../../Hooks/useUserProfile";
import {useSubPropertyValue} from "../../../Hooks/Subscriptions/useSubPropertyValue";

type MapImageOverlayProps = {
    bounds: LatLngTuple[];
    object?: ObjectItem;
    userInfo: UserInfoType;
    handleImageExists?(state: boolean): void;
    pixelPoints?: LatLngTuple[];
}

const MapImageOverlay = (props: MapImageOverlayProps) => {
    const {bounds} = props;

    const [image, setImage] = useState<string>("");
    const [selectedObjectId, setSelectedObjectId] = useState<string>();
    const [transformedImage, setTransformedImage] = useState<string>("");
    const mounted = React.useRef(false);

    useEffect(() => {
        mounted.current = true; // Will set it to true on mount ...
        return () => { mounted.current = false; }; // ... and to false on unmount
    }, []);

    const getMinMaxPoints = () => {
        let newMinMaxPoints: LatLngTuple[] = [];

        if (Array.isArray(bounds) && bounds.length !== 0) {
            newMinMaxPoints.push(getMinPoint(bounds));
            newMinMaxPoints.push(getMaxPoint(bounds));
        }

        return newMinMaxPoints;
    };

    const minMaxPoints = getMinMaxPoints();

    const updateTransformedImage = (img: HTMLCanvasElement | null) => {
        if (!mounted.current) {
            return;
        }

        const url = img ? img.toDataURL() : "";
        setTransformedImage(url);
    };

    useEffect(() => {
        if (props.handleImageExists) {
            if (transformedImage.length) {
                props.handleImageExists(true)
            } else {
                props.handleImageExists(false)
            }
        }
    }, [transformedImage])

    useEffect(() => {
        if (!props.object || !props.object.properties) {
            return;
        }

        let imagePolygon = props.object.properties["image_polygon"] || null;
        let mapPolygon = props.object.properties["map_polygon"] || null;

        if (!imagePolygon || !mapPolygon) {
            return;
        }
        const imagePolygonValue = (imagePolygon as PropertyItem).value as ImagePolygonItem;
        // const mapPolygonValue = ((mapPolygon as PropertyItem).value as LatLngTuple[]) || [];
        const mapPolygonValue = (bounds as LatLngTuple[]) || [];
        const changedDirection = mapPolygonValue.map((point: PointTuple) => {
            let _point = JSON.parse(JSON.stringify(point));
            _point[0] = -1 * _point[0];
            return _point;
        });
        if (imagePolygonValue?.object_id && mapPolygonValue) {
            setSelectedObjectId(imagePolygonValue.object_id);
            // transformImage(image, imagePolygonValue.points, changedDirection, true, updateTransformedImage);
            _transformImage(image, imagePolygonValue.points, changedDirection, true, updateTransformedImage, props.pixelPoints);
        } else {
            setTransformedImage("");
            setSelectedObjectId("");
        }
    }, [props.object?.properties["image_polygon"].value, props.object?.properties["map_polygon"].value, image, props.pixelPoints, bounds]);

    useEffect(() => {
        if (!selectedObjectId) {
            setImage("");
            return;
        }

        let active = true;

        (async () => {
            try {
                const response = await getObjectById(selectedObjectId);
                if (!active) {
                    return
                }
                setImage(response?.properties?.image?.value || "");
            } catch (e) {
                console.error(e);
            }
        })();

        return () => {
            active = false;
        };
    }, [selectedObjectId]);

    useSubPropertyValue(selectedObjectId, imagePropertyKey, (data) => {
        if (data?.value) {
            setImage(data.value as string);
        }
    });

    if (!Array.isArray(minMaxPoints) || minMaxPoints.length === 0) {
        return null;
    }

    return (
        <React.Fragment>
            {transformedImage && <ImageOverlay url={transformedImage} bounds={minMaxPoints}/>}
        </React.Fragment>
    );
};

// export default React.memo(MapImageOverlay)
export default MapImageOverlay
