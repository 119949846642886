import React, { useState, useEffect } from 'react'
import { Console, Hook, Unhook } from 'console-feed'
import {createStyles, Drawer, Fab, Icon} from "@material-ui/core";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {grey} from "@material-ui/core/colors";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            backgroundColor: grey[800]
        },
        paperAnchorBottom: {
            top: 'auto',
            left: 0,
            bottom: 0,
            right: 0,
            height: 'auto',
            maxHeight: '50%',
        },
        fab: {
            margin: 15,
            position: "fixed",
            bottom: 15,
            right: 0,
            zIndex: theme.zIndex.drawer + 1,
        }
    }),
);

const LogsContainer = () => {
    const classes = useStyles();

    const [logs, setLogs] = useState<any[]>([])
    const [open, setOpen] = React.useState<boolean>(false);

    const toggleDrawer = () => (event: React.KeyboardEvent | React.MouseEvent,) => {
        if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
            return;
        }

        setOpen((prev) => {
            return !prev;
        });
    };

    // run once!
    useEffect(() => {
        Hook(
            (window as any).console,
            (log) => setLogs((currLogs) => {
                return [{...log, data: [new Date().toISOString(), ...(log.data || []) ]}, ...currLogs.slice(0, 2000)];
            }),
            false
        )
        return () => {
            Unhook((window as any).console)
        }
    }, []);

    return (<React.Fragment>
        <Fab
            color="secondary"
            size="small"
            aria-label="open properties"
            component="span"
            className={classes.fab}
            onClick={() => setOpen((prev) => {
                return !prev;
            })}
            children={<Icon className="fas fa-terminal" style={{ fontSize: 15 }}/>}
        />
        <Drawer classes={{paperAnchorBottom: classes.paperAnchorBottom, paper: classes.content}} anchor="bottom" open={open} onClose={toggleDrawer()}>
            <Console logs={logs} variant="dark" />
        </Drawer>
    </React.Fragment>);
}

export default LogsContainer;