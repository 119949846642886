import React from "react";
import { ListItem, ListItemText, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// Components
import type { MessageItemType } from "Components/Objects/Conversation/ObjectConversation";

const useStyles = makeStyles({
    dateMessage: {
        textAlign: 'center'
    },
    datelabel: {
        fontSize: '0.9rem',
        fontWeight: 'bold'
    }
});

type DateLabelProps = {
    chatMessage: MessageItemType;
};

const DateLabel = (props: DateLabelProps) => {

    const { chatMessage } = props;

    const classes = useStyles();

    return (
        <ListItem className={classes.dateMessage}>
            <ListItemText
                primary={
                    <Typography className={classes.datelabel}>
                        {chatMessage.title}
                    </Typography>
                }
            />
        </ListItem>
    );

}

export default DateLabel;
