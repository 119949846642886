import React from "react";
import {Button, Dialog, Typography, useMediaQuery, useTheme} from "@material-ui/core";
import DialogTitle from "../DialogUtils/DialogTitle";
import {useDialogStyles} from "../DialogStyles";
import {Theme, withStyles} from "@material-ui/core/styles";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import {Link} from "react-router-dom";
import {
    useGlobalDialogContentContext,
    useGlobalDialogContext,
    useUpdateGlobalDialogActionsContext
} from "../../../GlobalContext/GlobalContext";

const PRO_DIALOG_CONTENTS: { [key: string]: string } = {
    property_history_pro: "Please get the Pro plan in order to change the history graph date range. Upgrade now and get the 3 months of Pro plan for free.",
    floor_plan_new_element_pro: "Please get the Pro plan in order to add more elements to the floor plan. Free plan allows you to have up to 50 floor plan elements per the account. The total number of floor plan elements used by your account is greater than 50. Upgrade now and get the 3 months of Pro plan for free.",
    camera_resolution_pro: "Please get the Pro plan to be able alter the camera resolution. Free plan only supports 480p. Upgrade now and get the 3 months of Pro plan for free.",
    number_of_cameras_pro: "You have exceeded the allowed number of simultaneously streaming IP cameras per 1 client device for Free plan. Free plan supports  1 IP camera per client. Please upgrade to Pro to stream from more cameras.  Upgrade now and get the 3 months of Pro plan for free. "
}


const DialogActions = withStyles((theme: Theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const UpgradeToProDialog = () => {

    const classes = useDialogStyles();
    const theme = useTheme();
    const downXs = useMediaQuery(theme.breakpoints.down("xs"));


    const globalDialog = useGlobalDialogContext()
    const setGlobalDialog = useUpdateGlobalDialogActionsContext()

    const globalDialogContent = useGlobalDialogContentContext()

    const handleCloseDialog = () => {
        setGlobalDialog(undefined)
    };

    const getProDialogContent = (message_type: string) => {
        if(Object.keys(PRO_DIALOG_CONTENTS).includes(message_type)){
            return PRO_DIALOG_CONTENTS[message_type]
        }
        return "This functionality available in Professional edition." +
            " Upgrade today to access all the premium features and modules."
    }

    return (
        <Dialog
            fullWidth
            open={globalDialog === "UpgradeToProDialog"}
            classes={{paper: downXs ? classes.dialogPaperXs : classes.dialogPaper}}
        >
            <DialogTitle
                titleText={"Upgrade to Pro"}
                handleCloseDialog={handleCloseDialog}
            />
            <DialogContent dividers>
                <Typography variant="body1" gutterBottom>
                    {getProDialogContent(globalDialogContent)}
                </Typography>
            </DialogContent>
            <DialogActions
                style={{justifyContent: 'center'}}
            >
                <Button
                    component={Link}
                    to="/purchases"
                    variant="contained"
                    color="primary"
                    onClick={handleCloseDialog}
                >
                    Upgrade now
                </Button>
            </DialogActions>

        </Dialog>)

}

export default UpgradeToProDialog;
