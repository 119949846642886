import React, {Fragment, ReactNode, useEffect, useMemo, useState} from "react";
import Typography from "@material-ui/core/Typography";
import type {ServiceLifeType} from "../../Services/ObjectsService";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {differenceInDays, format, isValid, parse} from "date-fns";
import TextField from "@material-ui/core/TextField";
import {
    Divider,
    Fab,
    FormControlLabel,
    makeStyles,
    Menu,
    MenuItem,
    Switch
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import AutoRenewIcon from '@material-ui/icons/Autorenew';
import {createStyles, Theme} from "@material-ui/core/styles";
import {
    useUpdateGlobalDialogActionsContext,
    useUpdateGlobalDialogContentActionsContext
} from "../../GlobalContext/GlobalContext";
import ProgressComponent from "../Common/ProgressComponent/ProgressComponent";

type ServiceLifeControlProps = {
    id: string;
    label: string;
    value: ServiceLifeType;
    saveValue(key: string, value: string | number | boolean | ServiceLifeType): void;
    propertyKey: string;
    settings?: ReactNode;
    variant?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        autoRenewIcon: {
            position: "absolute",
            bottom: theme.spacing(1),
            right: theme.spacing(0.5),
        }
    }),
);

const ServiceLifeControl = (props: ServiceLifeControlProps) => {
    const classes = useStyles();
    const { id, label, value, saveValue, propertyKey, settings, variant } = props;

    const getStartDate = () => {
        return value.start_time ? parse(value.start_time, 'MM/dd/yyyy', new Date()) : new Date();
    };

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(getStartDate());
    const [numberLifeTime, setNumberLifeTime] = useState(value.life_time);
    const [numberNotificationTime, setNumberNotificationTime] = useState(value.notification_time);
    const [showPercentageChecked, setShowPercentageChecked] = useState(value.show_percentage);

    const setGlobalDialog = useUpdateGlobalDialogActionsContext();
    const setGlobalDialogContent = useUpdateGlobalDialogContentActionsContext();

    useEffect(() => {
        setSelectedStartDate(getStartDate());
        setNumberLifeTime(value.life_time);
        setNumberNotificationTime(value.notification_time);
        setShowPercentageChecked(value.show_percentage);
    }, [value?.start_time, value?.life_time, value?.notification_time, value?.show_percentage]);

    const handleClose = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setAnchorEl(null);
    };

    const handleRenew = (event: React.MouseEvent<HTMLElement>) => {

        handleClose(event);
        handleStartDateChange(new Date());
    }

    const handleRenewAndEdit = (event: React.MouseEvent<HTMLElement>) => {
        handleClose(event);
        setGlobalDialog(`RenewAndEditServiceLifeDialog_${id}`);
        setGlobalDialogContent({value: value, buildAndSaveValue: buildAndSaveValue});
    }

    const buildAndSaveValue = (newValue: {[key: string]: Date | number | boolean}) => {

        const newServiceLifeValue = {...value};

        if (newValue.hasOwnProperty("start_time")) {
            setSelectedStartDate(newValue["start_time"] as Date);
            newServiceLifeValue.start_time = format(newValue["start_time"] as Date || 0, "MM/dd/yyyy") || "";
        }

        if (newValue.hasOwnProperty("life_time")) {
            setNumberLifeTime(newValue["life_time"] as number);
            newServiceLifeValue.life_time = newValue["life_time"] as number;
        }

        if (newValue.hasOwnProperty("notification_time")) {
            setNumberNotificationTime(newValue["notification_time"] as number);
            newServiceLifeValue.notification_time = newValue["notification_time"] as number;
        }

        if (newValue.hasOwnProperty("show_percentage")) {
            setShowPercentageChecked(newValue["show_percentage"] as boolean);
            newServiceLifeValue.show_percentage = newValue["show_percentage"] as boolean;
        }

        saveValue(propertyKey, newServiceLifeValue);
    }

    const handleStartDateChange = (value: Date | null) => {
        if (value && isValid(value)) {
            buildAndSaveValue({start_time: value});
        }
    };

    const handleOnLifeTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        buildAndSaveValue({life_time: parseFloat(event.target.value)});
    };

    const handleOnNotificationTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        buildAndSaveValue({notification_time: parseFloat(event.target.value)});
    };

    const handleShowPercentageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        buildAndSaveValue({show_percentage: event.target.checked});
    };

    const remainingTimeOfLife = useMemo( () => {
        let remainingDaysOfLife = (numberLifeTime - differenceInDays(new Date(), selectedStartDate || 0));
        remainingDaysOfLife = remainingDaysOfLife > 0 ? remainingDaysOfLife : 0;
        const percent = Math.round(remainingDaysOfLife / numberLifeTime * 100)
        return {
            percent: percent,
            label: showPercentageChecked ? `${percent}%` : `${remainingDaysOfLife} day${remainingDaysOfLife !== 1 ? "s" : ""}`
        }
    }, [numberLifeTime, selectedStartDate, showPercentageChecked]);

    const handleAutoRenewClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        event.preventDefault();
        event.stopPropagation();
    };

    if (variant === "title" || variant === "propertyCard") {
        return (
            <Fragment>
                <Box position = "relative" width = "100%">
                    <ProgressComponent
                        variant="determinate"
                        value={remainingTimeOfLife.percent}
                        displayValue={remainingTimeOfLife.label}
                        progressVariant={"circular"}
                        size={110}
                        valueTypographyProps={{variant: "h6"}}
                    />
                    <Fab
                        size="small"
                        color="primary"
                        aria-label="renew-menu"
                        aria-controls="renew-menu"
                        component="span"
                        className={classes.autoRenewIcon}
                        onClick={handleAutoRenewClick}>
                        <AutoRenewIcon/>
                    </Fab>

                    <Menu id="renew-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                        <MenuItem onClick={handleRenew}>Renew</MenuItem>
                        <MenuItem onClick={handleRenewAndEdit}>Renew & Edit</MenuItem>
                    </Menu>
                </Box>
            </Fragment>
        );
    }

    return (
        <Fragment>
            <Typography component="div">
                <Divider variant="fullWidth" />
                <Grid component="label" container alignItems="center"  wrap="nowrap">
                    {settings && <Grid xs={1} item container direction="row" justifyContent="center" alignItems="center">
                        {settings}
                    </Grid>}
                    <Grid xs={settings ? 11 : 12} item >
                        {`${label} - Service Life`}
                    </Grid>
                </Grid>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-control"
                        label={"Start of service life"}
                        format="MM/dd/yyyy"
                        value={selectedStartDate}
                        onChange={handleStartDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        fullWidth
                    />
                </MuiPickersUtilsProvider>

                <Grid container spacing={1} justifyContent="center">
                    <Grid item xs={6}>
                        <Box p={0.5}>
                            <TextField
                                margin="normal"
                                variant="outlined"
                                type="number"
                                value={numberLifeTime}
                                name={propertyKey}
                                label={"Life time"}
                                onChange={handleOnLifeTimeChange}
                                fullWidth
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box p={0.5}>
                            <TextField
                                margin="normal"
                                variant="outlined"
                                type="number"
                                value={numberNotificationTime}
                                name={propertyKey}
                                label={"Notify expiration date"}
                                onChange={handleOnNotificationTimeChange}
                                fullWidth
                            />
                        </Box>
                    </Grid>
                </Grid>

                <FormControlLabel
                    value="end"
                    control={
                        <Switch
                            color="primary"
                            checked={showPercentageChecked}
                            onChange={handleShowPercentageChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    }
                    label="Show remaining life as a percentage"
                    labelPlacement="end"
                />
                <Divider variant="fullWidth" />
            </Typography>
        </Fragment>
    );
};

export default ServiceLifeControl;