import { copy, sendMessageToNativeConsole } from "./Utils";
import { getUserId } from "./UserService";
import { isSignedIn } from "./AuthenticationService";

type DBOptions = {
    storeNames: string;
    mode: IDBTransactionMode;
};

let db: any = null;
let errorConnect: any = null;
let currentUser: string = "";

const generateLocalId = (id: string) => {
    return `${id}-${getCurrentUserId()}`;
};

const initService = (): Promise<string | null> => {
    sendMessageToNativeConsole("initObjectLocalService");
    console.log("initService");
    return new Promise((resolve, reject) => {
        if (db) {
            return resolve(null);
        }

        let openRequest = indexedDB.open("store", 9);

        setTimeout(async () => {
            try {
                if (db) {
                    return resolve(null);
                }
                return resolve(initService());
            } catch (err) {
                reject(err);
            }
        }, 200);

        openRequest.onupgradeneeded = function () {
            console.log("====> onupgradeneeded");
            let upgradeDB = openRequest.result;
            let req: any = this;
            let dbObjects;
            let dbUsers;

            if (!upgradeDB.objectStoreNames.contains("objects")) {
                dbObjects = upgradeDB.createObjectStore("objects", { keyPath: "local_id" });
            } else {
                dbObjects = req.transaction.objectStore("objects");
            }

            if (!upgradeDB.objectStoreNames.contains("users")) {
                dbUsers = upgradeDB.createObjectStore("users", { keyPath: "local_id" });
            } else {
                dbUsers = req.transaction.objectStore("users");
            }

            dbObjects.clear();
            dbUsers.clear();

            if (!dbObjects.indexNames.contains("object_type")) {
                dbObjects.createIndex("object_type", "object_type");
            }

            if (!dbObjects.indexNames.contains("source")) {
                dbObjects.createIndex("source", "source");
            }

            if (!dbObjects.indexNames.contains("favorites_state")) {
                dbObjects.createIndex("favorites_state", "favorites_state");
            }

            if (!dbObjects.indexNames.contains("shared_as_root")) {
                dbObjects.createIndex("shared_as_root", "shared_as_root");
            }

            if (!dbObjects.indexNames.contains("native_identification_data, object_type")) {
                dbObjects.createIndex("native_identification_data, object_type", ["native_identification_data", "object_type"]);
            }

            if (!dbObjects.indexNames.contains("observer_id")) {
                dbObjects.createIndex("observer_id", "observer_id");
            }

            if (!dbObjects.indexNames.contains("created")) {
                dbObjects.createIndex("created", "created");
            }
        };

        openRequest.onerror = function () {
            console.error("Error", openRequest.error);
            errorConnect = openRequest.error;
            reject(openRequest.error);
        };

        openRequest.onsuccess = function () {
            console.log("Success connection local DB");
            db = openRequest.result;
            currentUser = getUserId();
            resolve(null);

            db.onversionchange = function () {
                db.close();
                alert("The database is outdated, please reload the page.");
            };
            db.onclose = () => {
                console.log('Database connection closed');
                db = null;
                if (isSignedIn()) {
                    console.log("Restart initService");
                    initService().then(() => {});
                }
            }
        };
    });
};

const closeService = () => {
    if (!db) {
        return;
    }

    db?.close();
    db = null;
    errorConnect = null;
    currentUser = "";
};

const checkConnectToDB = () => {
    return new Promise(async (resolve, reject) => {
        if (db) {
            resolve(null);
        } else if (!db && !errorConnect) {
            setTimeout(async () => {
                try {
                    resolve(await checkConnectToDB());
                } catch (err) {
                    reject(err);
                }
            }, 100);
        } else {

            console.log("errorConnect ", errorConnect)
            if (!isSignedIn()) {
                reject("No connection to local DB. Please sign in");
            }

            console.log("Restart initService 2")

            if (errorConnect) {
                window.location.reload();
            }

            errorConnect = null;

            try {
                await initService();

                setTimeout(async () => {
                    try {
                        resolve(await checkConnectToDB());
                    } catch (err) {
                        reject(err);
                    }
                }, 100);

            } catch (err) {
                console.log("Not connected to local DB.");
                reject(err);
            }
        }
    });
};

const getTransaction = (storeNames: string | string[], mode?: IDBTransactionMode): IDBTransaction => {
    return db.transaction(storeNames, mode);
};

const postLocalItem = async (id: string, data: any, dbOptions: DBOptions) => {
    return new Promise(async (resolve, reject) => {
        try {
            await checkConnectToDB();
        } catch (e) {
            return reject(e);
        }

        const { storeNames, mode } = dbOptions;

        const transaction = getTransaction(storeNames, mode);
        const request = transaction.objectStore(storeNames).put({ ...data, local_id: generateLocalId(id) });

        request.onerror = () => {
            console.error("Error postLocalItem: ", request.error);
            return reject(request.error);
        };

        transaction.oncomplete = () => {
            return resolve(null);
        }
    });
};

const getLocalItems = (dbOptions: DBOptions) => {
    return new Promise(async (resolve, reject) => {
        try {
            await checkConnectToDB();
        } catch (e) {
            return reject(e);
        }

        const { storeNames, mode } = dbOptions;

        const request = getTransaction(storeNames, mode).objectStore(storeNames).openCursor();

        let response: any[] = [];

        request.onsuccess = () => {
            let cursor = request.result;

            if (cursor) {
                if (cursor.value.local_id === generateLocalId(cursor.value.user_id)) {
                    response.push(cursor.value);
                }

                cursor.continue();
            } else {
                return resolve(response);
            }
        };

        request.onerror = () => {
            console.error("Error getLocalItems: ", request.error);
            return reject(request.error);
        };
    });
};

const getLocalItemById = (id: string, dbOptions: DBOptions) => {
    return new Promise(async (resolve, reject) => {
        try {
            await checkConnectToDB();
        } catch (e) {
            return reject(e);
        }

        const { storeNames, mode } = dbOptions;
        const localId = generateLocalId(id);

        const request = getTransaction(storeNames, mode).objectStore(storeNames).get(localId);

        request.onsuccess = () => {
            const result = request.result && copy(request.result);

            if (result === undefined) {
                return reject(`Item ${localId} not found in ${storeNames}`);
            }

            return resolve(result);
        };

        request.onerror = () => {
            console.log("Error: ", request.error);
            return reject(request.error);
        };
    });
};

const getCurrentUserId = () => {
    if (!currentUser) {
        currentUser = getUserId();
    }
    return currentUser;
};

export {
    generateLocalId,
    initService,
    closeService,
    checkConnectToDB,
    getTransaction,
    postLocalItem,
    getLocalItems,
    getLocalItemById,
    getCurrentUserId
}
