import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import useRecursiveTimeout from "../../../Hooks/useRecursiveTimeout";
import {checkIsOnline} from "../../../Services/Utils";

type StatusProps = {
    lastActivityEpoch: number | null;
    isOffline?: boolean;
    text?: string;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        colorTextPrimary: {
            color: theme.palette.success.main,
        },
        root: ({text}: {text:boolean}) => ({
            width: !text ? 60 : '100%',
            marginRight: !text ? 0 : '4px',
        })
    })
);

const getStatusText = (status: boolean) => {
    const text = status ? "Online" : "Offline";
    return text.toLocaleUpperCase();
};

const Status = (props: StatusProps) => {
    const {lastActivityEpoch, isOffline, text} = props;

    const classes = useStyles({text:!!text});

    const [status, setStatus] = useState<boolean>(checkIsOnline(lastActivityEpoch, isOffline));

    useRecursiveTimeout(() => {
        setStatus(checkIsOnline(lastActivityEpoch, isOffline));
    }, 30000);

    useEffect(() => {
        setStatus(checkIsOnline(lastActivityEpoch, isOffline));
    }, [lastActivityEpoch, isOffline]);

    return (
        <Typography
            variant="caption"
            gutterBottom={false}
            color={status ? "textPrimary" : "textSecondary"}
            classes={classes}
        >
            {
                text ?  checkIsOnline(lastActivityEpoch, isOffline) && text : getStatusText(status)
            }
        </Typography>
    )
};

export default Status;