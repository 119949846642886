import React, { useMemo } from "react";
import {
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import { format } from "date-fns";
import ChartTooltipContent from "./ChartTooltipContent";
import { getChartData } from "Helpers/HistoryHelper";
import { PropertyHistoryItem } from "Services/ObjectsService/Types";
import { SystemOfMeasuresType } from "Helpers/SystemOfMeasures";

type ChartProps = {
    startDate: Date;
    endDate: Date;
    historyData: any[];
    chartName: string;
    units?: string;
    systemOfMeasures: SystemOfMeasuresType;
}

const minRangeValue = (dataMin: number) => {
    const absDataMin = Math.abs(dataMin);
    if (absDataMin === Infinity) return dataMin;
    return dataMin - Math.ceil(absDataMin/10);
};
const maxRangeValue = (dataMax: number) => {
    const absDataMax = Math.abs(dataMax);
    if (absDataMax === Infinity) return dataMax;
    return dataMax + Math.ceil(absDataMax/10);
};

function HistoryChart(props: ChartProps) {

    const { chartName, startDate, endDate, historyData, units, systemOfMeasures } = props;

    const chartData = useMemo(
        () => getChartData(
            historyData.filter((data: PropertyHistoryItem) => typeof data.value === "number"),
            units,
            systemOfMeasures,
        ),
        [historyData, units, systemOfMeasures],
    )

    return (
        <ResponsiveContainer width='100%' height="100%">
            <LineChart
                data={chartData}
                margin={{
                    top: 20, right: 0, bottom: 0, left: -20
                }}
            >
                <XAxis
                    allowDataOverflow
                    type="number"
                    scale="time"
                    domain={[()=>(startDate), ()=>(endDate)]}
                    dataKey="x"
                    name="Time"
                    tickFormatter={(value: any) => format(value, "HH:mm")}
                    interval={"preserveStartEnd"}

                />
                <YAxis
                    domain={[minRangeValue, maxRangeValue]}
                />
                <Tooltip
                    cursor={{strokeDasharray: "3 3"}}
                    content={<ChartTooltipContent/>}
                />
                <Line
                    name={chartName}
                    type="monotone"
                    dataKey="y"
                    stroke="#8884d8"
                    dot={false}
                />
            </LineChart>
        </ResponsiveContainer>
    )
}

export default HistoryChart;
