import React, {Fragment, useCallback, useEffect, useMemo, useState} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {alpha,  Fab, Typography, Box, Icon} from "@material-ui/core";
import Property from "../../../../../Property/Property";
import {isSharedObject, PropertyItem, PropertyItemValue} from "../../../../../../../Services/ObjectsService";
import {getValueWithUnits} from "../../../../../../../Helpers/SystemOfMeasures";
import Skeleton from '@material-ui/lab/Skeleton';
import {getPrimeProperty} from "../../../../../../Common/PropertiesPriority";
import {CardMediaComponentProps} from "../CardMediaComponent";
import {HMCharacteristicValues} from "../../../../../Property/PropertyControls/PropertyControlsTypes";

const useStyles = makeStyles((theme) => ({
    mediaBlock: {
        height: "140px",
        backgroundColor: alpha("#999999", 0.1),
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    mediaIcon: {
        paddingTop: theme.spacing(3),
    },
    peopleIconWrapper: {
        width: "35px",
        height: "30px",
        position: "absolute",
        top: theme.spacing(0.4),
        right: theme.spacing(0.5),
    },
}));


const CardMediaBlock = (props: CardMediaComponentProps) => {
    const classes = useStyles();

    const {object, userInfo, parentId} = props;

    const [primeProperty, setPrimeProperty] = useState<PropertyItem | undefined>();

    const systemOfMeasures = userInfo.user?.system_of_measures || "imperial";

    useEffect(() => {
        const prime_property = getPrimeProperty(Object.values(object.properties));
        if (prime_property) {
            setPrimeProperty(prime_property);
        }

    }, [object]);

    const onChangeHandler = useCallback((key: string, value: PropertyItemValue) => {
        setPrimeProperty((prev) => {
            if (!prev) {
                return prev;
            }

            return {
                ...prev,
                value: value
            }
        })
    }, [setPrimeProperty])

    const isShared = useMemo(() => {
        return isSharedObject(object);
    }, [object, isSharedObject]);

    if (!object.object_id) {
        return (
            <Box className={classes.mediaBlock}>
                <Skeleton animation="wave" variant="rect" width="100%" height="100%"/>
            </Box>)
    }

    const primePropertyText =
        !primeProperty ?
            "" :
            HMCharacteristicValues[(object.properties?.[primeProperty.key]?.type) as keyof typeof HMCharacteristicValues]?.[primeProperty?.value as number]?.label ||
            getValueWithUnits(
                systemOfMeasures,
                isNaN(parseInt(primeProperty.value as string)) ? "" : primeProperty.value,
                primeProperty.units
            );

    return (
        <Box className={classes.mediaBlock}>
            {primeProperty && (
                <Property
                    property={primeProperty as PropertyItem}
                    object={object}
                    variant={"title"}
                    userInfo={userInfo}
                    parentId={parentId}
                    onChange={onChangeHandler}
                />
            )}

            {isShared && (
                <Fab disabled color="primary" className={classes.peopleIconWrapper}>
                    <Icon className="fas fa-users" style={{ fontSize: 17 }}/>
                </Fab>
            )}

            {(!primeProperty || primeProperty?.type === "numericValue") && (
                <Box className={classes.mediaIcon}>
                    <Typography gutterBottom variant={primePropertyText.length > 10 ? "h5" : "h4"}>
                        <Icon className={primeProperty?.icon || object.icon} fontSize={primePropertyText.length > 10 ? "medium" : "large"} color="disabled"/>
                        {' '}
                        {!!primeProperty && (
                            <Fragment>
                                {primePropertyText}
                            </Fragment>
                        )}
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

export default CardMediaBlock;
