export const inPolygon = (pointOnMap: { x: number; y: number }, polygon: { x: number; y: number }[]): boolean => {
    // The entry of the point into the polygon.
    // Calculated based on counting the number of ray intersections
    // Applies if polygon area is overlapped by marker label

    let {x, y} = pointOnMap;
    let inPolygon = false;

    let j = polygon.length - 1;
    for (let i = 0; i < polygon.length; i++) {
        const point_x = polygon[i].x;
        const point_y = polygon[i].y;
        const prev_point_x = polygon[j].x;
        const prev_point_y = polygon[j].y;
        if (
            ((point_y <= y && y < prev_point_y) || (prev_point_y <= y && y < point_y)) &&
            x > ((prev_point_x - point_x) * (y - point_y)) / (prev_point_y - point_y) + point_x
        ) {
            inPolygon = !inPolygon;
        }
        j = i;
    }
    return inPolygon;
};

// export const selectMarker = (
//     pointOnMap: { x: number; y: number },
//     markers: { [id: string]: { x: number; y: number } },
//     iconSize: number
// ): string | null => {
//     // Selecting an active marker by clicking on the map
//     // Returns the name of the active marker or null
//     const {x: pointX, y: pointY} = pointOnMap;
//     let targets: { [id: string]: number } = {};
//     for (const object_id of Object.keys(markers)) {
//         const markerX = markers[object_id].x;
//         const markerY = markers[object_id].y;
//
//         if (
//             pointX >= markerX - iconSize / 2 &&
//             pointX <= markerX + iconSize / 2 &&
//             pointY >= markerY &&
//             pointY <= markerY + iconSize
//         ) {
//             targets[object_id] = pointX - markerX + pointY - markerY;
//         }
//     }
//     if (Object.entries(targets).length) {
//         return Object.entries(targets).sort((a, b) => a[1] - b[1])[0][0];
//     }
//     return null;
// };

export const getMarkerPointForPolygon = (polygonPoints: [number, number][]) => {
    if (polygonPoints.length > 4) {
        const lat = polygonPoints.map((point) => point[0]);
        const lng = polygonPoints.map((point) => point[1]);

        return [
            Math.min(...lat) + (Math.max(...lat) - Math.min(...lat)) / 2,
            Math.min(...lng) + (Math.max(...lng) - Math.min(...lng)) / 2,
        ];
    } else {
        return polygonPoints
            .reduce((accum: Array<number>, current: Array<number>) => [accum[0] + current[0], accum[1] + current[1]], [
                0,
                0,
            ])
            .map((current: number) => current / polygonPoints.length);
    }
};
