import React from "react";
import {Grid, Typography} from "@material-ui/core";
import Link from "@material-ui/core/Link";
import WelcomeDialogContentBodyImg from "../WelcomeDialogContentBodyImg";
import {SlideType} from "../WelcomeDialogContent";

export const Slide8 = (props: SlideType) => {
    return (
        <>
            <Grid
                container
                spacing={3}
                direction="row"
                justifyContent="center"
                alignItems="flex-start">
                <Grid item xs={12} sm={6}>
                    <WelcomeDialogContentBodyImg
                        src={"/img/welcome_dialog/img_slide_8.png"}
                    />
                </Grid>
                <Grid item xs={12} sm>
                    <Typography
                        variant={props.xsSize ? "body1" : "h6"}
                        align="left"
                        gutterBottom={true}>
                        Set the correspondance between the points in camera frame and the appropriate map or floor plan
                        points using the LocalMachines camera zone tool, and have the camera image stretched over the
                        map or floor plan. This will allow you to see the top-view combined image from all cameras in
                        real-time.
                    </Typography>
                    <Typography
                        variant={props.xsSize ? "body1" : "h6"}
                        align="left"
                        gutterBottom={true}>
                        <Link
                            href="https://kb.localmachines.io/users-guides/camera-visibility-zone-map/"
                            rel="noreferrer" target="_blank">
                            Local Machines. How to add a camera visibility zone on map</Link>
                    </Typography>
                </Grid>
            </Grid>
        </>
    )
}
