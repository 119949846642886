import {useCommonStyles} from "../../common/Common";
import React, {ReactNode, useMemo, useState} from "react";
import {ColorChangeHandler, ColorResult, HSLColor, HuePicker, RGBColor} from "react-color";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

type HueComponentProps = {
    value: number;
    label: string;
    width?: string;
    height?: string;
    handleSaveValue(value: number):void;
    isDisabled?: boolean;
    settings?: ReactNode;
};

const HueComponent = (props: HueComponentProps) => {

    const {
        value,
        label,
        width,
        height,
        handleSaveValue,
        isDisabled,
        settings,
    } = props
    const commonClasses = useCommonStyles();

    const newHueColor = useMemo(() => {
        return {
            hsl: {
                h: value,
                s: 1,
                l: 0.5,
                a: 1,
            },
        };
    }, [value]);

    const [currentColor, setCurrentColor] = useState<{ hex?: string; hsl: HSLColor; rgb?: RGBColor; oldHue?: number } | ColorResult>(newHueColor);
    const timestampRef = React.useRef<number>(0);
    const timeoutRef = React.useRef<any>(null);

    const handleChangeColorComplete: ColorChangeHandler = (color) => {
        if (color.hsl === currentColor.hsl) return;
        setCurrentColor(color);

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
            timestampRef.current = Date.now();
            handleSaveValue(Math.round(color.hsl.h));
        }, 500)

        if (Date.now() - timestampRef.current < 500) {
            return;
        }
        timestampRef.current = Date.now();
        handleSaveValue(Math.round(color.hsl.h));
    };


    return isDisabled ? null : (
        <Grid container direction="column" justifyContent="center">
            <Grid item container direction="row" justifyContent="center" alignItems="center">
                {settings}
                <Typography>
                    {label}
                </Typography>
            </Grid>
            <div className={commonClasses.cursorPointer}>
                <HuePicker
                    color={currentColor.hsl}
                    onChange={handleChangeColorComplete}
                    // onChangeComplete={handleChangeColorComplete}
                    width={width}
                    height={height}
                />
            </div>
        </Grid>
    );
};

export default HueComponent