export enum MENU_ITEM {
    BUILDINGS = "Buildings",
    CAMERAS = "Cameras",
    CONSUMABLES = "Consumables",
    CONVERSATIONS = "Conversations",
    DOCUMENTS = "Documents",
    FILES = "Files",
    FAVORITES = "Favorites",
    HELP = "Help",
    MACHINES = "Machines",
    PUBLIC = "Public",
    SHARED_WITH_ME = "SharedWithMe",
    SITES = "Sites"
}

export enum HELP_MENU_ITEM {
    ACKNOWLEDGEMENTS = "Acknowledgements",
    LIMITATIONS = "Limitations",
    SUPPORT = "Support",
    USERS_GUIDES = "UsersGuides",
    WHATS_NEW = "WhatsNew"
}

export enum APPLICATION_ID {
    LOCAL_MACHINES = "localmachines",
    EXPIRIUM = "expirium",
    DOKU = "doku"
}
