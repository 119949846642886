import { AbstractElement, ElementData, ElementType } from "../AbstractElement";
import { ObjectItem } from "../../../../../Services/ObjectsService";
import { Point, Group } from "paper";
import { v4 } from "uuid";

/** Represent the object as an icon in the plan
 */
export class ObjectElement extends AbstractElement {
    protected icon: any;
    protected text: any;

    public isSelected(): Boolean {
        return this.paperJSItem.selected;
    }

    public onMouseDown(event: any): void {
        this.setProperty("plan_point", [event.point.x, event.point.y]);
        this.paperJSItem.selected = true;
    }

    public onMouseDrag(event: any): void {
        this.setProperty("plan_point", [event.point.x, event.point.y]);
    }

    public onMouseUp(event: any): void {
        this.setProperty("plan_point", [event.point.x, event.point.y]);
    }

    public updateAlignment(elements: AbstractElement[]): void {}

    /**
        Alignment method. Updates properties using the alignment information.
        I.e. sets properties according to the position of the elements
        the current element has to stick to.
    */
    public alignElement(elements: AbstractElement[]): void {}

    /**
        Geometry update according to the properties set
    */
    public updateGeometry(): void {
        let point = new Point(this.getProperty("plan_point"));
        this.paperJSItem.position = point;
    }

    protected generatePaperJSItem(): void {
        // console.log("Setting PaperJS item");

        this.paperJSItem = new Group({
            children: [],
        });
    }

    protected setDefaultData(): void {
        // console.log("Setting Default Data for PaperJS item");
        let data: ElementData = {
            id: v4(),
            type: ElementType.OBJECT_ELEMENT,
            info: { object_name: "Wall", description: "Solid wall" },
            properties: {
                plan_point: {
                    key: "plan_point",
                    property_id: v4(),
                    name: "Plan Point",
                    value: {},
                    type: "PlanPoint",
                    readable: true,
                    writable: false,
                    visibility: ["details"],
                },
            },
            // Visualisation formulas
            formulas: {},
            // Visualisation values
            currentValues: {},
            alignment: {},
        };

        this.paperJSItem.data = data;
    }

    public setObjectData(object: ObjectItem) {
        super.setObjectData(object);
        this.paperJSItem.removeChildren();

        // Do not display element on canvas
        // This is now displayed as a marker

        // if (object.icon) {
        //     const iconName = object.icon.substr(7);
        //     const svgFolders = ["solid", "brands", "regular"];
        //
        //     const importSVG = (folder: string) => {
        //         this.paperJSItem.importSVG(`/svgs/${folder}/${iconName}.svg`, {
        //             onLoad: (item: any, string: any) => {
        //                 console.log(item, string);
        //
        //                 item.scale(1 / 25);
        //
        //                 item.position = new Point(0, 0);
        //                 console.log("Item Position", item.position);
        //                 let text = new PointText(new Point(0, 0));
        //                 this.paperJSItem.addChild(text);
        //                 text.content = object.object_name;
        //                 text.position = new Point(0, 1.5 * text.bounds.height);
        //                 this.updateGeometry();
        //             },
        //             onError: (e: any) => {
        //                 console.log(`Error load icon from ${folder}`);
        //                 const index = svgFolders.indexOf(folder);
        //
        //                 if (index !== -1 && svgFolders[index + 1] && index < svgFolders.length) {
        //                     console.log(`Try load from ${svgFolders[index + 1]}`);
        //                     importSVG(svgFolders[index + 1]);
        //                 }
        //             },
        //         });
        //     };
        //
        //     importSVG(svgFolders[0]);
        // } else {
        //     let text = new PointText(new Point(0, 0));
        //     this.paperJSItem.addChild(text);
        //     text.content = object.object_name;
        //     this.updateGeometry();
        // }
    }
}
