import React, {Fragment, ReactNode, useEffect, useState} from "react";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Popover from "@material-ui/core/Popover";
import {popoverPosition, useCommonStyles} from "../common/Common";
import HueComponent from "./HueComponent/HueComponent";
import {withThrottle} from "../../../Helpers/Decorators";

type HueProps = {
    propertyKey: string;
    value: number;
    label: string;
    variant?: string;
    width?: string;
    height?: string;
    fontSize?: number | undefined;
    saveValue(key: string, value: string | number | boolean): void;
    isDisabled?: boolean;
    settings?: ReactNode;
};

const hueControlVariants = ["control", "popover", "marker"]


const Hue = (props: HueProps) => {

    const [panelBackgroundColor, setPanelBackgroundColor] = useState<string>(`hsl(${props.value}, 100%, 50%)`);
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const variant = !props.variant || !hueControlVariants.includes(props.variant) ? "control" : props.variant;

    const commonClasses = useCommonStyles();

    const handleSaveValue = (_value: number) => {
        props.saveValue(props.propertyKey, _value);
    };

    const handleSaveValueWithThrottle = withThrottle(handleSaveValue, 250)

    const handleClickColorPanel = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleColorPickerClose = () => {
        setAnchorEl(null);
        // handleSaveValue(props.value);
    };

    const displayColorPicker = Boolean(anchorEl);
    const _propertyKey = displayColorPicker ? props.propertyKey : undefined;

    useEffect(() => setPanelBackgroundColor(`hsl(${props.value}, 100%, 50%)`), [props.value]);


    const getPopover = () => (
        <Popover
            id={_propertyKey}
            open={displayColorPicker}
            anchorEl={anchorEl}
            onClose={handleColorPickerClose}
            {...popoverPosition}
        >
            <Box p={2}>
                <HueComponent
                    value={props.value}
                    label={props.label}
                    handleSaveValue={handleSaveValueWithThrottle}
                    isDisabled={props.isDisabled}
                    settings={props.settings}
                />
            </Box>
        </Popover>
    )


    if (variant === "control") {
        return (
            <React.Fragment>
                <HueComponent
                    value={props.value}
                    label={props.label}
                    handleSaveValue={handleSaveValueWithThrottle}
                    width={props.width}
                    isDisabled={props.isDisabled}
                    settings={props.settings}
                />
            </React.Fragment>
        );
    }

    if (variant === "marker") {
        return (
            <Fragment
            >
                <Typography noWrap gutterBottom variant="inherit" onClick={handleClickColorPanel}>
                    <Box
                        width={props.fontSize}
                        height={props.fontSize}
                        m={0.5}
                        bgcolor={panelBackgroundColor}
                        borderRadius={3}
                        border={1}
                        borderColor="text.disabled"
                    />
                </Typography>
                {getPopover()}
            </Fragment>
        )
    }

    return (
        <React.Fragment>
            <Typography component="div">
                <Grid
                    component="label"
                    container
                    alignItems="center"
                    spacing={1}
                    onClick={handleClickColorPanel}
                    className={commonClasses.cursorPointer}
                    wrap="nowrap"
                >
                    <Grid item container direction="row" justifyContent="center" alignItems="center">
                        {props.settings}
                        <Box fontSize="small">{props.label}</Box>
                    </Grid>
                    <Grid item>
                        <Box
                            width="16px"
                            height="16px"
                            m={0.5}
                            bgcolor={panelBackgroundColor}
                            borderRadius={3}
                            border={1}
                            borderColor="text.disabled"
                        />
                    </Grid>
                </Grid>
                {getPopover()}
            </Typography>
        </React.Fragment>
    );
};

export {Hue};
