import React, {useEffect, useState} from "react";
import Skeleton from '@material-ui/lab/Skeleton';
import {ChildrenCountByType, ListSubObjectsOnCardProps} from "./TypesCard";
import CardContentItem from "./ObjectCard/CardContentItem/CardContentItem";
import {
    getObjects,
    isObjectAvailableToDisplay,
    ObjectItem,
    SubObjectItem
} from "../../../Services/ObjectsService";
import { useSubChildren } from "../../../Hooks/Subscriptions/useSubChildren";

export default function ListSubObjectsOnCard(props: ListSubObjectsOnCardProps) {

    const { children, objectId } = props;

    const [childrenCountByType, setChildrenCountByType] = useState<ChildrenCountByType[]>([]);
    const [listChildren, setListChildren] = useState<string[]>([]);

    const countChildrenByType = async (listChild: string[]) => {
        if (!Array.isArray(listChild)) {
            return;
        }

        const params = {
            children: listChild
        };

        const sub_objects = (await getObjects(params) || []).filter((object: ObjectItem) => {
            return isObjectAvailableToDisplay(object);
        });

        if (!Array.isArray(sub_objects)) {
            return;
        }

        let childrenCountByTypeTmp: { [key: string]: ChildrenCountByType } = {};

        sub_objects.forEach((item: SubObjectItem) => {
            const _object_type = item.object_type;

            if (!childrenCountByTypeTmp.hasOwnProperty(_object_type)) {
                childrenCountByTypeTmp[_object_type] = {
                    count: 0,
                    type: _object_type,
                    icon: item.icon || "fas fa-info-circle",
                };
            }
            childrenCountByTypeTmp[_object_type].count++;

            //TODO show property for each sub_object
            // if (Array.isArray(item.properties) && item.properties.length !== 0) {
            //     return item.properties.filter((propItem: PropertyItem) => {
            //         return propItem.visibility.includes("parent");
            //     }).forEach((property: PropertyItem) => {
            //         const _property_type = property.type;
            //         if (!subObjectsTmp.hasOwnProperty(_property_type)) {
            //             subObjectsTmp[_property_type] = {
            //                 count: 0,
            //                 type: _property_type,
            //                 icon: property.icon || "fas fa-info-circle",
            //                 value: property.value,
            //                 name: property.name,
            //             };
            //         }
            //         subObjectsTmp[_property_type].count++;
            //     });
            // }
        });

        return Object.values(childrenCountByTypeTmp).sort((e1, e2) => {
            if(e1.type < e2.type) { return -1; }
            if(e1.type > e2.type) { return 1; }
            return 0;
        });

        // return ;
    };

    useEffect(() => {
        if (!Array.isArray(children)) {
            return;
        }

        setListChildren(children);

    },[children]);

    useEffect(() => {
        if (!Array.isArray(listChildren)) {
            return;
        }

        let active = true;

        (async () => {
            const result = await countChildrenByType(listChildren);

            if (active && result) {
                setChildrenCountByType(result);
            }
        })();

        return () => {
            active = false;
        }
    }, [listChildren]);

    useSubChildren(children || [], objectId, async (data)=> {
        const result = await countChildrenByType(data as string[]);

        if (result) {
            setChildrenCountByType(result);
        }
    });

    if (!objectId) {
        return (
            <>
                <Skeleton animation="wave" height={15} width="100%"/>
                <Skeleton animation="wave" height={15} width="65%"/>
            </>
        )
    }

    return (
        <>
            {childrenCountByType.map(({ count, type, icon }: ChildrenCountByType) => (
                <CardContentItem key={type} icon={icon || "fas fa-info-circle"} label={type + " x" + count} />
            ))}
        </>
    );
}
