import React, { useState } from "react";
import { AppBar, Container, Grid } from "@material-ui/core";
import { sub } from 'date-fns';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { ObjectItem, PropertyItem } from "Services/ObjectsService/Types";
import { UserInfoType } from 'Hooks/useUserProfile';
import { checkPropertyCardData } from 'Services/Utils';
import { SnackBarType } from 'Services/NotificationService';
import DateRange from 'Components/Common/DateRange/DateRange';
import {
    controlMapPointType,
    controlNumberTypes,
    controlPlanPointType,
} from "../Property/PropertyControls/PropertyControlsTypes";
import HistoryCardContainer from "./HistoryCardContainer";

type ObjectHistoryProps = {
    object: ObjectItem;
    notify: SnackBarType;
    userInfo: UserInfoType;
    parentId?: string;
}

const availablePropertyTypes = [
    ...controlNumberTypes,
    ...controlMapPointType,
    ...controlPlanPointType,
]

const useStyles = makeStyles((theme) => ({
    dateRangeBar: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
        paddingLeft: theme.spacing(5.5),
        top: '55px',
        [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
            top: '48px',
        },
        [theme.breakpoints.up("sm")]: {
            top: '64px',
        },
        position: "fixed",
    },
    contentContainer: {
        // paddingTop:  theme.spacing(5),
        // paddingBottom:  theme.spacing(10),
        position: "relative",
        top: `calc(55px + ${theme.spacing(8)}px)`,
        [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
            top: `calc(48px + ${theme.spacing(8)}px)`,
        },
        [theme.breakpoints.up("sm")]: {
            top: `calc(64px + ${theme.spacing(8)}px)`,
        },
    },
    contentHeight: {
        paddingBottom: theme.spacing(10),
    }
}));

const propertyFilter = (properties: { [key: string]: any }, availablePropertyTypes: string[]): PropertyItem[] => {
    return Object.values(properties)
        .filter(
            (property: PropertyItem) =>
                property &&
                (availablePropertyTypes.includes(property.type) || typeof property.value === "number") &&
                property.visibility?.includes('details') &&
                checkPropertyCardData(property)
        ).sort((property1, property2) => {
        if (property1.name < property2.name) { return -1; }
        if (property1.name > property2.name) { return 1; }
        if (property1.property_id < property2.property_id) { return -1; }
        if (property1.property_id > property2.property_id) { return 1; }
        return 0;
    })
};

const ObjectHistory = (props: ObjectHistoryProps): JSX.Element | null => {
    const { object, notify, parentId, userInfo } = props;

    const classes = useStyles();

    const [startDateRange, setStartDateRange] = useState<Date>(sub(new Date(), {hours: 500}));
    const [endDateRange, setEndDateRange] = useState<Date>(new Date());

    const propertyCards: PropertyItem[] = React.useMemo(() => {
        return propertyFilter(object.properties, availablePropertyTypes);
    }, [object.properties]);

    return (
        <>
            <AppBar component="div" className={classes.dateRangeBar} position="sticky" elevation={0}>
                <DateRange
                    startDateRange={startDateRange}
                    endDateRange={endDateRange}
                    setStartDateRange={setStartDateRange}
                    setEndDateRange={setEndDateRange}
                />
            </AppBar>
            <Container maxWidth='xl' className={classes.contentContainer}>
                <Grid container spacing={4} className={classes.contentHeight}>
                    { propertyCards
                        .map((property) => (
                            <Grid item key={property.property_id} xs={12}>
                                <HistoryCardContainer
                                    property={property}
                                    notify={notify}
                                    object={object}
                                    parentId={parentId}
                                    userInfo={userInfo}
                                    dateRange={{
                                        startDate: startDateRange,
                                        endDate: endDateRange,
                                    }}
                                />
                            </Grid>
                        )) }
                </Grid>
            </Container>
        </>
    );
};

export default ObjectHistory;
