import React, { useCallback, useState, useRef, useEffect } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { CircularProgress, TextField, Badge, Box, Fab, Grid, IconButton, InputAdornment } from '@material-ui/core';
import {
    Send as SendIcon,
    KeyboardArrowDown as KeyboardArrowDownIcon
} from '@material-ui/icons';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

// Components
import ShareDialog from "Components/Dialogs/ShareDialog/ShareDialog";
import ChatMessage from "Components/Objects/Conversation/ChatMessage";
import DateLabel from "Components/Objects/Conversation/DateLabel";
import ChatPlaceholder from "Components/Objects/Conversation/ChatPlaceholder";

// Hooks
import { useSubPropertyValue } from "Hooks/Subscriptions/useSubPropertyValue";
import useIsMounted from "Hooks/IsMounted/useIsMounted";

// Services
import type { ObjectItem, PropertyItem, ChatMessageType, Params } from "Services/ObjectsService/Types";
import type { UserDbItem } from "Services/UserService";
import { getObjectPropertyHistory, postObjectProperties } from "Services/ObjectsService/CRUD";
import { OriginType } from "Services/ObjectsService/Constants";
import { getClientId, getUserId, getUserById } from "Services/UserService";
import { buildSenderTitle, generatePropertyItem } from "Services/Utils";

const useStyles = makeStyles((theme: Theme) => ({
    chatWrapper: {
        height: 'calc(100% - 76px - 64px)'
    },
    chatFeedWrapper: {
        backgroundColor: theme.palette.background.paper,
        maxWidth: '600px',
        width: '100%',
        position: 'relative',
        overflow: 'hidden',
        height: '100%'
    },
    chatInputWrapper: {
        backgroundColor: theme.palette.background.paper,
        maxWidth: '600px',
        width: '100%'
    },
    chatInput: {
        margin: '4px 0px',
        // [IM] ::-webkit-scrollbar feature is non-standard, Firefox is not supported it
        '& > * > textarea::-webkit-scrollbar': {
            width: '7px'
        },
        '& > * > textarea::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            borderRadius: '7px'
        }
    },
    chevronDown: {
        position: 'absolute',
        bottom: theme.spacing(1),
        right: theme.spacing(1)
    },
    badgeOnChevron: {
        top: theme.spacing(0.5),
        right: theme.spacing(0.5)
    },
    loader: {
        position: 'absolute',
        top: '50%',
        left: '50%'
    }
}));

const HISTORY_LIMIT = 25;
const MAX_HEIGHT_TO_LOAD = 130;
const HEIGHT_TO_HIDE_FAB = 50;
const HIDE_TITLE_RANGE = 3 * 60 * 1000;

enum CHAT_MESSAGE_TYPES {
    TEXT = 'text',
    DATALABEL = 'datalabel'
}

const MONTH_NAMES = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

const checkTitleHideNeed = (prev_id: string, prev_time: number, cur_id: string, cur_time: number) => {
    let hide_title = false;

    if (cur_id === prev_id && cur_time - prev_time < HIDE_TITLE_RANGE &&
        // [IM] Prevent hiding title for messages with needed time range, but different days
        new Date(prev_time).getDate() === new Date(cur_time).getDate())
    {
        hide_title = true;
    }

    return hide_title;
};

const checkDateLabelNeed = (prev_time: number, cur_time: number) => {
    let date_label = false;

    if (new Date(prev_time).getUTCFullYear() < new Date(cur_time).getUTCFullYear() ||
        new Date(prev_time).getMonth()       < new Date(cur_time).getMonth()       ||
        new Date(prev_time).getDate()        < new Date(cur_time).getDate())
    {
        date_label = true;
    }

    return date_label;
};

const buildDataLabel = (timestamp: number) => {
    return MONTH_NAMES[new Date(timestamp).getMonth()] + ", " + new Date(timestamp).getDate();
};

export type MessageItemType = {
    own: boolean;
    senderId: string;
    title: string;
    hideTitle: boolean;
    type: string;
    text: string;
    date: number;
};

type ObjectConversationProps = {
    object: ObjectItem;
    displayModalShare?(state: boolean): void;
    openModalShare?: boolean;
};

const ObjectConversation = (props: ObjectConversationProps) => {

    const {
        object,
        displayModalShare,
        openModalShare
    } = props;

    const property: PropertyItem = object.properties.chat_message ||
        generatePropertyItem({
            key: "chat_message",
            name: "Chat Message",
            type: "ChatMessage",
            value: {
                text: "",
                timestamp: 0,
                data: {},
                user_id: getUserId()
            },
            visibility: ["card", "details", "parent", "title"],
        });

    const [chatLoading, setChatLoading] = useState<boolean>(true);
    const [historyLoading, setHistoryLoading] = useState<boolean>(false);
    const [messages, setMessages] = useState<MessageItemType[]>([]);
    const [showChevron, setShowChevron] = useState<boolean>(false);

    const history_available = useRef<boolean>(true);
    const history_last = useRef<number>(0);
    const history_scroll_height = useRef<number>(0);

    const chatFeedRef = useRef<any>(null);
    const chatInputRef = useRef<any>(null);

    const classes = useStyles();
    const isMounted = useIsMounted();

    const handleAddPeopleDialogClose = useCallback(() => {
        if (displayModalShare) {
            displayModalShare(false);
        }
    }, [displayModalShare]);

    const { subscriptionId } = useSubPropertyValue(object.object_id, "chat_message", async (data) => {
        const received_data = data.value as ChatMessageType;

        const received_message = received_data.text;
        const received_time = received_data.timestamp;
        const received_user_id = received_data.user_id;
        const sender_title = await buildSenderTitle(received_data || {});
        const is_current_user = received_user_id === getUserId();

        if (!isMounted()) { return; }

        let onBottom = true;

        if (chatFeedRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = chatFeedRef.current;
            onBottom = (scrollTop + clientHeight + HEIGHT_TO_HIDE_FAB >= scrollHeight);
        }

        setMessages((prevMessages: MessageItemType[]) => {
            let result = [...prevMessages];

            const prevMessage = result.length ? result[result.length - 1] : { senderId: 'unknown', date: 0 };

            if (!result.length || checkDateLabelNeed(prevMessage.date, received_time)) {
                const dateLabel = buildDataLabel(received_time);

                result.push({
                    own: false,
                    senderId: "system",
                    title: dateLabel,
                    hideTitle: false,
                    type: CHAT_MESSAGE_TYPES.DATALABEL,
                    text: "",
                    date: received_time - 1,
                });
            }

            const hideTitle = checkTitleHideNeed(prevMessage.senderId, prevMessage.date, received_user_id, received_time);

            result.push({
                own: is_current_user,
                senderId: received_user_id,
                title: sender_title,
                hideTitle: hideTitle,
                type: CHAT_MESSAGE_TYPES.TEXT,
                text: received_message,
                date: received_time,
            });

            return result;
        });

        if (onBottom) {
            scrollDown();
        } else if (!showChevron) {
            setShowChevron(true);
        }
    });

    const clickOnSend = useCallback(async () => {
        const trimCheck = chatInputRef.current?.value.trim() || "";

        if (!trimCheck.length) {
            return;
        }

        const sending_property_value: ChatMessageType = {
            text: chatInputRef.current.value,
            timestamp: new Date().getTime(),
            user_id: getUserId(),
            data: {}
        }

        const sending_message = sending_property_value.text;
        const sending_time = new Date(sending_property_value.timestamp).getTime();
        const sending_user_id = sending_property_value.user_id;
        const sender_title = await buildSenderTitle(sending_property_value || {});

        if (!isMounted()) { return; }

        chatInputRef.current.value = "";

        setMessages((prevMessages: MessageItemType[]) => {
            let result = [...prevMessages];

            const prevMessage = result.length ? result[result.length - 1] : { senderId: 'unknown', date: 0 };

            if (!result.length || checkDateLabelNeed(prevMessage.date, sending_time)) {
                const dateLabel = buildDataLabel(sending_time);

                result.push({
                    own: false,
                    senderId: "system",
                    title: dateLabel,
                    hideTitle: false,
                    type: CHAT_MESSAGE_TYPES.DATALABEL,
                    text: "",
                    date: sending_time - 1,
                });
            }

            const hideTitle = checkTitleHideNeed(prevMessage.senderId, prevMessage.date, sending_user_id, sending_time);

            result.push({
                own: true,
                senderId: sending_user_id,
                title: sender_title,
                hideTitle: hideTitle,
                type: CHAT_MESSAGE_TYPES.TEXT,
                text: sending_message,
                date: sending_time,
            });

            return result;
        });

        scrollDown();

        const _properties = {
            chat_message: {
                ...property,
                value: {
                    text: sending_message,
                    timestamp: sending_time,
                    data: {},
                    user_id: sending_user_id
                }
            }
        };

        const _origin = {
            origin: OriginType.USER,
            sid: subscriptionId,
            clientId: getClientId()
        }

        postObjectProperties(object.object_id, _properties, _origin).then(() => {}).catch(() => {});
    }, [object, property, subscriptionId, isMounted]);

    const loadHistory = useCallback(async (endDate: number) => {
        const params: Params = {
            limit: HISTORY_LIMIT,
            endDateRange: endDate - 1
        };

        try {
            const _history = await getObjectPropertyHistory(object.object_id, 'chat_message', params);

            let prev_messages: MessageItemType[] = [];
            let user_cache: { [key: string]: UserDbItem } = {};
            const currentUserId = getUserId();

            // [IM] Workaround for welcome message, that absent in history right after creation
            if (_history.count === 0 && object.properties.chat_message?.value) {
                _history.histories.push(object.properties.chat_message);
            }

            history_last.current = _history.last_date || 0;

            if (!_history.histories.length || _history.histories.length < HISTORY_LIMIT) {
                history_available.current = false;
            }

            for (const history_item of _history.histories) {
                if (!history_item.value) continue;

                const history_value = history_item.value as ChatMessageType;

                if (!history_value.text) {
                    // [IM] Do not display empty message
                    continue;
                }

                const received_message = history_value.text;
                const received_time = history_value.timestamp;
                const received_user_id = history_value.user_id;
                // [IM] buildSenderTitle function is not being applied in a reason of using user_cache
                let received_user_info: UserDbItem | null = null;
                try { received_user_info = user_cache[received_user_id] || await getUserById(received_user_id); } catch (e) {}
                const received_title = received_user_info && received_user_info.firstname && received_user_info.lastname ? received_user_info.firstname + " " + received_user_info.lastname : "Unknown User";
                const is_current_user = received_user_id === currentUserId;

                if (received_user_info && Object.keys(received_user_info).length) {
                    user_cache[received_user_id] = received_user_info;
                }

                if (prev_messages.length && checkTitleHideNeed(received_user_id, received_time, prev_messages[0].senderId, prev_messages[0].date)) {
                    prev_messages[0].hideTitle = true;
                }

                if (prev_messages.length && checkDateLabelNeed(received_time, prev_messages[0].date)) {
                    const dateLabel = buildDataLabel(prev_messages[0].date);

                    prev_messages.unshift({
                        own: false,
                        senderId: "system",
                        title: dateLabel,
                        hideTitle: false,
                        type: CHAT_MESSAGE_TYPES.DATALABEL,
                        text: "",
                        date: prev_messages[0].date - 1,
                    });
                }

                prev_messages.unshift({
                    own: is_current_user,
                    senderId: received_user_id,
                    title: received_title,
                    hideTitle: false,
                    type: CHAT_MESSAGE_TYPES.TEXT,
                    text: received_message,
                    date: received_time,
                });
            }

            // [IM] Add Date Label in the very beginning
            if (history_last.current === 0 && prev_messages.length) {
                const dateLabel = buildDataLabel(prev_messages[0].date);

                prev_messages.unshift({
                    own: false,
                    senderId: "system",
                    title: dateLabel,
                    hideTitle: false,
                    type: CHAT_MESSAGE_TYPES.DATALABEL,
                    text: "",
                    date: prev_messages[0].date - 1,
                });
            }

            return prev_messages;
        } catch (e) {
            console.error(e);
        }

        return [];
    }, [object]);

    const processHistory = useCallback((prev_messages: MessageItemType[]) => {
        if (!isMounted()) { return; }

        // [IM] Workaround to exclude scroll jumps, because after setMessage scrollTop will be changed
        // with lower value based on number of messages without title and date labels
        const prevScrollTop: number = chatFeedRef.current ? chatFeedRef.current.scrollTop : 0;

        setMessages((prev: MessageItemType[]) => {
            return [...prev_messages, ...prev];
        });
        setChatLoading(false);
        setHistoryLoading(false);

        if (chatFeedRef.current) {
            // [IM] Scroll to bottom initially, but not in case of scrolling history
            chatFeedRef.current.scrollTop = chatFeedRef.current.scrollHeight - history_scroll_height.current + prevScrollTop;
            history_scroll_height.current = chatFeedRef.current.scrollHeight;
        }
    }, [isMounted]);

    useEffect(() => {
        if (!object.object_id) {
            return;
        }

        let active = true;

        setChatLoading(true);

        loadHistory(new Date().getTime()).then((prev_messages) => {
            if (!active) {
                return;
            }

            processHistory(prev_messages);
        });

        return () => {
            setMessages([]);
            history_available.current = true;
            history_last.current = 0;
            history_scroll_height.current = 0;
            active = false;
        };
    }, [object, loadHistory, processHistory]);

    const onChatScroll = useCallback(() => {
        const { scrollTop, scrollHeight, clientHeight } = chatFeedRef.current;

        if (scrollTop + clientHeight + HEIGHT_TO_HIDE_FAB >= scrollHeight && showChevron) {
            setShowChevron(false);
        }

        if (scrollTop <= MAX_HEIGHT_TO_LOAD && history_available.current && history_last.current > 0 && !historyLoading) {
            setHistoryLoading(true);
            loadHistory(history_last.current).then(processHistory);
        }
    }, [showChevron, historyLoading, loadHistory, processHistory]);

    const scrollDown = () => {
        if (chatFeedRef.current) {
            chatFeedRef.current.scrollTop = chatFeedRef.current.scrollHeight;
        }
    };

    return (
        <>
            {chatLoading ? (
                <Box className={classes.loader}>
                    <CircularProgress/>
                </Box>
            ) : (
                <Grid
                    container className={classes.chatWrapper}
                    direction="column" alignItems="center"
                    wrap="nowrap" justifyContent="flex-end"
                >
                    <Grid item className={classes.chatFeedWrapper}>
                        <SimpleBar
                            style={{ height: '100%' }}
                            scrollableNodeProps={{
                                ref: chatFeedRef,
                                onScroll: onChatScroll
                            }}
                        >
                            {history_last.current > 0 && <ChatPlaceholder/>}
                            {messages.map((item, index) => {
                                return (item.type === CHAT_MESSAGE_TYPES.DATALABEL ? (
                                        <DateLabel chatMessage={item} key={index} />
                                    ) : (
                                        <ChatMessage chatMessage={item} key={index} />
                                    )
                                );
                            })}
                        </SimpleBar>
                        {showChevron && (
                            <Badge
                                color="primary" variant="dot" overlap="rectangular"
                                className={classes.chevronDown}
                                classes={{ anchorOriginTopRightRectangular: classes.badgeOnChevron }}
                            >
                                <Fab size="small" onClick={scrollDown}><KeyboardArrowDownIcon /></Fab>
                            </Badge>
                        )}
                    </Grid>
                    <Grid item className={classes.chatInputWrapper}>
                        <TextField
                            type="text" variant="outlined" multiline maxRows={3}
                            placeholder="Type a message..." autoFocus fullWidth
                            className={classes.chatInput}
                            inputRef={chatInputRef}
                            onKeyDown={(e: any) => {
                                if (e.shiftKey && e.keyCode === 13) { return true; }
                                if (e.keyCode === 13) { e.preventDefault(); clickOnSend().then(() => {}); }
                            }}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <IconButton onClick={clickOnSend} edge="end"><SendIcon /></IconButton>
                                    </InputAdornment>
                            }}
                        />
                    </Grid>
                </Grid>
            )}
            {openModalShare && <ShareDialog
                open={openModalShare}
                object_item={object}
                handleDisagree={handleAddPeopleDialogClose}
            />}
        </>
    );

}

export default ObjectConversation;
