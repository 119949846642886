import { ObjectItem, PrimaryClient } from "../../../Services/ObjectsService/Types";

type ObjectTreeNode = {
    id: string;
    object_id: string;
    name: string;
    icon?: string;
    properties: { [key: string]: any };
    last_active?: number;
    primary_client?: PrimaryClient;
    children?: string[];
};

export const get_tree = (list_objects: ObjectItem[]) => {
    let childIdsSet = new Set();
    let parentIdsSet = new Set();
    let treeIdsCounter: number = 0;

    const getTreeNodeId = () => ++treeIdsCounter;

    const getBaseNode = (el: ObjectItem) => ({
        id: getTreeNodeId().toString(),
        object_id: el.object_id,
        name: el.object_name,
        icon: el.icon,
        properties: el.properties
    });

    function build_tree(list_children: ObjectItem[], isChild = false, nodeParentIds:string[] = []) {
        let tree: any = [];

        // initially we go around the top-level elements that have children
        for (const el of list_children) {
            if (nodeParentIds.includes(el.object_id)) { continue; }
            if (childIdsSet.has(el.object_id) && !isChild) { continue; }
            if (el.children?.length) {
                const child_tree = build_tree(list_objects.filter(obj => el.children?.includes(obj.object_id)), true, nodeParentIds.concat([el.object_id]));

                let node: ObjectTreeNode = getBaseNode(el);
                if (Object.keys(el).includes("last_active")) { node.last_active = el.last_active; }
                if (Object.keys(el).includes("primary_client")) { node.primary_client = el.primary_client; }
                if (child_tree.length) { node.children = child_tree; }
                tree.push(node);
                if (!isChild) { parentIdsSet.add(el.object_id); }
            } else {
                // if the element is not top-level, has no children and is a child, then
                if (isChild) {
                    let node: ObjectTreeNode = getBaseNode(el);
                    if (Object.keys(el).includes("last_active")) { node.last_active = el.last_active; }
                    if (Object.keys(el).includes("primary_client")) { node.primary_client = el.primary_client; }
                    tree.push(node);
                }
            }
        }

        // add the top-level elements without children, if there are still any
        if (!isChild) {
            const childIds = [...childIdsSet];
            const parentIds = [...parentIdsSet];
            const single_objects = list_objects.filter(obj => !childIds.includes(obj.object_id) && !parentIds.includes(obj.object_id));

            for (let el of single_objects) {
                let node: ObjectTreeNode = getBaseNode(el);
                if (Object.keys(el).includes("last_active")) { node.last_active = el.last_active; }
                if (Object.keys(el).includes("primary_client")) { node.primary_client = el.primary_client; }
                tree.push(node);
            }
        }

        return tree;
    }

    // we clear the top level of possible child components
    for (const obj of list_objects) {
        obj.children?.forEach(child_id => childIdsSet.add(child_id));
    }

    return build_tree(list_objects);
};

export const search_in_tree = (baseTree: any, value: string | string[]) => {
    const getBaseNewNode = (node: ObjectTreeNode) => ({
        id: node.id,
        object_id: node.object_id,
        name: node.name,
        icon: node.icon,
        properties: node.properties
    });

    let tree: any = [];

    if (baseTree && value) {
        for (const node of baseTree) {
            if (node.children) {
                let children = search_in_tree(node.children, value);

                if (children.length) {
                    let new_node: ObjectTreeNode = getBaseNewNode(node);
                    new_node.children = children;
                    if (Object.keys(node).includes("last_active")) { new_node.last_active = node.last_active; }
                    if (Object.keys(node).includes("primary_client")) { new_node.primary_client = node.primary_client; }
                    tree.push(new_node);
                } else {
                    if ((typeof(value) === 'string' && node.name.toLowerCase().includes(value.toLowerCase())) ||
                        (typeof(value) !== 'string' && (value as string[]).some((item: string) => node.properties?.[item])))
                    {
                        let new_node: ObjectTreeNode = getBaseNewNode(node);
                        if (Object.keys(node).includes("last_active")) { new_node.last_active = node.last_active; }
                        if (Object.keys(node).includes("primary_client")) { new_node.primary_client = node.primary_client; }
                        tree.push(new_node);
                    }
                }
            } else {
                if ((typeof(value) === 'string' && node.name.toLowerCase().includes(value.toLowerCase())) ||
                    (typeof(value) !== 'string' && (value as string[]).some((item: string) => node.properties?.[item])))
                {
                    tree.push(node);
                }
            }
        }
    }

    return tree;
};
