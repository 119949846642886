import { AbstractElement, ElementType } from "./AbstractElement";
import { SUPPORTED_ELEMENT_TYPES, zoneElementObjectTypes } from "../../Constants";
import { ObjectItem, PropertyItem } from "../../../../Services/ObjectsService";
import { emptyImagePolygonItem } from "../../../Controls/ImagePolygon/ImagePolygon";
import { v4 } from "uuid";

type CreateZoneProps = {
    elementType: ElementType;
    properties: { [index: string]: PropertyItem };
    element?: AbstractElement;
    isNew?: boolean;
    objectType?: string;
    object?: ObjectItem;
};

export class ElementsFactory {
    protected parentId: string | null = null;
    constructor(parentId?: string) {
        if (parentId) {
            this.parentId = parentId;
        }
    }
    // @param elementType - element type to be created
    // @param properties - an optional array of the properties to be assigned
    // to the element
    public create(elementType: ElementType, properties: { [index: string]: PropertyItem }): AbstractElement {
        console.log("create", this.parentId);
        let AbstractElementType = SUPPORTED_ELEMENT_TYPES.get(elementType);
        if (AbstractElementType) {
            let abstractElement = new AbstractElementType(null, this.parentId);
            // Set Properties
            Object.keys(properties).forEach((key: any) => abstractElement.setProperty(key, properties[key].value));

            return abstractElement;
        } else {
            throw new Error(`Element with type ${elementType} not supported.`);
        }
    }

    public createWithObject(elementType: ElementType, object: ObjectItem): AbstractElement {
        // console.log("createWithObject");
        let AbstractElementType = SUPPORTED_ELEMENT_TYPES.get(elementType);
        if (AbstractElementType) {
            let abstractElement = new AbstractElementType(null, this.parentId);
            // Copy Object Data into the Element
            abstractElement.setObjectData(object);

            return abstractElement;
        } else {
            throw new Error(`Element with type ${elementType} not supported.`);
        }
    }

    public createWithObjectItems(objects: ObjectItem[]): AbstractElement[] {
        return objects.map((item: ObjectItem) => {
            let elementType: ElementType;

            if (ElementType[item.object_type as keyof typeof ElementType] !== undefined) {
                elementType = ElementType[item.object_type as keyof typeof ElementType];
            } else if (zoneElementObjectTypes.includes(item.object_type)) {
                elementType = ElementType.ZONE_ELEMENT;
            } else {
                elementType = ElementType.OBJECT_ELEMENT;
            }

            const element = this.createWithObject(elementType, item);
            if (ElementType[item.object_type as keyof typeof ElementType] !== undefined) {
                element.setObjectType(elementType);
            }

            return element;
        });
    }

    public createWithPaperJSItem(paperJSItem: any): AbstractElement {
        let AbstractElementType = SUPPORTED_ELEMENT_TYPES.get(paperJSItem.data.type);
        if (AbstractElementType) {
            let abstractElement = new AbstractElementType(paperJSItem, this.parentId);
            return abstractElement;
        } else {
            throw new Error(`Element with type ${paperJSItem.data.type} not supported.`);
        }
    }

    public createWithPaperJSItems(paperJSItems: any[]): AbstractElement[] {
        return paperJSItems.map((paperJSItem) => this.createWithPaperJSItem(paperJSItem));
    }

    public createZone(props: CreateZoneProps): AbstractElement {
        const { elementType, properties, element, objectType, object } = props;

        let AbstractElementType = SUPPORTED_ELEMENT_TYPES.get(elementType);
        if (AbstractElementType) {
            if (element && element.getElementType() === elementType && !objectType) {
                return element;
            }

            if (!objectType) {
                throw new Error(`Incorrect Object Type for creating new Zone Element.`);
            }

            const abstractElement = this.create(elementType, properties);
            abstractElement.setObjectType(objectType);

            if (objectType === "CameraPlanZone" && !object?.properties?.hasOwnProperty("image_polygon")) {
                abstractElement.setPropertyItem({
                    key: "image_polygon",
                    name: "ImageBounds",
                    property_id: v4(),
                    value: emptyImagePolygonItem,
                    type: "ImagePolygon",
                    readable: true,
                    writable: false,
                    visibility: ["card"],
                });
            }

            const zoneNamesByType: { [key: string]: string } = {
                Room: "New Room",
                Zone: "New Zone",
                CameraPlanZone: "New Camera Zone",
            };

            const zoneName = zoneNamesByType.hasOwnProperty(objectType) ? zoneNamesByType[objectType] : "New Zone";
            abstractElement.setObjectName(zoneName);

            if (object) {
                abstractElement.setObjectData(object);
            }

            return abstractElement;
        } else {
            throw new Error(`Element with type ${elementType} not supported.`);
        }
    }
}
