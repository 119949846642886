import React from "react";
import {CircularProgress, DialogContent, makeStyles} from "@material-ui/core";

type DialogLoaderProps = {
    state: boolean;
};

const useStyles = makeStyles((theme) => ({
    loader: {
        paddingTop: theme.spacing(2),
        textAlign: "center"
    }
}));

const DialogLoader = (props: DialogLoaderProps) => {

    const classes = useStyles();

    const {state} = props;

    if (!state) {
        return null;
    }

    return (
        <DialogContent>
            <div className={classes.loader} key={0}>
                <CircularProgress/>
            </div>
        </DialogContent>
    );

};

export default DialogLoader;
