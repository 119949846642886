import {useEffect} from "react";
import {v4} from "uuid";
import L from "leaflet";
import {MAP_SUBSCRIPTION, subMap, unsubMap} from "../../Services/ObjectsService/Subscription";
import {OriginType} from "../../Services/ObjectsService/Constants";
import {MapObject} from "../../Services/ObjectsService/Types";


type MapCallbackType = (data: MapObject) => void;
type useSubMapChangesType = (mapBounds: L.LatLngBounds | null, listObjectIds: string[], callback: MapCallbackType) => void;

export const useSubMap: useSubMapChangesType = (mapBounds, listObjectIds, callback) => {
    const northEast = mapBounds?.getNorthEast();
    const southWest = mapBounds?.getSouthWest();

    useEffect(() => {
        if (!mapBounds) {
            return;
        }

        let active = true;
        const subscriptionId = v4();

        subMap(MAP_SUBSCRIPTION, mapBounds, subscriptionId, OriginType.USER, (keys, data) => {
            if (keys.objectId !== MAP_SUBSCRIPTION || !active || !data) {
                return;
            }

            callback(data as MapObject);
        });

        return () => {
            active = false;
            unsubMap(MAP_SUBSCRIPTION, subscriptionId);
        };
    }, [northEast?.lat, northEast?.lng, southWest?.lng, southWest?.lat, listObjectIds]);
};