import React, {Fragment, useCallback, useRef} from "react";
import {useSiteMapStyles} from "../../SiteMapStyles";
import {MapPointChildProps} from "../../TypesSiteMap";
import Draggable from "react-draggable";
import Marker from "../../../../MarkerLabel/Marker";
import {mapMarkerFontSizes, mapMarkerIconSizes} from "../../../../MarkerLabel/MarkerUtils";
import {useSubPropertyValue} from "../../../../../Hooks/Subscriptions/useSubPropertyValue";

const HEADER_OFFSET = 64;

function MapPointChild(props: MapPointChildProps) {
    const {
        editMode,
        child,
        siteMapController,
        setMapDraggable,
        zoomed,
        map,
        showMarkers,
        openProperties,
        selectionHistory
    } = props;

    const classes = useSiteMapStyles({public_map: false, open: true});

    const property = child.properties["map_point"] || null;
    const markerLayerPoint = (property && property.value) ? map.latLngToContainerPoint(property.value) : null;
    const selectedObjectId = siteMapController?.getSelectObject()?.object_id || "";
    const markerDraggable = editMode && selectedObjectId === child.object_id;

    // [IM] Need to handle marker shift after deselecting
    const startX = useRef<number>(0);
    const startY = useRef<number>(0);

    // [IM] Need to handle marker shift after dragging
    const iconOffsetX = useRef<number>(0);
    const iconOffsetY = useRef<number>(0);

    const handleClickOnMarker = useCallback(() => {
        if (siteMapController) {
            if (openProperties && selectionHistory && selectionHistory.current === child.object_id) {
                openProperties();
            }
            siteMapController.setSelectObject(child);
        }
    }, [siteMapController, child]);

    const handleMarkerDragOnStart = (e: any) => {
        if (e.type === "touchstart") {
            startX.current = e.changedTouches[0].clientX;
            startY.current = e.changedTouches[0].clientY;
        } else {
            startX.current = e.clientX;
            startY.current = e.clientY;
        }

        iconOffsetX.current = markerLayerPoint.x - startX.current;
        iconOffsetY.current = markerLayerPoint.y - startY.current + HEADER_OFFSET;

        setMapDraggable(false);
    };

    const handleMarkerDragOnStop = (e: any) => {
        let {clientX, clientY} = e;

        if (e.type === "touchend") {
            clientX = e.changedTouches[0].clientX;
            clientY = e.changedTouches[0].clientY;
        }

        const endX = startX.current - clientX;
        const endY = startY.current - clientY;

        if (endX !== 0 || endY !== 0) {
            const mapPoint = map.containerPointToLatLng([clientX + iconOffsetX.current, clientY - HEADER_OFFSET + iconOffsetY.current]);
            siteMapController?.updateValueOfProperty("map_point", [mapPoint.lat, mapPoint.lng]);
        }

        siteMapController?.setSelectObject(null);
        setMapDraggable(true);
    };

    useSubPropertyValue(child.object_id, "map_point", (data) => {
        if (data.hasOwnProperty("value") && Array.isArray(data.value)) {
            siteMapController?.updateValueOfPropertyByChildId(child.object_id,"map_point", data.value);
        }
    });

    return (
        <Fragment>
            {showMarkers && markerLayerPoint && (
                <Fragment>
                    {markerDraggable ? (
                        <Draggable
                            onStart={(e: any) => handleMarkerDragOnStart(e)}
                            onStop={(e: any) => handleMarkerDragOnStop(e)}
                        >
                            <div id={child.object_id} className={classes.markersContainer}>
                                <Marker
                                    object={child}
                                    object_id={child.object_id}
                                    object_name={child.object_name}
                                    object_type={child.object_type}
                                    object_icon={child.icon}
                                    centerPointX={Math.round(markerLayerPoint.x)}
                                    centerPointY={Math.round(markerLayerPoint.y)}
                                    fontSize={mapMarkerFontSizes[zoomed]}
                                    iconSize={mapMarkerIconSizes[zoomed]}
                                    selected={child.object_id === selectedObjectId}
                                    showData={zoomed >= 17}
                                    userInfo={props.userInfo}
                                    visibilityType="map"
                                    handleClickOnMarker={handleClickOnMarker}
                                />
                            </div>
                        </Draggable>
                    ) : (
                        <Marker
                            object={child}
                            object_id={child.object_id}
                            object_name={child.object_name}
                            object_type={child.object_type}
                            object_icon={child.icon}
                            centerPointX={Math.round(markerLayerPoint.x)}
                            centerPointY={Math.round(markerLayerPoint.y)}
                            fontSize={mapMarkerFontSizes[zoomed]}
                            iconSize={mapMarkerIconSizes[zoomed]}
                            selected={child.object_id === selectedObjectId}
                            showData={zoomed >= 17}
                            userInfo={props.userInfo}
                            visibilityType="map"
                            handleClickOnMarker={handleClickOnMarker}
                        />
                    )}
                </Fragment>
            )}
        </Fragment>
    )
}

export default MapPointChild
