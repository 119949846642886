import {Box, Divider, IconButton, InputBase} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import ObjectTreeView from "../ObjectTreeView/ObjectTreeView";
import DialogLoader from "../../Dialogs/DialogUtils/DialogLoader";
import React, {Fragment, useCallback, useEffect, useState, useRef} from "react";
import {useListObjectsStyles} from "../ListObjectsStyles";
import {get_tree, search_in_tree} from "../ObjectTreeView/ObjectTreeUtils";
import {ObjectItem, Params} from "../../../Services/ObjectsService/Types";
import {getObjectById, getObjects} from "../../../Services/ObjectsService/CRUD";
import {zoneElementObjectTypes} from "../../FloorPlanEditor/Constants";
import {MAP_ZONE_ELEMENT_OBJECT_TYPE} from "../../Objects/SiteMap/Constants";
import {UserInfoType} from "../../../Hooks/useUserProfile";
import {isObjectAvailableToDisplay} from "../../../Services/ObjectsService";
import { getOrgHierarchy } from "../../../Helpers/OrganizationsHelper";

type TreeViewWithSearchProps = {
    filterObjectsIds: string[];
    handleSelectObject(object: ObjectItem | null): void;
    filterType?: string[];
    userInfo: UserInfoType;
    childrenObjectsIds?: string[];
}

const TreeViewWithSearch = (props: TreeViewWithSearchProps) => {
    const classes = useListObjectsStyles();
    const {
        filterObjectsIds,
        handleSelectObject,
        filterType,
        userInfo,
        childrenObjectsIds,
    } = props;

    const [search, setSearch] = useState<string>('');
    const [objectsTree, setObjectsTree] = useState<any>(null);
    const [dialogLoader, setDialogLoader] = useState<boolean>(false);

    const defaultTree = useRef<any>(null);
    const propertyFilter = useRef<string[]>([]);
    const orgObjects = useRef<string[]>([]);

    // search objects in objects tree
    const handleSearch = useCallback((value: string) => {
        if (!value) {
            setObjectsTree(defaultTree.current)
        } else if (value.length < search.length) {
            setObjectsTree(search_in_tree(defaultTree.current, value))
        } else {
            setObjectsTree(search_in_tree(objectsTree, value))
        }

        setSearch(value);
    }, [objectsTree]);

    const handleClearSearch = useCallback(() => {
        handleSearch('');
    }, [handleSearch]);

    const handleChangeSearch = useCallback((event: any) => {
        handleSearch(event.target.value);
    }, [handleSearch]);

    const selectObject = useCallback(async (id: string) => {
        try {
            const result = await getObjectById(id);
            handleSelectObject(result);
        } catch (e) {
            console.log("Error: ", e);
        }
    }, [handleSelectObject]);

    const loadObjects = async () => {
        orgObjects.current = await getOrgHierarchy();

        let params: Params = {};
        propertyFilter.current = [];

        if (filterType && filterType.length) {
            if (filterType.length === 1 && filterType[0] === "zone") {
                params.object_type = zoneElementObjectTypes;
            } else if (filterType.length === 1 && filterType[0] === "mapZone") {
                params.object_type = MAP_ZONE_ELEMENT_OBJECT_TYPE;
            } else if (filterType.length === 1 && filterType[0] === "contains_image") {
                propertyFilter.current.push("image");
            } else if (!filterType.includes("all_available")) {
                params.object_type = filterType
            }
        }

        params.insideHierarchy = orgObjects.current;

        if (childrenObjectsIds) {
            params.children = childrenObjectsIds;
        }

        try {
            setDialogLoader(true);

            const res = await getObjects(params);

            setDialogLoader(false);

            let _objects = (res || []).filter((_object: ObjectItem) => {
                return !filterObjectsIds.includes(_object.object_id) && isObjectAvailableToDisplay(_object);
            });

            const listObjects: ObjectItem[] = _objects.sort((a: any, b: any) => a.object_name - b.object_name);

            if (!listObjects.length) {
                defaultTree.current = null;
                setObjectsTree(null);
                return;
            }

            if (propertyFilter.current.length) {
                defaultTree.current = search_in_tree(get_tree(listObjects), propertyFilter.current);
            } else {
                defaultTree.current = get_tree(listObjects);
            }

            setObjectsTree(defaultTree.current);

            if (search) {
                handleSearch(search);
            }
        } catch (e) {
            console.log("Error: ", e);
        }
    };

    useEffect(() => {
        if (!dialogLoader) {
            void loadObjects();
        }
    }, [filterObjectsIds]);

    return (
        <Fragment>
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
                alignItems="center"
                className={classes.drawerHeader}
            >
                <Box
                    component={"div"}
                    className={[classes.searchBar, classes.searchBarFullWidth].join(" ")}
                >
                    <InputBase
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        }}
                        placeholder="Search..."
                        disabled={dialogLoader}
                        value={search}
                        onChange={handleChangeSearch}
                    />
                    <Box
                        component={"div"}
                        className={classes.searchBarActionButton}>
                        {search ? (
                            <IconButton onClick={handleClearSearch}>
                                <CloseIcon/>
                            </IconButton>
                        ) : (
                            <IconButton disabled>
                                <SearchIcon/>
                            </IconButton>
                        )}
                    </Box>
                </Box>
            </Box>
            <Divider/>
            <Box p={1}>
                {dialogLoader ? (
                    <DialogLoader state={dialogLoader}/>
                ) : ( objectsTree ? (
                    <ObjectTreeView
                        tree={objectsTree}
                        search={search}
                        userInfo={userInfo}
                        displayOptions={propertyFilter.current}
                        selectObjectHandler={selectObject}
                    />
                ) : (
                    <Box component={'div'}>
                        No nodes...
                    </Box>
                ))}
            </Box>
        </Fragment>
    )
};

export default TreeViewWithSearch;
