import React from "react";
import {Box, Grid, Typography} from "@material-ui/core";
import BlockIcon from "@material-ui/icons/Block";

const HistoryCardGuestContent = () => {
    return (
        <Box marginTop={6}>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item >
                    <BlockIcon style={{ fontSize: 70 }}/>
                </Grid>
                <Grid item>
                    <Typography variant="body2">
                        The object history is not available for Guest users. Please sign-up
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

export default HistoryCardGuestContent;
