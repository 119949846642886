import "date-fns";
import { format, parse, isValid } from "date-fns";
import React, { useEffect } from "react";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";


type DatePickerControlProps = {
    label?: string;
    date: string;
    onChange(value: string): void;
};

const DatePickerControl = (props: DatePickerControlProps) => {

    const { label, date, onChange } = props;

    const getDate = () => {
        return date ? parse(date, 'dd/MM/yyyy', new Date()) : new Date();
    };

    const [selectedDate, setSelectedDate] = React.useState<Date | null>(getDate());

    const handleDateChange = (value: Date | null) => {
        if (value && isValid(value)) {
            setSelectedDate(value);
            onChange(format(value, "dd/MM/yyyy"));
        }
    };

    useEffect(() => {
        setSelectedDate(getDate());
    }, [date]);

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
                // margin="normal"
                id="date-picker-control"
                label={label}
                format="dd/MM/yyyy"
                value={selectedDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
            />
        </MuiPickersUtilsProvider>
    );
};

export default DatePickerControl;