import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Card, CardActionArea, CardContent, Grid, Typography, withWidth} from "@material-ui/core";
import "@fortawesome/fontawesome-free/js/all";
import {EMPTY_OBJECT, getObjectById} from "../../../../Services/ObjectsService";
import {ObjectItem} from "../../../../Services/ObjectsService/Types"
import {Link, useRouteMatch} from "react-router-dom";
import ListSubObjectsOnCard from "../ListSubObjectsOnCard";
import {ObjectCardProps} from "../TypesCard";
import ListPropertiesOnCard from "../ListPropertiesOnCard";
import CardMediaComponent from "./CardContentItem/CardMediaComponent/CardMediaComponent";
import ObjectActions from "../../ObjectAction/ObjectAction";
import Skeleton from '@material-ui/lab/Skeleton';
import {getEpochTime, isUserHasAccess} from "../../../../Services/Utils";
import {AccessLevel} from "../../../../Services/ObjectsService/Constants";
import RequestAccessOnCard from "./RequestAccessOnCard/RequestAccessOnCard";
import useUpdateCameraImage from "../../../../Hooks/UpdateCameraImage/useUpdateCameraImage";
import useIsMounted from "Hooks/IsMounted/useIsMounted";
import { getLocationFromStorage } from "Components/Menu/NavigationBar/BottomNavigationBar";
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

const useStyles = makeStyles((theme) => ({
    card: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        margin: "0 auto",
        position: "relative",
        [theme.breakpoints.up("sm")]: {
            maxWidth: "320px",
        },
    },
    cardContentRoot: {
        width: "fill-available",
    },
    deleteButton: {
        marginLeft: "auto",
    },
    linkOffButton: {
        marginLeft: "auto",
    },
    shareButton: {
        margin: "auto",
    },
    editButton: {
        marginRight: "auto",
    },
    description: {
        marginTop: theme.spacing(1),
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "block",
        maxHeight: "40px",
    },
    subObjects: {
        marginTop: theme.spacing(1),
        display: 'flex',
        flexWrap: 'wrap',
    },
}));

const ObjectCard = (props: ObjectCardProps & { width: string; }) => {
    const classes = useStyles();
    const isMounted = useIsMounted();
    const [objectItem, setObjectItem] = useState<ObjectItem>(EMPTY_OBJECT);

    let {url} = useRouteMatch();

    useEffect(() => {
        if (!props.object_id) {
            return;
        }

        (async () => {
            try {
                const object = await getObjectById(props.object_id, {skipChildrenTransform: true});

                if (!isMounted()) return;

                object.children = (object.children || []).filter((child: any) => {
                    return !child.unbind || getEpochTime(child.bind) > getEpochTime(child.unbind);
                }).map((child: any) => {
                    return child.id;
                });
                setObjectItem(object);
            } catch (e) {
                console.error(e)
            }
        })();
    }, [props.object_id]);

    useUpdateCameraImage(objectItem?.object_id, objectItem?.object_type);

    return (
        <Card className={classes.card}>
            <CardActionArea
                component={Link}
                to={`${props.viewInDrawer ? "/objects" : url}/${props.object_id}${getLocationFromStorage(objectItem.object_type, props.object_id)}`}
                onClick={() => {
                    props.setObject && props.setObject(EMPTY_OBJECT)
                }}
            >
                <CardMediaComponent
                    object={objectItem}
                    userInfo={props.userInfo}
                    parentId={props.parentId}
                />
            </CardActionArea>

            <Grid container justifyContent={"flex-start"}>
                <SimpleBar style={{ height: '205px', width: '100%' }} autoHide={false}>
                    <CardContent classes={{root: classes.cardContentRoot}}>
                        {
                            !objectItem.object_id ?
                                <>
                                    <Grid container alignItems="center" justifyContent="center">
                                        <Skeleton animation="wave" height={50} width="70%"/>
                                    </Grid>
                                    <Skeleton animation="wave" height={30} width="100%"/>
                                </>
                                :
                                <>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        {objectItem.object_name}
                                    </Typography>
                                    <Typography align="left">{objectItem.object_type}</Typography>
                                    <Typography align="left" variant="body2" component="p"
                                                className={classes.description}>
                                        {objectItem.description}
                                    </Typography>
                                </>
                        }
                        <Typography
                            align="left"
                            variant="body2"
                            color="textSecondary"
                            component="div"
                            className={classes.subObjects}
                        >
                            <ListSubObjectsOnCard children={objectItem.children} objectId={objectItem.object_id}/>
                            <ListPropertiesOnCard
                                isDisabled={!isUserHasAccess(objectItem, AccessLevel.CONTROL)}
                                properties={objectItem.properties}
                                object={objectItem}
                                userInfo={props.userInfo}
                                filterVisibility={"card"}
                            />
                            <RequestAccessOnCard object={objectItem}/>
                        </Typography>
                    </CardContent>
                </SimpleBar>
            </Grid>
            <ObjectActions
                setObject={props.setObject}
                handleDeleteObject={props.handleDeleteObject}
                userInfo={props.userInfo}
                parentId={props.parentId}
                viewInDrawer={props.viewInDrawer}
                root={props.root}
                object_item={objectItem}
                cardView
            />
        </Card>
    );
};

export default withWidth()(ObjectCard);
