import {useEffect} from "react";
import {ObjectItem, ObjectsTypeKeysType, subObjectsType, unsubObjectsType} from "../../Services/ObjectsService";
import {v4} from "uuid";
import {OriginType} from "../../Services/ObjectsService/Constants";

type CallbackType = (data: ObjectItem) => void;
type UseSubObjectsTypeType = (objectsType: ObjectsTypeKeysType, callback: CallbackType) => void;

export const useSubObjectsType: UseSubObjectsTypeType = (objectsType, callback) => {

    useEffect(() => {
        if (!objectsType) {
            return;
        }

        const subscriptionId = v4();
        let active = true;

        subObjectsType(objectsType, subscriptionId, OriginType.USER, (keys, data) => {
            if (keys.objectsType !== objectsType || !active) {
                console.error("Incorrect Object Type");
                return;
            }

            callback(data as ObjectItem);
        });

        return () => {
            active = false;
            unsubObjectsType(objectsType, subscriptionId);
        }

    }, [objectsType, callback]);

}
