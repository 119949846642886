import React from "react";
import { alpha, Grid, makeStyles, Typography, Icon } from "@material-ui/core";
import { PropertyItem } from "../../../../../Services/ObjectsService";
import { checkPropertyDetailsHeaderData, isUserHasAccess } from "../../../../../Services/Utils";
import ListPropertiesOnCard from "../../../Card/ListPropertiesOnCard";
import {ObjectDetailsHeaderProps} from "../../TypesObjectDetails";
import Box from "@material-ui/core/Box";
import ObjectActions from "../../../ObjectAction/ObjectAction";
import {AccessLevel} from "../../../../../Services/ObjectsService/Constants";
import CardActivityBlock
    from "../../../Card/ObjectCard/CardContentItem/CardMediaComponent/CardActivityBlock/CardActivityBlock";
import useUpdateCameraImage from "../../../../../Hooks/UpdateCameraImage/useUpdateCameraImage";
import ObjectDetailsHeaderImg from "./ObjectDetailsHeaderImg";

const useStyles = makeStyles((theme) => ({
    mainFeaturedPostContent: {
        position: "relative",
        padding: theme.spacing(3),
        [theme.breakpoints.up("md")]: {
            padding: theme.spacing(6),
        },
    },
    propertyBlock: {
        width : "100%",
        position: "relative",
        padding: theme.spacing(1),
        backgroundColor: alpha(
            theme.palette.type !== "dark" ? theme.palette.common.white : theme.palette.common.black,
            0.75
        ),
    },
    propertyBlockBorderRadius: {
        borderTopRightRadius: theme.spacing(0.75),
    },
    activityBlock: {
        paddingLeft: 0,
        paddingRight: 0
    },
}));

export default function ObjectDetailsHeader(props: ObjectDetailsHeaderProps) {

    const {
        object,
        userInfo,
        setObject,
        parentId,
        root,
        displayModalBind,
        viewInDrawer,
        handleDeleteObject,
    } = props

    const classes = useStyles();
    makeStyles(
        (theme) => ({
            root: {
                color: theme.palette.text.primary,
            },
            icon: {
                color: theme.palette.text.disabled,
            },
        }),
        {name: "MuiChip"}
    )();

    useUpdateCameraImage(object?.object_id, object?.object_type);

    const listProperties = Object.values(object.properties)
        .reduce((headerProperties: { [key: string]: PropertyItem }, property: PropertyItem) => {
            if (property?.visibility?.includes("details") && !checkPropertyDetailsHeaderData(property)) {
                headerProperties[property.key] = property
            }
            return headerProperties
        }, {})

    return (
        <ObjectDetailsHeaderImg object={object}>
            <Grid container direction="column" style={{width:"100%", overflow:"hidden"}}>
                <Grid item xs={12} md={6}>
                    <div className={classes.mainFeaturedPostContent}>
                        <Typography align="left" component="h1" variant="h3" color="inherit" gutterBottom>
                            {/* <Icon className={object.icon || ""} fontSize="large" color="inherit" /> */}
                            {` ${object.object_name}`}
                        </Typography>
                        <Typography align="left" variant="h5" color="inherit" paragraph>
                             <Icon className={object.icon || ""} fontSize={"small"} color="inherit" />
                            {`  ${object.object_type}`}
                        </Typography>
                        <Typography align="left" variant="subtitle2" color="inherit" paragraph>
                            {object.description}
                        </Typography>
                    </div>
                </Grid>
                <ObjectActions
                    object_item={object}
                    userInfo={userInfo}
                    setObject={setObject}
                    handleDeleteObject={handleDeleteObject}
                    displayModalBind={displayModalBind}
                    parentId={parentId}
                    root={root}
                    viewInDrawer={viewInDrawer}
                />
                {Object.keys(listProperties).length > 0 && (
                    <Grid container item alignItems="center">
                        <Box className={[classes.propertyBlock, classes.propertyBlockBorderRadius].join(" ")}>
                            <ListPropertiesOnCard
                                isDisabled={!isUserHasAccess(object, AccessLevel.CONTROL)}
                                properties={listProperties}
                                object={object}
                                userInfo={userInfo}
                                filterVisibility={"details"}
                            />
                        </Box>
                    </Grid>
                )}
                <Grid item>
                    <Box className={[classes.propertyBlock, classes.activityBlock].join(" ")}>
                        <CardActivityBlock
                            objectId={object.object_id}
                            lastActivityEpoch={object.last_active}
                            objectDeviceName={object.primary_client?.name}
                            inDetails={true}
                        />
                    </Box>
                </Grid>
            </Grid>
        </ObjectDetailsHeaderImg>
    );
}
