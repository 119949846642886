import {useEffect} from "react";
import {v4} from "uuid";
import {
    EVENT_TYPE,
    subSystemEvent,
    unsubSystemEvent
} from "../../Services/SystemEventsService";
import type {SystemEventCallbackType} from "../../Services/SystemEventsService";

const useSystemEvent = (eventType: EVENT_TYPE, callback: SystemEventCallbackType) => {

    useEffect(() => {
        const SID = v4();

        subSystemEvent(SID, eventType, callback);

        return () => {
            unsubSystemEvent(SID, eventType);
        }
    }, [eventType, callback]);

};

export default useSystemEvent;
