import { KEY_LS } from "./Constants";
import type { GetStorageOptions, GetItemOptions, SetItemOptions, RemoveItemType } from "./Types"

//
// This service should be the only point of using localStorage and sessionStorage.
// I think we should update functions and use getStorage to determine actual storage.
// This function is based on REMEMBER_ME flag. REMEMBER_ME is always stored in localStorage.
// Every function should be additionally reviewed before applying this update. [AS]
//

const getStorage = (options?: GetStorageOptions): Storage => {
    const { local, session } = options || {};

    if (session && session !== local) {
        return sessionStorage;
    }

    if (local && local !== session) {
        return localStorage;
    }

    return isRememberMeEnabled() ? localStorage : sessionStorage;
};

const isRememberMeEnabled = () => {
    return !!getItem(KEY_LS.REMEMBER_ME, { parsing: true, local: true });
};

// [IM] Reason for using KEY_LS | string:
// for UserInfo structure user_id is being used as a key
// for DeviceToken - {KEY_LS.APPLE_DEVICE_TOKEN}-{user_id}

const getItem = (key: KEY_LS | string, options?: GetItemOptions) => {
    const { parsing, ...storageOptions } = options || {};
    const storage = getStorage(storageOptions);
    const data = storage.getItem(key);

    if (!data || !parsing) {
        return data;
    }

    try {
        return JSON.parse(data);
    } catch (e) {
        return null;
    }
};

const setItem = (key: KEY_LS | string, data: any, options?: SetItemOptions) => {
    const { ...storageOptions } = options || {};
    const storage = getStorage(storageOptions);
    const value = typeof data === "string" ? data : JSON.stringify(data);
    storage.setItem(key, value);
};

const removeItem = (key: KEY_LS | string, options?: RemoveItemType) => {
    const { ...storageOptions } = options || {};
    const storage = getStorage(storageOptions);
    storage.removeItem(key);
};

export {
    getItem,
    setItem,
    removeItem
}
