import React, {memo} from "react";
import {Box, Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";


type MarkerIconLightProps = {
    // object?: ObjectItem
    object_icon?: string;
    object_type: string;
    iconSize: number;
    selected: boolean;
    // visibilityType: string
    children?: React.ReactElement | React.ReactElement[] | null;
};

const MarkerIconLight = (props: MarkerIconLightProps) => {

    const {children, selected, iconSize, object_type, object_icon} = props;

    const useStyles = makeStyles({
        block: {
            marginBottom: 4
        },
        icon: {
            width: "fit-content",
            filter: "drop-shadow(1px 0px 1px #000) drop-shadow(-1px 0px 1px #000) drop-shadow(0px -1px 1px #000) drop-shadow(0px 1px 1px #000)",
            fontSize: iconSize,
        },
        iconElement: {
            cursor: "pointer",
        },
        iconSelected: {
            padding: '2px',
            border: "1px #fff solid",
            background: "rgba(0, 0, 0, .1)",
        },
    })

    const classes = useStyles()

    const iconAwesomeClass = object_icon || (object_type === "Zone"
        ? "far fa-object-group"
        : "fas fa-info-circle");

    const iconStyle = selected
        ? [classes.icon, classes.iconSelected].join(' ')
        : classes.icon

    return (
        <Box className={classes.block}>
            <Grid
                container
                item
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
                className={iconStyle}
            >
                <Grid item>
                    <Box
                        className={classes.iconElement}
                        component="span"
                    >
                        <i className={iconAwesomeClass}/>
                    </Box>
                </Grid>
                {!!children && <Grid item>
                    {children}
                </Grid>}
            </Grid>
        </Box>
    );
}


// export default MarkerIconLight;
export default memo(MarkerIconLight, (prevProps: MarkerIconLightProps, nextProps: MarkerIconLightProps) => {
    return prevProps.iconSize === nextProps.iconSize &&
        prevProps.object_icon === nextProps.object_icon &&
        prevProps.selected === nextProps.selected &&
        prevProps.children === nextProps.children
});
