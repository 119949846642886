import React, { useCallback, useEffect, useState } from "react";
import { Divider } from "@material-ui/core";
import { getObjectPropertyHistory, ObjectItem, PropertyHistoryItem, PropertyItem } from "Services/ObjectsService";
import type { SnackBarType } from "Services/NotificationService";
import { UserInfoType } from "Hooks/useUserProfile";
import HistoryCard from "./HistoryCard";
import { isGuest } from "Services/UserService/UserService";
import { useGlobalNotificationContext } from "Components/Notification/NotificationProvider";
import HistoryCardContent from "./HistoryCardContent";
import { SystemOfMeasuresType } from "Helpers/SystemOfMeasures";
import useIsMounted from "Hooks/IsMounted/useIsMounted";

export type PropertyCardProps = {
    property: PropertyItem;
    notify: SnackBarType;
    object: ObjectItem;
    parentId?: string;
    userInfo: UserInfoType;
    viewInDrawer?: boolean;
    dateRange: {
        startDate: Date;
        endDate: Date;
    }
};

const HistoryCardContainer = (props: PropertyCardProps): JSX.Element | null => {
    const { dateRange, object, property, parentId } = props;

    const systemOfMeasures: SystemOfMeasuresType = props.userInfo.user?.system_of_measures || "imperial";

    const [historyData, setHistoryData] = useState<PropertyHistoryItem[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const isMounted = useIsMounted();

    const notify: SnackBarType = useGlobalNotificationContext();

    const userIsGuest: boolean = isGuest();

    const recursiveGetHistoryData: (lastDate?: (number | null)) => Promise<void> = useCallback(async (lastDate: number | null = null) => {
        let params: any = {
            startDateRange: dateRange.startDate.getTime(),
            endDateRange: dateRange.endDate.getTime(),
        }
        if (lastDate) params.lastDate = lastDate;

        const {histories, last_date} = await getObjectPropertyHistory(object.object_id, property.key, params);

        if (!isMounted()) return;

        if (lastDate) {
            setHistoryData((prev) => [ ...histories, ...prev ]);
        } else {
            setHistoryData(histories);
        }

        if (last_date) {
            await recursiveGetHistoryData(last_date);
        }
    }, [dateRange.startDate, dateRange.endDate, object.object_id, property.key]);

    const loadData: () => Promise<void> = useCallback(async () => {
        if (userIsGuest) {
            notify.warningNotify("The object history is not available for Guest users. Please sign-up");
            return;
        }

        setLoading(true);

        try {
            await recursiveGetHistoryData();
        } catch(err: any) {
            notify.errorNotify(err.message);
        } finally {
            if (isMounted()) {
                setLoading(false);
            }
        }
    }, [recursiveGetHistoryData, userIsGuest, setLoading]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    useEffect(() => {
        console.log('HistoryCardContainer => historyData', property.name, historyData);
    }, [historyData, property.name]);

    return (
        <HistoryCard
            name={property.name}
            icon={property.icon}
        >
            <Divider/>
            <HistoryCardContent
                property={property}
                dateRange={dateRange}
                historyData={historyData}
                loading={loading}
                userIsGuest={userIsGuest}
                systemOfMeasures={systemOfMeasures}
                object={object}
                parentId={parentId}
            />
        </HistoryCard>
    );
}

export default HistoryCardContainer;
