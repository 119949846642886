import React, {Fragment, useEffect, useState} from "react";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Popover from "@material-ui/core/Popover";
import TextField from "@material-ui/core/TextField";
import {popoverPosition, useCommonStyles} from "./common/Common";
import type {SystemOfMeasuresType} from "../../Helpers/SystemOfMeasures";
import {
    convertImperialSizeValueToMetric,
    convertMetricSizeToImperialValue,
    getValueWithUnits
} from "../../Helpers/SystemOfMeasures";

type NumberControlProps = {
    propertyKey: string;
    label: string;
    value: number;
    onChangeHandler?: boolean;
    variant?: string;
    controlInputProps?: any;
    saveValue(key: string, value: string | number | boolean): void;
    units?: string;
    systemOfMeasures: SystemOfMeasuresType;
};

const NumberControl = (props: NumberControlProps) => {
    const commonClasses = useCommonStyles();

    const {propertyKey, label, value, onChangeHandler, variant, controlInputProps, saveValue, units, systemOfMeasures} = props;

    const localVariant = !variant || !["control", "popover", "propertyCard"].includes(variant) ? "control" : variant;

    const [numberComponentState, setNumberComponentState] = useState(0);
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const [isImperialControls, setIsImperialControls] = useState<boolean>(false)
    const [valueInFt, setValueInFt] = useState<number>(0)
    const [valueInInch, setValueInInch] = useState<number>(0)


    useEffect(() => {
        if (units === "mm" && systemOfMeasures === "imperial") {
            setIsImperialControls(true)

            let [ft, inch] = convertMetricSizeToImperialValue(value)
            setValueInFt(ft)
            setValueInInch(inch)
        }
    }, [value, units, systemOfMeasures])

    useEffect(() => {
        setNumberComponentState(value);
    }, [value]);

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>, key?: string) => {
        const new_value = parseFloat(event.target.value)
        if (isImperialControls) {

            if (new_value < 0) return

            let metric_value = 0;
            if (key === "ft") {
                metric_value = convertImperialSizeValueToMetric(new_value, valueInInch)
            } else {

                if (new_value > 11) return

                metric_value = convertImperialSizeValueToMetric(valueInFt, new_value)
            }
            setNumberComponentState(metric_value);
            if (onChangeHandler) {
                saveValue(event.target.name, metric_value || 0);
            }
        } else {
            setNumberComponentState(new_value);
            if (onChangeHandler) {
                saveValue(event.target.name, new_value || 0);
            }
        }
    };

    const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>, key?: string) => {
        const new_value = parseFloat(event.target.value)
        if (isImperialControls) {

            if (new_value < 0) return

            let metric_value = 0;
            if (key === "ft") {
                metric_value = convertImperialSizeValueToMetric(new_value, valueInInch)
            } else {

                if (new_value > 11) return

                metric_value = convertImperialSizeValueToMetric(valueInFt, new_value)
            }
            if (!onChangeHandler) {
                saveValue(event.target.name, metric_value || 0);
            }
        } else {
            if (!onChangeHandler) {
                saveValue(event.target.name, new_value || 0);
            }
        }
    };

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const displayPopover = Boolean(anchorEl);
    const popoverId = displayPopover ? propertyKey : undefined;

    if (localVariant === "control") {

        return isImperialControls
            ? (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            {...controlInputProps}
                            type="number"
                            value={valueInFt}
                            name={propertyKey}
                            label={`${label} (Ft)`}
                            onBlur={(e: any) => handleOnBlur(e, "ft")}
                            onChange={(e: any) => handleOnChange(e, "ft")}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            {...controlInputProps}
                            type="number"
                            value={valueInInch}
                            name={propertyKey}
                            label={`${label} (In)`}
                            onBlur={(e: any) => handleOnBlur(e, "inch")}
                            onChange={(e: any) => handleOnChange(e, "inch")}
                        />
                    </Grid>
                </Grid>
            ) : (
                <Fragment>
                    <TextField
                        {...controlInputProps}
                        type="number"
                        value={numberComponentState}
                        name={propertyKey}
                        label={label}
                        onBlur={handleOnBlur}
                        onChange={handleOnChange}
                    />
                </Fragment>
            );
    }

    return (
        <React.Fragment>
            <Typography component="div">
                {variant === "popover" ? (
                    <Grid
                        component="label"
                        container
                        alignItems="center"
                        spacing={1}
                        onClick={handlePopoverOpen}
                        className={commonClasses.cursorPointer}
                        wrap="nowrap"
                    >
                        <Grid item>
                            <Box fontSize="small">{label}</Box>
                        </Grid>
                        <Grid item>
                            <Box fontSize="small">{value}</Box>
                        </Grid>
                    </Grid>
                ) : (
                    <Typography noWrap gutterBottom variant="h3" onClick={handlePopoverOpen}>
                        {`${getValueWithUnits(systemOfMeasures, value, units)}`}
                    </Typography>
                )}
                <Popover
                    id={popoverId}
                    open={displayPopover}
                    anchorEl={anchorEl}
                    onClose={handlePopoverClose}
                    {...popoverPosition}
                >
                    {
                        isImperialControls
                            ? (
                                <Fragment>
                                    <Box p={1}>
                                        <TextField
                                            {...controlInputProps}
                                            type="number"
                                            value={valueInFt}
                                            name={propertyKey}
                                            label={`${label} (Ft)`}
                                            onBlur={(e: any) => handleOnBlur(e, "ft")}
                                            onChange={(e: any) => handleOnChange(e, "ft")}
                                        />
                                    </Box>
                                    <Box p={1}>
                                        <TextField
                                            {...controlInputProps}
                                            type="number"
                                            value={valueInInch}
                                            name={propertyKey}
                                            label={`${label} (In)`}
                                            onBlur={(e: any) => handleOnBlur(e, "inch")}
                                            onChange={(e: any) => handleOnChange(e, "inch")}
                                        />
                                    </Box>
                                </Fragment>

                            ) : (
                                <Box p={1}>
                                    <TextField
                                        {...controlInputProps}
                                        type="number"
                                        value={numberComponentState}
                                        name={propertyKey}
                                        label={label}
                                        onBlur={handleOnBlur}
                                        onChange={handleOnChange}
                                    />
                                </Box>
                            )
                    }
                </Popover>
            </Typography>
        </React.Fragment>
    );
};

export {NumberControl};
