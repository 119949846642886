import React, {useCallback, useEffect} from "react";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Box, Button, Grid, useMediaQuery, useTheme} from "@material-ui/core";
import {ButtonProps} from "@material-ui/core/Button/Button";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            margin: theme.spacing(1),
            position: 'relative',
        },
        buttonProgress: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        },
        button: {
            margin: theme.spacing(1),
        },
    }),
);

type ButtonWithLoaderProps = {
    loading: boolean;
    onClick(): void;
    value: string;
    buttonProps: ButtonProps;
};

const ButtonWithLoader = (props: ButtonWithLoaderProps) => {
    const classes = useStyles();

    const { onClick, value, buttonProps } = props;

    const [loading, setLoading] = React.useState(false);

    const theme = useTheme();
    const downXs = useMediaQuery(theme.breakpoints.down("xs"));

    const onClickButton = useCallback(() => {
        onClick();
        setLoading(true);
    }, [onClick, setLoading]);

    useEffect(() => {
        setLoading(props.loading);
    }, [props.loading]);

    return (
        <Grid container justifyContent="center">
            <Grid container item justifyContent="center">
                <Box className={classes.wrapper}>
                    <Button
                        {...buttonProps}
                        disabled={loading}
                        className={classes.button}
                        onClick={onClickButton}
                        variant="contained"
                        size={downXs ? "small" : "medium"}
                    >
                        {value}
                    </Button>
                    {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </Box>
            </Grid>
        </Grid>
    );
};

export default ButtonWithLoader;

