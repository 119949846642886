import React from "react";
import {Grid, Link, Typography} from "@material-ui/core";
import WelcomeDialogContentBodyImg from "../WelcomeDialogContentBodyImg";
import {SlideType} from "../WelcomeDialogContent";


export const Slide1 = (props: SlideType) => {
    const handleClickOnLink = (event: any)=>{
        event.preventDefault();
        props.handlePageChange(event);
    }
    return (
        <>
            <Grid
                container
                spacing={3}
                direction="row"
                justifyContent="center"
                alignItems="flex-start">
                <Grid item xs={4} sm={3}>
                    <WelcomeDialogContentBodyImg
                        src={"/img/welcome_dialog/img_slide_1_dark.svg"}
                        themePaletteType="dark"
                    />
                    <WelcomeDialogContentBodyImg
                        src={"/img/welcome_dialog/img_slide_1_light.svg"}
                        themePaletteType="light"
                    />
                </Grid>
            </Grid>
            <Typography
                variant={props.xsSize ? "h6" : "h3"}
                align="center"
                gutterBottom={true}>
                Welcome to LocalMachines!
            </Typography>
            <Typography
                variant={props.xsSize ? "body1" : "h5"}
                align="center"
                gutterBottom={true}>
                It’s time to build the smart world around you.
            </Typography>
            <Typography
                variant={props.xsSize ? "body1" : "h5"}
                align="center"
                gutterBottom={true}>
                Click <Link onClick={handleClickOnLink}>Next</Link> to learn how.
            </Typography>
        </>)

}

