import {useMemo} from "react";
import {CAMERA_TYPES, CAMERA_ZONE_TYPES} from "../../Services/ObjectsService/Constants";
import {CAMERA_STREAM_TIMEOUT, getPlanInfo} from "../../Services/PurchasesService";
import useRecursiveTimeout from "../useRecursiveTimeout";
import {getCachedData, getObjectById} from "../../Services/ObjectsService/CRUD";
import {checkIsOnline, copy} from "../../Services/Utils";

type UseUpdateCameraImageType = (objectId: string | undefined, objectType: string | undefined) => void;
const AVAILABLE_TYPES = [...CAMERA_TYPES, ...CAMERA_ZONE_TYPES];

const useUpdateCameraImage: UseUpdateCameraImageType = (objectId, objectType) => {

    const delay = useMemo(() => {
        if (!objectType || !AVAILABLE_TYPES.includes(objectType) || !objectId) {
            return null;
        }

        const product_id = getPlanInfo().name.toLocaleLowerCase();
        return product_id !== "free" ? CAMERA_STREAM_TIMEOUT.pro : CAMERA_STREAM_TIMEOUT.free;
    }, [objectType, objectId]);


    useRecursiveTimeout(async () => {
        if (!objectType || !AVAILABLE_TYPES.includes(objectType) || !objectId) {
            return;
        }

        const getCameraId = async () => {
            if (CAMERA_ZONE_TYPES.includes(objectType)) {
                const cameraZoneObject = await getObjectById(objectId, {skipChildrenTransform: true});
                // [IM] Do not return camera id if points are not set.
                return (cameraZoneObject?.properties?.image_polygon?.value?.points?.length && cameraZoneObject?.properties?.image_polygon?.value?.object_id) || null;
            }

            return objectId;
        };

        try {
            const cameraId = await getCameraId();

            if (!cameraId) {
                return;
            }

            const camera = await getObjectById(cameraId, {skipChildrenTransform: true});

            if (!checkIsOnline(camera?.last_active)) {
                return;
            }

            await getCachedData(cameraId, copy(camera?.properties?.image));
        } catch (e) {
            console.error(e);
        }
    }, delay);
};

export default useUpdateCameraImage;
