import React from "react";
import {Redirect} from "react-router-dom";
import {UserInfoType} from "../../../Hooks/useUserProfile";
import {isGuest} from "../../../Services/UserService";

const DEFAULT_PAGE_LINK: string = process.env.REACT_APP_DEFAULT_PAGE_LINK || "";

type CheckAuthComponentProps = {
    children: React.ReactElement | React.ReactElement[];
    userInfo: UserInfoType;
    redirectTo?: string;
    nonAuth?: boolean;
};

const CheckAuthComponent = (props: CheckAuthComponentProps) => {

    const { children, userInfo, redirectTo, nonAuth } = props;

    if ((nonAuth && userInfo.checkAuthToken() && !isGuest()) || (!nonAuth && !userInfo.checkAuthToken())) {
        return (
            <Redirect to={redirectTo || DEFAULT_PAGE_LINK}/>
        );
    }

    return (
        <>
            {children}
        </>
    )
};


export default CheckAuthComponent;