import React, {useEffect, useMemo, useRef, useState} from "react";
import {Line, LineChart, Tooltip, XAxis, YAxis} from "recharts";
import {PropertyItem, getObjectPropertyHistory} from "../../../../../Services/ObjectsService";
import type {SystemOfMeasuresType} from "../../../../../Helpers/SystemOfMeasures";
// import Badge from "@material-ui/core/Badge";
import {CircularProgress, Grid, Typography, Box} from "@material-ui/core";
import BlockIcon from '@material-ui/icons/Block';
import {add, format, sub} from "date-fns";
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import TooltipContent from "./ChartTooltipContent";
import {wait} from "../../../../../Services/Utils";
import {convertValueToTargetMetricSystem} from "../../../../../Helpers/SystemOfMeasures";
// import {getPlanInfo} from "../../../../../Services/PurchasesService";
// import {
//     useUpdateGlobalDialogActionsContext,
//     useUpdateGlobalDialogContentActionsContext
// } from "../../../../../GlobalContext/GlobalContext";
import {isGuest} from "../../../../../Services/UserService";
import {useGlobalNotificationContext} from "../../../../Notification/NotificationProvider";
import type {SnackBarType} from "../../../../../Services/NotificationService";

type ChartProps = {
    objectId: string
    property: PropertyItem
    system_of_measures?: SystemOfMeasuresType;
}

function Chart(props: ChartProps) {

    const {objectId, property, system_of_measures} = props;

    const [loader, setLoader] = useState(false);
    const [data, setData] = useState<any>([]);
    const [startDateRange, setStartDateRange] = useState<Date>(sub(new Date(), {hours: 24}));
    const [endDateRange, setEndDateRange] = useState<Date>(new Date());

    const nextDataChunk = useRef<boolean>(false)

    // const product_id = getPlanInfo().product_id
    // const setGlobalDialog = useUpdateGlobalDialogActionsContext()
    // const setGlobalDialogContent = useUpdateGlobalDialogContentActionsContext()
    const notify: SnackBarType = useGlobalNotificationContext();

    const userIsGuest = useMemo(() => {
        return isGuest();
    }, [isGuest()]);

    const createData = (x: number, y: number) => {
        return {x, y};
    };

    const getHistoryData = (data: any) => {
        return data?.length > 0
            ? data.map((history: any) => {
                return createData(
                    Number(history.epoch_time),
                    property.units && system_of_measures
                        ? convertValueToTargetMetricSystem(system_of_measures, history.value, property.units)
                        : history.value);
            }).sort((a: any, b: any) => a.x - b.x)
            : [];
    }


    const loadData = async (lastDate: number | null = null) => {
        try {
            let params: any = {
                startDateRange: startDateRange.getTime(),
                endDateRange: endDateRange.getTime(),
            }
            if (lastDate) params.lastDate = lastDate

            return await getObjectPropertyHistory(objectId, property.key, params);
        } catch (err) {
            console.log(err);
            if (isGuest()) {
                notify.warningNotify("The object history is not available for Guest users. Please sign-up")
            }
            return { histories: [], last_date: null, count: 0 };
        }
    }

    // current_temperature
    const loadHistoryData = async () => {
        if (!objectId || !property.key) {
            return;
        }

        let last_date: number | null = null

        // check if we have any data already.
        // Perhaps we just need to load the missing part of the data,
        // and not everything completely

        if (data.length > 1) {
            const averageDataSpan = data[data.length - 1].x - data[data.length - 2].x
            // increase the average a little to avoid additional queries (* 4)
            const predicate = (d: any) => d.x > endDateRange.getTime() - averageDataSpan * 4 && d.x < endDateRange.getTime() + averageDataSpan * 4
            let endDate = data.find(predicate)
            if (endDate) last_date = data[0].x
        }

        // initial chunk of information
        if (!last_date) {
            setLoader(true)
        }

        // {
        //     count: count,
        //         histories: histories,
        //     last_id: histories.length && histories.length !== count ? histories[histories.length - 1]._id : null
        // }

        let history_data = await loadData(last_date)

        // immediately displayed to users
        if (last_date) {
            setData((prev: any) => [...getHistoryData(history_data.histories), ...prev]);
        } else {
            setData(getHistoryData(history_data.histories))
        }
        if (!last_date) {
            setLoader(false)
        }

        const loadHistory = async (lastDate: number | null): Promise<null> => {
            if (!lastDate) {
                return null;
            }

            try {
                const {histories, last_date} = await loadData(lastDate);
                setData((prev: any) => [...getHistoryData(histories), ...prev]);
                if (!last_date) {
                    return null;
                }
                await wait(50);
                return await loadHistory(last_date);
            } catch (e) {
                console.error(e);
                return null;
            }
        };

        await loadHistory(history_data.last_date)

        // nextDataChunk.current = true
        //
        // while (history_data.length && nextDataChunk.current) {
        //     last_date = history_data.slice(-1)[0].epoch_time
        //     history_data = await loadData(last_date)
        //     setData((prev: any) => [...getHistoryData(history_data), ...prev]);
        // }
    }

    // const handleOnOpenDateChange = () => {
    //     if (product_id === "free") {
    //         setGlobalDialog("UpgradeToProDialog")
    //         setGlobalDialogContent("property_history_pro")
    //     }
    // }

    useEffect(() => {
        const todayMidnight = new Date().setHours(0, 0, 0, 0)
        if (endDateRange.getTime() > todayMidnight) {
            const newDateRange = add(new Date(), {minutes: 1})
            const newData = createData(Number(newDateRange), Number(property.value))
            setData([...data, newData])
            setEndDateRange(newDateRange);
        }
    }, [property.value]);

    useEffect(() => {
        loadHistoryData()
    }, []);

    useEffect(() => {
        if (data.length) {
            const startDate = data[0].x
            if (startDateRange.getTime() < startDate) {
                nextDataChunk.current = false
                loadHistoryData()
            }
        }
    }, [startDateRange])

    useEffect(() => {
        if (data.length) {
            const endDate = data[data.length - 1].x
            if (endDateRange.getTime() > endDate) {
                nextDataChunk.current = false
                loadHistoryData()
            }
        }
    }, [endDateRange])

    if (loader) {
        return (
            <Box pt={2}>
                <CircularProgress/>
            </Box>
        );
    }

    if (userIsGuest) {
        return (
            <Box marginTop={6}>
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item >
                        <BlockIcon style={{ fontSize: 70 }}/>
                    </Grid>
                    <Grid item>
                        <Typography variant="body2">
                            The object history is not available for Guest users. Please sign-up
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        )
    }


    return (
        <>
            <LineChart
                width={250}
                height={200}
                data={data}
                margin={{
                    top: 20, right: 0, bottom: 0, left: -20
                }}
            >
                <XAxis
                    allowDataOverflow
                    type="number"
                    scale="time"
                    domain={[() => startDateRange, () => endDateRange]}
                    dataKey="x"
                    name="Time"
                    tickFormatter={(value: any) => format(value, "HH:mm")}
                    interval={"preserveStartEnd"}

                />
                <YAxis
                    domain={['auto', 'auto']}
                />
                <Tooltip cursor={{strokeDasharray: "3 3"}} content={<TooltipContent/>}/>
                <Line
                    name={property.name}
                    type="monotone"
                    dataKey="y"
                    stroke="#8884d8"
                    dot={false}/>
            </LineChart>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container spacing={5}>
                    <Grid item xs={6}>
                        {/*{product_id === "free" ? (*/} {/* [IM 15/06/2022] temporary equal to Pro level */}
                        {/*    <Badge*/}
                        {/*        anchorOrigin={{*/}
                        {/*            vertical: 'top',*/}
                        {/*            horizontal: 'left',*/}
                        {/*        }}*/}
                        {/*        badgeContent={"Pro"}*/}
                        {/*        color="primary"*/}
                        {/*        overlap={"rectangular"}*/}
                        {/*    >*/}
                        {/*        <DateTimePicker*/}
                        {/*            autoOk*/}
                        {/*            ampm={false}*/}
                        {/*            disableFuture*/}
                        {/*            value={startDateRange}*/}
                        {/*            open={false}*/}
                        {/*            onOpen={handleOnOpenDateChange}*/}
                        {/*            onChange={(value: Date | null) => value && setStartDateRange(value)}*/}
                        {/*            format="MM/dd HH:mm"*/}
                        {/*            maxDate={endDateRange}*/}
                        {/*        />*/}
                        {/*    </Badge>*/}
                        {/*) : (*/}
                            <DateTimePicker
                                autoOk
                                ampm={false}
                                disableFuture
                                value={startDateRange}
                                onChange={(value: Date | null) => value && setStartDateRange(value)}
                                format="MM/dd HH:mm"
                                maxDate={endDateRange}
                            />
                        {/*)}*/}
                    </Grid>
                    <Grid item xs={6}>
                        {/*{product_id === "free" ? (*/} {/* [IM 15/06/2022] temporary equal to Pro level* /}
                        {/*    <Badge*/}
                        {/*        anchorOrigin={{*/}
                        {/*            vertical: 'top',*/}
                        {/*            horizontal: 'left',*/}
                        {/*        }}*/}
                        {/*        badgeContent={"Pro"}*/}
                        {/*        color="primary"*/}
                        {/*        overlap={"rectangular"}*/}
                        {/*    >*/}
                        {/*        <DateTimePicker*/}
                        {/*            autoOk*/}
                        {/*            ampm={false}*/}
                        {/*            disableFuture*/}
                        {/*            value={endDateRange}*/}
                        {/*            open={false}*/}
                        {/*            onOpen={handleOnOpenDateChange}*/}
                        {/*            onChange={(value: Date | null) => value && setEndDateRange(value)}*/}
                        {/*            format="MM/dd HH:mm"*/}
                        {/*            minDate={startDateRange}*/}
                        {/*        />*/}
                        {/*    </Badge>*/}
                        {/*) : (*/}
                            <DateTimePicker
                                autoOk
                                ampm={false}
                                disableFuture
                                value={endDateRange}
                                onChange={(value: Date | null) => value && setEndDateRange(value)}
                                format="MM/dd HH:mm"
                                minDate={startDateRange}
                            />
                        {/*)}*/}
                    </Grid>
                </Grid>
            </MuiPickersUtilsProvider>
        </>
    )
}

export default React.memo(Chart);
