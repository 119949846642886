import React, {Fragment} from "react";
import IconButton from "@material-ui/core/IconButton";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import {useUserProfileStyles} from "../../../UserProfileStyles";
import {UserPropertyItemActionProps} from "../../../UserProfileTypes";

const UserPropertyItemAction = (props: UserPropertyItemActionProps) => {
    const classes = useUserProfileStyles();

    const {editMode, disabled, handleAgree, handleDisagree, handleChangeMode} = props;

    const onClickAgree = () => {
        handleAgree();
    };

    const onClickDisagree = () => {
        handleDisagree();
    };

    const onClickChangeMode = () => {
        handleChangeMode();
    };

    return (
        <ListItemSecondaryAction>
            {editMode ?
                <Fragment>
                    <IconButton edge="end" onClick={onClickAgree}>
                        <CheckIcon fontSize="small" className={classes.iconSecondaryColor}/>
                    </IconButton>
                    <IconButton edge="end" onClick={onClickDisagree}>
                        <CloseIcon fontSize="small" className={classes.iconSecondaryColor}/>
                    </IconButton>
                </Fragment>
                :
                <IconButton edge="end" onClick={onClickChangeMode} disabled={disabled}>
                    <ArrowForwardIosIcon fontSize="small" className={classes.iconSecondaryColor}/>
                </IconButton>
            }
        </ListItemSecondaryAction>);
};

export default UserPropertyItemAction;