import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles({
    chatMessageShadow: {
        margin: '8px 4px'
    }
});

const PLACEHOLDERS = [1, 2, 3];

const ChatPlaceholder = () => {

    const classes = useStyles();

    return (
        <>
            {PLACEHOLDERS.map((item) => {
                return (
                    <Box key={item} className={classes.chatMessageShadow}>
                        <Skeleton width="40%" />
                        <Skeleton />
                    </Box>
                );
            })}
        </>
    );

}

export default ChatPlaceholder;
