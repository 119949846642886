import React, { useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
    Collapse,
    IconButton,
    Link as LinkToExternal,
    List,
    ListItem,
    ListItemIcon,
    ListItemText
} from "@material-ui/core";
import {
    Description as DescriptionIcon,
    ExpandLess as ExpandLessIcon,
    ExpandMore as ExpandMoreIcon,
    Help as HelpIcon,
    Slideshow as SlideshowIcon
} from "@material-ui/icons";

import { useUpdateGlobalDialogActionsContext } from "GlobalContext/GlobalContext";

import { HELP_MENU_ITEM, HELP_MENU_ITEM_CONTENT } from "Constants";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        nested: {
            paddingLeft: theme.spacing(4)
        }
    })
);

const HELP_ITEMS: { [key: string]: { [key: string]: string | React.ReactNode } } = {
    [HELP_MENU_ITEM.USERS_GUIDES]:      {text: "User’s Guides",      icon: <DescriptionIcon/>},
    [HELP_MENU_ITEM.LIMITATIONS]:       {text: "Limitations",        icon: <DescriptionIcon/>},
    [HELP_MENU_ITEM.ACKNOWLEDGEMENTS]:  {text: "Acknowledgements",   icon: <DescriptionIcon/>},
    [HELP_MENU_ITEM.WHATS_NEW]:         {text: "What's New",         icon: <DescriptionIcon/>},
    [HELP_MENU_ITEM.SUPPORT]:           {text: "Support",            icon: <HelpIcon/>},
};

type ListMenuHelpItemProps = {
    menuOpenToggled(): void;
};

const ListMenuHelpItem = (props: ListMenuHelpItemProps) => {
    const { menuOpenToggled } = props;

    const setGlobalDialog = useUpdateGlobalDialogActionsContext();

    const classes = useStyles();

    const [openHelpSubMenu, setOpenHelpSubMenu] = useState<boolean>(false);

    const onClickHelpMenu = () => {
        setOpenHelpSubMenu(prev => !prev);
    };

    const handleIntroClick = () => {
        setGlobalDialog("WelcomeDialog")
        menuOpenToggled();
    };

    return (
        <>
            <ListItem button key="Help" onClick={onClickHelpMenu}>
                <ListItemIcon><HelpIcon/></ListItemIcon>
                <ListItemText primary="Help"/>
                <IconButton size={'small'} edge={'end'}>
                    {openHelpSubMenu ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                </IconButton>
            </ListItem>
            <Collapse in={openHelpSubMenu} timeout="auto">
                <List disablePadding>
                    <ListItem button className={classes.nested} onClick={handleIntroClick}>
                        <ListItemIcon><SlideshowIcon/></ListItemIcon>
                        <ListItemText primary="Intro"/>
                    </ListItem>
                    {HELP_MENU_ITEM_CONTENT.map((help_item, index) => (
                        <ListItem button className={classes.nested} key={index}>
                            <ListItemIcon>{HELP_ITEMS[help_item.key].icon}</ListItemIcon>
                            <LinkToExternal href={help_item.link as string} color="inherit" underline="none" rel="noreferrer" target="_blank">
                                <ListItemText primary={HELP_ITEMS[help_item.key].text}/>
                            </LinkToExternal>
                        </ListItem>
                    ))}
                </List>
            </Collapse>
        </>
    )
};

export default ListMenuHelpItem;
