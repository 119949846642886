import React from "react";
import { Menu, MenuItem } from "@material-ui/core";
import { Link } from "react-router-dom";

type CreateChatPopupProps = {
    anchorElConv: null | HTMLElement;
    handleClose(): void;
    handleOpenModalDirect(): void;
    handleCreateConversation(): void;
    additionalItems?: { [key: string]: string }[];
};

const CreateChatPopup = (props: CreateChatPopupProps) => {

    const { anchorElConv, handleClose, handleOpenModalDirect, handleCreateConversation, additionalItems } = props;

    return (
        <Menu id="conv-menu" anchorEl={anchorElConv} keepMounted open={Boolean(anchorElConv)} onClose={handleClose} style={{ zIndex: 1310 }}>
            <MenuItem component={Link} to={`/conversations/create-object`} onClick={handleCreateConversation}>
                Group Chat
            </MenuItem>
            <MenuItem onClick={handleOpenModalDirect}>
                Direct Message
            </MenuItem>
            {(additionalItems || []).map((item, index) => (
                <MenuItem key={index} component={Link} to={item.link} onClick={handleClose}>
                    {item.text}
                </MenuItem>
            ))}
        </Menu>
    );
};

export default CreateChatPopup;
