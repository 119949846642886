import React, {ReactNode} from "react";
import Checkbox from '@material-ui/core/Checkbox';
import {FormControl, InputLabel, ListItemText, MenuItem, Select} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

type MultipleSelectControlElementProps = {
    propertyKey: string;
    label: string;
    value: string[];
    disabled: boolean;
    valueOptionsList: string[];
    handleChange(event: React.ChangeEvent<{ value: unknown }>): void;
    settings?: ReactNode;
}

const MultipleSelectControlElement = (props: MultipleSelectControlElementProps) => {
    const {propertyKey, label, value, valueOptionsList, disabled, handleChange, settings} = props;

    return (
        <div>
            <Typography component="div">
                <Grid component="label" container alignItems="center"  wrap="nowrap">
                    {settings && <Grid xs={1} item container direction="row" justifyContent="center" alignItems="center">
                        {settings}
                    </Grid>}
                    <Grid xs={settings ? 11 : 12} item >
                        <FormControl variant="outlined" fullWidth={true}>
                            <InputLabel id={propertyKey + "-label"}>{label}</InputLabel>
                            <Select
                                labelId={propertyKey + "-label"}
                                id={propertyKey}
                                multiple
                                value={value}
                                onChange={handleChange}
                                displayEmpty={true}
                                label={label}
                                disabled={disabled}
                                renderValue={(selected) => {return  (selected as string[]).join(', ')}}
                                autoWidth={true}
                            >
                                {valueOptionsList.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        <Checkbox checked={value.includes(option)} />
                                        <ListItemText primary={option} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Typography>
        </div>
    );
}

export default MultipleSelectControlElement
