export enum APN_NOTIFICATION_TYPE {
    PROPERTY,
    OBJECT,
    CHAT_MESSAGE
}

export enum NOTIFICATION_TYPE {
    SUCCESS,
    ERROR,
    WARNING
}

export enum SUBSCRIPTION_TYPE {
    APN_NOTIFICATION = "APN_NOTIFICATION",
    SERVICE_NOTIFICATION = "SERVICE_NOTIFICATION"
}
