import React from "react";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import {getVersion, getSiteName} from "../../../Services/Utils";
import {APP_URL} from "../../../Services/Constants";

const Copyright = () => {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {"Copyright © "}
            <Link color="inherit" href={APP_URL} rel="noreferrer" target="_blank">
                {getSiteName() + ", Telemetry LLC"}
            </Link>{" "}
            {new Date().getFullYear()}
            {`.  ${getVersion()}`}
        </Typography>
    );
}

export default Copyright;
