import {v4} from "uuid";
import AsyncLock from "async-lock";
import {differenceInSeconds, isSameDay} from "date-fns";
import {
    clearDetectionObjects,
    deleteLocalObject,
    getLocalObjectById,
    getLocalObjects,
    getServiceLifeNotificationObjects,
    postLocalObject,
    postLocalObjectPermissions,
    unBindLocalObjectChildren,
    updateLocalObjectProperties,
    clearLocalDB,
    clearLocalObjectCache,
    getLocalObjectByNativeIdentificationDataAndObjectType
} from "./ObjectsLocalService";
import {
    deleteRemoteObject,
    getRemoteCachedData,
    getRemoteObjectById,
    getRemoteObjectPermissions,
    getRemoteObjects,
    subscribeRemoteObjectsData,
    unsubscribeRemoteObjectsData,
    subscribeRemotePublicObjects,
    unsubscribeRemotePublicObjects,
} from "./ObjectsRemoteService"
import {
    pubPropertiesChanges,
    pub as pub_object_events
} from "./ObjectsService/Subscription";
import {
    isAvailableNativeCamera,
    publishPropertiesChangesToNative,
} from "./ObjectsService/ObjectsNativeService/ObjectsNativeService";
import {
    cleanPermissionObjectFromRootKey,
    copy,
    getDataExpirationTime,
    getEpochTime,
    getObjectFields,
    isActiveAccessLevel,
    isObjectPublic,
    isObjectsHasEqualFieldsValue,
    isUserHasAccess,
    mergeChildren,
    mergeObjectChildren,
    mergeObjectNativeIdData,
    mergePermissions,
    mergeProperties,
    needUpdateUnknownFields,
    wait
} from "./Utils";
import {
    deleteChildObjects,
    deleteObject,
    deleteObjectChildren,
    deleteObjects,
    getCachedData,
    getObjectById,
    getObjectPermissions,
    getObjectPropertyHistory,
    getObjects,
    postObject,
    postObjectAccessRequest,
    postObjectChildren,
    postObjectKeepAlive,
    postObjectNotificationState,
    postObjectPermissions,
    postObjectProperties,
    postObjectToRemote,
    postSeveralObjectChildren,
    processKeepAlive,
    saveObjectPropertiesToS3,
    updateObjectProperties
} from "./ObjectsService/CRUD"
import {
    BodyObjectItem,
    ChildItemType,
    ObjectItem,
    OriginPropsType,
    Params,
    ResponseOLSType,
    SubObjectItem
} from "./ObjectsService/Types";
import {isSignedIn} from "./AuthenticationService";
import {
    AccessLevel,
    OBJECT_TYPE,
    OBJECT_TYPE_WITH_DISABLED_SHARING,
    OriginType,
    SubscriptionType
} from "./ObjectsService/Constants";
import {isDevice, isiDeviceHost, updateDeviceCameras, addingNewCamera, registerDevice} from "./DeviceService";
import {getDeviceId, getUsers, getUserId, isGuest, getClientId} from "./UserService";
import {closeService, initService} from "./DatabaseService";
import {
    NOTIFICATION_TYPE,
    pubApnNotification,
    pubServiceNotification
} from "./NotificationService";
import {SHARING_APPLE_HOME, DISPLAYING_APPLE_HOME, LIST_OF_PROPERTIES_STORED_ON_S3} from "./Constants";

const syncObjectLock = new AsyncLock();
const lockSyncWithARemoteObjectByID = new AsyncLock({maxPending: 0});
const publicObjectLock = new AsyncLock({maxPending: 0});

let lastPublicRequestTime = 0;

let subscriptionId: string;

const syncData: { [key: string]: boolean | number } = {
    finished: false,
    last_sync: 0
};

export function getDefaultChildType(parent_type: string) {
    switch (parent_type) {
        case "Site":
            return "Zone";
        case "Building":
            return "Floor";
        case "Zone":
        case "Floor":
            return "Room";
        case "Room":
            return "IPCamera";
        case "Machine":
            return "Service";
        case "Document":
            return "Document";
        default:
            return "IPCamera";
    }
}

// [IM] kept it here for historical reference
// export function getAvailableChildTypes(parent_type: string) {
//     switch (parent_type) {
//         case "":
//             return [];
//         case "Site":
//         case "Building":
//             return ["Floor", "Zone", "Room", "CameraPlanZone", "CameraMapZone"];
//         case "Zone":
//         case "Floor":
//             return ["Room", "CameraPlanZone", "CameraMapZone"];
//         case "Room":
//             return ["IPCamera", "CameraPlanZone", "CameraMapZone"];
//         case "Machine":
//             return ["IPCamera", "Service"];
//         default:
//             return ["IPCamera"];
//     }
// }

const checkIsSyncAvailableToContinue = async (userIdOnStart: string) => {
    const currentUserId = getUserId();

    if (currentUserId !== userIdOnStart) {
        return Promise.reject("User changed. Sync declined");
    }

    if (isGuest()) {
        return Promise.reject("User is Guest. Sync declined");
    }

    return;
};

export const forceSync = async () => {
    if (!syncData.finished || differenceInSeconds(Date.now(), syncData.last_sync as number) <= 60 || !isSignedIn() || isGuest()) {
        console.log("Sync declined");
        return;
    }

    const checkSyncAvailable = checkIsSyncAvailableToContinue.bind(null, getUserId());

    syncData.finished = false;

    console.log("Start sync Data");
    console.time("Sync Process");
    console.time("> Finish Sync - Step 1");

    let _fromRemote = 0;
    let _toRemote_1 = 0;
    let _toRemote_2 = 0;

    const dateNow = Date.now();
    const expirationTime = getDataExpirationTime(dateNow);

    const listUserIds: string[] = [getUserId()];

    let allLocalObjects: { [key: string]: ObjectItem };
    let allRemoteObjects: ObjectItem[];

    const getLocalObjectsDict = async () => {
        try {
            await checkSyncAvailable();

            const params: any = {
                includeDeleted: true,
                publicRequest: true,
                skipChildrenTransform: true,
            };

            const localObjects = (await getLocalObjects(params)) as ObjectItem[];
            const localObjectsById: { [key: string]: ObjectItem } = {};

            localObjects.forEach((object) => {
                if (!isUserHasAccess(object, AccessLevel.READ)) {
                    return;
                }

                localObjectsById[object.object_id] = object;
            });

            return localObjectsById;
        } catch (e) {
            return Promise.reject(e);
        }
    };

    const getListRemoteObjects = async (initLastId: string | null): Promise<ObjectItem[]> => {
        console.log("getListRemoteObjects", initLastId);
        try {
            await checkSyncAvailable();

            const params: Params = {
                by_page: true,
                limit: 100,
                getDeleted: true,
                publicRequest: false,
            };

            if (initLastId) {
                params.last_id = initLastId;
            }

            let {last_id, objects} = await getRemoteObjects(params);

            if (last_id) {
                await wait(100);
                const result = await getListRemoteObjects(last_id);
                objects = [...objects, ...result];
            }

            return objects;

        } catch (e) {
            return Promise.reject(e);
        }
    };

    const findLocalObjectByIdentificationData = async (remoteObject: ObjectItem, localObjectsById: { [key: string]: ObjectItem }) => {
        if (!Array.isArray(remoteObject.native_identification_data)) {
            return null;
        }

        // search object by native data
        let minMatchedPercent = 0.5;
        let localObject = null;

        const listLocalObjects = Object.values(localObjectsById);

        for (const storedObject of Object.values(listLocalObjects)) {
            if (remoteObject.object_type !== storedObject.object_type || !storedObject.native_identification_data?.length) {
                continue;
            }

            const matchedSerialNumbers = storedObject.native_identification_data.filter((element) => {
                return remoteObject?.native_identification_data?.includes(element);
            });

            if (!matchedSerialNumbers?.length) {
                continue;
            }

            const matchedPercent = matchedSerialNumbers.length / Math.min(storedObject.native_identification_data.length, remoteObject.native_identification_data.length);

            if (matchedPercent > minMatchedPercent) {
                minMatchedPercent = matchedPercent;
                localObject = storedObject;
            }
        }

        if (!localObject) {
            return null;
        }

        const localObjectIdToDelete = localObject.object_id;
        localObject.object_id = remoteObject.object_id;
        mergeObjectChildren(remoteObject, localObject);

        try {
            responseHandlerOLS(await deleteLocalObject(localObjectIdToDelete, new Date().getTime(), {forceDelete: true}, OriginType.REMOTE), {origin: OriginType.REMOTE});
        } catch (e) {
        }

        delete localObjectsById[localObjectIdToDelete];

        return localObject;
    };

    const performUpdatingObject = async (localObject: ObjectItem | null, remoteObject: ObjectItem) => {
        //Attention! By making changes to this function change the code in the function forceSyncObjectFromRemoteDatabaseById
        const objectId = remoteObject.object_id;
        const localObjectUpdated = getEpochTime(localObject?.updated);
        const remoteObjectUpdated = getEpochTime(remoteObject?.updated);
        const updated = localObjectUpdated > remoteObjectUpdated ? localObjectUpdated : remoteObjectUpdated;
        const currentUser: string = getUserId();
        let objectFieldsToRemoteUpdate: BodyObjectItem = {};
        let objectFieldsToLocalUpdate: BodyObjectItem = {};

        try {
            if (remoteObject.permissions?.private_acl?.[currentUser]?.access_level === "revoked") {
                const date = remoteObjectUpdated || dateNow;
                responseHandlerOLS(await deleteLocalObject(objectId, date, {forceDelete:true}, OriginType.REMOTE), {origin: OriginType.REMOTE});
                return;
            }

            if ((localObject && localObject.deleted && !remoteObject.deleted) || (!localObject && remoteObject.deleted && remoteObjectUpdated <= expirationTime)) {
                const date = localObjectUpdated || dateNow;
                if (remoteObject.owner_id === currentUser) {
                    deleteRemoteObject(objectId, date).then(() => {});
                }
                return;
            }

            if (localObject && !localObject.deleted && remoteObject.deleted) {
                const date = remoteObjectUpdated || dateNow;
                responseHandlerOLS(await deleteLocalObject(objectId, date, {}, OriginType.REMOTE), {origin: OriginType.REMOTE});
                return;
            }

            if (localObject && localObject.deleted && localObjectUpdated < expirationTime) {
                const date = localObjectUpdated || dateNow;
                if (localObject.owner_id === currentUser) {
                    deleteRemoteObject(objectId, date).then(() => {});
                }
                responseHandlerOLS(await deleteLocalObject(objectId, date, {}, OriginType.LOCAL), {origin: OriginType.LOCAL});
                return;
            }

            if (localObject && localObjectUpdated > remoteObjectUpdated) {
                const localObjectFields = getObjectFields(localObject);
                const remoteObjectFields = getObjectFields(remoteObject);

                if (!isObjectsHasEqualFieldsValue(remoteObjectFields, localObjectFields, true)) {
                    objectFieldsToRemoteUpdate = {...localObjectFields};
                }
                objectFieldsToRemoteUpdate.updated = localObjectUpdated;
            }

            if ((!localObject && (!remoteObject.deleted || (remoteObject.deleted && remoteObjectUpdated > expirationTime))) || (localObject && localObjectUpdated < remoteObjectUpdated)) {
                const localObjectFields = getObjectFields(localObject);
                const remoteObjectFields = getObjectFields(remoteObject);

                if (!isObjectsHasEqualFieldsValue(localObjectFields, remoteObjectFields, true)) {
                    objectFieldsToLocalUpdate = {...remoteObjectFields};
                }
                objectFieldsToLocalUpdate.updated = remoteObjectUpdated;
            }

            // update UNKNOWN fields if another object has correct value
            if (needUpdateUnknownFields(remoteObject?.object_type, localObject?.object_type)) {
                objectFieldsToRemoteUpdate.object_type = localObject?.object_type;
            }

            if (needUpdateUnknownFields(remoteObject?.object_name, localObject?.object_name)) {
                objectFieldsToRemoteUpdate.object_name = localObject?.object_name;
            }

            if (needUpdateUnknownFields(localObject?.object_type, remoteObject?.object_type)) {
                objectFieldsToLocalUpdate.object_type = remoteObject?.object_type;
            }

            if (needUpdateUnknownFields(localObject?.object_name, remoteObject?.object_name)) {
                objectFieldsToLocalUpdate.object_name = remoteObject.object_name;
            }

            // Merge Native_id information
            const mergedNativeId = mergeObjectNativeIdData(remoteObject?.native_id, localObject?.native_id);
            if (Object.keys(mergedNativeId).length !== Object.keys(remoteObject?.native_id || {}).length) {
                objectFieldsToRemoteUpdate.native_id = mergedNativeId;
            }
            if (Object.keys(mergedNativeId).length !== Object.keys(localObject?.native_id || {}).length) {
                objectFieldsToLocalUpdate.native_id = mergedNativeId;
            }

            // Merge favorites information
            const localObjectFavorites = localObject?.favorites?.[currentUser];
            const remoteObjectFavorites = remoteObject?.favorites?.[currentUser];
            const localObjectFavoritesUpdated = getEpochTime(localObjectFavorites?.updated);
            const remoteObjectFavoritesUpdated = getEpochTime(remoteObjectFavorites?.updated);

            if (remoteObjectFavorites && remoteObjectFavoritesUpdated > localObjectFavoritesUpdated) {
                objectFieldsToLocalUpdate.favorites = {[currentUser]: remoteObjectFavorites};
            }
            if (localObjectFavorites && remoteObjectFavoritesUpdated < localObjectFavoritesUpdated) {
                objectFieldsToRemoteUpdate.favorites = {[currentUser]: localObjectFavorites};
            }

            // Merge Object Children information
            const {
                mergedChildren,
                updatedInputChildren,
                updatedCurrentChildren
            } = mergeChildren(localObject?.children as any, remoteObject?.children as any);
            const unbindRemovedResult = unbindRemovedChildren(mergedChildren, updatedInputChildren, updatedCurrentChildren);

            if (unbindRemovedResult.input.length) {
                objectFieldsToRemoteUpdate.children = unbindRemovedResult.input;
            }
            if (unbindRemovedResult.current.length) {
                objectFieldsToLocalUpdate.children = unbindRemovedResult.current;
            }

            // Merge Object Properties
            if (localObjectUpdated > remoteObjectUpdated) {
                const mergedPropertiesResult = mergeProperties(remoteObject?.properties, localObject?.properties);
                if (Object.keys(mergedPropertiesResult.updatedOriginal).length) {
                    objectFieldsToRemoteUpdate.properties = mergedPropertiesResult.updatedOriginal;
                }
                if (Object.keys(mergedPropertiesResult.updatedInput).length) {
                    objectFieldsToLocalUpdate.properties = mergedPropertiesResult.updatedInput;
                }
            } else {
                const mergedPropertiesResult = mergeProperties(localObject?.properties, remoteObject?.properties);
                if (Object.keys(mergedPropertiesResult.updatedOriginal).length) {
                    objectFieldsToLocalUpdate.properties = mergedPropertiesResult.updatedOriginal;
                }
                if (Object.keys(mergedPropertiesResult.updatedInput).length) {
                    objectFieldsToRemoteUpdate.properties = mergedPropertiesResult.updatedInput;
                }
            }

            // MERGE PERMISSIONS
            const mergedPermissionsResult = mergePermissions(localObject?.permissions, remoteObject?.permissions);
            if (Object.keys(mergedPermissionsResult?.updatedOriginal || {}).length) {
                objectFieldsToLocalUpdate.permissions = mergedPermissionsResult.updatedOriginal;
            }
            if (Object.keys(mergedPermissionsResult?.updatedInput || {}).length) {
                objectFieldsToRemoteUpdate.permissions = mergedPermissionsResult.updatedInput;
            }

            const remoteEncryptionKey = remoteObject?.encryption_key || null;
            // Encryption key
            if (localObject?.encryption_key !== remoteEncryptionKey) {
                objectFieldsToLocalUpdate.encryption_key = remoteEncryptionKey;
            }

            const remoteOwnerId = remoteObject?.owner_id;
            // Owner Id
            if (!localObject?.owner_id && remoteOwnerId) {
                objectFieldsToLocalUpdate.owner_id = remoteOwnerId;
            }

            if (localObject && Object.keys(objectFieldsToRemoteUpdate).length && (!getEpochTime(localObject.expiration_time) || getEpochTime(localObject.expiration_time) > dateNow)) {
                const encryptionKey = localObject.encryption_key || null;
                delete localObject.encryption_key;

                const options = {
                    clientId: getClientId(),
                    encryptionKey: encryptionKey,
                    saveKeyRequired: false,
                    isPublic: isObjectPublic(localObject.permissions),
                    isOwner: localObject.owner_id === getUserId(),
                    permissions: localObject?.permissions || null
                }

                const origin = {
                    origin: OriginType.LOCAL,
                    clientId: getClientId()
                }

                _toRemote_1++;

                if (isUserHasAccess(localObject, AccessLevel.WRITE)) {
                    postObjectToRemote({
                        ...objectFieldsToRemoteUpdate,
                        updated: updated,
                        object_id: objectId
                    }, options, origin).then(() => {}).catch((e) => { console.error("Sync Step 1.1 Error: ", e); });
                } else if (objectFieldsToRemoteUpdate.favorites) {
                    const { favorites, source, object_type, native_identification_data } = objectFieldsToRemoteUpdate;

                    postObjectToRemote({
                        object_id: objectId,
                        favorites,
                        source,
                        object_type,
                        native_identification_data,
                    }, options, origin).then(() => {}).catch((e) => { console.error("Sync Step 1.2 Error: ", e); });
                }
            }

            if (remoteObject && Object.keys(objectFieldsToLocalUpdate).length) {
                _fromRemote++;

                responseHandlerOLS(await postLocalObject({
                    ...objectFieldsToLocalUpdate,
                    updated: updated,
                    object_id: objectId
                }, {includeDeleted: true, saveEncryptionKey: objectFieldsToLocalUpdate.hasOwnProperty("encryption_key")}), {origin: OriginType.REMOTE});

                for (const propertyKey of LIST_OF_PROPERTIES_STORED_ON_S3) {
                    if (objectFieldsToLocalUpdate?.properties?.hasOwnProperty(propertyKey)) {
                        const property = copy(objectFieldsToLocalUpdate.properties[propertyKey]);
                        try {
                            getRemoteCachedData(objectId, property).then(() => {});
                        } catch (e) {}
                    }
                }
            }

            return;
        } catch (e) {
            console.log("Error: ", e);
        }
    };

    const unbindRemovedChildren = (children: ChildItemType[], input: ChildItemType[], current: ChildItemType[]) => {
        for (const child of children) {
            const childId = child.id;
            if (getEpochTime(child.bind) < getEpochTime(child.unbind)) {
                continue;
            }

            const local = allLocalObjects[childId];
            const remote = allRemoteObjects.find((object) => object.object_id === childId);
            const localDeleted = local?.deleted;
            const remoteDeleted = remote?.deleted;
            const localUpdated = getEpochTime(local?.updated);
            const remoteUpdated = getEpochTime(remote?.updated);
            const own = remote?.owner_id === getUserId() || local?.owner_id === getUserId();

            if (own && ((localDeleted && remoteDeleted) || (localUpdated > remoteUpdated && localDeleted) || (remoteUpdated > localUpdated && remoteDeleted))) {
                for (const childrenList of [input, current]) {
                    const index = childrenList.findIndex((item) => childId === item.id);
                    if (index === -1) {
                        childrenList.push({id: childId, unbind: dateNow});
                    } else {
                        childrenList[index].unbind = dateNow;
                    }
                }
            }
        }

        return {
            input: input,
            current: current
        };
    };

    const addUserIds = (userIds: string[]) => {
        for (const userId of userIds) {
            if (listUserIds.includes(userId)) {
                continue;
            }
            listUserIds.push(userId);
        }
    };

    try {
        const remoteObjects = await getListRemoteObjects(null);
        const localObjectsById = await getLocalObjectsDict();

        allLocalObjects = copy(localObjectsById);
        allRemoteObjects = copy(remoteObjects);

        // sync from remote
        for (const remoteObject of remoteObjects) {
            await checkSyncAvailable();

            const objectId = remoteObject.object_id;
            let localObject: ObjectItem | null = localObjectsById[objectId] || null;

            if (!localObject) {
                localObject = await findLocalObjectByIdentificationData(remoteObject, localObjectsById);
            }

            const remoteUsers = Object.keys(remoteObject?.permissions?.private_acl || {});
            const localUsers = Object.keys(localObject?.permissions?.private_acl || {});

            addUserIds(remoteUsers);
            addUserIds(localUsers);

            await performUpdatingObject(localObject, remoteObject);

            delete localObjectsById[objectId];
        }

        console.timeEnd("> Finish Sync - Step 1");
        console.log("Items from Remote: " + _fromRemote + ". Items to Remote: " + _toRemote_1);
        console.time("> Finish Sync - Step 2");

        // sync to remote
        for (const localObject of Object.values(localObjectsById)) {
            if (isObjectPublic(localObject.permissions)) {
                try {
                    await forceSyncObjectFromRemoteDatabaseById(localObject.object_id);
                } catch (e: any) {
                    if (e.status && e.status === 404) {
                        responseHandlerOLS(await deleteLocalObject(localObject.object_id, dateNow, {forceDelete: true}, OriginType.REMOTE), {origin: OriginType.REMOTE});
                        console.log("Unavailable public " + localObject.object_type + " " + localObject.object_name + " removed");
                    } else {
                        console.error(e);
                    }
                }

                continue;
            }

            if ((localObject.deleted && getEpochTime(localObject.updated) < expirationTime) || localObject.owner_id !== getUserId()) {
                responseHandlerOLS(await deleteLocalObject(localObject.object_id, dateNow, {forceDelete: true}, OriginType.LOCAL), {origin: OriginType.LOCAL});
                continue;
            }

            // [IM] Skip sending to remote objects with exceeded expiration time
            if (getEpochTime(localObject.expiration_time) && getEpochTime(localObject.expiration_time) < dateNow) {
                continue;
            }

            await checkSyncAvailable();

            const localUsers = Object.keys(localObject?.permissions?.private_acl || {});
            addUserIds(localUsers);

            const encryptionKey = localObject.encryption_key || null;
            delete localObject.encryption_key;

            _toRemote_2++;

            postObjectToRemote(localObject, {
                clientId: getClientId(),
                encryptionKey: encryptionKey,
                saveKeyRequired: true,
                isPublic: isObjectPublic(localObject.permissions),
                isOwner: localObject.owner_id === getUserId(),
                permissions: localObject?.permissions || null
            }, {
                origin: OriginType.LOCAL,
                clientId: getClientId()
            }).then(() => {}).catch((e) => { console.error("Sync Step 2 Error: ", e); });
        }

        console.timeEnd("> Finish Sync - Step 2");
        console.log("Items from Local: " + _toRemote_2);
        console.time("> Finish Sync - Step 3");

        await checkSyncAvailable();

        try {
            await getUsers({user_ids: listUserIds});
        } catch (e) {
            console.error(e);
        }

        console.timeEnd("> Finish Sync - Step 3");
    } catch (e) {
        console.error("Sync Error: ", e);
    }

    syncData.finished = true;
    syncData.last_sync = Date.now();

    console.timeEnd("Sync Process");
};

export const forceSyncObjectById = async (objectId: string): Promise<ObjectItem> => {
    try {
        return await syncObjectLock.acquire(objectId, async (): Promise<ObjectItem> => {
            try {
                return await getLocalObjectById(objectId, {includeDeleted: true, includePublic: true});
            } catch (e) {
                const remoteObject = await getRemoteObjectById(objectId, {includeDeleted: true});
                return responseHandlerOLS(await postLocalObject(remoteObject, {includeDeleted: true, saveEncryptionKey: true}), {origin: OriginType.REMOTE});
            }
        });
    } catch (e) {
        return Promise.reject(e);
    }
};

export const forceSyncObjectFromRemoteDatabaseById = async (objectId: string): Promise<ObjectItem> => {

    const checkSyncAvailable = checkIsSyncAvailableToContinue.bind(null, getUserId());

    //Attention! By making changes to this function change the code in the function performUpdatingObject
    try {
        return await lockSyncWithARemoteObjectByID.acquire(objectId, async (): Promise<ObjectItem> => {
            const remoteObject = await getRemoteObjectById(objectId, {includeDeleted: true});
            const localObject = await getLocalObjectById(objectId, {includeDeleted: true, includePublic: true, skipChildrenTransform: true});

            const localObjectUpdated = getEpochTime(localObject?.updated);
            const remoteObjectUpdated = getEpochTime(remoteObject?.updated);
            const updated = localObjectUpdated > remoteObjectUpdated ? localObjectUpdated : remoteObjectUpdated;
            const currentUser: string = getUserId();
            let objectFieldsToLocalUpdate: BodyObjectItem = {};
            const dateNow = Date.now();
            const expirationTime = getDataExpirationTime(dateNow);

            if (localObject && !localObject.deleted && remoteObject.deleted) {
                const date = remoteObjectUpdated || dateNow;
                return responseHandlerOLS(await deleteLocalObject(objectId, date, {}, OriginType.REMOTE), {origin: OriginType.REMOTE});
            }

            if (localObject && localObject.deleted && localObjectUpdated < expirationTime) {
                const date = localObjectUpdated || dateNow;
                return responseHandlerOLS(await deleteLocalObject(objectId, date, {}, OriginType.LOCAL), {origin: OriginType.LOCAL});
            }

            if ((!localObject && (!remoteObject.deleted || (remoteObject.deleted && remoteObjectUpdated > expirationTime))) || (localObject && localObjectUpdated < remoteObjectUpdated)) {
                const localObjectFields = getObjectFields(localObject);
                const remoteObjectFields = getObjectFields(remoteObject);

                if (!isObjectsHasEqualFieldsValue(localObjectFields, remoteObjectFields, true)) {
                    objectFieldsToLocalUpdate = {...remoteObjectFields};
                }
                objectFieldsToLocalUpdate.updated = remoteObjectUpdated;
            }

            // update UNKNOWN fields if another object has correct value
            if (needUpdateUnknownFields(localObject?.object_type, remoteObject?.object_type)) {
                objectFieldsToLocalUpdate.object_type = remoteObject?.object_type;
            }

            if (needUpdateUnknownFields(localObject?.object_name, remoteObject?.object_name)) {
                objectFieldsToLocalUpdate.object_name = remoteObject.object_name;
            }

            // Merge Native_id information
            const mergedNativeId = mergeObjectNativeIdData(remoteObject?.native_id, localObject?.native_id);
            if (Object.keys(mergedNativeId).length !== Object.keys(localObject?.native_id || {}).length) {
                objectFieldsToLocalUpdate.native_id = mergedNativeId;
            }

            // Merge favorites information
            const localObjectFavorites = localObject?.favorites?.[currentUser];
            const remoteObjectFavorites = remoteObject?.favorites?.[currentUser];
            const localObjectFavoritesUpdated = getEpochTime(localObjectFavorites?.updated);
            const remoteObjectFavoritesUpdated = getEpochTime(remoteObjectFavorites?.updated);

            if (remoteObjectFavorites && remoteObjectFavoritesUpdated > localObjectFavoritesUpdated) {
                objectFieldsToLocalUpdate.favorites = {[currentUser]: remoteObjectFavorites};
            }

            // Merge Object Children information
            const {
                // mergedChildren,
                // updatedInputChildren,
                updatedCurrentChildren
            } = mergeChildren(localObject?.children as any, remoteObject?.children as any);
            // const unbindRemovedResult = unbindRemovedChildren(mergedChildren, updatedInputChildren, updatedCurrentChildren);

            if (updatedCurrentChildren.length) {
                objectFieldsToLocalUpdate.children = updatedCurrentChildren;
            }

            // Merge Object Properties
            if (localObjectUpdated > remoteObjectUpdated) {
                const mergedPropertiesResult = mergeProperties(remoteObject?.properties, localObject?.properties);
                if (Object.keys(mergedPropertiesResult.updatedInput).length) {
                    objectFieldsToLocalUpdate.properties = mergedPropertiesResult.updatedInput;
                }
            } else {
                const mergedPropertiesResult = mergeProperties(localObject?.properties, remoteObject?.properties);
                if (Object.keys(mergedPropertiesResult.updatedOriginal).length) {
                    objectFieldsToLocalUpdate.properties = mergedPropertiesResult.updatedOriginal;
                }
            }

            // MERGE PERMISSIONS
            const mergedPermissionsResult = mergePermissions(localObject?.permissions, remoteObject?.permissions);
            if (Object.keys(mergedPermissionsResult?.updatedOriginal || {}).length) {
                objectFieldsToLocalUpdate.permissions = mergedPermissionsResult.updatedOriginal;
            }

            const remoteEncryptionKey = remoteObject?.encryption_key || null;
            // Encryption key
            if (localObject?.encryption_key !== remoteEncryptionKey) {
                objectFieldsToLocalUpdate.encryption_key = remoteEncryptionKey;
            }

            const remoteOwnerId = remoteObject?.owner_id;
            // Owner Id
            if (!localObject?.owner_id && remoteOwnerId) {
                objectFieldsToLocalUpdate.owner_id = remoteOwnerId;
            }

            if (remoteObject && Object.keys(objectFieldsToLocalUpdate).length) {
                await checkSyncAvailable();

                const res = responseHandlerOLS(await postLocalObject({
                    ...objectFieldsToLocalUpdate,
                    updated: updated,
                    object_id: objectId
                }, {includeDeleted: true, saveEncryptionKey: objectFieldsToLocalUpdate.hasOwnProperty("encryption_key")}), {origin: OriginType.REMOTE});

                for (const propertyKey of LIST_OF_PROPERTIES_STORED_ON_S3) {
                    if (objectFieldsToLocalUpdate?.properties?.hasOwnProperty(propertyKey)) {
                        const property = copy(objectFieldsToLocalUpdate.properties[propertyKey]);
                        try {
                            getRemoteCachedData(objectId, property).then(() => {});
                        } catch (e) {}
                    }
                }

                return res;
            }
            return remoteObject
        });
    } catch (e) {
        return Promise.reject(e);
    }
};

export const registerObjects = async () => {
    await initService();

    if (isiDeviceHost()) {
        //iOS (iPhone, iPod or iPad)
        //WKWebView
        registerDevice("ObjectService");
    }

    subscriptionId = v4();
    syncData.finished = true;

    initCronClearDetectionObjects();

    cronServiceLifeNotifications();

    subscribeAllObjects();

    void forceSync();
};

export const deregisterObjects = () => {
    syncData.finished = false;
    syncData.last_sync = 0;

    unsubscribeAllObjects();
    closeService();
};

export const responseHandlerOLS = (response: ResponseOLSType, originProps: OriginPropsType): ObjectItem => {

    let {object, events} = response;

    if (events.includes(SubscriptionType.NEW) && object.object_type === "IPCamera") {
        void addingNewCamera(object, originProps?.parentId || "");
    }

    const deviceId = getDeviceId();
    if (events.includes(SubscriptionType.CHILDREN) && isiDeviceHost() && object.native_id?.[deviceId] === deviceId) {
        void updateDeviceCameras(object);
    }

    if (!Array.isArray(events) || events.length === 0) {
        return object;
    }

    events = events.filter((item) => {
        return item !== originProps.event;
    });

    if (events.includes(SubscriptionType.PROPERTIES)) {
        pubPropertiesChanges(object.object_id, object.properties, object, {
            ...originProps,
            event: SubscriptionType.PROPERTIES
        });

        void publishPropertiesChangesToNative(object);
        // TODO here should be called function for send data to NativeService [AS]
        events = events.filter((item) => {
            return item !== SubscriptionType.PROPERTIES;
        });
    }

    if (events.length !== 0) {
        void pub_object_events(events, object, originProps);
    }

    return object;
};

const initCronClearDetectionObjects = () => {
    setTimeout(async () => {
        try {
            let _objects = await clearDetectionObjects();

            let now = new Date().getTime();

            if (_objects.length) {
                for (const _objectItem of _objects) {
                    const expirationTime = _objectItem.properties?.expiration_time?.value;
                    if (expirationTime && new Date(expirationTime).getTime() <= now) {
                        responseHandlerOLS(await deleteLocalObject(_objectItem.object_id, now, {}, OriginType.LOCAL), {origin: OriginType.LOCAL});
                        responseHandlerOLS(await unBindLocalObjectChildren((_objectItem.observer_id as string), [_objectItem.object_id], now), {origin: OriginType.LOCAL});
                    }
                }
            }

            initCronClearDetectionObjects();

        } catch (err) {
            console.log("Error: ", err);
            initCronClearDetectionObjects();
        }
    }, 60000);
};

const cronServiceLifeNotifications = async () => {
    try {
        let _objects = await getServiceLifeNotificationObjects();

        let currentTime = new Date().getTime();
        _objects.forEach((object) => {
            Object.values(object.service_life_notifications || []).forEach((serviceLifeNotification) => {
                serviceLifeNotification.notifications.forEach((notification: {alert_time: number, message: string}) => {
                    if (isSameDay(notification.alert_time, currentTime)) {
                        pubServiceNotification({
                            message: notification.message,
                            type: NOTIFICATION_TYPE.WARNING
                        });
                    }
                });
            });
        });

        initCronServiceLifeNotifications();

    } catch (e) {
        console.log("Error: ", e);
        initCronServiceLifeNotifications();
    }
};

const initCronServiceLifeNotifications = () => {
    setTimeout(cronServiceLifeNotifications, 3_600_000);
};

const subscribeAllObjects = () => {
    subscribeRemoteObjectsData(subscriptionId, async (objectId, data: any, originProps) => {
        if (!objectId) {
            return;
        }

        try {
            let res;
            if (originProps.event === SubscriptionType.DELETE) {
                if (originProps.epoch) {
                    delete originProps.event;
                    res = await postLocalObject({
                        object_id: objectId,
                        updated: originProps.epoch,
                        deleted: true
                    }, { includeDeleted: true });
                }
            } else if (originProps.event === SubscriptionType.PROPERTIES) {
                if (data) {
                    delete originProps.event;

                    let epoch = 0;

                    for (const key in data) {
                        if (!data.hasOwnProperty(key)) {
                            continue;
                        }

                        if (data[key].value_epoch && data[key].value_epoch > epoch) {
                            epoch = data[key].value_epoch;
                        }
                    }

                    epoch = epoch || Date.now();

                    res = await updateLocalObjectProperties(objectId, data, epoch, originProps.origin);
                }
            } else if (originProps.event === SubscriptionType.PERMISSIONS) {
                let remoteObject: any = {};

                try {
                    remoteObject = await getObjectPermissions(data.object_id);
                } catch (e: any) {
                    if (e.status && e.status === 403) {
                        const unavailableObject = await getObjectById(data.object_id, {skipChildrenTransform: true}); // [IM] (!) used for displaying log below only
                        responseHandlerOLS(await deleteLocalObject(objectId, Date.now(), {forceDelete: true}, OriginType.REMOTE), {origin: OriginType.REMOTE});
                        console.log("Unavailable " + unavailableObject.object_type + " " + unavailableObject.object_name + " removed");
                    } else {
                        console.error(e);
                    }

                    return;
                }

                const cleanedPermissions: any = cleanPermissionObjectFromRootKey(remoteObject.permissions);

                for (const id of remoteObject.list_object_id || []) {
                    // [IM] excluded because data.object_id was processed in scope of getObjectPermissions
                    if (id === data.object_id) {
                        continue;
                    }

                    await forceSyncObjectById(id);
                    const localResponse = await postLocalObjectPermissions(id, cleanedPermissions);
                    responseHandlerOLS(localResponse, originProps);
                    // [IM] responseHandlerOLS at the bottom will not be called as res is still undefined
                }
            } else {
                delete originProps.event;
                res = await postLocalObject(data, { includeDeleted: true, saveEncryptionKey:  data.hasOwnProperty("encryption_key")});
                if (data.first_detection && !res.object.deleted) {
                    res.events.push(SubscriptionType.NOTIFICATION_DETECT_OBJECT)
                }
            }

            if (res) {
                responseHandlerOLS(res, originProps);
            }
        } catch (e) {
            console.error("subscribeRemoteObjectsData-CALLBACK", e);
        }
    });
};

const unsubscribeAllObjects = () => {
    unsubscribeRemoteObjectsData(subscriptionId);
};

const subscribeAllPublicObjects = (subscriptionId: string, tileNames: string[]) => {
    subscribeRemotePublicObjects(subscriptionId, tileNames, async (objectId, object: any, originProps) => {
        if (!objectId) {
            return;
        }

        try {
            let res;
            if (originProps.event === SubscriptionType.PROPERTIES) {
                if (object.properties) {
                    delete originProps.event;

                    let epoch = 0;

                    for (const key in object.properties) {
                        if (!object.properties.hasOwnProperty(key)) {
                            continue;
                        }

                        if (object.properties[key].value_epoch && object.properties[key].value_epoch > epoch) {
                            epoch = object.properties[key].value_epoch;
                        }
                    }

                    epoch = epoch || Date.now();

                    res = await updateLocalObjectProperties(objectId, object.properties, epoch, originProps.origin);
                }
            } else {

                delete originProps.event;
                res = await postLocalObject(object, { includeDeleted: true, saveEncryptionKey:  object.hasOwnProperty("encryption_key")});
                if (object.first_detection && !res.object.deleted) {
                    res.events.push(SubscriptionType.NOTIFICATION_DETECT_OBJECT)
                }
            }

            if (res) {
                responseHandlerOLS(res, originProps);
            }
        } catch (e) {}
    });
};

const unsubscribeAllPublicObjects = async (subscriptionId: string, tileNames: string[]) => {
    unsubscribeRemotePublicObjects(subscriptionId, tileNames);
};

export const getExistingObjectIds = async (listIds?: string[]) => {
    if (!Array.isArray(listIds)) {
        return [];
    }

    try {
        const objects = await getLocalObjects({children: listIds});
        return (objects as ObjectItem[]).filter((child) => {
            return isObjectAvailableToDisplay(child);
        }).map((child) => {
            return child.object_id;
        });
    } catch (e) {
        return [];
    }
};

// Handle Apple notifications

// A situation is possible when the detected object did not reach the local database
// In this case, we must forcibly get it from the remote base. (forceSyncObjectById)

const handleRemoteNotification = async (notification: any) => {
    pubApnNotification(notification).then(() => {});
};

const getPublicObjects = async (params: {geo_bounds_limit: number[]}) => {
    const { geo_bounds_limit } = params;
    const epoch = Date.now();
    if (epoch - lastPublicRequestTime <= 5000) {
        return;
    }
    try {
        await publicObjectLock.acquire("PUBLIC_OBJECTS", async () => {
            lastPublicRequestTime = epoch;
            const { objects } = await getObjects({
                only_public: true,
                by_page: true,
                limit: 10,
                geo_bounds_limit: geo_bounds_limit
            });

            for (const object of objects) {
                try {
                    // const res =
                    await postLocalObject(object, {saveEncryptionKey: true}, OriginType.REMOTE);
                    // console.log("res", res);
                } catch (e) {
                    console.error("Something went wrong. Public object not saved.")
                }
            }
        });
    } catch (e) {

    }
};

const isAppleHomeObject = (object: ObjectItem) => {
    return OBJECT_TYPE_WITH_DISABLED_SHARING.includes(object.object_type) && object.source === "apple_home";
};

const isAllowedObjectSharing = (object: ObjectItem) => {
    return !isAppleHomeObject(object) || SHARING_APPLE_HOME;
};

const getListAllowedForSharingObjectIds = async (listObjectIds: string[] = []) => {
    try {
        return (await getObjects({children: listObjectIds || []})).filter((object: ObjectItem) => {
            return isAllowedObjectSharing(object);
        }).map((object: ObjectItem)=>{
            return object.object_id;
        });
    } catch (e) {
        console.error(e);
        return [];
    }

};

const isUnknownObject = (object: ObjectItem | SubObjectItem) => {
    return object?.object_type === OBJECT_TYPE.UNKNOWN;
};

const isAllowedDisplayAppleHomeObject = (object: ObjectItem | SubObjectItem) => {
    if (DISPLAYING_APPLE_HOME) {
        return true;
    }


    if (!(isiDeviceHost() || isDevice()) && isAppleHomeObject(object as any)) {
        return false;
    }

    return true;
};

const isObjectAvailableToDisplay = (object: ObjectItem | SubObjectItem) => {
    if (isUnknownObject(object)) {
        return false;
    }

    if (!isAllowedDisplayAppleHomeObject(object)) {
        return false;
    }

    return true;
};

const isSharedObject = (object: ObjectItem) => {
    const userId = getUserId();
    return isActiveAccessLevel(object?.permissions?.private_acl?.[userId]?.access_level) || isObjectPublic(object?.permissions);
};

const convertChildrenToStringArray = (children?: string[] | ChildItemType[]) => {
    if (!Array.isArray(children)) {
        return children;
    }

    const result: string[] = [];

    for (const child of children) {
        if (typeof child === "string" && !result.hasOwnProperty(child)) {
            result.push(child);
        }

        if (typeof child === "object" && getEpochTime(child?.bind) > getEpochTime(child?.unbind) && !result.hasOwnProperty(child.id)) {
            result.push(child.id);
        }
    }

    return result;
};

const getObjectByNativeIdentificationDataAndObjectType = async (nativeIdentificationData: string, objectType: string) => {
    try {
        return getLocalObjectByNativeIdentificationDataAndObjectType(nativeIdentificationData, objectType);
    } catch (err) {
        console.error(err);
        return Promise.reject(err);
    }
};

const clearObjectCache = () => {
    clearLocalObjectCache();
};

const clearDB = () => {
    clearLocalDB();
};

export {
    getObjects,
    getObjectById,
    postObject,
    postObjectKeepAlive,
    processKeepAlive,
    deleteObject,
    deleteObjects,
    postObjectProperties,
    postObjectPermissions,
    postObjectNotificationState,
    updateObjectProperties,
    saveObjectPropertiesToS3,
    getCachedData,
    postObjectChildren,
    postSeveralObjectChildren,
    deleteObjectChildren,
    deleteChildObjects,
    isAvailableNativeCamera,
    getObjectPropertyHistory,
    handleRemoteNotification,
    postObjectAccessRequest,
    getRemoteObjectPermissions,
    subscribeAllPublicObjects,
    unsubscribeAllPublicObjects,
    getPublicObjects,
    isAllowedObjectSharing,
    getListAllowedForSharingObjectIds,
    isUnknownObject,
    isObjectAvailableToDisplay,
    isSharedObject,
    convertChildrenToStringArray,
    clearDB,
    clearObjectCache,
    getObjectByNativeIdentificationDataAndObjectType
};

export * from "./ObjectsService/Subscription";
export * from "./ObjectsService/Types";

export {
    EMPTY_OBJECT,
    imagePropertyKey,
    rootControllers
} from "./ObjectsService/Constants";
