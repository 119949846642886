import React, {useState} from 'react';
import {useLocation} from "react-router-dom";
import {
    AppBar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Toolbar
} from '@material-ui/core';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';

import {isSignedIn} from "../../../Services/AuthenticationService";
import {useGlobalLoaderContext} from "../../../GlobalContext/GlobalContext";
import Icon from "@material-ui/core/Icon";
import {getSystemState, setSystemState, STATE_TYPE} from "../../../Services/SystemStatesService";
import {pubSubmitEvent} from "../../../Services/SystemEventsService";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuButton: {
      marginRight: theme.spacing(2),
    },
    iconButton: {
      flexDirection: "column",
      padding: theme.spacing(1),
    },
    overall: (props: {loader: boolean}) => ({
      zIndex: props.loader ? theme.zIndex.modal + 10 : theme.zIndex.appBar,
    }),
  }),
);


type NavigationBarProps = {
    menuOpenToggled (): void,
    children: React.ReactNode,
    handleBackButton(): void;
};

export const NavigationBar = (props: NavigationBarProps) => {
    const loader = useGlobalLoaderContext();

    const classes = useStyles({loader: loader});
    const location = useLocation();
    const [open, setOpen] = useState<boolean>(false);

    const controllerMatch: string[] = location.pathname.split("/")

    const handleBackButton = () => {
        if (!location.pathname.includes("/page/edit") || !getSystemState(STATE_TYPE.UNSAVED_CHANGES_TO_OBJECT)) {
            props.handleBackButton();
            return;
        }

        setOpen(true);
    };

    const ShowBackButton = () => {
        if (controllerMatch.length <= 2) {
            return null;
        }

        return (
            <IconButton color="inherit" aria-label="open drawer" onClick={handleBackButton} className={classes.iconButton}>
                <Icon className="fa fa-chevron-left" fontSize="small"/>
            </IconButton>
        );
    };

    const MenuButton = () => {
        if (!isSignedIn()) {
            return null;
        }

        return (
            <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={props.menuOpenToggled}
                // className={classes.menuButton}
                className={classes.iconButton}
            >
                <Icon className="fa fa-bars" fontSize="small"/>
            </IconButton>
        );
    };

    const handleCloseDialog = () => {
        setOpen(false);
    };

    const handleDoNotSave = () => {
        handleCloseDialog();
        setSystemState(STATE_TYPE.UNSAVED_CHANGES_TO_OBJECT, false);
        props.handleBackButton();
    };

    const handleSave = () => {
        pubSubmitEvent();
        handleCloseDialog();
        props.handleBackButton();
    };

    return (
        <AppBar position="fixed" classes={{root: classes.overall}}>
            <Toolbar>
                <MenuButton/>
                <ShowBackButton/>

                {props.children}

            </Toolbar>
            <Dialog
                open={open}
                onClose={handleCloseDialog}
                aria-labelledby="unsaved-changes-dialog-title"
                aria-describedby="unsaved-changes-dialog-description"
            >
                <DialogTitle id="unsaved-changes-dialog-title">
                    Unsaved changes
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="unsaved-changes-dialog-description">
                        Do you want to save the changes you made to page?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDoNotSave} color="primary">
                        Don't save
                    </Button>
                    <Button onClick={handleSave} color="primary" autoFocus>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </AppBar>
    );
};
