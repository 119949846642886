import React, {useEffect} from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

type RadioButtonsControlProps = {
    label?: string;
    data: any;
    value: string;
    onChange(value: string): void;
};

const RadioButtonsControl = (props: RadioButtonsControlProps) => {
    const { label, data, onChange } = props;
    const [value, setValue] = React.useState(props.value);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
        onChange((event.target as HTMLInputElement).value);
    };

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    return (
        <FormControl component="fieldset">
            <FormLabel component="legend">{label}</FormLabel>
            <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
                {
                    data.map((item: any) => {
                        return (
                            <FormControlLabel
                                key={item.value}
                                value={item.value}
                                control={<Radio color="primary"/>}
                                label={item.label}
                                disabled={item.disabled}
                            />
                        )
                    })
                }
            </RadioGroup>
        </FormControl>
    );
};

export default RadioButtonsControl;