import React, {ReactNode, Fragment, useEffect} from "react";
import {Box, Button, Grid, Link, makeStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {FileType} from "../../Services/ObjectsService/Types";
import {FileIcon, defaultStyles, DefaultExtensionType} from "react-file-icon";
import {downloadFile} from "../../Services/Utils";

type FileProps = {
    value: FileType;
    label: string;
    id: string;
    saveValue(key: string, value: FileType): void;
    isDisabled?: boolean;
    settings?: ReactNode;
    variant?: string;
};

const useStyles = makeStyles(() => ({
    wrap: {
        display: "flex",
        flexFlow: "column",
        height: "100%",
        width: "100%",
    },
    boxContainer: {
        height: "100%",
        width: "100%",
        position: "relative",
    },
    fileIcon: {
        width: "auto",
        maxHeight: "100%",
        maxWidth: "100%",
        margin: "auto",
        position: "relative",
        zoom: 10,
        display: "flex",
    },
}));


const FileControl = (props: FileProps) => {
    const classes = useStyles();
    const {saveValue, id, value, label, isDisabled, settings, variant} = props;

    const [fileExtension, setFileExtension] = React.useState<string>("");

    const needHeader = !["propertyCard", "title"].includes(variant as string);

    useEffect(() => {
        setFileExtension(value?.name?.split('.').pop() || "")
    }, [value.name]);

    const handleFileChange = (e: any) => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = async () => {
            if (!file.name) {
                return;
            }

            const newFile: FileType = {
                name: file.name as string,
                type: file.type as string,
                content: reader.result as string
            };
            saveValue(id, newFile);
        };

        if (file) {
            reader.readAsDataURL(new Blob([file], {type: file.type}));
        }
    };

    const handleFileDownload = (e: any) => {
        e.preventDefault();

        if (!value.name) {
            return;
        }

        const arr = value.content.split(',');
        const decodeStr = window.atob(arr[1] || '');
        let n = decodeStr.length;
        const u8arr = new Uint8Array(n);

        while(n--){
            u8arr[n] = decodeStr.charCodeAt(n);
        }

        downloadFile(value.name, u8arr, value.type);
    };

    const FileLink = () => {
        return (
            value.name ? (
                <Link onClick={(e) => handleFileDownload(e)} variant="body1" style={{cursor: "pointer"}}>
                    {value.name}
                </Link>) :
                <>"(Please select a File.)"</>
        );
    }

    const Icon = () => {
        return(
            fileExtension ?
                <FileIcon extension={fileExtension} {...defaultStyles[fileExtension as DefaultExtensionType]}/> :
                null
        );
    }
    const DisplayHeader = () => {
        return (
            <Grid container justifyContent={"center"} alignItems={"center"} direction={"column"}>
                <Box display="inline">
                    <Typography>
                        {settings}
                        {label || "File"} {" "}
                         <FileLink />
                    </Typography>
                </Box>
                <Box marginBottom={'16px'}>
                    {!isDisabled ? (
                        <Button variant="contained" component="label">
                            Upload File
                            <input type="file" style={{display: "none"}} onChange={(e) => handleFileChange(e)}/>
                        </Button>
                    ) : null}
                </Box>
            </Grid>
        );
    };

    return(
        <Grid container className={classes.wrap} justifyContent="center" alignItems="center" direction="column" style={{flexFlow: "column"}}>
            {needHeader ?
                <DisplayHeader /> :
                variant === "title" && value.name ?
                    <Fragment>
                        <div className={classes.fileIcon} style={{ maxHeight: '70%' }}>
                            <Icon />
                        </div>
                    </Fragment> :
                    <Grid container className={classes.boxContainer} justifyContent="center" alignItems="center"

                          direction={"column"} style={{display: "flex", flexFlow: "column"}}>
                        {value.name ? (
                            <Fragment>
                                <div className={classes.fileIcon} onClick={(e) => handleFileDownload(e)} style={{cursor: "pointer"}}>
                                    <Icon />
                                </div>
                                <FileLink />
                            </Fragment>
                        ) : null}
                    </Grid>
            }
        </Grid>
    );
};

export {FileControl};
