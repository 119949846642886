import React, {useState} from "react";

import {EditorState} from "lexical";
import {ContentEditable} from "@lexical/react/LexicalContentEditable";
import {LexicalComposer} from "@lexical/react/LexicalComposer";
import { TRANSFORMERS } from "@lexical/markdown";

import {AutoFocusPlugin} from "@lexical/react/LexicalAutoFocusPlugin";
import {CheckListPlugin} from '@lexical/react/LexicalCheckListPlugin';
import {HistoryPlugin} from "@lexical/react/LexicalHistoryPlugin";
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import {LinkPlugin} from "@lexical/react/LexicalLinkPlugin";
import {ListPlugin} from "@lexical/react/LexicalListPlugin";
import {MarkdownShortcutPlugin} from "@lexical/react/LexicalMarkdownShortcutPlugin";
import {OnChangePlugin} from "@lexical/react/LexicalOnChangePlugin";
import {RichTextPlugin} from "@lexical/react/LexicalRichTextPlugin";
import {TablePlugin} from '@lexical/react/LexicalTablePlugin';

// import AutocompletePlugin from "./plugins/AutocompletePlugin";
import AutoEmbedPlugin from './plugins/AutoEmbedPlugin';
import AutoLinkPlugin from "./plugins/AutoLinkPlugin";
import ClickableLinkPlugin from './plugins/ClickableLinkPlugin';
import CodeActionMenuPlugin from './plugins/CodeActionMenuPlugin';
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin";
import CollapsiblePlugin from './plugins/CollapsiblePlugin';
import DragDropPaste from './plugins/DragDropPastePlugin';
import DraggableBlockPlugin from './plugins/DraggableBlockPlugin';
import EmojiPickerPlugin from './plugins/EmojiPickerPlugin';
import EmojisPlugin from './plugins/EmojisPlugin';
import EquationsPlugin from './plugins/EquationsPlugin';
import ExcalidrawPlugin from './plugins/ExcalidrawPlugin';
import FigmaPlugin from './plugins/FigmaPlugin';
import FloatingLinkEditorPlugin from './plugins/FloatingLinkEditorPlugin';
import HorizontalRulePlugin from './plugins/HorizontalRulePlugin';
import ImagesPlugin from './plugins/ImagesPlugin';
import KeywordsPlugin from './plugins/KeywordsPlugin';
import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndentLevelPlugin";
import LocalMachinesPlugin from "./plugins/LocalMachinesPlugin";
import MentionsPlugin from './plugins/MentionsPlugin';
import PollPlugin from './plugins/PollPlugin';
import TableCellActionMenuPlugin from './plugins/TableActionMenuPlugin';
import TableCellResizer from './plugins/TableCellResizer';
import {TableContext, TablePlugin as NewTablePlugin} from './plugins/TablePlugin';
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import TwitterPlugin from './plugins/TwitterPlugin';
import YouTubePlugin from './plugins/YouTubePlugin';

import TableCellNodes from './nodes/TableCellNodes';
import PlaygroundNodes from "./nodes/PlaygroundNodes";
import PlaygroundEditorTheme from "./themes/PlaygroundEditorTheme";
import {SharedAutocompleteContext} from "./context/SharedAutocompleteContext";
import {UserInfoType} from "../../Hooks/useUserProfile";
import {Permissions} from "../../Services/ObjectsService/Types";


const onError = (error: any) => {
    console.error("Error ", error);
    throw error;
}

type PageEditorProps = {
    onEditorStateChange(editorState: string): void;
    initEditorState: string | null;
    userInfo: UserInfoType;
    objectId: string;
    objectOwnerId?: string;
    objectPermissions: Permissions;
};

export const PageEditor = (props: PageEditorProps) => {

    const {
        onEditorStateChange,
        initEditorState,
        userInfo,
        objectId,
        objectOwnerId,
        objectPermissions
    } = props;

    const onChange = (editorState: EditorState) => {
        onEditorStateChange(JSON.stringify(editorState.toJSON()));
    };

    const [floatingAnchorElem, setFloatingAnchorElem] =
        useState<HTMLDivElement | null>(null);

    const onRef = (_floatingAnchorElem: HTMLDivElement) => {
        if (_floatingAnchorElem !== null) {
            setFloatingAnchorElem(_floatingAnchorElem);
        }
    };

    const editorConfig = {
        namespace: "LM",
        theme: PlaygroundEditorTheme,
        onError,
        nodes: [...PlaygroundNodes],
        editorState: initEditorState
    };

    const cellEditorConfig = {
        namespace: "LM",
        nodes: [...TableCellNodes],
        onError: (error: Error) => {
            throw error;
        },
        theme: PlaygroundEditorTheme,
    };

    return (
        <LexicalComposer initialConfig={editorConfig}>
            <TableContext>
                <SharedAutocompleteContext>
                    <div className="editor-shell">
                        <ToolbarPlugin
                            userInfo={userInfo}
                            objectId={objectId}
                            objectOwnerId={objectOwnerId}
                            objectPermissions={objectPermissions}
                        />
                        <div className="editor-container">
                            {/*<AutocompletePlugin />*/}
                            <AutoEmbedPlugin />
                            <AutoFocusPlugin />
                            <AutoLinkPlugin />
                            <CheckListPlugin />
                            <ClickableLinkPlugin />
                            <CodeHighlightPlugin />
                            <CollapsiblePlugin />
                            <DragDropPaste />
                            <EmojiPickerPlugin />
                            <EmojisPlugin />
                            <EquationsPlugin />
                            <ExcalidrawPlugin />
                            <FigmaPlugin />
                            <HistoryPlugin />
                            <HorizontalRulePlugin />
                            <ImagesPlugin />
                            <KeywordsPlugin />
                            <LinkPlugin />
                            <ListMaxIndentLevelPlugin maxDepth={7} />
                            <ListPlugin />
                            <LocalMachinesPlugin />
                            <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
                            <MentionsPlugin />
                            <OnChangePlugin
                                ignoreSelectionChange={true}
                                onChange={onChange} />
                            <PollPlugin />
                            <RichTextPlugin
                                contentEditable={
                                    <div ref={onRef} style={{ height: '100%' }}>
                                        <ContentEditable className="editor-input" />
                                    </div>
                                }
                                placeholder={<div className="editor-placeholder">Enter the page text...</div>}
                                ErrorBoundary={LexicalErrorBoundary}
                            />
                            <NewTablePlugin cellEditorConfig={cellEditorConfig}>
                                <AutoFocusPlugin />
                                <RichTextPlugin
                                    contentEditable={
                                        <ContentEditable className="TableNode__contentEditable" />
                                    }
                                    placeholder={''}
                                    ErrorBoundary={LexicalErrorBoundary}
                                />
                                <MentionsPlugin />
                                <HistoryPlugin />
                                <ImagesPlugin captionsEnabled={false} />
                                <LinkPlugin />
                                <ClickableLinkPlugin />
                                {/*<FloatingTextFormatToolbarPlugin />*/}
                            </NewTablePlugin>
                            <TablePlugin />
                            <TableCellResizer />
                            <TwitterPlugin />
                            <YouTubePlugin />
                            {floatingAnchorElem && (
                                <>
                                    <DraggableBlockPlugin anchorElem={floatingAnchorElem} />
                                    <CodeActionMenuPlugin anchorElem={floatingAnchorElem} />
                                    <FloatingLinkEditorPlugin anchorElem={floatingAnchorElem} />
                                    <TableCellActionMenuPlugin anchorElem={floatingAnchorElem} />
                                    {/*<FloatingTextFormatToolbarPlugin*/}
                                    {/*    anchorElem={floatingAnchorElem}*/}
                                    {/*/>*/}
                                </>
                            )}
                        </div>
                    </div>
                </SharedAutocompleteContext>
            </TableContext>
        </LexicalComposer>
    );
};
