import React from "react";
import {Icon} from "@material-ui/core";
import DevicesIcon from '@material-ui/icons/Devices';
import AppleIcon from '@material-ui/icons/Apple';
import AndroidIcon from '@material-ui/icons/Android';
import {DeviceIconComponentProps} from "../../../UserProfileTypes";


const DeviceIconComponent = (props: DeviceIconComponentProps) => {
    const { os } = props;

    if (os === "Android") {
        return ( <AndroidIcon/> );
    }

    if (os === "Mac OS" || os === "iOS") {
        return ( <AppleIcon/> );
    }

    if (os?.toLocaleLowerCase().includes("linux")) {
        return <Icon className="fab fa-linux"/>
    }

    if (os?.toLocaleLowerCase().includes("windows")) {
        return <Icon className="fab fa-windows"/>
    }

    return ( <DevicesIcon/> );
};

export default DeviceIconComponent;