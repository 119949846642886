import React from "react";
import CardMediaBlock from "./CardMediaBlock/CardMediaBlock";
import CardActivityBlock from "./CardActivityBlock/CardActivityBlock";
import {ObjectItem} from "../../../../../../Services/ObjectsService/Types";
import {UserInfoType} from "../../../../../../Hooks/useUserProfile";

export type CardMediaComponentProps = {
    object: ObjectItem;
    userInfo: UserInfoType;
    parentId?: string;
};

export default function CardMediaComponent(props: CardMediaComponentProps) {
    return (
        <>
            <CardMediaBlock
                object={props.object}
                userInfo={props.userInfo}
                parentId={props.parentId}
            />
            <CardActivityBlock objectId={props.object.object_id} lastActivityEpoch={props.object.last_active} objectDeviceName={props.object.primary_client?.name}/>
        </>
    );
}
