import {AbstractTool} from "./AbstractTool";
import {AbstractElement, ElementType} from "./Elements/AbstractElement";
import {Point} from "paper";

export class EditElementTool extends AbstractTool {
    element?: AbstractElement;

    // with the help of this functionality,
    // we are trying to deal with throttling when clicking on a plan
    private layerIsMoving: boolean
    private hitElementData: any

    private pinchingEnd: boolean

    private dragStartDelta: any

    constructor(paperScape: any, movable: boolean = true) {
        super(paperScape);
        this.layerIsMoving = false
        this.pinchingEnd = false
    }

    public getPinchingEnd(): boolean {
        return this.pinchingEnd
    }

    public setPinchingEnd(value: boolean): void {
        this.pinchingEnd = value
    }


    // Mouse Event Handlers Implementation
    onMouseDown(event: any): void {

        this.paperScope.project.activeLayer.selected = false;
        this.hitElementData = undefined;

        // The Greater Index - the higher priority
        let elementHitPriority = [
            ElementType.ZONE_ELEMENT,
            ElementType.SOLID_WALL,
            ElementType.SINGLE_DOOR,
            ElementType.SINGLE_WINDOW,
            ElementType.OBJECT_ELEMENT
        ];

        let hitElements = this.elements
            .filter((element: AbstractElement) => {
                return element.testElementHit(event);
            })
            .sort((e1, e2) => {
                // the selected element has more priority
                if(e1 === this.element) return -1;
                if(e2 === this.element) return 1;
                
                let index1 = elementHitPriority.indexOf(e1.getElementType());
                let index2 = elementHitPriority.indexOf(e2.getElementType());
                return index2 - index1;
            });

        // console.log("Hit Elements", hitElements);

        if (hitElements.length > 0) {
            // Do binding
            
            this.hitElementData = JSON.parse(JSON.stringify(hitElements[0].getPaperJSItem().data));
            let hitElement = hitElements[0];
            
            this.element = hitElement;
            
            // Make the correction of current touch point by binding it to the appropriate point of the element hit
            
            this.dragStartDelta = this.element.getDragStartDifference(event) 
            console.log("Drag Start Delta: ",this.dragStartDelta)
            event.point = new Point(event.point).subtract(this.dragStartDelta)
            
            event.point = this.applyBindings(event.point);
             

            this.element.onMouseDown(event);
            this.element.updateAlignment(this.elements);
            this.notifyListenersOnElementsUpdated([this.element]);
        } else {
            this.element?.updateGeometry();
            this.element = undefined;
            this.notifyListenersOnElementsUpdated([]);
        }
    }

    onMouseDrag(event: any): void {

        this.layerIsMoving = true

        if (this.element) {
            // Make the correction of current touch point by binding it to the appropriate point of the element hit
            
            event.point = new Point(event.point).subtract(this.dragStartDelta)
            
            // Do binding
            event.point = this.applyBindings(event.point);
            // Pass the event to the element
            this.element.onMouseDrag(event);
            // Update the element alignment
            this.element.updateAlignment(this.elements);
            this.notifyListenersOnElementsUpdated([this.element]);
        } else {
            let delta = event.downPoint.subtract(event.point);
            if (Math.round(delta.x) !== 0 && Math.round(delta.y) !== 0)
                this.paperScope.view.scrollBy(new Point(delta));
        }

    }

    onMouseUp(event: any): void {

        if (this.pinchingEnd) {
            this.pinchingEnd = false
            return;
        }

        let _diff: any;
        if (this.element && this.hitElementData) {
            const el = this.element.getPaperJSItem().data;
            _diff = JSON.stringify(this.hitElementData.properties) !== JSON.stringify(el.properties)
        }

        if (this.layerIsMoving || _diff) {
            this.layerIsMoving = false
            if (this.element) {
                // Make the correction of current touch point by binding it to the appropriate point of the element hit
            
                event.point = new Point(event.point).subtract(this.dragStartDelta)
                
                // Do binding
                event.point = this.applyBindings(event.point);

                // Pass the event to the element
                this.element.onMouseUp(event);
                // Update the element alignment
                this.element.updateAlignment(this.elements);
                this.notifyListenersOnElementsCompleted([this.element]);
            } else {
                let delta = event.downPoint.subtract(event.point);
                if (Math.round(delta.x) !== 0 && Math.round(delta.y) !== 0)
                    this.paperScope.view.scrollBy(new Point(delta));
            }
        }
    }
}
