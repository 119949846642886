import React, {Fragment, ReactNode, useEffect} from "react";
import { Box, Button, CardMedia, CircularProgress, Fab, Grid, makeStyles } from '@material-ui/core';
import Typography from "@material-ui/core/Typography";
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import imageCompression from 'browser-image-compression';
import {
    useGlobalDialogContext,
    useUpdateGlobalDialogActionsContext,
    useUpdateGlobalDialogContentActionsContext
} from "../../GlobalContext/GlobalContext";
import Icon from "@material-ui/core/Icon/Icon";
import {useGlobalNotificationContext} from "../Notification/NotificationProvider";
import type {SnackBarType} from "../../Services/NotificationService";

type ImageProps = {
    value: string;
    label: string;
    id: string;
    width?: string;
    height?: string;
    saveValue(key: string, value: string | number | boolean): void;
    isDisabled?: boolean;
    settings?: ReactNode;
    variant?: string;
    icon?: string;
};

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    imgBlur: {
        width: "inherit",
        height: "inherit",
        opacity: "0.5",
        filter: "blur(4px)",
        position: "absolute",
        top: "0",
        left: "0",
    },
    imgPreview: {
        width: "auto",
        maxHeight: "100%",
        maxWidth: "100%",
        margin: "auto",
        position: "relative",
        zoom: 10,
    },
    previewText: {
        margin: "0 auto",
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
    },
    formControl: {
        width: "100%",
    },
    boxContainer: {
        // paddingBottom: "15px",
        height: "100%",
        width: "100%",
        position: "relative",
    },
    textContainer: {
        // paddingBottom: "15px",
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
    },
    wrap: {
        display: "flex",
        // flexWrap: "wrap",
        // flexDirection: "column",
        flexFlow: "column",
        height: "100%",
        width: "100%",
    },
    fullscreenIcon: {
        position: "absolute",
        bottom: theme.spacing(0.5),
        right: theme.spacing(0.5),
    },
    spinner: {
        marginLeft: 1,
        marginRight: 1,
    },
}));

const ViewImage = (props: ImageProps) => {
    const classes = useStyles();
    const {saveValue, id, value, label, isDisabled, settings, variant, icon} = props;

    const globalDialog = useGlobalDialogContext()
    const setGlobalDialog = useUpdateGlobalDialogActionsContext()
    const setGlobalDialogContent = useUpdateGlobalDialogContentActionsContext()
    const notify: SnackBarType = useGlobalNotificationContext();

    const needHeader = !["propertyCard", "title"].includes(variant as string);
    const [loader, setLoader] = React.useState(false);

    const handleImageChange = (e: any) => {
        e.preventDefault();

        let reader = new FileReader();
        let imageFile = e.target.files[0];
        let compressed = false;

        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: false,
        }

        reader.onloadend = async () => {
            let value = reader.result as string
            if (value.length > 1395000) {
                if (compressed) {
                    saveValue(id, "");
                    notify.errorNotify("Maximum size of the picture has been exceeded.");
                    return;
                }
                setLoader(true);
                const compressFile = await imageCompression(imageFile, options);
                setLoader(false);
                compressed = true;
                reader.readAsDataURL(compressFile);
                return;
            }
            saveValue(id, reader.result as string);
        };
        reader.readAsDataURL(new Blob([imageFile], {type: imageFile.type}));
    };

    const displayHeader = () => {
        return (
            <Grid container justifyContent={"center"} alignItems={"center"} direction={"column"}>
                <Box display="inline">
                    <Typography>
                        {settings}
                        {label || "Image"}
                    </Typography>
                </Box>
                <Box marginBottom={'16px'}>
                    {!isDisabled ? (
                        <Button variant="contained" component="label">
                            Upload File
                            <input type="file" style={{display: "none"}} onChange={(e) => handleImageChange(e)}/>
                            {loader && <CircularProgress
                                className={classes.spinner}
                                size={20}
                            />}
                        </Button>
                    ) : null}
                </Box>
            </Grid>
        );
    };

    const handleFullscreenOpenClick = (event:any) => {
        event.preventDefault()
        event.stopPropagation()
        setGlobalDialog(`FullscreenImageDialog_${id}`)
        setGlobalDialogContent(value)
    }
    useEffect(()=>{
        if (globalDialog===`FullscreenImageDialog_${id}`){
            setGlobalDialogContent(value)
        }
    }, [value]);

    return (
        <Grid container className={classes.wrap} justifyContent={"center"} alignItems={"center"} direction={"column"}
              style={{flexFlow: "column"}}>
            {needHeader && displayHeader()}
            <Grid container className={classes.boxContainer} justifyContent={"center"} alignItems={"center"}
                  direction={"column"} style={{display: needHeader ? "block" : "flex", flexFlow: "column"}}>
                {value && variant && <CardMedia className={classes.imgBlur} image={value} component="img" alt=""/>}
                {value ? (
                    <Fragment>
                        <CardMedia className={classes.imgPreview} image={value} component="img" alt=""/>
                        <Fab
                            size="small"
                            color="primary"
                            aria-label="open fullscreen"
                            component="span"
                            className={classes.fullscreenIcon}
                            onClick={(event:any)=>handleFullscreenOpenClick(event)}>
                            <FullscreenIcon/>
                        </Fab>
                    </Fragment>
                ) : variant === "title" ? (
                    <div>
                        <Icon className={icon || "fas fa-info-circle"} fontSize="large" color="disabled"/>
                    </div>
                ) : !isDisabled ? (
                    <div className={classes.previewText}>Please select an Image (Max size 1 MB.)</div>
                ) : null}
            </Grid>
        </Grid>
    );
};

export {ViewImage};
