import React from 'react';
import { Link } from "react-router-dom";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Avatar, Box, Button, Grid, ListItemText } from '@material-ui/core';
import { Person as PersonIcon } from '@material-ui/icons';

import type { UserItem } from "Services/UserService";

type UserAvatarProps = {
    menuOpenToggled(): void;
    user: UserItem | null;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            '& > *': {
                margin: theme.spacing(1)
            }
        },
        text: {
            wordWrap: "break-word",
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            textAlign: "center"
        }
    })
);

const UserAvatar = (props: UserAvatarProps) => {
    const { menuOpenToggled, user } = props;

    const classes = useStyles();

    return (
        <Grid container>
            <Grid item xs={12} container justifyContent="center">
                <Grid item xs={3}>
                    <Button key="Profile" component={Link} to="/profile" onClick={menuOpenToggled}>
                        <Box className={classes.root}>
                            <Avatar src={user?.picture || ""}>
                                <PersonIcon/>
                            </Avatar>
                        </Box>
                    </Button>
                </Grid>
            </Grid>
            <Grid item xs={12} container className={classes.text}>
                <ListItemText
                    primary={`${user?.firstname || ""} ${user?.lastname || ""}`}
                    secondary={user?.email}
                    primaryTypographyProps={{ variant: "subtitle2" }}
                    secondaryTypographyProps={{ variant: "caption" }}
                />
            </Grid>
        </Grid>
    );
};

export default UserAvatar;
