import {Layer, PaperScope} from "paper";
import {GridViewController} from "./GridViewController";
//import {MeasurementsViewController} from "./MeasurementsViewController";
import {VisualisationController} from "./VisualisationController";

import {AbstractElement} from "./tools/Elements/AbstractElement";
import {AbstractTool, AbstractToolListener} from "./tools/AbstractTool";
import {ScrollTool} from "./tools/ScrollTool";
import {ViewElementTool} from "./tools/ViewElementTool";
import {AbstractBinding} from "./bindings/AbstractBinding";
import {GridBinding} from "./bindings/GridBinding";
import {ElementsFactory} from "./tools/Elements/ElementsFactory";
import {ObjectItem} from "../../Services/ObjectsService";
import type {SystemOfMeasuresType} from "../../Helpers/SystemOfMeasures";
import {ZoneElement} from "./tools/Elements/objects/ZoneElement";
import {ObjectElement} from "./tools/Elements/objects/ObjectElement";
import {sequenceGenerator} from "../../Services/Utils";

// TODO: Common visualisation features should be moved to superclass
// "Edit" class should only add Undo/Redo history and editing tools

export class PlanViewController implements AbstractToolListener {
    protected elementsFactory: ElementsFactory;
    // Elements
    protected elements: AbstractElement[] = [];

    //     // Bindings
    protected bindings: AbstractBinding[] = [];

    // Sub-controllers
    protected gridViewController: GridViewController;
    // protected menuEventsController: MenuEventsController;
    //protected measurementsViewController: MeasurementsViewController;
    protected visualisationController: VisualisationController;

    // Tools
    // protected createElementTool: CreateElementTool;
    // protected editElementTool: EditElementTool;
    protected viewElementTool: ViewElementTool;

    // TODO should it be here?
    // Zooming
    protected currentZoom: number = 1;

    // Canvas the PaperJS should be inited on
    protected canvas: HTMLCanvasElement;

    // Current color theme of the app
    protected theme: "dark" | "light"

    //system of measures
    protected system_of_measures: SystemOfMeasuresType

    // PaperJS Scope
    protected scope: any = null;

    // PaperJS Layers
    protected elementsLayer: any = null;
    protected visualisationLayer: any = null;
    protected measurementsLayer: any = null;
    protected gridLayer: any = null;

    // Tools
    protected scrollTool: ScrollTool;

    //forceUpdate
    protected forceUpdate: () => void;

    constructor(canvas: HTMLCanvasElement,
                theme: "dark" | "light",
                system_of_measures: SystemOfMeasuresType = 'metric',
                forceUpdate: () => void,
                parentId?: string) {

        // Init PaperJS
        this.canvas = canvas;
        this.theme = theme
        this.system_of_measures = system_of_measures

        this.forceUpdate = forceUpdate;

        this.elementsFactory = new ElementsFactory(parentId);

        // PaperScope() is used here instead of the standard paper.setup()
        // This is done according to the example:
        //  - https://gist.github.com/donpark/f5e46b6afd1a8b166dc742f012b26a41
        // This helps to avoid the crash on the init.

        // Most likely the problem is that Javascript code loader which
        // Loads the NPM module does not run the initialisation code,
        // and thereby does not create the default PaperScope() object.
        this.scope = new PaperScope();
        this.scope.setup(canvas);

        // Create the working PaperJS Layers
        this.elementsLayer = this.scope.project.activeLayer;
        this.visualisationLayer = new Layer([]);
        this.measurementsLayer = new Layer([]);
        this.gridLayer = new Layer([]);

        // Align the layers
        this.gridLayer.sendToBack();
        this.elementsLayer.activate();

        // Init Sub-Controllers
        this.gridViewController = new GridViewController(this.gridLayer, this.theme);
        /*this.measurementsViewController = new MeasurementsViewController(
            this.elementsLayer, this.measurementsLayer, this.theme, this.system_of_measures);*/
        /*this.menuEventsController = new MenuEventsController(this);*/
        this.visualisationController = new VisualisationController(this.elementsLayer, this.visualisationLayer);

        // Render the Grid
        this.gridViewController.renderGrid();
        this.scrollTool = new ScrollTool(this.scope);
        this.scrollTool.activate();

        //
        //         // Init the Bindings
        this.bindings = [new GridBinding()];
        //
        //         // Init the Tools
        // this.createElementTool = new CreateElementTool(this.scope, parentId);
        // this.editElementTool = new EditElementTool(this.scope);
        this.viewElementTool = new ViewElementTool(this.scope);
        //         //this.selectAndDragTool = new SelectAndDragTool();
        //         this.createElementTool.activate();
        //         this.createElementTool.addListener(this);
        //         this.createElementTool.setBindings(this.bindings);
        this.viewElementTool.setBindings(this.bindings);
        this.viewElementTool.activate();
        //
        // TODO: Elements Layer should be activated by element create/edit tools
        this.elementsLayer.activate();
        //
        //         // TODO: Remove this demonstration code
        //         this.createElementTool.setElementType(ElementType.SOLID_WALL);
        //         this.createElementTool.setElementProperties([
        //             {
        //                 name: 'thickness',
        //                 value: 400
        //             }
        //         ]);
    }

    // Alignment
    // =======================================================================

    // Elements Access Method
    getSelectedElement(): AbstractElement | null {
        const selectedElements = this.elements.filter((element) => element.isSelected())
        return selectedElements.length ? selectedElements[0] : null
    }

    getSelectedElements(): AbstractElement[] {
        return this.elements.filter((element) => element.isSelected());
    }

    setElementSelected(element_id: string | null): void {
        this.elements.forEach((element) => {
            element.setSelect(element.getPaperJSItem().data.id === element_id);
        })
    }

    // Removing Element
    public removeSelectedElements(): void {
        this.elements.forEach((element) => {
            if (element.isSelected()) {
                element.removeElement();
            }
        });

        this.elements = this.elements.filter((element) => !element.isRemoved());

        // this.createElementTool.setElements(this.elements);
        // this.editElementTool.setElements(this.elements);

        this.updatePlanViewAndRecordState();
    }

    // Update Elements Geometry
    updateElementsGeometry(): void {
        // console.log("Updating elements geometry");
        this.elements.forEach((element: AbstractElement) => {
            element.updateGeometry();
        });
    }

    // Apply The Alignment Rules
    alignElements(): void {
        this.elements.forEach((element: AbstractElement) => {
            element.alignElement(this.elements);
        });
    }

    protected planDidUpdate: (() => void) | null = null;

    // Plan Update Listener
    public setPlanDidUpdate(planDidUpdate: () => void) {
        this.planDidUpdate = planDidUpdate;
    }

    // AbstractToolListener interface implementation
    // =======================================================================

    abstractElementsUpdated(_tool: AbstractTool, _elements: AbstractElement[]): void {
        // Invoke Measurements, Alignment and Rendering update
        // this.alignElements();
        // this.updateElementsGeometry();
        // this.measurementsViewController.updateMeasurements();
        // this.visualisationController.drawJoins();
    }

    abstractElementsAdded(_tool: AbstractTool, elements: AbstractElement[]): void {
        // Add the newly created elements to the elements list:
        // this.elements = this.elements.concat(elements);
        //
        // this.createElementTool.setElements(this.elements);
        // this.editElementTool.setElements(this.elements);
        //
        // this.alignElements();
        // this.updateElementsGeometry();
        // // Make the controllers to update the data they are responsible for accordingly
        // this.measurementsViewController.updateMeasurements();
        // this.visualisationController.drawJoins();
    }

    abstractElementsRemoved(_tool: AbstractTool, elements: AbstractElement[]): void {
        // Remove the just removed elements from the elements list:
        // elements.forEach((element: AbstractElement)=>{
        //     let index = this.elements.indexOf(element);
        //     if(index>-1){
        //         this.elements.splice(index,1);
        //     }
        // });
        //
        // this.createElementTool.setElements(this.elements);
        // this.editElementTool.setElements(this.elements);
        //
        // this.alignElements();
        // this.updateElementsGeometry();
        // // Make the controllers to update the data they are responsible for accordingly
        // this.measurementsViewController.updateMeasurements();
        // this.visualisationController.drawJoins();
    }

    abstractElementsCompleted(tool: AbstractTool, elements: AbstractElement[]): void {
        // this.alignElements();
        // this.updateElementsGeometry();
        // this.measurementsViewController.updateMeasurements();
        // this.visualisationController.drawJoins();
    }

    // Zooming
    // =======================================================================

    // Zoom should be a property of PlanController (superclass for PlanEditController)
    // It is applicable to the viewer as well

    public getCurrentZoom(): number {
        return this.currentZoom;
    }

    public changeCurrentZoom(predictedChangeValue: number): void {

        const predictedZoom = this.currentZoom + predictedChangeValue

        if (predictedZoom > 5) {
            this.currentZoom = 5
            this.scope.view.zoom = this.currentZoom
        } else if (predictedZoom < 0.5) {
            this.currentZoom = 0.5
            this.scope.view.zoom = this.currentZoom
        } else {
            this.currentZoom = predictedZoom
            this.scope.view.zoom = this.currentZoom
        }
    }

    public zoomIn(): void {
        if (this.currentZoom % 0.25 > 0) {
            this.currentZoom += parseFloat((0.25 - this.currentZoom % 0.25).toFixed(2))
        } else {
            this.currentZoom += 0.25;
        }
        if (this.currentZoom > 5) {
            this.currentZoom = 5;
        }
        this.scope.view.zoom = this.currentZoom;
    }

    public zoomOut(): void {
        if (this.currentZoom % 0.25 > 0) {
            this.currentZoom -= parseFloat((this.currentZoom % 0.25).toFixed(2))
        } else {
            this.currentZoom -= 0.25;
        }
        if (this.currentZoom < 0.5) {
            this.currentZoom = 0.5;
        }
        this.scope.view.zoom = this.currentZoom;
    }

    public updatePlanView() {
        this.alignElements();
        this.updateElementsGeometry();
        // Make the controllers to update the data they are responsible for accordingly
        //this.measurementsViewController.updateMeasurements();
        this.visualisationController.drawJoins();

        this.forceUpdate()
    }

    public updatePlanViewAndRecordState() {
        this.updatePlanView();
    }

    public loadJSON(json: any) {
        this.elementsLayer.removeChildren();
        this.elementsLayer.importJSON(json);

        // this.elements = this.elementsFactory.createWithPaperJSItems(this.elementsLayer.children);

        // this.createElementTool.setElements(this.elements);
        // this.editElementTool.setElements(this.elements);
        //
        this.alignElements();
        this.updateElementsGeometry();
        // Make the controllers to update the data they are responsible for accordingly
        //this.measurementsViewController.updateMeasurements();
        this.visualisationController.drawJoins();
    }

    public getJSON(): any {
        return this.elementsLayer.exportJSON();
    }

    public getAllZones(): AbstractElement[] {
        return this.elements.filter((element) => element instanceof ZoneElement);
    }

    public getAllMarkedObjects(): AbstractElement[] {
        return this.elements.filter(
            (element) => element instanceof ZoneElement || element instanceof ObjectElement);
    }

    public getViewBounds() {
        return this.scope.view.bounds;
    }

    public getLastPoint() {
        return this.scope.tool._lastPoint
    }

    public loadPlanObjects(objects: ObjectItem[]) {
        this.scope.activate();
        this.elementsLayer.removeChildren();

        this.elements = this.elementsFactory.createWithObjectItems(objects);
        this.elements.forEach((item) => {
            item.updateGeometry();
            item.updateAlignment(this.elements);
            item.subUpdateProperty(()=>{
                item.updateAlignment(this.elements);
                this.updatePlanView();
            });
            item.isDisabled = true;
        });

        this.viewElementTool.setElements(this.elements);

        this.updatePlanView();
    }

    public removePlanObjects() {
        this.elements.forEach((item) => {
            item.removeElement();
        });
    }

    public moveToCenter() {
        if (this.scope.project.activeLayer.children.length > 0) {
            this.scope.view.center = this.scope.project.activeLayer.children[0].bounds.center.clone();
        } else {
            this.scope.view.center = this.scope.project.activeLayer.bounds.center.clone();
        }

        this.viewElementTool.remove();
        this.scrollTool.remove();
    }

    // Called when the plan is first drawn
    // Allows to display all elements in the center and select the appropriate zoom
    public moveCanvasToCenter() {
        if (this.elements.length) {
            const coords = this.elements.reduce((prev: any, current: AbstractElement) => {
                let bounds = current.getPaperJSItem().bounds
                return [...prev, {x: bounds.x, y: bounds.y}, {x: bounds.x + bounds.width, y: bounds.y + bounds.height}]
            }, [])


            const xCoords = coords.map(c => c.x)
            const yCoords = coords.map(c => c.y)

            const minX = Math.min(...xCoords)
            const minY = Math.min(...yCoords)
            const maxX = Math.max(...xCoords)
            const maxY = Math.max(...yCoords)

            const averageX = minX + (maxX - minX) / 2
            const averageY = minY + (maxY + 76 - minY) / 2 // 76 px bottom navigation bar

            this.scope.view.center = {x: averageX, y: averageY}

            const elementsWidth = maxX - minX + 50
            const elementsHeight = maxY - minY + 50

            const zoomRatioX = this.scope.view.bounds.width / elementsWidth
            const zoomRatioY = this.scope.view.bounds.height / elementsHeight

            const zoomVariants = [...sequenceGenerator(0.5, 5, 0.25)]

            let targetZoom = Math.min(...[
                Math.max(...zoomVariants.filter(v => v <= zoomRatioX)),
                Math.max(...zoomVariants.filter(v => v <= zoomRatioY)),
            ])

            if (targetZoom < 0.5) targetZoom = 0.5
            if (targetZoom > 1) targetZoom = 1

            this.currentZoom = targetZoom
            this.scope.view.zoom = this.currentZoom
        }
    }

    public getViewElementToolPinchingEnd(): boolean {
        return this.viewElementTool.getPinchingEnd()
    }

    public setViewElementToolPinchingEnd(value: boolean): void {
        this.viewElementTool.setPinchingEnd(value)
    }
}
