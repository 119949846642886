import React, {useCallback, useEffect, useState} from "react";
import List from '@material-ui/core/List';
import TextField from '@material-ui/core/TextField';
import DarkModeSwitch from "./DarkModeSwitch";
import GroupContainer from "../GroupContainer"
import UserPropertyItem from "./UserPropertyItem/UserPropertyItem";
import {
    DEFAULT_USER_ITEM,
    isGuest,
    postProfile,
} from "../../../../Services/UserService";
import type {UserItem} from "../../../../Services/UserService";
import {useHistory} from "react-router-dom";
import {UserBasicInfoProps} from "../UserProfileTypes";
import {useUserProfileStyles} from "../UserProfileStyles";
import {useGlobalLoaderActionsContext, useGlobalLoaderContext} from "../../../../GlobalContext/GlobalContext";
import PasswordItem from "./PasswordItem/PasswordItem";
import {useSubSubmit} from "../../../../Hooks/SystemEvent/Toolbar/useToolbar";
import {pubDisableUIEvent} from "../../../../Services/SystemEventsService";

const UserBasicInfo = (props: UserBasicInfoProps) => {
    const classes = useUserProfileStyles();
    let history = useHistory();

    const loader = useGlobalLoaderContext();
    const setLoader = useGlobalLoaderActionsContext();
    const mounted = React.useRef(false);

    const { userInfo } = props;

    useEffect(() => {
        mounted.current = true; // Will set it to true on mount ...
        return () => { mounted.current = false; }; // ... and to false on unmount
    }, []);

    const [userProfile, setUserProfile] = useState<UserItem>(userInfo.user ? {
        ...userInfo.user,
        // email: !userInfo.user.guest ? userInfo.user.email : ""
    } : DEFAULT_USER_ITEM);

    const updateUserProfileByKey = useCallback((key: string, value: any) => {
        if (loader) return;
        setUserProfile((prevState) => ({...prevState, [key]: value}));
    }, [loader])

    const handleImageChange = (e: any) => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.readAsDataURL(new Blob([file], {type: file.type}));
        reader.onloadend = function () {
            updateUserProfileByKey("picture", reader.result);
        };
    };

    useEffect(() => {
        setUserProfile(userInfo.user ? {
            ...userInfo.user,
            // email: !userInfo.user.guest ? userInfo.user.email : ""
        } : DEFAULT_USER_ITEM);
    }, [userInfo.user]);

    useSubSubmit(async () => {
        try {
            setLoader(true);
            const res = await postProfile(userProfile);
            await userInfo.setUser(res.user);
            history.replace("/map");
        } catch (e: any) {
            props.notify.errorNotify(e?.message || JSON.stringify(e));
        } finally {
            setLoader(false);
            pubDisableUIEvent(false);
        }
    });

    return (
        <GroupContainer
            title="Basic Info"
            action={<DarkModeSwitch />}
        >
            <List aria-label="main mailbox folders">
                <TextField
                    className={classes.input}
                    id="user-profile-avatar"
                    name="user-profile-avatar"
                    type="file"
                    onChange={handleImageChange}
                    disabled={loader}
                />
                <label htmlFor="user-profile-avatar">
                    <UserPropertyItem
                        name="Picture"
                        itemKey="picture"
                        value={"Add a photo to personalize your profile"}
                        divider
                        imageSrc={userProfile.picture}
                        isAvatar
                        updateItem={updateUserProfileByKey}
                    />
                </label>
                <UserPropertyItem
                    name="First Name"
                    itemKey="firstname"
                    value={userProfile.firstname}
                    divider
                    updateItem={updateUserProfileByKey}
                />
                <UserPropertyItem
                    name="Last Name"
                    itemKey="lastname"
                    value={userProfile.lastname}
                    divider
                    updateItem={updateUserProfileByKey}
                />
                <UserPropertyItem
                    name="Email"
                    itemKey="email"
                    value={userProfile.email}
                    divider
                    disabled={true}
                    updateItem={updateUserProfileByKey}
                />
                <PasswordItem
                    email={userProfile.email}
                    disabled={!userProfile.email || !!userInfo?.user?.guest || isGuest()}
                    notify={props.notify}
                />
                <UserPropertyItem
                    name="Age"
                    itemKey="age"
                    value={userProfile.age}
                    divider
                    updateItem={updateUserProfileByKey}
                />
                <UserPropertyItem
                    name="Gender"
                    itemKey="gender"
                    value={userProfile.gender}
                    divider
                    updateItem={updateUserProfileByKey}
                />
                <UserPropertyItem
                    name="System of measures"
                    itemKey="system_of_measures"
                    value={userProfile.system_of_measures}
                    updateItem={updateUserProfileByKey}
                />
            </List>
        </GroupContainer>
    )
};

export {
    UserBasicInfo
}