import {v4} from "uuid";
import {
    BodyObjectItem,
    ObjectItem,
    Params,
    PropertiesType,
    PropertyItemValue
} from "../Types";
import {
    convertNativeIdToStoredId,
    convertStoredIdToNativeId,
    nativeObjectIdentification
} from "./ObjectsIdentificationService/ObjectsIdentificationService";
import * as ObjectsService from "../../ObjectsService";
import {isDevice, isHandlerExist, sendMessageToDevice} from "../../DeviceService";
import {DeviceHandler, OriginType, SubscriptionType} from "../Constants";
import {getDeviceId} from "../../UserService";
import {removeUnacceptableFields} from "../../Utils";
import {LIST_OF_PROPERTIES_STORED_ON_S3} from "../../Constants";


const subscriptionId = v4();

const getNativeObjects = async (params: Params): Promise<any> => {
    try {
        const listObjects = await ObjectsService.getObjects(params);

        await Promise.all(listObjects.map(async (object: ObjectItem) => {
            try {
                object.object_id = await convertStoredIdToNativeId(object.object_id, object);
                return;
            } catch (e) {
                console.log(e);
                return;
            }
        }));

        return listObjects;
    } catch (err) {
        console.error(err);
        return Promise.reject(err);
    }
};

const getNativeObjectById = async (object_id: string, params?: Params): Promise<any> => {
    try {
        const storedId = await convertNativeIdToStoredId(object_id);
        return await ObjectsService.getObjectById(storedId, params);
    } catch (err) {
        console.error(err);
        return Promise.reject(err);
    }
};

const getDetectedObjectById = async (object_id: string, params?: Params): Promise<any> => {
    try {
        return await ObjectsService.getObjectById(object_id, params);
    } catch (err) {
        return Promise.reject(err);
    }
};

const postNativeObject = async (object: BodyObjectItem, params?: Params): Promise<any> => {
    try {
        if (!params?.skipConvert) {
            if (object.object_id != null) {
                if (object.native_identification_data) {
                    object = await nativeObjectIdentification(object);
                } else {
                    object.object_id = await convertNativeIdToStoredId(object.object_id, false, object);
                }
            }

            object.source = "apple_home";
        }
        await ObjectsService.postObject(object, {
            origin: OriginType.NATIVE,
            sid: subscriptionId
        });
        return object.object_id;
    } catch (err) {
        console.error(err);
        return Promise.reject(err);
    }
};

const postNativeObjectKeepAlive = async (object: BodyObjectItem): Promise<any> => {
    try {
        object.object_id = await convertNativeIdToStoredId(object.object_id as string);
        object.source = "apple_home";

        await ObjectsService.postObjectKeepAlive(object, {
            origin: OriginType.NATIVE,
            sid: subscriptionId
        });

        return null;
    } catch (err) {
        console.error(err);
        return Promise.reject(err);
    }
};

const saveNativeObjectPropertiesToS3 = async (objectId: string, propertyKey: string, properties: PropertiesType, epoch: number) => {
    if (!properties || !LIST_OF_PROPERTIES_STORED_ON_S3.includes(propertyKey)) {
       return;
    }

    try {
        const storedObjectId = await convertNativeIdToStoredId(objectId);

        await ObjectsService.saveObjectPropertiesToS3(storedObjectId, propertyKey, properties, epoch, {
            origin: OriginType.NATIVE,
            source: "apple_home",
            sid: subscriptionId
        });

    } catch (e) {
        return Promise.reject(e);
    }
};

const postNativeObjectProperties = async (object_id: string, properties: { [string: string]: any }, client_id: string | null): Promise<any> => {
    try {
        const storedObjectId = await convertNativeIdToStoredId(object_id);

        await ObjectsService.postObjectProperties(storedObjectId, properties, {
            source: "apple_home",
            origin: OriginType.NATIVE,
            clientId: client_id,
            sid: subscriptionId
        });
        return null;
    } catch (err) {
        console.error(err);
        return Promise.reject(err);
    }
};

const pubNativeObjectProperties = async (objectId: string, properties: PropertiesType, object: ObjectItem) => {
    try {
        const storedObjectId = await convertNativeIdToStoredId(objectId);

        ObjectsService.pubPropertiesChanges(storedObjectId, properties, object, {
            source: "apple_home",
            event: SubscriptionType.PROPERTIES,
            origin: OriginType.NATIVE,
            sid: subscriptionId
        });
    } catch (e) {
        return Promise.reject(e);
    }
};

const postNativeObjectsHierarchy = async (objects: {[key: string]: ObjectItem}, hierarchy: {parentId: string; childId: string []}[], params?: Params) => {
    try {
        for (const hierarchyItem of hierarchy) {
            const listStoredChildren = [];

            for (const nativeChildId of hierarchyItem.childId) {
                const object = objects[nativeChildId];

                if (!object) {
                    continue;
                }

                const storedId = await postNativeObject(object, params);

                listStoredChildren.push(storedId);
            }

            const inputParentObject = objects[hierarchyItem.parentId];

            await postNativeObject({
                ...inputParentObject,
                children: listStoredChildren
            }, params);
        }

        return null;
    } catch (e) {
        return Promise.reject(e);
    }
};

const postNativeObjectChildren = async (parent_id: string, child_ids: string[]): Promise<any> => {
    try {
        const parentObjectId = await convertNativeIdToStoredId(parent_id);

        let childIds: string[] = [];
        for (const child_id of child_ids) {
            try {
                const stored_child_id = await convertNativeIdToStoredId(child_id);
                childIds.push(stored_child_id)
            } catch (e) {}
        }
        await postSeveralObjectChildrenWithoutConversion(parentObjectId, childIds);
        return null;
    } catch (err) {
        console.error(err);
        return Promise.reject(err);
    }
};

const postObjectChildrenWOChildConvert = async (parent_id: string, child_id: string): Promise<any> => {
    try {
        // parent_id = await convertNativeIdToStoredId(parent_id); // [IM] Can be removed, because parent_id is stored id already?
        return await ObjectsService.postObjectChildren(parent_id, child_id, {
            source: "apple_home",
            origin: OriginType.NATIVE
        });
    } catch (err) {
        console.error(err);
        return Promise.reject(err);
    }
};

const postSeveralObjectChildrenWithoutConversion = async (parent_id: string, child_ids: string[]): Promise<any> => {
    try {
        return await ObjectsService.postSeveralObjectChildren(parent_id, child_ids, {
            source: "apple_home",
            origin: OriginType.NATIVE,
            sid: subscriptionId
        });
    } catch (err) {
        console.error(err);
        return Promise.reject(err);
    }
};

const deleteChildObjectsWithoutConvertChildObjects = async (parentId: string, childIds: string[]): Promise<any> => {
    try {
        return await ObjectsService.deleteChildObjects(parentId, childIds, {origin: OriginType.NATIVE});
    } catch (err) {
        console.error(err);
        return Promise.reject(err);
    }
};

const deleteNativeObjects = async (objectIds: string[]): Promise<any> => {
    try {
        return await ObjectsService.deleteObjects(objectIds, {origin: OriginType.NATIVE});
    } catch (err) {
        console.error(err);
        return Promise.reject(err);
    }
};

const handleEnterForeground = () => {
    return void ObjectsService.forceSync();
};

// >>>>> External Interface for DeviceService

const isAvailableNativeCamera = () => {
    return isHandlerExist(DeviceHandler.GET_QR_DATA);
};

type MESSAGE_NATIVE_PROPERTIES = {
    [k: string]: MESSAGE_NATIVE_PROPERTY
};

type MESSAGE_NATIVE_PROPERTY = {
    value: PropertyItemValue;
    reported_value: PropertyItemValue;
    property_id: string;
    type: string;
};

const publishPropertiesChangesToNative = async (object: ObjectItem) => {
    if ((object.source !== "apple_home" && object.object_type !== "IPCamera") || !object.object_id || !isDevice()) {
        return;
    }

    const properties = object.properties || {};

    const writablePropertyKeys = Object.keys(properties).filter((key) => {
        if (!object.properties.hasOwnProperty(key)) {
            return false;
        }
        const property = properties[key];
        return !!property?.writable;
    });

    if (!writablePropertyKeys.length) {
        return;
    }

    try {
        const nativeId = await convertStoredIdToNativeId(object.object_id);

        const propertiesToNative: MESSAGE_NATIVE_PROPERTIES = {};

        for (const key of writablePropertyKeys) {
            if (!properties.hasOwnProperty(key)) {
                continue;
            }

            propertiesToNative[key] = removeUnacceptableFields(properties[key],"native_properties") as MESSAGE_NATIVE_PROPERTY;
        }

        sendMessageToDevice(DeviceHandler.UPDATE_OBJECT_PROPERTIES, {
            objectId: nativeId,
            payload: JSON.stringify(propertiesToNative),
        });
    } catch (e) {
        console.error(e);
    }
};

const getCurrentDeviceId = async () => {
    try {
        if (isDevice()) {
            return await convertNativeIdToStoredId(getDeviceId());
        } else {
            return "";
        }

    } catch (err) {
        console.error(err);
        return Promise.reject(err);
    }
};

export {
    getNativeObjects,
    getNativeObjectById,
    getDetectedObjectById,
    postNativeObject,
    postNativeObjectKeepAlive,
    saveNativeObjectPropertiesToS3,
    postNativeObjectProperties,
    pubNativeObjectProperties,
    postNativeObjectsHierarchy,
    postNativeObjectChildren,
    postObjectChildrenWOChildConvert,
    postSeveralObjectChildrenWithoutConversion,
    deleteChildObjectsWithoutConvertChildObjects,
    deleteNativeObjects,
    isAvailableNativeCamera,
    publishPropertiesChangesToNative,
    getCurrentDeviceId,
    handleEnterForeground,
};

export {
    updateCache
} from "./ObjectsIdentificationService/ObjectsIdentificationService";
