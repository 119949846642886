import React, {createContext, useContext, useState} from "react";

// Global loader
const GlobalLoaderContext = createContext<any>(undefined)
const GlobalLoaderActionsContext = createContext<any>(undefined)
const useGlobalLoaderContext = () => useContext(GlobalLoaderContext)
const useGlobalLoaderActionsContext = () => useContext(GlobalLoaderActionsContext)

// Dialogs
const GlobalDialogContext = createContext<any>(undefined)
const UpdateGlobalDialogActionsContext = createContext<any>(undefined)
const useGlobalDialogContext = () => useContext(GlobalDialogContext)
const useUpdateGlobalDialogActionsContext = () => useContext(UpdateGlobalDialogActionsContext)

// Dialog content
const GlobalDialogContentContext = createContext<any>(undefined)
const UpdateGlobalDialogContentActionsContext = createContext<any>(undefined)
const useGlobalDialogContentContext = () => useContext(GlobalDialogContentContext)
const useUpdateGlobalDialogContentActionsContext = () => useContext(UpdateGlobalDialogContentActionsContext)

type GlobalContextProviderProps = {
    children: React.ReactNode
}

const GlobalContextProvider = (props: GlobalContextProviderProps) => {

    const [globalLoaderOn, setGlobalLoaderOn] = useState(false)
    const [globalDialog, setGlobalDialog] = useState<string | undefined>(undefined)
    const [globalDialogContent, setGlobalDialogContent] = useState<string | undefined>(undefined)
    return (

    <GlobalLoaderContext.Provider value={globalLoaderOn}>
            <GlobalLoaderActionsContext.Provider value={setGlobalLoaderOn}>
                <GlobalDialogContext.Provider value={globalDialog}>
                    <UpdateGlobalDialogActionsContext.Provider value={setGlobalDialog}>
                        <GlobalDialogContentContext.Provider value={globalDialogContent}>
                            <UpdateGlobalDialogContentActionsContext.Provider value={setGlobalDialogContent}>
                                {props.children}
                            </UpdateGlobalDialogContentActionsContext.Provider>
                        </GlobalDialogContentContext.Provider>
                    </UpdateGlobalDialogActionsContext.Provider>
                </GlobalDialogContext.Provider>
            </GlobalLoaderActionsContext.Provider>
        </GlobalLoaderContext.Provider>
    )
}

export {
    GlobalContextProvider,
    useGlobalLoaderContext,
    useGlobalLoaderActionsContext,
    useGlobalDialogContext,
    useUpdateGlobalDialogActionsContext,
    useGlobalDialogContentContext,
    useUpdateGlobalDialogContentActionsContext,
}
