import {getDeviceId, getUserId} from "./UserService";
import {
    checkIsOnline,
    copy,
    getDataExpirationTime,
    getEpochTime,
    getObjectExpirationTime,
    getObjectServiceLifeNotifications,
    getPropertyFields,
    isObjectPublic,
    isObjectsHasEqualFieldsValue,
    isUserHasAccess,
    mergeDictionary,
    mergeObjectChildren,
    mergeObjectNativeIdData,
    mergePermissions,
    removeUnacceptableFields
} from "./Utils";
import type {
    BodyObjectItem,
    ChildItemType,
    ObjectItem,
    Params,
    Permissions,
    ResponseOLSType
} from "./ObjectsService/Types";
import {
    AccessLevel,
    EMPTY_OBJECT,
    OBJECT_TYPE,
    OriginType,
    ROOT_TYPES,
    CHAT_NOTIFICATION,
    PROPERTIES_WITH_NOTIFICATION,
    PROPERTY_KEYS_FOR_TIME_TRANSFORM,
    SubscriptionType
} from "./ObjectsService/Constants"
import {generateObjectEncryptionKey} from "./CryptoService";

import AsyncLock from "async-lock";
import {
    checkConnectToDB,
    generateLocalId,
    getCurrentUserId,
    getTransaction
} from "./DatabaseService";
import {convertChildrenToStringArray, isAllowedObjectSharing} from "./ObjectsService";

let objectCache: { [key: string]: any } = {};
let lockObjectCache = new AsyncLock();

// >>> Service Utils >>>

const isObjectCached = (id: string) => {
    return objectCache.hasOwnProperty(generateLocalId(id));
};

const getIconByType = (objectType: string) => {
    switch (objectType) {
        case "Conversation":
            return "fas fa-comments";
        case "Building":
            return "fas fa-building";
        case "Floor":
            return "fas fa-layer-group";
        case "Room":
            return "fab fa-buromobelexperte";
        case "Zone":
            return "far fa-object-group";
        case "IPCamera":
            return "fas fa-video";
        case "Human":
            return "fas fa-user";
        case "Dog":
            return "fas fa-dog";
        case "Cat":
            return "fas fa-cat";
        case "Machine":
            return "fas fa-microchip";
        case "LiveStreaming":
            return "fas fa-video";
        case "ObjectDetection":
            return "fas fa-running";
        case "Car":
            return "fas fa-car";
        case "Tv":
            return "fas fa-tv";
        case "Document":
            return "fas fa-file-alt";
        case "File":
            return "fas fa-file";
        default:
            return "fas fa-info-circle";
    }
};

const getObjectFromCacheOrIndexDB = (id: string, params: Params): Promise<ObjectItem> => {
    if (isObjectCached(id)) {
        const objectCached = objectCache[generateLocalId(id)];
        return Promise.resolve(objectCached && copy(objectCached));
    } else {
        return getObjectFromIndexedDB(id, params);
    }
};

const postObjectToIndexedDB = async (object: ObjectItem) => {
    try {
        await checkConnectToDB();
    } catch (e) {
        return Promise.reject(e);
    }

    // const transaction = db.transaction("objects", "readwrite");
    const transaction = getTransaction("objects", "readwrite");
    const objects = transaction.objectStore("objects");
    const request = objects.put(removeUnacceptableFields(object, "local_object"));

    request.onerror = () => {
        console.error("Error postObjectToIndexedDB " + object.object_id + ": " + request.error);
    };

    // request.onsuccess = () => {
    //     // console.log("Onsuccess postObjectToIndexedDB: ", object.object_id);
    // };
    // transaction.oncomplete = () => {
    //     // console.log("Oncomplete postObjectToIndexedDB: ", object.object_id);
    // }
};

const deleteObjectToIndexedDB = async (objectId: string) => {
    try {
        await checkConnectToDB();
    } catch (e) {
        return Promise.reject(e);
    }

    // const transaction = db.transaction("objects", "readwrite");
    const transaction = getTransaction("objects", "readwrite");
    const objects = transaction.objectStore("objects");
    const request = objects.delete(objectId);

    request.onerror = function () {
        console.error("Error deleteObjectToIndexedDB: ", request.error);
    };

    // request.onsuccess = () => {
    //     // console.log("Onsuccess deleteObjectToIndexedDB: ", objectId);
    // };
    // transaction.oncomplete = () => {
    //     // console.log("Oncomplete deleteObjectToIndexedDB: ", objectId);
    // }
};

const getObjectFromIndexedDB = (id: string, params?: Params): Promise<ObjectItem> => {
    return new Promise(async (resolve, reject) => {
        try {
            await checkConnectToDB();
        } catch (e) {
            return reject(e);
        }

        // const request = db.transaction("objects", "readonly").objectStore("objects").get(generateLocalId(id));
        const request = getTransaction("objects", "readonly").objectStore("objects").get(generateLocalId(id));
        request.onsuccess = async function () {
            let object = request.result && copy(request.result);
            if (object === undefined) {
                objectCache[generateLocalId(id)] = undefined;
                reject("Object " + generateLocalId(id) + " not found");
            }

            objectCache[generateLocalId(id)] = object && copy(object);

            if (object && (!object.deleted || params?.includeDeleted) && (isUserHasAccess(object || undefined, AccessLevel.READ))) {
                resolve(object);
            } else {
                reject("Object " + generateLocalId(id) + " not found");
            }
        };

        request.onerror = function () {
            console.log("Error: ", request.error);
            reject(request.error);
        };
    });
};

const getLocalObjectById = (id: string, params?: Params): Promise<ObjectItem> => {
    return new Promise(async (resolve, reject) => {
        const objectPreprocessing = (object: ObjectItem) => {
            let parsedParams = {
                includeDeleted: !!params?.includeDeleted,
                includePublic: !!params?.includePublic,
                skipChildrenTransform: !!params?.skipChildrenTransform
            };

            if (
                object &&
                (!object.deleted || parsedParams.includeDeleted) &&
                (!isObjectPublic(object.permissions) || parsedParams.includePublic || isUserHasAccess(object || undefined, AccessLevel.READ))
            ) {
                if (!parsedParams.skipChildrenTransform) {
                    object.children = convertChildrenToStringArray(object?.children);
                }
                resolve(object);
            } else {
                reject("Object " + generateLocalId(id) + " not found");
            }
        };

        if (isObjectCached(id)) {
            let object = objectCache[generateLocalId(id)] && copy(objectCache[generateLocalId(id)]);
            objectPreprocessing(object);
        } else {
            try {
                await checkConnectToDB();
            } catch (e) {
                return reject(e);
            }

            // const request = db.transaction("objects", "readonly").objectStore("objects").get(generateLocalId(id));
            const request = getTransaction("objects", "readonly").objectStore("objects").get(generateLocalId(id));
            request.onsuccess = async function () {
                let object = request.result && copy(request.result);
                if (object === undefined) {
                    objectCache[generateLocalId(id)] = undefined;
                    reject("Object " + generateLocalId(id) + " not found");
                }

                objectCache[generateLocalId(id)] = object&& copy(object);
                objectPreprocessing(object);
            };

            request.onerror = function () {
                console.log("Error: ", request.error);
                reject(request.error);
            };
        }
    });
};

const getObjectByType = (types: string[], lastId: string, params: Params): Promise<ObjectItem[]> => {
    return new Promise(async (resolve, reject) => {
        try {
            await checkConnectToDB();
        } catch (e) {
            return Promise.reject(e);
        }

        // const dateIndex = db.transaction("objects", "readonly").objectStore("objects").index("created");
        const dateIndex = getTransaction("objects", "readonly").objectStore("objects").index("created");
        const request = dateIndex.openCursor(null, 'next');

        let response: ObjectItem[] = [];
        let isContinueByLastId = false;

        let parsedParams = {
            includeDeleted: !!params?.includeDeleted,
            includePublic: !!params?.includePublic,
            includeOnlyRootObjects: !!params?.includeOnlyRootObjects,
        };

        request.onsuccess = function () {
            let cursor = request.result;
            if (cursor && (!params?.limit || response.length < params?.limit || params?.includeOnlyRootObjects)) {
                if (cursor.value.local_id.includes(getCurrentUserId())) {
                    if (
                        lastId &&
                        lastId !== cursor.primaryKey &&
                        !isContinueByLastId &&
                        (!isObjectPublic(cursor.value.permissions) || cursor.value.owner_id === getUserId()) &&
                        !cursor.value.deleted
                    ) {
                        return cursor.continue();
                    } else
                     if (lastId === cursor.primaryKey) {
                        isContinueByLastId = true;
                        return cursor.continue();
                    }
                    if (
                        (!cursor.value.deleted || parsedParams.includeDeleted) &&
                        (!isObjectPublic(cursor.value.permissions)  || parsedParams.includePublic || cursor.value.owner_id === getUserId()) &&
                        types.includes(cursor.value.object_type) &&
                        (!params.insideHierarchy || (params.insideHierarchy as string [])?.includes(cursor.value.object_id))
                    ) {
                        response.push(cursor.value);
                    }
                }
                cursor.continue();
            } else {
                const updatedResponse = response.map((objectItem: ObjectItem) => {
                    if (objectItem.object_id && isObjectCached(objectItem.object_id)) {
                        return objectCache[generateLocalId(objectItem.object_id)] && copy(objectCache[generateLocalId(objectItem.object_id)]);
                    } else {
                        return objectItem;
                    }
                });

                if (params?.includeOnlyRootObjects) {
                    const childObjectIds = new Set();
                    updatedResponse.forEach((objectItem: ObjectItem) => {
                        objectItem.children?.forEach((child) => {
                            const childItem = child as unknown as ChildItemType;
                            if (getEpochTime(childItem?.bind) > getEpochTime(childItem?.unbind)) {
                                childObjectIds.add(childItem.id);
                            }
                        });
                    });

                    const onlyRootObjects = updatedResponse.filter((objectItem: ObjectItem) => {
                        return !childObjectIds.has(objectItem.object_id);
                    });

                    return resolve(onlyRootObjects);
                }

                resolve(updatedResponse);
            }
        };

        request.onerror = function () {
            console.log("Error: ", request.error);
            reject(request.error);
        };
    });
};

const getObjectBySource = (source: string): Promise<ObjectItem[]> => {
    return new Promise(async (resolve, reject) => {
        try {
            await checkConnectToDB();
        } catch (e) {
            return Promise.reject(e);
        }

        // const sourceIndex = db.transaction("objects", "readonly").objectStore("objects").index("source");
        const sourceIndex = getTransaction("objects", "readonly").objectStore("objects").index("source");
        const request = sourceIndex.openCursor(source);

        let response: ObjectItem[] = [];

        request.onsuccess = function () {
            let cursor = request.result;
            if (cursor) {
                if (cursor.value.local_id.includes(getCurrentUserId()) && !cursor.value.deleted) {
                    response.push(cursor.value);
                }
                cursor.continue();
            } else {
                const updatedResponse = response.map((objectItem: ObjectItem) => {
                    if (objectItem.object_id && isObjectCached(objectItem.object_id)) {
                        return objectCache[generateLocalId(objectItem.object_id)] && copy(objectCache[generateLocalId(objectItem.object_id)]);
                    } else {
                        return objectItem;
                    }
                });
                resolve(updatedResponse);
            }
        };

        request.onerror = function () {
            console.log("Error: ", request.error);
            reject(request.error);
        };
    });
};

const getLocalObjectByNativeIdentificationDataAndObjectType = (nativeIdentificationData: string, objectType: string): Promise<ObjectItem[]> => {
    return new Promise(async (resolve, reject) => {
        try {
            await checkConnectToDB();
        } catch (e) {
            return Promise.reject(e);
        }

        // const index = db.transaction("objects", "readonly").objectStore("objects").index("native_identification_data, object_type");
        const index = getTransaction("objects", "readonly").objectStore("objects").index("native_identification_data, object_type");
        const request = index.openCursor([nativeIdentificationData, objectType]);

        let response: ObjectItem[] = [];

        request.onsuccess = function () {
            let cursor = request.result;
            if (cursor) {
                if (cursor.value.local_id.includes(getCurrentUserId()) && isUserHasAccess(cursor.value, AccessLevel.OWNER) && !cursor.value.deleted) {
                    response.push(cursor.value);
                }
                cursor.continue();
            } else {
                const updatedResponse = response.map((objectItem: ObjectItem) => {
                    if (objectItem.object_id && isObjectCached(objectItem.object_id)) {
                        return objectCache[generateLocalId(objectItem.object_id)] && copy(objectCache[generateLocalId(objectItem.object_id)]);
                    } else {
                        return objectItem;
                    }
                });
                resolve(updatedResponse);
            }
        };

        request.onerror = function () {
            console.log("Error getObjectByNativeIdentificationData: ", request.error);
            reject(request.error);
        };
    });
};

const getObjectByFavoriteState = (lastId: string, params: Params): Promise<ObjectItem[]> => {
    return new Promise(async (resolve, reject) => {
        try {
            await checkConnectToDB();
        } catch (e) {
            return Promise.reject(e);
        }

        const dateIndex = getTransaction("objects", "readonly").objectStore("objects").index("favorites_state");
        const request = dateIndex.openCursor(1);

        let response: ObjectItem[] = [];
        let isContinueByLastId = false;

        const parsedParams = {
            includeDeleted: !!params?.includeDeleted,
            includePublic: !!params?.includePublic,
        };

        request.onsuccess = function () {
            let cursor = request.result;
            if (cursor && (!params?.limit || response.length < params?.limit)) {
                if (cursor.value.local_id.includes(getCurrentUserId())) {
                    if (
                        lastId &&
                        lastId !== cursor.primaryKey &&
                        !isContinueByLastId &&
                        (!isObjectPublic(cursor.value.permissions) || cursor.value.owner_id === getUserId()) &&
                        !cursor.value.deleted
                    ) {
                        return cursor.continue();
                    } else if (lastId === cursor.primaryKey) {
                        isContinueByLastId = true;
                        return cursor.continue();
                    }
                    if ((!cursor.value.deleted || parsedParams.includeDeleted) &&
                        (!params.insideHierarchy || (params.insideHierarchy as string [])?.includes(cursor.value.object_id))
                    ) {
                        response.push(cursor.value);
                    }
                }
                cursor.continue();
            } else {
                const updatedResponse = response.map((objectItem: ObjectItem) => {
                    if (objectItem.object_id && isObjectCached(objectItem.object_id)) {
                        return objectCache[generateLocalId(objectItem.object_id)] && copy(objectCache[generateLocalId(objectItem.object_id)]);
                    } else {
                        return objectItem;
                    }
                });
                resolve(updatedResponse);
            }
        };

        request.onerror = function () {
            console.log("Error: ", request.error);
            reject(request.error);
        };
    });
};

const getObjectBySharedAsRoot = (lastId: string, params: Params): Promise<ObjectItem[]> => {
    return new Promise(async (resolve, reject) => {
        try {
            await checkConnectToDB();
        } catch (e) {
            return Promise.reject(e);
        }

        const dateIndex = getTransaction("objects", "readonly").objectStore("objects").index("shared_as_root");
        const request = dateIndex.openCursor(1);

        let response: ObjectItem[] = [];
        let isContinueByLastId = false;

        const parsedParams = {
            includeDeleted: !!params?.includeDeleted,
            includePublic: !!params?.includePublic,
        };

        request.onsuccess = function () {
            let cursor = request.result;
            if (cursor && (!params?.limit || response.length < params?.limit)) {
                if (cursor.value.local_id.includes(getCurrentUserId())) {
                    if (
                        lastId &&
                        lastId !== cursor.primaryKey &&
                        !isContinueByLastId &&
                        (!isObjectPublic(cursor.value.permissions) || cursor.value.owner_id === getUserId()) &&
                        !cursor.value.deleted
                    ) {
                        return cursor.continue();
                    } else if (lastId === cursor.primaryKey) {
                        isContinueByLastId = true;
                        return cursor.continue();
                    }
                    if ((!cursor.value.deleted || parsedParams.includeDeleted) &&
                        (!params.insideHierarchy || (params.insideHierarchy as string [])?.includes(cursor.value.object_id))
                    ) {
                        response.push(cursor.value);
                    }
                }
                cursor.continue();
            } else {
                const updatedResponse = response.map((objectItem: ObjectItem) => {
                    if (objectItem.object_id && isObjectCached(objectItem.object_id)) {
                        return objectCache[generateLocalId(objectItem.object_id)] && copy(objectCache[generateLocalId(objectItem.object_id)]);
                    } else {
                        return objectItem;
                    }
                });
                resolve(updatedResponse);
            }
        };

        request.onerror = function () {
            console.log("Error: ", request.error);
            reject(request.error);
        };
    });
};

const buildDataToPostObject = async (inputObject: BodyObjectItem, dbObject: ObjectItem | null, options: {origin?: OriginType, saveEncryptionKey?: boolean, parentOwnerId?: string}) => {
    const events: SubscriptionType[] = [];
    const epoch = inputObject.updated || Date.now();
    const currentUser = getCurrentUserId();

    const { origin, saveEncryptionKey, parentOwnerId } = options;

    let isCreateObject = false;
    let isInternalEncryptionKey = true;
    let externalEncryptionKey = null;

    if (inputObject.hasOwnProperty("encryption_key") || saveEncryptionKey) {
        isInternalEncryptionKey = false;
        externalEncryptionKey = inputObject.encryption_key || null;
        if (!saveEncryptionKey) {
            delete inputObject.encryption_key;
        }
    }

    PROPERTY_KEYS_FOR_TIME_TRANSFORM.forEach((propertyKey) => {
        if (inputObject.properties?.hasOwnProperty(propertyKey) && inputObject.properties[propertyKey].value) {
            const value = inputObject.properties[propertyKey].value;
            inputObject.properties[propertyKey].value = new Date(value).toISOString();
        }
    });

    if (inputObject.children) {
        events.push(SubscriptionType.CHILDREN);
    }

    if (inputObject.hasOwnProperty("last_active") || inputObject.hasOwnProperty("primary_client") || inputObject.hasOwnProperty("reachable")) {
        if (origin === OriginType.NATIVE && dbObject && dbObject.primary_client?.id !== getDeviceId() && checkIsOnline(dbObject.last_active) ) {
            delete inputObject.last_active;
            delete inputObject.primary_client;
            delete inputObject.reachable;
        } else {
            if ((origin !== OriginType.NATIVE && !checkIsOnline(dbObject?.last_active || 0) && checkIsOnline(inputObject?.last_active || 0)) ||
                (dbObject?.reachable === false && inputObject.reachable === true))
            {
                events.push(SubscriptionType.NOTIFICATION_STATUS);
            }
            events.push(SubscriptionType.STATUS);
        }
    }

    if (inputObject.native_id) {
        inputObject.native_id = mergeObjectNativeIdData(inputObject.native_id, dbObject?.native_id);
    }

    if (inputObject?.favorites?.[currentUser]) {
        const favorites_data = {
            state: inputObject?.favorites?.[currentUser]?.state,
            updated: inputObject?.favorites?.[currentUser]?.updated
        };

        inputObject.favorites_state = favorites_data.state ? 1 : 0;
        inputObject.favorites = {[currentUser]: favorites_data};
    }

    if (inputObject.permissions?.private_acl) {
        for (const key in inputObject.permissions.private_acl) {
            if (!inputObject.permissions.private_acl.hasOwnProperty(key)) {
                continue;
            }

            if (inputObject.permissions.private_acl[key].removed) {
                delete inputObject.permissions.private_acl[key];
                continue;
            }

            inputObject.permissions.private_acl[key] = removeUnacceptableFields(inputObject.permissions.private_acl[key], "private_acl");
        }
    }

    if (inputObject.permissions?.private_acl?.[currentUser]?.root) {
        inputObject.shared_as_root = 1;
    } else if (inputObject.shared_as_root) {
        delete inputObject.shared_as_root;
    }

    if (dbObject) {
        if (inputObject.properties) {
            inputObject.properties = Object.values(inputObject.properties).reduce((dbProperties, updateProperty) => {
                dbProperties[updateProperty.key] = updateProperty;
                return dbProperties;
            }, dbObject.properties);
        }

        const srcObject: ObjectItem = copy(dbObject);
        const isChildrenUpdated: boolean = mergeObjectChildren(srcObject, inputObject);

        if (!isChildrenUpdated) {
            const index = events.indexOf(SubscriptionType.CHILDREN);

            if (index !== -1) {
                events.splice(index, 1);
            }
        }

    } else {
        console.log("Create new Object ", generateLocalId(inputObject.object_id as string));

        isCreateObject = true;

        const encryptionKey = isInternalEncryptionKey ? await generateObjectEncryptionKey() : externalEncryptionKey;
        const currentUser = getUserId();

        inputObject.object_type = inputObject.object_type || OBJECT_TYPE.UNKNOWN;
        inputObject.object_name = inputObject.object_name || OBJECT_TYPE.UNKNOWN;

        inputObject = Object.assign({
            local_id: generateLocalId(inputObject.object_id as string),
            owner_id: parentOwnerId || currentUser,
            icon: getIconByType(inputObject.object_type as string),
            created: inputObject.created || epoch,
            properties: {},
            permissions: parentOwnerId && parentOwnerId !== currentUser ? { private_acl: { [currentUser]: { access_level: AccessLevel.WRITE, updated: epoch } } } : {},
            children: [],
            encryption_key: encryptionKey
        }, inputObject);

        if (!inputObject.object_type) {
            return {
                err: `${inputObject.object_id}: Object type required!`
            };
        }

        if (!inputObject.object_name) {
            return {
                err: `${inputObject.object_id}: Object name required!`
            };
        }

        if (inputObject.children) {
            inputObject = {
                ...inputObject,
                children: (inputObject.children as any).map((child: string|ChildItemType) => {
                    let typeChild = typeof child;
                    if (typeChild === "string") {
                        return {id: child, bind: (inputObject as ObjectItem).created || epoch};
                    } else if (typeChild === "object") {
                        return child;
                    }
                    return null;
                }).filter((el: ChildItemType|null)=>!!el),
            };
        }

        events.push(SubscriptionType.NEW);

        if (ROOT_TYPES.includes(inputObject.object_type as string)) {
            events.push(SubscriptionType.OBJECTS_TYPE);
        }
    }

    if (inputObject.properties) {
        for (const key in inputObject.properties) {
            if (!inputObject.properties.hasOwnProperty(key)) {
                continue;
            }

            if (inputObject.properties[key].removed) {
                delete inputObject.properties[key];
                continue;
            }

            inputObject.properties[key] = removeUnacceptableFields(inputObject.properties[key], "properties");
        }

        const expirationTime = getObjectExpirationTime(inputObject.properties);
        if (expirationTime) {
            inputObject.expiration_time = expirationTime;
        }

        const serviceLifeNotifications = getObjectServiceLifeNotifications(inputObject.object_name || "", inputObject.properties);

        if (serviceLifeNotifications) {
            inputObject.service_life_notifications = serviceLifeNotifications;
        }

        events.push(SubscriptionType.PROPERTIES);
    }

    if (inputObject.notifications) {
        events.push(SubscriptionType.NOTIFICATION_STATE);
    }

    if (inputObject.favorites?.[currentUser]) {
        events.push(SubscriptionType.FAVORITES);
    }

    if (inputObject.deleted && !dbObject?.deleted) {
        events.push(SubscriptionType.DELETE);
        if (dbObject?.observer_id) {
            events.push(SubscriptionType.NOTIFICATION_LOST_OBJECT);
        }
        if (ROOT_TYPES.includes(dbObject?.object_type as string)) {
            events.push(SubscriptionType.OBJECTS_TYPE);
        }
    }

    return {
        object: !dbObject ? inputObject : Object.assign(dbObject, inputObject),
        events: [...new Set(events)],
        saveKeyRequired: isCreateObject && isInternalEncryptionKey,
        err: null,
    }
};

const postLocalObject = (_object: BodyObjectItem, params?: Params, origin?: OriginType): Promise<ResponseOLSType> => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await lockObjectCache.acquire(_object.object_id as string, async (): Promise<ResponseOLSType> => {
                let object: ObjectItem | null = null;

                try {
                    object = await getObjectFromCacheOrIndexDB(_object.object_id as string, { includeDeleted: true });
                } catch(e) {}

                if (!object) {
                    console.log("Object " + generateLocalId(_object.object_id as string) + " not found");
                    object = null;
                }

                if (object && !isUserHasAccess(object || undefined, AccessLevel.WRITE) && origin === OriginType.USER) {
                    return Promise.reject("You can not update object (" + generateLocalId(_object.object_id as string) + ")");
                }

                if (object?.deleted && origin === OriginType.USER) {
                    return Promise.reject("You can not update removed object (" + generateLocalId(_object.object_id as string) + ")");
                }
                let postObjectData;
                try {
                    let _params: {origin?: OriginType, saveEncryptionKey?: boolean, parentOwnerId?: string} = {
                        origin: origin,
                        saveEncryptionKey: !!params?.saveEncryptionKey
                    };
                    if (params?.parentOwnerId) {
                        _params.parentOwnerId = params.parentOwnerId.toString();
                    }
                    postObjectData = await buildDataToPostObject(_object, object, _params);
                } catch (e) {
                    return Promise.reject(e);
                }

                if (postObjectData.err) {
                    return Promise.reject(postObjectData.err);
                }

                let copyObject: ObjectItem = postObjectData.object && copy(postObjectData.object);
                objectCache[generateLocalId(copyObject.object_id)] = copyObject;

                postObjectToIndexedDB(copy(postObjectData.object) as ObjectItem).then(() => {});

                const encryptionKey = postObjectData?.object?.encryption_key || null;
                delete postObjectData?.object?.encryption_key;

                return {
                    object: postObjectData.object,
                    events: postObjectData.events,
                    encryption: {
                        encryptionKey: encryptionKey,
                        saveKeyRequired: postObjectData?.saveKeyRequired,
                        isPublic: isObjectPublic(postObjectData?.object?.permissions)
                    }
                } as ResponseOLSType;
            });
            resolve(response);
        } catch (e) {
            reject(e);
        }
    });
};

const deleteLocalObject = (id: string, epoch: number, params?: Params, origin?: OriginType): Promise<ResponseOLSType> => {
    return new Promise(async (resolve, reject) => {
        let events: SubscriptionType[] = [SubscriptionType.DELETE];
        const tplObject: ObjectItem = copy(EMPTY_OBJECT);
        try {
            const response = await lockObjectCache.acquire(id, async (): Promise<ResponseOLSType> => {
                const parsedParams = {
                    forceDelete: !!params?.forceDelete
                };

                let object: ObjectItem | null = null;

                try {
                    object = await getObjectFromCacheOrIndexDB(id, { includeDeleted: true });
                } catch(e) {}

                if (!parsedParams.forceDelete) {
                    if (!object) {
                        return Promise.reject("Object not found for deleteLocalObject");
                    } else if (!isUserHasAccess(object || undefined, AccessLevel.WRITE) && origin === OriginType.USER) {
                        return Promise.reject("You have no permissions for this operation");
                    }
                    if (object && object.observer_id) {
                        tplObject.observer_name = object.observer_name;
                        tplObject.observer_id = object.observer_id;
                        tplObject.object_name = object.object_name;
                        events.push(SubscriptionType.NOTIFICATION_LOST_OBJECT);
                    }
                }

                const expiryDate = getDataExpirationTime();
                let _object;

                if (object && !parsedParams.forceDelete && (!object.deleted || (object.deleted && new Date(object.updated || 0).getTime() > expiryDate))) {
                    Object.assign(object, {deleted: true, updated: epoch});

                    let copyObject: ObjectItem = object && copy(object);
                    objectCache[generateLocalId(copyObject.object_id)] = copyObject;

                    postObjectToIndexedDB(object).then(() => {});

                    _object = copy(object);
                } else {
                    delete objectCache[generateLocalId(id)];
                    deleteObjectToIndexedDB(generateLocalId(id)).then(() => {});

                    _object = { ...(object || tplObject), object_id: id, deleted: true, updated: epoch };

                    if (ROOT_TYPES.includes(_object.object_type as string)) {
                        events.push(SubscriptionType.OBJECTS_TYPE);
                    }
                }

                return({ object: _object, events });
            });
            resolve(response);
        } catch (e) {
            reject(e);
        }
    });
};

const getLocalObjects = (params: Params): Promise<ObjectItem[] | any> => {
    return new Promise(async (resolve, reject) => {
        try {
            await checkConnectToDB();
        } catch (e) {
            return reject(e);
        }

        try {
            let lastId: string | null = null;
            let objects: ObjectItem[] = [];

            if (Array.isArray(params.children)) {
                for (let id of params.children as string[]) {
                    let object: ObjectItem;

                    try {
                        object = await getLocalObjectById(id, { includeDeleted: !!params.includeDeleted, includePublic: !!params.publicRequest, skipChildrenTransform: true });
                    } catch (err) {
                        continue;
                    }

                    if (!params.object_type || (params.object_type as string[]).includes(object.object_type)) {
                        objects.push(object);
                    }
                }
            } else if (Array.isArray(params.object_type)) {
                let responseObjects: ObjectItem[] = await getObjectByType(params.object_type as string[], params.last_id as string, {
                    includeDeleted: false,
                    includePublic: !!params.publicRequest,
                    insideHierarchy: params.insideHierarchy,
                    includeOnlyRootObjects: !!params.includeOnlyRootObjects,
                    limit: params.limit
                });
                objects = objects.concat(responseObjects);
                lastId = objects.length > 0 ? (objects.slice(-1)[0] as any)?.local_id : null;
            } else if (params.source) {
                objects = await getObjectBySource(params.source as string);
            } else if (params.favorites) {
                const responseObjects = await getObjectByFavoriteState(params.last_id as string, {
                    includeDeleted: false,
                    includePublic: !!params.publicRequest,
                    insideHierarchy: params.insideHierarchy,
                    limit: params.limit
                });
                objects = [...responseObjects, ...objects];
                lastId = objects.length > 1 ? (objects.slice(-1)[0] as any)?.local_id : null;
            } else if (params.shared_as_root) {
                const responseObjects = await getObjectBySharedAsRoot(params.last_id as string, {
                    includeDeleted: false,
                    includePublic: !!params.publicRequest,
                    insideHierarchy: params.insideHierarchy,
                    limit: params.limit
                });
                objects = [...responseObjects, ...objects];
                lastId = objects.length > 1 ? (objects.slice(-1)[0] as any)?.local_id : null;
            } else {
                let responseObjects: ObjectItem[] = await getAllObjects(params);
                objects = objects.concat(responseObjects);
                lastId = objects.length > 1 ? (objects.slice(-1)[0] as any)?.local_id : null;
            }

            if (!params.skipChildrenTransform) {
                for (let object of objects) {
                    if (!object) {
                        continue;
                    }
                    object.children = convertChildrenToStringArray(object?.children);
                }
            }
            objects = objects.filter((object) => {
                return !!object;
            });
            return resolve(
                params.by_page
                    ? {
                          last_id: lastId,
                          objects: objects,
                      }
                    : objects
            );
        } catch (err) {
            console.log(err);
            reject(err);
        }
    });
};

const getAllObjects = (params: Params): Promise<ObjectItem[]> => {
    return new Promise(async (resolve, reject) => {
        try {
            await checkConnectToDB();
        } catch (e) {
            return Promise.reject(e);
        }

        // const request = db.transaction("objects", "readonly").objectStore("objects").openCursor();
        const request = getTransaction("objects", "readonly").objectStore("objects").openCursor();

        let response: ObjectItem[] = [];
        let isContinueByLastId = false;


        let limit = params.limit || 9;

        request.onsuccess = function () {
            let cursor = request.result;
            if (cursor && (!params.by_page || (params.by_page && response.length < limit))) {
                if (params.by_page) {
                    if (params.last_id && (params.last_id as string) !== cursor.primaryKey && !isContinueByLastId) {
                        isContinueByLastId = true;
                        return cursor.continue(params.last_id as string);
                    } else if (params.last_id === cursor.primaryKey) {
                        return cursor.continue();
                    }
                }
                if (
                    cursor.value.local_id.includes(getCurrentUserId()) &&
                    (!cursor.value.deleted || params.includeDeleted) &&
                    (!isObjectPublic(cursor.value.permissions) || params.publicRequest) &&
                    (cursor.value.owner_id !== getUserId() || params.ownObjects !== false) &&
                    (!params.insideHierarchy || (params.insideHierarchy as string [])?.includes(cursor.value.object_id))
                ) {
                    response.push(cursor.value);
                }
                cursor.continue();
            } else {
                const updatedResponse = response.map((objectItem: ObjectItem) => {
                    if (objectItem.object_id && isObjectCached(objectItem.object_id)) {
                        return objectCache[generateLocalId(objectItem.object_id)] && copy(objectCache[generateLocalId(objectItem.object_id)]);
                    } else {
                        return objectItem;
                    }
                });

                resolve(updatedResponse);
            }
        };

        request.onerror = function () {
            console.log("Error: ", request.error);
            reject(request.error);
        };
    });
};

const updateLocalObjectProperties = (objectId: string, properties: { [string: string]: any }, epoch: number, origin?: OriginType): Promise<ResponseOLSType> => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await lockObjectCache.acquire(objectId, async (): Promise<ResponseOLSType> => {
                let object: ObjectItem = await getObjectFromCacheOrIndexDB(objectId, { includeDeleted: true });
                let events: SubscriptionType[] = [];
                const newProperties = copy(properties);
                // const resultProperties: PropertiesType = {};
                const expirationTime = getObjectExpirationTime(properties);

                if (!object) {
                    return Promise.reject("Object " + generateLocalId(objectId) + " not found for updateLocalObjectProperties");
                }

                if (!isUserHasAccess(object || undefined, AccessLevel.CONTROL) && origin === OriginType.USER) {
                    return Promise.reject("You can not updateLocalObjectProperties object (" + generateLocalId(objectId) + ")");
                }

                if (object?.deleted && origin === OriginType.USER) {
                    return Promise.reject("You can not update removed object (" + generateLocalId(objectId) + ")");
                }

                let dictionaryProperties = object.properties ? copy(object.properties) : {};

                for (const key in newProperties) {
                    if (!newProperties.hasOwnProperty(key)) {
                        continue;
                    }

                    if (PROPERTY_KEYS_FOR_TIME_TRANSFORM.includes(key) && newProperties?.[key]?.value) {
                        newProperties[key].value = new Date(newProperties[key].value).toISOString();
                    }

                    if (newProperties[key] && newProperties[key].removed) {
                        delete dictionaryProperties[key];
                        delete newProperties[key];
                        continue;
                    }

                    if (!dictionaryProperties[key]) {
                        dictionaryProperties[key] = {};
                    }

                    if (object.notifications?.state !== false && JSON.stringify(dictionaryProperties[key].value) !== JSON.stringify(newProperties[key].value)) {
                        if (PROPERTIES_WITH_NOTIFICATION.includes(dictionaryProperties[key]?.type)) {
                            events.push(SubscriptionType.NOTIFICATION_PROPERTIES);
                        } else if (CHAT_NOTIFICATION.includes(dictionaryProperties[key]?.type)) {
                            events.push(SubscriptionType.NOTIFICATION_CHAT_MESSAGE);
                        }
                    }

                    newProperties[key] = removeUnacceptableFields(newProperties[key], "properties");
                    dictionaryProperties[key] = removeUnacceptableFields(dictionaryProperties[key], "properties");

                    const dictionaryPropertyFields = getPropertyFields(dictionaryProperties[key]);
                    const newPropertyFields = getPropertyFields(newProperties[key]);

                    if (!isObjectsHasEqualFieldsValue(dictionaryPropertyFields, newPropertyFields)) {
                        dictionaryProperties[key] = mergeDictionary(dictionaryProperties[key], newPropertyFields);
                    }

                    if (newProperties[key].value !== undefined && newProperties[key].value !== dictionaryProperties[key].value) {
                        dictionaryProperties[key].value = newProperties[key].value;
                        dictionaryProperties[key].value_epoch = newProperties[key].value_epoch;
                        // resultProperties[key] = {...dictionaryProperties[key]};
                    }

                    if (newProperties[key].reported_value !== undefined && newProperties[key].reported_value !== dictionaryProperties[key].reported_value) {
                        dictionaryProperties[key].reported_value = newProperties[key].reported_value;
                        dictionaryProperties[key].reported_value_epoch = newProperties[key].reported_value_epoch;
                        // resultProperties[key] = {...dictionaryProperties[key]};
                    }

                    // if (newProperties[key].value !== undefined && newProperties[key].value !== dictionaryProperties[key].value ||
                    //     newProperties[key].reported_value !== undefined && newProperties[key].reported_value !== dictionaryProperties[key].reported_value) {
                    //     resultProperties[key] = Object.assign(dictionaryProperties[key], newProperties[key]);
                    // }
                }

                const updatedObject: {[k: string]: any} = {
                    properties: dictionaryProperties,
                    updated: getEpochTime(object.updated) >= epoch ? object.updated : epoch
                };

                if (expirationTime) {
                    updatedObject.expiration_time = expirationTime;
                }

                const serviceLifeNotifications = getObjectServiceLifeNotifications(object.object_name, properties);

                if (serviceLifeNotifications) {
                    updatedObject.service_life_notifications = serviceLifeNotifications;
                }

                Object.assign(object, updatedObject);

                if (!serviceLifeNotifications) {
                    delete object.service_life_notifications;
                }

                let copyObject: ObjectItem = object && copy(object);
                objectCache[generateLocalId(copyObject.object_id)] = copyObject;

                postObjectToIndexedDB(copy(object)).then(() => {});

                const encryptionKey = object?.encryption_key || null;
                delete object?.encryption_key;

                return {
                    object: {
                        ...object,
                        // properties: resultProperties
                    },
                    events: [...new Set(events)].concat([SubscriptionType.PROPERTIES]),
                    encryption: {
                        encryptionKey: encryptionKey,
                        saveKeyRequired: false,
                        isPublic: isObjectPublic(object?.permissions)
                    }
                };
            });
            resolve(response);
        } catch (err) {
            console.error(err);
            reject(err);
        }
    });
};

const unBindLocalObjectChildren = (parentId: string, childIds: string[], epoch: number): Promise<ResponseOLSType> => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await lockObjectCache.acquire(parentId, async (): Promise<ResponseOLSType> => {

                let object: ObjectItem = await getObjectFromCacheOrIndexDB(parentId, { includeDeleted: false });
                const events: SubscriptionType[] = [];

                if (!object || object.deleted || (!isUserHasAccess(object || undefined, AccessLevel.CONTRIBUTE)) ) {
                    return Promise.reject("Object " + generateLocalId(parentId) + " not found for unBindLocalObjectChildren");
                }

                Object.assign(object, {
                    updated: epoch,
                    children: (object as ObjectItem).children?.map((child: any) => {
                        if (childIds.includes(child.id)) {
                            return { ...child, unbind: epoch };
                        } else {
                            return child;
                        }
                    }),
                });

                events.push(SubscriptionType.CHILDREN);

                let copyObject: ObjectItem = object && copy(object);
                objectCache[generateLocalId(copyObject.object_id)] = copyObject;

                postObjectToIndexedDB(object).then(() => {});

                return {object: object, events: events} as ResponseOLSType;
            });
            resolve(response);
        } catch (e) {
            console.error(e);
            reject(e);
        }
    });
};

const bindLocalObjectChildren = (parentId: string, childIds: string[], epoch: number): Promise<ResponseOLSType> => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await lockObjectCache.acquire(parentId, async (): Promise<ResponseOLSType> => {

                let object: ObjectItem = await getObjectFromCacheOrIndexDB(parentId, { includeDeleted: false });
                const events: SubscriptionType[] = [];

                if (!object || object.deleted || (!isUserHasAccess(object || undefined, AccessLevel.CONTRIBUTE)) ) {
                    return Promise.reject("Object " + generateLocalId(parentId) + " not found for bindLocalObjectChildren");
                }

                let _children_updated = false;

                for (let childId of childIds) {
                    if (!object.children) {
                        object.children = [{ id: childId, bind: epoch }] as any;
                        _children_updated = true;
                    } else {
                        let index: any = (object as ObjectItem).children?.findIndex((child: any) => child.id === childId);
                        if (index === -1) {
                            (object as ObjectItem).children?.push({ id: childId, bind: epoch } as any);
                            _children_updated = true;
                        } else {
                            let _child: any = object.children[index];
                            if (!_child.bind || (_child.unbind && getEpochTime(_child.unbind) >= getEpochTime(_child.bind))) {
                                Object.assign(object.children[index], { bind: epoch });
                                _children_updated = true;
                            }
                        }
                    }
                }

                if (!_children_updated) {
                    return {object: object, isChildrenUpdated: false, events: events} as ResponseOLSType;
                }

                events.push(SubscriptionType.CHILDREN);

                Object.assign(object, { updated: epoch });

                let copyObject: ObjectItem = object && copy(object);
                objectCache[generateLocalId(copyObject.object_id)] = copyObject;

                postObjectToIndexedDB(object).then(() => {});

                return {object: object, events: events} as ResponseOLSType
            });
            resolve(response);
        } catch (e) {
            console.error(e);
            reject(e);
        }
    });
};

const postLocalObjectPermissions = (objectId: string, permissions: Permissions): Promise<ResponseOLSType> => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await lockObjectCache.acquire(objectId, async (): Promise<ResponseOLSType> => {
                let object: ObjectItem = await getObjectFromCacheOrIndexDB(objectId, { includeDeleted: false });
        //         let events: SubscriptionType[] = [];
        //         const newProperties = copy(properties);
        //         const resultProperties: PropertiesType = {};
        //         const expirationTime = getObjectExpirationTime(properties);

                if ( !object || object.deleted || (isObjectPublic(object.permissions) && object.owner_id !== getUserId()) ) {
                    return Promise.reject("Object " + generateLocalId(objectId) + " not found for postLocalObjectPermissions");
                }

                if (isAllowedObjectSharing(object)) {
                    const {
                        // updatedOriginal,
                        // updatedInput,
                        mergedPermissions
                    } = mergePermissions(object.permissions, permissions);

                    object.permissions = copy(mergedPermissions);

                    let copyObject: ObjectItem = object && copy(object);
                    objectCache[generateLocalId(copyObject.object_id)] = copyObject;

                    postObjectToIndexedDB(copy(object)).then(() => {});
                } else {
                    console.warn(`SKIPPED. This object (${objectId}) cannot be shared`);
                }

                return {
                    object: {
                        ...object
                    },
                    events: [],
                    encryption: {
                        encryptionKey: object?.encryption_key || null,
                        saveKeyRequired: true,
                        isPublic: false
                    }
                };
            });
            resolve(response);
        } catch (err) {
            console.log(err);
            reject(err);
        }
    });
};

const clearDetectionObjects = async (): Promise<ObjectItem[]> => {
    return new Promise(async (resolve, reject) => {
        try {
            await checkConnectToDB();
        } catch (e) {
            return reject(e);
        }

        // const request = db.transaction("objects", "readonly").objectStore("objects").index("observer_id").getAll();//[AM] the device may not have enough memory for this operation
        const request = getTransaction("objects", "readonly").objectStore("objects").index("observer_id").getAll();//[AM] the device may not have enough memory for this operation
        request.onsuccess = function () {
            const res = request.result.filter((item: any) => {
                return item.local_id.includes(getCurrentUserId()) && !item.deleted;
            });
            resolve(res);
        };

        request.onerror = function () {
            reject(request.error);
        };
    });
};

const getServiceLifeNotificationObjects = async (): Promise<ObjectItem[]> => {
    return new Promise(async (resolve, reject) => {
        try {
            const params: any = {
                skipChildrenTransform: true,
            };

            const allLocalObjects = (await getLocalObjects(params)) as ObjectItem[];

            const serviceLifeNotificationObjects = allLocalObjects.filter((localObject) => localObject.service_life_notifications);

            resolve(serviceLifeNotificationObjects);
        } catch (e) {
            return reject(e);
        }
    });
};

const clearLocalDB = () => {
    // db.transaction("objects", "readwrite").objectStore("objects").clear();
    getTransaction("objects", "readwrite").objectStore("objects").clear();
    clearLocalObjectCache();
};


const clearLocalObjectCache = () => {
    objectCache = {};
};

export {
    getLocalObjects,
    getLocalObjectById,
    postLocalObject,
    deleteLocalObject,
    updateLocalObjectProperties,
    unBindLocalObjectChildren,
    bindLocalObjectChildren,

    postLocalObjectPermissions,
    getServiceLifeNotificationObjects,

    getLocalObjectByNativeIdentificationDataAndObjectType,
    clearLocalDB,
    clearLocalObjectCache,
    clearDetectionObjects
};
