import { isEmail, wait } from "../Utils";
import {
    getDeviceId,
    getDeviceInfo,
    getUserCryptoInfo,
    getUserId,
    removeUserId,
    postUsers,
    setUserCryptoInfo
} from "../UserService";
import {
    generateUserCryptoKeys,
    unwrapUserSigningKeyPairAndSignMessage,
    unwrapUserEncryptionKeyPair,
    importAndWrapKeyPair,
} from "../CryptoService";
import type { ExtendedUserCrypto } from "../CryptoService";
import {
    getJWTToken,
    getJWTTokenTime,
    removeJWTToken,
    removeRememberMe,
    setJWTToken,
    setRememberMe
} from "./AuthStorageService";
import {
    getRemoteIoTLink,
    getRemoteLogin,
    getRemoteGuestLogin,
    postRemoteRefreshToken,
    postRemoteLogin,
    postRemoteVerifiedEmail,
    postRemoteRegister,
    postRemoteForgotPassword,
    postRemoteCreatePassword,
    postRemoteUpdatePassword,
} from "./AuthRemoteService";
// TODO [IM] combine into import { clearIdentificationCache, clearObjectCache } form "../ObjectsService"
import { clearIdentificationCache } from "../ObjectsService/ObjectsNativeService/ObjectsIdentificationService/ObjectsIdentificationService";
import { clearObjectCache } from "../ObjectsService";
import { isDevice } from "../DeviceService";

const responseWrapper = async (response: Promise<any>, prettyError?: string) => {
    try {
        return await response;
    } catch (e) {
        console.error(e);
        return Promise.reject(prettyError || e);
    }
};

const saveRememberMe = (rememberMe: boolean) => {
    setRememberMe(rememberMe);
};

const setToken = (token: string) => {
    setJWTToken(token);
};

const getToken = () => {
    return getJWTToken() as string;
};

const isSignedIn = (): boolean => {
    return !!getJWTToken();
};

const isTokenExpired = () => {
    const tokenTime = getJWTTokenTime();
    return Date.now() - parseInt(tokenTime || "0") > 1000 * 60 * 60 * 24;
};

const signOut = () => {
    removeJWTToken();
    removeRememberMe();
    removeUserId();
    clearIdentificationCache();
    clearObjectCache();
};

// This function need to check DeviceInfo existing,
const checkDeviceInfoExisting = async (iteration_count = 0): Promise<null> => {
    const deviceId = getDeviceId();
    const deviceInfo = getDeviceInfo();

    if (iteration_count === 30) {
        console.warn("Something went wrong to getting deviceInfo...");
        return null;
    }

    if (!isDevice() || deviceId === deviceInfo?.deviceId || Object.keys(deviceInfo).length) {
        return null;
    }

    await wait(250);

    return checkDeviceInfoExisting(iteration_count + 1)
};

const signIn = async (username: string, password: string) => {
    try {
        if (!isEmail(username)) {
            return Promise.reject({ message: "Email is invalid" });
        }

        const authData = await getRemoteLogin(username);

        const { signature, signing } = await unwrapUserSigningKeyPairAndSignMessage(authData.signing, password, authData.verification_code);

        const result = await checkAuth(await postRemoteLogin(username, authData.session_id, signature));

        const encryption = await unwrapUserEncryptionKeyPair(result.encryption, password);

        postUsers([{
            email: result.user.email,
            firstname: result.user.firstname,
            lastname: result.user.lastname,
            user_id: result.user.id || result.user.user_id,
            encryption: {
                public_key: result.encryption?.public_key || null,
                key_id: result.encryption?.key_id || ""
            }
        }]).then(() => {});

        setUserCryptoInfo(result.user.id, {
            encryption: {
                publicJWK: encryption.publicJWK,
                privateJWK: encryption.privateJWK
            },
            signing: {
                publicJWK: signing.publicJWK,
                privateJWK: signing.privateJWK
            }
        });

        return result.user;
    } catch (e) {
        console.error(e);
        return Promise.reject(e);
    }
};

const signInAsGuest = async () => {
    try {
        await checkDeviceInfoExisting();

        const response = await getRemoteGuestLogin();

        return await checkAuth(response);
    } catch (err) {
        console.error(err);
        return Promise.reject(err);
    }
};

const signUp = async (username: string, firstname: string, lastname: string, password: string) => {
    try {
        const { signing, encryption } = await generateUserCryptoKeys(password);

        if (!isEmail(username)) {
            return Promise.reject({ message: "Email is invalid" });
        }

        const result = await postRemoteRegister({
            username: username,
            firstname: firstname,
            lastname: lastname,
        }, {
            signing: signing,
            encryption: encryption
        });

        setUserCryptoInfo(result.user_id, {
            encryption: {
                publicJWK: encryption.publicJWK,
                privateJWK: encryption.privateJWK
            },
            signing: {
                publicJWK: signing.publicJWK,
                privateJWK: signing.privateJWK
            }
        });

        return result;
    } catch (err) {
        console.error(err);
        return Promise.reject(err);
    }
};

const verifiedEmail = async (token: string) => {
    return responseWrapper(postRemoteVerifiedEmail(token), "Verifying Email failed");
};

const createPassword = async (data: { token: string, password: string, sessionId: string, verificationCode: string, crypto: ExtendedUserCrypto }) => {
    try {
        const { token, password, sessionId, verificationCode, crypto } = data;
        const { signing, encryption } = crypto;

        const wrappedSigning = await importAndWrapKeyPair(password, signing, "RS256");
        const wrappedEncryption = await importAndWrapKeyPair(password, encryption, "RSA-OAEP");

        return await postRemoteCreatePassword(token, sessionId, verificationCode, {
            signing: wrappedSigning,
            encryption: wrappedEncryption
        });
    } catch (err) {
        console.error(err);
        return Promise.reject(err);
    }
};

const forgotPassword = async (email: string) => {
    try {
        if (!isEmail(email)) {
            return Promise.reject({ message: "Email is invalid" });
        }

        return await postRemoteForgotPassword(email);
    } catch (err) {
        console.error(err);
        return Promise.reject(err);
    }
};

const resetPassword = async (email: string, password: string, newPassword: string) => {
    try {
        if (!isEmail(email)) {
            return Promise.reject({ message: "Email is invalid" });
        }

        const userId = getUserId();
        const crypto = getUserCryptoInfo(userId);

        if (!crypto) {
            signOut();
            window.dispatchEvent(new Event("signOut"));
            return Promise.reject({ message: "Crypto Data is missing" });
        }

        const authData = await getRemoteLogin(email);

        const { signature } = await unwrapUserSigningKeyPairAndSignMessage(authData.signing, password, authData.verification_code);

        const { signing, encryption } = crypto;

        const wrappedSigning = await importAndWrapKeyPair(newPassword, signing, "RS256");
        const wrappedEncryption = await importAndWrapKeyPair(newPassword, encryption, "RSA-OAEP");

        return await postRemoteUpdatePassword(email, authData.session_id, signature, {
            encryption: wrappedEncryption,
            signing: wrappedSigning
        });
    } catch (e) {
        console.error(e);
        return Promise.reject(e);
    }
};

const checkAuth = async (result: any) => {
    if (result?.auth === false) {
        console.error("Auth false");
        signOut();
        window.dispatchEvent(new Event("signOut"));
        return Promise.reject({ ...result, message: result.message || 'Auth false' });
    }

    if (result?.token) {
        setToken(result.token);
    }

    return result;
};

const refreshToken = async () => {
    return responseWrapper(postRemoteRefreshToken(), "Creating Refresh Token failed");
};

const getIoTLink = async () => {
    return responseWrapper(getRemoteIoTLink(), "Get IoT Link failed");
};

export {
    getToken,
    saveRememberMe,
    isSignedIn,
    isTokenExpired,
    checkAuth,
    signOut,

    signIn,
    signInAsGuest,
    signUp,
    verifiedEmail,
    createPassword,
    forgotPassword,
    resetPassword,
    refreshToken,
    getIoTLink,
}
