import React, {useCallback, useEffect, useMemo, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {CardActions, Grid} from "@material-ui/core";
import {DeleteForever, Edit, Link as LinkIcon, LinkOff, Share, Star, StarBorder} from "@material-ui/icons";
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import AlertDialog from "../../Dialogs/AlertDialog/AlertDialog";
import "@fortawesome/fontawesome-free/js/all";
import {Link, useHistory, useLocation, useRouteMatch} from "react-router-dom";
import {
    EMPTY_OBJECT,
    isAllowedObjectSharing,
    ObjectItem,
    postObjectNotificationState
} from "../../../Services/ObjectsService";
import ShareDialog from "../../Dialogs/ShareDialog/ShareDialog";
import {useGlobalNotificationContext} from "../../Notification/NotificationProvider";
import type {SnackBarType} from "../../../Services/NotificationService";
import {UserInfoType} from "../../../Hooks/useUserProfile";
import {getClientId, getUserId, isGuest} from "../../../Services/UserService";
import {useSubObjectNotificationStatus} from "../../../Hooks/Subscriptions/useSubObjectNotificationStatus";
import {postObjectFavoriteState} from "../../../Services/ObjectsService/CRUD";
import {useSubObjectFavoriteStatus} from "../../../Hooks/Subscriptions/useSubObjectFavoriteStatus";
import {isObjectPublic, isUserHasAccess} from "../../../Services/Utils";
import {AccessLevel, OriginType} from "../../../Services/ObjectsService/Constants";
import {useGlobalLoaderActionsContext} from "../../../GlobalContext/GlobalContext";
import ActionButton from "./ActionButton/ActionButton";

const useStyles = makeStyles((theme) => ({
    actionBlock: {
        width: '100%',
        color: theme.palette.common.white,
        padding: theme.spacing(3),
        paddingTop: theme.spacing(0),
        [theme.breakpoints.up("md")]: {
            padding: theme.spacing(6),
            paddingTop: theme.spacing(0),
        },
    }
}));

type ObjectActionProps = {
    setObject?(object: ObjectItem): void;
    handleDeleteObject(id: string, action: string, cardView: boolean): void;
    displayModalBind?(state: boolean): void;
    object_item: ObjectItem;
    userInfo: UserInfoType;
    parentId?: string;
    viewInDrawer?: boolean;
    root?: boolean;
    cardView?: boolean;
};

export default function ObjectActions(props: ObjectActionProps) {
    const {
        setObject,
        handleDeleteObject,
        displayModalBind,
        object_item,
        userInfo,
        parentId,
        viewInDrawer,
        root,
        cardView
    } = props;

    const classes = useStyles();

    const location = useLocation();
    const history = useHistory()

    const setLoader = useGlobalLoaderActionsContext();
    const notify: SnackBarType = useGlobalNotificationContext();

    const [openAlertDialog, setOpenAlertDialog] = useState<string>('');
    const [openShareDialog, setOpenShareDialog] = useState<boolean>(false);
    const {showNotification, notificationValue, setNotificationValue} = useSubObjectNotificationStatus(object_item);
    const {isFavorite, setIsFavorite} = useSubObjectFavoriteStatus(object_item);

    let {url} = useRouteMatch();

    const userId = useMemo(() => {
        return userInfo?.user?.id || getUserId();
    }, [userInfo?.user?.id, getUserId()]);

    const userIsGuest = useMemo(() => {
        return isGuest();
    }, [isGuest()]);

    const deleteObjectButtonClicked = () => {
        if (!openAlertDialog) return;
        console.log("Delete Building Button clicked");
        handleAlertDialogClose();

        handleDeleteObject(object_item.object_id, openAlertDialog, !!cardView);
    };

    const handleAlertDialogOpen = useCallback((action: string) => {
        setOpenAlertDialog(action);
    },[setOpenAlertDialog]);

    const handleAlertDialogClose = useCallback(() => {
        setOpenAlertDialog("");
    },[setOpenAlertDialog]);

    const handleShareDialogOpen = useCallback(() => {
        if (userIsGuest) {
            notify.warningNotify("Sharing is not allowed for Guest user. Please sign-up");
            return;
        }

        setOpenShareDialog(true);
    }, [setOpenShareDialog, userIsGuest, notify]);

    const handleShareDialogClose = useCallback(() => {
        setOpenShareDialog(false);
    }, [setOpenShareDialog]);

    const handleNotificationClick = () => {
        postObjectNotificationState(getClientId(), object_item.object_id,{state: !notificationValue});
        setNotificationValue((prev) => !prev);
    };

    const handleFavoriteClick = async () => {
        try {
            setLoader(true);

            await postObjectFavoriteState(object_item.object_id, !isFavorite, userId, {origin: OriginType.USER});
            setIsFavorite((prev) => !prev);
        } catch (err) {
            console.error(err);
        } finally {
            setLoader(false);
        }
    };

    const handleOpenModalBind = useCallback(() => {
        if (displayModalBind) {
            displayModalBind(true);
        }
    }, [displayModalBind]);

    const handleEditObjectClick = useCallback(() => {
        setObject && setObject(EMPTY_OBJECT)
    },[setObject, EMPTY_OBJECT]);

    useEffect(() => {
        if (!location.pathname.includes("objects")) {
            return;
        }

        const query = new URLSearchParams(location.search);
        const shareDialog = Boolean(query.get("shareDialog"));

        if (shareDialog) {
            setOpenShareDialog(true);
            query.delete("shareDialog")
            history.replace({
                search: query.toString(),
            });
        }

    }, [location.pathname]);

    // elements

    const buttons = useMemo(() => {
        return [
            {
                key: `${object_item.object_id}_edit_object`,
                type: "edit",
                icon: <Edit/>,
                title: "Edit Object",
                onClick: handleEditObjectClick,
                component: Link,
                to: viewInDrawer ? `/objects/${object_item.object_id}/edit` : cardView ? `${url}/${object_item.object_id}/edit` : `${url}/edit`,
                // renderAllowed: isUserHasAccess(object_item, AccessLevel.WRITE)
            }, {
                key: `${object_item.object_id}_favorites`,
                type: "favorites",
                icon: isFavorite ? <Star/> : <StarBorder/>,
                title: "Favorites",
                onClick: handleFavoriteClick,
                // renderAllowed: !userInfo.isGuest
            }, {
                key: `${object_item.object_id}_notification`,
                type: "notification",
                icon: notificationValue ? <NotificationsIcon/> : <NotificationsOffIcon/>,
                title: "Notification",
                onClick: handleNotificationClick,
                // renderAllowed: showNotification
            }, {
                key: `${object_item.object_id}_share_object`,
                type: "share",
                icon: <Share/>,
                title: "Share Object",
                onClick: handleShareDialogOpen,
                // renderAllowed: !userInfo.isGuest
            }, {
                key: `${object_item.object_id}_bind_object`,
                type: "bind",
                icon: <LinkIcon/>,
                title: "Bind New Object",
                onClick: handleOpenModalBind,
                // renderAllowed: isUserHasAccess(object_item, AccessLevel.WRITE) && object_item?.object_type && !["Service", "IPCamera", "BackCamera", "FrontCamera"].includes(object_item.object_type)
            }, {
                key: `${object_item.object_id}_unlink_object`,
                type: "unlink",
                icon: <LinkOff/>,
                title: "Unlink Object",
                onClick: handleAlertDialogOpen.bind(null, "unlink"),
                // renderAllowed: !root && isUserHasAccess(object_item, AccessLevel.WRITE)
            }, {
                key: `${object_item.object_id}_delete_object`,
                type: "delete",
                icon: <DeleteForever/>,
                title: "Delete Object",
                onClick: handleAlertDialogOpen.bind(null, "delete"),
                // renderAllowed: object_item?.own
            }
        ]
    }, [
        handleEditObjectClick,
        viewInDrawer,
        object_item.object_id,
        cardView,
        url,
        isFavorite,
        // userInfo.isGuest,
        // root,
        // object_item?.own,
        // object_item?.object_type,
        notificationValue,
        // isUserHasAccess,
        handleFavoriteClick,
        handleShareDialogOpen,
        handleAlertDialogOpen,
        handleOpenModalBind,
        handleNotificationClick
    ]);

    const CardViewAction = () => {

        const renderAllowed:{[k: string]: boolean} = {
            edit: isUserHasAccess(object_item, AccessLevel.WRITE),
            favorites: true,
            share: isAllowedObjectSharing(object_item),
            unlink: !root && isUserHasAccess(object_item, AccessLevel.CONTRIBUTE),
            delete: !!root && object_item?.owner_id === getUserId(),
            // bind: isUserHasAccess(object_item, AccessLevel.CONTRIBUTE) && !!object_item?.object_type && !["Service", "IPCamera", "BackCamera", "FrontCamera"].includes(object_item.object_type),
            notification: showNotification,
        };

        return (
            <CardActions disableSpacing>
                {buttons.filter((button) => {
                    return renderAllowed[button.type];
                }).map((item) => {
                    const buttonProps = {
                        title: item.title,
                        onClick: item.onClick,
                        component: item.component,
                        to: item.to
                    };

                    return (
                        <ActionButton key={item.key} buttonProps={buttonProps} containerProps={{xs:true}} icon={item.icon}/>
                    )
                })}
            </CardActions>
        );
    }

    const HeaderViewAction = () => {
        const renderAllowed:{[k: string]: boolean} = {
            edit: isUserHasAccess(object_item, AccessLevel.WRITE),
            favorites: true,
            share: isAllowedObjectSharing(object_item),
            unlink: !!parentId && !viewInDrawer && !root && isUserHasAccess(object_item, AccessLevel.CONTRIBUTE),
            delete: object_item?.owner_id === getUserId(),
            bind: isUserHasAccess(object_item, AccessLevel.CONTRIBUTE) && !!object_item?.object_type && !["Service", "IPCamera", "BackCamera", "FrontCamera"].includes(object_item.object_type),
            notification: showNotification,
        };

        return (
            <Grid
                container
                justifyContent="flex-start"
                classes={{root: classes.actionBlock}}
            >
                {buttons.filter((button) => {
                    return renderAllowed[button.type];
                }).map((item) => {
                    const buttonProps = {
                        title: item.title,
                        onClick: item.onClick,
                        component: item.component,
                        to: item.to
                    };

                    return (
                        <ActionButton key={item.key} buttonProps={buttonProps} icon={item.icon}/>
                    )
                })}
            </Grid>
        )
    };

    const Actions = () => {
        if (props.object_item?.owner_id !== getUserId() && isObjectPublic(props.object_item?.permissions)) {
            return null;
        }

        if (props.cardView) {
            return (<CardViewAction/>);
        }

        return (<HeaderViewAction/>);
    };

    return (
        <>
            <Actions/>
            <AlertDialog
                open={!!openAlertDialog}
                dialogTitle={
                    "Are you sure you want to " +
                    openAlertDialog +
                    " this " +
                    props.object_item.object_type.toLocaleLowerCase() +
                    "?"
                }
                handleAgree={deleteObjectButtonClicked}
                handleDisagree={handleAlertDialogClose}
            />
            {isAllowedObjectSharing(object_item)? (
                <ShareDialog
                    object_item={object_item}
                    handleDisagree={handleShareDialogClose}
                    open={openShareDialog}
                />
            ) : null}
        </>
    );
}
