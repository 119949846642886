export const AUTH_HEADERS = {
    IAP_PRODUCT_ID: "Iap-Product-Id",
    IAP_END_DATE: "Iap-End-Date"
};

export const METHOD = {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    DELETE: "DELETE"
};

export enum REQUEST_TYPE {
    // OBJECTS
    DELETE_OBJECT_BY_ID,
    DELETE_OBJECT_CHILDREN,
    GET_LIST_OBJECT,
    GET_LIST_PUBLIC_OBJECT,
    GET_OBJECT_PERMISSIONS,
    GET_OBJECT_BY_ID,
    GET_PUBLIC_OBJECT_BY_ID,
    GET_OBJECT_HISTORY_BY_ID,
    GET_OBJECT_PROPERTIES_FROM_CACHE,
    GET_OBJECT_PROPERTIES_SIGNED_URL,
    GET_PROPERTY_HISTORY_BY_KEY,
    POST_OBJECT_PERMISSIONS,
    POST_OBJECT_PERMISSIONS_INVITE,
    POST_OBJECT_ACCESS_REQUEST,
    POST_OBJECT,
    POST_OBJECT_CHILDREN,
    POST_OBJECT_NOTIFICATION,
    POST_OBJECT_NOTIFICATION_STATE,
    POST_OBJECT_PROPERTIES,
    POST_OBJECT_PROPERTIES_TO_CACHE,
    POST_OBJECT_STATUS,
    POST_OBJECT_STATUS_POLL,

    // SUPPORT
    POST_REPORT_TO_SUPPORT,

    // AUTH
    GET_IOT_LINK,
    GET_LOGIN,
    POST_CREATE_PASSWORD,
    POST_FORGOT_PASSWORD,
    POST_UPDATE_PASSWORD,
    POST_LOGIN,
    POST_REFRESH_TOKEN,
    POST_GUEST_LOGIN,
    POST_REGISTER,
    POST_VERIFIED_EMAIL,

    // USER
    DELETE_USER_CLIENT,
    DELETE_USER_DEVICE_TOKEN,
    GET_LIST_USER,
    GET_USER_CLIENT_BY_ID,
    GET_USER_PROFILE,
    POST_USER_CLIENT,
    POST_USER_DEVICE_TOKEN,
    POST_USER_PROFILE,
    POST_USER_STATUS,

    //IAP
    POST_IAP_VERIFY_RECEIPT,

    //S3
    UPLOAD_TO_S3,
    DOWNLOAD_FROM_S3,
}

export const API_WITH_LIMIT = [
    REQUEST_TYPE.GET_OBJECT_BY_ID,
    REQUEST_TYPE.POST_OBJECT,
    REQUEST_TYPE.GET_LIST_OBJECT,
    REQUEST_TYPE.GET_OBJECT_PROPERTIES_FROM_CACHE,
    REQUEST_TYPE.POST_OBJECT_PROPERTIES_TO_CACHE,
    REQUEST_TYPE.POST_OBJECT_PROPERTIES,
    REQUEST_TYPE.POST_OBJECT_NOTIFICATION,
    REQUEST_TYPE.POST_OBJECT_CHILDREN,
    REQUEST_TYPE.GET_OBJECT_HISTORY_BY_ID,
    REQUEST_TYPE.GET_PROPERTY_HISTORY_BY_KEY,
];

export const ALLOWED_GUEST_API = [
    REQUEST_TYPE.GET_IOT_LINK,
    REQUEST_TYPE.GET_LOGIN,
    REQUEST_TYPE.POST_CREATE_PASSWORD,
    REQUEST_TYPE.POST_FORGOT_PASSWORD,
    REQUEST_TYPE.POST_UPDATE_PASSWORD,
    REQUEST_TYPE.POST_LOGIN,
    REQUEST_TYPE.POST_REFRESH_TOKEN,
    REQUEST_TYPE.POST_GUEST_LOGIN,
    REQUEST_TYPE.POST_REGISTER,
    REQUEST_TYPE.POST_VERIFIED_EMAIL,
    REQUEST_TYPE.POST_REPORT_TO_SUPPORT,
    REQUEST_TYPE.GET_IOT_LINK,
    REQUEST_TYPE.DELETE_USER_DEVICE_TOKEN,
    REQUEST_TYPE.GET_USER_CLIENT_BY_ID,
    REQUEST_TYPE.GET_USER_PROFILE,
    REQUEST_TYPE.POST_USER_CLIENT,
    REQUEST_TYPE.POST_USER_PROFILE,
    REQUEST_TYPE.POST_USER_STATUS,
];
