import React from "react";
import {Route, Switch} from "react-router-dom";
import Login from "./Login/Login";
import VerifiedEmail from "./VerifiedEmail/VerifiedEmail";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import CreatePassword from "./CreatePassword/CreatePassword";
import {UserInfoType} from "../../Hooks/useUserProfile";
import CheckAuthComponent from "./CheckAuthComponent/CheckAuthComponent";

type AuthorizationProps = {
    userInfo: UserInfoType;
    redirectPath: string;
};

const Authorization = (props: AuthorizationProps) => {

    const {userInfo, redirectPath} = props;

    return (
        <Switch>
            <Route key="login" path="/login">
                <CheckAuthComponent userInfo={userInfo} redirectTo={redirectPath} nonAuth>
                    <Login userInfo={userInfo}/>
                </CheckAuthComponent>
            </Route>
            <Route key="verified-email" path="/verified-email">
                <VerifiedEmail/>
            </Route>
            <Route key="forgot-password" path="/forgot-password">
                <CheckAuthComponent userInfo={userInfo} nonAuth>
                    <ForgotPassword/>
                </CheckAuthComponent>
            </Route>
            <Route key="create-password" path="/create-password">
                <CheckAuthComponent userInfo={userInfo} nonAuth>
                    <CreatePassword/>
                </CheckAuthComponent>
            </Route>
            <Route key="reset-password" path="/reset-password">
                <CheckAuthComponent userInfo={userInfo} nonAuth>
                    <CreatePassword isResetPassword/>
                </CheckAuthComponent>
            </Route>
        </Switch>
    );
};

export default Authorization;
