import React, { useState } from "react";
import { Avatar, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText } from "@material-ui/core";
import { ChevronRight as ChevronRightIcon, Person as PersonIcon } from "@material-ui/icons";

import OrganizationsDialog from "Components/Dialogs/OrganizationsDialog/OrganizationsDialog";

import { getOrganization, setOrganization } from "Helpers/OrganizationsHelper";
import { useSubOrganization } from "Hooks/SystemEvent/useContext";
import { EMPTY_OBJECT } from "Services/ObjectsService/Constants";
import type { ObjectItem } from "Services/ObjectsService/Types";
import { getTextAvatar } from "Services/Utils";

type OrgSelectorProps = {
    menuOpenToggled(): void;
    setObject(object: ObjectItem): void;
};

const OrgSelector = (props: OrgSelectorProps) => {
    const { setObject, menuOpenToggled } = props;

    const [orgOpen, setOrgOpen] = useState<boolean>(false);
    const [orgTitle, setOrgTitle] = useState<string>(getOrganization()?.object_name || "Personal Account");

    const onClickOrgMenu = () => {
        setOrgOpen(true);
        menuOpenToggled();
    };

    const handleClose = (org: ObjectItem | null) => {
        setOrgOpen(false);

        if (org?.object_id) {
            if (org.object_id === 'personal') {
                setOrganization(null);
            } else {
                setOrganization(org);
            }
        }
    };

    const handleOrganizationUpsert = () => {
        setObject(EMPTY_OBJECT);
        setOrgOpen(false);
    };

    useSubOrganization(() => {
        const orgName = getOrganization()?.object_name || "Personal Account";

        if (orgTitle !== orgName) {
            setOrgTitle(orgName);
        }
    });

    return (
        <>
            <Divider/>
            <List disablePadding>
                <ListItem button onClick={onClickOrgMenu}>
                    <ListItemAvatar>
                        <Avatar>{orgTitle === 'Personal Account' ? <PersonIcon/> : getTextAvatar(orgTitle)}</Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={orgTitle}/>
                    <IconButton size={'small'} edge={'end'}>
                        <ChevronRightIcon/>
                    </IconButton>
                </ListItem>
            </List>
            {orgOpen && (
                <OrganizationsDialog
                    open={orgOpen}
                    onClose={handleClose}
                    onOrganizationUpsert={handleOrganizationUpsert}
                />
            )}
        </>
    );
};

export default OrgSelector;
