import Paho from "paho-mqtt";
import { isSignedIn, getIoTLink } from "../AuthenticationService";
import { getClientId, getUserId } from "../UserService";
import { copy, wait } from "../Utils";
import { registerDevice } from "../DeviceService";
import type { TopicItem, TopicItemKeys, MQTTCallbackType } from "./Types";
import { TOPIC_TYPES, MQTT_TOPIC_MAIN } from "./Constants";
import { APP_ID } from "../Constants";

let client: Paho.Client | null = null;
let clientId: string = "";
let localClientId: string = "";
let timerId: null | NodeJS.Timeout = null;

const DEFAULT_SUBSCRIPTIONS = {
    [TOPIC_TYPES.OBJECTS]: [],
    [TOPIC_TYPES.CLIENTS]: [],
    [TOPIC_TYPES.IAP]: [],
    [TOPIC_TYPES.UNKNOWN]: [],
    [TOPIC_TYPES.PUBLIC_OBJECTS]: []
};

let subscriptions: { [key in TOPIC_TYPES]: TopicItem[] } = JSON.parse(JSON.stringify(DEFAULT_SUBSCRIPTIONS));

// ===== MQTT utils =====
const getIdFromTopic = (topic: string) => {
    return topic.replace(/io.localmachines.sandbox\/user\/(?<user_id>[\w-]+)\/(objects|clients)\/(?<id>[\w-]+)/, (...match) => {
        const groups = match.pop();
        return groups.id;
    });
};

const getTopicInfo = (messageType: string, topic: string) => {
    if (["status", "children", "deleted", "new_object", "properties", "permissions", "notification_state", "favorites"].includes(messageType)) {
        return {
            type: TOPIC_TYPES.OBJECTS,
            id: {
                object_id: getIdFromTopic(topic)
            }
        };
    }

    if (["new_public_object", "deleted_public_object", "status_public_object", "children_public_object", "properties_public_object"].includes(messageType)) {
        return {
            type: TOPIC_TYPES.PUBLIC_OBJECTS,
            id: {
                object_id: ""
            }
        };
    }

    if (["last_activity"].includes(messageType)) {
        return {
            type: TOPIC_TYPES.CLIENTS,
            id: {
                client_id: getIdFromTopic(topic)
            }
        };
    }

    if (["iap"].includes(messageType)) {
        return {type: TOPIC_TYPES.IAP, id: {}};
    }

    if (["bulk_status"].includes(messageType)) {
        return {type: TOPIC_TYPES.OBJECTS, id: {}};
    }

    return {type: TOPIC_TYPES.UNKNOWN, id: {}};
};

const randomString = (len: number) => {
    const gen = (min: number, max: number) => {
        return [...Array(max - min)]
            .map((s, i) => {
                return String.fromCharCode(min + i);
            })
            .join("");
    };

    const sets = {
        num: gen(48, 57),
        alphaLower: gen(97, 122),
        alphaUpper: gen(65, 90),
    };

    const characters = Object.values(sets).join("");
    const length = characters.length;

    return [...Array(len)]
        .map(() => {
            return characters.charAt(Math.floor(Math.random() * length));
        })
        .join("");
};

const generateTopicWithKeys = (topic: string, keys: TopicItemKeys = {}): string => {
    let generatedTopic = copy(topic);

    for (const key in keys) {
        if (!keys.hasOwnProperty(key)) {
            continue;
        }

        const value = keys[key as keyof TopicItemKeys];
        generatedTopic = generatedTopic.replace(new RegExp(`{${key}}`, "g"), value);
    }
    generatedTopic = generatedTopic.replace(new RegExp(`{user_id}`, "g"), getUserId());

    return generatedTopic;
};
// ===== MQTT utils =====

const socketConnect = async () => {
    console.log("Connecting...");

    if (!isSignedIn()) {
        console.log("Unauthorized");
        socketDisconnect();
        client = null;
        clientId = "";
        subscriptions = copy(DEFAULT_SUBSCRIPTIONS);
        // return;
    }

    const handleMQTTMessage = (topic: string, payload: string) => {
        const payloadData = JSON.parse(payload);

        if (payloadData.client_id && localClientId && payloadData.client_id === localClientId) {
            return;
        }

        delete payloadData.client_id;

        const topicInfo = getTopicInfo(payloadData.message_type, topic);

        if ((topicInfo.type === TOPIC_TYPES.OBJECTS || topicInfo.type === TOPIC_TYPES.PUBLIC_OBJECTS) &&
            Array.isArray(payloadData.allow_app) && !payloadData.allow_app.includes(APP_ID)) {

            console.log("Filter APP_ID ", APP_ID, payloadData.allow_app);//temp!
            return;
        }

        const matchedTopics = subscriptions[topicInfo.type];

        if (!Array.isArray(matchedTopics) || matchedTopics.length === 0) {
            return;
        }

        for (const topicItem of matchedTopics) {
            if (payloadData.message_type === 'bulk_status') {
                for (let obj of payloadData.objects) {
                    const _payload = {
                        message_type: "status",
                        last_active: obj.last_active,
                        reachable: payloadData.reachable,
                        primary_client: payloadData.primary_client
                    };

                    topicItem.callback(copy({ object_id: obj.object_id }), _payload);
                }
            } else {
                topicItem.callback(copy({...topicItem.keys, ...topicInfo.id}), payloadData);
            }
        }
    };

    const onSuccessHandler = () => {
        console.log("Connected");
        localClientId = getClientId();
        registerDevice("MQTT");
        if (client) {
            let topic = generateTopicWithKeys(MQTT_TOPIC_MAIN);
            client.subscribe(topic);

            if ((subscriptions?.[TOPIC_TYPES.PUBLIC_OBJECTS] || []).length) {
                for (const subscription of subscriptions?.[TOPIC_TYPES.PUBLIC_OBJECTS]) {
                    if ((subscription.keys?.topics || []).length) {
                        for (const topic of subscription?.keys?.topics || []) {
                            client.subscribe(topic);
                        }
                    }
                }
            }
        }

        console.log(subscriptions);
    };

    if (!clientId) {
        clientId = randomString(21);
    }

    if (!timerId) {
        timerId = setInterval(async () => {
            if (!isSignedIn()) {
                return;
            }

            await wait(10000);

            console.log("Checking MQTT Connection...");
            if (client?.isConnected()) {
                console.log("Client is connected");
                return;
            }

            console.log("The client is not connected. Trying to reconnect...");
            socketReconnect();
        }, 60000);
    }

    let data = {link: ""};

    try {
        data = await getIoTLink();
    } catch (e) {
        console.error("Error GET IOT link: ",JSON.stringify(e));
        return;
    }

    console.log("IOT DATA", data);

    client = new Paho.Client(data.link, clientId);
    client.onMessageArrived = (message: Paho.Message) => {
        const topic = message.destinationName;
        const payload = message.payloadString;

        handleMQTTMessage(topic, payload);
    };

    client.onConnectionLost = (responseObject: Paho.MQTTError) => {
        console.log("Lost connection");
        console.error(responseObject);
        if (!responseObject || responseObject.errorCode === 0) {
            console.log("Reconnect skipped after connection lost");
            return;
        }

        setTimeout(function () {
            socketReconnect();
        }, 5000); //to have time to remove the incorrect subscription
    };

    client.connect({
        onSuccess: onSuccessHandler,
        useSSL: true,
        keepAliveInterval: 30,
        mqttVersion: 4,
        onFailure: (e) => {
            console.log(e);
            socketReconnect();
        },
    });
};

const socketReconnect = async (skipDelay?: boolean) => {
    socketDisconnect();
    await setTimeout(function () {
        console.log("Reconnect");
        socketConnect();
    }, !!skipDelay ? 200 : 2000);
};

const socketDisconnect = () => {
    if (client) {
        console.log("Disconnected");
        try {
            client.disconnect();
        } catch (e) {
        }
    }
};

const subscribe = (type: TOPIC_TYPES, keys: TopicItemKeys, callback: MQTTCallbackType) => {
    if (!subscriptions?.[type]) {
        subscriptions[type] = [];
    }

    subscriptions[type].push({
        type: type,
        keys: keys,
        callback: callback
    });

    if (client?.isConnected() && type === TOPIC_TYPES.PUBLIC_OBJECTS && keys.topics) {
        for (const topic of keys.topics) {
            client.subscribe(topic);
        }
    }
};

const unsubscribe = (type: TOPIC_TYPES, keys: TopicItemKeys) => {
    const items = subscriptions[type] || [];

    subscriptions[type] = items.filter((item) => {
        return item.keys.subscription_id !== keys.subscription_id;
    });

    if (client?.isConnected() && type === TOPIC_TYPES.PUBLIC_OBJECTS && keys.topics) {
        for (const topic of keys.topics) {
            client.unsubscribe(topic);
        }
    }
};

export {
    socketReconnect,
    subscribe,
    unsubscribe
};
