import React from "react";
import {Button, Typography} from "@material-ui/core";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import useDisableUI from "../../../Hooks/SystemEvent/disableUI/useDisableUI";
import {pubSubmitEvent, pubDisableUIEvent} from "../../../Services/SystemEventsService";

const useStyles = makeStyles(() =>
    createStyles({
        title: {
            flexGrow: 1,
        }
    })
);

const UserProfileNavigationBar = () => {
    const classes = useStyles();

    const {disabled} = useDisableUI();

    const saveButtonClicked = () => {
        pubSubmitEvent();
        pubDisableUIEvent(true);
    };

    return (
        <React.Fragment>
            <Typography variant="h6" noWrap className={classes.title}>
                Personal info
            </Typography>
            <Button color="inherit" onClick={saveButtonClicked} disabled={disabled}>
                Save
            </Button>
        </React.Fragment>
    );
};

export default UserProfileNavigationBar;
