import {useEffect, useState, useRef} from "react";
import {v4} from "uuid";
import {FavoritesInfoType, ObjectItem} from "../../Services/ObjectsService/Types";
import {subObjectFavoriteState, unsubObjectFavoriteState} from "../../Services/ObjectsService/Subscription";
import {OriginType} from "../../Services/ObjectsService/Constants";
import {getUserId} from "../../Services/UserService";

export const useSubObjectFavoriteStatus = (object_item: ObjectItem) => {
    const userId = useRef<string>(getUserId());

    const state = !!(object_item?.favorites?.[userId.current]?.state);
    const [isFavorite, setIsFavorite] = useState<boolean>(state);

    useEffect(() => {
        if (!object_item) {
            return;
        }
        setIsFavorite(state)
    }, [state])

    useEffect(() => {
        if (!object_item?.object_id) {
            return;
        }
        let active = true;

        const subscriptionId = v4();

        subObjectFavoriteState(object_item.object_id, subscriptionId, OriginType.USER, (keys, data) => {
            if (keys.objectId !== object_item?.object_id || !active || !data) {
                return;
            }
            setIsFavorite(!!(data as FavoritesInfoType)?.[userId.current]?.state);
        })

        return () => {
            active = false;
            unsubObjectFavoriteState(object_item?.object_id, subscriptionId);
        }
    }, [object_item.object_id])

    return { isFavorite, setIsFavorite };
}
