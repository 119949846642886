import {createStyles, alpha, makeStyles, Theme} from "@material-ui/core/styles";

export const useListObjectsStyles = makeStyles((theme: Theme) =>
    createStyles({

        drawerHeader: {
            right: 0,
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        // old
        drawer: {
            width: 320,
            flexShrink: 0,
            overflow: "hidden",
        },
        drawerPaper: {
            width: 320,
        },
        searchBar: {
            display: "flex",
            position: "relative",
            borderRadius: theme.shape.borderRadius,
            backgroundColor: alpha(theme.palette.common.black, 0.15),
            "&:hover": {
                backgroundColor: alpha(theme.palette.common.black, 0.25),
            },
            marginLeft: 0,
            [theme.breakpoints.up("sm")]: {
                marginLeft: theme.spacing(1),
                width: "auto",
            },
        },
        searchBarFullWidth: {
            width: "100%"
        },
        searchBarActionButton: {
            marginLeft: "auto"
        },
        searchBarIcon: {
            padding: theme.spacing(0, 2),
            height: "100%",
            position: "absolute",
            pointerEvents: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        inputRoot: {
            color: "inherit",
            width: "100%"
        },
        inputInput: {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(1)}px)`,
            transition: theme.transitions.create("width"),
            width: "100%",
        },
        loader: {
            paddingTop: theme.spacing(2),
        },
        test: {
            overflow: "auto",
            minHeight: "250px",
        },
        treeRoot: {
            height: 216,
            flexGrow: 1,
        },
    })
);
