import React, { useState } from "react";

import "@fortawesome/fontawesome-free/js/all";
import Icon from "@material-ui/core/Icon";
import { BottomNavigation, BottomNavigationAction } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { matchPath, useHistory, useLocation } from "react-router-dom";
import { PLAN_RELATED_TYPES, OBJECT_TYPE } from "Services/ObjectsService/Constants";
import { NAVIGATION_VIEW_REGEXP } from "./constants";
import { ViewType } from "./types";
import { changeLocationByViewMode, getBottomNavigationItems, getDefaultViewByLocation } from "./service";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        bottomNavigationBar: {
            top: "auto",
            position: "fixed",
            bottom: 0,
            width: "100%",
            height: 76,
            zIndex: theme.zIndex.drawer - 1,
        },
    })
);

const BottomNavigationBar = ({ type }: { type: string }) => {
    const classes = useStyles();

    const history = useHistory();
    const location = useLocation();

    let { params }: any = matchPath(`/${location.pathname?.replace(NAVIGATION_VIEW_REGEXP, "")}`, [
        "(.*)/:id",
    ]) || { params: {} };

    const bottomNavigationItems = React.useMemo(() => {
        return getBottomNavigationItems(type);
    }, [type]);

    const [value, setValue] = useState<ViewType>(getDefaultViewByLocation(location.pathname));

    const onChangeHandler = async (event: React.ChangeEvent<{}>, newValue: ViewType): Promise<void> => {
        setValue(newValue);
        const newPath = changeLocationByViewMode(newValue, params.id, location.pathname);
        if (newPath) {
            history.replace(newPath);
        }
    };

    return (
        <BottomNavigation
            value={value}
            showLabels
            onChange={onChangeHandler}
            className={classes.bottomNavigationBar}
        >
            { bottomNavigationItems.map((item) => (
                <BottomNavigationAction
                    key={item.value}
                    label={item.label}
                    icon={<Icon className={item.icon} fontSize="small"/>}
                    value={item.value}
                />
            )) }
        </BottomNavigation>
    );
};

const ShowBottomNavigation = ({objectType}: { objectType: string; }) => {
    const location = useLocation();
    const availableObjectTypes = [...PLAN_RELATED_TYPES, OBJECT_TYPE.DOCUMENT, OBJECT_TYPE.SITE, OBJECT_TYPE.CONVERSATION];

    if (
        availableObjectTypes.includes(objectType) &&
        !location.pathname.includes("/edit") &&
        !location.pathname.includes("/create-object")
    ) {
        return (<BottomNavigationBar type={objectType} />);
    }

    return null;
}

export { ShowBottomNavigation };
