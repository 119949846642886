import React, {useEffect, useState} from "react";

import {LexicalComposer} from "@lexical/react/LexicalComposer";
import {ContentEditable} from "@lexical/react/LexicalContentEditable";
import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";

import {RichTextPlugin} from "@lexical/react/LexicalRichTextPlugin";
import {HistoryPlugin} from "@lexical/react/LexicalHistoryPlugin";
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import {AutoFocusPlugin} from "@lexical/react/LexicalAutoFocusPlugin";
import {ListPlugin} from "@lexical/react/LexicalListPlugin";
import {LinkPlugin} from "@lexical/react/LexicalLinkPlugin";
import {MarkdownShortcutPlugin} from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { TRANSFORMERS } from "@lexical/markdown";

import {CheckListPlugin} from "@lexical/react/LexicalCheckListPlugin";
import {TablePlugin} from "@lexical/react/LexicalTablePlugin";

import AutoLinkPlugin from "./plugins/AutoLinkPlugin";
import ClickableLinkPlugin from './plugins/ClickableLinkPlugin';
import CodeActionMenuPlugin from './plugins/CodeActionMenuPlugin';
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin";
import CollapsiblePlugin from './plugins/CollapsiblePlugin';

// import DraggableBlockPlugin from './plugins/DraggableBlockPlugin';
import EmojiPickerPlugin from './plugins/EmojiPickerPlugin';
import EmojisPlugin from './plugins/EmojisPlugin';
import EquationsPlugin from './plugins/EquationsPlugin';
import ExcalidrawPlugin from './plugins/ExcalidrawPlugin';
import FigmaPlugin from './plugins/FigmaPlugin';
import FloatingLinkEditorPlugin from './plugins/FloatingLinkEditorPlugin';
import HorizontalRulePlugin from './plugins/HorizontalRulePlugin';
import ImagesPlugin from './plugins/ImagesPlugin';
import KeywordsPlugin from './plugins/KeywordsPlugin';
import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndentLevelPlugin";
import MentionsPlugin from './plugins/MentionsPlugin';
import PollPlugin from './plugins/PollPlugin';
import TableCellActionMenuPlugin from './plugins/TableActionMenuPlugin';
import {TableContext, TablePlugin as NewTablePlugin} from './plugins/TablePlugin';
import TwitterPlugin from './plugins/TwitterPlugin';
import YouTubePlugin from './plugins/YouTubePlugin';


import TableCellNodes from './nodes/TableCellNodes';
import PlaygroundNodes from "./nodes/PlaygroundNodes";
import PlaygroundEditorTheme from "./themes/PlaygroundEditorTheme";


const onError = (error: any) => {
    console.error("Error ", error);
    throw error;
};

type PageEditorReadModeProps = {
    editorState: string | null;
};


export const PageEditorReadMode = (props: PageEditorReadModeProps) => {

    const { editorState } = props;

    const emptyEditorState = `{"root":{"children":[{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}`;

    const CustomSetEditorStatePlugin = () => {
        const [editor] = useLexicalComposerContext();

        useEffect(() => {
            if (editorState) {
                editor.setEditorState(editor.parseEditorState(editorState));
            } else {
                editor.setEditorState(editor.parseEditorState(emptyEditorState)); //[AM] is there another way?
            }
        }, [editorState]);

        return null;
    };

    const [floatingAnchorElem, setFloatingAnchorElem] =
        useState<HTMLDivElement | null>(null);

    const onRef = (_floatingAnchorElem: HTMLDivElement) => {
        if (_floatingAnchorElem !== null) {
            setFloatingAnchorElem(_floatingAnchorElem);
        }
    };

    const editorConfig = {
        namespace: "LM",
        theme: PlaygroundEditorTheme,
        onError,
        nodes: [...PlaygroundNodes],
        editorState: editorState,
        editable: false
    };

    const cellEditorConfig = {
        namespace: "LM",
        nodes: [...TableCellNodes],
        onError: (error: Error) => {
            throw error;
        },
        theme: PlaygroundEditorTheme,
    };

    return (
        <LexicalComposer initialConfig={editorConfig}>
            <TableContext>
                <div className="editor-shell">
                    <div className="editor-container">
                        <AutoFocusPlugin />
                        <AutoLinkPlugin />
                        <CheckListPlugin />
                        <ClickableLinkPlugin />
                        <CodeHighlightPlugin />
                        <CollapsiblePlugin />

                        <CustomSetEditorStatePlugin />

                        <EmojiPickerPlugin />
                        <EmojisPlugin />
                        <EquationsPlugin />
                        <ExcalidrawPlugin />
                        <FigmaPlugin />
                        <HistoryPlugin />
                        <HorizontalRulePlugin />
                        <ImagesPlugin />
                        <KeywordsPlugin />
                        <LinkPlugin />
                        <ListMaxIndentLevelPlugin maxDepth={7} />
                        <ListPlugin />
                        <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
                        <MentionsPlugin />
                        <PollPlugin />
                        <RichTextPlugin
                            contentEditable={
                                <div ref={onRef} style={{ height: '100%' }}>
                                    <ContentEditable className="editor-input" />
                                </div>
                            }
                            placeholder={<div className="editor-placeholder">Enter the page text...</div>}
                            ErrorBoundary={LexicalErrorBoundary}
                        />
                        <NewTablePlugin cellEditorConfig={cellEditorConfig}>
                            <AutoFocusPlugin />
                            <RichTextPlugin
                                contentEditable={
                                    <ContentEditable className="TableNode__contentEditable" />
                                }
                                placeholder={''}
                                ErrorBoundary={LexicalErrorBoundary}
                            />
                            <MentionsPlugin />
                            <HistoryPlugin />
                            <ImagesPlugin captionsEnabled={false} />
                            <LinkPlugin />
                            <ClickableLinkPlugin />
                            {/*<FloatingTextFormatToolbarPlugin />*/}
                        </NewTablePlugin>
                        <TablePlugin />
                        <TwitterPlugin />
                        <YouTubePlugin />
                        {floatingAnchorElem && (
                            <>
                                {/*<DraggableBlockPlugin anchorElem={floatingAnchorElem} />*/}
                                <CodeActionMenuPlugin anchorElem={floatingAnchorElem} />
                                <FloatingLinkEditorPlugin anchorElem={floatingAnchorElem} />
                                <TableCellActionMenuPlugin anchorElem={floatingAnchorElem} />
                                {/*<FloatingTextFormatToolbarPlugin*/}
                                {/*    anchorElem={floatingAnchorElem}*/}
                                {/*/>*/}
                            </>
                        )}
                    </div>
                </div>
            </TableContext>
        </LexicalComposer>
    );
};
