import React, {useRef} from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import {forgotPassword} from "../../../Services/AuthenticationService";
import {useHistory} from "react-router-dom";
import {useGlobalNotificationContext} from "../../Notification/NotificationProvider";
import type {SnackBarType} from "../../../Services/NotificationService";
import useAuthorizationStyles from "../AuthorizationStyles";
import Copyright from "../Copyright/Copyright";
import {useGlobalLoaderActionsContext, useGlobalLoaderContext} from "../../../GlobalContext/GlobalContext";
import {isEmail} from "../../../Services/Utils";

const ForgotPassword = () => {
    const classes = useAuthorizationStyles();
    const history = useHistory();
    const notify: SnackBarType = useGlobalNotificationContext();

    const loader = useGlobalLoaderContext();
    const setLoader = useGlobalLoaderActionsContext();

    const emailRef = useRef<HTMLInputElement>(null);

    const forgotPasswordButtonClicked = async () => {
        if (!emailRef.current?.value) {
            return notify.errorNotify("Email Required");
        }

        if (!isEmail(emailRef.current.value)) {
            return notify.errorNotify("Email is invalid");
        }

        setLoader(true);
        try {
            await forgotPassword(emailRef.current?.value);
            setLoader(false);
            notify.successNotify("Please check your email");
            history.replace('/');
        } catch(err: any) {
            setLoader(false);
            notify.errorNotify(err?.message || JSON.stringify(err));
        } finally {
            setLoader(false);
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Typography component="h1" variant="h5">
                    Forgot Password
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            disabled={loader}
                            inputRef={emailRef}
                        />
                    </Grid>
                </Grid>
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={loader}
                    onClick={forgotPasswordButtonClicked}
                >
                    Reset Password
                </Button>
                <Grid container justifyContent="flex-end">
                    <Grid item>
                        <Link onClick={()=> {
                            if (loader) return;
                            history.push("/login");
                        }} variant="body2">
                            Remember password? Sign in
                        </Link>
                    </Grid>
                </Grid>
            </div>
            <Box mt={5}>
                <Copyright />
            </Box>
        </Container>
    );
};

export default ForgotPassword;
