export const VIEW_TYPES = {
    VIEW_LIST: "view_list",
    VIEW_PAGE: "view_page",
    VIEW_PLAN: "view_plan",
    VIEW_MAP: "view_map",
    VIEW_CHAT: "view_chat",
    VIEW_HISTORY: "view_history",
} as const;

export const BOTTOM_NAVIGATION_ITEMS = {
    LIST: {
        label: "List",
        icon: "fa fa-table",
        value: VIEW_TYPES.VIEW_LIST,
    },
    DOCUMENT: {
        label: "Document",
        icon: "fas fa-file-alt",
        value: VIEW_TYPES.VIEW_PAGE,
    },
    PLAN: {
        label: "Plan",
        icon: "fas fa-ruler-combined rule",
        value: VIEW_TYPES.VIEW_PLAN,
    },
    MAP: {
        label: "Map",
        icon: "fa fa-map",
        value: VIEW_TYPES.VIEW_MAP,
    },
    CHAT: {
        label: "Chat",
        icon: "fas fa-comments",
        value: VIEW_TYPES.VIEW_CHAT,
    },
    HISTORY: {
        label: "History",
        icon: "fas fa-chart-line",
        value: VIEW_TYPES.VIEW_HISTORY,
    },
    CHILDREN: {
        label: "Children",
        icon: "fa fa-table",
        value: VIEW_TYPES.VIEW_LIST,
    },
} as const

export const NAVIGATION_VIEW_MODE = "view_mode";

export const NAVIGATION_VIEW_REGEXP = /(\/(plan\/edit|map\/edit|edit|page|plan|map|chat|history)$)/;
