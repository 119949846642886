import React, {Fragment, useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";

import {ObjectItem} from "../../Services/ObjectsService/Types";
import TreeViewWithSearch from "../ListObjects/TreeViewWithSearch/TreeViewWithSearch";
import {UserInfoType} from "../../Hooks/useUserProfile";
import {OBJECT_TYPE} from "../../Services/ObjectsService/Constants";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {useHistory} from "react-router-dom";
import ModalBindWithTree from "./ObjectDetails/ModalBindWithTree";
import {useGlobalNotificationContext} from "../Notification/NotificationProvider";
import type {SnackBarType} from "../../Services/NotificationService";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import IconButton from '@material-ui/core/IconButton';
import {Divider, useMediaQuery} from "@material-ui/core";
import {useSubShowDocumentStructure} from "../../Hooks/SystemEvent/Toolbar/useToolbar";
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import {PageEditorReadMode} from "../PageEditor/PageEditorReadMode";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: 'calc(100% - 124px)',       //124 = 48 + 76
        "@media (min-width: 600px)": {
            height: 'calc(100% - 140px)',   //140 = 64 + 76
        },
    },
    grid: {
        height: '100%',
    },
    container: {
        height: 'inherit',
        paddingLeft: '44px',
        paddingRight: '44px',
    },
    drawer: {
        // background: '#E8E8E8',
        height: '100%',
        borderRight: 'solid 3px gray',
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        // ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    viewHTML: {
        height: '100%',
        textAlign: 'left',
    },
}));

type ObjectDocumentProps = {
    object: ObjectItem;
    userInfo: UserInfoType;
    displayModalBind(state: boolean): void;
    openModalBind: boolean;
};

export const ObjectDocument = (props: ObjectDocumentProps) => {
    const classes = useStyles();
    const theme = useTheme();

    const history = useHistory();
    const notify: SnackBarType = useGlobalNotificationContext();

    const slimScreen = useMediaQuery(theme.breakpoints.down(800));

    const { object, userInfo, displayModalBind, openModalBind } = props;
    const [viewObject, setViewObject] = useState<ObjectItem | null>(null);
    const [open, setOpen] = React.useState(!slimScreen);

    useEffect(() => {
        if (object) {
            setViewObject(object);
        }
    }, [object?.properties?.page?.value])

    useEffect(() => {
        setOpen(!slimScreen);
    }, [slimScreen])

    const handleSelectObject = (object: ObjectItem | null) => {
        if (object) {
            setViewObject(object);
            history.replace(`/documents/${object.object_id}/page`);

            if (slimScreen) {
                handleDrawerClose();
            }
        }
    }

    // const handleDrawerOpen = () => {
    //     setOpen(true);
    // };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    useSubShowDocumentStructure(() => {
        setOpen(!open);
    });

    return (
        <Fragment>
            <div className={classes.root}>
                <Grid
                    container
                    className={classes.grid}
                >
                    {open && <Grid item xs={slimScreen ? 12 : 3} className={classes.drawer}>
                        <div className={classes.drawerHeader}>
                            <IconButton onClick={handleDrawerClose}>
                                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                            </IconButton>
                        </div>
                        <Divider />
                        <SimpleBar style={{ height: 'calc(100% - 49px)' }}>
                            <TreeViewWithSearch
                                filterObjectsIds={[]}
                                handleSelectObject={handleSelectObject}
                                filterType={[OBJECT_TYPE.DOCUMENT]}
                                userInfo={userInfo}
                            />
                        </SimpleBar>
                    </Grid>}
                    {(!slimScreen || !open) && <Grid item xs={open ? 9 : 12} className={classes.viewHTML}>
                        <SimpleBar style={{ height: '100%' }}>
                            <PageEditorReadMode
                                editorState={viewObject?.properties?.page?.value || null}
                            />
                        </SimpleBar>
                        <ModalBindWithTree
                            show={openModalBind}
                            handleShowDialog={displayModalBind}
                            parentObject={object}
                            userInfo={userInfo}
                            notify={notify}
                        />
                    </Grid>}
                </Grid>
            </div>
        </Fragment>
    );
};
