import React from "react";
import {Button, Icon, IconButton, Typography} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {pubUndoEvent, pubRedoEvent, pubZoomInEvent, pubZoomOutEvent, pubRemoveEvent, pubSubmitEvent} from "../../../Services/SystemEventsService";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            flexGrow: 1,
        },
        iconButton: {
            flexDirection: "column",
            padding: theme.spacing(0.5),
        },
    })
);

type PlanEditorNavbarToolsProps = {
    objectType: string;
};

export default function PlanEditorNavbarTools(props: PlanEditorNavbarToolsProps) {

    const {
        objectType
    } = props;

    const classes = useStyles();

    return (
        <React.Fragment>
            <IconButton color="inherit" onClick={pubUndoEvent} className={classes.iconButton}>
                <Icon className="fa fa-undo" fontSize="small"/>
            </IconButton>
            <IconButton color="inherit" onClick={pubRedoEvent} className={classes.iconButton}>
                <Icon className="fa fa-redo" fontSize="small"/>
            </IconButton>
            <IconButton color="inherit" onClick={pubZoomInEvent} className={classes.iconButton}>
                <Icon className="fa fa-search-plus" fontSize="small"/>
            </IconButton>
            <IconButton color="inherit" onClick={pubZoomOutEvent} className={classes.iconButton}>
                <Icon className="fa fa-search-minus" fontSize="small"/>
            </IconButton>
            <IconButton color="inherit" onClick={pubRemoveEvent} className={classes.iconButton}>
                <Icon className="fa fa-trash-alt" fontSize="small"/>
            </IconButton>

            <Typography variant="h6" noWrap className={classes.title}>
                Edit {objectType} Plan
            </Typography>
            <Button color="inherit" onClick={pubSubmitEvent}>
                Save
            </Button>
        </React.Fragment>
    );
}
