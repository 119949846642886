import React, {useCallback, useEffect, useState} from "react";
import {createStyles, makeStyles, Theme, useTheme} from "@material-ui/core/styles";

import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";

import {PlanEditController} from "../FloorPlanEditor/PlanEditController";
import Property from "../Objects/Property/Property";
import Grid from "@material-ui/core/Grid";
import {getObjectById, ObjectItem, PropertyItem, PropertyItemValue} from "../../Services/ObjectsService";
import {OBJECT_TYPE} from "../../Services/ObjectsService/Constants";
import {AbstractElement, ElementType} from "../FloorPlanEditor/tools/Elements/AbstractElement";

import {TextControl} from "../Controls/TextControl";
import {PlanViewController} from "../FloorPlanEditor/PlanViewController";
import ModalAddProperty from "../Objects/ObjectDetails/ModalAddProperty";
import {Settings as SettingsIcon} from "@material-ui/icons";
import {UserInfoType} from "../../Hooks/useUserProfile";
import ObjectDetails from "../Objects/ObjectDetails/ObjectDetails";
import useForceUpdate from "../../Hooks/ForceUpdate/useForseUpdate";
import md5 from "md5";
import {useGlobalLoaderActionsContext} from "../../GlobalContext/GlobalContext";
import ObjectConversation from "../Objects/Conversation/ObjectConversation";

const drawerWidth = 320;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
        },
        title: {
            flexGrow: 1,
        },
        hide: {
            display: "none",
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
        },
        drawerHeader: {
            display: "flex",
            alignItems: "center",
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
            justifyContent: "flex-start",
        },
        mediaIcon: {
            paddingTop: theme.spacing(2),
        },
        imgPreview: {
            width: "100%",
            height: "auto",
            padding: theme.spacing(2),
        },
    })
);

type PlanElementPropertiesProps = {
    open: boolean;
    planController: PlanEditController | PlanViewController | null;
    elementPropertiesClosed(): void;
    setObject(object: ObjectItem): void;
    displayModalBind(state: boolean): void;
    openModalBind: boolean;
    isDisabled?: boolean;
    userInfo: UserInfoType;
    parentId?: string;
    selectedElementId: string | null;
};


export default function PlanElementProperties(props: PlanElementPropertiesProps) {
    const classes = useStyles();
    const theme = useTheme();
    const forceUpdate = useForceUpdate();

    const setLoader = useGlobalLoaderActionsContext();

    const [openPropertyDialog, setOpenPropertyDialog] = useState<boolean>(false);
    const [editProperty, setEditProperty] = useState<PropertyItem | undefined>();
    const [object, setObject] = useState<ObjectItem | null>(null);
    const mounted = React.useRef(false);

    useEffect(() => {
        mounted.current = true; // Will set it to true on mount ...
        return () => { mounted.current = false; }; // ... and to false on unmount
    }, []);

    const handleEditPropertyDialogOpen = (property: PropertyItem) => {
        setEditProperty(property);
        setOpenPropertyDialog(true);
    };

    const handleDialogClose = useCallback(() => {
        setEditProperty(undefined);
        setOpenPropertyDialog(false);
    }, [setEditProperty, setOpenPropertyDialog]);


    const saveObjectByKey = (key: string, value: any, element: any) => {
        if (key !== "properties") return;
        element.setProperties(value);
    };

    const handleDrawerClose = () => {
        props.elementPropertiesClosed();
    };

    props.planController &&
    props.planController.setPlanDidUpdate(() => {
        if (!mounted.current) return;

        forceUpdate();
    });

    const handlePropertyValueChange = (element: any, property_key: any, value: PropertyItemValue) => {
        console.log("Updating property value change,", element, property_key, value);

        const props = element.getElementData().properties;

        if (
            props &&
            props.hasOwnProperty(property_key) &&
            JSON.stringify(props[property_key].value) === JSON.stringify(value)
        ) {
            // TODO If value does not change we should skip property update to exclude re-render the Plan [AS]
            return;
        }

        element.setProperty(property_key, value);
        element.updateGeometry();
        props.planController && props.planController.updatePlanViewAndRecordState();
    };

    useEffect(() => {
        if (!props.selectedElementId) return () => setObject(null)

        if (!props.planController && props.isDisabled) {
            return () => setObject(null)
        }
        (async () => {
            try {
                let selectObject = props.planController?.getSelectedElements()[0];
                if (selectObject) {
                    setLoader(true);
                    let result = await getObjectById(selectObject.getId());
                    setObject(result);
                    setLoader(false);
                }
            } catch (err) {
                setLoader(false);
            }

            return () => setObject(null);
        })();
    }, [props.selectedElementId]);

    const showZoneElementType = (element: AbstractElement) => {
        const elementData = element.getElementData();

        if (elementData.type === ElementType.ZONE_ELEMENT) {
            return elementData.object_type;
        }

        return "";
    };

    const callbackDeleteObject = () => {
        props.planController?.removeSelectedElements();
        setLoader(false);
        handleDrawerClose();
    }

    const displayEditControls = () => (
        props.planController &&
        props.planController.getSelectedElements().map((element, index) => (
            <React.Fragment key={`element_${index}`}>
                <Typography gutterBottom variant="h5" component="h2">
                    {showZoneElementType(element)}
                </Typography>
                <List>
                    <ListItem>
                        <Grid item xs={12}>
                            <TextControl
                                propertyKey={`object_name_${element.getId()}`}
                                label="Name"
                                value={element.getElementData().info.object_name}
                                saveValue={(key, value) => {
                                    element.setObjectName(value as string);
                                }}
                                controlInputProps={{
                                    fullWidth: true,
                                    variant: "outlined",
                                    disabled: props.isDisabled,
                                }}
                            />
                        </Grid>
                    </ListItem>
                    <ListItem>
                        <Grid item xs={12}>
                            <TextControl
                                propertyKey={`description_${element.getId()}`}
                                label="Description"
                                value={element.getElementData().info.description}
                                saveValue={(key, value) => {
                                    element.setDescription(value as string);
                                }}
                                controlInputProps={{
                                    fullWidth: true,
                                    variant: "outlined",
                                    disabled: props.isDisabled,
                                }}
                            />
                        </Grid>
                    </ListItem>
                    {Object.values(element.getElementData().properties).map((property, index) => (
                        <ListItem key={md5(JSON.stringify(property))}>
                            <Grid item xs={12}>
                                <Property
                                    key={element.getElementData().id}
                                    property={property}
                                    object={object || undefined}
                                    onChange={(key: string, value: PropertyItemValue) =>
                                        handlePropertyValueChange(element, property.key, value)
                                    }
                                    variant={"drawer"}
                                    isDisabled={props.isDisabled}
                                    parentId={props.parentId}
                                    settings={
                                        !props.isDisabled ? (
                                            <IconButton
                                                color="primary"
                                                children={<SettingsIcon/>}
                                                onClick={handleEditPropertyDialogOpen.bind(null, property)}
                                            />
                                        ) : undefined
                                    }
                                    userInfo={props.userInfo}
                                />
                            </Grid>
                        </ListItem>
                    ))}
                </List>
                <Divider/>
            </React.Fragment>
        ))
    )

    const displayObjectDetails = () => (
        props.open &&
        props.selectedElementId &&
        object &&
        ( object.object_type === OBJECT_TYPE.CONVERSATION ? (
            <ObjectConversation object={object} />
        ) : (
            <ObjectDetails
                displayModalBind={props.displayModalBind}
                openModalBind={props.openModalBind}
                object={object}
                userInfo={props.userInfo}
                parentId={props.parentId}
                handleBackButton={() => {
                }}
                setObject={props.setObject}
                viewInDrawer
                callbackDeleteObject={callbackDeleteObject}
            />
        ))
    )


    return (
        <React.Fragment>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="right"
                open={props.open}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === "rtl" ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
                    </IconButton>
                </div>
                <Divider/>
                {props.isDisabled ? displayObjectDetails() : displayEditControls()}
            </Drawer>
            {!props.isDisabled &&
            props.planController &&
            props.planController
                .getSelectedElements()
                .map((element, index) =>
                    element.getElementData() ? (
                        <ModalAddProperty
                            key={`modal_element_${index}`}
                            open={openPropertyDialog}
                            onClose={handleDialogClose}
                            object={element.getElementData()}
                            saveObjectByKey={(key, value) => saveObjectByKey(key, value, element)}
                            propertyItem={editProperty}
                            userInfo={props.userInfo}
                        />
                    ) : null
                )}
        </React.Fragment>
    );
}
