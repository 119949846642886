import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Box, Divider, List, ListItem, ListItemAvatar, ListItemText } from "@material-ui/core";
import { ArrowUpward as ArrowUpwardIcon } from '@material-ui/icons';

type PurchasesStatusProps = {
    menuOpenToggled(): void;
};

const useStyles = makeStyles((theme) => ({
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3)
    },
    background: {
        backgroundColor: theme.palette.type === "light" ? theme.palette.grey[200] : theme.palette.background.default
    }
}));

const PurchasesStatus = (props: PurchasesStatusProps) => {
    const { menuOpenToggled } = props;

    const classes = useStyles();

    return (
        <Box className={classes.background}>
            <Divider/>
            <List disablePadding>
                <ListItem key="Purchases" component={Link} to="/purchases" button onClick={menuOpenToggled}>
                    <ListItemAvatar>
                        <Avatar className={classes.small}>
                            <ArrowUpwardIcon fontSize="small"/>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Upgrade to Pro" primaryTypographyProps={{ variant: "caption" }}/>
                </ListItem>
            </List>
        </Box>
    )
};

export default PurchasesStatus;
