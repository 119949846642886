import React, {Fragment, useEffect, useState} from "react";
import {Grid, TextField} from "@material-ui/core";
import {convertImperialSizeValueToMetric, convertMetricSizeToImperialValue} from "../../Helpers/SystemOfMeasures";

type ImperialMeasurementsControlsProps = {
    name: string;
    value: number;
    onChange(value: number): void;
    disabled: boolean;
}

const ImperialMeasurementsControls = (props: ImperialMeasurementsControlsProps) => {
    const {
        name,
        value,
        onChange,
        disabled
    } = props;

    const [valueInFt, setValueInFt] = useState<number>(0)
    const [valueInInch, setValueInInch] = useState<number>(0)

    useEffect(() => {

        let [ft, inch] = convertMetricSizeToImperialValue(value)
        setValueInFt(ft)
        setValueInInch(inch)

    }, [value])

    const handleOnChange = (type: "ft" | "inch", new_value: number) => {
        if (new_value < 0) return
        let metric_value = 0

        if (type === "ft") {
            metric_value = convertImperialSizeValueToMetric(new_value, valueInInch)
        } else {
            if (new_value > 11) return
            metric_value = convertImperialSizeValueToMetric(valueInFt, new_value)
        }
        onChange(metric_value)
    }

    return (
        <Fragment>
            <Grid item xs={12}>
                <TextField
                    variant="outlined"
                    value={valueInFt}
                    name={`${name} (Ft)`}
                    type="number"
                    label={`${name} (Ft)`}
                    fullWidth={true}
                    onChange={(e: any) => handleOnChange("ft", e.target.value)}
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    variant="outlined"
                    value={valueInInch}
                    name={`${name} (In)`}
                    type="number"
                    label={`${name} (In)`}
                    fullWidth={true}
                    onChange={(e: any) => handleOnChange("inch", e.target.value)}
                    disabled={disabled}
                />
            </Grid>
        </Fragment>
    )
}

export default ImperialMeasurementsControls