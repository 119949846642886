import React from "react";
import { NavigationBar } from "./NavigationBar/NavigationBar";
import { Route, Switch } from "react-router-dom";
import ObjectsGridNavbarTools from "./NavigationBar/ObjectsGridNavbarTools";
import PlanEditorNavbarTools from "./NavigationBar/PlanEditorNavbarTools";
import MapEditorNavbarTools from "./NavigationBar/MapEditorNavbarTools";
import UserProfileNavigationBar from "./NavigationBar/UserProfileNavigationBar";
import { ObjectItem, rootControllers } from "../../Services/ObjectsService";
import SideMenu from "./SideMenu";
import { UserInfoType } from "../../Hooks/useUserProfile";
import IAPNavigationBar from "./NavigationBar/IAPNavigationBar";
import PublicNavigationBar from "./NavigationBar/PublicNavigationBar";
import {useGlobalLoaderContext} from "../../GlobalContext/GlobalContext";
import {DocumentEditorNavbarTools} from "./NavigationBar/DocumentEditorNavbarTools";

type MenuComponentProps = {
    object: ObjectItem;
    userInfo: UserInfoType;
    setObject(object: ObjectItem): void;
    setOpenModalBind(state: boolean): void;
    setOpenModalDirect(state: boolean): void;
    setOpenModalShare(state: boolean): void;
    handleBackButton(): void;
};

const MenuComponent = (props: MenuComponentProps) => {
    const {
        object,
        userInfo,
        setObject,
        setOpenModalBind,
        setOpenModalDirect,
        setOpenModalShare,
        handleBackButton,
    } = props;

    const loader = useGlobalLoaderContext();

    const [mobileOpen, setMobileOpen] = React.useState<boolean>(false);

    const handleDrawerToggle = React.useCallback(() => {
        setMobileOpen((prev) => !prev);
    }, []);

    return (
        <>
            <NavigationBar menuOpenToggled={handleDrawerToggle} handleBackButton={handleBackButton}>
                {!loader && <Switch>
                    <Route key="public" path="/map" exact={true}>
                        <PublicNavigationBar/>
                    </Route>
                    <Route key="documentEditorNavbarTools" path="(.*)/page/edit">
                        <DocumentEditorNavbarTools
                            pageName={object?.object_name || ""}
                        />
                    </Route>
                    <Route key="planEditNavbarTools" path="(.*)/plan/edit">
                        <PlanEditorNavbarTools
                            objectType={object.object_type}
                        />
                    </Route>
                    <Route key="mapEditNavbarTools" path="(.*)/map/edit">
                        <MapEditorNavbarTools/>
                    </Route>
                    <Route key="objectGrid" path={`/(org/[0-9a-z-]+/)?(${rootControllers.join("|")})`}>
                        <ObjectsGridNavbarTools
                            displayModalBind={setOpenModalBind}
                            displayModalDirect={setOpenModalDirect}
                            displayModalShare={setOpenModalShare}
                            object={object}
                        />
                    </Route>
                    <Route key="profile" path="/profile">
                        <UserProfileNavigationBar/>
                    </Route>
                    <Route key="iap" path="/purchases">
                        <IAPNavigationBar/>
                    </Route>
                    <Route key="login" path="/login" />
                </Switch>}
            </NavigationBar>
            <SideMenu
                open={mobileOpen}
                displayModalDirect={setOpenModalDirect}
                menuOpenToggled={handleDrawerToggle}
                userInfo={userInfo}
                setObject={setObject}
            />
        </>
    );
};

export default MenuComponent;
