import React, {useCallback, useEffect, useState, useRef} from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import Grid, {GridSize} from "@material-ui/core/Grid";
import ObjectCard from "./Card/ObjectCard/ObjectCard";
import Container from "@material-ui/core/Container";
import {
    deleteObject,
    EMPTY_OBJECT,
    getObjects,
    isObjectAvailableToDisplay,
    ObjectItem,
    Params
} from "../../Services/ObjectsService";
import { useLocation } from "react-router-dom";
import PullToRefresh from 'react-simple-pull-to-refresh';
import CircularProgress from "@material-ui/core/CircularProgress";
import type {SnackBarType} from "../../Services/NotificationService";
import {UserInfoType} from "../../Hooks/useUserProfile";
import {OriginType} from "../../Services/ObjectsService/Constants";
import {useSubObjectsType} from "../../Hooks/Subscriptions/useSubObjectsType";
import AddPeopleDialog from "../Dialogs/AddPeopleDialog/AddPeopleDialog";
import { wait } from '../../Services/Utils';
import { getOrganization, getOrgHierarchy } from "Helpers/OrganizationsHelper";
import { useSubOrganization } from "../../Hooks/SystemEvent/useContext";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        paper: {
            height: 140,
            width: 100,
        },
        control: {
            padding: theme.spacing(2),
        },
        cardGrid: {
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(10),
            overflowX: 'hidden',
        },
        cardGridContainer: {
            margin: "0 auto",
        },
        loader: {
            paddingTop: theme.spacing(2),
        },
        overflowHidden: {
            height: 'calc(100% + 40px)!important',
            '& .ptr__children': {
                height: 'calc(100% + 40px)!important',
            },
        },
    })
);

const gridLayout = {
    xs: 12 as GridSize,
    sm: 6 as GridSize,
    md: 4 as GridSize,
    lg: 3 as GridSize,
    xl: 2 as GridSize
};

const GRID_LIMIT: number = 9;

type ObjectsGridProps = {
    displayLoader(value: boolean): void;
    setObject(object: ObjectItem): void;
    displayModalDirect(state: boolean): void;
    openModalDirect: boolean;
    requested_type: string;
    notify: SnackBarType;
    userInfo: UserInfoType;
};

export default function ObjectsGrid(props: ObjectsGridProps) {

    const {
        displayLoader,
        setObject,
        displayModalDirect,
        openModalDirect,
        requested_type,
        notify,
        userInfo
    } = props;

    const classes = useStyles();

    const location = useLocation();

    const [lastId, setLastId] = useState<string | undefined | null>(null);
    const [listObjectId, setListObjectId] = useState<string[]>([]);

    const loadMoreFlag = useRef<boolean>(false);
    const orgObjects = useRef<string[]>([]);

    const handleAddPeopleDialogClose = useCallback(() => {
        displayModalDirect(false);
    }, [displayModalDirect]);

    const loadMoreObjects = async () => {
        loadMoreFlag.current = true;

        await wait(500);

        return getObjectsList(false);
    };

    const handleRefresh = async () => {
        loadMoreFlag.current = true;

        await wait(500);

        return getObjectsList(true);
    }

    const getObjectsList = async (init: boolean) => {
        if (!loadMoreFlag.current) {
            displayLoader(true);
        }

        const params: Params = {
            by_page: true,
            limit: GRID_LIMIT
        };

        switch (requested_type) {
            case "Camera":
                params.object_type = ["IPCamera", "BackCamera", "FrontCamera"];
                break;
            case "Favorites":
                params.favorites = true;
                break;
            case "SharedWithMe":
                params.shared_as_root = true;
                break;
            case "Document":
                params.includeOnlyRootObjects = true;
                params.object_type = [requested_type];
                break;
            default:
                params.object_type = [requested_type];
                break;
        }

        params.insideHierarchy = orgObjects.current;

        if (!init && lastId) {
            params.last_id = lastId;
        }

        try {
            const res = await getObjects(params);

            if (Array.isArray(params.object_type) && params.object_type[0] !== requested_type && requested_type !== "Camera") {
                displayLoader(false);
                return;
            }
            if (res) {
                res.objects = Array.isArray(res.objects) ? res.objects : [];
                const listIds = res.objects.filter((item: ObjectItem) => {
                    return isObjectAvailableToDisplay(item);
                }).map((item: ObjectItem) => {
                    return item.object_id;
                });
                setListObjectId((prev) => {
                    const onlyNew = listIds.filter((item: string) => {
                        return !prev.includes(item);
                    });

                    return !init ? prev.concat(onlyNew) : listIds;
                });

                setLastId(res.last_id);
            }
            loadMoreFlag.current = false;
        } catch (err: any) {
            notify.errorNotify(err?.message || JSON.stringify(err));
        }
        displayLoader(false);
    };

    const handleDeleteObject = useCallback(async (id: string) => {
        try {
            const res = await deleteObject(id, {origin: OriginType.USER});
            if (res) {
                setListObjectId((prev) => {
                    return prev.filter((object_id) => object_id !== id);
                });
            }
            console.log("Object deleted successfully ", id);
        } catch (err: any) {
            notify.errorNotify(err?.message || JSON.stringify(err));
        }
        displayLoader(false);
    }, [displayLoader, notify]);

    const initGrid = async () => {
        setObject(EMPTY_OBJECT);
        orgObjects.current = await getOrgHierarchy();
        getObjectsList(true).then(() => {});
    };

    useSubOrganization(() => {
        const selectedOrg = getOrganization();

        if (selectedOrg?.object_id && location.pathname.startsWith("/org/" + selectedOrg.object_id)) {
            return;
        }

        initGrid().then(() => {});
    });

    useEffect(() => {
        initGrid().then(() => {});

        return () => {
            setLastId(null);
            setListObjectId([]);
        };
    }, [requested_type]);

    useSubObjectsType(requested_type, async (data) => {
        await wait(500);
        orgObjects.current = await getOrgHierarchy();

        setListObjectId((prev) => {
            if (data.deleted) {
                return prev.filter((object_id) => object_id !== data.object_id);
            }

            if (orgObjects.current.includes(data.object_id)) {
                return [data.object_id, ...prev];
            } else {
                return prev;
            }
        });
    });

    return (
        <>
            <Container className={classes.cardGrid} maxWidth="xl">
                <Grid container spacing={4}>
                    <Grid item xs={12} className={classes.cardGridContainer}>
                        <PullToRefresh
                            className={classes.overflowHidden}
                            canFetchMore={!!lastId && listObjectId.length >= GRID_LIMIT} // [IM] Block load more in case displayed < 9
                            onRefresh={handleRefresh}
                            onFetchMore={loadMoreObjects}
                            refreshingContent={
                                <div className={classes.loader} key={0}>
                                    <CircularProgress/>
                                </div>
                            }
                            pullingContent={
                                <div className={classes.loader} key={0}>
                                    <CircularProgress/>
                                </div>
                            }
                        >
                            <Grid container item spacing={5}>
                                {listObjectId.map((object_id: string) => (
                                    <Grid item key={object_id} {...gridLayout}>
                                        <ObjectCard
                                            object_id={object_id}
                                            handleDeleteObject={handleDeleteObject}
                                            root={true}
                                            userInfo={userInfo}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </PullToRefresh>
                    </Grid>
                </Grid>
            </Container>
            {openModalDirect && <AddPeopleDialog
                open={openModalDirect}
                userInfo={userInfo}
                handleCloseDialog={handleAddPeopleDialogClose}
            />}
        </>
    );
}
