import { MQTT_TOPIC_CORE } from "../Constants";

export enum TOPIC_TYPES {
    OBJECTS,
    CLIENTS,
    IAP,
    UNKNOWN,
    PUBLIC_OBJECTS
}

// OBJECTS
export const MQTT_TOPIC_PUBLIC_OBJECT = `${MQTT_TOPIC_CORE}/public/{tile}`
export const MQTT_TOPIC_MAIN = `${MQTT_TOPIC_CORE}/user/{user_id}/#`;
// export const MQTT_TOPIC_OBJECT_DATA = `${MQTT_TOPIC_CORE}/user/{user_id}/objects/{object_id}`;
// export const MQTT_TOPIC_ALL_OBJECT_DATA = `${MQTT_TOPIC_CORE}/user/{user_id}/objects/{object_id}/#`;
// export const MQTT_TOPIC_DELETED_OBJECT = `${MQTT_TOPIC_CORE}/user/{user_id}/objects/{object_id}`;
// export const MQTT_TOPIC_PROPERTIES = `${MQTT_TOPIC_CORE}/objects/{object_id}/properties`;
// export const MQTT_TOPIC_OBJECT_ACTIVITY_STATUS = `${MQTT_TOPIC_CORE}/user/{user_id}/objects/{object_id}`;
// export const MQTT_TOPIC_LAST_ACTIVE_TIME_OF_OBJECT = `${MQTT_TOPIC_CORE}/objects/{object_id}/lastActive`;
// export const MQTT_TOPIC_OBJECT_IS_REACHABLE = `${MQTT_TOPIC_CORE}/objects/{object_id}/reachable`;
// export const MQTT_TOPIC_PRIMARY_CLIENT_OF_OBJECT = `${MQTT_TOPIC_CORE}/objects/{object_id}/primaryClient`;
// export const MQTT_TOPIC_STATE = `${MQTT_TOPIC_CORE}/user/{user_id}/objects/{object_id}/state`;
// export const MQTT_TOPIC_PROPERTIES_VALUE_ENCRYPTED = `${MQTT_TOPIC_CORE}/user/{user_id}/objects/{object_id}`;
// export const MQTT_TOPIC_PROPERTIES_VALUE = `${MQTT_TOPIC_CORE}/user/{user_id}/objects/{object_id}`;
// export const MQTT_TOPIC_CHILDREN = `${MQTT_TOPIC_CORE}/user/{user_id}/objects/{object_id}`;
// export const MQTT_TOPIC_NEW_OBJECT = `${MQTT_TOPIC_CORE}/user/{user_id}/objects/new_object`;

// CLIENTS
// export const MQTT_TOPIC_CLIENT_LAST_ACTIVE = `${MQTT_TOPIC_CORE}/user/{user_id}/clients/{client_id}`;

// IAP
// export const MQTT_TOPIC_USER_IAP_STATUS = `${MQTT_TOPIC_CORE}/user/{user_id}/iap`;
