import {colors, alpha} from "@material-ui/core";

declare module "@material-ui/core/styles/createBreakpoints" {
    interface BreakpointOverrides {
        xs: true;
        xsm: true,
        sm: true;
        md: true;
        lg: true;
        xl: true;
    }
}

const coreThemeObj = {
    palette: {
        primary: colors.indigo,
        secondary: colors.pink
    },
    breakpoints: {
        values: {
            xs: 0,
            xsm: 350,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
};

export const darkTheme = {
    ...coreThemeObj,
    palette: {
        primary: colors.blue,
        type: 'dark'
    },
    overrides: {
        MuiBottomNavigationAction: {
            root: {
                color: "white",
                "&$selected": {
                    backgroundColor: "white",
                    color: "#333",
                },
                '&$selected&:hover': {
                    backgroundColor: alpha("#fff", .8),
                    "@media (hover: none)": {
                        backgroundColor: alpha("#fff", .8),
                    }
                },
                '&:hover': {
                    backgroundColor: alpha("#333", .8),
                    "@media (hover: none)": {
                        backgroundColor: alpha("#333", .8),
                    }
                },
            },
        },
        MuiBottomNavigation: {
            root: {
                color: "#333"
            }
        },
        MuiAppBar: {
            colorPrimary: {
                color: "white",
                backgroundColor: "#333"
            }
        },
        MuiOutlinedInput: {
            input: {
                '&:-webkit-autofill': {
                    WebkitBoxShadow: '0 0 0 100px #333 inset',
                    WebkitTextFillColor: '#fff',
                }
            }
        },
        MuiFab: {
            primary: {
                backgroundColor: "#333",
                '&:hover': {
                    backgroundColor: alpha("#333", .8),
                    "@media (hover: none)": {
                        backgroundColor: alpha("#333", .8),
                    }
                },
            }
        },
        MuiTreeItem: {
            root: {
                '&$selected > $content $label:hover, &$selected:focus > $content $label': {
                    '@media (hover: none)': {
                        backgroundColor: alpha(colors.blue["500"], .24)
                    }
                }
            }
        }
    }
};

export const lightTheme = {
    ...coreThemeObj,
    palette: {
        ...coreThemeObj.palette,
        type: 'light'
    },
    overrides: {
        MuiBottomNavigation: {
            root: {
                backgroundColor: coreThemeObj.palette.primary["500"]
            }
        },
        MuiBottomNavigationAction: {
            root: {
                color: "white",
                "&$selected": {
                    backgroundColor: "white",
                    color: coreThemeObj.palette.primary["500"]
                },
                '&$selected&:hover': {
                    backgroundColor: alpha("#fff", .8),
                    "@media (hover: none)": {
                        backgroundColor: alpha("#fff", .8),
                    }
                },
                '&:hover': {
                    backgroundColor: coreThemeObj.palette.primary["600"],
                    "@media (hover: none)": {
                        backgroundColor: coreThemeObj.palette.primary["600"],
                    }
                },
            },
        },
        MuiOutlinedInput: {
            input: {
                '&:-webkit-autofill': {
                    WebkitBoxShadow: '0 0 0 100px white inset',
                    WebkitTextFillColor: "inherit",
                }
            }
        },
        MuiTreeItem: {
            root: {
                '&$selected > $content $label:hover, &$selected:focus > $content $label': {
                    '@media (hover: none)': {
                        backgroundColor: alpha(colors.indigo["500"], .12)
                    }
                }
            }
        }
    }
};
