import React, {useEffect, useState} from "react";
import GroupContainer from "../GroupContainer";
import {
    Avatar,
    Button,
    Grid,
    Icon,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    useMediaQuery,
    useTheme
} from "@material-ui/core";
import GradeIcon from '@material-ui/icons/Grade';
import SettingsIcon from '@material-ui/icons/Settings';
import {useUserProfileStyles} from "../UserProfileStyles";
import {Link} from "react-router-dom";
import {getPlanInfo, API_LIMIT} from "../../../../Services/PurchasesService";
import FormattedActivityTime from "../../../Common/FormattedActivityTime/FormattedActivityTime";
import {UserPurchasesProps} from "../UserProfileTypes";
import {Skeleton} from "@material-ui/lab";
import ProgressComponent from "../../../Common/ProgressComponent/ProgressComponent";
import {APP_ID} from "../../../../Services/Constants";
import {APPLICATION_ID} from "../../../../Constants";

const UserPurchases = (props: UserPurchasesProps) => {
    const classes = useUserProfileStyles();
    const theme = useTheme();
    const downXs = useMediaQuery(theme.breakpoints.down("xs"));

    const {loader,userInfo} = props;

    const [plan, setPlan] = useState<any>({});
    const [apiCalls, setApiCalls] = useState<number>(0);
    const [apiCallsLimit, setApiCallsLimit] = useState<number>(API_LIMIT.free);

    useEffect(() => {
        if (!userInfo.user) {
            return;
        }
        const plan = getPlanInfo();
        setApiCallsLimit(plan.product_id === "free" ? API_LIMIT.free : API_LIMIT.pro);
        setPlan(plan);
        setApiCalls(userInfo?.user?.api_calls || 0);
    }, [userInfo.user]);

    return (
        <GroupContainer title="Purchases" collapse>
            <List>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar>
                            <GradeIcon/>
                        </Avatar>
                    </ListItemAvatar>
                    {loader ?
                        <Skeleton animation="wave" height={30} width={150}/>
                        :
                        <ListItemText
                            primary={plan.name}
                            primaryTypographyProps={{variant: downXs ? "body2" : "body1"}}
                            secondary={
                                <FormattedActivityTime
                                    propsTypography={
                                        {
                                            component: "span",
                                            variant: downXs ? "caption" : "body2"
                                        }
                                    }

                                    activityEpoch={plan.end_date}
                                    options={{
                                        label: "Expire Date:",
                                        disableAutoUpdating: true,
                                        disableFormatDistance: true
                                    }}
                                />
                            }
                        />
                    }
                </ListItem>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar>
                            <Icon className="fas fa-server"/>
                        </Avatar>
                    </ListItemAvatar>
                    {loader ?
                        <Skeleton animation="wave" height={30} width={150}/> :
                        <ListItemText>
                            <ProgressComponent
                                variant="determinate"
                                value={apiCalls}
                                max={apiCallsLimit}
                                label={`API calls (${apiCalls}/${apiCallsLimit})`}
                                progressVariant="linear"
                                size={125}
                                thickness={6}
                            />
                        </ListItemText>
                    }
                </ListItem>
            </List>

            <Grid container justifyContent="center">
                <Grid container item sm={2} xs={10}>
                    {APP_ID === APPLICATION_ID.LOCAL_MACHINES && <Button
                        className={classes.actionButton}
                        variant="outlined"
                        color={"inherit"}
                        size="large"
                        startIcon={<SettingsIcon/>}
                        component={Link}
                        to="/purchases"
                        onClick={() => {
                        }}
                        fullWidth
                        disabled={loader}
                    >
                        Manage Plan
                    </Button>}
                </Grid>
            </Grid>


        </GroupContainer>
    );
};

export default UserPurchases;
