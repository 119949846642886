import React, {useCallback, useEffect, useRef, useState} from "react";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {v4} from "uuid";
import {format} from "date-fns";
import {Container, FormControl, Grid, InputLabel, Select} from "@material-ui/core";
import {
    BodyObjectItem,
    EMPTY_OBJECT,
    getDefaultChildType,
    getObjectById,
    ObjectItem,
    Permissions,
    postObject,
    postObjectChildren,
    PropertiesType,
    PropertyItem,
    PropertyItemValue,
} from "../../../Services/ObjectsService";
import {TextControl} from "../../Controls/TextControl";
import {useGlobalNotificationContext} from "../../Notification/NotificationProvider";
import type {SnackBarType} from "../../../Services/NotificationService";
import {UserInfoType} from "../../../Hooks/useUserProfile";
import {
    OBJECT_TYPES_ALLOWED_FOR_DETECTION,
    OriginType,
    VISIBILITY_OF_DETECTED_OBJECT,
    OBJECT_TYPE,
    ROOT_TYPES,
    CREATION_TYPES,
    pagesWithSharing,
    AccessLevel
} from "../../../Services/ObjectsService/Constants";
import {useGlobalLoaderActionsContext} from "../../../GlobalContext/GlobalContext";
import {getClientId, getUsers, getUserId} from "../../../Services/UserService";
import {RESOLUTIONS} from "../../../Services/Constants";
import {copy, isAllowedOperation, isEmail, wait} from "../../../Services/Utils";
import ObjectFormProperties from "./ObjectFormProperties/ObjectFormProperties";
import ObjectFormActions from "./ObjectFormActions/ObjectFormActions";
import {useObjectFormStyles} from "./ObjectFormStyle";
import {postObjectPermissionsInvite, saveObjectEncryptKeyForExistingUser} from "../../../Services/ObjectsService/CRUD";
import AddPeopleDropdown from "../../Common/AddPeopleDropdown/AddPeopleDropdown";
import {useSubSubmit} from "../../../Hooks/SystemEvent/Toolbar/useToolbar";
import {DEFAULT_PROPERTIES_BY_MACHINES} from "../../../Constants";

type ObjectFormProps = {
    setObject(object: ObjectItem): void;
    userInfo: UserInfoType;
    parentId?: string;
    creating?: boolean;
    handleBackButton(): void;
};

const ObjectForm = (props: ObjectFormProps) => {
    const classes = useObjectFormStyles();
    const location = useLocation();
    const history = useHistory();

    const notify: SnackBarType = useGlobalNotificationContext();
    const setLoader = useGlobalLoaderActionsContext();

    const {
        // setObject, TODO [IM] used as props.setObject, should be removed while refactoring
        userInfo,
        parentId,
        creating,
        handleBackButton,
    } = props;

    const [addedEmails, setAddedEmails] = useState<string[]>([]);
    const [emailsInput, setEmailsInput] = useState<string>("");
    const [object, setObject] = useState<ObjectItem>(EMPTY_OBJECT);

    const objectParent = useRef<ObjectItem | null>(null);

    const writableTypeProperty = ["LiveStreaming", "ObjectDetection", "Url", "enum", "MultipleSelect"];

    const AVAILABLE_PROPERTIES: { [key: string]: { [key: string]: PropertyItemValue }} = {
        address: {type: "Address", key: "address"},
        chat_message: {type: "ChatMessage", name: "Chat Message", key: "chat_message", value: {text: "", user_id: getUserId(), data: {}, timestamp: Date.now()}, icon: "fas fa-comment", protection: true},
        elevation: {type: "Elevation", key: "elevation"},
        file: {type: "File", key: "file", icon: "fas fa-file", value: {name: "", type: "", content: ""}, protection: true},
        image: {type: "Image", key: "image", icon: "fas fa-image", protection: true},
        image_polygon: {type: "ImagePolygon", name: "ImageBounds", key: "image_polygon"},
        live_streaming: {type: "LiveStreaming", name: "Live Streaming", key: "live_streaming", icon: "fas fa-video"},
        map_point: {type: "MapPoint", name: "Location", key: "map_point", icon: "fas fa-map-marker-alt"},
        map_polygon: {type: "MapPolygon", name: "MapBounds", key: "map_polygon"},
        object_detection: {type: "ObjectDetection", name: "Object Detection", key: "object_detection", icon: "fas fa-running"},
        object_types_allowed_for_detection: {type: "MultipleSelect", name: "Object Types Allowed For Detection", key: "object_types_allowed_for_detection", icon: "fas fa-list", value: OBJECT_TYPES_ALLOWED_FOR_DETECTION, value_options_list: OBJECT_TYPES_ALLOWED_FOR_DETECTION},
        page: {type: "Page", name: "Page", key: "page", icon: "fas fa-file-alt", value: ""},
        preferred_resolution: {type: "enum", name: "Preferred Resolution", key: "preferred_resolution", icon: "fas fa-photo-video", value: RESOLUTIONS.SD, value_options_list: Object.values(RESOLUTIONS)},
        service_life: {type: "ServiceLife", name: "Service Life", key: v4(), value: {start_time: format(new Date(), "MM/dd/yyyy"), life_time: 365, notification_time: 14, show_percentage: true}},
        visibility_detected_object: {type: "enum", name: "Visibility Of Detected Object", key: "visibility_detected_object", icon: "fas fa-universal-access", value: "As a parent", value_options_list: VISIBILITY_OF_DETECTED_OBJECT},
        url: {type: "Url", name: "Url", key: "url", icon: "fas fa-link", protection: true},
    };

    let params: { id: any } = useParams();

    const addDefaultPropByType = (availablePropKeys: string[]) => {

        function generateKey(word: string): string {
            return word
                .replace(/\w([A-Z])/g, function (m) {
                    return m[0] + "_" + m[1];
                })
                .toLowerCase();
        }

        let properties: { [string: string]: PropertyItem } = {};

        availablePropKeys.forEach((propKey) => {
            const prop = AVAILABLE_PROPERTIES[propKey];
            let keyProp = (prop.key || generateKey(prop.type as string)) as string;
            properties[keyProp] = {
                property_id: prop.type !== "ServiceLife" ? v4() : keyProp,
                key: keyProp,
                name: (prop.name || prop.type) as string,
                readable: true,
                type: prop.type as string,
                value: prop.value as PropertyItemValue || "",
                writable: writableTypeProperty.includes(prop.type as string),
                visibility: ["card", "details", "parent", "title"],
                protection: !!prop.protection,
            };
            if (prop.value_options_list) {
                properties[keyProp]["value_options_list"] = prop.value_options_list as string[];
            }
            if (prop.icon) {
                properties[keyProp]["icon"] = prop.icon as string;
            }
        });

        updateObjectByKey("properties", properties);
    };

    const handleObjectType = (type: string) => {
        setObject({...EMPTY_OBJECT});
        updateObjectByKey("object_type", type);
    };

    const getDefaultTypeByParent = (type: string) => {
        const default_type = getDefaultChildType(type);

        if (type) {
            handleObjectType(default_type);
        } else if (location.pathname.includes("/buildings")) {
            handleObjectType("Building");
        } else if (location.pathname.includes("/organizations")) {
            handleObjectType("Organization");
        } else if (location.pathname.includes("/sites")) {
            handleObjectType("Site");
        } else if (location.pathname.includes("/documents")) {
            handleObjectType("Document");
        } else if (location.pathname.includes("/files")) {
            handleObjectType("File");
        } else if (location.pathname.includes("/machines") || location.pathname.includes("/consumables")) {
            handleObjectType("Machine");
        } else if (location.pathname.includes("/conversations")) {
            handleObjectType("Conversation");
        } else {
            handleObjectType(default_type);
        }
    };

    const updateObjectByKey = useCallback((key: string, value: string | number | boolean | PropertiesType) => {
        setObject((prevState) => ({...prevState, [key]: value}));
    }, [setObject]);

    useEffect(() => {
        if (params.id) {
            return;
        }

        updateObjectByKey("properties", {});
        switch (object.object_type) {
            case "Building":
                addDefaultPropByType(["address", "map_point", "image"]);
                break;
            case "Organization":
            case "Zone":
            case "Room":
                addDefaultPropByType(["image"]);
                break;
            case "Floor":
                addDefaultPropByType(["elevation", "image"]);
                break;
            case "Site":
                addDefaultPropByType(["map_point", "image"]);
                break;
            case "Document":
                addDefaultPropByType(["page"]);
                break;
            case "File":
                addDefaultPropByType(["file"]);
                break;
            case "CameraPlanZone":
            case "CameraMapZone":
                addDefaultPropByType(["map_polygon", "image_polygon", "image"]);
                break;
            case "IPCamera":
                addDefaultPropByType(["live_streaming", "object_detection", "url", "image", "preferred_resolution", "visibility_detected_object", "object_types_allowed_for_detection"]);
                break;
            case "Machine":
                addDefaultPropByType(DEFAULT_PROPERTIES_BY_MACHINES);
                break;
            case "Conversation":
                addDefaultPropByType(["chat_message", "image"]);
                break;
            default:
                break;
        }
    }, [object.object_type]);

    useEffect(() => {
        if (!params.id) {
            props.setObject({...EMPTY_OBJECT});
            return;
        }

        let active = true;

        (async () => {
            const res = await getObjectById(params.id, {skipChildrenTransform: true});

            if (!active || !res) {
                return;
            }

            props.setObject(res);
            setObject(res);
        })();

        return () => {
            active = false;
        }
    }, [params.id]);

    useEffect(() => {
        if (!parentId) {
            getDefaultTypeByParent("");
            return;
        }

        let active = true;

        (async () => {
            const res = await getObjectById(parentId as string, {skipChildrenTransform: true});
            objectParent.current = res;

            if (!active || !res) {
                return;
            }

            getDefaultTypeByParent(res.object_type === OBJECT_TYPE.ORGANIZATION ? "" : res.object_type);
        })();

        return () => {
            active = false;
        }
    }, [parentId, location.pathname]);

    useSubSubmit(async () => {
        setLoader(true);

        const objectToSave: BodyObjectItem = copy(object);
        const has_object_id: boolean = !!objectToSave.object_id;

        if (!has_object_id && !objectToSave.object_name) {
            setLoader(false);
            return notify.errorNotify("Object Name Required");
        }
        // for simple objects we save the image in s3 as well
        if (objectToSave.properties?.image) {
            objectToSave.properties.image.persistent = true
        }

        let _emails: string[] = copy(addedEmails);

        if (pagesWithSharing.includes(location.pathname.split('/')[1] as string) && emailsInput && isEmail(emailsInput) &&
            userInfo?.user?.email !== emailsInput && (!addedEmails || !addedEmails.includes(emailsInput)))
        {
            _emails = [...addedEmails, emailsInput];
        }

        if (creating && objectParent.current) {
            const _epoch = Date.now();

            const _permissions: Permissions = {
                perm_id: v4(),
                updated: _epoch
            };

            if (objectParent.current?.permissions?.private_acl) {
                for (let key in objectParent.current.permissions.private_acl) {
                    if (!objectParent.current.permissions.private_acl.hasOwnProperty(key)) {
                        continue;
                    }

                    if (key === getUserId() && (!objectParent.current.permissions.private_acl[key].access_level ||
                        !isAllowedOperation(objectParent.current.permissions.private_acl[key].access_level, AccessLevel.CONTRIBUTE))
                    ) {
                        setLoader(false);
                        return notify.errorNotify("You have no permission to create a child object");
                    }

                    if (!_permissions.private_acl) {
                        _permissions.private_acl = {};
                    }

                    _permissions.private_acl[key] = {
                        access_level: objectParent.current.permissions.private_acl[key].access_level,
                        updated: _epoch
                    }
                }
            }

            if (_permissions.private_acl && Object.keys(_permissions.private_acl).length) {
                objectToSave.permissions = _permissions;
            }
        }

        try {
            const res = await postObject(objectToSave, {
                origin: OriginType.USER,
                parentId: parentId,
                parentOwnerId: objectParent.current?.owner_id,
                clientId: getClientId()
            });

            if (parentId && res?.object_id) {
                await postObjectChildren(parentId, res.object_id, {origin: OriginType.USER});
            }

            if (pagesWithSharing.includes(location.pathname.split('/')[1] as string) && res?.object_id && _emails.length) {
                const users = await getUsers({email_list: _emails});

                const tempPermissions: Permissions = {
                    perm_id: v4(),
                    updated: Date.now(),
                    private_acl: {}
                };

                for (const user of users || []) {
                    if (!user?.id || !tempPermissions?.private_acl) {
                        continue;
                    }
                    tempPermissions.private_acl[user.id] = {
                        updated: Date.now(),
                        access_level: AccessLevel.WRITE
                    }
                }

                await saveObjectEncryptKeyForExistingUser(res.object_id, tempPermissions, {origin: OriginType.USER});

                await postObjectPermissionsInvite(
                    res.object_id,
                    AccessLevel.WRITE,
                    _emails,
                    "",
                    [res.object_id],
                    {
                        origin: OriginType.USER,
                        clientId: getClientId()
                    }
                );
            }

            await wait(1000);

            if (location.pathname.includes("/conversations") && res?.object_id) {
                history.replace("/conversations/" + res.object_id + "/chat");
            } else if (location.pathname.includes("/documents") && res?.object_id) {
                history.replace("/documents/" + res.object_id + "/page/edit");
            } else {
                handleBackButton();
            }
        } catch (e: any) {
            notify.errorNotify(e?.message || JSON.stringify(e));
        }

        setLoader(false);
    });

    return (
        <Container component="main">
            <div className={classes.paper}>
                <form className={classes.form} noValidate>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={12} sm={10} md={8}>
                            <TextControl
                                propertyKey="object_name"
                                label="Object Name"
                                value={object.object_name as string}
                                saveValue={updateObjectByKey}
                                controlInputProps={{
                                    fullWidth: true,
                                    variant: "outlined",
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={10} md={8}>
                            <TextControl
                                propertyKey="description"
                                label="Description"
                                value={object.description || ""}
                                saveValue={updateObjectByKey}
                                controlInputProps={{
                                    fullWidth: true,
                                    variant: "outlined",
                                }}
                            />
                        </Grid>
                        {!ROOT_TYPES.includes(object.object_type) && !params.id && parentId ? (
                            <Grid item xs={12} sm={10} md={8} container justifyContent="flex-start">
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel htmlFor="outlined-age-native-simple">Type</InputLabel>
                                    <Select
                                        native
                                        value={object.object_type}
                                        onChange={(e) => updateObjectByKey("object_type", e.target.value as string)}
                                        label="object_type"
                                    >
                                        {CREATION_TYPES.map((item) => (
                                            <option key={item} value={item}>
                                                {item}
                                            </option>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        ) : null}

                        <ObjectFormProperties
                            object={object}
                            userInfo={userInfo}
                            updateObjectByKey={updateObjectByKey}
                        />
                        <ObjectFormActions
                            object={object}
                            userInfo={userInfo}
                            updateObjectByKey={updateObjectByKey}
                            handleBackButton={handleBackButton}
                        />
                        {pagesWithSharing.includes(location.pathname.split('/')[1] as string) && (
                            <Grid item xs={12} sm={10} md={8}>
                                <AddPeopleDropdown
                                    addedEmails={addedEmails}
                                    setAddedEmails={setAddedEmails}
                                    emailsInput={emailsInput}
                                    setEmailsInput={setEmailsInput}
                                />
                            </Grid>
                        )}
                    </Grid>
                </form>
            </div>
        </Container>
    );
};

export default ObjectForm;
