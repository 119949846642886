import React from "react";

import Typography from "@material-ui/core/Typography";
import { Switch } from "@material-ui/core";
import useDarkMode, { DarkMode } from "@fisch0920/use-dark-mode";

const DarkModeSwitch = () => {

    const darkMode: DarkMode = useDarkMode();

    return (
        <>
            <Typography variant="body2" gutterBottom>
                Dark Theme
            </Typography>
            <Switch
                checked={darkMode.value}
                onClick={darkMode.toggle}
                name="dark-mode-switch"
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
            />
        </>
    )
};

export default DarkModeSwitch;
