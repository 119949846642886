import { getItem, setItem, removeItem, KEY_LS } from "../StorageService";

// GET
const getJWTToken = () => {
    return getItem(KEY_LS.JWT_TOKEN);
};

const getJWTTokenTime = () => {
    return getItem(KEY_LS.JWT_TOKEN_TIME);
};

// SET
const setJWTToken = (token: string) => {
    const tokenTime = Date.now().toString();

    setItem(KEY_LS.JWT_TOKEN, token);
    setItem(KEY_LS.JWT_TOKEN_TIME, tokenTime);
};

const setRememberMe = (value: boolean) => {
    setItem(KEY_LS.REMEMBER_ME, value, { local: true });
};

// REMOVE
const removeJWTToken = () => {
    removeItem(KEY_LS.JWT_TOKEN);
    removeItem(KEY_LS.JWT_TOKEN_TIME);
};

const removeRememberMe = () => {
    removeItem(KEY_LS.REMEMBER_ME, { local: true });
};

export {
    // GET
    getJWTToken,
    getJWTTokenTime,

    // SET
    setJWTToken,
    setRememberMe,

    // REMOVE
    removeJWTToken,
    removeRememberMe
}
