import React, {useCallback} from "react";
import {TextControl} from "./TextControl";
import {PropertyItemValue, ChatMessageType} from "../../Services/ObjectsService/Types";

type ChatMessageControlProps = {
    propertyKey: string;
    label: string;
    value: ChatMessageType;
    onChangeHandler?: boolean;
    variant?: string;
    controlInputProps?: object;
    saveValue(key: string, value: PropertyItemValue): void;
};

const ChatMessageControl = (props: ChatMessageControlProps) => {

    const {
        propertyKey,
        label,
        value,
        onChangeHandler,
        variant,
        controlInputProps,
        saveValue
    } = props;

    const _saveValue = useCallback((_key: string, _value: string | number | boolean) => {
        saveValue(_key, {
            ...value,
            text: _value,
            timestamp: Date.now()
        } as ChatMessageType);
    }, [saveValue, value]);

    return (
        <TextControl
            propertyKey={propertyKey}
            label={label}
            value={value.text}
            onChangeHandler={onChangeHandler}
            variant={variant}
            controlInputProps={controlInputProps}
            saveValue={_saveValue}
        />
    );
};

export {
    ChatMessageControl
};
