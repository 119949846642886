import { UserItem } from "./Types";

export const DEFAULT_USER_ITEM: UserItem = {
    lastname: "",
    firstname: "",
    email: "",
    picture: "",
    age: "",
    gender: "",
    system_of_measures: "metric",
    show_welcome_dialog: true,
    clients: [],
    guest: false,
};

export const PROFILE_FIELDS_TO_STORE: string[] = ['api_calls', 'email', 'firstname', 'gender', 'age', 'id', 'lastname',
                                                  'show_welcome_dialog', 'system_of_measures', 'guest', 'picture'];

export enum USER_INFO_KEY_LS {
    CLIENT_ID = "client_id",
    IAP = "iap"
}
