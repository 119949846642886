import React, {ReactNode, useEffect, useState} from "react";
import {ObjectItem, PointTuple} from "../../Services/ObjectsService";
import type {SystemOfMeasuresType} from "../../Helpers/SystemOfMeasures";
import {PLAN_RELATED_TYPES} from "../../Services/ObjectsService/Constants";
import {PointFields} from "./common/PointFields";
import Typography from "@material-ui/core/Typography";
import {Box, Grid} from "@material-ui/core";
import {useCommonStyles} from "./common/Common";
import {PlanControl} from "./common/PlanControl";
import ObjectsFromTreeView from "../ListObjects/ObjectsFromTreeView/ObjectsFromTreeView";
import {UserInfoType} from "../../Hooks/useUserProfile";

type PlanPointControlProps = {
    propertyKey: string;
    label: string;
    value?: { [key: string]: PointTuple };
    enableSelect?: boolean;
    enableDelete?: boolean;
    saveValue(key: string, value: { [key: string]: PointTuple }): void;
    isDisabled?: boolean;
    settings?: ReactNode;
    parentId?: string;
    object?: ObjectItem;
    variant?: string;
    userInfo: UserInfoType;
    systemOfMeasures: SystemOfMeasuresType;
};

const PlanPoint = (props: PlanPointControlProps) => {
    const classes = useCommonStyles();

    const {
        propertyKey,
        label,
        value,
        enableSelect,
        enableDelete,
        saveValue,
        isDisabled,
        settings,
        parentId,
        variant,
        object,
        userInfo,
        systemOfMeasures
    } = props;

    let parentIds = (value && Object.keys(value)) || [];
    //parentIds = parentIds.concat(parentId || []);
    const [selectedId, setSelectedId] = useState<string>("");
    const [point, setPoint] = useState<PointTuple>([0, 0]);

    const updatePoints = (_value: PointTuple) => {
        setPoint(() => {
            saveValue(propertyKey, {...value, [selectedId]: _value});
            return _value;
        });
    };

    useEffect(() => {
        if (value && selectedId) {
            console.log("Value: ", value);
            setPoint(value[selectedId] || [0, 0]);
        }
    }, [selectedId, value]);

    const changeSelectParentId = (object: ObjectItem | null) => {
        setSelectedId(object?.object_id || "");
    };
    if (["propertyCard", "title"].includes(variant as string)) {
        return <PlanControl
            object={object}
            parentId={parentId}
            variant={variant}
            systemOfMeasures={systemOfMeasures}
        />;
    } else {
        return (
            <Grid container direction="column" justifyContent="center">
                <Grid item container direction="row" justifyContent="center" alignItems="center">
                    {settings}
                    <Typography>{label || "Plan Point"}</Typography>
                </Grid>
                <Box border={1} className={classes.border}>
                    <ObjectsFromTreeView
                        handleObject={changeSelectParentId}
                        currentObjectId={parentId || parentIds[0]}
                        filterType={PLAN_RELATED_TYPES}
                        userInfo={userInfo}
                        childrenObjectsIds={parentIds}
                    />
                    {selectedId && (
                        <PointFields
                            key={`planpointfield`}
                            point={point as PointTuple}
                            enableSelect={enableSelect}
                            enableDelete={enableDelete}
                            handleUpdate={updatePoints.bind(null)}
                            isDisabled={isDisabled}
                        />
                    )}
                </Box>
            </Grid>
        );
    }
};

export {PlanPoint};
