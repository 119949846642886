import React, { useEffect, useState } from "react";
import { Map, Marker, Popup, TileLayer } from "react-leaflet";
import { LeafletEvent, LeafletMouseEvent } from "leaflet";
import { Box, Grid, TextField, Fab } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useCommonStyles } from "../common/Common";
import { MapComponentProps } from "./MapPointTypes";
import { MAP_BOUNDS, MAP_CENTER } from "Components/Objects/SiteMap/Constants";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        switch_layers: {
            display: 'none',
            margin: 5,
            position: "absolute",
            bottom: 5,
            left: 5,
            zIndex: theme.zIndex.appBar - 1,
            borderRadius: "4px",
            backgroundColor: "#fff",
            width: "26px",
            height: "26px",
        },
        box_relative: {
            position: "relative",
            height: "inherit",
        },
        box_property_card: {
            width: "100%",
            overflow: "hidden",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
        }
    })
);

const MapComponent = (props: MapComponentProps) => {
    const commonClasses = useCommonStyles();
    const classes = useStyles();

    const { value, mapWidth, saveValue, isDisabled, isPropertyCard } = props;

    const [zoom, setZoom] = useState<number>(value ? 12 : 1);
    const [lat, setLat] = useState<number>(value ? value[0] : 0);
    const [lng, setLng] = useState<number>(value ? value[1] : 0);
    const [layer, setLayer] = useState<string>("map");

    const handleClickMap = (e: LeafletMouseEvent) => {
        setZoom(e.target._zoom);
        setLng(e.latlng.lng);
        setLat(e.latlng.lat);
        saveValue([e.latlng.lat, e.latlng.lng]);
    };

    const handleOnChangeLat = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newLat: number = parseFloat(e.target.value);
        setLat(newLat || 0);
    };

    const handleOnChangeLng = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newLng: number = parseFloat(e.target.value);
        setLng(newLng || 0);
    };

    const handleZoomLevelChange = (e: LeafletEvent) => {
        setZoom(e.target._zoom);
    };

    const saveSelectedValue = () => {
        console.log([lat, lng]);
        saveValue([lat, lng]);
    };

    useEffect(() => {
        if (!value) return;
        setLat(value[0]);
        setLng(value[1]);
    }, [value]);

    const switchLayers = (e: any) => {
        setLayer((prev) => (prev === "map" ? "satellite" : "map"));
    };

    const displayMap = () => {
        return (
            <>
                <Map
                    center={value ? [lat, lng] : MAP_CENTER}
                    zoom={zoom}
                    onClick={isDisabled ? null : handleClickMap}
                    scrollWheelZoom={false}
                    onZoomEnd={handleZoomLevelChange}
                    className={commonClasses.leafletContainer}
                    maxBounds={MAP_BOUNDS}
                >
                    {layer === "map" ? (
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        />
                    ) : (
                        [
                            <TileLayer
                                url="https://server.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            />,
                            <TileLayer
                                url="https://server.arcgisonline.com/arcgis/rest/services/Reference/World_Boundaries_and_Places/MapServer/tile/{z}/{y}/{x}"
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            />,
                        ]
                    )}
                    {value ? (
                        <Marker position={[lat, lng]}>
                            <Popup>Selected location here</Popup>
                        </Marker>
                    ) : null}
                </Map>
                {!isPropertyCard && (
                    <Fab
                        aria-label="switch layers"
                        key={layer}
                        component="div"
                        className={classes.switch_layers}
                        onClick={switchLayers}
                        children={<a className={`fas fa-${layer === "map" ? "satellite" : "map"}`} />}
                    />
                )}
            </>
        );
    };

    return (
        <Box width={mapWidth} className={`${classes.box_relative} ${isPropertyCard ? classes.box_property_card : ""}`}>
            <Grid container spacing={1} justifyContent="center" className={classes.box_relative}>
                <Grid item xs={12} className={classes.box_relative}>
                    {!isPropertyCard ? (
                        <Box height={300}>{displayMap()}</Box>
                    ) : (
                        <Box className={`${classes.box_relative} ${classes.box_property_card}`}>{displayMap()}</Box>
                    )}
                </Grid>
                <Grid item xs={12}>
                    {value && !isPropertyCard ? (
                        <Grid container spacing={1} justifyContent="center">
                            <Grid item xs={6}>
                                <Box p={0.5}>
                                    <TextField
                                        variant="outlined"
                                        value={lat}
                                        name={"lat"}
                                        type="number"
                                        label="Latitude"
                                        margin="dense"
                                        fullWidth={true}
                                        onChange={handleOnChangeLat}
                                        onBlur={saveSelectedValue}
                                        disabled={isDisabled}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box p={0.5}>
                                    <TextField
                                        variant="outlined"
                                        value={lng}
                                        name={"lng"}
                                        type="number"
                                        label="Longitude"
                                        margin="dense"
                                        fullWidth={true}
                                        onChange={handleOnChangeLng}
                                        onBlur={saveSelectedValue}
                                        disabled={isDisabled}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    ) : null}
                </Grid>
            </Grid>
        </Box>
    );
};

export { MapComponent };
