import {useState, useCallback, useMemo, useRef} from "react";
import {ObjectItem, OriginPropsType, PropertyItemValue} from "../../Services/ObjectsService/Types";
import {copy} from "../../Services/Utils";
import {MAP_ZONE_ELEMENT_OBJECT_TYPE} from "../../Components/Objects/SiteMap/Constants";
import {
    deleteObject,
    deleteObjectChildren,
    postObject,
    postObjectChildren,
    postObjectProperties
} from "../../Services/ObjectsService/CRUD";
import {useLocation} from "react-router-dom";
import {isObjectAvailableToDisplay} from "../../Services/ObjectsService";

export type useMapControllerType = {
    objects: ObjectItem[];
    selectedObject: ObjectItem | null;
    viewportCenter: number[];
    setObjects(objects: ObjectItem[]): void;
    setSelectedObject(object: ObjectItem | null): void;
    setViewportCenter(value: number[]): void;
    makeUndo(): void;
    makeRedo(): void;
    getChildrenObject(): ObjectItem[];
    updateChildObjects(objects: { [key: string]: ObjectItem }): void;
    addChildObjects(objects: { [key: string]: ObjectItem }): void;
    addChildObject(object: ObjectItem): void;
    deleteChildrenObjects(ids: string[]): void;
    getSelectObject(): ObjectItem | null;
    setSelectObject(object: ObjectItem | null): void;
    updateValueOfProperty(key: string, value: PropertyItemValue): void;
    updateValueOfPropertyByChildId(childId: string, key: string, value: PropertyItemValue): void;
    updateSelectedObjectProperties(properties: { [string: string]: any }): void;
    updateSelectedObject(key: string, value: | number | boolean): void;
    deleteSelectObjectOnMap(): void;
    saveMap(parentId: string | undefined, origin: OriginPropsType): Promise<undefined>;
};

const useMapController = (inputObjects: ObjectItem[] = []): useMapControllerType => {

    const location = useLocation();

    const [objects, setObjects] = useState<ObjectItem[]>(copy(inputObjects));
    const [selectedObject, setSelectedObject] = useState<ObjectItem | null>(null);
    const [viewportCenter, setViewportCenter] = useState<number[]>([]);

    const historyStates = useRef<ObjectItem[][]>([]);
    const currentHistory = useRef<number>(0);

    const pushHistory = useCallback((listObjects: ObjectItem[]) => {
        const history = historyStates.current.splice(0, currentHistory.current + 1);
        history.push(copy(listObjects));
        historyStates.current = history;
        currentHistory.current = history.length - 1;
    },[historyStates.current, currentHistory.current]);

    const applyHistory = useCallback((index: number) => {
        const historyObjects: ObjectItem[] = copy(historyStates.current[index]);
        setSelectedObject((prevObject) => {
            return copy(historyObjects.find((object) => {
                return prevObject?.object_id === object.object_id;
            })) || null;
        });
        setObjects(historyObjects);
    }, [setObjects, setSelectedObject, historyStates.current, selectedObject]);

    const makeUndo = useCallback(() => {
        if (currentHistory.current - 1 === -1) {
            return;
        }
        --currentHistory.current;

        applyHistory(currentHistory.current);

    },[currentHistory.current, applyHistory]);

    const makeRedo = useCallback(() => {
        if (currentHistory.current + 1 === historyStates.current.length) {
            return;
        }
        ++currentHistory.current;

        applyHistory(currentHistory.current);
    }, [currentHistory.current, applyHistory]);

    const getChildrenObject = useCallback((all?: boolean) => {
        return objects.filter((object) => !object.is_deleted || all);
    }, [objects]);

    const updateChildObjects = useCallback((childObjects: { [key: string]: ObjectItem }) => {
        console.log("updateChildObjects");
        //need additional checking this logic
        setObjects((prevObjects) => {
            const inputObjects = copy(childObjects);

            const result = [...prevObjects];

            for (const objectId in inputObjects || {}) {
                if (!inputObjects.hasOwnProperty(objectId) || !inputObjects[objectId]) {
                    continue;
                }

                const object = result.find((object) => {
                    return object.object_id === objectId;
                });

                if (!object) {
                    continue;
                }

                const properties = copy(inputObjects[objectId].properties);
                delete inputObjects[objectId].properties;

                Object.assign(object, inputObjects[objectId]);

                if (properties) {
                    object.properties = Object.assign(object.properties || {}, properties);
                }
            }

            return result;
        });
    },[setObjects]);

    const addChildObjects = useCallback((childObjects: { [key: string]: ObjectItem }) => {
        // console.log("addChildObjects");
        //need additional checking this logic
        setObjects((prevObjects) => {
            const inputObjects = copy(childObjects);

            const result = [...prevObjects];

            for (const objectId in inputObjects || {}) {
                if (!inputObjects.hasOwnProperty(objectId) || !inputObjects[objectId]) {
                    continue;
                }

                const index = result.findIndex((item) => {
                    return item.object_id === objectId;
                });

                if (index === -1) {
                    result.push(inputObjects[objectId]);
                } else if (result[index].is_deleted) {
                    result[index] = inputObjects[objectId];
                }
            }

            return result;
        });
    },[setObjects]);

    const addChildObject = useCallback((input: ObjectItem) => {
        const object = copy(input);
        
        if (!isObjectAvailableToDisplay(object)) {
            return;
        }

        console.log("addChildObject");
        //need to additional tests
        setObjects((prev) => {
            const index = prev.findIndex((item) => {
                return item.object_id === object.object_id;
            });

            let result = [...prev];

            if (index === -1) {
                result.push({...object, is_created: true})
            } else {
                delete object.is_deleted;
                result[index] = object;
            }

            if (!MAP_ZONE_ELEMENT_OBJECT_TYPE.includes(object.object_type) || !object.is_created) {
                object.changed = true;
                pushHistory(result);
            }

            return result;
        });
    },[setObjects, pushHistory]);

    const deleteChildrenObjects =  useCallback((ids: string[]) => {
        // console.log("deleteChildrenObjects");

        setObjects((prev) => {
            return prev.filter((item) => {
                if (!ids.includes(item.object_id)) {
                    return true;
                }

                if (selectedObject?.object_id === item.object_id) {
                    setSelectedObject(null);
                }

                return false;
            });
        });
    },[setObjects, selectedObject]);

    const getSelectObject = useCallback(() => {
        return objects.find((object) => {
            return object.object_id === selectedObject?.object_id;
        }) || selectedObject || null;
    }, [objects, selectedObject]);

    const setSelectObject = useCallback((object: ObjectItem | null) => {
        setSelectedObject(copy(object));
    },[setSelectedObject]);

    const updateValueOfProperty = useCallback((key: string, value: PropertyItemValue) => {
        console.log("updateValueOfProperty");
        if (!selectedObject) {
            return;
        }

        setSelectedObject((prevObject) => {
            if (!prevObject) {
                return prevObject;
            }

            const updatedSelectedObject = copy((prevObject as any));
            updatedSelectedObject.changed = true;
            updatedSelectedObject.properties[key].value = copy(value);

            setObjects((prevObjects) => {
                if (!updatedSelectedObject) {
                    return  prevObjects;
                }

                const result = prevObjects.map((object) => {
                    if (object.object_id !== updatedSelectedObject?.object_id) {
                        return object;
                    }

                    (object as any).changed = true;
                    object.properties[key].value = copy(value);

                    return object;
                });

                pushHistory(result);
                return result;
            });

            return updatedSelectedObject;
        });
    }, [setSelectedObject, selectedObject, setObjects, objects, pushHistory]);

    const updateValueOfPropertyByChildId = useCallback((childId: string, key: string, value: PropertyItemValue) => {
        console.log("updateValueOfPropertyByChildId");
        setObjects((prevObjects) => {
            return prevObjects.map((object) => {
                if (object.object_id === childId) {
                    object.properties[key].value = copy(value);
                }
                return object;
            });
        });
    }, [setObjects, objects]);

    const updateSelectedObjectProperties = useCallback((properties) => {
        console.log("updateSelectedObjectProperties");
        if (!selectedObject) {
            return;
        }

        setSelectedObject((prevSelectedObject) => {
            if (!prevSelectedObject) {
                return prevSelectedObject;
            }

            const updatedSelectedObject = {...prevSelectedObject, properties: copy(properties)};

            setObjects((prevObjects) => {
                if (!updatedSelectedObject) {
                    return  prevObjects;
                }

                return prevObjects.map((child) => {
                    return child.object_id === updatedSelectedObject?.object_id
                        ? updatedSelectedObject
                        : child;
                });
            });

            return updatedSelectedObject;
        });
    }, [setSelectedObject, setObjects, selectedObject, objects]);

    const updateSelectedObject = useCallback((key: string, value: string | number | boolean) => {
        console.log("updateSelectedObject");
        if (!selectedObject) {
            return;
        }

        setSelectedObject((prevObject) => {
            if (!prevObject) {
                return prevObject;
            }

            const updatedSelectedObject = copy((prevObject as any));
            updatedSelectedObject.changed = true;

            updatedSelectedObject[key] = copy(value);

            setObjects((prevObjects) => {
                if (!updatedSelectedObject) {
                    return  prevObjects;
                }

                const result = prevObjects.map((object: any) => {
                    if (object.object_id !== updatedSelectedObject?.object_id) {
                        return object;
                    }

                    (object as any).changed = true;

                    object[key] = copy(value);

                    return object;
                });

                pushHistory(result);
                return result;
            });

            return updatedSelectedObject;
        });
    }, [setSelectedObject, selectedObject, setObjects, objects, pushHistory]);

    const deleteSelectObjectOnMap = useCallback(() => {
        console.log("deleteSelectObjectOnMap");

        setSelectedObject((prevSelectedObject) => {
            const objectId = prevSelectedObject?.object_id;

            setObjects((prevObjects) => {
                if (!objectId) {
                    return prevObjects;
                }

                const result = prevObjects.map((object) => {
                    if (object.object_id !== objectId) {
                        return object;
                    }

                    object.is_deleted = true;
                    delete object.changed;
                    if (object?.properties.map_point) {
                        object.properties.map_point.removed = true;
                    }
                    if (object?.properties.map_polygon) {
                        object.properties.map_polygon.value = [];
                    }

                    return object;
                });

                pushHistory(result);
                return result;
            });

            return null;
        });
    }, [setSelectedObject, setObjects, pushHistory]);

    const saveMap = useCallback (async (parentId , origin) => {
        console.log("Saving map");

        const saveObjects = getChildrenObject(true).filter((object) => {
            return (object.is_deleted && !(object.changed || object.is_created)) ||
                  (!object.is_deleted &&  (object.changed || object.is_created));
        }) || [];

        // console.log("Objects: ", getChildrenObject(true));
        // console.log("saveObjects: ", saveObjects);

        if (!saveObjects.length) {
            return Promise.reject({message: "No items to save on the map"});
        }

        const childrenToUpdate: { [key: string]: ObjectItem } = {};
        const childrenToRemove: string[] = [];

        for (const object of saveObjects) {
            try {
                if (object.is_created) {
                    console.log("Created object: ", copy(object));
                    delete object.is_created;
                    delete object.changed;
                    await postObject(object, origin);
                    if (parentId && !location.pathname.includes("/objects") && location.pathname !== "/map/edit" && parentId !== object.object_id) {
                        await postObjectChildren(parentId, object.object_id, origin);
                    }
                    childrenToUpdate[object.object_id] = object;
                } else if (object.is_deleted) {
                    console.log("Deleted object: ", copy(object));
                    if (object.object_type === "CameraMapZone") {
                        await deleteObject(object.object_id, origin);
                    } else {
                        await postObjectProperties(object.object_id, object.properties, origin);
                    }
                    if (parentId && !location.pathname.includes("/objects") && location.pathname !== "/map/edit") {
                        await deleteObjectChildren(parentId, object.object_id, origin);
                    }
                    childrenToRemove.push(object.object_id);
                } else if (object.changed) {
                    console.log("Changed object: ", copy(object));
                    delete object.changed;
                    await postObject({object_id: object.object_id, object_name: object.object_name, description: object.description, properties: object.properties}, origin);
                    if (parentId && !location.pathname.includes("/objects") && location.pathname !== "/map/edit" && parentId !== object.object_id) {
                        await postObjectChildren(parentId, object.object_id, origin);
                    }
                    childrenToUpdate[object.object_id] = object;
                }
            } catch (e) {
                console.error(e);
            }
        }

        if (Object.keys(childrenToUpdate).length !== 0) {
            updateChildObjects(childrenToUpdate);
        }

        if (childrenToRemove.length !== 0) {
            deleteChildrenObjects(childrenToRemove);
        }

        console.log("End Save Map");
    }, [getChildrenObject, updateChildObjects, deleteChildrenObjects]);

    return {
        objects: useMemo(() => {
            return objects;
        }, [objects]),
        selectedObject: useMemo(() => {
            return selectedObject;
        }, [selectedObject]),
        viewportCenter: useMemo(()=> {
            return viewportCenter;
        }, [viewportCenter]),
        setObjects: useCallback((value ) => {
            setObjects(copy(value));
        }, [setObjects]),
        setSelectedObject: useCallback((value) => {
            setSelectedObject(copy(value));
        }, [setSelectedObject]),
        setViewportCenter: useCallback((value) => {
            setViewportCenter(value);
        }, [setViewportCenter]),
        makeUndo: makeUndo,
        makeRedo: makeRedo,
        getChildrenObject: getChildrenObject,
        updateChildObjects: updateChildObjects,
        addChildObjects: addChildObjects,
        addChildObject: addChildObject,
        deleteChildrenObjects: deleteChildrenObjects,
        getSelectObject: getSelectObject,
        setSelectObject: setSelectObject,
        updateValueOfProperty: updateValueOfProperty,
        updateValueOfPropertyByChildId: updateValueOfPropertyByChildId,
        updateSelectedObjectProperties: updateSelectedObjectProperties,
        updateSelectedObject: updateSelectedObject,
        deleteSelectObjectOnMap: deleteSelectObjectOnMap,
        saveMap: saveMap,
    }
};

export default useMapController;
