import React from "react";
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import UpdateIcon from '@material-ui/icons/Update';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import LanguageIcon from '@material-ui/icons/Language';
import DeviceIconComponent from "./DeviceIconComponent"
import FormattedActivityTime from "../../../../../Common/FormattedActivityTime/FormattedActivityTime";
import {ClientItemDetailsProps} from "../../../UserProfileTypes";
import Avatar from "@material-ui/core/Avatar/Avatar";
import ListItemAvatar from "@material-ui/core/ListItemAvatar/ListItemAvatar";
import {useMediaQuery, useTheme} from "@material-ui/core";


const ClientItemDetails = (props: ClientItemDetailsProps) => {
    const theme = useTheme();
    const downXs = useMediaQuery(theme.breakpoints.down("xs"));

    const {client, userInfo} = props;

    return (
        <List component="div" disablePadding dense>
            {
                userInfo.clientId === client.client_id
                    ?
                    <ListItem >
                        <ListItemAvatar>
                            <Avatar>
                                <CheckCircleIcon/>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary="This device"
                            primaryTypographyProps={{variant: downXs ? "caption" : "body1"}}
                        />
                    </ListItem>
                    :
                    null
            }
            <ListItem >
                <ListItemAvatar>
                    <Avatar>
                        <DeviceIconComponent os={client.os}/>
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={`${client.os || ""} ${client.os_version || ""}`}
                    secondary="Operating system"
                    secondaryTypographyProps={{ variant: "caption" }}
                    primaryTypographyProps={{variant: downXs ? "caption" : "body1"}}
                />
            </ListItem>
            <ListItem >
                <ListItemAvatar>
                    <Avatar>
                        <LanguageIcon/>
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={`${client.browser_name || ""} ${client.browser_version || ""}`}
                    secondary="Browser"
                    secondaryTypographyProps={{ variant: "caption" }}
                    primaryTypographyProps={{variant: downXs ? "caption" : "body1"}}
                />
            </ListItem>
            <ListItem >
                <ListItemAvatar>
                    <Avatar>
                        <LockOpenIcon/>
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={
                    <FormattedActivityTime
                        propsTypography={{ variant: downXs ? "caption" : "body2"}}
                        activityEpoch={client.login_epoch}
                    />
                }
                    secondary="last login"
                    secondaryTypographyProps={{ variant: "caption" }}
                />
            </ListItem>
            <ListItem >
                <ListItemAvatar>
                    <Avatar>
                        <UpdateIcon/>
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={
                    <FormattedActivityTime
                        propsTypography={{ variant: downXs ? "caption" : "body2" }}
                        activityEpoch={client.last_activity_epoch}
                    />
                }
                    secondary="last active"
                    secondaryTypographyProps={{ variant: "caption" }}
                />
            </ListItem>
        </List>
    )
};

export default ClientItemDetails;
